import React from 'react';
import Heading from '@atlaskit/heading';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import { FormattedMessage } from '@atlassian/jira-intl';
import CellButton from '../cell-button';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

// Remove with the cleanup of com.atlassian.rm.jpo.ease-of-use.remove-no-parent-header
function IssuesWithoutParentHeader({ id, isExpanded = false, issuesCount, onToggle }: Props) {
	const handleToggle = () => {
		onToggle({ [id]: !isExpanded });
	};

	const content = (
		<Heading size="xsmall" as="h2" data-role="header">
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.issuesWithoutParentText}>
				{isExpanded ? <ChevronDownIcon label="" /> : <ChevronRightIcon label="" />}
				<FormattedMessage
					{...messages.issuesWithoutParent}
					values={{
						issuesCount,
					}}
				/>
			</div>
		</Heading>
	);

	return (
		<CellButton
			onClick={handleToggle}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles.toggleButton}
			data-name="toggleIssuesWithoutParentHeader"
			ariaExpanded={isExpanded}
		>
			{content}
		</CellButton>
	);
}

export default IssuesWithoutParentHeader;
