import { getSupportedCustomFields } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { Props, StateProps } from './types';

const querySelector = createStructuredSelector<State, Props, StateProps>({
	customFieldValue: (state: State, props: Props) =>
		getSupportedCustomFields(state).find((field) => field.id.toString() === props.attribute),
});

export default querySelector;
