import {
	isReadOnly,
	isOptimizedMode,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app';
import {
	getIssueBeingEdited,
	getIsLoadingIssueDescription,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-modal';
import { getProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects';
import { getAllIssuesWithOriginalAndOptimizedById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { OwnProps, StateProps } from './types';

export default createStructuredSelector<State, OwnProps, StateProps>({
	isLoadingIssueDescription: getIsLoadingIssueDescription,
	isOptimizedMode,
	isReadOnly,
	issueBeingEdited: getIssueBeingEdited,
	issuesById: getAllIssuesWithOriginalAndOptimizedById,
	projectsById: getProjectsById,
});
