.settingsList {
	/* (60px (Jira nav) + 100 px (ARJ header) + 32px (top/bottom padding) + 64px (bottom offset) = 256px */
	max-height: calc(100vh - 256px);
	/* This removes scrollbars that appear from overlapped elements in IE11 */
	-ms-overflow-style: auto;
	overflow-y: auto;

	& tbody {
		border: 0;
	}
}

.checkboxInfo {
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	margin-left: calc(3 * var(--jpo-common-base-spacing));
	font-size: 13px;
}

.clearAllButton {
	text-align: right;
}

.showCapacityCheckbox {
	margin-left: calc(var(--jpo-common-base-spacing) * -1);
}

.viewSettingSelect {
	width: 100%;
}

.rollupSetting {
	min-width: 100px;
}

.row {
	& td {
		padding-bottom: calc(2 * var(--jpo-common-base-spacing));
	}

	&:last-child td {
		padding-bottom: 0;
	}
}

.topAlignedTd {
	vertical-align: top;
}
