import { combineReducers } from '@atlassian/jira-portfolio-3-portfolio/src/common/redux';
import changeStatus from './change-status/reducer';
import changesWarnings from './changes-warnings/reducer';
import changes from './changes/reducer';
import commit from './commit/reducer';
import hiddenIssues from './hidden-issues/reducer';
import requiredFields from './required-fields/reducer';
import revert from './revert/reducer';
import warnings from './warnings/reducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default combineReducers<any, any>({
	changes,
	changesWarnings,
	commit,
	revert,
	hiddenIssues,
	warnings,
	requiredFields,
	changeStatus,
});
