import React from 'react';
import { formatDistanceToNow as distanceInWordsToNow } from 'date-fns';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { getLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import { weekdayFormat } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/constants.tsx';
import { formatTimestampWithIntl } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import messages from './messages';
import type { Props } from './types';

function useTooltip({ direction, baselineStart, baselineEnd, timelineRange }: Props) {
	const locale = useLocale();
	const intl = useIntl();

	const formatDate = (value: number) => formatTimestampWithIntl(intl, value, weekdayFormat);

	const localeOptions = {
		locale: getLocale(locale),
	};

	switch (direction) {
		case 'left':
			if (
				isDefined(baselineStart) &&
				baselineStart < timelineRange.start &&
				isDefined(baselineEnd) &&
				baselineEnd < timelineRange.start
			) {
				return intl.formatMessage(commonMessages.datesOutsideRange);
			}

			if (isDefined(baselineStart) && baselineStart < timelineRange.start) {
				return intl.formatMessage(messages.startDateAgo, {
					date: formatDate(baselineStart),
					dateDistance: distanceInWordsToNow(baselineStart, localeOptions),
				});
			}

			if (isDefined(baselineEnd) && baselineEnd < timelineRange.start) {
				return intl.formatMessage(messages.endDateAgo, {
					date: formatDate(baselineEnd),
					dateDistance: distanceInWordsToNow(baselineEnd, localeOptions),
				});
			}

			return '';

		case 'right':
			if (
				isDefined(baselineStart) &&
				baselineStart > timelineRange.end &&
				isDefined(baselineEnd) &&
				baselineEnd > timelineRange.end
			) {
				return intl.formatMessage(commonMessages.datesOutsideRange);
			}

			if (isDefined(baselineStart) && baselineStart > timelineRange.end) {
				return intl.formatMessage(messages.startDateFromNow, {
					date: formatDate(baselineStart),
					dateDistance: distanceInWordsToNow(baselineStart, localeOptions),
				});
			}

			if (isDefined(baselineEnd) && baselineEnd > timelineRange.end) {
				return intl.formatMessage(messages.endDateFromNow, {
					date: formatDate(baselineEnd),
					dateDistance: distanceInWordsToNow(baselineEnd, localeOptions),
				});
			}

			return '';

		default:
			// prettier-ignore
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return (direction as never);
	}
}

export default function ArrowTooltip(props: Props) {
	const { direction, children } = props;
	const tooltip = useTooltip(props);

	return (
		<Tooltip content={tooltip} position={direction}>
			{children}
		</Tooltip>
	);
}
