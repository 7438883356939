import * as R from 'ramda';
import { statusColourMap } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colours';
import { createMigrate, incremental } from '../common/migrate';
import type { ColourByStateV0, ColourByStateV1, ColourByStateV2 } from './types';

export default createMigrate(
	...incremental(
		{
			V0: function fromV0ToV0(prevState: ColourByStateV0): ColourByStateV0 {
				return R.mergeDeepRight(prevState, { colourMaps: { status: statusColourMap } });
			},
			V1: function fromV0ToV1(prevState: ColourByStateV0): ColourByStateV1 {
				return { ...prevState, colourIssueTypes: [], colourPriorities: [] };
			},
			V2: function fromV1ToV2(prevState: ColourByStateV1): ColourByStateV2 {
				return { ...prevState, colourIssueTypeKeys: [] };
			},
		},
		{ prefix: 'colourBy', from: 'V0' },
	),
);
