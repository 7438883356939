import React, { Component } from 'react';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import { Bleed, Box, xcss } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import { token } from '@atlaskit/tokens';
import { injectIntl, FormattedMessage } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import IssueLink from '@atlassian/jira-portfolio-3-common/src/issue-link';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, State } from './types';

// eslint-disable-next-line jira/react/no-class-components
class InfoMessage extends Component<Props, State> {
	static defaultProps = {
		// using for story
		isDefaultFlyoutOpen: false,
	};

	state = {
		isFlyoutOpen: this.props.isDefaultFlyoutOpen,
	};

	toggleFlyout = () => {
		this.setState((state) => ({
			isFlyoutOpen: !state.isFlyoutOpen,
		}));
	};

	closeFlyout = () => {
		this.setState(() => ({
			isFlyoutOpen: false,
		}));
	};

	issueOptionRenderer = (issue: Issue, i: number) => {
		const { projectsById, issueTypesById } = this.props;
		const projectKey: string =
			(issue.project && projectsById[issue.project] && projectsById[issue.project].key) || '';
		const issueTypeIconUrl = issueTypesById[issue.type] && issueTypesById[issue.type].iconUrl;

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles['issue-option-wrapper']} key={`${i}-option`}>
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles['issue-type-icon']}
					style={{
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						backgroundSize: 'contain',
						backgroundImage: `url(${issueTypeIconUrl})`,
					}}
				/>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.link}>
					<IssueLink issueKey={issue.issueKey} projectKey={projectKey} />
				</div>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles['issue-label']} title={issue.summary}>
					{issue.summary}
				</div>
			</div>
		);
	};

	renderFlyout = (issues: Array<Issue>) => {
		const { intl } = this.props;
		return (
			<Box
				xcss={flyoutStyles}
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-date.info-message.div"
			>
				<Bleed block="space.100" inline="space.200" xcss={closeFlyoutStyles}>
					<Button
						appearance="subtle-link"
						iconBefore={
							<EditorCloseIcon
								label=""
								testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-date.info-message.editor-close-icon"
								primaryColor={token('color.text.subtle', colors.N200)}
							/>
						}
						onClick={() => this.closeFlyout()}
						spacing="none"
						aria-label={intl.formatMessage(commonMessages.close)}
					/>
				</Bleed>
				{issues.map(this.issueOptionRenderer)}
			</Box>
		);
	};

	renderInlineDialog = () => {
		const { intl, issuesWithoutDates } = this.props;

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles['inline-dialog-container']}>
				<InlineDialog
					content={this.renderFlyout(issuesWithoutDates)}
					isOpen={this.state.isFlyoutOpen}
					placement="bottom"
					noHorizontalScroll
					classNames="inline-dialog"
				>
					<Button
						appearance="link"
						spacing="none"
						onClick={() => this.toggleFlyout()}
						testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-date.info-message.button"
					>
						{intl.formatMessage(messages.linkLabel, {
							issueCount: issuesWithoutDates.length,
						})}
					</Button>
				</InlineDialog>
			</div>
		);
	};

	render() {
		const { issuesWithoutDates } = this.props;
		return issuesWithoutDates.length > 0 ? (
			<SectionMessage
				appearance="information"
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-date.info-message.info-message"
			>
				<FormattedMessage {...messages.infoMessageBeforeLink} />
				<FormattedMessage
					{...messages.infoMessageAfterLink}
					values={{
						issuesWithoutDates: this.renderInlineDialog(),
					}}
				/>
			</SectionMessage>
		) : null;
	}
}

export default injectIntl(InfoMessage);

const flyoutStyles = xcss({
	maxWidth: '480px',
	position: 'relative',
	paddingTop: 'space.100',
	paddingRight: 'space.150',
	paddingBottom: 'space.050',
	paddingLeft: '0',
});

const closeFlyoutStyles = xcss({
	position: 'absolute',
	top: '0',
	right: '0',
});
