import { useCallback, useMemo } from 'react';
import find from 'lodash/find';
import * as R from 'ramda';
import {
	INITIATIVE_LEVEL,
	EPIC_LEVEL,
} from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import {
	CttOnboardingVariant,
	type CttOnboardingVariantType,
} from '@atlassian/jira-portfolio-3-onboarding/src/controllers/ctt-onboarding/types';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { GROUPING } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import type { Project } from '../../state/domain/projects/types.tsx';
import { getInlineCreateIssueTypeId } from '../main/tabs/roadmap/util';
import { useRedirectToSpaPlanPage } from '../redirect-to-spa-plan-page';
import type { ChildProps, Props } from './types';

export const CttOnboardingPlanDataSubscriber = ({
	children,
	planId,
	setIssueTypeIdForHierarchy,
	setGlobalCreateIssue,
	enrichedHierarchyLevels,
	projects,
	startInlineCreate,
	setDefaultProject,
	defaultProjectId,
	typeToLevel,
	issueTypeId: currentIssueTypeId,
	IssueTypeIdForHierarchy,
	setGroupNone,
	setHierarchyLevels,
	expandScopeInHierarchy,
	visualisationGrouping,
	hierarchyRange,
	initativeLevelIssues,
	epicLevelIssues,
	clearInlineCreate,
	tmpIssueTypeIds,
}: Props) => {
	const redirectToSpaPlanPage = useRedirectToSpaPlanPage();

	const handleCreate = useCallback(
		(hierarchyLevel: number, project: Project) => {
			const issueTypeId = getInlineCreateIssueTypeId(hierarchyLevel, project, {
				issueTypeId: currentIssueTypeId,
				IssueTypeIdForHierarchy,
				typeToLevel,
			});
			setIssueTypeIdForHierarchy(hierarchyLevel, issueTypeId);
			setGlobalCreateIssue();

			const getProjectsForCurrentIssueTypeLevel = (level: number): Project[] => {
				const enrichedLevel = R.find(R.propEq('level', level), enrichedHierarchyLevels);
				const orderedIssueTypes = enrichedLevel ? enrichedLevel.issueTypes : [];
				const currentLevelIssueTypes = orderedIssueTypes.map(({ id }) => id);
				return projects.filter(
					// eslint-disable-next-line @typescript-eslint/no-shadow
					(project) => R.intersection(project.issueTypeIds, currentLevelIssueTypes).length > 0,
				);
			};

			const projectsForCurrentIssueTypeLevel = getProjectsForCurrentIssueTypeLevel(hierarchyLevel);

			if (projectsForCurrentIssueTypeLevel.length === 1) {
				setDefaultProject(project.id);
			}

			startInlineCreate({
				parentId: null,
				siblingId: null,
				hierarchyLevel,
				issueTypeId,
				projectId: project.id,
				source: 'HEADER',
			});
		},
		[
			IssueTypeIdForHierarchy,
			currentIssueTypeId,
			enrichedHierarchyLevels,
			projects,
			setDefaultProject,
			setGlobalCreateIssue,
			setIssueTypeIdForHierarchy,
			startInlineCreate,
			typeToLevel,
		],
	);

	const inlineCreate = useCallback(() => {
		const projectsForLevel = projects.filter((x) =>
			x.issueTypeIds.some((id: number) => typeToLevel(id) === INITIATIVE_LEVEL),
		);

		const project = find(projectsForLevel, ['id', defaultProjectId]) || projectsForLevel[0];

		if (!isDefined(project)) {
			throw new Error(
				'No project available for this level of hierarchy. This step should have been skipped.',
			);
		} else {
			handleCreate(INITIATIVE_LEVEL, project);
		}
	}, [defaultProjectId, handleCreate, projects, typeToLevel]);

	const primeRoadmap = useCallback(() => {
		expandScopeInHierarchy();
		setGroupNone();
		setHierarchyLevels({ start: hierarchyRange.max, end: hierarchyRange.min });
	}, [expandScopeInHierarchy, hierarchyRange, setGroupNone, setHierarchyLevels]);

	const hasInitiativeLevelConfigured = useMemo(
		() => enrichedHierarchyLevels.some((hl) => hl.level === INITIATIVE_LEVEL),
		[enrichedHierarchyLevels],
	);
	const hasEpicLevelConfiguredAndPopulated = useMemo(
		() =>
			enrichedHierarchyLevels.some((hl) => hl.level === EPIC_LEVEL) && epicLevelIssues.length > 0,
		[enrichedHierarchyLevels, epicLevelIssues.length],
	);
	const hasOnlyTmpEpics = useMemo(
		() => epicLevelIssues?.every((issue) => issue.type in tmpIssueTypeIds),
		[epicLevelIssues, tmpIssueTypeIds],
	);

	const hasAnyInitiatives = initativeLevelIssues?.length > 0;
	const hasEpicWithParent = useMemo(
		() => epicLevelIssues.some((issue) => issue.parent),
		[epicLevelIssues],
	);
	const isRoadmapGroupedByNone = visualisationGrouping === GROUPING.NONE;

	const cttOnboardingVariant: CttOnboardingVariantType = useMemo(() => {
		if (!hasInitiativeLevelConfigured) return CttOnboardingVariant.NO_INITIATIVE;
		if (!hasEpicLevelConfiguredAndPopulated) return CttOnboardingVariant.NO_EPIC;
		if (hasOnlyTmpEpics) return CttOnboardingVariant.TMP_EPIC;
		return CttOnboardingVariant.EPIC;
	}, [hasEpicLevelConfiguredAndPopulated, hasInitiativeLevelConfigured, hasOnlyTmpEpics]);

	const childrenProps: ChildProps = {
		planId,
		variant: cttOnboardingVariant,
		hasAnyInitiatives,
		hasEpicWithParent,
		isRoadmapGroupedByNone,
		setInlineCreate: inlineCreate,
		setClearInlineCreate: clearInlineCreate,
		setRedirectToSpaPlanPage: redirectToSpaPlanPage,
		setPrimeRoadmap: primeRoadmap,
	};

	return children(childrenProps);
};
