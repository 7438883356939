import React, { Component } from 'react';
import * as R from 'ramda';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, { ModalHeader, ModalTitle, ModalBody } from '@atlaskit/modal-dialog';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { injectIntl } from '@atlassian/jira-intl';
import type { Version } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/versions/types.tsx';
import { RELEASE_STATUSES } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import { createVersionOption } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/versions/utils';
import { MODAL_DIALOG_WIDTH, SET_RELEASE } from '../../types';
import messages from './messages';
import type { Props } from './types';

// eslint-disable-next-line jira/react/no-class-components
class SetVersionsDialog extends Component<Props> {
	getCurrentValue = () => {
		const {
			intl: { formatMessage },
			selectedIssues,
			versionsById,
		} = this.props;

		const firstValue = selectedIssues[0].fixVersions || [];
		const isSameValue = !selectedIssues.some(
			(issue) => !R.equals(issue.fixVersions || [], firstValue),
		);

		if (isSameValue && !firstValue.length) {
			return formatMessage(commonMessages.none);
		}

		if (isSameValue && firstValue.length) {
			return firstValue.map((versionId) => versionsById[versionId].name).join(', ');
		}
		return formatMessage(messages.variousValues);
	};

	getUnreleasedOptionsGroup(versions: Version[]) {
		const {
			intl: { formatMessage },
			crossProjectVersionsById,
			sortVersionsByLowestRank,
		} = this.props;

		const unreleasedVersions = R.filter(
			(version) => version.releaseStatusId !== RELEASE_STATUSES.RELEASED,
			versions,
		);
		return {
			label: formatMessage(messages.unreleased),
			options: R.map(
				(version) => createVersionOption(version, crossProjectVersionsById),
				sortVersionsByLowestRank(unreleasedVersions),
			),
		};
	}

	getReleasedOptionsGroup(versions: Version[]) {
		const {
			intl: { formatMessage },
			crossProjectVersionsById,
			sortVersionsByHighestRank,
		} = this.props;

		const ReleasedVersions = R.filter(
			(version) => version.releaseStatusId === RELEASE_STATUSES.RELEASED,
			versions,
		);
		return {
			label: formatMessage(messages.released),
			options: R.map(
				(version) => createVersionOption(version, crossProjectVersionsById),
				sortVersionsByHighestRank(ReleasedVersions),
			),
		};
	}

	getOptions = () => {
		const { versions, selectedIssues } = this.props;
		const projectOfIssues = selectedIssues[0].project;
		const versionsInProject = versions.filter((version) =>
			version.projects.includes(projectOfIssues),
		);

		return [
			this.getReleasedOptionsGroup(versionsInProject),
			this.getUnreleasedOptionsGroup(versionsInProject),
		] as const;
	};

	render() {
		const {
			intl: { formatMessage },
			toggleModalDialog,
			bulkUpdateAttribute,
			UpdateAttributeForm,
			bulkActionSuccess,
		} = this.props;
		return (
			<ShortcutScope>
				<ModalDialog
					shouldScrollInViewport
					onClose={toggleModalDialog}
					width={MODAL_DIALOG_WIDTH}
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-versions"
				>
					<ModalHeader>
						<ModalTitle>{formatMessage(messages.setReleaseHeader)}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<UpdateAttributeForm
							updatedAttributeType={SET_RELEASE}
							options={this.getOptions()}
							currentValue={this.getCurrentValue()}
							onCancel={toggleModalDialog}
							onSubmit={(formData: { [key: string]: string }, analyticsEvent: UIAnalyticsEvent) => {
								bulkUpdateAttribute(formData);
								toggleModalDialog();
								bulkActionSuccess(analyticsEvent);
							}}
						/>
					</ModalBody>
				</ModalDialog>
			</ShortcutScope>
		);
	}
}

export default injectIntl(SetVersionsDialog);
