import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { startOptimize } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/optimize';
import { getMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app';
import { getSelectedIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues';
import { getExistingTeams } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams';
import { OPTIMIZING } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import type {
	MapStateToProps,
	MapDispatchToPropsSimple,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import { connect } from '@atlassian/jira-react-redux';
import type { DispatchProps, OwnProps, StateProps } from './types';
import View from './view';

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state) => {
	const mode = getMode(state);
	return {
		showSpinner: mode === OPTIMIZING,
		selectedIssueCount: getSelectedIssues(state).length,
		existingTeams: getExistingTeams(state),
	};
};

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	onOptimize: (analyticsEvent: UIAnalyticsEvent) => {
		dispatch(startOptimize(analyticsEvent));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
