import React from 'react';
import * as R from 'ramda';
import { xcss } from '@atlaskit/primitives';
import IssueLink from '@atlassian/jira-portfolio-3-common/src/issue-link';
import { getIdForChangeCategory } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/title-bar/update-jira/utils';
import { indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { ENTITY } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

export default function IssueTitle({ projects, issueTypes, details }: Props) {
	const projectsByIdMap = indexBy(R.prop('id'), projects);
	const issueTypesByIdMap = indexBy(R.prop('id'), issueTypes);
	const {
		issueKey,
		values: { summary, project: projectId, type: issueTypeId },
		id,
	} = details;
	const projectKey = projectId ? projectsByIdMap[projectId]?.key || '' : '';
	const issueType = issueTypesByIdMap[issueTypeId];

	return (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles.issueTitle}
			id={getIdForChangeCategory(id, ENTITY.ISSUE)}
			data-testid={`portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.title.issue.issue-title-container-${id}`}
		>
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.issueIcon}
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					backgroundSize: 'contain',
					backgroundImage: issueType && `url(${issueType.iconUrl})`,
				}}
			/>
			<IssueLink projectKey={projectKey} issueKey={issueKey} xcssStyles={issueLinkStyles} />
			<EllipsedWithTooltip content={summary} id={id}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.issueSummary}>{summary}</div>
			</EllipsedWithTooltip>
		</div>
	);
}

const issueLinkStyles = xcss({
	whiteSpace: 'nowrap',
});
