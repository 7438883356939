/*
* the inlineDialog width is 496px, minus 48px padding
* labels get 170px width of which 16px of right padding
* filters get 496 - 48 - 170 = 278px width
* so each hierarchy filter button gets (278px - 8px (padding in-between)) = 270px / 2 = 135px width
*/
.hierarchyFilterLegacy {
	align-items: center;
	display: inline-flex;

	& button,
	& button:focus,
	& button:hover,
	& button:active {
		text-align: left;
		width: 135px;
	}
}

.hierarchyFilter {
	align-items: center;
	display: inline-flex;

	/* Menu Triggers */
	& button[aria-haspopup] {
		text-align: left;
		width: 135px;
	}

	/* Menu items */
	& button {
		min-width: 135px;
	}
}

.separator {
	padding: 0 calc(var(--jpo-common-base-spacing) / 2);
}
