import { getIssueSourcesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { ISSUE_SOURCE_TYPES } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import type { OwnProps } from './types';

export default createSelector(
	[getIssueSourcesById, (_: State, ownProps: OwnProps) => ownProps],
	(issueSourcesById, ownProps) => {
		const { sprint } = ownProps;
		const issueSourceId = sprint.issueSources && sprint.issueSources[0];
		const issueSource = issueSourcesById[issueSourceId];
		const boardId =
			issueSource && issueSource.type === ISSUE_SOURCE_TYPES.BOARD ? issueSource.value : undefined;

		return {
			boardId,
		};
	},
);
