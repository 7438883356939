import * as R from 'ramda';
import { isDefined, groupBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import {
	NUM_ISSUES_IN_EXPORT_PREVIEW,
	GROUPING,
	SCOPE_ROW_HEIGHT,
	type Grouping,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import type {
	Group,
	GroupedScope,
	Scope,
	ScopeIssue,
	GroupCombination,
} from '../../state/domain/scope/types';
import type { InlineCreateState } from '../../state/ui/main/tabs/roadmap/scope/inline-create/types.tsx';
import { isExportPreview } from '../app';
import { getInlineCreateState } from '../inline-create';
import { getScope, getGroupAttribute, getActiveSearchIssue } from '../scope';
import type { SearchMatch } from '../scope/types';
import { getGroupKey } from '../scope/util.tsx';
import { getSprintStreamsForTeams } from '../sprints';
import type { SprintStreamsForTeams } from '../sprints/types.tsx';

/* A short tale about table items

getScope query returns an intermediate representation of the main content accessible via roadmap
tab: issues in scope. Filtered, grouped, sorted and enhanced with presentation-specific data like
an isExpanded flag. This representation is very useful to keep all views like sections in sync as
they could derive all necessary structure from it and enhance it further with view-specific data by
joining with other queries.

getTableItems query aims to make this data even more presentation-specific. All sections at the
moment use a virtualized table as the basis of presentation. It demands ultimately regular
structure (array) of potentially heterogeneous items (like an issue, group header, inline create)
while getScope provides non-regular structure (custom tree) of homogeneous items (issues).
getTableItems query returns array of tagged variants in form of object {tag, value} which allows
explicit and fast matching in table's rowRenderer.
*/

/**
 * A Jira issue. This could be an epic, story, subtask, etc.
 */
export const TABLE_ISSUE = 'app-simple-plans.query.table.ISSUE_TAG' as const;

/**
 * The inline create input. This is not always visible.
 */
export const TABLE_INLINE_CREATE = 'app-simple-plans.query.table.INLINE_CREATE_TAG' as const;

/**
 * A header, e.g. "8 issues without parent". The header is expandable, and contains TABLE_GROUP_HEADER items,
 * which further breaks down the issues.
 */
export const TABLE_HEADER = 'app-simple-plans.query.table.HEADER_TAG' as const;

/**
 * The group by grouping. This is usually the highest level in the issues list.
 */
export const TABLE_GROUP = 'app-simple-plans.query.table.GROUP_TAG' as const;

/**
 * A sub-section of the TABLE_HEADER, e.g. "Epic - 6 issues". These are expandable, and contains issues.
 */
export const TABLE_GROUP_HEADER = 'app-simple-plans.query.table.GROUP_HEADER_TAG' as const;

/**
 * A blank row that takes up space. There is usually one blank row at the bottom of each TABLE_GROUP.
 */
export const TABLE_BLANK_ROW = 'app-simple-plans.query.table.BLANK_ROW_TAG' as const;

/**
 * An example items layout could look like:
 *
 * TABLE_GROUP { value: 'Foo', grouping: 'project' }
 *   TABLE_ISSUE { level: STRATEGY_LEVEL }
 *     TABLE_ISSUE { level: INITIATIVE_LEVEL }
 *        TABLE_ISSUE { level: EPIC_LEVEL }
 *   TABLE_HEADER { value: '2 issues without parent' }
 *     TABLE_GROUP_HEADER { value: 'Epic - 1 issue' }
 *       TABLE_ISSUE { level: INITIATIVE_LEVEL }
 *     TABLE_GROUP_HEADER { value: 'Story - 1 issue' }
 *       TABLE_ISSUE { level: EPIC_LEVEL }
 *   TABLE_BLANK_ROW
 * TABLE_GROUP { value: 'Bar', grouping: 'project' }
 *   TABLE_HEADER { value: '1 issue without parent' }
 *     TABLE_GROUP_HEADER { value: 'Subtask - 1 issue' }
 *        TABLE_ISSUE { level 0 }
 *   TABLE_BLANK_ROW
 * TABLE_GROUP { value: 'Baz', grouping: 'project', isExpanded: false }
 */

export type GroupHeaderData = {
	id: string;
	isExpanded?: boolean;
	level: number;
	rootIssuesCount: number;
};

export type TableRow = {
	key: string;
	index: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	style: Record<any, any>;
	isScrolling: boolean;
};

const inlineCreateToDummyIssue = (
	inlineCreate: Omit<InlineCreateState, 'values'> & {
		values: Omit<InlineCreateState['values'], 'project'> &
			Partial<Pick<InlineCreateState['values'], 'project'>>;
	},
) => ({
	depth: 0,
	id: inlineCreate.id,
	lexoRank: '',
	level: inlineCreate.hierarchyLevel,
	rootIndex: 0,
	summary: '',
	project: inlineCreate.projectId,
	type: inlineCreate.issueTypeId,
	...inlineCreate.values,
});

export type TableItemCommonFields = {
	height?: number;
	parentGroup: string | null | undefined;
	topOffset: number;
};

export type IssueTableItem = TableItemCommonFields & {
	tag: typeof TABLE_ISSUE;
	value: ScopeIssue;
	group?: string;
	groupCombination?: GroupCombination;
};

export type InlineCreateTableItem = TableItemCommonFields & {
	tag: typeof TABLE_INLINE_CREATE;
	value: ScopeIssue;
	group?: string;
	groupCombination?: GroupCombination;
};

export type HeaderTableItem = TableItemCommonFields & {
	tag: typeof TABLE_HEADER;
	value: {
		id: string;
		isExpanded: boolean;
		issueCount: number;
	};
	group?: string;
	groupCombination?: GroupCombination;
};

export type GroupTableItem = TableItemCommonFields & {
	tag: typeof TABLE_GROUP;
	value: string;
	grouping: Grouping;
	url?: string;
	isExpanded: boolean;
	group: string;
	groupCombination: GroupCombination;
	childGroups?: Group<ScopeIssue>[] | null | undefined;
};

export type GroupHeaderTableItem = TableItemCommonFields & {
	tag: typeof TABLE_GROUP_HEADER;
	value: GroupHeaderData;
	group?: string;
	groupCombination?: GroupCombination;
};

export type BlankRowTableItem = TableItemCommonFields & {
	tag: typeof TABLE_BLANK_ROW;
	value: undefined;
	group?: string;
	groupCombination?: GroupCombination;
};

export type TableItem =
	| IssueTableItem
	| InlineCreateTableItem
	| HeaderTableItem
	| GroupTableItem
	| GroupHeaderTableItem
	| BlankRowTableItem;

export const inlineCreateProbe = (
	inlineCreateState: InlineCreateState,
	result: TableItem[],
	groupCombination: GroupCombination = {},
) => {
	const { isOpen, parentId, siblingId } = inlineCreateState;

	let inlineCreateDummyIssue: TableItem | null;

	return (issue?: ScopeIssue) => {
		if (!(isOpen && (parentId || siblingId))) {
			return;
		}

		const isSibling = isDefined(siblingId);
		const isAnchor = issue && (isSibling ? issue.id === siblingId : issue.id === parentId);

		if (
			issue &&
			isAnchor &&
			!(inlineCreateState.group && issue.group !== inlineCreateState.group) &&
			(!inlineCreateState.groupCombination ||
				R.equals(groupCombination, inlineCreateState.groupCombination))
		) {
			// @ts-expect-error - Type '{ tag: "app-simple-plans.query.table.INLINE_CREATE_TAG"; value: { level: number; depth: number; rootIndex: number; color?: string | null | undefined; status?: string | undefined; description?: string | ... 1 more ... | undefined; ... 35 more ...; type: number; }; }' is not assignable to type 'TableItem | null'.
			inlineCreateDummyIssue = {
				tag: TABLE_INLINE_CREATE,
				value: {
					...inlineCreateToDummyIssue(inlineCreateState),
					level: issue.id === parentId ? issue.level - 1 : issue.level,
					depth: issue.id === parentId ? issue.depth + 1 : issue.depth,
					rootIndex: issue.rootIndex,
				},
			};
			return;
		}

		if (
			inlineCreateDummyIssue &&
			siblingId &&
			// @ts-expect-error - Object is possibly 'undefined'.
			!(issue && issue.level < inlineCreateDummyIssue.value.level)
		) {
			result.push({
				...inlineCreateDummyIssue,
				topOffset:
					(result.length ? result[result.length - 1]?.topOffset || 0 : 0) + SCOPE_ROW_HEIGHT,
			});
			inlineCreateDummyIssue = null;
		}

		if (
			inlineCreateDummyIssue &&
			parentId &&
			// @ts-expect-error - Object is possibly 'undefined'.
			!(issue && issue.level <= inlineCreateDummyIssue.value.level)
		) {
			result.push({
				...inlineCreateDummyIssue,
				topOffset:
					(result.length ? result[result.length - 1]?.topOffset || 0 : 0) + SCOPE_ROW_HEIGHT,
			});
			inlineCreateDummyIssue = null;
		}
	};
};

export const addScope = (
	result: TableItem[],
	{ startLevelIssues, issuesWithoutParent }: Scope<ScopeIssue> | GroupedScope<ScopeIssue>,
	inlineCreateState: InlineCreateState,
	groupName?: string,
	grouping: Grouping = GROUPING.NONE,
	groupUrl?: string,
	isExpanded?: boolean,
	group?: string,
	sprintStreamsForTeams?: SprintStreamsForTeams,
	groupCombination: GroupCombination = {},
	parentGroup?: string,
	isParentGroupExpanded?: boolean,
	childGroupsByParentMap?: {
		[key: string]: Group<ScopeIssue>[];
	},
): void => {
	if (isDefined(group) && isDefined(groupName)) {
		let height = 1;
		if (grouping === GROUPING.TEAM && sprintStreamsForTeams && group) {
			const streams =
				isDefined(groupCombination.team) && sprintStreamsForTeams[groupCombination.team];
			if (streams && streams.length > 0) {
				height = streams.length;
			}
		}

		let topOffset =
			(result.length ? result[result.length - 1]?.topOffset || 0 : 0) + SCOPE_ROW_HEIGHT * height;

		if (parentGroup) {
			if (!result.find((item) => item.group === parentGroup)) {
				result.push({
					tag: TABLE_GROUP,
					value: '',
					grouping,
					url: groupUrl,
					isExpanded: !!isParentGroupExpanded,
					group: parentGroup,
					parentGroup: undefined,
					height,
					topOffset,
					groupCombination: { [getGroupAttribute(grouping)]: parentGroup },
					childGroups: childGroupsByParentMap && childGroupsByParentMap[parentGroup],
				});
				topOffset += SCOPE_ROW_HEIGHT;
			}

			if (!isParentGroupExpanded) {
				return;
			}
		}

		result.push({
			tag: TABLE_GROUP,
			value: groupName,
			grouping,
			url: groupUrl,
			isExpanded: isExpanded || false,
			group,
			height,
			topOffset,
			groupCombination,
			parentGroup,
		});
	}

	const { parentId, siblingId, hierarchyLevel, isOpen } = inlineCreateState;
	const issuesWithoutParentCount = issuesWithoutParent
		.map(({ rootIssuesCount = 0 }) => rootIssuesCount)
		.reduce((a, x) => a + x, 0);

	if (startLevelIssues.issues.length + issuesWithoutParentCount === 0) {
		if (isOpen && (!isDefined(group) || group === inlineCreateState.group)) {
			result.push({
				tag: TABLE_INLINE_CREATE,
				value: inlineCreateToDummyIssue(inlineCreateState),
				group,
				groupCombination,
				parentGroup,
				topOffset:
					(result.length ? result[result.length - 1]?.topOffset || 0 : 0) + SCOPE_ROW_HEIGHT,
			});

			result.push({
				tag: TABLE_BLANK_ROW,
				value: undefined,
				group,
				groupCombination,
				parentGroup,
				topOffset:
					(result.length ? result[result.length - 1]?.topOffset || 0 : 0) + SCOPE_ROW_HEIGHT,
			});
		}

		return;
	}

	const tryToInsertInlineCreate = inlineCreateProbe(inlineCreateState, result, groupCombination);

	for (const issue of startLevelIssues.issues) {
		tryToInsertInlineCreate(issue);

		result.push({
			tag: TABLE_ISSUE,
			value: issue,
			group,
			groupCombination,
			parentGroup,
			topOffset: (result.length ? result[result.length - 1]?.topOffset || 0 : 0) + SCOPE_ROW_HEIGHT,
		});
	}

	const isTheSameGroup =
		!inlineCreateState.groupCombination ||
		getGroupKey(inlineCreateState.groupCombination) === group;

	if (
		isOpen &&
		!(parentId || siblingId) &&
		startLevelIssues.level === hierarchyLevel &&
		isTheSameGroup
	) {
		// Insert at the bottom of the level when creating from the header
		result.push({
			tag: TABLE_INLINE_CREATE,
			value: inlineCreateToDummyIssue(inlineCreateState),
			group,
			groupCombination,
			parentGroup,
			topOffset: (result.length ? result[result.length - 1]?.topOffset || 0 : 0) + SCOPE_ROW_HEIGHT,
		});
	}
	tryToInsertInlineCreate();

	issuesWithoutParent.forEach(
		({ id: groupId, isExpanded: isGroupExpanded, level, issues, rootIssuesCount }) => {
			const shouldRenderInlineCreate =
				isOpen && !(parentId || siblingId) && level === hierarchyLevel && isTheSameGroup;

			if (issues.length === 0 && !shouldRenderInlineCreate) return;

			result.push({
				tag: TABLE_GROUP_HEADER,
				group,
				value: { id: groupId, isExpanded: isGroupExpanded, level, rootIssuesCount },
				groupCombination,
				parentGroup,
				topOffset:
					(result.length ? result[result.length - 1]?.topOffset || 0 : 0) + SCOPE_ROW_HEIGHT,
			});

			if (isGroupExpanded) {
				issues.forEach((issue) => {
					tryToInsertInlineCreate(issue);

					result.push({
						tag: TABLE_ISSUE,
						value: issue,
						group,
						groupCombination,
						parentGroup,
						topOffset:
							(result.length ? result[result.length - 1]?.topOffset || 0 : 0) + SCOPE_ROW_HEIGHT,
					});
				});
				tryToInsertInlineCreate();
			}

			if (isOpen && !(parentId || siblingId) && level === hierarchyLevel && isTheSameGroup) {
				// Insert at the bottom of the level when creating from the header
				result.push({
					tag: TABLE_INLINE_CREATE,
					group,
					value: inlineCreateToDummyIssue(inlineCreateState),
					groupCombination,
					parentGroup,
					topOffset:
						(result.length ? result[result.length - 1]?.topOffset || 0 : 0) + SCOPE_ROW_HEIGHT,
				});
			}
		},
	);

	result.push({
		tag: TABLE_BLANK_ROW,
		value: undefined,
		group,
		groupCombination,
		parentGroup,
		topOffset: (result.length ? result[result.length - 1]?.topOffset || 0 : 0) + SCOPE_ROW_HEIGHT,
	});
};

export const getTableItemsPure = (
	scope: Scope<ScopeIssue>,
	inlineCreateState: InlineCreateState,
	sprintStreamsForTeams: SprintStreamsForTeams,
	exportPreview: boolean,
): TableItem[] => {
	const result: Array<TableItem> = [];

	const { groupCount = 0, groups = [] } = scope;
	const childGroupsByParentMap = groupBy(
		(group: Group<ScopeIssue>) => group.parentGroup || 'no_parent_groups',
		groups,
	);

	if (groupCount === 0) {
		addScope(result, scope, inlineCreateState);
	} else if (groupCount > 0) {
		groups.forEach((groupedScope) => {
			const {
				// eslint-disable-next-line @typescript-eslint/no-shadow
				scope,
				groupName,
				grouping,
				groupUrl,
				isExpanded,
				group,
				groupCombination,
				parentGroup,
				isParentGroupExpanded,
			} = groupedScope;
			return addScope(
				result,
				scope,
				inlineCreateState,
				groupName,
				grouping,
				groupUrl,
				isExpanded,
				group,
				sprintStreamsForTeams,
				groupCombination,
				parentGroup,
				isParentGroupExpanded,
				childGroupsByParentMap,
			);
		});
	}

	// In export preview we only return the first 20 issues and their headers
	if (exportPreview) {
		let issuesCounted = 0;
		let indexToSliceAt = NUM_ISSUES_IN_EXPORT_PREVIEW;
		for (let i = 0; i < result.length; i++) {
			if (result[i].tag === TABLE_ISSUE) {
				issuesCounted++;
				if (issuesCounted === NUM_ISSUES_IN_EXPORT_PREVIEW) {
					indexToSliceAt = i;
					break;
				}
			}
		}
		return result.slice(0, indexToSliceAt + 1);
	}

	return result;
};

export const getTableItems = createSelector(
	[getScope, getInlineCreateState, getSprintStreamsForTeams, isExportPreview],
	getTableItemsPure,
);

export const getScopeHeight = createSelector([getTableItems], (tableItems) =>
	tableItems.reduce((scopeHeight, { height = 1 }) => scopeHeight + height * SCOPE_ROW_HEIGHT, 0),
);

export const getScrollToIndexPure = (
	items: TableItem[],
	{ isOpen }: InlineCreateState,
	activeSearchIssue?: SearchMatch | null,
) => {
	if (isOpen) {
		return R.findIndex<TableItem>(R.propEq<'tag', string>('tag', TABLE_INLINE_CREATE))(items);
	}

	if (activeSearchIssue) {
		const index = R.findIndex<TableItem>(({ value, group = '' }) => {
			if (R.propEq<'tag', string>('tag', TABLE_ISSUE) && typeof value === 'object') {
				return value.id === activeSearchIssue.id && group === activeSearchIssue.group;
			}
			return false;
		})(items);

		return index > -1 ? index : undefined;
	}
};

export const getScrollToIndex = createSelector(
	[getTableItems, getInlineCreateState, getActiveSearchIssue],
	getScrollToIndexPure,
);

export const getDisableDnD = createSelector([getInlineCreateState], ({ isOpen }) => isOpen);
