import React from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import type { Props } from './types';

export const FieldUserAvatarCell = ({ title, avatarUrl }: Props) => (
	<Tooltip delay={1000} content={title}>
		<ReadViewContainer>
			{isDefined(avatarUrl) ? (
				<AvatarContainer>
					<Avatar borderColor="transparent" src={avatarUrl} size="xsmall" />
				</AvatarContainer>
			) : null}
			<NameWrapper>
				<span>{title}</span>
			</NameWrapper>
		</ReadViewContainer>
	</Tooltip>
);

export default FieldUserAvatarCell;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled.div({
	alignItems: 'center',
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NameWrapper = styled.div({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	lineHeight: '20px',
	verticalAlign: 'middle',
	display: 'inline-block',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarContainer = styled.div({
	height: '20px',
	margin: token('space.075', '6px'),
	padding: token('space.050', '4px'),
});
