import { getCustomFields } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields';
import { getIssuePrioritiesIdList } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-priorities';
import {
	getDateConfiguration,
	getPlanningUnit,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan';
import { getVisualisationSorting } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export default createStructuredSelector<State, StateProps>({
	customFields: getCustomFields,
	sorting: getVisualisationSorting,
	dateConfiguration: getDateConfiguration,
	planningUnit: getPlanningUnit,
	issuePrioritiesIdList: getIssuePrioritiesIdList,
});
