import {
	OPEN_DIALOG,
	CLOSE_DIALOG,
	INITIATE_REQUEST,
	RESET_REQUEST,
	type OpenDialogAction,
	type CloseDialogAction,
	type InitiateRequsetAction,
	type ResetRequsetAction,
} from './actions';
import type { AlignDatesDialog } from './types';

const initialState: AlignDatesDialog = {
	isDialogOpen: false,
	versionId: '',
	isProcessingRequest: false,
};

type Action = OpenDialogAction | CloseDialogAction | InitiateRequsetAction | ResetRequsetAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: AlignDatesDialog = initialState, action: Action) => {
	switch (action.type) {
		case OPEN_DIALOG:
			return {
				...state,
				isDialogOpen: true,
				versionId: action.versionId,
			};
		case CLOSE_DIALOG:
			return initialState;
		case INITIATE_REQUEST:
			return {
				...state,
				isProcessingRequest: true,
			};
		case RESET_REQUEST:
			return {
				...state,
				isProcessingRequest: false,
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
