import React, { useCallback, useState, type ComponentType } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type { StatusTransition } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch';
import messages from './messages';
import type { OwnProps, DispatchProps, Props } from './types';
import {
	getFilteredOrderedTransitions,
	addOriginalTransitions,
	removeTransitionsToCurrentAndOriginalStatus,
} from './utils';

export const transitionsProvider = (ComposedComponent: ComponentType<Props>) =>
	function (props: OwnProps & DispatchProps) {
		const { genericError, issueKey, issueStatuses, issue } = props;
		const { formatMessage } = useIntl();
		const [loading, setLoading] = useState(false);
		const [transitions, setTransitions] = useState<StatusTransition[] | null>(null);
		const fetchTransitions = useCallback(() => {
			if (loading) {
				return;
			}
			setLoading(true);
			setTransitions(null);

			fetch(`/rest/api/2/issue/${issueKey}/transitions?sortByOpsBarAndStatus=true`, {
				method: 'GET',
			})
				.then((response) => {
					setLoading(false);
					if (response.ok) {
						return response.json();
					}
					throw new Error(`Response not ok. Statuscode: ${response.status}`);
				}) // eslint-disable-next-line @typescript-eslint/no-explicit-any
				.then((result: any) => {
					const transitionsFromServer = getFilteredOrderedTransitions(
						result.transitions || [],
						issue.status,
					);
					setTransitions(
						addOriginalTransitions(
							issue,
							removeTransitionsToCurrentAndOriginalStatus(
								issue,
								transitionsFromServer,
								issueStatuses,
							),
							issueStatuses,
							formatMessage(messages.originalTransitionStatusName),
						),
					);
				})
				.catch((err) => {
					setLoading(false);
					genericError({ message: err.message, stackTrace: err.stack });
					setTransitions(null);
				});
		}, [issueKey, issue, issueStatuses, loading, genericError, formatMessage]);

		return (
			<ComposedComponent
				{...props}
				transitions={transitions}
				loading={loading}
				fetchTransitions={fetchTransitions}
			/>
		);
	};
