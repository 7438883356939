import * as R from 'ramda';
import type { Plan } from '../../state/domain/plan/types';
import type { Sequence } from '../../state/domain/sequence/types';
import { sequenceKeys } from '../update-jira/api';
import type { BulkCommitRequestEntity, BulkCommitRequest } from './types';

export const bulkCommitUrl = '/rest/jpo/1.0/bulk-commit';

export const bulkCommitBody = (
	{ id: planId, currentScenarioId: scenarioId }: Plan,
	sequence: Sequence,
	orderedCommitEntities: BulkCommitRequestEntity[],
	// Defaults to true, we want to notify watchers
	notifyWatchers = true,
) => {
	if (!planId || !scenarioId) {
		throw new Error('Need both plan and scenario to commit or get changes');
	}
	const payload: BulkCommitRequest = {
		planId,
		scenarioId,
		sequence: R.fromPairs(
			sequenceKeys.map((key) => [key, { current: sequence[key]?.current ?? 0 }]),
		),
		orderedCommitEntities,
		notifyWatchers,
	};

	return payload;
};
