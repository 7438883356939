import type { Effect } from 'redux-saga';
import { fork, takeEvery, put, select, call, takeLatest } from 'redux-saga/effects';
import {
	GROUPING,
	UNDEFINED_KEY,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import { getHierarchyFilter } from '../../query/filters/hierarchy-filter';
import { getFilteredIssuesWithHierarchy, getParentAndDescendant } from '../../query/issues';
import type { IssueMap } from '../../query/issues/types';
import { getIssueMapById } from '../../query/raw-issues/index.tsx';
import { getDescendantsByParent } from '../../query/raw-issues/issues-tree';
import {
	getIssuesByGroup,
	getActiveSearchIssue,
	getGroupCombinationByIssueId,
} from '../../query/scope';
import type { IssuesByGroup, SearchMatch } from '../../query/scope/types';
import { getGroupKey } from '../../query/scope/util.tsx';
import { getVisualisationGrouping } from '../../query/visualisations';
import { setIsExpanded } from '../../state/domain/view-settings/issue-expansions/actions.tsx';
import { toggleExpandGroup } from '../../state/domain/view-settings/visualisations/actions';
import batch from '../batch';
import { expandAllAnalytics, collapseAllAnalytics } from '../issue';

export const TOGGLE_EXPANSION_IN_HIERARCHY = 'command.scope.TOGGLE_EXPANSION_IN_HIERARCHY' as const;

export const EXPAND_ISSUE_WITH_ANCESTORS = 'command.scope.EXPAND_ISSUE_WITH_ANCESTORS' as const;

export type ToggleExpansionInHierarchyPayload = {
	isExpanded: boolean;
};

export type ExpandIssueWithAncestorsPayload = {
	issueId: string;
};

export type ToggleExpansionInHierarchyAction = {
	type: typeof TOGGLE_EXPANSION_IN_HIERARCHY;
	payload: ToggleExpansionInHierarchyPayload;
};

export type ExpandIssueWithAncestorsAction = {
	type: typeof EXPAND_ISSUE_WITH_ANCESTORS;
	payload: ExpandIssueWithAncestorsPayload;
};

export const toggleExpansionInHierarchy = (payload: ToggleExpansionInHierarchyPayload) => ({
	type: TOGGLE_EXPANSION_IN_HIERARCHY,
	payload,
});

export function* doToggleExpansionIssuesInHierarchy({
	payload: { isExpanded }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: ToggleExpansionInHierarchyAction): Generator<Effect, any, any> {
	const filteredIssuesWithHierarchy: ReturnType<typeof getFilteredIssuesWithHierarchy> =
		yield select(getFilteredIssuesWithHierarchy);
	const visualisationGrouping = (yield select(getVisualisationGrouping)) || GROUPING.NONE;
	const hierarchyFilter = yield select(getHierarchyFilter);
	const descendantsByParent = yield select(getDescendantsByParent);
	yield* batch(function* () {
		const collapseIssues: Record<string, boolean> = {};
		const headerIds = ['IssuesWithoutParentHeader'];
		for (let i = hierarchyFilter.value.end; i <= hierarchyFilter.value.start; i++) {
			headerIds.push(`IssuesWithoutParent-${i}`);
		}
		if (visualisationGrouping === GROUPING.NONE) {
			for (const issue of filteredIssuesWithHierarchy) {
				// If the issue doesn't have children, then no need to store the expand/collapse state
				if ((descendantsByParent[issue.id] || []).length > 0) {
					collapseIssues[issue.id] = isExpanded;
				}
			}
			for (const header of headerIds) {
				collapseIssues[header] = isExpanded;
			}
		} else {
			const issuesByGroup: IssuesByGroup = yield select(getIssuesByGroup);
			const parentGroups = new Set<string>();
			const filteredIssueIds = new Set(filteredIssuesWithHierarchy.map((x) => x.id));
			for (const group of Object.values(issuesByGroup)) {
				const { issues = new Set(), parentGroup, ...groupCombination } = group;
				for (const issue of issues) {
					if (
						// Prevent expansion/collapse of parent issues which do not actually appear in scope but are in issuesByGroup because of ancestor/descendant completion
						filteredIssueIds.has(issue.id) &&
						// If the issue doesn't have children, then no need to store the expand/collapse state
						(descendantsByParent[issue.id] || []).length > 0
					) {
						collapseIssues[`${issue.id}:${getGroupKey(groupCombination) || UNDEFINED_KEY}`] =
							isExpanded;
					}
				}
				for (const header of headerIds) {
					collapseIssues[`${header}:${getGroupKey(groupCombination) || UNDEFINED_KEY}`] =
						isExpanded;
				}
				yield put(
					toggleExpandGroup({
						grouping: visualisationGrouping,
						groupName: getGroupKey(groupCombination),
						expand: isExpanded,
					}),
				);
				if (group.parentGroup) {
					parentGroups.add(group.parentGroup);
				}
			}
			for (const parentGroup of parentGroups) {
				yield put(
					toggleExpandGroup({
						grouping: visualisationGrouping,
						groupName: parentGroup,
						expand: isExpanded,
					}),
				);
			}
		}
		yield put(setIsExpanded(collapseIssues));
	});
	const hierarchyRange = hierarchyFilter.value.start - hierarchyFilter.value.end;
	if (isExpanded) {
		yield put(expandAllAnalytics({ hierarchyLevels: hierarchyRange }));
	} else {
		yield put(collapseAllAnalytics({ hierarchyLevels: hierarchyRange }));
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchToggleExpansionIssuesInHierarchy(): Generator<Effect, any, any> {
	yield takeEvery(TOGGLE_EXPANSION_IN_HIERARCHY, doToggleExpansionIssuesInHierarchy);
}

export const expandIssueWithAncestors = () => ({
	type: EXPAND_ISSUE_WITH_ANCESTORS,
});

export const getIssuesWithoutParentHeaderKeys = (issueLevel: number, hierarchyStart: number) => {
	const headerIds = issueLevel < hierarchyStart ? ['IssuesWithoutParentHeader'] : [];
	for (let i = issueLevel; i < hierarchyStart; i++) {
		headerIds.push(`IssuesWithoutParent-${i}`);
	}
	return headerIds;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doExpandIssueWithAncestors(): Generator<Effect, any, any> {
	const activeSearch: SearchMatch | undefined = yield select(getActiveSearchIssue);
	if (!activeSearch) {
		return;
	}

	const { id: issueId, group: activeGroupName } = activeSearch;
	const { ancestors = [] }: ReturnType<typeof getParentAndDescendant>[string] =
		(yield select(getParentAndDescendant))[issueId] || {};

	const issueMapById: IssueMap = yield select(getIssueMapById);
	const issue = issueMapById[issueId];

	// A short tale about why we're double-checking for ancestors even if the getParentAndDescendant selector
	// returned no ancestors for the issue...
	// When filters are applied in the plan, a parent issue may match the filters but not its children.
	// If the "Show Full Hierarchy" option is enabled in the Filters menu, the children will be greyed out BUT
	// displayed in the plan, therefore they can be matched by the search query
	// From the getParentAndDescendant selector perspective, these children are filtered out of the plan,
	// therefore the selector does not look for ancestors or descendants for these issues.
	// However, in the context of issue search, we need those ancestors in order to expand the hierarchy
	// of the greyed out children issues matched by the search query when the user navigates through the results
	if (ancestors.length === 0) {
		let issueParentId = issue?.parent;
		// traversing the parent layers back up to the top
		while (issueParentId && issueMapById[issueParentId]) {
			ancestors.push(issueMapById[issueParentId]);
			issueParentId = issueMapById[issueParentId].parent;
		}
	}

	const maxHierarchy = Math.max(...[...ancestors, issue].map(({ level }) => level));
	const { value: hierarchyValue } = yield select(getHierarchyFilter);

	const headerKeys = yield call(
		getIssuesWithoutParentHeaderKeys,
		maxHierarchy,
		hierarchyValue.start,
	);

	let expandPayload: Record<string, boolean> | undefined;
	const visualisationGrouping = (yield select(getVisualisationGrouping)) || GROUPING.NONE;

	if (visualisationGrouping === GROUPING.NONE) {
		expandPayload = ancestors.reduce(
			(acc, { id }) =>
				Object.assign(acc, {
					[id]: true,
				}),
			{},
		);
		for (const header of headerKeys) {
			expandPayload[header] = true;
		}
	} else {
		const groupCombinationByIssue: ReturnType<typeof getGroupCombinationByIssueId> = yield select(
			getGroupCombinationByIssueId,
		);
		const parentGroups = new Set<string>();
		for (const { groupCombination, parentGroup } of groupCombinationByIssue[issueId]) {
			const groupName = getGroupKey(groupCombination);

			if (groupName === activeGroupName) {
				if (parentGroup) {
					parentGroups.add(parentGroup);
				}
				expandPayload = (ancestors || []).reduce(
					(acc, { id }) =>
						Object.assign(acc, {
							[`${id}:${groupName || UNDEFINED_KEY}`]: true,
						}),
					{},
				);
				for (const header of headerKeys) {
					expandPayload[`${header}:${groupName || UNDEFINED_KEY}`] = true;
				}

				for (const group of parentGroups) {
					yield put(
						toggleExpandGroup({
							grouping: visualisationGrouping,
							groupName: group,
							expand: true,
						}),
					);
				}

				yield put(
					toggleExpandGroup({
						grouping: visualisationGrouping,
						groupName,
						expand: true,
					}),
				);
			}
		}
	}

	if (expandPayload) {
		yield put(setIsExpanded(expandPayload));
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchExpandIssueWithAncestors(): Generator<Effect, any, any> {
	yield takeLatest(EXPAND_ISSUE_WITH_ANCESTORS, doExpandIssueWithAncestors);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchToggleExpansionIssuesInHierarchy);
	yield fork(watchExpandIssueWithAncestors);
}
