import { type SetStagePositionScaleAction, SET_STAGE_POSITION_SCALE } from './actions';
import type { StagePosAndScale } from './types';

const initialState: StagePosAndScale = {
	x: 0,
	y: 0,
	scale: 1,
};

type Action = SetStagePositionScaleAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: StagePosAndScale = initialState, action: Action): StagePosAndScale => {
	switch (action.type) {
		case SET_STAGE_POSITION_SCALE:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};
