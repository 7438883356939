import React from 'react';
import { DropdownItemGroup as DropMenuItemGroup } from '@atlaskit/dropdown-menu';
import { Inline, xcss } from '@atlaskit/primitives';
import { FormattedMessage } from '@atlassian/jira-intl';
import { DropMenuItem } from '@atlassian/jira-portfolio-3-common/src/drop-menu/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { isScenarioIssue as getIsScenarioIssue } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/utils/issue';
import { REMOVE_ISSUE } from '../../constants';
import messages from './messages';
import type { Props } from './types';

const RemoveIssue = ({
	hierarchy,
	isDisabled = false,
	issue: { level, id: issueId },
	setModalAction,
}: Props) => {
	const currentLevel = hierarchy.find((hierarchyLevel) => level === hierarchyLevel.level);
	if (!isDefined(currentLevel)) {
		return null;
	}

	const isScenarioIssue = getIsScenarioIssue(issueId);

	return (
		<DropMenuItemGroup>
			<DropMenuItem
				id={`remove-${level}`}
				onClick={() => setModalAction(REMOVE_ISSUE)}
				key={`remove-${level}`}
				isDisabled={isDisabled}
			>
				<Inline xcss={menuItemLabelStyles}>
					<FormattedMessage
						{...(isScenarioIssue ? messages.removeScenarioIssue : messages.removeSavedIssue)}
					/>
				</Inline>
			</DropMenuItem>
			{/* .
			Commenting out this option until inline delete is rolled out for Plans
			See: https://hello.atlassian.net/wiki/spaces/Spork/pages/4017365823/MAKE+PLVR-208+Allow+user+to+delete+unsaved+and+remove+saved+issue+directly+from+timeline
			<DropMenuItem
				id={`delete-${level}`}
				onClick={() => setModalAction(DELETE_ISSUE)}
				key={`delete-${level}`}
				isDisabled={isDisabled}
			>
				<Inline xcss={menuItemLabelStyles}>
					<FormattedMessage
						{...messages.deleteIssueNonFinal}
						values={{ issueType: hierarchyLevelTitle(currentLevel) }}
					/>
				</Inline>
			</DropMenuItem> */}
		</DropMenuItemGroup>
	);
};

export default RemoveIssue;

const menuItemLabelStyles = xcss({
	maxWidth: '215px',
	display: 'inline-block',
});
