import type { Dispatch } from 'redux';
import { updateIssueOrInlineCreate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue';
import { updateIssueSprint } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/sprint';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { Sprint as ApiSprint } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { DispatchProps } from './types';

export const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (
	dispatch: Dispatch,
) => ({
	// remove when cleaning up use_sprint_search_in_plan_dropdown
	onChangeOld: (issue: ScopeIssue, sprint?: string | null) => {
		dispatch(
			updateIssueOrInlineCreate({
				id: issue.id,
				sprint,
			}),
		);
	},
	onChange: (issue: ScopeIssue, value?: string | null, sprint?: ApiSprint) => {
		dispatch(
			updateIssueSprint({
				id: issue.id,
				value,
				sprint,
			}),
		);
	},
});
