import React, { Component } from 'react';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { formatDateWithIntl } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import { getDetailChange } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams/changes';
import type {
	PlannedCapacity as PlannedCapacityType,
	Issue as ApiIssue,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import {
	TARGET_START_FIELD,
	TARGET_END_FIELD,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { injectIntlWithDefaultProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/inject-intl-with-default-props';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import {
	ENTITY,
	SCENARIO_TYPE,
	ISSUE_LINK_DIRECTION,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip';
import ReleaseDate from '@atlassian/jira-portfolio-3-portfolio/src/common/view/versions/release-date';
import StartDate from '@atlassian/jira-portfolio-3-portfolio/src/common/view/versions/start-date';
import Assignee from './assignee';
import AssociatedIssues from './associated-issues';
import Components from './components';
import CrossProjectRelease from './cross-project-release';
import CustomField from './custom-field';
import Estimate from './estimate';
import Goals from './goals';
import IssueLink from './issue-link';
import Labels from './labels/view.tsx';
import messages from './messages';
import Priority from './priority';
import Project from './project';
import Release from './release';
import Sprint from './sprint';
import PlannedCapacity from './sprint-velocity/view.tsx';
import Status from './status';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import Team from './team';
import { type FromDefaultProps, type Props, type State, VALUE_TYPE } from './types';

// eslint-disable-next-line jira/react/no-class-components
class ValueChangeCell extends Component<Props, State> {
	static defaultProps: FromDefaultProps = {
		AssigneeComponent: Assignee,
		CrossProjectReleaseComponent: CrossProjectRelease,
		EstimateComponent: Estimate,
		IssueLinkComponent: IssueLink,
		ProjectComponent: Project,
		PriorityComponent: Priority,
		ReleaseComponent: Release,
		SprintComponent: Sprint,
		TeamComponent: Team,
		CustomFieldComponent: CustomField,
		PlannedCapacityComponent: PlannedCapacity,
		StatusComponent: Status,
		GoalsComponent: Goals,
		AssociatedIssuesComponent: AssociatedIssues,
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getValues = (): any => {
		const { change, valueType } = this.props;
		switch (change.category) {
			case ENTITY.ISSUE:
			case ENTITY.RELEASE:
			case ENTITY.CROSS_PROJECT_RELEASE:
			case ENTITY.SPRINT:
				if (valueType === VALUE_TYPE.CURRENT) {
					return change.details.originals;
				}
				return change.details.values;
			case ENTITY.TEAM:
			case ENTITY.RESOURCE: {
				const detailChange = getDetailChange(change);
				if (!detailChange) {
					return {};
				}

				if (valueType === VALUE_TYPE.CURRENT) {
					return {
						[detailChange.attributeName]: detailChange.originalValue,
					};
				}
				return {
					[detailChange.attributeName]: detailChange.currentValue,
				};
			}
			default:
				return {};
		}
	};

	render() {
		const { intl, change, expandedChanges } = this.props;
		const { attributeName, id, category, type, changeCount } = change;
		if (type === SCENARIO_TYPE.DELETED) {
			return null;
		}
		const values = this.getValues();
		const isExpanded = isDefined(expandedChanges) && expandedChanges[category].includes(id);
		const hasMultipleChanges =
			((type === SCENARIO_TYPE.ADDED || type === SCENARIO_TYPE.ADDEDEXISTING) && changeCount > 0) ||
			type === SCENARIO_TYPE.MULTIPLE;

		if (hasMultipleChanges && isExpanded) {
			return null;
		}

		if (hasMultipleChanges) {
			return (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				<span className={styles['multiple-label']}>
					{intl.formatMessage(messages.multipleChanges)}
				</span>
			);
		}

		if (isDefined(attributeName)) {
			const value = values[attributeName];
			if (attributeName.startsWith('issueLink-')) {
				if (!value) {
					return '-';
				}
				const {
					sourceItemKey,
					targetItemKey,
					// eslint-disable-next-line @typescript-eslint/no-shadow
					values: { type },
				} = value;
				const { IssueLinkComponent } = this.props;
				if (sourceItemKey === id) {
					return (
						<IssueLinkComponent
							issue={targetItemKey}
							linkType={type}
							direction={ISSUE_LINK_DIRECTION.OUTWARD}
						/>
					);
				}
				return (
					<IssueLinkComponent
						issue={sourceItemKey}
						linkType={type}
						direction={ISSUE_LINK_DIRECTION.INWARD}
					/>
				);
			}
			if (attributeName.startsWith('customField')) {
				if (!value) {
					return '-';
				}
				const { CustomFieldComponent } = this.props;
				// eslint-disable-next-line @typescript-eslint/no-shadow
				const { id, value: customFieldValue } = value;
				return (
					<CustomFieldComponent id={id} value={customFieldValue} attributeName={attributeName} />
				);
			}
			if (attributeName.startsWith('teamVelocity')) {
				const { PlannedCapacityComponent } = this.props;
				return (
					<PlannedCapacityComponent
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						plannedCapacity={values[attributeName] as PlannedCapacityType}
					/>
				);
			}

			switch (attributeName) {
				case 'assignee': {
					const { AssigneeComponent } = this.props;
					return <AssigneeComponent assignee={value} />;
				}

				case TARGET_START_FIELD:
				case TARGET_END_FIELD:
				case 'dueDate': {
					const date = value;
					return <span>{date ? formatDateWithIntl(intl, date) : '-'}</span>;
				}

				case 'iterationLength': {
					return (
						<span>
							{value !== undefined ? `${value}  ${intl.formatMessage(messages.weeks)}` : '-'}
						</span>
					);
				}

				case 'crossProjectVersion': {
					const crossProjectVersionId = value;
					const { CrossProjectReleaseComponent } = this.props;
					return (
						<span>
							{isDefined(crossProjectVersionId) ? (
								<CrossProjectReleaseComponent crossProjectVersionId={crossProjectVersionId} />
							) : (
								'-'
							)}
						</span>
					);
				}

				case 'end': {
					const content = <ReleaseDate end={value} emptyMessage="-" />;

					return (
						<div
							style={{
								// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
								paddingRight: token('space.050', '4px'),
							}}
						>
							<EllipsedWithTooltip content={content} id={value}>
								{content}
							</EllipsedWithTooltip>
						</div>
					);
				}

				case 'fixVersions': {
					const { ReleaseComponent } = this.props;
					return <ReleaseComponent fixVersions={value} />;
				}

				case 'lexoRank':
				case 'description': {
					// Never show a value for rank or description changes
					return null;
				}

				case 'parent': {
					const { IssueLinkComponent } = this.props;
					return <IssueLinkComponent issue={value} />;
				}

				case 'priority': {
					// eslint-disable-next-line @typescript-eslint/no-shadow
					const { PriorityComponent, change } = this.props;
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
					const details: ApiIssue = (change as any).details;
					return <PriorityComponent priority={value} project={details.values.project} />;
				}

				case 'project': {
					const { ProjectComponent } = this.props;
					return <ProjectComponent project={value} />;
				}

				case 'start': {
					const content = <StartDate start={value} delta={values.delta} emptyMessage="-" />;

					return (
						<div
							style={{
								// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
								paddingRight: token('space.050', '4px'),
							}}
						>
							<EllipsedWithTooltip content={content} id={value}>
								{content}
							</EllipsedWithTooltip>
						</div>
					);
				}

				case 'sprint': {
					const { SprintComponent } = this.props;
					return <SprintComponent sprint={value} />;
				}

				case 'status': {
					const { StatusComponent } = this.props;
					return <StatusComponent statusId={value} />;
				}

				case 'statusTransition': {
					return null;
				}

				case 'team': {
					const { TeamComponent } = this.props;
					return <TeamComponent team={value} />;
				}

				case 'timeEstimate': {
					const { EstimateComponent } = this.props;
					return <EstimateComponent timeEstimate={value} />;
				}

				case 'exceptions': {
					return <Text>-</Text>;
				}

				case 'components':
				case 'addedComponents':
				case 'removedComponents': {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
					const details: ApiIssue = (this.props.change as any).details;
					return (
						<Components
							project={details.values.project}
							components={value}
							isAdded={attributeName !== 'removedComponents'}
						/>
					);
				}
				case 'labels':
				case 'addedLabels':
				case 'removedLabels': {
					return <Labels labels={value || []} isAdded={attributeName !== 'removedLabels'} />;
				}

				case 'color': {
					return null;
				}

				case 'goals': {
					const { GoalsComponent } = this.props;
					return <GoalsComponent goals={value} />;
				}

				case 'associatedIssueIds': {
					const { AssociatedIssuesComponent } = this.props;
					return <AssociatedIssuesComponent associatedIssuesIds={value} />;
				}

				default: {
					const text = isDefined(value) ? value : '-';
					return (
						<EllipsedWithTooltip content={text}>
							<span>{text}</span>
						</EllipsedWithTooltip>
					);
				}
			}
		}
		return null;
	}
}

export default injectIntlWithDefaultProps(ValueChangeCell);
