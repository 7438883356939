import React from 'react';
import Toolbar from '@atlassian/jira-aais-timeline-toolbar/src/ui/container/index.tsx';
import type { ToolbarItem } from '@atlassian/jira-aais-timeline-toolbar/src/ui/container/types.tsx';
import FullscreenButton from '@atlassian/jira-fullscreen-button';
import { JiraBottomRightCornerOutlet } from '@atlassian/jira-layout-controller/src/ui/bottom-right-corner/outlet/index.tsx';
import { VIEW_MODES } from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import { useZoomLevel } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import Legend from '../../legend';
import PanningControl from '../../panning-control/view.tsx';
import TimeScale from '../../time-scale';
import TodayButton from '../../today-button';
import type { Props } from './types';

export const TableToolbar = ({
	isCollapsible,
	onToggleFullscreen,
	isFullscreen,
	viewMode,
}: Props) => {
	const [zoomLevel] = useZoomLevel();
	const toolbarItems: ToolbarItem[] = [
		{
			id: 'pan-left-right',
			element: (
				<>
					<PanningControl direction="left" />
					<PanningControl direction="right" />
				</>
			),
			isVisible: Boolean(zoomLevel && viewMode === VIEW_MODES.TIMELINE),
		},
		{
			id: 'today-button',
			element: <TodayButton />,
			isVisible: Boolean(zoomLevel && viewMode === VIEW_MODES.TIMELINE),
		},
		{
			id: 'timeline-mode-switcher',
			element: <TimeScale />,
			isVisible: viewMode === VIEW_MODES.TIMELINE,
		},
		{
			id: 'legend',
			element: <Legend />,
			isVisible: true,
		},
		{
			id: 'fullscreen-button',
			element: (
				<FullscreenButton
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.toolbar.fullscreen"
					onToggle={onToggleFullscreen}
					analyticsAttributes={{
						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						screenWidth: window.innerWidth,

						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						screenHeight: window.innerHeight,
					}}
					withContainerStyles={false}
					isFullscreen={isFullscreen}
					isSubtle={isVisualRefreshEnabled()}
				/>
			),
			isVisible: true,
		},
	];

	return (
		<JiraBottomRightCornerOutlet orderFromRight={2} id="portfolio-3-plans-table-overlay">
			<Toolbar items={toolbarItems} isCollapsible={isCollapsible} />
		</JiraBottomRightCornerOutlet>
	);
};
