import { token } from '@atlaskit/tokens';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import { Colors } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import messages from './messages';

export const priorityColourMap = [colors.R400, colors.Y400, colors.B400] as const;

export const statusColourMap = {
	'2': colors.N500, // todo
	'4': colors.B500, // in progress
	'3': colors.G500, // done
} as const;

export const colourPalette = [
	// Dark colours
	colors.P400,
	colors.B400,
	colors.T400,
	colors.G400,
	colors.Y400,

	// Light colours
	colors.R100,
	colors.P75,
	colors.B100,
	colors.T100,
	colors.G100,
	colors.Y100,

	// Dark red is at the end because it could be interpreted as a warning.
	// We don't want to remove it from palette, just make it less likely to be used.
	colors.R400,
] as const;

export const colourPickerPalette = [
	// Dark colours
	colors.R400,
	colors.P400,
	colors.B400,
	colors.T400,
	colors.G400,
	colors.Y400,

	// Light colours
	colors.R100,
	colors.P75,
	colors.B100,
	colors.T100,
	colors.G100,
	colors.Y100,
] as const;

export const colourPickerPaletteLabels: Record<string, MessageDescriptor> = {
	// Dark colours
	[colors.R400]: messages.r400,
	[colors.P400]: messages.p400,
	[colors.B400]: messages.b400,
	[colors.T400]: messages.t400,
	[colors.G400]: messages.g400,
	[colors.Y400]: messages.y400,

	// Light colours
	[colors.R100]: messages.r100,
	[colors.P75]: messages.p75,
	[colors.B100]: messages.b100,
	[colors.T100]: messages.t100,
	[colors.G100]: messages.g100,
	[colors.Y100]: messages.y100,

	// Grey colour
	[colors.N100]: messages.n100,
} as const;

export const colourPaletteTextColoursMap = {
	// Light font colour for dark colours
	[colors.R400]: colors.N0,
	[colors.P400]: colors.N0,
	[colors.B400]: colors.N0,
	[colors.T400]: colors.N0,
	[colors.G400]: colors.N0,

	// Dark font colour for light colours
	[colors.R100]: colors.N800,
	[colors.P75]: colors.N800,
	[colors.B100]: colors.N800,
	[colors.T100]: colors.N800,
	[colors.G100]: colors.N800,
	[colors.Y100]: colors.N800,
	[colors.Y400]: colors.N800,
} as const;

export const DEFAULT_COLOR = colors.N100;
export const OPTIMIZED_COLOR = colors.P300;

export const colourByOptions = {
	COMPONENT: 'component',
	EPIC: 'epic',
	ISSUE_TYPE: 'issueType',
	ISSUE_TYPE_KEY: 'issueTypeKey',
	LABEL: 'label',
	NONE: 'none',
	PRIORITY: 'priority',
	PROJECT: 'project',
	STATUS: 'status',
	TEAM: 'team',
} as const;

// Only used in JS code - hence we can use token here
export const statusBreakdownColourMap = {
	TODO: token('color.border', colors.N50),
	INPROGRESS: token('color.border.information', colors.B400),
	DONE: token('color.border.success', colors.G300),
};

export const epicColorToARJColor = {
	// Dark colours
	[Colors.DARK_ORANGE]: colors.R400,
	[Colors.DARK_PURPLE]: colors.P400,
	[Colors.DARK_BLUE]: colors.B400,
	[Colors.DARK_TEAL]: colors.T400,
	[Colors.DARK_GREEN]: colors.G400,
	[Colors.DARK_YELLOW]: colors.Y400,

	// Light colours
	[Colors.ORANGE]: colors.R100,
	[Colors.PURPLE]: colors.P75,
	[Colors.BLUE]: colors.B100,
	[Colors.TEAL]: colors.T100,
	[Colors.GREEN]: colors.G100,
	[Colors.YELLOW]: colors.Y100,

	// Extra colours
	[Colors.DARK_GREY]: colors.N400,
	[Colors.GREY]: colors.N100,
};
