import { createSelector } from 'reselect';
import * as R from 'ramda';
import type { IssueStatusCategory } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import type { State } from '../../state/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { IssueStatusCategory } from '../../../common/api/types.tsx';

export const getStatusCategories = (state: State) => state.domain.issueStatusCategories;

export const getStatusCategoryById = (state: State) =>
	indexBy<IssueStatusCategory, number>(R.prop('id'), state.domain.issueStatusCategories);

/**
 * Returns ordered Map of category id to category name. Custom order from back-end.
 */
export const getCategoryNameById = createSelector<
	State,
	IssueStatusCategory[],
	Map<string, string>
>(
	[getStatusCategories],
	(statusCategories) => new Map(statusCategories.map(({ id, name }) => [`${id}`, name])),
);
