import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { getIsGoalsLoading } from '../../query/issue-goals';
import { getIsIssuesLoading } from '../../query/issues';
import type { State } from '../../state/types';
import type { StateProps } from './types';

export default createStructuredSelector<State, StateProps>({
	isLoading: getIsIssuesLoading,
	isGoalsLoading: getIsGoalsLoading,
});
