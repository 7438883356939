import { changeSort } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/visualisations/actions.tsx';
import type { Sorting } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/visualisations/types.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { DispatchProps } from './types';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	changeSort: (sortBy: Sorting) => {
		dispatch(
			changeSort({
				sorting: sortBy,
			}),
		);
	},
});

export default mapDispatchToProps;
