import { getIssueSourcesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan';
import { getGroupOptions } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope';
import {
	getAllSprints,
	getAllSprintsByIdMap,
	getExternalSprintsById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export default createStructuredSelector<State, StateProps>({
	groups: getGroupOptions,
	sprints: getAllSprints,
	sprintsByIdMap: getAllSprintsByIdMap,
	externalSprintsById: getExternalSprintsById,
	issueSourcesById: getIssueSourcesById,
});
