import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';

export const NoIssuesInFilterEmptyView = () => {
	const { formatMessage } = useIntl();
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.emptyView}>
			<div>{formatMessage(messages.noMatchingIssues)}</div>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.line2}>{formatMessage(messages.adjustFilter)}</div>
		</div>
	);
};

export default NoIssuesInFilterEmptyView;
