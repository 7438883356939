import type { Effect } from 'redux-saga';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch';
import { indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import type { IssueId } from '@atlassian/jira-portfolio-3-portfolio/src/common/types';
import { getExternalIssues } from '../../query/external-issues';
import * as externalIssuesActions from '../../state/domain/external-issues/actions';
import { parseError } from '../api';
import { genericError } from '../errors';
import { urls } from './api';

export const LOAD_EXTERNAL_ISSUES = 'command.external-issues.LOAD_EXTERNAL_ISSUES' as const;

export type LoadExternalIssuesAction = {
	type: typeof LOAD_EXTERNAL_ISSUES;
	payload: IssueId[];
};

export const loadExternalIssues = (payload: IssueId[]): LoadExternalIssuesAction => ({
	type: LOAD_EXTERNAL_ISSUES,
	payload,
});

export function* doLoadExternalIssues({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: LoadExternalIssuesAction): Generator<Effect, any, any> {
	const requestedExternalIssues = yield select(getExternalIssues);
	// Do not request issues which were requested before.
	const issueIds = payload.filter((id) => !requestedExternalIssues[id]);
	// If response would miss some issues or if request would fail we should put nulls into state to
	// indicate failure. The easiest way is to have id => null map for all issues now and just merge
	// response into it later.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result = issueIds.reduce<Record<string, any>>((acc, id) => {
		acc[id] = null;
		return acc;
	}, {});
	const url = urls.get;
	const body = { issueIds };
	try {
		const response = yield call(fetch, url, {
			method: 'POST',
			body,
		});
		if (response.ok) {
			const { issues }: { issues: Array<{ id: string }> } = yield call(
				response.json.bind(response),
			);
			Object.assign(
				result,
				indexBy((x) => x.id, issues),
			);
		} else {
			yield put(
				genericError({
					...parseError(response, yield call(response.text.bind(response))),
					requestInfo: {
						url,
						type: 'POST',
						status: response.status,
						body,
					},
				}),
			);
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		yield put(genericError({ message: e.message, stackTrace: e.stack }));
	} finally {
		yield put(externalIssuesActions.add(result));
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchLoadExternalIssues(): Generator<Effect, any, any> {
	yield takeEvery(LOAD_EXTERNAL_ISSUES, doLoadExternalIssues);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchLoadExternalIssues);
}
