import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type {
	UpdateIssuePayload,
	UpdateMultipleIssuesPayload,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue/types';
import type { ProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/types.tsx';
import type { CustomField } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/custom-fields/types';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import type { DateConfiguration } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/plan/types.tsx';
import type { IssueType } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';

export const START_DATE = 'baselineStartField' as const;
export const END_DATE = 'baselineEndField' as const;
export const KEEP_EXISTING = 'KEEP_EXISTING' as const;
export const CLEAR_DATE = 'CLEAR_DATE' as const;
export const SET_BY_CHILD_DATES = 'SET_BY_CHILD_DATES' as const;
export const SET_CUSTOM_DATE = 'SET_CUSTOM_DATE' as const;
export const UPDATE_DATE_OPTIONS = [
	KEEP_EXISTING,
	CLEAR_DATE,
	SET_BY_CHILD_DATES,
	SET_CUSTOM_DATE,
] as const;

export type RollUpDates = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[parentId: string]: any;
};

export type StateProps = {
	childrenByParent: {
		[parentId: string]: Issue[];
	};
	customFieldsById: { [id: string]: CustomField };
	dateConfiguration: DateConfiguration;
	issueTypesById: {
		[key: string]: IssueType;
	};
	projectsById: ProjectsById;
	selectedIssues: Issue[];
};

export type Props = StateProps & {
	bulkActionSuccess: (analytics: UIAnalyticsEvent) => void;
	bulkUpdateAttribute: (arg1: UpdateIssuePayload) => void;
	toggleModalDialog: () => void;
	updateMultipleIssues: (arg1: UpdateMultipleIssuesPayload) => void;
};
