import { calculateStatusOfAllReleases } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/versions';
import { getRollupViewSettings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings';
import {
	setShowRolledUpDates,
	setShowRolledUpOthers,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/rollup-settings/actions';
import type {
	MapDispatchToPropsSimple,
	MapStateToProps,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import { connect } from '@atlassian/jira-react-redux';
import type { DispatchProps, StateProps } from './types';
import View from './view';

const mapStateToProps: MapStateToProps<StateProps> = (state) => ({
	rollupSettings: getRollupViewSettings(state),
});

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	setShowingRolledUpDates: (shouldShow: boolean) => {
		dispatch(
			setShowRolledUpDates({
				shouldShow,
			}),
		);
		dispatch(calculateStatusOfAllReleases());
	},
	setShowRolledUpOthers: (shouldShow: boolean) => {
		dispatch(
			setShowRolledUpOthers({
				shouldShow,
			}),
		);
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
