import React, { useCallback, useEffect, useRef } from 'react';
import Badge from '@atlaskit/badge';
import ButtonGroup from '@atlaskit/button/button-group';
import { Inline, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types';
import { QuickSelectOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/title-bar/update-jira/types';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import type { Entity } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';
import type { Props, Option } from './types';
import {
	getMyChanges,
	getTodaysChanges,
	getTimelineChanges,
	getAllChanges,
	filterChangesByToday,
	filterChangesByUserId,
	filterChangesBySelectedOnTimeline,
} from './utils';

const QuickSelectors = ({
	selectedIssues,
	changes,
	replaceSelection,
	userFilter,
	option,
	changeQuickSelect,
}: Props) => {
	const currentUserData = useCurrentUser().data.user;

	const isCurrentUserFilteredOut =
		userFilter.length > 0 &&
		isDefined(currentUserData.accountId) &&
		!userFilter.includes(currentUserData.accountId);
	const hasTimelineSelection = selectedIssues.size > 0;
	const defaultSelection = (() => {
		if (hasTimelineSelection) {
			return QuickSelectOption.TIMELINE;
		}
		if (!isCurrentUserFilteredOut) {
			return QuickSelectOption.MINE;
		}
		// User filter persists, so if the current user is filtered out, select 'none' as default value
		return QuickSelectOption.NONE;
	})();

	const isInitialRender = useRef<boolean>(true);
	const lastChanges = useRef<number>(changes.length);
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const applySelection = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-shadow
		(changes: Record<string, string[]>) => {
			replaceSelection(changes);
		},
		[replaceSelection],
	);

	const getSelectionsFromOption = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-shadow
		(option: QuickSelectOption): Record<Entity, string[]> => {
			let newSelections;
			switch (option) {
				case QuickSelectOption.MINE:
					newSelections = getMyChanges(changes, currentUserData.accountId);
					break;

				case QuickSelectOption.TODAY:
					newSelections = getTodaysChanges(changes);
					break;

				case QuickSelectOption.TIMELINE:
					newSelections = getTimelineChanges(changes, selectedIssues);
					break;

				default:
					newSelections = getAllChanges(changes);
					break;
			}
			return newSelections;
		},
		[changes, currentUserData.accountId, selectedIssues],
	);

	const selectIssuesOnOptionChange = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-shadow
		(option: QuickSelectOption) => {
			applySelection(getSelectionsFromOption(option));
		},
		[applySelection, getSelectionsFromOption],
	);

	useEffect(() => {
		if (isInitialRender.current) {
			isInitialRender.current = false;
			changeQuickSelect(defaultSelection);
			selectIssuesOnOptionChange(defaultSelection);
		}
	}, [changeQuickSelect, defaultSelection, selectIssuesOnOptionChange]);

	useEffect(() => {
		if (!isInitialRender.current && lastChanges.current !== changes.length) {
			if (option !== QuickSelectOption.NONE) {
				selectIssuesOnOptionChange(option);
			}
			lastChanges.current = changes.length;
		}
	}, [changes, selectIssuesOnOptionChange, option]);

	const getIssueCountFromOption = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-shadow
		(option: QuickSelectOption): number => {
			let issueCount = 0;
			switch (option) {
				case QuickSelectOption.MINE:
					({ length: issueCount } = filterChangesByUserId(changes, currentUserData.accountId));
					break;
				case QuickSelectOption.TODAY:
					({ length: issueCount } = filterChangesByToday(changes));
					break;

				case QuickSelectOption.TIMELINE:
					({ length: issueCount } = filterChangesBySelectedOnTimeline(changes, selectedIssues));
					break;

				default:
					break;
			}
			return issueCount;
		},
		[changes, currentUserData.accountId, selectedIssues],
	);

	const options: Option[] = [
		{
			value: QuickSelectOption.MINE,
			label: formatMessage(messages.changedByMeLabel),
			isDisabled: isCurrentUserFilteredOut,
			count: getIssueCountFromOption(QuickSelectOption.MINE),
		},
		{
			value: QuickSelectOption.TODAY,
			label: formatMessage(messages.changedTodayLabel),
			count: getIssueCountFromOption(QuickSelectOption.TODAY),
		},
		{
			value: QuickSelectOption.TIMELINE,
			label: formatMessage(messages.selectedOnTimelineLabel),
			count: getIssueCountFromOption(QuickSelectOption.TIMELINE),
		},
	];

	const handleSelect = (clickedOption: QuickSelectOption) => {
		const selectOrDeselectOption =
			option === clickedOption ? QuickSelectOption.NONE : clickedOption;

		changeQuickSelect(selectOrDeselectOption);
		selectIssuesOnOptionChange(selectOrDeselectOption);

		const [actionSubject, action] =
			PRODUCT_ANALYTICS_EVENT_NAMES.REVIEW_CHANGES_MODAL_QUICK_SELECT_CLICKED.split(' ');

		fireUIAnalytics(createAnalyticsEvent({ action, actionSubject }), {
			option: selectOrDeselectOption,
		});
	};

	return (
		<ButtonGroup>
			{options.map(({ value, label, isDisabled, count }, index) => (
				<Button
					key={`${value}-quick-select`}
					isSelected={option === value}
					isDisabled={isDisabled}
					onClick={() => {
						handleSelect(value);
					}}
					testId={`portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.quick-selectors.button-${value}`}
					autoFocus={index === 0}
				>
					<Inline xcss={buttonLabelStyles}>
						{label}
						<Inline xcss={changeCountBadgeStyles}>
							<Badge appearance={option === value ? 'primary' : 'default'}>{count}</Badge>
						</Inline>
					</Inline>
				</Button>
			))}
		</ButtonGroup>
	);
};

export default QuickSelectors;

const buttonLabelStyles = xcss({
	display: 'flex',
	alignItems: 'center',
});

const changeCountBadgeStyles = xcss({
	marginLeft: 'space.075',
});
