import { LOAD, CANCEL, SUCCESS, FAIL, type Action } from './actions';
import type { ChangeStatus } from './types';

const initialState: ChangeStatus = {
	issueId: '',
	isWaiting: false,
	issueKeyInJira: undefined,
	transitionId: undefined,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: ChangeStatus = initialState, action: Action) => {
	switch (action.type) {
		case LOAD:
			return {
				...state,
				isWaiting: true,
				...action.payload,
			};
		case CANCEL:
			return {
				...state,
				isWaiting: false,
			};
		case SUCCESS:
			return {
				...state,
				isWaiting: false,
			};
		case FAIL:
			return {
				...state,
				isWaiting: false,
			};
		default:
			return state;
	}
};
