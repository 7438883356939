import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types';
import {
	type DateRangeFilterValue,
	type DateRangeFilter,
	DATE_RANGE_FILTER_ID,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { getFiltersViewSettings } from '../../view-settings';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { DATE_RANGE_FILTER_ID } from '../../../state/domain/view-settings/filters/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	DateRangeFilter,
	DateRangeFilterValue,
} from '../../../state/domain/view-settings/filters/types';

export type DateRangeFilterState = {
	id: typeof DATE_RANGE_FILTER_ID;
	value: DateRangeFilterValue;
};

type DateRangeFilterStateWithFallback = Omit<DateRangeFilterState, 'value'> & {
	value: DateRangeFilterValue | undefined;
};

export const getDateRangeFilterState = (state: State): DateRangeFilterStateWithFallback => {
	const { value } = getFiltersViewSettings(state)[DATE_RANGE_FILTER_ID] || {};

	return {
		id: DATE_RANGE_FILTER_ID,
		value,
	};
};

export const getDateRangeFilterPure = (
	dateRangeFilter: DateRangeFilterStateWithFallback,
): DateRangeFilter => ({
	...dateRangeFilter,
	value: dateRangeFilter.value || {},
});

export const getDateRangeFilter = createSelector([getDateRangeFilterState], getDateRangeFilterPure);

export const applyFilter = (
	{ baselineEnd, baselineStart }: Issue,
	{ value: { startDate, endDate } }: DateRangeFilter,
): boolean =>
	(!isDefined(startDate) ||
		(isDefined(baselineStart) && baselineStart >= startDate) ||
		(isDefined(baselineEnd) && baselineEnd >= startDate)) &&
	(!isDefined(endDate) ||
		(isDefined(baselineEnd) && baselineEnd <= endDate) ||
		(isDefined(baselineStart) && baselineStart <= endDate));
