import React, { Component } from 'react';
import noop from 'lodash/noop';
import { ONE_DAY } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import type { DragHandler } from '@atlassian/jira-portfolio-3-common/src/drag-observer/types';
import HoverObserver from '@atlassian/jira-portfolio-3-common/src/hover-observer/index.tsx';
import { ISSUE_LINK_DIRECTION } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import type { Props as BarProps, BaselineDates } from '../bar/types';
import { getPositionsForBar } from '../bar/utils';
import Bar from '../bar/view.tsx';
import DateField from '../date-field';
import DateTooltip from '../date-tooltips';
import DependencyMarker from '../dependency-marker';
import IssueLength from '../issue-length';
import Overflowed from '../overflowed';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, State } from './types';

const snapWithRound = (val: number, snap: number) => Math.round(val / snap) * snap;

// eslint-disable-next-line jira/react/no-class-components
export default class PartialDatesRow extends Component<Props, State> {
	static defaultProps: Partial<Props> = {
		DependencyMarker,
		Bar,
		isReadOnly: false,
		showStripesInBar: { left: false, right: false },
		isBeingInteractedWith: false,
		cancelTimelinePreview: noop,
		commitTimelinePreview: noop,
		resizeTimelineIssue: noop,
		disableOtherRows: noop,
		reEnableOtherRows: noop,
		renderFlyout: () => null,
	};

	state = {
		hovered: false,
	};

	render() {
		const {
			issue,
			baselineStart,
			baselineEnd,
			timelineRange,
			barColor,
			barColors,
			showStripesInBar,
			preview,
			hasDependencyLineDragPreview,
			renderFlyout,
			isBeingInteractedWith,
			isReadOnly,
			externalIncomingLinks,
			externalOutgoingLinks,
			internalIncomingLinks,
			internalIncomingLinksOriginal,
			internalOutgoingLinks,
			internalOutgoingLinksOriginal,
			opacity,
			width,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			DependencyMarker,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			Bar,
			topOffset,
		} = this.props;
		const { hovered } = this.state;

		const createBar = (dates: BaselineDates, props: Partial<BarProps>) => {
			const position = getPositionsForBar(dates, timelineRange);

			return (
				<Bar
					{...position}
					color={barColor}
					colors={barColors}
					showStripes={showStripesInBar}
					id={issue.id}
					{...props}
					opacity={opacity}
					topOffset={topOffset}
				/>
			);
		};

		let leftHandleDragHandler: DragHandler | undefined;
		let rightHandleDragHandler: DragHandler | undefined;

		if (!isReadOnly) {
			const baseDragHandler = {
				onDragStart: () => {
					this.props.disableOtherRows(issue.id);
				},
				onDragEnd: () => {
					this.props.commitTimelinePreview();
					this.props.reEnableOtherRows();
				},
			};

			if (baselineStart !== undefined) {
				leftHandleDragHandler = {
					...baseDragHandler,
					onDrag: (_, to, start) => {
						const timelineWidth = timelineRange.end - timelineRange.start;
						const deltaRatio = (to.x - start.x) / width;
						const delta = snapWithRound(deltaRatio * timelineWidth, ONE_DAY);
						const newBaselineStart = baselineStart + delta;
						this.props.resizeTimelineIssue(newBaselineStart, undefined);
					},
				};
			}

			if (baselineEnd !== undefined) {
				rightHandleDragHandler = {
					...baseDragHandler,
					onDrag: (_, to, start) => {
						const timelineWidth = timelineRange.end - timelineRange.start;
						const deltaRatio = (to.x - start.x) / width;
						const delta = snapWithRound(deltaRatio * timelineWidth, ONE_DAY);
						const newBaselineEnd = baselineEnd + delta;
						this.props.resizeTimelineIssue(undefined, newBaselineEnd);
					},
				};
			}
		}

		const barOpacity = preview ? 0.5 : 1;
		const bar = createBar(
			{ baselineStart, baselineEnd },
			{
				opacity: barOpacity,
				leftHandleDragHandler,
				rightHandleDragHandler,
				showHandles: !isReadOnly && hovered && !isBeingInteractedWith,
				renderFlyout,
			},
		);

		let previewBar;
		if (preview) {
			previewBar = createBar(preview, { opacity: 0.8 });
		}
		const datePosition = getPositionsForBar({ baselineStart, baselineEnd }, timelineRange);

		const incomingDependencies = (
			<DependencyMarker
				externalIncomingLinks={externalIncomingLinks}
				internalIncomingLinks={internalIncomingLinks}
				originalIssueLinks={internalIncomingLinksOriginal}
				startOrEnd="start"
				direction={ISSUE_LINK_DIRECTION.INWARD}
				datePosition={datePosition}
				width={width}
				issue={issue}
				isHovered={hovered}
				barColor={barColor}
				barColors={barColors}
			/>
		);

		const outgoingDependencies = (
			<DependencyMarker
				externalOutgoingLinks={externalOutgoingLinks}
				internalOutgoingLinks={internalOutgoingLinks}
				internalOriginalIssueLinks={internalOutgoingLinksOriginal}
				startOrEnd="end"
				direction={ISSUE_LINK_DIRECTION.OUTWARD}
				datePosition={datePosition}
				width={width}
				issue={issue}
				isHovered={hovered}
				barColor={barColor}
				barColors={barColors}
			/>
		);

		let startDate;
		let endDate;
		let dates;
		let numDays;

		if (hovered && !hasDependencyLineDragPreview) {
			startDate = <DateField startOrEnd="start" date={baselineStart} />;

			endDate = <DateField startOrEnd="end" date={baselineEnd} />;

			numDays = <IssueLength startDate={baselineStart} endDate={baselineEnd} />;

			dates = (
				<DateTooltip datePosition={datePosition} width={width}>
					{startDate}
					{numDays}
					{endDate}
				</DateTooltip>
			);
		}

		return (
			<HoverObserver onHoverChanged={(hover) => this.setState({ hovered: hover })}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.spaPartialDatesRow}>
					{bar}
					{previewBar}
					{dates}
					{incomingDependencies}
					{outgoingDependencies}
					<Overflowed />
				</div>
			</HoverObserver>
		);
	}
}
