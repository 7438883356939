import * as R from 'ramda';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { CustomDateRange } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/types.tsx';
import type { ZoomLevel } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/common/types.tsx';
import {
	ISSUE_INFERRED_DATE_SELECTION,
	DATEFIELD_TYPES,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import {
	colourByOptions,
	colourPickerPaletteLabels,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colours';
import {
	SPRINT_STATES,
	PACKAGE_NAME,
	ERROR_REPORTING_PACKAGE,
	ERROR_REPORTING_TEAM,
	TIMELINE_MODES,
	ZOOM_LEVELS,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import {
	getPresetViewNames,
	getMigratedPresetViewName,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/preset-view-names';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import type { IssueType } from '../../domain/issue-types/types';
import type { Issue } from '../../domain/issues/types.tsx';
import type { PlanInfo } from '../../domain/plan/types';
import type { Project } from '../../domain/projects/types';
import type { Sprint } from '../../domain/sprints/types.tsx';
import type { Version } from '../../domain/versions/types';
import type { ColourByOption, ColourByState } from '../../domain/view-settings/colour-by/types';
import type { View } from '../../domain/views/types';
import type {
	ColorSetting,
	ColoursUsed,
	ColourViewSettings,
	IssueAnalyticRequiredAttributes,
} from './types';

type CountFutureSprintDates = (arg1: Sprint[]) => {
	closed: number;
	active: number;
	future: number;
	sprintFutureSetDates: number;
	sprintFutureInferredDates: number;
};

export const countFutureSprintDates: CountFutureSprintDates = R.compose(
	(result) => {
		const { sprintFutureSetDates = 0, sprintFutureInferredDates = 0 } = result;
		return R.merge({
			closed: 0,
			active: 0,
			future: sprintFutureSetDates + sprintFutureInferredDates,
			sprintFutureSetDates,
			sprintFutureInferredDates,
		})(result);
	},
	R.countBy<Sprint>((sprint) => {
		if (sprint.state === SPRINT_STATES.FUTURE) {
			return sprint.planned ? 'sprintFutureSetDates' : 'sprintFutureInferredDates';
		}
		return String(sprint.state).toLowerCase();
	}),
);

type CountIssueInferredDates = {
	issuesWithFixedStartDateCount: number;
	issuesWithFixedEndDateCount: number;
	issuesWithSprintStartDateCount: number;
	issuesWithSprintEndDateCount: number;
	issuesWithReleaseStartDateCount: number;
	issuesWithReleaseEndDateCount: number;
};

// Assumption: issueReleases will always contain 1 element
const inferringReleaseStart = (allReleases: Version[], issueReleases: string[]): boolean => {
	if (issueReleases.length === 0) return false;

	const releaseToCheck = allReleases.find((release) => release.id === issueReleases[0]);
	return releaseToCheck?.start != null;
};

const inferringReleaseEnd = (allReleases: Version[], issueReleases: string[]): boolean => {
	if (issueReleases.length === 0) return false;

	const releaseToCheck = allReleases.find((release) => release.id === issueReleases[0]);
	return releaseToCheck?.end != null;
};

export const countIssueInferredDates = (
	plan: PlanInfo,
	issues: Issue[],
	releases: Version[],
): CountIssueInferredDates => {
	const { baselineStartField, baselineEndField, issueInferredDateSelection } = plan;

	let startInferred = 0;
	let endInferred = 0;
	let issuesWithFixedStartDateCount = 0;
	let issuesWithFixedEndDateCount = 0;
	let issuesWithSprintStartDateCount = 0;
	let issuesWithSprintEndDateCount = 0;
	let issuesWithReleaseStartDateCount = 0;
	let issuesWithReleaseEndDateCount = 0;

	issues.forEach((issue) => {
		let startDate;
		let endDate;

		if (baselineStartField.type === DATEFIELD_TYPES.BUILT_IN) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			startDate = issue[baselineStartField.key as keyof Issue];
		} else {
			// issue start date is a custom field
			const customFields = issue.customFields;
			// baselineStartField.key will be the id of the custom field
			startDate = customFields ? customFields[baselineStartField.key] : null;
		}

		if (baselineEndField.type === DATEFIELD_TYPES.BUILT_IN) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			endDate = issue[baselineEndField.key as keyof Issue];
		} else {
			// issue end date is a custom field
			const customFields = issue.customFields;
			// baselineEndField.key will be the id of the custom field
			endDate = customFields ? customFields[baselineEndField.key] : null;
		}

		// if both start and end are null, then we can assume we *may* be inferring from sprint or release
		// or we could not find the custom fields which they are using to indicate start or end date
		if (startDate == null && endDate == null) {
			// check if inferring from sprints and issue is assigned to a sprint
			if (
				issueInferredDateSelection === ISSUE_INFERRED_DATE_SELECTION.SPRINT &&
				issue.sprint != null
			) {
				startInferred += 1;
				endInferred += 1;
			} else if (issueInferredDateSelection === ISSUE_INFERRED_DATE_SELECTION.RELEASE) {
				// check if inferring from release
				if (issue.fixVersions && issue.fixVersions.length !== 0) {
					// issue is associated with a release
					if (inferringReleaseStart(releases, issue.fixVersions)) {
						startInferred += 1;
					}
					// Have to be explicit for flow
					if (issue.fixVersions != null && inferringReleaseEnd(releases, issue.fixVersions)) {
						endInferred += 1;
					}
				}
			}
		}

		// issue has a start date
		if (startDate != null) {
			issuesWithFixedStartDateCount += 1;
		}

		// issue has an end date
		if (endDate != null) {
			issuesWithFixedEndDateCount += 1;
		}
	});

	// assign inferredStart and inferredEnd to appropriate attribute
	if (issueInferredDateSelection === ISSUE_INFERRED_DATE_SELECTION.SPRINT) {
		issuesWithSprintStartDateCount = startInferred;
		issuesWithSprintEndDateCount = endInferred;
	} else if (issueInferredDateSelection === ISSUE_INFERRED_DATE_SELECTION.RELEASE) {
		issuesWithReleaseStartDateCount = startInferred;
		issuesWithReleaseEndDateCount = endInferred;
	}

	return {
		issuesWithFixedStartDateCount,
		issuesWithFixedEndDateCount,
		issuesWithSprintStartDateCount,
		issuesWithSprintEndDateCount,
		issuesWithReleaseStartDateCount,
		issuesWithReleaseEndDateCount,
	};
};

export const getUsedColoursFromColourMaps = (
	colourByViewSettings: ColourByState,
	colourByOption: ColourByOption,
): ColoursUsed => {
	const colours: string[] = R.values(colourByViewSettings.colourMaps[colourByOption]).map(
		(colour) => {
			const colourMessage = colourPickerPaletteLabels[colour];

			if (colourMessage === undefined || colourMessage.defaultMessage === undefined) return colour;

			return colourMessage.defaultMessage;
		},
	);
	if (R.isEmpty(colours)) {
		// @ts-expect-error - TS2739 - Type '{}' is missing the following properties from type 'ColoursUsed': averageOptionsPerColour, colours, count
		return {};
	}
	return {
		averageOptionsPerColour: 1,
		colours,
		count: colours.length,
	};
};

const getAverageOptionsPerColour = (colours: ColorSetting[]) =>
	colours.reduce(
		(acc: number, { colour, ...rest }: ColorSetting) => acc + Object.values(rest)[0].length,
		0,
	) / colours.length;

const getColourNames = (colours: ColorSetting[]) =>
	colours.reduce((acc: string[], { colour }: ColorSetting) => {
		if (isDefined(colour)) {
			acc.push(colourPickerPaletteLabels[colour]?.defaultMessage || colour);
		}
		return acc;
	}, []);

export const getUsedColoursFromColourPicker = (
	colourByViewSettings: ColourByState,
	colourViewSettings: ColourViewSettings,
): ColoursUsed => {
	const colours = colourByViewSettings[colourViewSettings];
	if (!isDefined(colours) || R.isEmpty(colours)) {
		// @ts-expect-error - TS2739 - Type '{}' is missing the following properties from type 'ColoursUsed': averageOptionsPerColour, colours, count
		return {};
	}

	return {
		averageOptionsPerColour: getAverageOptionsPerColour(colours),
		colours: getColourNames(colours),
		count: colours.length,
	};
};

export const getColoursUsedInPlan = (colourByViewSettings: ColourByState) => ({
	[colourByOptions.COMPONENT]: getUsedColoursFromColourPicker(
		colourByViewSettings,
		'colourComponents',
	),
	[colourByOptions.ISSUE_TYPE]: getUsedColoursFromColourPicker(
		colourByViewSettings,
		'colourIssueTypes',
	),
	[colourByOptions.LABEL]: getUsedColoursFromColourPicker(colourByViewSettings, 'colourLabels'),
	[colourByOptions.PRIORITY]: getUsedColoursFromColourPicker(
		colourByViewSettings,
		'colourPriorities',
	),
	[colourByOptions.PROJECT]: getUsedColoursFromColourMaps(
		colourByViewSettings,
		colourByOptions.PROJECT,
	),
	[colourByOptions.STATUS]: getUsedColoursFromColourMaps(
		colourByViewSettings,
		colourByOptions.STATUS,
	),
	[colourByOptions.TEAM]: getUsedColoursFromColourMaps(colourByViewSettings, colourByOptions.TEAM),
});

export const getIssueAnalyticAttributes = (
	issue: IssueAnalyticRequiredAttributes,
	issueTypesById: {
		[key: number]: IssueType;
	},
	projectsById: {
		[id: number]: Project;
	},
	additionalAttributes: Attributes = {},
) => {
	const issueAttributes = {
		...(isDefined(issue.id) && { issueId: issue.id }),
		issueTypeId: issue.type,
		issueType: issueTypesById[issue.type]?.name,
		hierarchyLevel: issue.level,
		isSimplified: projectsById[issue.project]?.isSimplified,
	};
	return Object.assign(additionalAttributes, issueAttributes);
};

export const getTimelineAttributes = (customDateRange: CustomDateRange, zoomLevel?: ZoomLevel) => {
	let timelineType; // "weeks", "months", "quarters", "years", or "custom"
	let customTimelineType = ''; // "fixed" or "relative"

	switch (zoomLevel) {
		case ZOOM_LEVELS.WEEKS:
		case ZOOM_LEVELS.MONTHS:
		case ZOOM_LEVELS.QUARTERS:
		case ZOOM_LEVELS.YEARS:
			timelineType = zoomLevel;
			break;

		// if zoomLevel is undefined, the plan has a "custom" date range
		default:
			timelineType = TIMELINE_MODES.CUSTOM;

			if (!R.isEmpty(customDateRange)) {
				// @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
				customTimelineType = customDateRange.typeOfCustomDateRange;
			}

			break;
	}

	return {
		customTimelineType,
		timelineType,
	};
};

export const logAnalyticsError = (error: Error, message: string) => {
	fireErrorAnalytics({
		meta: {
			packageName: PACKAGE_NAME,
			id: ERROR_REPORTING_PACKAGE.ROADMAP,
			teamName: ERROR_REPORTING_TEAM,
		},
		attributes: { message },
		error,
		sendToPrivacyUnsafeSplunk: true,
	});
};

export const getPresetName = (view: View) => {
	const presets = getPresetViewNames();
	const viewName = getMigratedPresetViewName(view);

	const presetNames = [...Object.values(presets), 'Untitled'];
	if (presetNames.indexOf(viewName) >= 0) {
		return viewName;
	}
	return 'Custom';
};
