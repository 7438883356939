import * as R from 'ramda';
import { VIEW_MODES } from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import { combineReducers } from '@atlassian/jira-portfolio-3-portfolio/src/common/redux';
import { RESET, type ResetAction } from './actions';
import activeFilterView from './active-filter-view/reducer';
import colourBy from './colour-by/reducer';
import componentGroups from './component-groups/reducer';
import customFieldValuesGroups from './custom-field-values-groups/reducer';
import dateConstraints from './date-constraints-setting/reducer';
import dependencySettings from './dependency-settings/reducer';
import fieldColumns, { type Action as FieldColumnsAction } from './field-columns/reducer';
import filterOptions from './filter-options/reducer';
import filters from './filters/reducer';
import highlightedVersions from './highlighted-versions/reducer';
import issueExpansions from './issue-expansions/reducer';
import labelGroups from './label-groups/reducer';
import rollupSettings from './rollup-settings/reducer';
import timeScale from './time-scale/reducer';
import type { ViewSettingsState } from './types';
import viewMode from './view-mode/reducer';
import visualisations from './visualisations/reducer';
import warningSettings from './warning-settings/reducer';

type Action = ResetAction;

const reducersByKey = {
	filtersV1: filters,
	colourByV2: colourBy,
	timeScaleV1: timeScale,
	issueExpansionsV0: issueExpansions,
	fieldColumnsV0: fieldColumns,
	filterOptionsV1: filterOptions,
	rollupSettingsV0: rollupSettings,
	highlightedVersionsV0: highlightedVersions,
	componentGroupsV0: componentGroups,
	labelGroupsV0: labelGroups,
	customFieldValuesGroupsV0: customFieldValuesGroups,
	warningSettingsV1: warningSettings,
	dateConstraintsV0: dateConstraints,
	visualisationsV0: visualisations,
	dependencySettingsV0: dependencySettings,
	listFieldColumnsV0: fieldColumns,
	viewModeV0: viewMode,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
	calendarFiltersV1: filters as any,
	activeFilterViewV0: activeFilterView,
} as const;

const FIELD_COLUMNS_KEY = 'fieldColumnsV0' as const;
const LIST_FIELD_COLUMNS_KEY = 'listFieldColumnsV0' as const;

const FILTERS_KEY = 'filtersV1' as const;
const CALENDAR_FILTERS_KEY = 'calendarFiltersV1' as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const combined = combineReducers<any, any>(reducersByKey);

const initialState: ViewSettingsState = combined(undefined, { type: '' });

export default function reducer(
	state: ViewSettingsState = initialState,
	action: Action | FieldColumnsAction,
): ViewSettingsState {
	const resetState = initialState;

	switch (action.type) {
		case RESET: {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const pickExact: (arg1: Record<any, any>) => Record<any, any> = R.pick(
				// activeFilterViewV0 is in the view-settings state for the convenience of
				// accessing it from the default reducer below.
				// However, it's set based on the route and must not be reset by saved views.
				Object.keys(resetState).filter((key) => key !== 'activeFilterViewV0'),
			);
			return R.mergeDeepLeft(pickExact(action.payload))(resetState);
		}

		default: {
			const fieldColumnsKey =
				state.viewModeV0?.mode === VIEW_MODES.LIST ? LIST_FIELD_COLUMNS_KEY : FIELD_COLUMNS_KEY;

			const filtersKey =
				state.activeFilterViewV0 === 'calendar' ? CALENDAR_FILTERS_KEY : FILTERS_KEY;

			return {
				...state,
				...R.omit(
					[FIELD_COLUMNS_KEY, LIST_FIELD_COLUMNS_KEY, FILTERS_KEY, CALENDAR_FILTERS_KEY],
					combined(state, action),
				),
				[fieldColumnsKey]: fieldColumns(state[fieldColumnsKey], action),
				[filtersKey]: filters(state[filtersKey], action),
			};
		}
	}
}
