import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';
import { getIssueTypeKeyFilterValue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/issue-type-key-filter';
import {
	getHierarchyTitles,
	getIssueTypeWithLevelById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/hierarchy';
import { getIssueTypeIconByKey } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types';
import type { AggregatedIssueTypeHierarchy } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types/types';
import type { IssueTypeWithLevel } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/hierarchy/types';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { StateProps } from './types';

export const getAggregatedIssueTypeHierarchiesPure = (
	hierarchyTitles: Record<number, string>,
	issueTypeIconByKey: Record<string, string>,
	issueTypeById: Record<number, IssueTypeWithLevel>,
): AggregatedIssueTypeHierarchy[] => {
	const issueTypeByLevel: Record<number, IssueTypeWithLevel[]> = groupBy(
		Object.values(issueTypeById),
		({ level }) => level,
	);

	return Object.entries(issueTypeByLevel)
		.sort(([firstLevel], [secondLevel]) => Number(secondLevel) - Number(firstLevel))
		.reduce<AggregatedIssueTypeHierarchy[]>((acc, [level, issueTypes]) => {
			const aggregatedIssueTypes = uniq(issueTypes.map(({ name }) => name))
				.sort()
				.map((name) => ({ name, iconUrl: issueTypeIconByKey[`${level} ${name}`] ?? '#' }));

			const aggregatedIssueTypeHierarchy = {
				level: Number(level),
				title: hierarchyTitles[Number(level)] ?? '',
				issueTypes: aggregatedIssueTypes,
			};
			acc.push(aggregatedIssueTypeHierarchy);
			return acc;
		}, []);
};

const getAggregatedIssueTypeHierarchies = createSelector<
	State,
	Record<number, string>,
	Record<string, string>,
	Record<number, IssueTypeWithLevel>,
	AggregatedIssueTypeHierarchy[]
>(
	[getHierarchyTitles, getIssueTypeIconByKey, getIssueTypeWithLevelById],
	getAggregatedIssueTypeHierarchiesPure,
);

const mapStateToProps: MapStateToProps<StateProps> = (state) => ({
	value: getIssueTypeKeyFilterValue(state),
	aggregatedIssueTypeHierarchy: getAggregatedIssueTypeHierarchies(state),
});

export default mapStateToProps;
