import React, { useEffect, useRef, type ReactNode } from 'react';
import equals from 'lodash/fp/equals';
import * as R from 'ramda';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import { DEFAULT_COLOR } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colours';
import {
	HORIZONTAL_SCROLL_OFFSET,
	PREVIEW_OPACITY,
	SCOPE_ROW_HEIGHT,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import { Z_INDEX_LAYER } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/types.tsx';
import { ZIndex } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/view.tsx';
import { useDndItem } from '../../dependency-lines/dnd-item/provider/index.tsx';
import { useDepLinesDrag } from '../../dependency-lines/drag-preview/provider/index.tsx';
import DragScrollingObserver from '../drag-scrolling-observer';
import { FLYOUT_CONTENT_WIDTH } from '../flyout/view';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';
import {
	useDropTarget,
	colorsToGradient,
	getStripesStyle,
	HORIZONTAL_HIT_TARGET_THRESHOLD,
} from './utils';

export default function Bar({
	additionalStyles,
	alwaysShowFlyout = false,
	barDragHandler,
	children,
	color = DEFAULT_COLOR,
	colors,
	id,
	leftAnchorPercentage,
	leftHandleDragHandler,
	leftPositionPercentage,
	opacity = 1,
	renderFlyout,
	rightAnchorPercentage,
	rightHandleDragHandler,
	rightPositionPercentage,
	showHandles = false,
	showStripes,
	topOffset,
}: Props) {
	const ref = useRef<HTMLDivElement>(null);

	const {
		dragHovered,
		dragSource,
		removeIssueRect,
		updateIssueRect,
		width: viewportWidth,
	} = useDepLinesDrag();
	const { id: dndItemId } = useDndItem();

	const isBeingDragHovered = equals(dndItemId, dragHovered);
	const isSourceOfDragPreview = equals(dndItemId, dragSource?.rowId);

	useEffect(() => {
		// we render a bar AND a previewBar for each row, and
		// we don't want to modify the issueRects when it's a previewBar
		if (opacity === PREVIEW_OPACITY) return;

		const widthPercentage = 100 - rightPositionPercentage - leftPositionPercentage;
		const rect = {
			top: topOffset - SCOPE_ROW_HEIGHT,
			left:
				(leftPositionPercentage / 100) * viewportWidth -
				HORIZONTAL_HIT_TARGET_THRESHOLD +
				HORIZONTAL_SCROLL_OFFSET,
			width: (widthPercentage / 100) * viewportWidth + 2 * HORIZONTAL_HIT_TARGET_THRESHOLD,
			height: SCOPE_ROW_HEIGHT,
		};

		updateIssueRect(dndItemId, rect);
		return () => removeIssueRect(dndItemId);
	}, [
		dndItemId,
		leftPositionPercentage,
		opacity,
		removeIssueRect,
		rightPositionPercentage,
		topOffset,
		updateIssueRect,
		viewportWidth,
	]);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const isDraggedOver = ff('com.atlassian.rm.jpo.transposition.m2') && useDropTarget(ref);

	const normalizedColors = R.defaultTo([{ colour: color, ratio: 1 }], colors);

	const stripes = showStripes && (showStripes.left || showStripes.right) && (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles.stripes}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			style={getStripesStyle(showStripes, normalizedColors)}
			data-testid={`issue-bar-stripes-${id}`}
		/>
	);

	const handleStyle = {
		display: showHandles ? 'block' : 'none',
	};

	const leftHandle = leftAnchorPercentage === 0 && (
		<DragScrollingObserver dragHandler={leftHandleDragHandler || {}} flag="LEFT-HANDLE">
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.leftHandle} style={handleStyle} />
		</DragScrollingObserver>
	);

	const rightHandle = rightAnchorPercentage === 100 && (
		<DragScrollingObserver dragHandler={rightHandleDragHandler || {}} flag="RIGHT-HANDLE">
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.rightHandle} style={handleStyle} />
		</DragScrollingObserver>
	);

	// Using && ensures we don't render the flyout unnecessarily
	const flyoutContent = alwaysShowFlyout && renderFlyout();

	// Consider remove this function when cleaning up com.atlassian.rm.jpo.transposition.m2
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const withZIndexOverride = (children: (zIndex: number | string | undefined) => ReactNode) => {
		if (!ff('com.atlassian.rm.jpo.transposition.m2')) {
			return children(undefined);
		}

		return (
			<ZIndex layer={Z_INDEX_LAYER.ISSUE_BARS}>{(zIndex) => children(zIndex ?? 'initial')}</ZIndex>
		);
	};

	return (
		<>
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.flyoutPositioner}
				// We hard-code the width of the flyout because measuring it dynamically is tricky.
				// It would require rendering the flyout, then storing the dimensions of the flyout in state,
				// then re-rendering again with the correct positioning.
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					left: `calc(${leftPositionPercentage}% + ${(FLYOUT_CONTENT_WIDTH + 50) / 2}px)`,
				}}
			>
				<InlineDialog
					content={flyoutContent}
					isOpen={alwaysShowFlyout && flyoutContent !== undefined}
					placement="bottom"
				>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.flyoutContent} />
				</InlineDialog>
			</div>
			<DragScrollingObserver dragHandler={barDragHandler || {}} flag="BAR">
				{withZIndexOverride((zIndex) => (
					<div
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={styles.spaBar}
						data-name={`issue-bar-${id}`}
						data-testid={`issue-bar-${id}`}
						ref={ref}
						style={{
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
							background: colorsToGradient({
								colors: normalizedColors,
								leftAnchorPercentage,
								rightAnchorPercentage,
							}),
							// Remove this with com.atlassian.rm.jpo.transposition.m2
							border:
								isBeingDragHovered === true || isSourceOfDragPreview === true
									? `solid 1.5px ${token('color.background.neutral.bold', '#000000')}`
									: '',

							boxShadow: isDraggedOver
								? `0 0 0 1.5px ${token('color.background.neutral.bold', '#000000')}`
								: undefined,

							left: `${leftPositionPercentage}%`,
							opacity,
							right: `${rightPositionPercentage}%`,
							zIndex,
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							...additionalStyles,
						}}
					>
						{stripes}
						{leftHandle}
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.content}>{children}</div>
						{rightHandle}
					</div>
				))}
			</DragScrollingObserver>
		</>
	);
}
