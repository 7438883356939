import {
	EPIC_LEVEL,
	SUB_TASK_LEVEL,
} from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import {
	TABLE_GROUP,
	TABLE_GROUP_HEADER,
	TABLE_ISSUE,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table';
import { ISSUE_FIELDS } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import {
	type TableGroupItem,
	DROP_CHILD,
	DROP_INVALID_CHILD,
	DROP_INVALID_SIBLING,
	DROP_NONE,
	DROP_SIBLING,
	type DropErrorType,
	type DropState,
	type DropType,
	type GroupHeaderItem,
	type Item,
	type RelativePosition,
	REPARENT_CMP_TO_TMP_ERROR,
	REPARENT_TMP_TO_EXTERNAL_ERROR,
	type TableIssueItem,
} from '../types';
import type { OnSortOverArgs } from './types';

export function isTableGroupItem(issue: Item): issue is TableGroupItem {
	return 'height' in issue;
}

export const getIndexBeforeAndAfterNewIndex = (
	index: number,
	newIndex: number,
): { indexBefore: number; indexAfter: number } => {
	if (newIndex > index) {
		// The item currently at newIndex will become the itemBefore after drop
		// because the gap left behind the dragged item will offset the index - 1.
		// The item currently after newIndex will remain the itemAfter after drop.
		return { indexBefore: newIndex, indexAfter: newIndex + 1 };
	}
	if (newIndex < index) {
		return { indexBefore: newIndex - 1, indexAfter: newIndex };
	}
	// newIndex === index
	return { indexBefore: newIndex - 1, indexAfter: newIndex + 1 };
};

const getRelativePosition = ({
	issue,
	itemBefore,
	itemAfter,
}: {
	issue: TableIssueItem;
	itemBefore?: Item;
	itemAfter?: Item;
}): RelativePosition => {
	// prefer to rank issue before sibling issue than to rank issue after (parent) issue
	if (itemAfter?.tag === TABLE_ISSUE && issue.value.level === itemAfter.value.level) {
		return {
			anchor: itemAfter.value.id,
			relation: 'BEFORE',
		};
	}
	if (itemBefore?.tag === TABLE_ISSUE) {
		return {
			anchor: itemBefore.value.id,
			relation: 'AFTER',
		};
	}
	return {
		anchor: undefined,
		relation: 'LAST',
	};
};

const findPreviousSibling = (
	items: Item[],
	startIndex: number,
	id: string,
	level: number,
): TableIssueItem | undefined => {
	for (let i = startIndex; i >= 0; i--) {
		const item = items[i];
		if (item?.tag === TABLE_ISSUE) {
			const itemIsNotSelf = item.value.id !== id;
			const itemIsSameLevel = item.value.level === level;
			if (itemIsNotSelf && itemIsSameLevel) {
				return item;
			}
			if (item.value.level > level) {
				return undefined;
			}
		} else {
			return undefined;
		}
	}
	return undefined;
};

const getReparentDropState = ({
	issue,
	parentProject,
	parentId,
}: {
	issue: TableIssueItem;
	parentProject: Project;
	parentId: string | undefined;
}): { parentId: string | null | undefined; type: DropType; errorType: DropErrorType } => {
	const {
		value: { project, level },
	} = issue;

	if (parentId !== undefined) {
		if (level !== EPIC_LEVEL) {
			if (project.isSimplified && project.id !== parentProject.id) {
				return {
					parentId: null,
					type: DROP_INVALID_CHILD,
					errorType: REPARENT_TMP_TO_EXTERNAL_ERROR,
				};
			}
			if (!project.isSimplified && parentProject.isSimplified) {
				return {
					parentId: null,
					type: DROP_INVALID_CHILD,
					errorType: REPARENT_CMP_TO_TMP_ERROR,
				};
			}
		}
	}
	return {
		parentId,
		type: DROP_CHILD,
		errorType: null,
	};
};

export const isSortByRank = (sortByField: string): boolean => sortByField === ISSUE_FIELDS.RANK;

export const getNewDropState = ({
	items,
	index,
	newIndex,
	startLevel,
}: OnSortOverArgs): DropState => {
	const draggedItem = items[index];

	if (draggedItem?.tag !== TABLE_ISSUE) {
		throw new Error(`Dragged item  was expected to be ${TABLE_ISSUE}, but was ${draggedItem.tag}.`);
	}

	// dragged item must be table issue
	const {
		value: { id, level, parent },
		group,
	} = // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		draggedItem as TableIssueItem;

	const defaultDropState: DropState = {
		id,
		group,
		parentId: parent,
		anchor: undefined,
		relation: null,
		type: DROP_NONE,
		errorType: null,
	};

	// It's not always obvious what the user's intent was, so it's difficult to accurately classify the invalid reason.
	// Where possible, we differentiate DROP_INVALID_SIBLING from DROP_INVALID_CHILD.
	const invalidDropState = {
		...defaultDropState,
		type: DROP_INVALID_SIBLING,
	};

	if (index === newIndex) {
		return defaultDropState;
	}

	const { indexBefore, indexAfter } = getIndexBeforeAndAfterNewIndex(index, newIndex);
	const itemBefore = items[indexBefore];
	const itemAfter = items[indexAfter];

	const isDropInSameGroup = group === itemBefore?.group;
	if (!isDropInSameGroup) {
		return invalidDropState;
	}

	const isDropAtStart = newIndex === 0;
	const isDropAfterGroup = itemBefore?.tag === TABLE_GROUP;
	if (isDropAtStart || isDropAfterGroup) {
		if (level === startLevel) {
			return {
				...defaultDropState,
				...getRelativePosition({
					issue: draggedItem,
					itemAfter,
				}),
				parentId: null,
				type: DROP_SIBLING,
			};
		}
		return invalidDropState;
	}

	const isDropAfterGroupHeader = itemBefore?.tag === TABLE_GROUP_HEADER;
	if (isDropAfterGroupHeader) {
		const {
			value: {
				level: { level: groupHeaderLevel },
			},
		} = // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			itemBefore as GroupHeaderItem;

		const willOrphanSubtask = level === SUB_TASK_LEVEL;
		if (level === groupHeaderLevel && !willOrphanSubtask) {
			return {
				...defaultDropState,
				...getRelativePosition({
					issue: draggedItem,
					itemAfter,
				}),
				parentId: null,
				type: DROP_SIBLING,
			};
		}
		return invalidDropState;
	}

	const isDropAfterIssue = itemBefore?.tag === TABLE_ISSUE;
	if (isDropAfterIssue) {
		const {
			value: { id: issueBeforeId, level: issueBeforeLevel, project: issueBeforeProject },
		} = // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			itemBefore as TableIssueItem;

		// including same parent
		const isReparent = level === issueBeforeLevel - 1;
		if (isReparent) {
			const reparentState = getReparentDropState({
				issue: draggedItem,
				parentProject: issueBeforeProject,
				parentId: issueBeforeId,
			});
			return reparentState.errorType === null
				? {
						...defaultDropState,
						...reparentState,
						...getRelativePosition({ issue: draggedItem, itemBefore, itemAfter }),
					}
				: {
						...defaultDropState,
						...reparentState,
					};
		}

		// isRerank
		const isInsertBetweenParentAndChild =
			itemAfter?.tag === TABLE_ISSUE && level > itemAfter.value.level;
		if (!isInsertBetweenParentAndChild) {
			const sibling = findPreviousSibling(items, indexBefore, id, level);
			if (sibling !== undefined) {
				const {
					value: { project: siblingProject, parent: siblingParent },
				} = sibling;

				const willOrphanSubtask = level === SUB_TASK_LEVEL && siblingParent === undefined;
				if (willOrphanSubtask) {
					return invalidDropState;
				}

				const isAlsoReparent = parent !== siblingParent;
				if (isAlsoReparent) {
					const reparentState = getReparentDropState({
						issue: draggedItem,
						parentProject: siblingProject, // using sibling's project as a proxy for actual parent's project
						parentId: siblingParent,
					});
					return reparentState.errorType === null
						? {
								...defaultDropState,
								...reparentState,
								...getRelativePosition({ issue: draggedItem, itemBefore, itemAfter }),
							}
						: {
								...defaultDropState,
								...reparentState,
							};
				}

				return {
					...defaultDropState,
					...getRelativePosition({ issue: draggedItem, itemBefore, itemAfter }),
					type: DROP_SIBLING,
				};
			}
		}
	}

	// drop after any other type of item, such as blank row or inline create, are considered invalid
	return invalidDropState;
};
