import type { GroupOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/types';
import type { EnrichedVersion } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/types';
import type { CustomField } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/custom-fields/types';
import type { IssueType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-types/types';
import type { GroupCombination } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { ToggleExpandGroupActionPayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/visualisations/actions.tsx';
import type { StartInlineCreateActionPayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/scope/inline-create/actions.tsx';
import {
	GROUPING,
	type Grouping,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import type { Option } from './options-providers/types';

export const ISSUE_CREATE_SUPPORTED_GROUPS: readonly string[] = [
	GROUPING.NONE,
	GROUPING.ASSIGNEE,
	GROUPING.REPORTER,
	GROUPING.PROJECT,
	GROUPING.RELEASE,
	GROUPING.SPRINT,
	GROUPING.TEAM,
] as const;

export const GROUP = 'group';
export const PROJECT = 'project';
export const ISSUE_TYPE = 'issueType';

export type CreateIssueOptions = {
	[GROUP]: Option<GroupCombination>;
	[PROJECT]: Option<GroupCombination>;
	[ISSUE_TYPE]: Option<IssueType>;
};

export type StateProps = {
	enrichedVersions: EnrichedVersion[];
	groups?: GroupOption[];
	grouping?: Grouping;
	customFieldsById: { [id: string]: CustomField };
};

export type DispatchProps = {
	setDefaultProject: (arg1: number) => void;
	setGlobalCreateIssue: () => void;
	setIssueTypeIdForHierarchy: (hierarchyLevel: number, issueTypeId: number) => void;
	startInlineCreate: (arg1: StartInlineCreateActionPayload) => void;
	toggleExpandGroup: (arg1: ToggleExpandGroupActionPayload) => void;
};

export type Props = StateProps & DispatchProps;
