import { type RefObject, useEffect, useState } from 'react';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { attachClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
	draggable,
	dropTargetForElements,
	monitorForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { useDragAndDropController, useDragAndDropInstance } from '../controller/hooks.tsx';
import type { Callbacks } from '../types';
import type { UseDragTargetResponse, AllowedAxis } from './types';
import { getDragTarget, getDropTarget } from './utils';

export const useDragTarget = (
	ref: RefObject<HTMLElement>,
	index: number,
): UseDragTargetResponse => {
	const instance = useDragAndDropInstance();

	const [isDragging, setIsDragging] = useState<boolean>(false);

	useEffect(() => {
		if (!ref.current) return;

		const element = ref.current;

		const data = { index, instance };

		return draggable({
			element,
			getInitialData() {
				return data;
			},
			onGenerateDragPreview() {
				setIsDragging(true);
			},
			onDrop() {
				setIsDragging(false);
			},
		});
	}, [index, instance, ref]);

	return { isDragging };
};

export const useDropTarget = (ref: RefObject<HTMLElement>, index: number) => {
	const instance = useDragAndDropInstance();

	useEffect(() => {
		if (!ref.current) return;

		const element = ref.current;

		const data = { index, instance };

		return dropTargetForElements({
			element,
			canDrop({ source }) {
				return source.data.instance === instance;
			},
			getData({ input }) {
				return attachClosestEdge(data, {
					element,
					input,
					allowedEdges: ['top', 'bottom'],
				});
			},
		});
	}, [index, instance, ref]);
};

export const useDragAndDropMonitor = ({
	onDragStart,
	onDrag,
	onDrop,
	getIndicators,
}: Callbacks) => {
	const [
		{ instance },
		{
			initialize,
			onDragStart: onDragStartInternal,
			onDrag: onDragInternal,
			onDropTargetChange: onDropTargetChangeInternal,
			onDrop: onDropInternal,
		},
	] = useDragAndDropController();

	useEffect(
		() =>
			monitorForElements({
				canMonitor({ source }) {
					return source.data.instance === instance;
				},
				onDragStart({ source }) {
					const dragTarget = getDragTarget(source);

					onDragStartInternal(dragTarget);
				},
				onDrag({ location }) {
					const target = location.current.dropTargets[0];
					const dropTarget = getDropTarget(target);

					onDragInternal(dropTarget);
				},
				onDropTargetChange({ location }) {
					const target = location.current.dropTargets[0];
					const dropTarget = getDropTarget(target);

					onDropTargetChangeInternal(dropTarget);
				},
				onDrop({ source, location }) {
					const target = location.current.dropTargets[0];

					const dragTarget = getDragTarget(source);
					const dropTarget = getDropTarget(target);

					onDropInternal(dragTarget, dropTarget);
				},
			}),
		[instance, onDragInternal, onDragStartInternal, onDropInternal, onDropTargetChangeInternal],
	);

	useEffect(() => {
		initialize({
			onDragStart,
			onDrag,
			onDrop,
			getIndicators,
		});
	}, [initialize, onDragStart, onDrag, onDrop, getIndicators]);
};

export const useDragAndDropAutoScroll = (
	ref: RefObject<HTMLElement>,
	allowedAxis: AllowedAxis = 'all',
) => {
	const instance = useDragAndDropInstance();

	useEffect(() => {
		if (!ref.current) return;

		const element = ref.current;

		return combine(
			autoScrollForElements({
				element,
				canScroll({ source }) {
					return source.data.instance === instance;
				},
				getAllowedAxis() {
					return allowedAxis;
				},
			}),
		);
	}, [instance, ref, allowedAxis]);
};
