const DOCUMENT_TITLE_DIVIDER_PLANS = ' - Plans - ';

// This function is ran outside of react context and cannot be localized
export const tryExtractPlanTitleFromDocument = (): string | null | undefined => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (document && document.title) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const index = document.title.lastIndexOf(DOCUMENT_TITLE_DIVIDER_PLANS);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		return index === -1 ? undefined : document.title.substring(0, index);
	}
	return undefined;
};
