import React, { memo, useCallback, useState } from 'react';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { performGetRequest, performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import { getUserPropertyEndpoint } from '../review-changes-button-wrapper/review-changes-button/review-changes-spotlight-manager';
import type { ReviewChangesUserProperty } from '../review-changes-button-wrapper/review-changes-button/review-changes-spotlight-manager/types';
import SpotlightChangesCountMonitor from '../review-changes-button-wrapper/spotlight-changes-count-monitor';

const UserPropertyBackFiller = memo(({ changesCount }: { changesCount: number }) => {
	const [shouldMonitorChanges, setShouldMonitorChanges] = useState<boolean>(true);

	const {
		data: {
			user: { accountId },
		},
	} = useCurrentUser();

	const getUserProperty = useCallback(
		() => performGetRequest<ReviewChangesUserProperty>(getUserPropertyEndpoint(accountId ?? '')),
		[accountId],
	);

	const updateUserProperty = useCallback(
		(timestamp: number) =>
			performPutRequest(getUserPropertyEndpoint(accountId ?? ''), {
				body: JSON.stringify({ timestamp }),
			}),
		[accountId],
	);

	const onCountChanged = useCallback(() => {
		setShouldMonitorChanges(false);

		getUserProperty()
			.then((userProperty) => {
				if (!userProperty?.value?.timestamp) {
					updateUserProperty(Date.now());
				}
			})
			.catch((error: FetchError) => {
				if (error?.statusCode === 404) {
					updateUserProperty(Date.now());
				}
			});
	}, [getUserProperty, updateUserProperty]);

	return shouldMonitorChanges ? (
		<SpotlightChangesCountMonitor changesCount={changesCount} onCountChanged={onCountChanged} />
	) : null;
});

export default UserPropertyBackFiller;
