import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import TodayButton from '@atlassian/jira-aais-timeline-toolbar/src/ui/today-button/index.tsx';
import { useToday } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import { UseShortcuts } from '@atlassian/jira-portfolio-3-keyboard-shortcuts/src/controllers';
import KeyboardShortcutTooltip from '@atlassian/jira-portfolio-3-keyboard-shortcuts/src/ui/keyboard-shortcut-tooltip/index.tsx';
import messages from './messages';

export default function TodayButtonWrapper() {
	const [, { backToToday }] = useToday();

	return (
		<Tooltip
			content={<KeyboardShortcutTooltip letter="T" message={messages.todayTooltip} />}
			position="top-end"
		>
			<UseShortcuts shortcutKey="KeyT" onKeyDown={backToToday} />
			<TodayButton
				onClick={backToToday}
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.today-button.today-button"
			/>
		</Tooltip>
	);
}
