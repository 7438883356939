import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types';
import {
	PROJECT_FILTER_ID,
	type ProjectFilter,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { getProjects } from '../../projects';
import { getFiltersViewSettings } from '../../view-settings';
import { applyFilter as applyAttributeFilter } from '../attribute-filter';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { PROJECT_FILTER_ID } from '../../../state/domain/view-settings/filters/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ProjectFilter } from '../../../state/domain/view-settings/filters/types';

export const applyFilter = (issue: Issue, filter: ProjectFilter, optimizedMode: boolean): boolean =>
	applyAttributeFilter(issue, filter.value, 'project', optimizedMode);

export const getProjectFilterPure = (state: State): ProjectFilter =>
	getFiltersViewSettings(state)[PROJECT_FILTER_ID] || {
		id: PROJECT_FILTER_ID,
		value: [],
	};

export const getProjectFilter = createSelector(
	[getProjects, getProjectFilterPure],
	(projects: Project[], pureFilter: ProjectFilter): ProjectFilter => {
		const { value = [] } = pureFilter;
		const filteredProjects = value.filter((projectId: number) =>
			projects.some(({ id }) => id === projectId),
		);
		return {
			id: PROJECT_FILTER_ID,
			value: filteredProjects,
		};
	},
);

export const getFilteredProjectsPure = (projects: Project[], { value }: ProjectFilter): Project[] =>
	projects.filter(({ id }) => value.length === 0 || value.includes(id));

export const getFilteredProjects = createSelector(
	[getProjects, getProjectFilter],
	getFilteredProjectsPure,
);
