import {
	isExportMode,
	getMode,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app';
import { getScope } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope';
import { EDIT } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';

export default createSelector(
	[getScope, isExportMode, getMode],
	({ startLevelIssues: { rootIssuesCount } }, exportMode, mode) => ({
		rankDigits: rootIssuesCount.toString().length,
		isReadOnly: mode !== EDIT,
		isExportMode: exportMode,
	}),
);
