import * as R from 'ramda';
import type { IssueStatus } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import {
	ascend,
	sortWith,
	isDefined,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { IssueId } from '@atlassian/jira-portfolio-3-portfolio/src/common/types';
import {
	SORT_DIRECTION,
	ISSUE_FIELD_TYPES,
	ISSUE_STATUS_CATEGORIES,
	PlanningUnits,
	type PlanningUnit,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import type { Person } from '../../state/domain/assignees/types.tsx';
import { type Issue, ISSUE_FIELDS } from '../../state/domain/issues/types';
import type { Adjustments } from '../../state/domain/lexorank/types';
import type { DateConfiguration } from '../../state/domain/plan/types.tsx';
import type { SelectOption } from '../../state/domain/select-options/types.tsx';
import type { Team } from '../../state/domain/teams/types.tsx';
import type { Sorting } from '../../state/domain/view-settings/visualisations/types';
import type { State } from '../../state/types';
import { getAssigneesById } from '../assignees';
import { getSelectOptionsById } from '../custom-fields';
import { getIssuePrioritiesIdList } from '../issue-priorities';
import { getIssueStatusById } from '../issue-statuses';
import type { RollUps } from '../issues/types';
import { getIssueLexoRankAdjustments as getLexoRankAdjustments } from '../lexorank';
import { getDateConfiguration, getPlanningUnit } from '../plan';
import { getAllTeamsById } from '../teams';
import { getShowRolledUpOthers, getVisualisationViewSettings } from '../view-settings';
import { getRollupMap } from './issues-tree';

export type IssueMap = Record<IssueId, Issue>;

export type SortIssues = (arg1: Issue[]) => Issue[];
type ComparatorFn<T = Issue> = (
	comparator: (issue: T) => string | number,
) => (issue1: T, issue2: T) => number;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EntityById = Record<string, any>;

export const getFieldKey = (
	fieldId: string,
	planningUnit?: (typeof PlanningUnits)[keyof typeof PlanningUnits],
) => {
	if (fieldId === ISSUE_FIELDS.ESTIMATE) {
		return planningUnit === PlanningUnits.storyPoints ? 'storyPoints' : 'timeEstimate';
	}
	return fieldId;
};

export const getField = (isCustomField: boolean | null | undefined, field: string, issue: Issue) =>
	isCustomField
		? R.path<NonNullable<Issue['customFields']>[string]>(['customFields', field], issue)
		: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			issue[field as keyof Issue];

export const getSortByTeam =
	(allTeamsById: EntityById, direction: Sorting['direction']) =>
	(comparatorFunction: ComparatorFn<string>) => [
		// we need this custom logic because issues without team should
		// be at the end if sort is ascending and first if sort is descending (JPOS-3338)
		({ team: teamOfIssueA }: Issue, { team: teamOfIssueB }: Issue) => {
			if (teamOfIssueA && teamOfIssueB) {
				return comparatorFunction((team) =>
					R.pathOr('', [team, 'title'], allTeamsById).toLowerCase(),
				)(teamOfIssueA, teamOfIssueB);
			}
			if (!teamOfIssueA && !teamOfIssueB) {
				return 0;
			}
			if (direction === SORT_DIRECTION.ASCENDING) {
				return teamOfIssueA ? -1 : 1;
			}
			return teamOfIssueA ? 1 : -1;
		},
	];

export const getSortByPriority =
	(issuePrioritiesIdList: string[]) => (comparatorFunction: ComparatorFn) => [
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		comparatorFunction(({ priority }) => issuePrioritiesIdList.indexOf(priority!)),
	];

export const getSortByStatus =
	(issueStatusById: EntityById) => (comparatorFunction: ComparatorFn) => {
		const categoriesMap = {
			[ISSUE_STATUS_CATEGORIES.TODO]: 1,
			[ISSUE_STATUS_CATEGORIES.INPROGRESS]: 2,
			[ISSUE_STATUS_CATEGORIES.DONE]: 3,
		};
		return [
			comparatorFunction(({ status }) => {
				if (!isDefined(status)) {
					return 0;
				}
				const issueStatus = issueStatusById[status];
				if (!isDefined(issueStatus)) {
					return 0;
				}
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				return categoriesMap[issueStatus.categoryId as keyof typeof categoriesMap];
			}),
			comparatorFunction(({ status }) => {
				if (!isDefined(status)) {
					return '';
				}
				return R.pathOr('', [status, 'name'], issueStatusById).toLowerCase();
			}),
		] as const;
	};

export const getSortByAssignee =
	(assigneesById: EntityById, direction: Sorting['direction']) =>
	(comparatorFunction: ComparatorFn<string>) => [
		// we need this custom logic because issues that are unassigned should
		// be at the end if sort is ascending and first if sort is descending (JPOS-4280)
		({ assignee: assigneeOfIssueA }: Issue, { assignee: assigneeOfIssueB }: Issue) => {
			if (assigneeOfIssueA !== 'unassigned' && assigneeOfIssueB !== 'unassigned') {
				return comparatorFunction((assignee) =>
					R.pathOr('', [assignee, 'jiraUser', 'title'], assigneesById).toLowerCase(),
				)(assigneeOfIssueA, assigneeOfIssueB);
			}
			if (assigneeOfIssueA === 'unassigned' && assigneeOfIssueB === 'unassigned') {
				return 0;
			}
			if (direction === SORT_DIRECTION.ASCENDING) {
				return assigneeOfIssueA === 'unassigned' ? 1 : -1;
			}
			return assigneeOfIssueA === 'unassigned' ? -1 : 1;
		},
	];

/** CAUTION:
 *  This query is defined here to avoid circular dependency on app-simple-plans/query/visualisations.
 *  This query is also defined in app-simple-plans/query/visualisations.
 */
export const getVisualisationSorting = (state: State): Sorting =>
	getVisualisationViewSettings(state).sorting;

export const ascendWithNull = R.curryN(
	3,
	(fn: (value?: Issue | null) => number, a: Issue, b: Issue) => {
		// function does not guarantee strict order between null and undefined
		const aa = fn(a);
		const bb = fn(b);

		if (R.isNil(aa) && R.isNil(bb)) return 0;
		if (R.isNil(aa)) return -1;
		if (R.isNil(bb)) return 1;
		if (aa < bb) return -1;
		if (aa > bb) return 1;
		return 0;
	},
);

export const descendWithNull = R.curryN(
	3,
	(fn: (value?: Issue | null) => number, a: Issue, b: Issue) => {
		// function does not guarantee strict order between null and undefined
		const aa = fn(a);
		const bb = fn(b);

		if (R.isNil(aa) && R.isNil(bb)) return 0;
		if (R.isNil(aa)) return 1;
		if (R.isNil(bb)) return -1;
		if (aa > bb) return -1;
		if (aa < bb) return 1;
		return 0;
	},
);

export const getSortPure = (
	lexoRankAdjustments: Adjustments,
	sorting: Sorting,
	dateConfiguration: DateConfiguration,
	issueStatusById: Record<string, IssueStatus>,
	showRolledUpOthers = false,
	planningUnit: PlanningUnit,
	rollupMap: RollUps,
	issuePrioritiesIdList: string[] | null | undefined,
	allTeamsById: Record<string, Team>,
	assigneesById: Record<string, Person>,
	selectOptionsById: Record<string, SelectOption>,
): SortIssues => {
	const comparatorFunction =
		isDefined(sorting.direction) && sorting.direction === SORT_DIRECTION.DESCENDING
			? descendWithNull
			: ascendWithNull;
	const defaultSortFunctions = [
		ascend<Issue, string | number>(({ lexoRank }) => lexoRank || ''),
		ascend<Issue, string | number>(({ id }) => lexoRankAdjustments.indexOf(id)),
	];
	const {
		baselineStartField: { key: configuredStartField },
		baselineEndField: { key: configuredEndField },
	} = dateConfiguration;

	const sortByStringFunctionsMap = {
		[ISSUE_FIELDS.PRIORITY_FIELD]: issuePrioritiesIdList
			? getSortByPriority(R.reverse(issuePrioritiesIdList))
			: () => [],
		[ISSUE_FIELDS.TEAM_FIELD]: getSortByTeam(allTeamsById, sorting.direction),
		[ISSUE_FIELDS.STATUS_FIELD]: getSortByStatus(issueStatusById),
		[ISSUE_FIELDS.ASSIGNEE_FIELD]: getSortByAssignee(assigneesById, sorting.direction),
	};

	switch (sorting.type) {
		/** When a new data type is supported for sorting,
		 *  add relevant system fields for which sort is supported and "customfield_{type}" to
		 *  portfolio-2/portfolio/portfolio-plugin/src/main/resources/analytics/whitelist.js
		 *
		 *  Example: If support for string is added, then
		 *  add "customfield_string" and all the issue fields, whose data type is string and
		 *  are added to sort by option in view settings to p4j.viewRoadmap.page (sortByField)
		 *  and p4j.sortField.button (field)
		 *  events in whitelist.js
		 */
		case ISSUE_FIELD_TYPES.DATE:
			return sortWith([
				comparatorFunction((issue: Issue) => {
					if (sorting.field === configuredStartField) {
						return issue.baselineStart || 0;
					}
					if (sorting.field === configuredEndField) {
						return issue.baselineEnd || 0;
					}
					const dateAttribute = getField(sorting.isCustomField, sorting.field, issue);

					return dateAttribute || 0;
				}),
				...defaultSortFunctions,
			]);
		case ISSUE_FIELD_TYPES.STRING: {
			const getSortBy =
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				sortByStringFunctionsMap[sorting.field as keyof typeof sortByStringFunctionsMap];
			if (!getSortBy) {
				return sortWith([
					comparatorFunction((issue: Issue) => {
						const field = getField(sorting.isCustomField, sorting.field, issue);

						if (sorting.isCustomField) {
							const customFieldValue =
								field && selectOptionsById[field] && selectOptionsById[field].value;

							return isDefined(customFieldValue) ? R.toLower(customFieldValue) : customFieldValue;
						}
						return isDefined(field) ? R.toLower(field) : field;
					}),
					...defaultSortFunctions,
				]);
			}
			return sortWith([...getSortBy(comparatorFunction), ...defaultSortFunctions]);
		}

		case ISSUE_FIELD_TYPES.NUMBER:
			return sortWith([
				comparatorFunction((issue: Issue) => {
					const rollupIssueValues = rollupMap[issue.id];
					const attributeKey = sorting.isCustomField
						? sorting.field
						: getFieldKey(sorting.field, planningUnit);
					const value = getField(sorting.isCustomField, attributeKey, issue);
					const isValueDefined = isDefined(value);

					if (showRolledUpOthers) {
						const isRollupValueDefined = isDefined(
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							rollupIssueValues[sorting.field as keyof typeof rollupIssueValues],
						);
						if (!isRollupValueDefined) {
							return value;
						}
						if (!isValueDefined) {
							return rollupIssueValues[
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								sorting.field as keyof typeof rollupIssueValues
							];
						}
						return (
							value +
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							rollupIssueValues[sorting.field as keyof typeof rollupIssueValues]
						);
					}
					return value;
				}),
				...defaultSortFunctions,
			]);
		default:
			return sortWith(defaultSortFunctions);
	}
};

export const getSort = createSelector(
	[
		getLexoRankAdjustments,
		getVisualisationSorting,
		getDateConfiguration,
		getIssueStatusById,
		getShowRolledUpOthers,
		getPlanningUnit,
		getRollupMap,
		getIssuePrioritiesIdList,
		getAllTeamsById,
		getAssigneesById,
		getSelectOptionsById,
	],
	getSortPure,
);
