import { getIssueTypes } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types';
import { getProjects } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export default createStructuredSelector<State, StateProps>({
	projects: getProjects,
	issueTypes: getIssueTypes,
});
