import React, { useCallback, type ChangeEvent } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import {
	type ViewMode,
	VIEW_MODES,
} from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import { UseShortcuts } from '@atlassian/jira-portfolio-3-keyboard-shortcuts/src/controllers';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import ToggleButtons from '@atlassian/jira-toggle-buttons';
import usePressTracing from '@atlassian/react-ufo/use-press-tracing';
import messages from './messages';
import type { Props } from './types';

export const ViewModeSwitcher = ({ mode, changeViewMode }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const tracePress = usePressTracing('arj-toggle-view-mode');

	const testIdPrefix =
		'portfolio-3-portfolio.app-simple-plans.top.page-header.view-mode-switcher.toggle-button.';

	const options = [
		{
			id: VIEW_MODES.TIMELINE,
			label: formatMessage(commonMessages.timeline),
			testId: `${testIdPrefix}${VIEW_MODES.TIMELINE}`,
		},
		{
			id: VIEW_MODES.LIST,
			label: formatMessage(messages.listOptionLabel),
			testId: `${testIdPrefix}${VIEW_MODES.LIST}`,
		},
	];

	const onChange = useCallback(
		(
			viewMode: string,
			event: ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>,
		) => {
			tracePress(event.timeStamp);
			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			});
			fireUIAnalytics(analyticsEvent, 'viewMode', { viewMode });

			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			changeViewMode(viewMode as ViewMode);
		},
		[tracePress, changeViewMode, createAnalyticsEvent],
	);

	const onKeyDown = useCallback(
		(event: KeyboardEvent) => {
			tracePress(event.timeStamp);
			const viewMode = mode === VIEW_MODES.TIMELINE ? VIEW_MODES.LIST : VIEW_MODES.TIMELINE;

			changeViewMode(viewMode);
		},
		[tracePress, changeViewMode, mode],
	);

	return (
		<>
			<UseShortcuts shortcutKey="KeyL" onKeyDown={onKeyDown} />
			<ToggleButtons
				label={formatMessage(messages.toggleButtonsLabel)}
				options={options}
				selectedOption={mode}
				onChange={onChange}
			/>
		</>
	);
};
