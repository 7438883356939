import { TIMELINE_SHARE_TYPE } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import { SET_CONFLUENCE_MACRO_TYPE, type ConfluenceMacroTypeAction } from './actions';
import type { State } from './types';

const initialState: State = {
	confluenceMacroType: TIMELINE_SHARE_TYPE,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = initialState, action: ConfluenceMacroTypeAction) => {
	switch (action.type) {
		case SET_CONFLUENCE_MACRO_TYPE:
			return {
				...state,
				confluenceMacroType: action.payload,
			};
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
