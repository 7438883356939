import type { Scenario as PlanScenario } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import type { PlanInfo } from './types';

export const RESET = 'state.domain.plan.RESET' as const;
export const UPDATE = 'state.domain.plan.UPDATE' as const;
export const ADD_PLAN_SCENARIO = 'state.domain.plan.ADD_PLAN_SCENARIO' as const;
export const ENABLE_PLAN_SCENARIOS = 'state.domain.plan.ENABLE_PLAN_SCENARIOS' as const;
export const REMOVE_PLAN_SCENARIO = 'state.domain.plan.REMOVE_PLAN_SCENARIO' as const;
export const DISABLE_PLAN_SCENARIOS = 'state.domain.plan.DISABLE_PLAN_SCENARIOS' as const;
export const UPDATE_PLAN_SCENARIO = 'state.domain.plan.UPDATE_PLAN_SCENARIO' as const;
export const UPDATE_AUTO_SCHEDULER_ENABLED =
	'state.domain.plan.UPDATE_AUTO_SCHEDULER_ENABLED' as const;

export type ResetActionPayload = PlanInfo;

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export const reset = (payload: ResetActionPayload) => ({
	type: RESET,
	payload,
});

// NOTE Usually we use $Shape for such cases, but it doesn't play well with covariant keys/$ReadOnly
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UpdateActionPayload = any;

export type UpdateAction = {
	type: typeof UPDATE;
	payload: UpdateActionPayload;
};

export const update = (payload: UpdateActionPayload) => ({
	type: UPDATE,
	payload,
});

export type UpdateAutoSchedulerEnabledPayload = {
	autoSchedulerEnabled: boolean;
};

export type UpdateAutoSchedulerEnabledAction = {
	type: typeof UPDATE_AUTO_SCHEDULER_ENABLED;
	payload: UpdateAutoSchedulerEnabledPayload;
};

export const updateAutoSchedulerEnabled = (
	payload: UpdateAutoSchedulerEnabledPayload,
): UpdateAutoSchedulerEnabledAction => ({
	type: UPDATE_AUTO_SCHEDULER_ENABLED,
	payload,
});

export type AddPlanScenarioAction = {
	type: typeof ADD_PLAN_SCENARIO;
	payload: PlanScenario;
};

export const addPlanScenario = (payload: PlanScenario): AddPlanScenarioAction => ({
	type: ADD_PLAN_SCENARIO,
	payload,
});

export type EnablePlanScenariosAction = {
	type: typeof ENABLE_PLAN_SCENARIOS;
	payload: PlanScenario[];
};

export const enablePlanScenarios = (payload: PlanScenario[]): EnablePlanScenariosAction => ({
	type: ENABLE_PLAN_SCENARIOS,
	payload,
});

export type RemovePlanScenarioAction = {
	type: typeof REMOVE_PLAN_SCENARIO;
	payload: PlanScenario;
};

export const removePlanScenario = (payload: PlanScenario): RemovePlanScenarioAction => ({
	type: REMOVE_PLAN_SCENARIO,
	payload,
});

export type DisablePlanScenariosAction = {
	type: typeof DISABLE_PLAN_SCENARIOS;
	scenarioToKeepId: number;
};

export const disablePlanScenarios = (scenarioToKeepId: number): DisablePlanScenariosAction => ({
	type: DISABLE_PLAN_SCENARIOS,
	scenarioToKeepId,
});

export type UpdatePlanScenarioPayload = {
	id: number;
	color?: string;
	title?: string;
};

export type UpdatePlanScenarioAction = {
	type: typeof UPDATE_PLAN_SCENARIO;
	payload: UpdatePlanScenarioPayload;
};

export const updatePlanScenario = (
	payload: UpdatePlanScenarioPayload,
): UpdatePlanScenarioAction => ({
	type: UPDATE_PLAN_SCENARIO,
	payload,
});
