import {
	START,
	type StartAction,
	INTERRUPT,
	type InterruptAction,
	STOP,
	type StopAction,
	INCREMENT_NUMBER_OF_COMMITTED_CHANGES,
	type IncrementAction,
} from './actions';
import type { Commit } from './types';

type Action = StartAction | InterruptAction | IncrementAction | StopAction;

const initialState: Commit = {
	inProgress: false,
	isInterrupted: false,
	progressStats: {
		numberOfCommittedChanges: 0,
		totalChanges: 0,
	},
	changesCommitted: 0,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: Commit = initialState, action: Action) => {
	switch (action.type) {
		case START:
			return {
				...state,
				inProgress: true,
				progressStats: {
					numberOfCommittedChanges: 0,
					totalChanges: action.payload,
				},
			};
		case INCREMENT_NUMBER_OF_COMMITTED_CHANGES:
			return {
				...state,
				progressStats: {
					...state.progressStats,
					numberOfCommittedChanges: state.progressStats.numberOfCommittedChanges + 1,
				},
			};
		case INTERRUPT:
			return {
				...state,
				isInterrupted: true,
			};
		case STOP:
			return {
				...state,
				inProgress: false,
				isInterrupted: false,
				progressStats: {
					...initialState.progressStats,
				},
				changesCommitted: state.progressStats.numberOfCommittedChanges,
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
