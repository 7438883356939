import type {
	CrossProjectVersion,
	NewCrossProjectVersion,
	CrossProjectVersionPatch,
} from '../../state/domain/cross-project-versions/types.tsx';
import type { Plan } from '../../state/domain/plan/types';

export const urls = {
	add: '/rest/jpo/1.0/xprojectversions/add',
	update: '/rest/jpo/1.0/xprojectversions/update',
	delete: '/rest/jpo/1.0/xprojectversions/delete',
	revertChanges: '/rest/jpo/1.0/xprojectversions/revert',
} as const;

export type AddReleaseCommandPayload = NewCrossProjectVersion;
export type UpdateReleaseCommandPayload = CrossProjectVersionPatch;
export type DeleteReleaseCommandPayload = {
	crossProjectVersion: CrossProjectVersion;
	doesRemoveVersions: boolean;
};

export const addVersionBody = (plan: Plan, { name, versions }: AddReleaseCommandPayload) => {
	const { id: planId, currentScenarioId: scenarioId } = plan;

	return {
		planId,
		scenarioId,
		description: {
			name: {
				value: name,
			},
		},
		versions,
	};
};

export const updateVersionBody = (
	plan: Plan,
	{ versionId, patch }: UpdateReleaseCommandPayload,
) => {
	const { id: planId, currentScenarioId: scenarioId } = plan;

	return {
		planId,
		scenarioId,
		itemKeys: [versionId],
		description: {
			name: {
				value: patch.name,
			},
		},
	};
};

export const deleteVersionBody = (
	{ id: planId, currentScenarioId: scenarioId }: Plan,
	{ crossProjectVersion, doesRemoveVersions }: DeleteReleaseCommandPayload,
) => {
	const { id: versionId, versions: projectVersions } = crossProjectVersion;

	if (doesRemoveVersions) {
		return {
			itemKeys: [versionId],
			planId,
			scenarioId,
			versionItemKeys: projectVersions,
		};
	}
	return {
		itemKeys: [versionId],
		planId,
		scenarioId,
	};
};
