import { getCrossProjectVersionsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/cross-project-versions';
import {
	getSelectedIssues,
	getCPRsForSelectedIssues,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export default createStructuredSelector<State, StateProps>({
	selectedIssues: getSelectedIssues,
	crossProjectVersionsById: getCrossProjectVersionsById,
	cpVersionsForSelectedIssues: getCPRsForSelectedIssues,
});
