import * as R from 'ramda';
import { filterMap, isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import type { JiraUser, Person, TransformedJiraUser } from '../../state/domain/assignees/types.tsx';

export const transformPersons = (persons: Person[]): TransformedJiraUser[] =>
	filterMap(
		(person) => isDefined(person.jiraUser),
		(person) => ({
			value: person.jiraUser.accountId,
			label: person.jiraUser.title,
			icon: person.jiraUser.avatarUrl,
			email: person.jiraUser.email,
		}),
		persons,
	);

const sortByPersonTitle = R.sortBy((el) => R.toLower(el.jiraUser.title));
const sortByAssigned = R.sortBy(R.pipe(R.propEq('personId', 'unassigned'), R.not));

export const sort: (arg1: Person[]) => Person[] = R.pipe(sortByPersonTitle, sortByAssigned);

/*
<
    Person,
    JiraUser,
    [JiraUser['email'], JiraUser['title']],
    string,
    string
>
*/

const getFullText = R.pipe(
	R.prop<'jiraUser', JiraUser>('jiraUser'),
	(user) => [user?.email ?? '', user.title],
	R.join(' '),
	R.toLower,
);

const matchQuery = R.curry((query: string, person: Person): boolean =>
	R.includes(R.toLower(query))(getFullText(person)),
);

export const filter: (arg1: string, arg2: Person[]) => Person[] = R.curry(
	(query: string, people: Person[]): Person[] => R.filter(matchQuery(query))(people),
);
