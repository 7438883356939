import { getFilterableCustomFields } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields';
import { getIssuePrioritiesIdList } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-priorities';
import { getViewMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings';
import {
	getColorByValue,
	getColourMaps,
	getColourPriorities,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { OwnProps, StateProps } from './types';

export default createStructuredSelector<State, OwnProps, StateProps>({
	colourByValue: getColorByValue,
	colourMaps: getColourMaps,
	colourPriorities: getColourPriorities,
	filterableCustomFields: getFilterableCustomFields,
	issuePrioritiesIdList: getIssuePrioritiesIdList,
	viewMode: getViewMode,
});
