import { useEffect } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log';
import { getIsIssuesLoading } from '../../query/issues';
import store from '../../state/store';
import type { Props } from './types';
import { useReduxStateCache } from './utils';

export const ReduxStoreLifecycle = ({
	locale,
	initialLoad,
	restoreReduxStore,
	resetReduxStore,
	changeActiveFilterView,
	planView,
}: Props) => {
	const [cachedState, cacheState] = useReduxStateCache();
	useEffect(() => {
		if (cachedState) {
			try {
				if (!cachedState.domain.plan.id) {
					log.safeErrorWithoutCustomerData(
						'plans.plan-id-not-available', // Using this name for easier querying
						'Plan id is not available while restoring Redux store state',
						{
							loading: getIsIssuesLoading(cachedState),
						},
					);
				}
			} catch (err) {
				// not necessary
			}
			restoreReduxStore(cachedState);
			if (planView) {
				changeActiveFilterView(planView);
			}
		} else {
			initialLoad({ locale, planView });
		}
	}, [cachedState, initialLoad, locale, restoreReduxStore, planView, changeActiveFilterView]);

	useEffect(
		() => () => {
			const state = store.getState();
			// Only cache when the plan is done loading
			cacheState(getIsIssuesLoading(state) ? undefined : state);
			try {
				if (!state.domain.plan.id) {
					log.safeErrorWithoutCustomerData(
						'plans.plan-id-not-available', // Using this name for easier querying
						'Plan id is not available while caching Redux store state',
						{
							loading: getIsIssuesLoading(state),
						},
					);
				}
			} catch (err) {
				// not necessary
			}
		},
		[cacheState],
	);

	// Reset the store on unmount to ensure we don't have data
	// of the current plan when switching into another plan.
	useEffect(
		() => () => {
			resetReduxStore();
		},
		[resetReduxStore],
	);

	return null;
};
