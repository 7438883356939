import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Text } from '@atlaskit/primitives';
import type { Goal } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-goals/types';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip';
import type { Props } from './types';

export const GoalComponent = ({ goals, lazyGoalsByARI }: Props) => {
	const allGoals = goals.reduce<Goal[]>((acc, goalARI) => {
		const lazyGoal = lazyGoalsByARI[goalARI]?.goal;
		if (lazyGoal) {
			acc.push(lazyGoal);
		}
		return acc;
	}, []);

	if (isEmpty(allGoals)) {
		return <Text>-</Text>;
	}
	const sortedGoalNames = allGoals
		.map((goal) => goal.name)
		.sort()
		.join(', ');
	const sortedGoalKeys = allGoals
		.map((goal) => goal.key)
		.sort()
		.join(', ');
	return (
		<EllipsedWithTooltip content={sortedGoalNames}>
			<Text>{sortedGoalKeys}</Text>
		</EllipsedWithTooltip>
	);
};
