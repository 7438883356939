import * as R from 'ramda';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { Issue } from '../../state/domain/issues/types';
import type { ScopeIssue } from '../../state/domain/scope/types';
import type { State } from '../../state/types';
import { getIssueSources } from '../plan';
import { getDefaultIterationLength } from '../plan-defaults';
import { getTeams } from '../teams';
import type { TimelineSprint } from './types';
import { getSprintsWithFutureDatesPure, getSprintsForTeamPure } from './utils';

// These selectors exist in this file to prevent a cyclic dependency between `query/sprints/index` and `query/timeline/index`

export const getSprints = (state: State) => state.domain.sprints;

export const getExternalSprints = (state: State) => state.domain.externalSprints;

export const getSprintsForTeam = createSelector(
	[getTeams, getIssueSources, getSprints],
	getSprintsForTeamPure,
);

export const getSprintsWithFutureDates = createSelector(
	[getSprintsForTeam, getTeams, getIssueSources, getSprints, getDefaultIterationLength],
	getSprintsWithFutureDatesPure,
);

export const getFutureSprintsForIssue = (
	{ team, sprint, completedSprints }: Issue | ScopeIssue,
	sprintsWithDates: {
		team: Record<string, TimelineSprint[]>;
		sprint: Record<string, TimelineSprint[]>;
	},
) => {
	const requiredSprintsForIssue = [...(completedSprints || []), ...(sprint ? [sprint] : [])];

	if (isDefined(team)) {
		const futureSprintsForTeam = sprintsWithDates.team[team];
		if (isDefined(futureSprintsForTeam)) {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const sprintIds = futureSprintsForTeam.map((sprint) => sprint.id);
			if (requiredSprintsForIssue.some((sprintId) => sprintIds.includes(sprintId))) {
				return futureSprintsForTeam;
			}
		}
	}

	if (isDefined(sprint)) {
		const futureSprintsBasedOnIssueSource = sprintsWithDates.sprint[sprint];
		if (isDefined(futureSprintsBasedOnIssueSource)) {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const sprintIds = futureSprintsBasedOnIssueSource.map((sprint) => sprint.id);
			if (requiredSprintsForIssue.some((sprintId) => sprintIds.includes(sprintId))) {
				return futureSprintsBasedOnIssueSource;
			}
		}
	}

	if (completedSprints && completedSprints.length) {
		const hasSprints = (id: string) =>
			!!sprintsWithDates.sprint[id] &&
			R.any((el: TimelineSprint) => R.includes(R.prop('id', el))(requiredSprintsForIssue))(
				sprintsWithDates.sprint[id],
			);
		return R.pipe(R.takeWhile(hasSprints), R.head, (id: string) =>
			id ? sprintsWithDates.sprint[id] : [],
		)(completedSprints);
	}

	return [];
};
