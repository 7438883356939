import React from 'react';
import noop from 'lodash/noop';
import * as R from 'ramda';
import { fg } from '@atlassian/jira-feature-gating';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import type { IssueStatusesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-statuses/types';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { ColourMaps } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/colour-by/types';
import {
	getCurrentValue,
	hasValueChanged,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/util';
import { ISSUE_INFERRED_DATE_SELECTION } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import type { Timestamp } from '@atlassian/jira-portfolio-3-portfolio/src/common/types';
import BaseComponent from '@atlassian/jira-portfolio-3-portfolio/src/common/view/base-component';
import {
	colourByOptions,
	DEFAULT_COLOR,
	OPTIMIZED_COLOR,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colours';
import { PREVIEW_OPACITY } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import BothDatesRow from './both-dates';
import Container from './container';
import BarFlyout from './flyout';
import NoDatesRow from './no-dates';
import PartialDatesRow from './partial-dates';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';
import { getForcedGradients } from './utils';

export const getIssueBarStatusColour = (
	issue: ScopeIssue,
	issueStatuses: IssueStatusesById,
	colourMap: ColourMaps,
) => {
	const issueCategoryId =
		isDefined(issue) &&
		isDefined(issue.status) &&
		isDefined(issueStatuses) &&
		!R.isEmpty(issueStatuses) &&
		isDefined(issueStatuses[issue.status]) &&
		issueStatuses[issue.status].categoryId;

	const issueStatusColour =
		isDefined(colourMap) &&
		!R.isEmpty(colourMap) &&
		isDefined(colourMap.status) &&
		!R.isEmpty(colourMap.status) &&
		issueCategoryId &&
		colourMap.status[issueCategoryId.toString()];

	// Not using tokens because bar gradients use mathematical calulcations which rely on hex
	return issueStatusColour || colors.N500;
};

const getColour = (
	issue: ScopeIssue,
	colourByValue: string,
	issueStatuses: IssueStatusesById,
	colourMap: ColourMaps,
	epicColor: string | undefined,
) => {
	switch (colourByValue) {
		case colourByOptions.PROJECT:
			if (issue.project) {
				return colourMap.project[issue.project.toString()];
			}
			break;
		case colourByOptions.STATUS:
			return getIssueBarStatusColour(issue, issueStatuses, colourMap);
		case colourByOptions.TEAM:
			if (issue.team) {
				return colourMap.team[issue.team];
			}
			break;
		case colourByOptions.EPIC:
			if (epicColor) {
				return epicColor;
			}
			break;
		default:
			return DEFAULT_COLOR;
	}
};

// eslint-disable-next-line jira/react/no-class-components
export default class Row extends BaseComponent<Props, {}> {
	static defaultProps = {
		isReadOnly: false,
		showOptimizations: false,
		isBeingInteractedWith: false,
		issueWarnings: [],
		disableOtherRows: noop,
		reEnableOtherRows: noop,
		BothDatesRow,
		PartialDatesRow,
		NoDatesRow,
	};

	areDatesInferredFromRollUp = (): boolean => {
		const {
			issue: { inferred: { baselineStart, baselineEnd } = {} },
		} = this.props;

		return (
			baselineStart === ISSUE_INFERRED_DATE_SELECTION.ROLL_UP ||
			baselineEnd === ISSUE_INFERRED_DATE_SELECTION.ROLL_UP
		);
	};

	getInnerView = (innerViewProps: {
		baselineStart: Timestamp | null | undefined;
		baselineEnd: Timestamp | null | undefined;
		showActiveSprintOnTooltip: boolean;
	}) => {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const { BothDatesRow, PartialDatesRow, NoDatesRow } = this.props;
		const { baselineStart, baselineEnd, showActiveSprintOnTooltip } = innerViewProps;
		if (
			isDefined(baselineStart) &&
			isDefined(baselineEnd) &&
			fg('due_date_warning_on_plan_timeline')
		) {
			// If baselineEnd is before baselineStart, we swap them for display in the timeline to ensure
			// the bar does not appear 'broken' - but display an appropriate warning next to the issue key.
			const minBaselineStart = Math.min(baselineStart, baselineEnd);
			const maxBaselineEnd = Math.max(baselineStart, baselineEnd);
			return (
				<BothDatesRow
					{...innerViewProps}
					showActiveSprintOnTooltip={showActiveSprintOnTooltip}
					baselineStart={minBaselineStart}
					baselineEnd={maxBaselineEnd}
				/>
			);
		}
		if (
			isDefined(baselineStart) &&
			isDefined(baselineEnd) &&
			baselineStart <= baselineEnd &&
			!fg('due_date_warning_on_plan_timeline')
		) {
			return (
				<BothDatesRow {...innerViewProps} showActiveSprintOnTooltip={showActiveSprintOnTooltip} />
			);
		}
		if (isDefined(baselineStart) || isDefined(baselineEnd)) {
			return <PartialDatesRow {...innerViewProps} />;
		}
		return <NoDatesRow {...innerViewProps} />;
	};

	getRollUpInnerView = (innerViewProps: {
		baselineStart: Timestamp | null | undefined;
		baselineEnd: Timestamp | null | undefined;
		issueChildren: Issue[];
		showActiveSprintOnTooltip: boolean;
	}) => {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const { issue, BothDatesRow } = this.props;
		const { baselineStart, baselineEnd, issueChildren, showActiveSprintOnTooltip } = innerViewProps;
		const forcedGradients = getForcedGradients({ baselineStart, baselineEnd }, issueChildren);

		const start = R.defaultTo(forcedGradients.earliestStart, baselineStart);
		const end = R.defaultTo(forcedGradients.latestEnd, baselineEnd);

		if (start <= end) {
			return (
				<BothDatesRow
					{...innerViewProps}
					baselineStart={start}
					baselineEnd={end}
					forcedGradients={forcedGradients}
					showActiveSprintOnTooltip={showActiveSprintOnTooltip}
					datesInferredFrom={issue.inferred}
				/>
			);
		}
		return null;
	};

	render() {
		const {
			issue,
			issueChildren,
			timelineRange,
			backgroundColor,
			preview,
			showOptimizations,
			disableOtherRows,
			reEnableOtherRows,
			isBeingInteractedWith,
			isReadOnly,
			issueWarnings,
			externalIncomingLinks,
			externalOutgoingLinks,
			internalIncomingLinks,
			internalIncomingLinksOriginal,
			internalOutgoingLinks,
			internalOutgoingLinksOriginal,
			width,
			colourByValue,
			issueStatuses,
			colourMap,
			barColors,
			showWarning,
			topOffset,
			epicColor,
			hasDependencyLineDragPreview,
		} = this.props;

		const hasWarnings = issueWarnings.length > 0 && showWarning;
		let hasOptimizedChanges = false;
		if (showOptimizations && issue.optimized) {
			for (const [optimizedKey, optimizedValue] of Object.entries(issue.optimized)) {
				if (hasValueChanged(getCurrentValue(issue, optimizedKey), optimizedValue)) {
					hasOptimizedChanges = true;
					break;
				}
			}
		}

		let barColor: string = DEFAULT_COLOR;
		let adjustedBarColors;
		const showStripesInBar = { left: false, right: false };

		if (!showOptimizations) {
			barColor =
				getColour(issue, colourByValue, issueStatuses, colourMap, epicColor) ?? DEFAULT_COLOR;
			adjustedBarColors = barColors;
		}

		if (hasWarnings && colourByValue === colourByOptions.NONE) {
			// Disabled because bar gradients use mathematical calulcations which rely on hex
			barColor = colors.Y300;
			adjustedBarColors = null;
			if (showOptimizations && hasOptimizedChanges) {
				showStripesInBar.left = true;
				showStripesInBar.right = true;
			}
			if (issue.inferred) {
				showStripesInBar.left =
					issue.inferred.baselineStart === ISSUE_INFERRED_DATE_SELECTION.ROLL_UP;
				showStripesInBar.right =
					issue.inferred.baselineEnd === ISSUE_INFERRED_DATE_SELECTION.ROLL_UP;
			}
		} else if (hasOptimizedChanges) {
			barColor = OPTIMIZED_COLOR;
			adjustedBarColors = null;
			showStripesInBar.left = true;
			showStripesInBar.right = true;
		} else if (issue.inferred) {
			showStripesInBar.left =
				issue.inferred.baselineStart === ISSUE_INFERRED_DATE_SELECTION.ROLL_UP;
			showStripesInBar.right = issue.inferred.baselineEnd === ISSUE_INFERRED_DATE_SELECTION.ROLL_UP;
		}

		const hasOptimizedDates =
			showOptimizations &&
			(isDefined(issue.optimized && issue.optimized.baselineStart) ||
				isDefined(issue.optimized && issue.optimized.baselineEnd));

		const { baselineStart, baselineEnd } =
			hasOptimizedDates && issue.optimized ? issue.optimized : issue;

		// When baselineStart and baselineEnd (aka targetStart and targetEnd) dates are not defined
		// but if there is a sprint assigned, we calculate the target start and target end dates
		// and draw the bar based on them
		const showActiveSprintOnTooltip = Boolean(
			issue.inferred &&
				(issue.inferred.baselineStart === ISSUE_INFERRED_DATE_SELECTION.SPRINT ||
					issue.inferred.baselineEnd === ISSUE_INFERRED_DATE_SELECTION.SPRINT),
		);

		let renderFlyout;

		if (hasOptimizedChanges) {
			renderFlyout = () => <BarFlyout issue={issue} />;
		}

		const opacity = preview ? PREVIEW_OPACITY : 1;

		const innerViewProps = {
			issue,
			issueChildren,
			baselineStart,
			baselineEnd,
			timelineRange,
			barColor,
			barColors: adjustedBarColors,
			showStripesInBar,
			preview,
			renderFlyout,
			disableOtherRows,
			reEnableOtherRows,
			isBeingInteractedWith,
			isReadOnly,
			externalIncomingLinks,
			externalOutgoingLinks,
			internalIncomingLinks,
			internalIncomingLinksOriginal,
			internalOutgoingLinks,
			internalOutgoingLinksOriginal,
			width,
			opacity,
			showActiveSprintOnTooltip,
			topOffset,
			hasDependencyLineDragPreview,
		};

		const innerView = this.areDatesInferredFromRollUp()
			? this.getRollUpInnerView(innerViewProps)
			: this.getInnerView(innerViewProps);

		return (
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.row}
				data-issue={issue.id}
				data-group={issue.group}
				data-testid={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.${issue.id}`}
			>
				<Container bgColor={backgroundColor}>{innerView}</Container>
			</div>
		);
	}
}
