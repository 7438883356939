import React from 'react';
import { injectIntl, FormattedMessage } from '@atlassian/jira-intl';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import type { Props } from './types';

function NotInPlanMessage(_: Props) {
	return <FormattedMessage {...commonMessages.notInPlan} />;
}

export default injectIntl(NotInPlanMessage);
