import * as R from 'ramda';
import type { OptionType, ValueType } from '@atlassian/jira-portfolio-3-common/src/select/types';
import type { IssuePriority } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-priorities/types';
import type {
	RemovePriorityColourActionPayload,
	UpdatePriorityColourActionPayload,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/colour-by/actions';
import type { ColourPriority } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/colour-by/types';
import { chain, isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';

export const getPriorityIdsToOptions = (
	priorityIds: string[],
	prioritiesById: {
		[key: string]: IssuePriority;
	},
): OptionType[] =>
	priorityIds.reduce<OptionType[]>((acc, priorityId) => {
		// this check prevents a plan crash if a priority previously picked for any colour is removed from the plan
		if (prioritiesById[priorityId]) {
			acc.push({
				label: prioritiesById[priorityId].name,
				value: priorityId,
			});
		}
		return acc;
	}, []);

export const getOptionsToPriorityIds = (options: ValueType): string[] =>
	R.map(R.prop('value'), options);

export const getAvailablePriorities = (
	colourPriorities: ColourPriority[],
	issuePrioritiesIdList: string[] = [],
): string[] => {
	const usedPriorities = new Set(chain((x) => x.priorities, colourPriorities));
	return issuePrioritiesIdList.filter((priorityId) => !usedPriorities.has(priorityId));
};

export const onColourChange =
	(
		index: number,
		removePriorityColour: (arg1: RemovePriorityColourActionPayload) => void,
		updatePriorityColour: (arg1: UpdatePriorityColourActionPayload) => void,
	) =>
	(colour?: string | null) => {
		if (isDefined(colour)) {
			updatePriorityColour({ index, colour });
		} else {
			removePriorityColour({ index });
		}
	};
