import {
	RESET,
	UPDATE_EXPANDED_ISSUE_LINKS,
	SET_IS_EXPANDED,
	type ResetAction,
	type UpdateExpandedIssueLinksAction,
	type SetIsExpandedAction,
} from './actions';
import type { IssueExpansionState } from './types';

type Action = ResetAction | UpdateExpandedIssueLinksAction | SetIsExpandedAction;

export const initialState: IssueExpansionState = {
	isExpanded: { IssuesWithoutParentHeader: true },
};

const reducer = (state: IssueExpansionState = initialState, action: Action) => {
	switch (action.type) {
		case RESET:
			return action.payload;

		case SET_IS_EXPANDED: {
			const { payload } = action;

			const all = { ...state.isExpanded, ...payload };
			// remove keys with false value from the object since the default value is false.
			// this optimization keeps the view preference payload small
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const prunedIsExpanded = Object.entries(all).reduce<Record<string, any>>(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(acc, [key, value]: [any, any]) => {
					if (value === true) {
						acc[key] = value;
					}
					return acc;
				},
				{},
			);

			return {
				...state,
				isExpanded: prunedIsExpanded,
			};
		}
		case UPDATE_EXPANDED_ISSUE_LINKS: {
			const { payload } = action;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const isExpanded = Object.keys(state.isExpanded).reduce<Record<string, any>>(
				(acc, currentKey) => {
					let newKey = currentKey;
					const keyComponents = currentKey.split(':');

					if (payload[keyComponents[0]]) {
						keyComponents[0] = payload[keyComponents[0]];
						newKey = keyComponents.join(':');
					}
					acc[newKey] = state.isExpanded[currentKey];
					return acc;
				},
				{},
			);

			return {
				...state,
				isExpanded,
			};
		}

		default:
			return state;
	}
};

export default reducer;
