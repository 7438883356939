import type { Appearance } from '@atlaskit/section-message/src';
import type { MessageDescriptorV2 } from '@atlassian/jira-intl/src/v2/types.tsx';
import {
	subscriptionErrors,
	type SubscriptionError,
} from '../../../services/issue-breakdown/types';
import messages from './messages';

// exported only for testing
export const appearances: { [key: string]: Appearance } = {
	information: 'information',
	warning: 'warning',
	error: 'error',
};

export const getSectionMessageContent = (
	error: SubscriptionError | null | undefined,
	isRefined?: boolean,
): {
	appearance: Appearance;
	bodyMessage: MessageDescriptorV2;
	headerMessage?: MessageDescriptorV2;
	hasRetry: boolean;
} => {
	switch (error) {
		case subscriptionErrors.notEnoughInformation:
			if (isRefined) {
				return {
					appearance: appearances.warning,
					bodyMessage: messages.refineNotEnoughInfoBody,
					headerMessage: messages.refineNotEnoughInfoHeader,
					hasRetry: false,
				};
			}
			return {
				appearance: appearances.warning,
				bodyMessage: messages.notEnoughInfoBody,
				headerMessage: messages.notEnoughInfoHeader,
				hasRetry: false,
			};
		case subscriptionErrors.noFurtherSuggestions:
			return {
				appearance: appearances.information,
				bodyMessage: messages.noFurtherSuggestionsBody,
				headerMessage: messages.noFurtherSuggestionsHeader,
				hasRetry: false,
			};
		case subscriptionErrors.unethicalContent:
			return {
				appearance: appearances.information,
				bodyMessage: messages.unethicalContentError,
				hasRetry: false,
			};
		case subscriptionErrors.communicationsFailures:
		case subscriptionErrors.unclassified:
		case subscriptionErrors.network:
		case subscriptionErrors.timeout:
		default:
			return {
				appearance: appearances.warning,
				bodyMessage: messages.genericError,
				hasRetry: true,
			};
	}
};
