import React from 'react';
import { FormattedMessage } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import type { Props } from './types';

export default function ClearFilterButton({ onClearClick, isVisible }: Props) {
	return (
		<span style={{ display: isVisible ? 'inline-flex' : 'none' }}>
			<Button appearance="link" onClick={onClearClick}>
				<FormattedMessage {...commonMessages.clearFilterText} />
			</Button>
		</span>
	);
}

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Props };
