import React, { type MouseEvent } from 'react';
import StandardButton from '@atlaskit/button/standard-button';
import { DropdownItemGroup as DropMenuItemGroup } from '@atlaskit/dropdown-menu';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import { FormattedMessage } from '@atlassian/jira-intl';
import DropMenu, { DropMenuItem } from '@atlassian/jira-portfolio-3-common/src/drop-menu/index.tsx';
import { KEYS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/interaction-constants';
import traceUFOPress from '@atlassian/react-ufo/trace-press';
import messages from './messages';
import type { Props } from './types';

function CollapseMenu({ toggleExpansionIssuesInHierarchy }: Props) {
	const renderMenu = () => (
		<DropMenuItemGroup>
			<DropMenuItem
				onClick={(event: MouseEvent) => {
					traceUFOPress(KEYS.EXPAND_ALL_ISSUES, event.timeStamp);
					toggleExpansionIssuesInHierarchy(true);
				}}
			>
				<FormattedMessage {...messages.expandHierarchy} />
			</DropMenuItem>
			<DropMenuItem
				onClick={(event: MouseEvent) => {
					traceUFOPress(KEYS.COLLAPSE_ALL_ISSUES, event.timeStamp);
					toggleExpansionIssuesInHierarchy(false);
				}}
			>
				<FormattedMessage {...messages.collapseHierarchy} />
			</DropMenuItem>
		</DropMenuItemGroup>
	);

	return (
		<DropMenu
			trigger={({ triggerRef, ...props }) => (
				<StandardButton
					{...props}
					ref={triggerRef}
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.collapse-menu.trigger-btn"
					iconAfter={<ChevronDownIcon label="" />}
					appearance="subtle"
				>
					<FormattedMessage {...messages.buttonTitle} />
				</StandardButton>
			)}
		>
			{renderMenu()}
		</DropMenu>
	);
}

export default CollapseMenu;
