import { fetchAssigneeList } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/assignees';
import { connect } from '@atlassian/jira-react-redux';
import mapStateToProps from './query';
import View from './view';

const mapDispatchToProps = {
	fetchAssigneeList,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
