import {
	RESET,
	SET_IS_LOADING,
	SET_IS_LOADING_HISTORY,
	SET_IS_SAVING,
	type Action,
} from './actions';
import selectableIssueReducer from './selectable-issue/reducer';
import type { Issues } from './types';

export const initialState: Issues = {
	selected: new Set(),
	lastSelected: {},
	isLoading: true,
	isLoadingHistory: false,
	isSavingIssue: false,
};

const reducer = (state: Issues = initialState, action: Action): Issues => {
	switch (action.type) {
		case RESET:
			return {
				isLoading: state.isLoading,
				selected: new Set<string>(),
				lastSelected: {},
				isSavingIssue: false,
				isLoadingHistory: false,
			};

		case SET_IS_LOADING: {
			return {
				...state,
				isLoading: action.payload,
			};
		}
		case SET_IS_LOADING_HISTORY: {
			return {
				...state,
				isLoadingHistory: action.payload,
			};
		}
		case SET_IS_SAVING: {
			return {
				...state,
				isSavingIssue: action.payload,
			};
		}
		default:
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			action as any;
			return selectableIssueReducer(state, action);
	}
};

export default reducer;
