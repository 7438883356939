// eslint-disable-next-line jira/restricted/react-component-props
import type { ReactNode, ReactElement, ComponentProps, CSSProperties, ComponentType } from 'react';
import type Item from './item';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { SectionProps } from './item';

export type SectionsState = {
	widths: {
		[key: string]: number;
	};
	minWidths: {
		[key: string]: number;
	};
	preDragWidths?: {
		[key: string]: number;
	};
	totalWidth?: number;
	activeSeparatorIndex?: number | null | undefined;
	hoveredSectionIndex?: number | null | undefined;
};

export type State = {
	sections: {
		[key: string]: SectionsState;
	};
	isDragging: boolean;
};

export const ConfigurableDateTypes = Object.freeze({
	start: 'CONFIGURABLE_DATE_START',
	end: 'CONFIGURABLE_DATE_END',
});

export type ConfigurableDate = (typeof ConfigurableDateTypes)[keyof typeof ConfigurableDateTypes];

export type SectionData = {
	initialWidth: number;
	isFixed: boolean;
	id: string;
	minWidth: number;
	width: number;
	title?: string;
	collapsible?: boolean;
	isLoading?: boolean;
	configurableDate?: ConfigurableDate;
	onboardingWidth?: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	HeaderContent?: ComponentType<any>;
};

export type SectionsData = SectionData[];

export type SectionsInitialData = Flow.Diff<
	SectionData,
	{
		width: number;
	}
>[];

export type Coord = {
	x: number;
	y: number;
};

export type SeparatorProps = {
	index: number;
	isActive: boolean;
	isDragging: boolean;
	setActiveSeparator: (arg1?: number | null | undefined) => void;
	onDragStart: (arg1: number) => void;
	onDragEnd: () => void;
	onDrag: (arg1: { index: number; from: Coord; to: Coord }) => void;
	testId?: string;
};

export type SeparatorState = {
	isMouseHover: boolean;
};

export const CONTEXT_KEY = '@portfolio/common/sections';

export type ProviderProps = {
	children: ReactNode;
	storageKey?: string;
};

export type PreDragSectionWidths = {
	[key: string]: number;
};

export type SectionWidths = {
	[key: string]: {
		width: number;
		order: number;
		isFixed?: boolean;
	};
};

export type SectionsContext = {
	id: string;
	index: number;
	sectionsData: SectionsData;
	totalWidth?: number;
	parent: SectionsContext | null | undefined;
};

export type OnDragPayload = {
	id: string;
	delta?: number;
	index: number;
	totalWidth?: number;
	sectionsData: SectionsData;
	parent: SectionsContext | null | undefined;
	isFitParent: boolean;
};

export type OnResizePayload = {
	id: string;
	width?: number;
	minWidth?: number;
	sectionsData: SectionsData;
	parent: SectionsContext | null | undefined;
	isFitParent: boolean;
};

export type SectionRendererArgs = {
	section: SectionData;
	onSectionClick: (arg1: number) => void;
	index: number;
	isDragging?: boolean;
	hoveredSectionIndex?: number | null | undefined;
	setHovered: (arg1?: number | null | undefined) => void;
};

export type SectionRenderer = (
	obj: SectionRendererArgs,
) => ReactElement<ComponentProps<typeof Item>>;

export type OwnProps = {
	id: string;
	sectionRenderer: SectionRenderer;
	sectionsData: SectionsData;
	width: number;
	linkToParent?: string;
	minWidth?: number;
	isFitParent?: boolean;
	onDragStart?: () => void;
	onDragEnd?: () => void;
};

export type Props = OwnProps &
	StateProps &
	DispatchProps & {
		height?: number | string;
		minHeight?: number | string; // type string is used for passing % values,
		onRender?: (arg1: SectionsData) => void;
	};

export type StateProps = {
	activeSeparatorIndex: number | null | undefined;
	sectionsData: SectionsData;
	totalWidth?: number;
	isDragging: boolean;
	hoveredSectionIndex: number | null | undefined;
};

export type DispatchProps = {
	setHovered: (index: number | null | undefined) => void;
	setActiveSeparator: (index: number | null | undefined) => void;
	onResize: (arg1: OnResizePayload) => void;
	onDrag: (arg1: OnDragPayload) => void;
	onDragStartOwn: (arg1: string) => void;
	onDragEndOwn: (arg1: string) => void;
	onLengthChange: (payload: OnResizePayload) => void;
	savePreDragWidths: (sectionId: string) => void;
	setOnboardingWidth: (id: string, sectionsData: SectionsData, width: number | undefined) => void;
};

export type ColumnTextState = {
	title?: ReactNode;
	icon?: ReactNode;
	tooltip?: string;
	width?: number;
	visible: boolean;
};

export type ColumnTextProps = ColumnTextState & {
	isExportMode: boolean;
};

export type ColumnTextsBySectionsId = {
	[id: string]: ColumnTextState[];
};

export type SectionContainerProps = {
	id: string;
	width: number;
	style: CSSProperties;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	dispatch: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	linkToParent: any;
};

export type NamespacedIdProps = {
	namespace: string;
	id: string;
	linkToParent?: string;
	isExportMode?: boolean;
};

type SectionsStateCollection = {
	[string: string]: SectionsState;
};
export type SubscriberProps = {
	sectionsData: SectionsStateCollection | undefined;
};

export type SubscriberOwnProps = {
	children?: (arg1: SubscriberProps) => ReactElement;
};
export type SubscriberHocProps = {
	sectionsData: SectionsStateCollection | undefined;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	dispatch: any;
} & SubscriberOwnProps;
