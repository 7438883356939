import * as R from 'ramda';
import { indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { Issue } from '../../state/domain/issues/types';
import type { Person, IssueReporterMap } from '../../state/domain/reporters/types';
import type { State } from '../../state/types';
import { getIssues } from '../raw-issues';

export const getIssueReporterMap = (state: State) => state.domain.reporters.issueReporterMap;

export const getReportersOfIssuesPure = (
	issues: Issue[],
	reporterMapByIssueId: IssueReporterMap,
): Person[] => {
	const reporters: Set<Person> = new Set();

	issues.forEach((issue) => {
		const reporterPerson = reporterMapByIssueId[issue.id];
		if (reporterPerson) {
			reporters.add(reporterPerson);
		}
	});
	return Array.from(reporters);
};

export type reporterById = Record<string, Person>;

export const getUniqueReportersOfIssues = createSelector(
	[getIssues, getIssueReporterMap],
	getReportersOfIssuesPure,
);

export const getReportersById = createSelector(
	[getUniqueReportersOfIssues],
	(reporter): reporterById => indexBy(R.prop('personId'), reporter),
);
