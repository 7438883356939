import React from 'react';
import { createPortal } from 'react-dom';
import { endOfQuarter, addQuarters, startOfQuarter, startOfYear } from 'date-fns';
import Lozenge from '@atlaskit/lozenge';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { ff } from '@atlassian/jira-feature-flagging';
import { FormattedMessage } from '@atlassian/jira-intl';
import type { ZoomLevel } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/common/types.tsx';
import { getBody } from '@atlassian/jira-portfolio-3-portfolio/src/common/dom';
import { SECTION_ITEM_KEYS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import { quarters } from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';

export const testId =
	'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.time-units-bar';

export const getTimeUnitsBarTopUnit = ({
	index,
	label,
	offset,
	width,
	zoomLevel,
}: {
	index: number;
	label: string | number;
	offset: string | number;
	width: number;
	zoomLevel?: ZoomLevel | undefined;
}) => {
	if (zoomLevel) {
		return (
			<div
				data-testid={`${testId}.top-unit-${index}`}
				key={`timeUnitsBarTopUnit${index}`}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={`${styles.timeUnitsBarTopUnit} ${styles.infiniteAxis} ${styles.newOverride}`}
				style={{
					left: offset,
				}}
			>
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={`${styles.timeUnitsBarTopUnitContent} ${styles.infiniteAxis} ${styles.withLeftBorder}`}
				>
					{label}
				</div>
			</div>
		);
	}
	return (
		<div
			data-testid={`${testId}.top-unit-${index}`}
			key={`timeUnitsBarTopUnit${index}`}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={`${styles.timeUnitsBarTopUnit} ${styles.newOverride}`}
			style={{
				left: offset,
				width: `${width}%`,
			}}
		>
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={`${styles.timeUnitsBarTopUnitContent} ${styles.staticAxis} ${
					index > 0 ? styles.withLeftBorder : ''
				}`}
			>
				{label}
			</div>
		</div>
	);
};

export const getTimeUnitsBarTopUnitPortal = ({
	index,
	label,
}: {
	index: number;
	label: string | number;
}) => {
	// portals are only used when the timeline is in "weeks", "months", "quarters" or "years"
	// to render a time unit which sticks to the top left edge of the timeline header
	// we only render it if its specific container is present in the DOM since
	// we don't want to use document.body as a fallback as it would look odd

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const container = document.getElementById(
		ff('com.atlassian.rm.jpo.transposition.m2')
			? 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.overlay.axis-first-time-unit.sticky-date'
			: `portfolio-3-common.sections.item.${SECTION_ITEM_KEYS.TIMELINE}`,
	);

	if (container) {
		return createPortal(
			<div
				data-testid={`${testId}.top-unit-${index}`}
				key={`timeUnitsBarTopUnit${index}`}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={`${styles.timeUnitsBarTopUnit} ${styles.infiniteAxis} ${styles.newOverride}`}
			>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={`${styles.timeUnitsBarTopUnitContent} ${styles.staticAxis}`}>{label}</div>
			</div>,
			// note: getBody() is added as a fallback just to make Flow happy
			container || getBody(),
		);
	}
	return <div key={`timeUnitsBarTopUnit${index}`} />;
};

export const getTimeUnitsBarBottomUnitLabel = (
	isToday: boolean,
	isWeekendDay: boolean,
	label: string | number,
) => {
	// blue lozenge for today day
	if (isToday) {
		return (
			<Lozenge appearance="inprogress">
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<span className={styles.dateLozenge}>{label}</span>
			</Lozenge>
		);
	}

	// grey lozenge for weekend day
	if (isWeekendDay) {
		return (
			<Lozenge appearance="default">
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<span className={styles.dateLozenge}>{label}</span>
			</Lozenge>
		);
	}

	// no lozenge otherwise
	return label;
};

export const getTimeUnitsBarBottomUnit = ({
	alignTextToLeft = false,
	index,
	isToday = false,
	isWeekendDay = false,
	label,
	offset,
	showLeftPadding = false,
	showRightBorder,
	showLeftBorder = false,
	width,
	zoomLevel,
	tooltipContent,
}: {
	alignTextToLeft?: boolean;
	index: number;
	isToday?: boolean;
	isWeekendDay?: boolean;
	label: string | number;
	offset: string | number;
	showLeftPadding?: boolean;
	showRightBorder: boolean;
	showLeftBorder?: boolean;
	width: number;
	zoomLevel?: ZoomLevel | undefined;
	tooltipContent?: string;
}) => (
	<Tooltip key={`timeUnitsBarBottomUnit${index}`} content={tooltipContent}>
		<div
			data-testid={`${testId}.bottom-unit-${index}`}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles.timeUnitsBarBottomUnit}
			style={{
				left: `${offset}%`,
				width: `${width}%`,
			}}
		>
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={`${styles.timeUnitsBarBottomUnitContent} ${styles.newOverride} ${
					zoomLevel ? '' : styles.staticAxis
				} ${showRightBorder} ${showLeftBorder && styles.withLeftBorder} ${styles.newOverride}`}
				style={{
					textAlign: alignTextToLeft ? 'left' : 'center',

					paddingLeft: showLeftPadding ? token('space.100', '8px') : token('space.0', '0px'),
				}}
			>
				{getTimeUnitsBarBottomUnitLabel(isToday, isWeekendDay, label)}
			</div>
		</div>
	</Tooltip>
);

export const getTopHeader = (timeUnit: number | string) => (
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
	<div className={`${styles.timeUnitsBarContent} ${styles.infiniteAxis} ${styles.withLeftBorder}`}>
		{timeUnit}
	</div>
);

export const getStaticQuarterLabels = (
	startTime: number,
	timelineRangeStart: number,
	timelineRangeEnd: number,
) => {
	let quarterStart = startOfYear(startTime);
	const timelineEndDate = new Date(timelineRangeEnd);
	const timelineStartDate = new Date(timelineRangeStart);
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.yearWrapper}>
			{quarters.map((quarter, index) => {
				quarterStart = startOfQuarter(quarterStart);
				const quarterEnd = endOfQuarter(quarterStart);
				quarterStart = addQuarters(quarterStart, 1);
				if (quarterStart > timelineStartDate && quarterEnd < timelineEndDate) {
					return (
						<div key={index}>
							<FormattedMessage {...quarter} />
						</div>
					);
				}
				return null;
			})}
		</div>
	);
};

export const isWeekendDay = (date: Date) => date.getUTCDay() % 6 === 0;
