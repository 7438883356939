import { getFiscalMonth } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/system';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

const mapStateToProps = createStructuredSelector<State, StateProps>({
	fiscalMonth: getFiscalMonth,
});

export default mapStateToProps;
