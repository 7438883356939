import React, { type ComponentType, Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import throttle from 'lodash/throttle';
import cx from 'classnames';
import { SimpleTag } from '@atlaskit/tag';
import Tooltip from '@atlaskit/tooltip';
import HoverObserver from '@atlassian/jira-portfolio-3-common/src/hover-observer/index.tsx';
import { selectComponents } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import type {
	InputActionMeta,
	OptionType,
} from '@atlassian/jira-portfolio-3-common/src/select/types';
import Cell from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/fields/columns/column/cell/view';
import { withSlots, slots } from '../../component-slots';
import { isDefined } from '../../ramda';
import { MIN_SELECT_WIDTH } from '../select/view.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, State } from './types';
import { selectStyles } from './utils';

// eslint-disable-next-line jira/react/no-class-components
export class MultiSelectCell extends Component<
	Props & {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		FieldSelect: ComponentType<any>;
	},
	State
> {
	// eslint-disable-next-line react/sort-comp
	state = {
		isLoading: false,
		options: [],
		isHovered: false,
		selectedOptions: [],
	};

	static defaultProps: Partial<Props> = {
		isCreatable: false,
		HoverObserver,
	};

	static getDerivedStateFromProps(props: Props, state: State) {
		return {
			...state,
			selectedOptions: props.getValues(props.issue, props.attribute),
		};
	}

	componentDidMount(): void {
		if (this.props.menuIsOpen) {
			this.getOptions('');
		}
	}

	getOptions(query: string) {
		this.setState({ isLoading: true });
		this.props.fetchOptions(query).then((options: OptionType[]) => {
			this.setState({ options, isLoading: false });
		});
	}

	throttledGetOptions = throttle(this.getOptions, 250);

	handleInputChange = (value: string, { action }: InputActionMeta) => {
		if (action === 'input-change' || action === 'input-blur') {
			this.throttledGetOptions(value);
		}
		// input the query and select the option, then need to render the rest options which need to set the query is empty string
		if (action === 'set-value') {
			this.throttledGetOptions('');
		}
	};

	setHovered = (isHovered: boolean) => this.setState({ isHovered });

	handleChange = (options?: OptionType | OptionType[] | null) => {
		this.setState({ selectedOptions: Array.isArray(options) ? options : [] });
		this.props.onChange(this.props.issue, options || []);
	};

	// this will be called with the input value when a new option is created, and onChange will not be called. Use this when you need more control over what happens when new options are created.
	handleCreateOption = (inputValue: string) => {
		this.props.onCreateOption && this.props.onCreateOption(inputValue);
		const newOption =
			(this.props.convertCreatedLabelToOption &&
				this.props.convertCreatedLabelToOption(inputValue)) ||
			{};
		const newSelectedOptions = [...this.state.selectedOptions, newOption];
		this.setState({ selectedOptions: newSelectedOptions });
		// manually call onChange event
		this.props.onChange(this.props.issue, newSelectedOptions);
	};

	renderMultipleLabels = (selectedValues: OptionType[], itemClasses: string) => {
		const title = selectedValues.map((value) => value.label).join(', ');

		return (
			<Tooltip content={title}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.tagContainer}>
					{selectedValues.map(({ label, value }) => (
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						<div key={value} className={itemClasses}>
							<SimpleTag text={label} />
						</div>
					))}
				</div>
			</Tooltip>
		);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getControlComponent = (props: any) => {
		const { isReadOnly, isDisabled } = this.props;
		const { isHovered } = this.state;
		const {
			selectProps: { menuIsOpen, value, onMenuOpen },
		} = props;
		const isDefaultControl =
			menuIsOpen || (isHovered && isEmpty(value) && !isDisabled && !isReadOnly);
		const className = cx(styles.componentsContainer, {
			[styles.componentsContainerDisabled]: isDisabled,
			[styles.hiddenContainer]: isDefaultControl,
		});
		return (
			<selectComponents.Control {...props} isFocused={menuIsOpen}>
				<>
					{isDefaultControl && props.children}
					{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={className} role="button" tabIndex={0} onClick={onMenuOpen}>
						{value &&
							value.length > 0 &&
							this.renderMultipleLabels(
								value,
								cx(styles.item, {
									[styles.disabled]: isDisabled && !isReadOnly,
								}),
							)}
					</div>
				</>
			</selectComponents.Control>
		);
	};

	render() {
		const {
			attribute,
			issue,
			messages,
			FieldSelect,
			isReadOnly,
			isDisabled,
			isCreatable,
			customNoOptionsMessage,
			formatCreateLabel,
			validateInputForCreation,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			HoverObserver,
			menuIsOpen,
		} = this.props;
		const values = this.state.selectedOptions;
		const noOptionsMessage = ({ inputValue }: { inputValue: string }) => {
			if (customNoOptionsMessage) {
				const message = customNoOptionsMessage(inputValue);
				if (message) {
					return message;
				}
			}

			if (inputValue === '' && isEmpty(values)) {
				return messages.noLabels;
			}

			if (inputValue === '' && !isEmpty(values)) {
				return messages.noLabelsLeft;
			}

			return messages.noMatch;
		};

		const getFieldSelect = () =>
			isDisabled || isReadOnly ? (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				<div className={styles.labelsContainer}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.itemsWrapper}>
						{values &&
							values.length > 0 &&
							this.renderMultipleLabels(
								values,
								cx(styles.item, {
									[styles.disabled]: isDisabled && !isReadOnly,
								}),
							)}
					</div>
				</div>
			) : (
				<FieldSelect
					isMulti
					isCreatable={isCreatable}
					formatCreateLabel={formatCreateLabel}
					isLoading={this.state.isLoading}
					value={values}
					onChange={this.handleChange}
					onMenuOpen={() => this.getOptions('')}
					onInputChange={this.handleInputChange}
					options={this.state.options}
					closeMenuOnSelect={false}
					styles={selectStyles}
					minSelectWidth={MIN_SELECT_WIDTH}
					isTransparentBackground={false}
					placeholder={messages.placeholder}
					noOptionsMessage={noOptionsMessage}
					issueId={issue.id}
					attribute={attribute}
					components={{
						Control: this.getControlComponent,
					}}
					isDisabled={isReadOnly || isDisabled}
					onCreateOption={this.handleCreateOption}
					isValidNewOption={validateInputForCreation}
					menuIsOpen={menuIsOpen}
				/>
			);

		return (
			<Cell attribute={attribute} issue={issue} isScrolling={false} showOptimizations={false}>
				{isDefined(HoverObserver) ? (
					<HoverObserver onHoverChanged={this.setHovered}>{getFieldSelect()}</HoverObserver>
				) : (
					getFieldSelect()
				)}
			</Cell>
		);
	}
}
// @ts-expect-error - TS2345 - Argument of type 'typeof MultiSelectCell' is not assignable to parameter of type 'ComponentType<any>'.
export default withSlots({ FieldSelect: slots.FieldSelect })(MultiSelectCell);
