import React from 'react';
import { Text } from '@atlaskit/primitives';
import TimelineSprintTooltip from '../sprint-tooltip';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

export default function LabeledUnit(props: Props) {
	const { widthPercentage, label, noMarker, gap } = props;
	let { content, display } = label;

	if (gap === true) {
		// gap is filled with space b/c inline-block div w/o content breaks layout in Safari
		// and using float:left instead of inline-block breaks no-wrap effect of the parent
		display = <Text>&nbsp;</Text>;
	}

	content = content || (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.innerContent}>{noMarker === true ? null : display}</div>
	);

	return (
		<div
			data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.unit"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles.unit}
			style={{ width: `${widthPercentage}%` }}
		>
			{gap === true ? (
				content
			) : (
				<TimelineSprintTooltip content={display}>{content}</TimelineSprintTooltip>
			)}
		</div>
	);
}
