import { getPlan } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan';
import { getAllIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/index.tsx';
import {
	getFieldsWidth,
	getScopeWidth,
	getTimelineExportWidth,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/section-widths';
import {
	getTableItems,
	TABLE_ISSUE,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table';
import {
	getTimeScaleViewSettings,
	getViewMode,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { MapStateToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { Props } from './types';
import { getInitialExportDateRange } from './util';

export const getInitialTimelineDateRange = createSelector(
	[getTimeScaleViewSettings],
	getInitialExportDateRange,
);

export const getNumVisibleIssues = createSelector(
	[getTableItems],
	(tableItems) => tableItems.filter((ti) => ti.tag === TABLE_ISSUE).length,
);

const mapStateToProps: MapStateToPropsSimple<State, Props> = (state) => ({
	fieldsWidth: getFieldsWidth(state),
	scopeWidth: getScopeWidth(state),
	timelineExportWidth: getTimelineExportWidth(state),
	initialTimelineDateRange: getInitialTimelineDateRange(state),
	numIssuesInPlan: getAllIssues(state).length,
	numIssues: getNumVisibleIssues(state),
	planTitle: getPlan(state).title,
	viewMode: getViewMode(state),
});

export default mapStateToProps;
