import { CUSTOM_TIME_RANGE_TYPES } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/constants.tsx';
import type { ChangeCustomDatesActionPayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/time-scale/actions';
import {
	CHANGE_OPTION,
	CHANGE_CUSTOM_DATES,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/time-scale/actions.tsx';
import type { TimeScaleOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/time-scale/types';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { DispatchProps } from './types';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	onTimeScaleOptionChange: (payload: TimeScaleOption) =>
		dispatch({
			type: CHANGE_OPTION,
			payload,
		}),
	onTimeScaleCustomDatesChange: (payload: ChangeCustomDatesActionPayload) =>
		dispatch({
			type: CHANGE_CUSTOM_DATES,
			payload: { ...payload, typeOfCustomDateRange: CUSTOM_TIME_RANGE_TYPES.FIXED },
		}),
});

export default mapDispatchToProps;
