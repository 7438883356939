import { createContainer, createStore } from '@atlassian/react-sweet-state';
import type { State } from '../types';
import { actions } from './actions';

export const DragAndDropContainer = createContainer();

export const store = createStore<State, typeof actions>({
	containedBy: DragAndDropContainer,
	initialState: {
		instance: Symbol('drag-and-drop-store-instance-id'),
	},
	actions,
});
