import React, { useMemo } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { VIEW_MODES } from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import AdvancedFieldsMenu from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/fields/header/advanced-fields-menu';
import type { MenuTriggerProps } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/fields/header/advanced-fields-menu/types';
import AdvancedFieldsTrigger from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/fields/header/advanced-fields-trigger';
import { getMatchingConfiguredDate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/fields/utils';
import messages from './messages';
import type { Props } from './types';

export const AddFields = ({
	isExportMode,
	isConfluenceMacro,
	columns,
	hideColumn,
	showColumn,
	moveColumn,
	dateConfiguration,
	viewMode,
}: Props) => {
	const visibleColumns = useMemo(() => columns.filter((column) => column.isVisible), [columns]);
	const { formatMessage } = useIntl();

	const renderMenuTrigger = (menuTriggerProps: MenuTriggerProps) =>
		viewMode === VIEW_MODES.LIST ? (
			<AdvancedFieldsTrigger numberOfVisibleFields={visibleColumns.length} {...menuTriggerProps} />
		) : (
			<></>
		);

	const renderEmptyFields = () => (
		<Box xcss={textContainerStyles}>{formatMessage(messages.noFields)}</Box>
	);

	return (
		<Box
			xcss={containerStyles}
			data-test-class="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.subheader.field.header"
		>
			{!isExportMode && !isConfluenceMacro ? (
				<>
					{viewMode === VIEW_MODES.LIST && (
						<AdvancedFieldsMenu
							id="add-fields-header"
							columns={columns}
							hideColumn={hideColumn}
							showColumn={showColumn}
							moveColumn={moveColumn}
							getMatchingConfiguredDate={getMatchingConfiguredDate(dateConfiguration)}
							isShortcutEnabled={false}
							renderMenuTrigger={renderMenuTrigger}
						/>
					)}
					{viewMode === VIEW_MODES.LIST && visibleColumns.length === 1 && renderEmptyFields()}
					{viewMode === VIEW_MODES.TIMELINE && visibleColumns.length === 0 && renderEmptyFields()}
				</>
			) : undefined}
		</Box>
	);
};

const containerStyles = xcss({
	position: 'absolute',
	inset: 'space.0',
	display: 'flex',
	alignItems: 'center',
	boxSizing: 'border-box',
});

const textContainerStyles = xcss({
	marginLeft: 'space.150',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
