import React, { useCallback, useMemo, useState, type Ref } from 'react';
import DropdownMenu, {
	DropdownItem,
	DropdownItemGroup,
	type CustomTriggerProps,
	type OnOpenChangeArgs,
} from '@atlaskit/dropdown-menu';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import { Box, Flex, xcss, Pressable, Text } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import type { SuggestedChildIssue } from '@atlassian/jira-issue-fetch-services/src/services/issue-breakdown-data-fetcher/types';
import type { ChildIssueType } from '@atlassian/jira-issue-view-common-types/src/child-issue-type';

type Props = {
	item: SuggestedChildIssue;
	selectedIssueType: ChildIssueType;
	childIssueTypes: ChildIssueType[];
	setSuggestedChildIssueType: (updatedItem: SuggestedChildIssue) => void;
};

export const Dropdown = ({
	item,
	selectedIssueType,
	childIssueTypes,
	setSuggestedChildIssueType,
}: Props) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const handleUpdateIssueType = useCallback(
		(option: ChildIssueType) => {
			const updatedItem = { ...item, issueTypeId: option.id };
			setSuggestedChildIssueType(updatedItem);
		},
		[item, setSuggestedChildIssueType],
	);

	const renderOption = useCallback(
		(option: ChildIssueType, index: number) => (
			<DropdownItem
				testId="portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.suggestion-list-item.dropdown.issue-type"
				onClick={() => handleUpdateIssueType(option)}
				key={index}
			>
				<Flex alignItems="center">
					<Flex xcss={imgStyles}>
						<img src={option.iconUrl || ''} alt={option.name} width="16px" height="16px" />
					</Flex>
					<Text>{option.name}</Text>
				</Flex>
			</DropdownItem>
		),
		[handleUpdateIssueType],
	);

	const renderIssueTypeIcon = useMemo(
		() => (
			<Tooltip
				content={selectedIssueType.name}
				position="top"
				testId="portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.suggestion-list-item.dropdown.tooltip"
			>
				<Flex>
					<AsyncIcon alt={selectedIssueType.name} url={selectedIssueType.iconUrl} />
				</Flex>
			</Tooltip>
		),
		[selectedIssueType.iconUrl, selectedIssueType.name],
	);

	// No dropdown necessary if there is only a single issue type
	if (childIssueTypes.length === 1) {
		return <Flex alignItems="center">{renderIssueTypeIcon}</Flex>;
	}

	return (
		<DropdownMenu
			isOpen={isDropdownOpen}
			onOpenChange={(attrs: OnOpenChangeArgs) => {
				setIsDropdownOpen(attrs.isOpen);
			}}
			shouldRenderToParent
			testId="portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.suggestion-list-item.dropdown.dropdown-menu"
			trigger={({ triggerRef, isSelected, ...props }: CustomTriggerProps) => (
				<Flex alignItems="center">
					<Pressable
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						ref={triggerRef as Ref<HTMLButtonElement>}
						xcss={inlineStyles}
						backgroundColor="color.background.neutral.subtle"
						{...props}
					>
						<Flex>
							{renderIssueTypeIcon}
							<Box
								xcss={[
									chevronBaseStyles,
									isDropdownOpen ? chevronRotateEnabledStyles : chevronRotateDisabledStyles,
								]}
							>
								<ChevronDownIcon color="currentColor" label="close" LEGACY_size="small" />
							</Box>
						</Flex>
					</Pressable>
				</Flex>
			)}
		>
			<DropdownItemGroup>
				{childIssueTypes.map((option: ChildIssueType, index) => renderOption(option, index))}
			</DropdownItemGroup>
		</DropdownMenu>
	);
};

const inlineStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	paddingBlock: 'space.0',
	paddingInline: 'space.0',
	borderRadius: 'border.radius',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
});

const chevronBaseStyles = xcss({
	transition: 'transform 250ms ease',
});

const chevronRotateEnabledStyles = xcss({
	transform: 'rotate(-180deg)',
});

const chevronRotateDisabledStyles = xcss({
	transform: '',
});

const imgStyles = xcss({
	paddingRight: 'space.100',
});
