import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import union from 'lodash/union';
import type { IssueStatus } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { Project } from '../../state/domain/projects/types.tsx';
import type { State } from '../../state/types';
import { getCompanyManagedProjects } from '../projects';
import type { IssueStatusesById, IssueStatusByKey } from './types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { IssueStatus } from '../../../common/api/types.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { IssueStatusesById, IssueStatusByKey } from './types';

export const getIssueStatuses = (state: State) => state.domain.issueStatuses;

const getIssueStatusByIdMap = (statuses: IssueStatus[]): IssueStatusesById =>
	indexBy((status: IssueStatus) => status.id, statuses);

export const getIssueStatusById = createSelector([getIssueStatuses], getIssueStatusByIdMap);

export const getIssueStatusByKey = createSelector(
	[getIssueStatuses],
	(statuses: IssueStatus[]): IssueStatusByKey =>
		groupBy(statuses, ({ categoryId, name }) => `${categoryId} ${name}`),
);

const getIssueStatusTypeIdsOfCompanyManagedProjects = createSelector(
	[getCompanyManagedProjects],
	(projects: Project[]): string[] =>
		reduce<Project, string[]>(
			projects,
			(current, { issueStatusIds }) => union(issueStatusIds, current),
			[],
		),
);

export const getIssueStatusByIdForCompanyManagedProjects = createSelector(
	[getIssueStatuses, getIssueStatusTypeIdsOfCompanyManagedProjects],
	(issueStatuses: IssueStatus[], issueStatusIdsOfCompanyManagedProjects: string[]) =>
		issueStatuses.filter((issueStatus: IssueStatus) =>
			issueStatusIdsOfCompanyManagedProjects.includes(issueStatus.id),
		),
);
