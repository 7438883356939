import { type SetScrollAction, SET_STICKY_HEADER_SCROLL } from './actions';
import type { StickyHeader } from './types';

type Action = SetScrollAction;

const initialState: StickyHeader = {
	scrollTop: 0,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: StickyHeader = initialState, action: Action): StickyHeader => {
	if (action.type === SET_STICKY_HEADER_SCROLL) {
		return { ...state, ...action.payload };
	}
	return state;
};
