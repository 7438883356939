import React from 'react';
import Avatar from '@atlaskit/avatar';
import Tooltip from '@atlaskit/tooltip';
import { injectIntl } from '@atlassian/jira-intl';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

function AssigneeCell(props: Props) {
	const {
		intl,
		row: { assignee },
	} = props;
	if (!assignee) {
		return null;
	}
	const {
		jiraUser: { avatarUrl, title, accountId },
	} = assignee;
	const name = title || accountId || intl.formatMessage(commonMessages.unassigned);
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.assignee}>
			<Tooltip content={name}>
				<Avatar size="small" src={avatarUrl ?? undefined} name={name} />
			</Tooltip>
		</div>
	);
}

export default injectIntl(AssigneeCell);
