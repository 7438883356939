import { useEffect } from 'react';
import { useCttOnboardingTour } from '../../../controllers/ctt-onboarding';
import {
	CttOnboardingVariant,
	type CttOnboardingVariantType,
} from '../../../controllers/ctt-onboarding/types';

interface Props {
	hasEpicWithParent: boolean;
	variant: CttOnboardingVariantType;
}

export const StepInputEpicReparent = ({ variant, hasEpicWithParent }: Props) => {
	const assertUnreachable = (_: never): never => {
		throw new Error("Didn't expect to get here");
	};

	const [, { nextCttStage }] = useCttOnboardingTour();

	useEffect(() => {
		try {
			switch (variant) {
				case CttOnboardingVariant.NO_INITIATIVE:
				case CttOnboardingVariant.NO_EPIC:
				case CttOnboardingVariant.TMP_EPIC:
					nextCttStage();
					break;
				case CttOnboardingVariant.EPIC:
					if (hasEpicWithParent) nextCttStage();
					break;
				default:
					assertUnreachable(variant);
					break;
			}
		} catch (e) {
			nextCttStage();
		}
	}, [hasEpicWithParent, nextCttStage, variant]);

	return null;
};
