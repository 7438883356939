import { getPersons } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/persons';
import {
	getIssueSources,
	getPlanningUnit,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan';
import { getPlanDefaults } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan-defaults';
import { getEditedTeam } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import { getRequestStatus } from '../query';
import type { StateProps } from './types';

const mapStateToProps: MapStateToProps<StateProps> = (state: State) => ({
	isProcessingRequest: getRequestStatus(state),
	issueSources: getIssueSources(state),
	persons: getPersons(state),
	planDefaults: getPlanDefaults(state),
	planningUnit: getPlanningUnit(state),
	editedTeam: getEditedTeam(state),
});

export default mapStateToProps;
