import { isReadOnly } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app';
import { getViewMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { connect } from '@atlassian/jira-react-redux';
import type { StateProps } from './types';
import View from './view';

const mapStateToProps = createStructuredSelector<State, {}, StateProps>({
	viewMode: getViewMode,
	isReadOnly,
});

export default connect(mapStateToProps, {})(View);
