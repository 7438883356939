import type { IssueType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-types/types';

export const getIssueTypeNamesWithMoreThanOneOccurrence = (
	issueTypeIds: number[],
	issueTypesById: {
		[key: number]: IssueType;
	},
): string[] => {
	const issueTypeNameCounter: { [key: string]: number } = {};
	const namesWithMoreThanOneOccurrence: string[] = [];
	issueTypeIds.forEach((issueTypeId) => {
		const issueType = issueTypesById[issueTypeId];
		if (issueType !== undefined) {
			const count = issueTypeNameCounter[issueType.name];
			if (count === undefined) {
				issueTypeNameCounter[issueType.name] = 1;
			} else if (count === 1) {
				issueTypeNameCounter[issueType.name] = 2;
				namesWithMoreThanOneOccurrence.push(issueType.name);
			}
			// not necessary to count beyond 2
		}
	});
	return namesWithMoreThanOneOccurrence;
};
