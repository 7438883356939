import React from 'react';
import { token } from '@atlaskit/tokens';

/*
 * TODO: JIV-17990
 * seek approvals to add this from
 * platform/packages/ai-mate/conversation-assistant/src/common/ui/icons/ai-icon/index.tsx
 * into @atlassian/atlassian-intelligence packages
 * We doing this now to unblock progress for TEAM'24
 */

export const AiIcon = ({
	color = token('color.icon', '#626F86'),
	size = 16,
	label,
}: {
	color?: string;
	size?: number;
	label: string;
}) => (
	<svg
		width={size}
		height={size}
		viewBox={`0 0 ${size} ${size}`}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-label={label}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.4468 8.00016C11.4468 7.63197 11.7453 7.3335 12.1134 7.3335H13.3334C13.7016 7.3335 14.0001 7.63197 14.0001 8.00016C14.0001 8.36835 13.7016 8.66683 13.3334 8.66683H12.1134C11.7453 8.66683 11.4468 8.36835 11.4468 8.00016Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.99992 2C8.36811 2 8.66659 2.29848 8.66659 2.66667V3.88667C8.66659 4.25486 8.36811 4.55333 7.99992 4.55333C7.63173 4.55333 7.33325 4.25486 7.33325 3.88667V2.66667C7.33325 2.29848 7.63173 2 7.99992 2Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2 8.00016C2 7.63197 2.29848 7.3335 2.66667 7.3335H3.88667C4.25486 7.3335 4.55333 7.63197 4.55333 8.00016C4.55333 8.36835 4.25486 8.66683 3.88667 8.66683H2.66667C2.29848 8.66683 2 8.36835 2 8.00016Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.99992 11.4468C8.36811 11.4468 8.66659 11.7453 8.66659 12.1134V13.3334C8.66659 13.7016 8.36811 14.0001 7.99992 14.0001C7.63173 14.0001 7.33325 13.7016 7.33325 13.3334V12.1134C7.33325 11.7453 7.63173 11.4468 7.99992 11.4468Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.52851 9.52876C9.78886 9.26841 10.211 9.26841 10.4713 9.52876L13.138 12.1954C13.3983 12.4558 13.3983 12.8779 13.138 13.1382C12.8776 13.3986 12.4555 13.3986 12.1952 13.1382L9.52851 10.4716C9.26816 10.2112 9.26816 9.78911 9.52851 9.52876Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.138 2.86177C13.3983 3.12212 13.3983 3.54423 13.138 3.80458L10.4713 6.47124C10.211 6.73159 9.78886 6.73159 9.52851 6.47124C9.26816 6.21089 9.26816 5.78878 9.52851 5.52843L12.1952 2.86177C12.4555 2.60142 12.8776 2.60142 13.138 2.86177Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.86201 2.86177C3.12236 2.60142 3.54447 2.60142 3.80482 2.86177L6.47149 5.52843C6.73183 5.78878 6.73183 6.21089 6.47149 6.47124C6.21114 6.73159 5.78903 6.73159 5.52868 6.47124L2.86201 3.80458C2.60166 3.54423 2.60166 3.12212 2.86201 2.86177Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.47149 9.52876C6.73183 9.78911 6.73183 10.2112 6.47149 10.4716L3.80482 13.1382C3.54447 13.3986 3.12236 13.3986 2.86201 13.1382C2.60166 12.8779 2.60166 12.4558 2.86201 12.1954L5.52868 9.52876C5.78903 9.26841 6.21114 9.26841 6.47149 9.52876Z"
			fill={color}
		/>
	</svg>
);
