import { useCallback, useRef, useState } from 'react';
import drop from 'lodash/fp/drop';
import dropRight from 'lodash/fp/dropRight';
import equals from 'lodash/fp/equals';
import sum from 'lodash/fp/sum';
import {
	VIEW_MODES,
	type ViewMode,
} from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import {
	SECTION_COLLAPSE_THRESHOLD,
	COLUMN_COLLAPSE_THRESHOLD,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/grid/constants';
import type { UpdateColumnWidthsActionPayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/section-widths/types';
import { shrink } from './utils/resizer';
import * as storage from './utils/storage';

export const useColumnWidths = (
	viewId: number,
	viewMode: ViewMode,
	columnIds: string[],
	defaultWidths: number[],
	minWidths: number[],
	squeezedWidths?: number[],
) => {
	const columnIdsRef = useRef(columnIds);
	columnIdsRef.current = columnIds;

	const [prevColumnIds, setPrevColumnIds] = useState<typeof columnIds>();
	const [prevViewId, setPrevViewId] = useState<typeof viewId>();
	const [prevViewMode, setPrevViewMode] = useState<typeof viewMode>();

	const [state, setState] = useState<number[]>([]);

	const updateState = useCallback(
		(next: number[]) => {
			setState(next);
			storage.persist(viewId, viewMode, columnIds, defaultWidths, minWidths)(next);
		},
		[columnIds, defaultWidths, minWidths, viewId, viewMode],
	);

	// This needs to happen in the same rendering cycle, so there is no useEffect()
	if (viewId !== prevViewId || prevViewMode !== viewMode) {
		// viewId became negative temporarily when it's being saved as new view
		const isTemporaryView = viewId < 0;
		/* we only load from storage when it's existing view (not being a view just after "Create as new view"). */
		const hasJustCloned = viewId >= 0 && (prevViewId ?? 0) < 0;

		if (!isTemporaryView && !hasJustCloned) {
			const [columnWidths, totalFieldsWidth] = storage.load(
				viewId,
				viewMode,
				columnIds,
				defaultWidths,
				minWidths,
			);

			// We need to refine the column widths of the fields to fit the total fields width
			// This is an original spec for TIMELINE mode
			if (columnWidths.length >= 3 && squeezedWidths && totalFieldsWidth !== undefined) {
				const fields = <T,>(columns: T[]): T[] => drop(1)(dropRight(1)(columns));
				const fieldsWidths = shrink(fields(minWidths), fields(squeezedWidths))(
					fields(columnWidths),
					totalFieldsWidth - sum(fields(columnWidths)),
				);
				columnWidths.splice(1, columnWidths.length - 2, ...fieldsWidths);
			}

			setState(columnWidths);
		}

		// When a view is just created from "Create as new view" we want to clone the prev column widths
		if (hasJustCloned) {
			updateState(state);
		}

		setPrevViewId(viewId);
		setPrevViewMode(viewMode);
		setPrevColumnIds(columnIds);

		return [state, updateState] as const;
	}

	// This needs to happen in the same rendering cycle, so there is no useEffect()
	if (!equals(columnIds, prevColumnIds)) {
		setPrevColumnIds(columnIds);
		setState((current) =>
			columnIds.map((columnId, index) =>
				Math.max(
					minWidths[index],
					current[(prevColumnIds ?? []).indexOf(columnId)] ?? defaultWidths[index],
				),
			),
		);
	}

	return [state, updateState] as const;
};

/** Return the squeezable widths */
export const getSqueezedWidths = (minWidths: number[]) => {
	const numberOfColumns = minWidths.length;
	return minWidths.map((minWidth, index) => {
		if (index === 0) return minWidth;
		if (index === 1)
			return numberOfColumns === 3
				? SECTION_COLLAPSE_THRESHOLD /* 32px */
				: COLUMN_COLLAPSE_THRESHOLD /* 24px */;
		if (index === 2)
			return numberOfColumns === 3
				? 0
				: SECTION_COLLAPSE_THRESHOLD - COLUMN_COLLAPSE_THRESHOLD /* 8px */;
		return 0;
	});
};

export const getTopLevelColumnWidths = (
	columnWidths: number[],
	viewMode: ViewMode,
): UpdateColumnWidthsActionPayload => {
	const scope = columnWidths[0] ?? 0;
	const fields = (
		viewMode === VIEW_MODES.TIMELINE ? columnWidths.slice(1, -1) : columnWidths.slice(1)
	).reduce((width, widthSum) => widthSum + width, 0);

	return { scope, fields, timeline: 0 };
};
