import type { ColourByState, ColourByOption } from './types';

export const RESET = 'state.domain.view-settings.colour-by.RESET' as const;

export const SET_COLOUR_BY_VALUE =
	'state.domain.view-settings.colour-by.SET_COLOUR_BY_VALUE' as const;

export const SET_COLOUR_MAP = 'state.domain.view-settings.colour-by.SET_COLOUR_MAP' as const;

export const UPDATE_TEAM_ID = 'state.domain.view-settings.colour-by.UPDATE_TEAM_ID' as const;

export const REMOVE_TEAM_ID = 'state.domain.view-settings.colour-by.REMOVE_TEAM_ID' as const;

export const SET_COLOUR_FOR_NEW_TEAM =
	'state.domain.view-settings.colour-by.SET_COLOUR_FOR_NEW_TEAM' as const;

export const ADD_ISSUE_TYPE_COLOUR =
	'state.domain.view-settings.colour-by.ADD_ISSUE_TYPE_COLOUR' as const;

export const UPDATE_ISSUE_TYPE_COLOUR =
	'state.domain.view-settings.colour-by.UPDATE_ISSUE_TYPE_COLOUR' as const;

export const REMOVE_ISSUE_TYPE_COLOUR =
	'state.domain.view-settings.colour-by.REMOVE_ISSUE_TYPE_COLOUR' as const;

export const ADD_LABEL_COLOUR = 'state.domain.view-settings.colour-by.ADD_LABEL_COLOUR' as const;

export const UPDATE_LABEL_COLOUR =
	'state.domain.view-settings.colour-by.UPDATE_LABEL_COLOUR' as const;

export const REMOVE_LABEL_COLOUR =
	'state.domain.view-settings.colour-by.REMOVE_LABEL_COLOUR' as const;

export const ADD_COMPONENT_COLOUR =
	'state.domain.view-settings.colour-by.ADD_COMPONENT_COLOUR' as const;

export const UPDATE_COMPONENT_COLOUR =
	'state.domain.view-settings.colour-by.UPDATE_COMPONENT_COLOUR' as const;

export const REMOVE_COMPONENT_COLOUR =
	'state.domain.view-settings.colour-by.REMOVE_COMPONENT_COLOUR' as const;

export const ADD_PRIORITY_COLOUR =
	'state.domain.view-settings.colour-by.ADD_PRIORITY_COLOUR' as const;

export const SET_PRIORITY_COLOUR_MAP =
	'state.domain.view-settings.colour-by.SET_PRIORITY_COLOUR_MAP' as const;

export const UPDATE_PRIORITY_COLOUR =
	'state.domain.view-settings.colour-by.UPDATE_PRIORITY_COLOUR' as const;

export const REMOVE_PRIORITY_COLOUR =
	'state.domain.view-settings.colour-by.REMOVE_PRIORITY_COLOUR' as const;

export const ADD_SELECT_COLOUR = 'state.domain.view-settings.colour-by.ADD_SELECT_COLOUR' as const;

export const UPDATE_SELECT_COLOUR =
	'state.domain.view-settings.colour-by.UPDATE_SELECT_COLOUR' as const;

export const REMOVE_SELECT_COLOUR =
	'state.domain.view-settings.colour-by.REMOVE_SELECT_COLOUR' as const;

export const CLEAR_ALL_ISSUE_TYPE_COLOURS =
	'state.domain.view-settings.colour-by.CLEAR_ALL_ISSUE_TYPE_COLOURS' as const;

export const CLEAR_ALL_LABEL_COLOURS =
	'state.domain.view-settings.colour-by.CLEAR_ALL_LABEL_COLOURS' as const;

export const CLEAR_ALL_PRIORITY_COLOURS =
	'state.domain.view-settings.colour-by.CLEAR_ALL_PRIORITY_COLOURS' as const;

export const CLEAR_ALL_SELECT_COLOURS =
	'state.domain.view-settings.colour-by.CLEAR_ALL_SELECT_COLOURS' as const;

export const CLEAR_ALL_COMPONENT_COLOURS =
	'state.domain.view-settings.colour-by.CLEAR_ALL_COMPONENT_COLOURS' as const;

export type SetColourByValueActionPayload = {
	id: ColourByOption;
};

export type SetColourMapActionPayload = {
	id: ColourByOption;
	map: {
		[key: string]: string;
	};
};

export type UpdateTeamIdActionPayload = {
	oldId: string;
	newId: string;
};

export type RemoveTeamIdActionPayload = {
	id: string;
};

export type SetColourForNewTeamActionPayload = {
	id: string;
};

export type UpdateIssueTypeColourActionPayload = {
	colour?: string;
	index: number;
	issueTypes?: number[];
};

export type RemoveIssueTypeColourActionPayload = {
	index: number;
};

export type UpdateIssueTypeColourAction = {
	type: typeof UPDATE_ISSUE_TYPE_COLOUR;
	payload: UpdateIssueTypeColourActionPayload;
};

export type RemoveIssueTypeColourAction = {
	type: typeof REMOVE_ISSUE_TYPE_COLOUR;
	payload: RemoveIssueTypeColourActionPayload;
};

export type UpdateLabelColourActionPayload = {
	index: number;
	colour?: string;
	labels?: string[];
};

export type RemoveLabelColourActionPayload = {
	index: number;
};

export type UpdateComponentColourActionPayload = {
	index: number;
	colour?: string;
	components?: string[];
};

export type RemoveComponentColourActionPayload = {
	index: number;
};

export type SetPriorityColourMapActionPayload = {
	chunks: number[];
	issuePrioritiesIdList: string[];
};

export type UpdatePriorityColourActionPayload = {
	index: number;
	colour?: string;
	priorities?: string[];
};

export type RemovePriorityColourActionPayload = {
	index: number;
};

export type SetPriorityColourMapAction = {
	type: typeof SET_PRIORITY_COLOUR_MAP;
	payload: SetPriorityColourMapActionPayload;
};

export type UpdatePriorityColourAction = {
	type: typeof UPDATE_PRIORITY_COLOUR;
	payload: UpdatePriorityColourActionPayload;
};

export type RemovePriorityColourAction = {
	type: typeof REMOVE_PRIORITY_COLOUR;
	payload: RemovePriorityColourActionPayload;
};

export type RemoveSelectColourActionPayload = {
	index: number;
};

export type ResetAction = {
	type: typeof RESET;
	payload: ColourByState;
};

export type SetColourByValueAction = {
	type: typeof SET_COLOUR_BY_VALUE;
	payload: SetColourByValueActionPayload;
};

export type SetColourMapAction = {
	type: typeof SET_COLOUR_MAP;
	payload: SetColourMapActionPayload;
};

export type UpdateTeamIdAction = {
	type: typeof UPDATE_TEAM_ID;
	payload: UpdateTeamIdActionPayload;
};

export type SetColourForNewTeamAction = {
	type: typeof SET_COLOUR_FOR_NEW_TEAM;
	payload: SetColourForNewTeamActionPayload;
};

export type RemoveTeamIdAction = {
	type: typeof REMOVE_TEAM_ID;
	payload: RemoveTeamIdActionPayload;
};

export type AddComponentAction = {
	type: typeof ADD_COMPONENT_COLOUR;
};

export type AddIssueTypeColourAction = {
	type: typeof ADD_ISSUE_TYPE_COLOUR;
};

export type AddLabelAction = {
	type: typeof ADD_LABEL_COLOUR;
};

export type AddPriorityColourAction = {
	type: typeof ADD_PRIORITY_COLOUR;
};

export type AddSelectColourAction = {
	type: typeof ADD_SELECT_COLOUR;
};

export type UpdateLabelColourAction = {
	type: typeof UPDATE_LABEL_COLOUR;
	payload: UpdateLabelColourActionPayload;
};

export type RemoveLabelColourAction = {
	type: typeof REMOVE_LABEL_COLOUR;
	payload: RemoveLabelColourActionPayload;
};

export type UpdateComponentColourAction = {
	type: typeof UPDATE_COMPONENT_COLOUR;
	payload: UpdateComponentColourActionPayload;
};

export type RemoveComponentColourAction = {
	type: typeof REMOVE_COMPONENT_COLOUR;
	payload: RemoveComponentColourActionPayload;
};

export type UpdateSelectColourActionPayload = {
	index: number;
	colour?: string;
	options?: string[];
};

export type UpdateSelectColourAction = {
	type: typeof UPDATE_SELECT_COLOUR;
	payload: UpdateSelectColourActionPayload;
};

export type RemoveSelectColourAction = {
	type: typeof REMOVE_SELECT_COLOUR;
	payload: RemoveSelectColourActionPayload;
};

export type ClearAllColourIssueTypesAction = {
	type: typeof CLEAR_ALL_ISSUE_TYPE_COLOURS;
};

export type ClearAllColourLabelsAction = {
	type: typeof CLEAR_ALL_LABEL_COLOURS;
};

export type ClearAllColourPrioritiesAction = {
	type: typeof CLEAR_ALL_PRIORITY_COLOURS;
};

export type ClearAllColourSelectsAction = {
	type: typeof CLEAR_ALL_SELECT_COLOURS;
};

export type ClearAllColourComponentsAction = {
	type: typeof CLEAR_ALL_COMPONENT_COLOURS;
};

export const reset = (payload: ColourByState) => ({
	type: RESET,
	payload,
});

export const setColourMap = (payload: SetColourMapActionPayload) => ({
	type: SET_COLOUR_MAP,
	payload,
});

export const setColourByValue = (payload: SetColourByValueActionPayload) => ({
	type: SET_COLOUR_BY_VALUE,
	payload,
});

export const updateTeamId = (payload: UpdateTeamIdActionPayload) => ({
	type: UPDATE_TEAM_ID,
	payload,
});

export const removeTeamId = (payload: RemoveTeamIdActionPayload) => ({
	type: REMOVE_TEAM_ID,
	payload,
});

export const setColourForNewTeam = (payload: SetColourForNewTeamActionPayload) => ({
	type: SET_COLOUR_FOR_NEW_TEAM,
	payload,
});

export const addIssueTypeColour = (): AddIssueTypeColourAction => ({ type: ADD_ISSUE_TYPE_COLOUR });

export const updateIssueTypeColour = (
	payload: UpdateIssueTypeColourActionPayload,
): UpdateIssueTypeColourAction => ({
	type: UPDATE_ISSUE_TYPE_COLOUR,
	payload,
});

export const removeIssueTypeColour = (
	payload: RemoveIssueTypeColourActionPayload,
): RemoveIssueTypeColourAction => ({
	type: REMOVE_ISSUE_TYPE_COLOUR,
	payload,
});

export const clearAllIssueTypeColours = (): ClearAllColourIssueTypesAction => ({
	type: CLEAR_ALL_ISSUE_TYPE_COLOURS,
});

export const addLabelColour = (): AddLabelAction => ({ type: ADD_LABEL_COLOUR });

export const updateLabelColour = (
	payload: UpdateLabelColourActionPayload,
): UpdateLabelColourAction => ({
	type: UPDATE_LABEL_COLOUR,
	payload,
});

export const removeLabelColour = (
	payload: RemoveLabelColourActionPayload,
): RemoveLabelColourAction => ({
	type: REMOVE_LABEL_COLOUR,
	payload,
});

export const clearAllLabelColours = (): ClearAllColourLabelsAction => ({
	type: CLEAR_ALL_LABEL_COLOURS,
});

export const addPriorityColour = (): AddPriorityColourAction => ({ type: ADD_PRIORITY_COLOUR });

export const setPriorityColourMap = (
	payload: SetPriorityColourMapActionPayload,
): SetPriorityColourMapAction => ({
	type: SET_PRIORITY_COLOUR_MAP,
	payload,
});

export const updatePriorityColour = (
	payload: UpdatePriorityColourActionPayload,
): UpdatePriorityColourAction => ({
	type: UPDATE_PRIORITY_COLOUR,
	payload,
});

export const removePriorityColour = (
	payload: RemovePriorityColourActionPayload,
): RemovePriorityColourAction => ({
	type: REMOVE_PRIORITY_COLOUR,
	payload,
});

export const clearAllPriorityColours = (): ClearAllColourPrioritiesAction => ({
	type: CLEAR_ALL_PRIORITY_COLOURS,
});

export const addSelectColour = (): AddSelectColourAction => ({ type: ADD_SELECT_COLOUR });

export const updateSelectColour = (
	payload: UpdateSelectColourActionPayload,
): UpdateSelectColourAction => ({
	type: UPDATE_SELECT_COLOUR,
	payload,
});

export const removeSelectColour = (
	payload: RemoveSelectColourActionPayload,
): RemoveSelectColourAction => ({
	type: REMOVE_SELECT_COLOUR,
	payload,
});

export const clearAllSelectColours = (): ClearAllColourSelectsAction => ({
	type: CLEAR_ALL_SELECT_COLOURS,
});

export const addComponentColour = (): AddComponentAction => ({ type: ADD_COMPONENT_COLOUR });

export const updateComponentColour = (
	payload: UpdateComponentColourActionPayload,
): UpdateComponentColourAction => ({
	type: UPDATE_COMPONENT_COLOUR,
	payload,
});

export const removeComponentColour = (
	payload: RemoveComponentColourActionPayload,
): RemoveComponentColourAction => ({
	type: REMOVE_COMPONENT_COLOUR,
	payload,
});

export const clearAllComponentColours = (): ClearAllColourComponentsAction => ({
	type: CLEAR_ALL_COMPONENT_COLOURS,
});
