import { move } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import {
	RESET,
	SHOW_COLUMN,
	HIDE_COLUMN,
	MOVE_COLUMN,
	type ResetAction,
	type ShowColumnAction,
	type HideColumnAction,
	type MoveColumnAction,
} from './actions';
import {
	type ColumnIdV0,
	type FieldColumnsState,
	type IssueColumnState,
	ADD_FIELDS_COLUMN_ID,
} from './types';

export type Action = ResetAction | ShowColumnAction | HideColumnAction | MoveColumnAction;

// This only needs to contain the columns that should be visible by default.
// Hidden columns will be automatically added by selectors.
export const initialState: FieldColumnsState = {
	columns: [
		{
			id: 'targetStart',
			isVisible: true,
		},
		{
			id: 'targetEnd',
			isVisible: true,
		},
		{
			id: 'issueStatus',
			isVisible: true,
		},
	],
};

export const updateColumn = (
	columns: IssueColumnState[],
	id: string,
	isVisible: boolean,
): IssueColumnState[] => {
	const safeColumns = columns.filter((col) => col.id !== ADD_FIELDS_COLUMN_ID);

	return [
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		...safeColumns.filter((col) => col.id !== (id as ColumnIdV0)),
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		{ id: id as ColumnIdV0, isVisible },
	];
};

export const moveColumn = (
	columns: IssueColumnState[],
	columnToMove: string,
	moveToTheIndexOf: string,
): IssueColumnState[] => {
	const indexOfColumnToMove = columns.findIndex((col) => col.id === columnToMove);
	let indexOfMoveTo = columns.findIndex((col) => col.id === moveToTheIndexOf);

	/**
	 * the columns array contains incomingDependencies and outgoingDependencies columns
	 * but when moving columns, when incomingDependencies is moved, the outgoingDependencies column
	 * should move along with it.
	 *
	 * Take a look at the tests to understand these cases more
	 */

	if (columnToMove === 'incomingDependencies') {
		const incomingDependencyMoveResults = move(indexOfColumnToMove, indexOfMoveTo, columns);
		const outgoingDependencyIndex = incomingDependencyMoveResults.findIndex(
			(col) => col.id === 'outgoingDependencies',
		);
		const indexOfMoveToForOutgoingDependency = incomingDependencyMoveResults.findIndex(
			(col) => col.id === 'incomingDependencies',
		);

		// adjust index based on whether the column is moving up or down
		const delta = outgoingDependencyIndex > indexOfMoveToForOutgoingDependency ? 1 : 0;
		const result = move(
			outgoingDependencyIndex,
			indexOfMoveToForOutgoingDependency + delta,
			incomingDependencyMoveResults,
		);
		return result;
	}

	// When a column is moving to the index of incomingDependencies, it should not move in-between incomingDependencies and outgoingDependencies
	if (moveToTheIndexOf === 'incomingDependencies') {
		const delta = indexOfMoveTo > indexOfColumnToMove ? 1 : 0;
		indexOfMoveTo += delta;
	}

	return move(indexOfColumnToMove, indexOfMoveTo, columns);
};

const reducer = (state: FieldColumnsState = initialState, action: Action): FieldColumnsState => {
	switch (action.type) {
		case RESET:
			return { ...action.payload };
		case SHOW_COLUMN:
			return {
				...state,
				columns: updateColumn(state.columns, action.id, true),
			};
		case HIDE_COLUMN:
			return {
				...state,
				columns: updateColumn(state.columns, action.id, false),
			};
		case MOVE_COLUMN:
			return {
				...state,
				columns: [
					...moveColumn(
						state.columns,
						action.payload.columnToMove,
						action.payload.moveToTheIndexOf,
					),
				],
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};

export default reducer;
