import isEqual from 'lodash/isEqual';
import memoizeOne from 'memoize-one';
import type { RangeExtractor } from '../../../common/types';
import { useScrollY, useScrollYDirection } from '../../../controllers/container';
import { DOWNWARD, UPWARD } from '../../../controllers/container/types.tsx';
import type { Row } from './types';

/**
 * Returns the rows that need to be rendered which includes
 * the ones being within the viewport and over-scanned ones
 *
 * @returns The [above, within, below]
 */
export const getRanges = (
	containerHeight: number,
	scrollOffset: number,
	getRowHeight: (index: number) => number | undefined,
	overscanAbove = 20,
	overscanBelow = 20,
): [Row[], Row[], Row[]] => {
	const above: Row[] = [];
	const within: Row[] = [];
	const below: Row[] = [];
	let lastRow: Row | undefined;

	const aboveViewport = (offset: number) => offset < scrollOffset;
	const belowViewport = (offset: number) => offset > scrollOffset + containerHeight;
	const inViewport = (offset: number) =>
		offset >= scrollOffset && offset <= scrollOffset + containerHeight;

	for (let cursor = 0; cursor < Infinity; cursor++) {
		const height = getRowHeight(cursor);

		if (height === undefined) break;

		const top = lastRow ? lastRow.top + lastRow.height : 0;
		const row = { index: cursor, top, height };

		if (aboveViewport(row.top + row.height)) {
			above.push(row);

			while (above.length > overscanAbove) {
				above.shift();
			}
		} else if (inViewport(row.top + row.height) || inViewport(row.top)) {
			within.push(row);
		} else if (belowViewport(row.top)) {
			if (below.length >= overscanBelow) {
				break;
			}

			below.push(row);
		}

		lastRow = row;
	}

	return [above, within, below];
};

/**
 * Lightweight wrapper for extracting the virtual ranges (above, within and below viewport)
 * It is being used as a performance optimisation. The virtualizer updates frequently to scroll
 * events, however we want to avoid re-renders unless the visible output would also change.
 */
export const getExtractedRanges = memoizeOne(
	(above: Row[], within: Row[], below: Row[], rangeExtractor: RangeExtractor) => {
		const getIndexes = (rows: Row[]) => rows.map(({ index }) => index);

		return rangeExtractor({
			above: getIndexes(above),
			within: getIndexes(within),
			below: getIndexes(below),
		});
	},
	isEqual,
);

/** Returns the top offset for virtualization which respects the threshold settings. */
export const useScrollOffset = (
	containerHeight: number,
	scrollHeight: number,
	scrollThreshold?: number,
): number => {
	const [scrollY] = useScrollY();
	const [scrollYDirection] = useScrollYDirection();

	if (scrollThreshold === undefined) {
		return scrollY;
	}

	switch (scrollYDirection) {
		case DOWNWARD:
			return Math.floor(scrollY / scrollThreshold) * scrollThreshold;

		case UPWARD: {
			const reverseScrollOffset = (input: number) => scrollHeight - (input + containerHeight);
			return reverseScrollOffset(
				Math.floor(reverseScrollOffset(scrollY) / scrollThreshold) * scrollThreshold,
			);
		}

		default:
			return scrollY;
	}
};
