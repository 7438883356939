import {
	OPEN_UPDATE_JIRA_DIALOG,
	type OpenDialogAction,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/title-bar/update-jira/actions';
import { ENTITY } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import { START as COMMIT_START, type StartAction as CommitStartAction } from '../commit/actions';
import { ADD, type AddAction } from './actions';
import type { State } from './types';

type Action = AddAction | CommitStartAction | OpenDialogAction;

const initialState: State = {
	[ENTITY.ISSUE]: {},
	[ENTITY.RELEASE]: {},
	[ENTITY.CROSS_PROJECT_RELEASE]: {},
	[ENTITY.TEAM]: {},
	[ENTITY.RESOURCE]: {},
	[ENTITY.SPRINT]: {},
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case ADD: {
			const { category, itemId, warnings } = action.payload;

			return {
				...state,
				[category]: {
					...state[category],
					[itemId]: warnings,
				},
			};
		}
		case COMMIT_START:
		case OPEN_UPDATE_JIRA_DIALOG: {
			return initialState;
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
