import React from 'react';
import Avatar from '@atlaskit/avatar';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip';
import NotInPlan from '../not-in-plan';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

export default function Assignee(props: Props) {
	const { assignee, assigneeList } = props;
	if (!assignee) {
		return <div>-</div>;
	}
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const person = assigneeList.find((person) => person.personId === assignee);
	if (person) {
		const {
			jiraUser: { title, avatarUrl },
		} = person;
		return (
			<EllipsedWithTooltip content={title} id={assignee}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<span className={styles.avatar}>
					<Avatar size="xsmall" src={avatarUrl} />
				</span>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<span className={styles.title}>{title}</span>
			</EllipsedWithTooltip>
		);
	}
	return (
		<div>
			<NotInPlan />
		</div>
	);
}
