import type { GroupOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/types';
import type { EnrichedVersion } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/types';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import {
	GROUPING,
	COMPLETED_RELEASES_GROUP,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import type { ProjectIdToGroupOptionTuple, ProjectId } from './release-dialog-content/types.tsx';
import type { StateProps, Groups } from './types';

export const getGroupOptionsFromReleasesByProject = ({
	groups,
	enrichedVersionsById,
	enrichedVersions,
	completeVersionsById,
}: {
	groups: Groups;
	enrichedVersionsById: StateProps['enrichedVersionsById'];
	enrichedVersions: StateProps['enrichedVersions'];
	completeVersionsById: StateProps['completeVersionsById'];
}): ProjectIdToGroupOptionTuple[] => {
	const createGroupOptionFromRelease = (release: EnrichedVersion): GroupOption => ({
		group: `release-${release.id}`,
		groupCombination: { release: release.id },
		groupName: release.name,
		grouping: GROUPING.RELEASE,
		startInlineCreateInEmptyGroup: true,
		parentGroup: isDefined(completeVersionsById[release.id]) ? COMPLETED_RELEASES_GROUP : undefined,
	});

	const projectToGroupOptionsMap = new Map<ProjectId, ProjectIdToGroupOptionTuple>();
	// We use a set to keep track of which releases we have already handled, because
	// we are handling a map of projectId to Option, neither of which are easy to filter through
	const processedReleaseIds: Set<string> = new Set();

	for (const group of groups) {
		const { scope, ...groupOption } = group;
		if (
			isDefined(groupOption.groupCombination) &&
			isDefined(groupOption.groupCombination.release)
		) {
			const enrichedVersion = enrichedVersionsById[groupOption.groupCombination.release];
			processedReleaseIds.add(groupOption.groupCombination.release);

			if (enrichedVersion !== undefined) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const projectId = (enrichedVersion.projects[0] as Project).id;
				const [, releasesArray] = projectToGroupOptionsMap.get(projectId) ?? [undefined, []];
				projectToGroupOptionsMap.set(projectId, [projectId, [...releasesArray, groupOption]]);
			}
		}
	}

	// Create option for releases that aren't currently assigned to any issues
	// Which therefore currently do not have a group.
	for (const release of enrichedVersions) {
		if (!processedReleaseIds.has(release.id)) {
			const option = createGroupOptionFromRelease(release);
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const projectId = (release.projects[0] as Project).id;
			const [, releasesArray] = projectToGroupOptionsMap.get(projectId) ?? [undefined, []];
			projectToGroupOptionsMap.set(projectId, [projectId, [...releasesArray, option]]);
		}
	}

	return [...projectToGroupOptionsMap.values()];
};
