import { getTimelineRange } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/timeline';
import { getShowSprints } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { OwnProps, QueryProps } from './types';

export default createStructuredSelector<State, OwnProps, QueryProps>({
	showCapacity: getShowSprints,
	timelineRange: getTimelineRange,
});
