import type { Issues } from './types';

export const RESET = 'state.ui.main.tabs.roadmap.scope.issues.RESET';
export const SET_IS_LOADING_HISTORY =
	'state.ui.main.tabs.roadmap.scope.issues.SET_IS_LOADING_HISTORY';
export const SET_IS_LOADING = 'state.ui.main.tabs.roadmap.scope.issues.SET_IS_LOADING';
export const SET_IS_FULL_ISSUE_SET_LOADING =
	'state.ui.main.table.roadmap.scope.issues.SET_IS_FULL_ISSUE_SET_LOADING';
export const SET_IS_SAVING = 'state.ui.main.tabs.roadmap.scope.issues.SET_IS_SAVING';

export type ResetAction = {
	type: typeof RESET;
	payload: Issues;
};

export type SetIsLoadingAction = {
	type: typeof SET_IS_LOADING;
	payload: boolean;
};

export type SetIsLoadingHistoryAction = {
	type: typeof SET_IS_LOADING_HISTORY;
	payload: boolean;
};

export type SetIsSavingAction = {
	type: typeof SET_IS_SAVING;
	payload: boolean;
};

export const reset = (payload: Issues): ResetAction => ({
	type: RESET,
	payload,
});

export const setIsLoadingHistory = (payload: boolean) => ({
	type: SET_IS_LOADING_HISTORY,
	payload,
});

export const setIsLoading = (payload: boolean) => ({
	type: SET_IS_LOADING,
	payload,
});

export const setIsSaving = (payload: boolean) => ({
	type: SET_IS_SAVING,
	payload,
});

export type Action =
	| ResetAction
	| SetIsLoadingAction
	| SetIsSavingAction
	| SetIsLoadingHistoryAction;

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	ToggleSelectedIssuesAction,
	ToggleSelectedIssuesActionPayload,
} from './selectable-issue/actions';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { toggleSelectedIssues, TOGGLE_SELECTED_ISSUES } from './selectable-issue/actions';
