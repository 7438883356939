import { getComponentsByProjectId } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/components';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

const querySelector = createStructuredSelector<State, {}, StateProps>({
	componentsByProjectId: getComponentsByProjectId,
});

export default querySelector;
