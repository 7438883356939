import { VIEW_MODES } from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import { isExportMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app';
import { getTimelineExportWidth } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/section-widths';
import {
	getViewMode,
	getTimeScaleViewSettings,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import {
	createStructuredSelector,
	createSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export const hasScrollableTimeline = createSelector(
	[getViewMode, getTimeScaleViewSettings],
	(viewMode, timeScale) => {
		if (viewMode === VIEW_MODES.LIST) {
			return false;
		}

		if (timeScale.mode) {
			return timeScale.mode !== 'custom';
		}

		return undefined;
	},
);

export default createStructuredSelector<State, StateProps>({
	isExportMode,
	hasScrollableTimeline,
	timelineExportWidth: getTimelineExportWidth,
});
