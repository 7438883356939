import React, { useCallback } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { defaultSorting } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/visualisations/types.tsx';
import AdvancedFieldsMenu from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/fields/header/advanced-fields-menu';
import CollapseButton from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/fields/header/collapse-button';
import { getMatchingConfiguredDate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/fields/utils';
import { useColumnWidth } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/index.tsx';
import ResetSortingOrder from './reset-sorting-order';
import type { Props } from './types';

export const FieldsHeader = ({
	columns,
	hideColumn,
	showColumn,
	moveColumn,
	changeSort,
	dateConfiguration,
	sorting,
	collapsible,
	useColumnToggle,
}: Props) => {
	const [scopeColumnWidth] = useColumnWidth({ column: 0, preview: true });
	const resetSort = () => {
		changeSort(defaultSorting);
	};

	const toggle = useColumnToggle();
	const collapse = useCallback(() => toggle(false), [toggle]);

	return (
		<Box xcss={containerStyles}>
			<Box xcss={stickyContainerStyles} style={{ left: scopeColumnWidth }}>
				<AdvancedFieldsMenu
					id="fields-top-section"
					columns={columns}
					hideColumn={hideColumn}
					showColumn={showColumn}
					moveColumn={moveColumn}
					getMatchingConfiguredDate={getMatchingConfiguredDate(dateConfiguration)}
				/>
			</Box>
			<Box xcss={resetContainerStyles}>
				{sorting.field !== defaultSorting.field && <ResetSortingOrder onClick={resetSort} />}
				{collapsible && <CollapseButton onClick={collapse} />}
			</Box>
		</Box>
	);
};
const containerStyles = xcss({
	position: 'absolute',
	inset: 'space.0',
	display: 'flex',
	alignItems: 'center',
	boxSizing: 'border-box',
	justifyContent: 'space-between',
});

const stickyContainerStyles = xcss({
	position: 'sticky',
	right: 'space.100',
	marginRight: 'space.100',
	overflow: 'hidden',
});

const resetContainerStyles = xcss({
	position: 'sticky',
	right: 'space.0',
	marginRight: 'space.100',
	minWidth: 'space.200',
	flex: '0 1 auto',
	display: 'flex',
});
