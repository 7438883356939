import React, { useCallback, type ComponentType } from 'react';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import commonLabelMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/label/messages';
import type { Props, Messages, InjectProps, InjectedComponentProps } from './types';

const messageDescriptors: Messages = {
	placeholder: commonLabelMessages.chooseLabel,
	noLabels: commonLabelMessages.noLabels,
	noLabelsLeft: commonLabelMessages.noLabelsLeft,
	noMatch: commonLabelMessages.noMatch,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (ComposedComponent: ComponentType<Omit<Props, keyof InjectedComponentProps>>) =>
	function (props: Omit<Props, keyof InjectProps | keyof InjectedComponentProps>) {
		const fetchOptions = useCallback(
			(
				customFieldId: string,
				_: number,
				_2: string,
				query = '',
			): Promise<{ value: string; label: string }[]> => {
				const { plan, genericError } = props;

				if (!isDefined(plan.id)) {
					genericError({
						message: 'Plan id is not available in the options provider label',
					});
					return Promise.reject('Plan id is not available');
				}
				if (!isDefined(plan.currentScenarioId)) {
					genericError({
						message: 'Scenario id is not available in the options provider label',
					});
					return Promise.reject('Scenario id is not available');
				}

				const body: {
					planId?: number;
					customFieldId: string;
					query: string;
					scenarioId?: string | number;
				} = {
					planId: plan.id,
					customFieldId,
					query,
					scenarioId: plan.currentScenarioId,
				};

				return fetch('/rest/jpo/1.0/labels', {
					method: 'POST',
					body,
				})
					.then((response) => {
						if (response.ok) {
							return response.json();
						}
						throw new Error(`Response not ok. Statuscode: ${response.status}`);
					})
					.then((data) => data.items.map((value: string) => ({ label: value, value })))
					.catch((err) => {
						genericError({ message: err.message, stackTrace: err.stack });
					});
			},
			[props],
		);
		return (
			<ComposedComponent {...props} fetchOptions={fetchOptions} messages={messageDescriptors} />
		);
	};
