import React from 'react';
import { DocumentTitle } from '@atlassian/jira-global-document-title';
import { useIntl } from '@atlassian/jira-intl';
import { usePlan } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/plan';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import messages from './messages';

export default function DocumentTitleWrapper({ pageHeading }: { pageHeading?: string }) {
	const { data } = usePlan();
	const { formatMessage } = useIntl();

	const productName = formatMessage(messages.plans);

	const documentTitle =
		isDefined(data?.title) && isDefined(pageHeading)
			? `${pageHeading} - ${data?.title} - ${productName}`
			: `${productName}`;

	return <DocumentTitle title={documentTitle} />;
}
