import React, { Component, type ChangeEvent } from 'react';
import classNames from 'classnames';
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle';
import AkTextField from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { injectIntl } from '@atlassian/jira-intl';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import { IconButton } from '@atlassian/jira-portfolio-3-common/src/icon/index.tsx';
import { DialogMenuItem } from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

// eslint-disable-next-line jira/react/no-class-components
class SearchField extends Component<Props> {
	onChange = (e: ChangeEvent<HTMLInputElement>) => {
		this.props.onQueryChange(e.target.value);
	};

	onClear = () => {
		this.props.onQueryChange('');
	};

	render() {
		const { placeholder, searchQuery, autoFocus = true, ariaLabel, intl } = this.props;

		const hasInput = searchQuery.length > 0;
		const styleName = classNames({ [styles.hasInput]: hasInput });

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<DialogMenuItem style={{ position: 'relative' }} className={styleName}>
				<AkTextField
					autoFocus={autoFocus}
					onChange={this.onChange}
					placeholder={placeholder}
					value={searchQuery}
					aria-label={ariaLabel}
				/>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.clearSearchField} style={{ display: hasInput ? 'block' : 'none' }}>
					<IconButton
						onClick={this.onClear}
						tabIndex={-1}
						ariaLabel={intl.formatMessage(messages.clearButtonLabel)}
					>
						<CrossCircleIcon
							label=""
							size="small"
							primaryColor={token('color.icon.subtle', colors.N40)}
						/>
					</IconButton>
				</div>
			</DialogMenuItem>
		);
	}
}
export default injectIntl(SearchField);
