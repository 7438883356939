import { getSprints } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints';
import { getTimelineState } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/timeline-preview';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { getForcedGradients } from '../utils';
import type { StateProps } from './types';

const mapStateToProps = createStructuredSelector<State, StateProps>({
	sprints: getSprints,
	// Have to do a similar calculation for previews
	// @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type. | TS7006 - Parameter 'props' implicitly has an 'any' type.
	previewForcedGradients: (state, props) => {
		const { preview, issueChildren } = props;
		if (!preview) return;
		const { previews } = getTimelineState(state) || {};
		const childrenPreviews =
			previews && issueChildren?.map((issue: Issue) => previews[issue.id]).filter(isDefined);
		return getForcedGradients(preview, childrenPreviews);
	},
});

export default mapStateToProps;
