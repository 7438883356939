import React, { useCallback, useState } from 'react';
import * as R from 'ramda';
import { useIntl } from '@atlassian/jira-intl';
import {
	endOfUtcDay,
	startOfUtcDay,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import {
	convertSecondsToHours,
	convertSecondsToDays,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/estimation';
import type { SprintUnit } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { PLANNING_UNITS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import TimelineSprintTooltip from '../../sprint-tooltip';
import SprintFlyout from '../sprint-flyout/view.tsx';
import Capacity from './capacity';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

const roundEstimate = (x: number) => Math.round(10 * x) / 10;

const Capacities = function ({
	plan,
	teamObject,
	plannedCapacity,
	workingHours,
	isUpdated,
	zoomLevel,
	getPercentageOffsetFromToday,
	sprintsCapacityOnTimeline,
	sprint: selectedSprint,
	team,
}: Props) {
	const [open, setOpen] = useState(false);
	const { formatMessage } = useIntl();
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const toggleFlyout = useCallback(() => setOpen((open) => !open), []);

	if (!isDefined(teamObject)) {
		return null;
	}

	const { issueSource } = teamObject;
	if (issueSource === undefined || issueSource === null) {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		return <div className={styles.message}>{formatMessage(messages.noIssueSources)}</div>;
	}

	const capacity: SprintUnit[] | undefined = R.path(
		[team, selectedSprint],
		sprintsCapacityOnTimeline,
	);

	if (!capacity) {
		return null;
	}

	const { planningUnit } = plan;
	const timeline = capacity.map((sprint: SprintUnit, index: number) => {
		const { usedCapacity = 0 } = sprint;
		const availableCapacity = plannedCapacity
			? plannedCapacity.capacity || 0
			: sprint.availableCapacity || 0;
		let display;
		switch (planningUnit) {
			case PLANNING_UNITS.DAYS:
				display = formatMessage(messages.daysUnit, {
					count: roundEstimate(convertSecondsToDays(usedCapacity, workingHours)),
				});
				break;
			case PLANNING_UNITS.HOURS:
				display = formatMessage(messages.hoursUnit, {
					count: roundEstimate(convertSecondsToHours(usedCapacity)),
				});
				break;
			case PLANNING_UNITS.STORY_POINTS:
				display = `${roundEstimate(usedCapacity)} / ${availableCapacity}`;
				break;
			default:
				throw new Error(`Unexpected planningUnit: ${planningUnit}`);
		}

		const content =
			sprint.id === 'GAP' ? null : (
				<SprintFlyout
					isOpen={open}
					onToggle={toggleFlyout}
					sprint={sprint}
					teamObject={teamObject}
					plannedCapacity={plannedCapacity}
					plan={plan}
				>
					<TimelineSprintTooltip content={display}>
						<div
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className={`${styles.sprintBox} ${
								usedCapacity > availableCapacity ? styles.overbooked : ''
							}`}
						>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<div className={styles.bar}>
								<div
									// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
									className={`${
										usedCapacity > availableCapacity ? styles.overbooked : styles.okay
									}`}
									style={{
										height: `${Math.min(100, (100 * usedCapacity) / availableCapacity)}%`,
									}}
								/>
							</div>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<div className={styles.sprintName}>{display}</div>
						</div>
					</TimelineSprintTooltip>
				</SprintFlyout>
			);

		const style =
			zoomLevel && getPercentageOffsetFromToday
				? {
						width: `${
							getPercentageOffsetFromToday(endOfUtcDay(sprint.rawEndDate || sprint.endDate)) -
							getPercentageOffsetFromToday(startOfUtcDay(sprint.rawStartDate || sprint.startDate))
						}%`,

						left: `${getPercentageOffsetFromToday(
							startOfUtcDay(sprint.rawStartDate || sprint.startDate),
						)}%`,
						position: 'absolute',
						transition: 'width 0.1s ease-in-out 0s',
						animation: '0.2s ease-in-out',
					}
				: {
						width: `${sprint.widthPercentage}%`,
						position: 'absolute',

						left: `${sprint.offset}%`,
						transition: 'width 0.1s ease-in-out 0s',
						animation: '0.2s ease-in-out',
					};

		return (
			<Capacity
				key={index}
				sprintId={sprint.id}
				content={content}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={style}
				isUpdated={isUpdated}
			/>
		);
	});

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.sprintLabels} key={0}>
			{timeline}
		</div>
	);
};

export default Capacities;
