import React, { memo } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useIsScrollingY } from '../../controllers/container';
import { GridProvider } from '../../controllers/grid';
import { Colgroup } from './colgroup';
import { Head, Body } from './rows';
import type { Props } from './types';
import { Virtualized } from './virtualized';
import { Virtualizer } from './virtualizer';

const Blanket = () => {
	const [isScrollingY] = useIsScrollingY();
	if (!isScrollingY) {
		return null;
	}
	return <Box xcss={blanketStyles} />;
};

export const Grid = memo(
	({
		rowConfigs,
		columnConfigs,
		colspan,
		layout = 'auto',
		row: renderRow,
		virtualizationScrollThreshold,
		empty,
		children,
		stickyOffset,
		rangeExtractor,
		columnWidths,
		overscan = 0,
		resizer,
		onColumnWidthsChange,
		ariaLabel,
		role,
		testId,
		dataName,
	}: Props) => {
		const layoutStyles = layout === 'fixed' ? [fixedTableStyles] : [];

		const table = (top: number, bottom: number) => (
			<>
				<Box
					as="table"
					xcss={[tableStyles, ...layoutStyles]}
					aria-label={ariaLabel}
					role={role}
					testId={testId}
					data-name={dataName}
				>
					<Colgroup count={columnConfigs.length} />
					<Virtualized rowConfigs={rowConfigs}>
						{({ head, body, liteRowIndexes, visibleCols }) => (
							<>
								<Blanket />
								<Box as="thead">
									<Head head={head} renderRow={renderRow} columnConfigs={columnConfigs} />
								</Box>
								<Box as="tbody">
									{empty(top /* Virtualization */)}
									<Body
										body={body}
										renderRow={renderRow}
										columnConfigs={columnConfigs}
										liteRowIndexes={liteRowIndexes}
										visibleCols={visibleCols}
									/>
									{empty(bottom)}
								</Box>
							</>
						)}
					</Virtualized>
				</Box>
				<Box role="presentation" xcss={fillWrapperStyles}>
					<Box
						as="table"
						xcss={[tableStyles, ...layoutStyles, fillParentStyles]}
						role="presentation"
					>
						<Colgroup count={columnConfigs.length} />
						<Box as="tbody">{empty()}</Box>
					</Box>
				</Box>
			</>
		);

		return (
			<Virtualizer
				rowConfigs={rowConfigs}
				overscan={overscan}
				rangeExtractor={rangeExtractor}
				scrollThreshold={virtualizationScrollThreshold}
			>
				{({ top, bottom }) => (
					<GridProvider
						layout={layout}
						columns={columnConfigs}
						widths={columnWidths}
						onWidthsChange={onColumnWidthsChange}
						rows={rowConfigs}
						colspan={colspan}
						stickyOffset={stickyOffset}
						resizer={resizer}
					>
						{children?.(table(top, bottom))}
					</GridProvider>
				)}
			</Virtualizer>
		);
	},
);

const tableStyles = xcss({
	margin: 'space.0',
	borderCollapse: 'separate',
	borderSpacing: 0,
	minWidth: '100%',
});

const fixedTableStyles = xcss({
	tableLayout: 'fixed',
});

const fillParentStyles = xcss({
	height: '100%',
});

const fillWrapperStyles = xcss({
	flex: 1,
	position: 'relative',
});

const blanketStyles = xcss({
	position: 'absolute',
	top: 'space.0',
	left: 'space.0',
	width: '100%',
	height: '100%',
	zIndex: 'blanket',
});
