import type { Issue } from '../issues/types';

export const RESET = 'state.domain.scenario-removed-issues.RESET' as const;
export const ADD = 'state.domain.scenario-removed-issues.ADD' as const;
export const BULK_ADD = 'state.domain.scenario-removed-issues.BULK_ADD' as const;
export const REMOVE = 'state.domain.scenario-removed-issues.REMOVE' as const;
export const BULK_REMOVE = 'state.domain.scenario-removed-issues.BULK_REMOVE' as const;

export type ResetAction = {
	type: typeof RESET;
	payload: Issue[];
};

export const reset = (payload: Issue[]): ResetAction => ({
	type: RESET,
	payload,
});

export type AddAction = {
	type: typeof ADD;
	payload: Issue;
};

export const add = (payload: Issue): AddAction => ({
	type: ADD,
	payload,
});

export type BulkAddAction = {
	type: typeof BULK_ADD;
	payload: Issue[];
};

export const bulkAdd = (payload: Issue[]): BulkAddAction => ({
	type: BULK_ADD,
	payload,
});

export type RemoveAction = {
	type: typeof REMOVE;
	payload: string;
};

export const remove = (payload: string): RemoveAction => ({
	type: REMOVE,
	payload,
});

export type BulkRemoveAction = {
	type: typeof BULK_REMOVE;
	payload: string[];
};

export const bulkRemove = (payload: string[]): BulkRemoveAction => ({
	type: BULK_REMOVE,
	payload,
});
