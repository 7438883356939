import {
	getAllIssuesWithOriginalAndOptimized,
	getIssuesWithOriginalsAndOptimized,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope';
import {
	getApplyingProgress,
	getIsSolutionLatest,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/solution';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export default createStructuredSelector<State, {}, StateProps>({
	allIssues: getAllIssuesWithOriginalAndOptimized,
	filteredIssues: getIssuesWithOriginalsAndOptimized,
	isLatest: getIsSolutionLatest,
	progress: getApplyingProgress,
});
