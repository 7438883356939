import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	warningCenterHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.warning-center-header',
		defaultMessage: 'Warning center',
		description: 'Header text of the warning center',
	},
	// Remove when FF com.atlassian.rm.jpo.jpo3cloud.revamp-warning-centre is removed
	warningsDialogProblemHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.warnings-dialog-problem-header',
		defaultMessage: 'Warning',
		description: 'It is a warning dialog problem header label.',
	},
	warningsDialogHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.warnings-dialog-header',
		defaultMessage: 'Show on timeline',
		description: 'It is a warning dialog problem header label.',
	},
	warningPopupStatusHeaderText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.warning-popup-status-header-text',
		defaultMessage: 'Status',
		description: 'Header text of the status column',
	},
	warningPopupEnabledStateHeaderText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.warning-popup-enabled-state-header-text',
		defaultMessage: 'Enabled',
		description: 'Header text of the checkbox column',
	},
	disabledIssueStatusText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.disabled-issue-status-text',
		defaultMessage: 'disabled',
		description: 'Status text to indicate that a warning is disabled',
	},
	// Remove when FF com.atlassian.rm.jpo.jpo3cloud.revamp-warning-centre is removed
	warningAffectCountText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.warning-affect-count-text',
		defaultMessage: 'affects {count, plural, one {# issue} other {# issues}}',
		description: 'Text to indicate how many issues are affected by this warning',
	},
	warningCountText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.warning-count-text',
		defaultMessage: '{count, plural, one {# issue} other {# issues}}',
		description: 'Text to indicate how many issues are affected by this warning',
	},
	zeroCountWarningText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.zero-count-warning-text',
		defaultMessage: '-',
		description: 'Hypen is used to indicate that 0 issues are affected by this warning',
	},
	// Remove when FF com.atlassian.rm.jpo.jpo3cloud.revamp-warning-centre is removed
	warningsDialogPreamble: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.warnings-dialog-preamble',
		defaultMessage: 'Configure which type of warnings you want to see in your plan.',
		description: 'It is a warning dialog preamble text.',
	},
	warningsDialogDescription: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.warnings-dialog-description',
		defaultMessage: 'Configure which warnings you want to see in your plan. {link}',
		description: 'It is a warning dialog preamble text.',
	},
	warningsDialogDescriptionAndDocumentationLink: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.warnings-dialog-description-and-documentation-link',
		defaultMessage:
			'Configure which warnings you want to see in your plan. Use the filter menu to only show issues with warnings. {link}',
		description: 'It is a warning dialog preamble text.',
	},
	iphLink: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.iph-link',
		defaultMessage: 'How do I resolve these warnings?',
		description: 'Link to the IPH article about the warnings',
	},
	// Remove when FF com.atlassian.rm.jpo.jpo3cloud.revamp-warning-centre is removed
	showWarnings: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.show-warnings',
		defaultMessage: 'Show warnings',
		description: 'Label of the "Show warnings" toggle in the header of the Warnings Centre',
	},
	showWarningsOnYourTimeline: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.show-warnings-on-your-timeline',
		defaultMessage: 'Show warnings on your timeline',
		description:
			'Label of the "Show warnings on your timeline" toggle in the header of the Warnings Centre',
	},
	// Remove when FF com.atlassian.rm.jpo.jpo3cloud.revamp-warning-centre is removed
	filterIssuesWithWarning: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.filter-issues-with-warning',
		defaultMessage: 'Filter issues with warnings',
		description: 'Text of filter issues with warning quick filter',
	},
	goBackToOverview: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.go-back-to-overview',
		defaultMessage: 'Go back to overview',
		description: 'Text of the back arrow',
	},
});
