import type { GoalsByARI } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-goals/types';
import { GOAL_NAME, GOAL_STATUS } from './constants';

type GoalValues = {
	[GOAL_NAME]: string[];
	[GOAL_STATUS]: string[];
};

const getGoalValues = (issueGoalARIs: string[] = [], goalsByARI: GoalsByARI): GoalValues =>
	issueGoalARIs.reduce<GoalValues>(
		(goalValues, issueGoalARI) => {
			const goal = goalsByARI[issueGoalARI];
			if (goal !== undefined) {
				// goal names
				goalValues[GOAL_NAME].push(goal.name);
				// goal statuses
				const goalStatus = goal?.state?.label;
				if (goalStatus !== undefined) {
					goalValues[GOAL_STATUS].push(goalStatus);
				}
			}
			return goalValues;
		},
		{ [GOAL_NAME]: [], [GOAL_STATUS]: [] },
	);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export { GOAL_NAME, GOAL_STATUS, getGoalValues };
