import { startInlineCreate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/inline-create';
import { setDefaultProject } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/actions';
import {
	toggleExpandGroup,
	type ToggleExpandGroupActionPayload,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/visualisations/actions.tsx';
import {
	type StartInlineCreateActionPayload,
	setIssueTypeIdForHierarchy,
	setGlobalCreateIssue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/scope/inline-create/actions.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { DispatchProps } from './types';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	startInlineCreate: (payload: StartInlineCreateActionPayload) => {
		dispatch(startInlineCreate(payload));
	},
	setIssueTypeIdForHierarchy: (hierarchyLevel: number, issueTypeId: number) => {
		dispatch(setIssueTypeIdForHierarchy(hierarchyLevel, issueTypeId));
	},
	setGlobalCreateIssue: () => {
		dispatch(setGlobalCreateIssue(true));
	},
	setDefaultProject: (projectId: number) => dispatch(setDefaultProject({ projectId })),
	toggleExpandGroup: (payload: ToggleExpandGroupActionPayload) =>
		dispatch(toggleExpandGroup(payload)),
});

export default mapDispatchToProps;
