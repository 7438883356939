import type { MessageDescriptor } from '@atlassian/jira-intl';
import type { ErrorDetails } from './types';

export const SHOW_ERROR = 'state.ui.errors.SHOW_ERROR' as const;
export const HIDE_ERROR = 'state.ui.errors.HIDE_ERROR' as const;
export const HIDE_ALL_ERRORS = 'state.ui.errors.HIDE_ALL_ERRORS' as const;
export const SHOW_ERROR_LOG_DIALOG = 'state.ui.errors.SHOW_ERROR_LOG_DIALOG' as const;
export const HIDE_ERROR_LOG_DIALOG = 'state.ui.errors.HIDE_ERROR_LOG_DIALOG' as const;
export const SET_IS_PROJECT_LIMIT_EXCEEDED =
	'state.ui.errors.SET_IS_PROJECT_LIMIT_EXCEEDED' as const;

export type ShowErrorActionPayload = {
	title: string;
	message: string;
	stackTrace?: string;
	details?: ErrorDetails;
	locationHash: string | null | undefined;
	locationPath: string | null | undefined;
	description?: MessageDescriptor;
};

export type ShowErrorAction = {
	type: typeof SHOW_ERROR;
	payload: ShowErrorActionPayload;
};

export type HideErrorAction = {
	type: typeof HIDE_ERROR;
};

export type HideAllErrorsAction = {
	type: typeof HIDE_ALL_ERRORS;
};

export type ShowErrorLogDialogAction = {
	type: typeof SHOW_ERROR_LOG_DIALOG;
};

export type HideErrorLogDialogAction = {
	type: typeof HIDE_ERROR_LOG_DIALOG;
};

export type SetIsProjectLimitExceeded = {
	type: typeof SET_IS_PROJECT_LIMIT_EXCEEDED;
};

export const showError = (
	message: string,
	stackTrace?: string,
	locationHash?: string | null,
	locationPath?: string | null,
	details?: ErrorDetails,
	description?: MessageDescriptor,
): ShowErrorAction => ({
	type: SHOW_ERROR,
	payload: {
		title: '',
		message,
		stackTrace,
		locationHash,
		locationPath,
		details,
		description,
	},
});

export const hideError = (): HideErrorAction => ({
	type: HIDE_ERROR,
});

export const hideAllErrors = (): HideAllErrorsAction => ({
	type: HIDE_ALL_ERRORS,
});

export const showErrorLogDialog = (): ShowErrorLogDialogAction => ({
	type: SHOW_ERROR_LOG_DIALOG,
});

export const hideErrorLogDialog = (): HideErrorLogDialogAction => ({
	type: HIDE_ERROR_LOG_DIALOG,
});

export const setIsProjectLimitExceeded = (): SetIsProjectLimitExceeded => ({
	type: SET_IS_PROJECT_LIMIT_EXCEEDED,
});
