import { sortAsLowerCaseByNameProp } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/composed';
import type { Version } from '../versions/types';
import { RESET, type ResetAction, ADD, type AddAction, REMOVE, type RemoveAction } from './actions';

const initialState: Version[] = [];

type Action = ResetAction | AddAction | RemoveAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: Version[] = initialState, action: Action): Version[] => {
	switch (action.type) {
		case RESET:
			return sortAsLowerCaseByNameProp([...action.payload]);
		case ADD: {
			return [...state, action.payload];
		}
		case REMOVE: {
			const removedId = action.versionId;
			return state.filter(({ id }) => id !== removedId);
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
