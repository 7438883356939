import type {
	JiraSuggestedChildIssueStatusType,
	JiraSuggestedIssueErrorType,
	JiraSuggestedIssueInput,
} from '@atlassian/jira-relay/src/__generated__/issueBreakdownSubscription.graphql';

export type OnNextStatusData = {
	readonly channelId: string | null | undefined;
	readonly status: JiraSuggestedChildIssueStatusType | null | undefined;
};
export type OnNextSuggestionData = {
	readonly description: string;
	readonly issueTypeId: string;
	readonly summary: string;
};

export type UseIssueBreakdownSubParams = {
	query: {
		issueId: string;
		channelId?: string | null;
		additionalContext?: string;
		acceptedChildIssues?: ReadonlyArray<JiraSuggestedIssueInput>;
		rejectedChildIssues?: ReadonlyArray<JiraSuggestedIssueInput>;
		issueTypeIds?: ReadonlyArray<string>;
	};
	onNextSuggestion?: (data: OnNextSuggestionData) => void;
	onNextStatus?: (data: OnNextStatusData) => void;
	onSuggestionError?: (
		error: JiraSuggestedIssueErrorType | SubscriptionError | null | undefined,
	) => void;
	onError?: (error: Error) => void;
	onTimeout?: () => void;
};

export const subscriptionErrors = {
	notEnoughInformation: 'NOT_ENOUGH_INFORMATION',
	noFurtherSuggestions: 'NO_FURTHER_SUGGESTIONS',
	unethicalContent: 'UNETHICAL_CONTENT',
	communicationsFailures: 'COMMUNICATIONS_FAILURES',
	unclassified: 'UNCLASSIFIED',
	network: 'NETWORK',
	timeout: 'TIMEOUT',
};

export type SubscriptionError = (typeof subscriptionErrors)[keyof typeof subscriptionErrors];
