import type { Permission, TeamPermissions, User } from '../../state/domain/permissions/types';
import type { State } from '../../state/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	Permissions,
	Permission,
	User,
	PermissionHolder,
	PermissionRole,
	PermissionActionPayload,
	TeamPermissions,
} from '../../state/domain/permissions/types';

export const getPlanPermissions = (state: State): Permission[] =>
	state.domain.permissions.planPermissions;
export const getPlanEditorPermissions = (state: State): Permission[] =>
	state.domain.permissions.planPermissions.filter((permission) =>
		permission.permissions.includes('EDIT'),
	);
export const getPlanViewerPermissions = (state: State): Permission[] =>
	state.domain.permissions.planPermissions.filter((permission) =>
		permission.permissions.includes('VIEW'),
	);
export const getCurrentUser = (state: State): User => ({
	displayName: state.domain.system.systemInfo.userName,
	key: state.domain.system.systemInfo.accountId,
	email: state.domain.system.systemInfo.userEmailAddress,
	avatarUrl: state.domain.system.systemInfo.avatarUrl,
});
export const isFetchPlanPermissionsInProgress = (state: State): boolean =>
	state.domain.permissions.isFetchPlanPermissionsInProgress;

export const getTeamPermissions = (state: State): TeamPermissions =>
	state.domain.permissions.teamPermissions;
