import React from 'react';
import { injectIntl } from '@atlassian/jira-intl';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip';
import NotInPlan from '../not-in-plan';
import messages from './messages';
import type { Props } from './types';

function Sprint(props: Props) {
	const { intl, sprint, sprints, externalSprintsById } = props;
	if (!sprint) {
		return <div>-</div>;
	}
	const targetSprint = sprints.find((currentSprint) => currentSprint.id === sprint);
	if (targetSprint) {
		const { title } = targetSprint;
		return (
			<EllipsedWithTooltip content={title} id={sprint}>
				<span>{title}</span>
			</EllipsedWithTooltip>
		);
	}

	const externalSprint = externalSprintsById[sprint];
	if (externalSprint) {
		const { title } = externalSprint;
		const externalSprintText = intl.formatMessage(messages.externalSprint);
		const text = `${title} (${externalSprintText})`;
		return (
			<EllipsedWithTooltip content={text} id={sprint}>
				<span>{text}</span>
			</EllipsedWithTooltip>
		);
	}

	return (
		<div>
			<NotInPlan />
		</div>
	);
}

export default injectIntl(Sprint);
