import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types';
import {
	ISSUE_WARNING_FILTER_ID,
	type IssueWarningFilter,
	type StringAttributeFilterValue,
	type FiltersState,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import type { WarningIssueHash } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/warnings/types';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { getFiltersViewSettings } from '../../view-settings';
import { getAllWarningsIssueHash } from '../../warnings';
import { applyFilter as applyAttributeFilter } from '../attribute-filter';

export const applyFilter = (
	issue: Issue,
	{ value, issueIdsWithWarnings }: IssueWarningFilter,
): boolean => {
	if (value.length === 0) {
		return true;
	}
	if (!issueIdsWithWarnings.length) {
		return false;
	}
	return applyAttributeFilter(issue, issueIdsWithWarnings, 'id');
};

export const getIssueWarningFilterValuePure = (
	filterSate: FiltersState,
): StringAttributeFilterValue => filterSate[ISSUE_WARNING_FILTER_ID]?.value ?? [];

const getIssueWarningFilterValue = createSelector(
	[getFiltersViewSettings],
	getIssueWarningFilterValuePure,
);

export const getIssueWarningFilterPure = (
	warningIssueHash: WarningIssueHash,
	value: StringAttributeFilterValue,
): IssueWarningFilter => {
	const issueIdsWithWarnings: string[] = [];
	const filteredWarnings = new Set<string>(value);

	for (const [issueId, warnings] of Object.entries(warningIssueHash)) {
		if (warnings.some((warning) => filteredWarnings.has(warning.type))) {
			issueIdsWithWarnings.push(issueId);
		}
	}

	return {
		id: ISSUE_WARNING_FILTER_ID,
		value,
		issueIdsWithWarnings,
	};
};

export const getIssueWarningFilter = createSelector(
	[getAllWarningsIssueHash, getIssueWarningFilterValue],
	getIssueWarningFilterPure,
);
