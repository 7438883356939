import {
	getSelected,
	getDescendantIdsByParent,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues';
import { getChildrenIdsByParent } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/issues-tree.tsx';
import { TABLE_ISSUE } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { getEnhancedIssues } from '../query';
import type { Issues, Item, TableIssueItem } from '../types';
import type { StateProps, OwnProps } from './types';

const lastSelected = (state: State) => state.ui.Main.Tabs.Roadmap.Scope.Issues.lastSelected;

const getIssueIdFromProps = (_: State, props: OwnProps): string => props.issueId;

const getIssues = createSelector([getEnhancedIssues], (enhancedIssues: Issues): TableIssueItem[] =>
	enhancedIssues.filter((item: Item): item is TableIssueItem => item.tag === TABLE_ISSUE),
);

const isSelected = (state: State, props: OwnProps) => !!getSelected(state).has(props.issueId);

export const hasChildSelectedPure = (
	descendantIds: {
		[key: string]: string[];
	},
	selected: Set<string>,
	issueId: string,
): boolean => !!(descendantIds[issueId] && descendantIds[issueId].some((id) => selected.has(id)));

export const getDescendentIdsByParent = (
	state: State,
	props: {
		issueId: string;
	},
) => getDescendantIdsByParent(state)[props.issueId] || [];

const hasChildSelected = createSelector(
	[getChildrenIdsByParent, getSelected, getIssueIdFromProps],
	hasChildSelectedPure,
);

const querySelector = createStructuredSelector<State, OwnProps, StateProps>({
	isSelected,
	hasChildSelected,
	lastSelected,
	descendantIds: getDescendentIdsByParent,
	issues: getIssues,
});

export default querySelector;
