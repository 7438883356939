import { ADD, type AddAction, RESET, type ResetAction, REMOVE, type RemoveAction } from './actions';
import type { IssueLabels } from './types';

const initialState: IssueLabels = [];

type Action = AddAction | ResetAction | RemoveAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: IssueLabels = initialState, action: Action): IssueLabels => {
	switch (action.type) {
		case ADD: {
			return [...state, action.payload].sort();
		}
		case RESET:
			return action.payload;
		case REMOVE: {
			return state.filter((label) => label !== action.payload).sort();
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
