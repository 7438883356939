import React, { useEffect } from 'react';
import type { Props } from './types';
import { useCollapsibleWidths } from './utils/hooks';
import { CollapseProvider, useActions } from './utils/state';

/** Provides the ability to toggle the columns. */
export const Collapsible = ({
	columnIds,
	resizer,
	defaultColumnWidths,
	onColumnWidthsChange,
	children,
}: Props) => {
	const widths = useCollapsibleWidths({ preview: true });

	return (
		<CollapseProvider
			singleton={false}
			widths={widths}
			defaultWidths={defaultColumnWidths}
			columnIds={columnIds}
			resizer={resizer}
			updateWidths={onColumnWidthsChange}
		>
			{children}
			<Observer />
		</CollapseProvider>
	);
};

/** Cache the pre-collapsed widths of the columns to expand after they are collapsed */
const Observer = () => {
	const widths = useCollapsibleWidths({ preview: false });
	const { cache } = useActions();

	useEffect(() => {
		cache(widths);
	}, [cache, widths]);

	return null;
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useColumnToggle, useColumnCollapsed } from './utils/hooks';
