import React, { type ComponentType } from 'react';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { token } from '@atlaskit/tokens';
import { AkFlag as Flag } from '@atlassian/jira-flags';
import { FormattedMessage } from '@atlassian/jira-intl';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import DescriptionDI from './description';
import messages from './messages';

export const getIssueSearchWarningFlag = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Description: ComponentType<Record<any, any>> = DescriptionDI,
) => (
	<Flag
		key="issue-search-warning"
		id="issue-search-warning"
		icon={<WarningIcon label="" primaryColor={token('color.icon.warning', colors.Y300)} />}
		title={<FormattedMessage {...messages.hiddenByFilterTitle} />}
		description={<Description />}
	/>
);
