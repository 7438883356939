import { Component } from 'react';
import { equals } from '../../reselect';

const EQUALS_DEPTH_LIMIT = 256;

// eslint-disable-next-line jira/react/no-class-components
export default class BaseComponent<Props, State> extends Component<Props, State> {
	shouldComponentUpdate(nextProps: Props, nextState: State) {
		return (
			!equals(this.props, nextProps, EQUALS_DEPTH_LIMIT) ||
			!equals(this.state, nextState, EQUALS_DEPTH_LIMIT)
		);
	}
}
