import { getHierarchyFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/hierarchy-filter';
import { getHierarchyLevels } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/hierarchy';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export default createSelector(
	[getHierarchyLevels, getHierarchyFilter],
	(hierarchy, { id, value: { start, end } }): StateProps => ({
		id,
		hierarchy,
		start,
		end,
	}),
);
