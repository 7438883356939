import { useCallback, useRef } from 'react';
import uuid from 'uuid';
import type { JiraSuggestedIssueErrorType } from '@atlassian/jira-relay/src/__generated__/issueBreakdownSubscription.graphql';
import {
	useAiWorkBreakDownAnalytics,
	useAiWorkBreakDownExperienceAnalytics,
} from '../../../common/analytics';
import { useTextFieldCharLengths } from '../../../common/analytics/utils';
import { useIssueBreakdown } from '../../../controllers/context';
import { useIssueBreakdownSubscription } from '../../../services/issue-breakdown';
import {
	subscriptionErrors,
	type OnNextStatusData,
	type OnNextSuggestionData,
	type SubscriptionError,
} from '../../../services/issue-breakdown/types';
import type { Props } from './types';

const KNOWN_ERRORS = [
	'Parent issue not found',
	'Project is not AI Enabled',
	'CloseEvent: ping timeout',
	'Event: error',
];

export const SubscribeToIssueBreakdown = ({
	issueTypeIds,
	numOfUrl,
	parentIssueDetails,
}: Props) => {
	const [
		{ channelId, headerPromptValue, acceptedChildIssues, rejectedChildIssues, status },
		{
			updateStreamingStatus,
			setStreamingError,
			setChannelId,
			addSuggestedIssue,
			getSuggestedLength,
		},
	] = useIssueBreakdown();
	const { fireTrack } = useAiWorkBreakDownAnalytics();
	const { sendSuccessEvent, sendFailedEvent } = useAiWorkBreakDownExperienceAnalytics(
		`${parentIssueDetails.projectKey}-${parentIssueDetails.issueKey}`,
	);
	const channelIdRef = useRef<string | null>();
	const textFieldCharLengths = useTextFieldCharLengths(`${parentIssueDetails.issueKey}`);

	const onNextStatus = useCallback(
		(data: OnNextStatusData) => {
			if (data.status === 'COMPLETE') {
				updateStreamingStatus(false);
				const { current: singleInstrumentationID } = channelIdRef;
				fireTrack('plansAiWorkBreakdownResult viewed', 'onStreamComplete', {
					singleInstrumentationID,
					suggestedNumber: getSuggestedLength(),
					numOfUrl,
				});
				sendSuccessEvent();
			} else {
				updateStreamingStatus(true, data.status);
				if (data.channelId) {
					setChannelId(data.channelId);
					channelIdRef.current = data.channelId;
				}
			}
		},
		[
			updateStreamingStatus,
			numOfUrl,
			fireTrack,
			getSuggestedLength,
			sendSuccessEvent,
			setChannelId,
		],
	);

	const onNextSuggestion = useCallback(
		({ summary, description, issueTypeId }: OnNextSuggestionData) => {
			const id = uuid();
			addSuggestedIssue({ id, summary, description, issueTypeId });
		},
		[addSuggestedIssue],
	);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const isNonReliabilityError = (error: any) =>
		Boolean(error) &&
		typeof error === 'object' &&
		(KNOWN_ERRORS.includes(error?.message) ||
			(typeof error.extensions === 'object' &&
				error.extensions.statusCode >= 400 &&
				error.extensions.statusCode < 500));

	const handleErrors = useCallback(
		(errorMessage: JiraSuggestedIssueErrorType | SubscriptionError, error?: Error) => {
			fireTrack('plansAiWorkBreakdownResult error', 'aiIssueBreakdownChildIssueError', {
				errorMessage,
				textFieldCharLengths,
				numOfUrl,
				singleInstrumentationID: channelIdRef.current,
				...(errorMessage === subscriptionErrors.network ? { originErr: error?.message } : {}),
				// We want to understand which status causes the most timeout
				// Note: the timeoutStatus will only available in timeout error
				...(errorMessage === subscriptionErrors.timeout ? { timeoutStatus: status } : {}),
			});
			setStreamingError(errorMessage);
			updateStreamingStatus(false);

			switch (errorMessage) {
				// Valid errors
				case subscriptionErrors.notEnoughInformation:
				case subscriptionErrors.noFurtherSuggestions:
				case subscriptionErrors.unethicalContent:
					sendSuccessEvent(errorMessage);
					break;
				// Unexpected errors
				case subscriptionErrors.communicationsFailures:
				default:
					if (isNonReliabilityError(error)) {
						break;
					}
					// Use Unclassified as a default error
					sendFailedEvent(errorMessage ?? subscriptionErrors.unclassified);
			}
		},
		[
			fireTrack,
			numOfUrl,
			status,
			setStreamingError,
			updateStreamingStatus,
			sendSuccessEvent,
			sendFailedEvent,
			textFieldCharLengths,
		],
	);

	const onSuggestionError = useCallback(
		(error: JiraSuggestedIssueErrorType | SubscriptionError | null | undefined) => {
			const errorMessage = error ?? subscriptionErrors.unclassified;
			handleErrors(errorMessage);
		},
		[handleErrors],
	);

	const onError = useCallback(
		(error: Error) => {
			const errorMessage = subscriptionErrors.network;
			handleErrors(errorMessage, error);
		},
		[handleErrors],
	);

	const onTimeout = useCallback(() => {
		const errorMessage = subscriptionErrors.timeout;
		handleErrors(errorMessage);
	}, [handleErrors]);

	const query = {
		channelId,
		additionalContext: headerPromptValue,
		acceptedChildIssues,
		rejectedChildIssues,
		issueTypeIds,
		issueId: parentIssueDetails.id,
	};
	const params = { query, onNextStatus, onNextSuggestion, onSuggestionError, onError, onTimeout };
	useIssueBreakdownSubscription(params);
	return null;
};
