import { UPDATE_ISSUE_REPORTER_MAP, type UpdateIssueReporterMapAction } from './actions';
import type { State } from './types';

const initialState: State = {
	issueReporterMap: {},
	reporterList: [],
};

type Action = UpdateIssueReporterMapAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = initialState, action: Action) => {
	switch (action.type) {
		case UPDATE_ISSUE_REPORTER_MAP:
			return {
				...state,
				issueReporterMap: {
					...state.issueReporterMap,
					...action.payload,
				},
			};
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
