import {
	isEditMode,
	isExportMode,
	isOptimizedMode,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app';
import { getExternalIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/external-issues';
import { getFilters } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters';
import {
	getIssueCountByLinkType,
	getDependencyIssueLinkTypes,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-links';
import { getIssueStatusById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-statuses';
import { getSyncStartEnabled } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan';
import { getAllIssuesWithOriginalAndOptimizedById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope';
import { DEPENDENCIES_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default createStructuredSelector<any, any, any>({
	externalIssues: getExternalIssues,
	issueStatusById: getIssueStatusById,
	isEditMode,
	isExportMode,
	isOptimizedMode,
	issuesWithOptimizedById: getAllIssuesWithOriginalAndOptimizedById,
	filterValue: (state) => getFilters(state)[DEPENDENCIES_FILTER_ID].value,
	syncStartEnabled: getSyncStartEnabled,
	issueLinkTypes: getDependencyIssueLinkTypes,
	issueCountByLinkType: getIssueCountByLinkType,
});
