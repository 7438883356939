import { useCallback, useRef } from 'react';
import issueBarDragEmitter from '@atlassian/jira-portfolio-3-portfolio/src/common/issue-bar-drag-events';

export const useScheduler = <TArgs,>(
	onFrame: (timeSinceLastFrame: number, args: TArgs) => void,
) => {
	const lastFrameTimestampRef = useRef<number>();
	const latestArgs = useRef<TArgs>();
	const frameIdRef = useRef<number>();

	const loop = useCallback(() => {
		const now = Date.now();

		if (latestArgs.current !== undefined && lastFrameTimestampRef.current !== undefined) {
			onFrame(now - lastFrameTimestampRef.current, latestArgs.current);
		}
		lastFrameTimestampRef.current = Date.now();
		frameIdRef.current = requestAnimationFrame(loop);
	}, [onFrame]);

	const start = useCallback(() => {
		lastFrameTimestampRef.current = Date.now();
		frameIdRef.current = requestAnimationFrame(loop);
	}, [loop]);

	const update = useCallback((args: TArgs) => {
		latestArgs.current = args;
	}, []);

	const end = useCallback(() => {
		lastFrameTimestampRef.current = undefined;

		if (frameIdRef.current !== undefined) {
			cancelAnimationFrame(frameIdRef.current);
		}

		frameIdRef.current = undefined;
		latestArgs.current = undefined;
	}, []);

	return { start, update, end };
};

export const monitorForIssueBarUpdate = ({
	onDragStart,
	onDrag,
	onDragEnd,
}: {
	onDragStart: () => void;
	onDrag: (arg0: { pageX: number; pageY: number }) => void;
	onDragEnd: () => void;
}) => {
	/** Set pageY params to be NaN to prevent the auto-scroll vertically. */
	const stripPageY =
		(callback: (arg0: { pageX: number; pageY: number }) => void): typeof callback =>
		({ pageX }) =>
			callback({ pageX, pageY: NaN });

	const handleDragStart = stripPageY(onDragStart);
	const handleDrag = stripPageY(onDrag);
	const handleDragEnd = stripPageY(onDragEnd);

	/* eslint-disable @atlaskit/design-system/no-direct-use-of-web-platform-drag-and-drop */
	issueBarDragEmitter.addEventListener('dragstart', handleDragStart);
	issueBarDragEmitter.addEventListener('drag', handleDrag);
	issueBarDragEmitter.addEventListener('dragend', handleDragEnd);

	return () => {
		issueBarDragEmitter.removeEventListener('dragstart', handleDragStart);
		issueBarDragEmitter.removeEventListener('drag', handleDrag);
		issueBarDragEmitter.removeEventListener('dragend', handleDragEnd);
	};
	/* eslint-enable @atlaskit/design-system/no-direct-use-of-web-platform-drag-and-drop */
};
