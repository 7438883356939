import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Spotlights } from '@atlassian/jira-portfolio-3-onboarding/src/controllers/spotlights';
import { SpotlightTarget } from '@atlassian/jira-portfolio-3-onboarding/src/ui/spotlight-target';
import {
	OPTIMIZED,
	REPORT,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import ViewSettings from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/view-settings';
import FilterBar from '../../filter-bar';
import UpdateJira from '../../title-bar/update-jira';
import Warnings from '../../title-bar/warnings';
import ViewBar from '../../view-bar';
import Search from '../search';
import ViewModeSwitcher from '../view-mode-switcher';
import type { Props } from './types';

export const Toolbar = ({ mode, headerMode = 'visible' }: Props) => {
	return (
		<RowContainer>
			<LeftAlignment>
				<Search />
				<SpotlightTarget name={Spotlights.ShowAndTell}>
					<LeftAlignment>
						<FilterBar />
						{/* @ts-expect-error - TS2739 - Type '{ isReadOnly: boolean; }' is missing the following properties from type 'OwnProps': isMultiScenarioEnabled, planScenarios */}
						{mode !== OPTIMIZED && mode !== REPORT && <ViewBar />}
					</LeftAlignment>
				</SpotlightTarget>
			</LeftAlignment>
			<RightAlignment>
				{mode !== OPTIMIZED && (
					<>
						<Warnings />
						<SpotlightTarget name={Spotlights.ListMode}>
							<ViewModeSwitcher />
						</SpotlightTarget>
						<SpotlightTarget name={Spotlights.FindYourLook}>
							<ViewSettings />
						</SpotlightTarget>
						{headerMode === 'toolbar' && <UpdateJira />}
					</>
				)}
			</RightAlignment>
		</RowContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RowContainer = styled.div({
	display: 'flex',
	gap: token('space.100', '8px'),
	justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RightAlignment = styled.div({
	display: 'flex',
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LeftAlignment = styled.div({
	alignItems: 'center',
	display: 'flex',
	gap: token('space.100', '8px'),
	justifyContent: 'left',
});
