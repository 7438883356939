import React, { Component } from 'react';
import { Field } from '@atlaskit/form';
import type {
	InputActionMeta,
	ValueType,
} from '@atlassian/jira-portfolio-3-common/src/select/types';
import {
	sort,
	transformPersons,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/assignees/utils';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import Select from '@atlassian/jira-portfolio-3-portfolio/src/common/view/select/view.tsx';
import { formatOptionLabel } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/user-picker';

// eslint-disable-next-line jira/react/no-class-components, @typescript-eslint/no-explicit-any
class AssigneeSelectField extends Component<any> {
	componentDidMount() {
		const { fetchAssigneeList } = this.props;
		fetchAssigneeList({
			query: '',
		});
	}

	onUserChange = (value: ValueType) => {
		const { onChange, id } = this.props;
		// @ts-expect-error - TS2551 - Property 'value' does not exist on type 'OptionsType'
		const update = (isDefined(value) && value.value) || value;
		onChange(id, update);
	};

	onInputChange = (currentFilter: string, { action }: InputActionMeta) => {
		if (action === 'input-change') {
			const { fetchAssigneeList } = this.props;
			fetchAssigneeList({ query: currentFilter });
		}
	};

	render() {
		const {
			assigneeListFetchStatus,
			assignees,
			autofocus,
			defaultValue,
			id,
			label,
			name,
			placeholder,
			required,
			value,
		} = this.props;

		const options = transformPersons(sort(assignees));

		const selectedValue = options.find(({ value: optValue }) => optValue === value);
		const defaultSelectedValue = options.find(({ value: optValue }) => optValue === defaultValue);

		return (
			<Field isRequired={required} key={`${id}-field`} label={label} name={name}>
				{({ fieldProps }) => (
					<Select
						{...fieldProps}
						autoFocus={autofocus}
						defaultValue={defaultSelectedValue}
						// @ts-expect-error TS2322 trouble casting from the general OptionType to the UserOption,
						// we need to update OptionType to be the atlaskit type first I think
						formatOptionLabel={formatOptionLabel}
						isLoading={assigneeListFetchStatus}
						isTransparentBackground={false}
						key={id}
						menuPlacement="auto"
						onChange={this.onUserChange}
						onInputChange={this.onInputChange}
						options={options}
						placeholder={placeholder}
						value={selectedValue}
					/>
				)}
			</Field>
		);
	}
}

export default AssigneeSelectField;
