import { getFilters } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters';
import { getIssueLabels } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-labels';
import { LABEL_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { StateProps } from './types';

const mapStateToProps: MapStateToProps<StateProps> = (state) => {
	const labels = getIssueLabels(state);
	const {
		[LABEL_FILTER_ID]: { id, value },
	} = getFilters(state);

	return {
		id,
		value,
		labels,
	};
};

export default mapStateToProps;
