import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';

export const getRequestStatus = (state: State) =>
	state.ui.Main.Tabs.Teams.TeamsList.isProcessingRequest;

const mapStateToProps = (state: State) => ({
	isProcessingRequest: getRequestStatus(state),
});

export default mapStateToProps;
