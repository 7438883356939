import React, { type ComponentType } from 'react';
import { lazy } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { SmartUserPickerProps } from './index';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AsyncUserPicker = lazy<ComponentType<SmartUserPickerProps>>(
	() => import(/* webpackChunkName: "async-advanced-roadmaps-user-picker" */ './index'),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: SmartUserPickerProps) => (
	<Placeholder name="advanced-roadmaps-user-picker" fallback={null}>
		<AsyncUserPicker {...props} />
	</Placeholder>
);
