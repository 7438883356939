import type { Dispatch } from 'redux';
import { changeDisplay } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/dependency-settings/actions';
import type { Action, DispatchProps } from './types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (dispatch: Dispatch<Action>): DispatchProps => ({
	changeDisplay: (payload) => {
		dispatch(changeDisplay(payload));
	},
});
