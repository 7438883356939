import compose from 'lodash/fp/compose';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { connect } from '@atlassian/jira-react-redux';
import mapDispatchToProps from './command';
import mapStateToProps from './query';
import type { StateProps, DispatchProps, OwnProps } from './types';
import ChangesTable, { withScrollTracking } from './view';

export default compose(
	withScrollTracking,
	// @ts-expect-error - TS2589 - Type instantiation is excessively deep and possibly infinite.
	connect<StateProps, DispatchProps, OwnProps, State>(mapStateToProps, mapDispatchToProps),
)(ChangesTable);
