import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types';
import {
	type FiltersState,
	type StringAttributeFilterValue,
	type StatusFilter,
	STATUS_FILTER_ID,
	STATUS_KEY_FILTER_ID,
	type StatusKeyFilterValue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import type { IssueStatus } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { CHECKBOX_STATES } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import { getCategoryNameById } from '../../issue-status-categories';
import { getIssueStatusById, getIssueStatusByKey, getIssueStatuses } from '../../issue-statuses';
import type { IssueStatusByKey } from '../../issue-statuses/types.tsx';
import { getFiltersViewSettings } from '../../view-settings';
import { applyFilter as applyAttributeFilter } from '../attribute-filter';
import {
	getIsAllStatusKeyOfCategorySelectedPure,
	getStatusKeyFilterOptionsByCategoryPure,
	getStatusKeyFilterPure,
	getStatusKeyFilterValuePure,
} from './pure';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { STATUS_KEY_FILTER_ID } from '../../../state/domain/view-settings/filters/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { STATUS_FILTER_ID } from '../../../state/domain/view-settings/filters/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { UNSAVED_STATUS } from './pure';

export const applyFilter = (
	issue: Issue,
	{ value }: StatusFilter,
	optimizedMode: boolean,
): boolean => applyAttributeFilter(issue, value, 'status', optimizedMode);

const getOldStatusFilterValue = createSelector<State, FiltersState, StringAttributeFilterValue>(
	[getFiltersViewSettings],
	(filters: FiltersState) => {
		const statusFilters = filters[STATUS_FILTER_ID];
		return statusFilters?.value ?? [];
	},
);

const getNewStatusKeyFilterValue = createSelector<State, FiltersState, StatusKeyFilterValue>(
	[getFiltersViewSettings],
	(filters: FiltersState) => {
		const statusKeyFilters = filters[STATUS_KEY_FILTER_ID];
		return statusKeyFilters?.value ?? [];
	},
);

/**
 * Returns the new status key filter values, otherwise falls back to the old status filter values (converted to keys).
 */
export const getStatusKeyFilterValue = createSelector<
	State,
	Record<string, IssueStatus>,
	StringAttributeFilterValue,
	StatusKeyFilterValue,
	StatusKeyFilterValue
>(
	[getIssueStatusById, getOldStatusFilterValue, getNewStatusKeyFilterValue],
	getStatusKeyFilterValuePure,
);

/**
 * Returns Map of all possible status key options selectable from the status filter dropdown.
 * Status keys are grouped by category to make it easier to consume by the view.
 * The Map is ordered by a custom category ordering.
 */
export const getStatusKeyFilterOptionsByCategory = createSelector<
	State,
	IssueStatus[],
	Map<string, string>,
	Map<string, string[]>
>([getIssueStatuses, getCategoryNameById], getStatusKeyFilterOptionsByCategoryPure);

/**
 * Returns mapping of each category to whether every status key from said category is selected.
 * For example, if every status from category 4 (In Progress) is selected, then category 4 will map to true.
 */
export const getAllStatusKeyOfCategoryChecked = createSelector<
	State,
	StatusKeyFilterValue,
	Map<string, string[]>,
	Map<string, string>,
	{ [key: string]: (typeof CHECKBOX_STATES)[keyof typeof CHECKBOX_STATES] }
>(
	[getStatusKeyFilterValue, getStatusKeyFilterOptionsByCategory, getCategoryNameById],
	getIsAllStatusKeyOfCategorySelectedPure,
);

/**
 * Returns a STATUS filter, with value being list of issue status IDs (rather than keys).
 * This is because the apply filter logic matches the filter value against hydrated issue objects,
 * which only contains the status id, but not the key.
 */
export const getStatusKeyFilter = createSelector<
	State,
	Record<string, IssueStatus>,
	IssueStatusByKey,
	StringAttributeFilterValue,
	StatusKeyFilterValue,
	StatusFilter
>(
	[getIssueStatusById, getIssueStatusByKey, getOldStatusFilterValue, getNewStatusKeyFilterValue],
	getStatusKeyFilterPure,
);
