import React from 'react';
import { lazy } from 'react-loosely-lazy';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type { KanbanFlyoutProps, ScrumFlyoutProps } from './types';

const lazyFlyout = () =>
	import(/* webpackChunkName: "async-advanced-roadmaps-sprint-flyout" */ './index');

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyScrumFlyout = lazy(() => lazyFlyout().then(({ ScrumFlyout }) => ScrumFlyout), {
	ssr: false,
});

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyKanbanFlyout = lazy(() => lazyFlyout().then(({ KanbanFlyout }) => KanbanFlyout), {
	ssr: false,
});

export const AsyncScrumFlyout = (props: ScrumFlyoutProps) => (
	<Placeholder name="scrum-flyout" fallback={<Spinner />}>
		<LazyScrumFlyout {...props} />
	</Placeholder>
);

export const AsyncKanbanFlyout = (props: KanbanFlyoutProps) => (
	<Placeholder name="kanban-flyout" fallback={<Spinner />}>
		<LazyKanbanFlyout {...props} />
	</Placeholder>
);

export const preloadFlyouts = () => {
	LazyScrumFlyout.preload();
	LazyKanbanFlyout.preload();
};
