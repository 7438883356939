import { getIssueLabels } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-labels';
import { getColorByViewSettings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings';
import {
	addLabelColour,
	updateLabelColour,
	removeLabelColour,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/colour-by/actions.tsx';
import type {
	MapDispatchToPropsSimple,
	MapStateToProps,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import { connect } from '@atlassian/jira-react-redux';
import type { StateProps, DispatchProps } from './types';
import View from './view';

const mapStateToProps: MapStateToProps<StateProps> = (state) => ({
	colourByConfiguration: getColorByViewSettings(state),
	issueLabels: getIssueLabels(state),
});

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	addLabelColour: () => {
		dispatch(addLabelColour());
	},
	updateLabelColour: (payload) => {
		dispatch(updateLabelColour(payload));
	},
	removeLabelColour: (payload) => {
		dispatch(removeLabelColour(payload));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
