import { updateIssueOrInlineCreate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue';
import type { MapDispatchToProps } from './types';

const dispatchMethods: MapDispatchToProps = (dispatch) => ({
	onChange: (issue, fixVersions) => {
		dispatch(
			updateIssueOrInlineCreate({
				id: issue.id,
				fixVersions,
			}),
		);
	},
});

export default dispatchMethods;
