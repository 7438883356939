import type { IssueTypeWithLevel } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/hierarchy/types';
import type { IssueType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-types/types.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types';
import {
	type IssueTypesFilter,
	type FiltersState,
	type IssueTypeKeyFilterValue,
	type NumberAttributeFilterValue,
	ISSUE_TYPE_KEY_FILTER_ID,
	ISSUE_TYPES_FILTER_ID,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { getIssueTypesByLevel, getIssueTypeWithLevelById } from '../../hierarchy';
import { getFiltersViewSettings } from '../../view-settings';
import { applyFilter as applyAttributeFilter } from '../attribute-filter';
import { getIssueTypeKeyValuesPure, getIssueTypeKeyFilterPure } from './pure';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { ISSUE_TYPE_KEY_FILTER_ID };

export const applyFilter = (
	issue: Issue,
	{ value }: IssueTypesFilter,
	optimizedMode: boolean,
): boolean => applyAttributeFilter(issue, value, 'type', optimizedMode);

const getNewIssueTypeFilterValues = createSelector<State, FiltersState, IssueTypeKeyFilterValue>(
	[getFiltersViewSettings],
	({ [ISSUE_TYPE_KEY_FILTER_ID]: filter }) => filter?.value ?? [],
);

const getOldIssueTypeFilterValues = createSelector<State, FiltersState, NumberAttributeFilterValue>(
	[getFiltersViewSettings],
	({ [ISSUE_TYPES_FILTER_ID]: filter }) => filter?.value ?? [],
);

export const getIssueTypeKeyFilterValue = createSelector<
	State,
	NumberAttributeFilterValue,
	IssueTypeKeyFilterValue,
	{ [key: number]: IssueTypeWithLevel },
	IssueTypeKeyFilterValue
>(
	[getOldIssueTypeFilterValues, getNewIssueTypeFilterValues, getIssueTypeWithLevelById],
	getIssueTypeKeyValuesPure,
);

/**
 * Returns a ISSUE_TYPE filter, with value being list of issue type IDs (rather than keys).
 * This is because the apply filter logic matches the filter value against hydrated issue objects,
 * which only contains the issue type id, but not the key.
 */
export const getIssueTypeKeyFilter = createSelector<
	State,
	IssueTypeKeyFilterValue,
	{ [key: number]: IssueType[] },
	IssueTypesFilter
>([getIssueTypeKeyFilterValue, getIssueTypesByLevel], getIssueTypeKeyFilterPure);
