/* eslint-disable jira/react/no-style-attribute */
import React from 'react';
import { Box } from '@atlaskit/primitives';
import { useRowConfig } from '../../controllers/grid';
import { RowProvider } from '../../controllers/row';
import type { Props } from './types';

export const Row = ({ index, height, style, ...restProps }: Props) => {
	const [rowConfig] = useRowConfig(index);
	const finalHeight = height ?? rowConfig?.height ?? 0;

	return (
		<RowProvider row={index} height={finalHeight}>
			<Box
				as="tr"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={{ height: finalHeight, ...style }}
				// This spreading is to allow the consumer to customize the tr attributes
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...restProps}
			/>
		</RowProvider>
	);
};
