import { updateIssueOrInlineCreate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue';
import { convertEstimate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/estimation';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import { PlanningUnits } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import type { DispatchProps } from './types';

const dispatchMethods: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	updateEstimate: (
		estimate: number | null | undefined,
		planningUnit: string,
		issueId: string,
		workingHours: number,
	) => {
		const propName = planningUnit === PlanningUnits.storyPoints ? 'storyPoints' : 'timeEstimate';
		dispatch(
			updateIssueOrInlineCreate({
				id: issueId,
				[propName]: estimate && convertEstimate(planningUnit, estimate, workingHours),
			}),
		);
	},
});

export default dispatchMethods;
