import { getHierarchyFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/hierarchy-filter';
import { getIssueTypesByProjectId } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export default createStructuredSelector<State, StateProps>({
	issueTypesByProjectId: getIssueTypesByProjectId,
	hierarchyRangeFilter: getHierarchyFilter,
});
