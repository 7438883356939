import { initialState } from '@atlassian/jira-portfolio-3-associated-issues/src/controllers/index.tsx';
import type { State as AssociatedIssueState } from '@atlassian/jira-portfolio-3-associated-issues/src/controllers/types.tsx';
import { SET_ASSOCIATED_ISSUES, type SetAssociatedIssuesAction } from './actions';

type Action = SetAssociatedIssuesAction;

export const associatedIssuesDomainReducer = (
	state: AssociatedIssueState = initialState,
	action: Action,
): AssociatedIssueState => {
	switch (action.type) {
		case SET_ASSOCIATED_ISSUES:
			return {
				...state,
				...action.payload,
			};
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
