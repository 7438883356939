import React from 'react';
import * as R from 'ramda';
import { useIntl } from '@atlassian/jira-intl';
import { VIEW_MODES } from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import type { OptionType } from '@atlassian/jira-portfolio-3-common/src/select/types';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types';
import { isColorByCustomFieldOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations';
import type { ColourByOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/colour-by/types';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import {
	colourByOptions,
	priorityColourMap,
	statusColourMap,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colours';
import { KEYS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/interaction-constants';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import traceUFOPress from '@atlassian/react-ufo/trace-press';
import ViewSettingSelect from '../view-setting-select';
import messages from './messages';
import type { Props } from './types';
import { colorByLabelsMap } from './utils';

function ColourBy({
	colourByValue,
	colourMaps,
	colourPriorities,
	filterableCustomFields,
	isDefaultMenuOpen = false,
	issuePrioritiesIdList,
	onMenuToggle,
	setColourByValue,
	setColourMap,
	setPriorityColourMap,
	viewMode = VIEW_MODES.TIMELINE,
}: Props) {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const createDefaultMap = (id: ColourByOption) => {
		if (id === colourByOptions.STATUS) {
			return statusColourMap;
		}
		// Maps for project and team are created on page load
		return {};
	};

	/**
	 * This function creates a preset of colours when the user colour the plan by priority
	 * It takes the priorities provided by the issuePrioritiesIdList, then the colours included in
	 * the priorityColourMap (3, at the time of writing these lines), and allocates priorities to these colours
	 */
	const createPriorityColourMap = () => {
		if (
			isDefined(issuePrioritiesIdList) &&
			issuePrioritiesIdList.length >= priorityColourMap.length
		) {
			const [floorChunkSize, ceilChunkSize] = R.juxt([Math.floor, Math.ceil])(
				issuePrioritiesIdList.length / priorityColourMap.length,
			);
			// we use different chunk sizes based on the amount of priorities e.g.
			// if there are 3 (3 % 3 = 0) priorities: [1, 1, 1]
			// if there are 4 (4 % 3 = 1) priorities: [1, 2, 1]
			// if there are 5 (5 % 3 = 2) priorities: [2, 1, 2]
			// if there are 6 (6 % 3 = 0) priorities: [2, 2, 2]
			// and so on...
			const chunks =
				issuePrioritiesIdList.length % priorityColourMap.length === 1
					? [floorChunkSize, ceilChunkSize, floorChunkSize]
					: [ceilChunkSize, floorChunkSize, ceilChunkSize];

			setPriorityColourMap({
				chunks,
				issuePrioritiesIdList,
			});
		}
	};

	const changeColourBy = ({ value }: OptionType) => {
		traceUFOPress(KEYS.CHANGE_COLOR_BY_SETTING);

		setColourByValue({ id: value });

		const [actionSubject, action, actionSubjectId] =
			PRODUCT_ANALYTICS_EVENT_NAMES.COLOR_BY_CLICKED.split(' ');
		fireUIAnalytics(createAnalyticsEvent({ actionSubject, action }), actionSubjectId, {
			newPlanSettingValue: value,
		});

		if (!isColorByCustomFieldOption(value)) {
			if (value === colourByOptions.PRIORITY && R.isEmpty(colourPriorities)) {
				// when Color by > Priority is selected and there are no options picked for any colours, we provide
				// a preset of colours and options picked for these colours to the user
				createPriorityColourMap();
			} else if (colourMaps[value] && Object.keys(colourMaps[value]).length === 0) {
				const map = createDefaultMap(value);
				setColourMap({ id: value, map });
			}
		}
	};

	const getColourByOptionsFromStandardFields = () =>
		Object.keys(colorByLabelsMap).map((key) => ({
			id: key.toString(),
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			content: formatMessage(colorByLabelsMap[key as keyof typeof colorByLabelsMap]),
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			label: formatMessage(colorByLabelsMap[key as keyof typeof colorByLabelsMap]),
			value: key.toString(),
		}));
	const getColourByOptionsFromCustomFields = () =>
		filterableCustomFields.map(({ id, title }) => ({
			id: id.toString(),
			content: title,
			label: title,
			value: id.toString(),
		}));

	const standardFieldsOptions = getColourByOptionsFromStandardFields();
	const customFieldsOptions = getColourByOptionsFromCustomFields();

	return (
		<ViewSettingSelect
			classNamePrefix="portfolio-3-portfolio_app-simple-plans_main_tabs_roadmap_view-settings_colour-by"
			customFieldsOptions={customFieldsOptions}
			handleOnChange={changeColourBy}
			isDefaultMenuOpen={isDefaultMenuOpen}
			isDisabled={viewMode === VIEW_MODES.LIST}
			onMenuToggle={onMenuToggle}
			selectedFieldId={colourByValue}
			standardFieldsOptions={standardFieldsOptions}
			label={formatMessage(messages.colourByLabel)}
		/>
	);
}

export default ColourBy;
