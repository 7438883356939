import React, { type FC, type ReactNode } from 'react';
import { Text } from '@atlaskit/primitives';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { useIntl } from '@atlassian/jira-intl';
import type { SubscriptionError } from '../../../services/issue-breakdown/types';
import messages from './messages';
import { getSectionMessageContent } from './utils';

type Props = {
	error: SubscriptionError | null | undefined;
	isRefined?: boolean;
	onRetry(): void;
};

const StatusPage = (chunks: ReactNode) => (
	<a href="https://status.atlassian.com/" target="_blank">
		{chunks}
	</a>
);

export const SectionErrorMessage: FC<Props> = ({ error, isRefined, onRetry }) => {
	const { formatMessage } = useIntl();
	const { appearance, headerMessage, bodyMessage, hasRetry } = getSectionMessageContent(
		error,
		isRefined,
	);

	const title = headerMessage ? formatMessage(headerMessage) : undefined;
	const actions = hasRetry ? (
		<SectionMessageAction onClick={onRetry}>
			{formatMessage(messages.retryButton)}
		</SectionMessageAction>
	) : undefined;

	return (
		<SectionMessage
			testId="portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.section-error-message.section-message"
			appearance={appearance}
			title={title}
			actions={actions}
		>
			<Text>{formatMessage(bodyMessage, { a: StatusPage })}</Text>
		</SectionMessage>
	);
};
