import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type { OptionType } from '@atlassian/jira-portfolio-3-common/src/select/types';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types';
import {
	GROUPING,
	type Grouping,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import {
	getGroupingCustomFields,
	standardFieldsGroupings,
	getGroupByStandardFieldLabel,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/grouping';
import { KEYS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/interaction-constants';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import traceUFOPress from '@atlassian/react-ufo/trace-press';
import ViewSettingSelect from '../view-setting-select';
import messages from './messages';
import type { Props } from './types';

export default function GroupBy({
	changeGroup,
	customFields,
	grouping,
	isDefaultMenuOpen = false,
	onMenuToggle,
	isAtlasConnectInstalled,
}: Props) {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const changeGroupBy = ({ type, value }: OptionType) => {
		traceUFOPress(KEYS.CHANGE_GROUP_BY_SETTING);

		changeGroup(value);

		const [actionSubject, action, actionSubjectId] =
			PRODUCT_ANALYTICS_EVENT_NAMES.GROUP_BY_CLICKED.split(' ');
		fireUIAnalytics(createAnalyticsEvent({ actionSubject, action }), actionSubjectId, {
			newPlanSettingValue: type,
		});
	};

	const standardFieldsOptions = Object.keys(standardFieldsGroupings(isAtlasConnectInstalled)).map(
		(key: Grouping | string) => ({
			content: formatMessage(getGroupByStandardFieldLabel(key)),
			id: key,
			label: formatMessage(getGroupByStandardFieldLabel(key)),
			type: key,
			value: key,
		}),
	);

	const customFieldsOptions =
		Object.entries(getGroupingCustomFields(customFields)).map(([key, { title, type }]) => ({
			content: title,
			id: key,
			label: title,
			type,
			value: key,
		})) ?? [];

	if (!isAtlasConnectInstalled && grouping === GROUPING.GOALS) {
		changeGroupBy({ type: 'standard', value: GROUPING.NONE });
	}

	return (
		<ViewSettingSelect
			classNamePrefix="portfolio-3-portfolio_app-simple-plans_main_tabs_roadmap_view-settings_group-by"
			customFieldsOptions={customFieldsOptions}
			handleOnChange={changeGroupBy}
			isDefaultMenuOpen={isDefaultMenuOpen}
			onMenuToggle={onMenuToggle}
			standardFieldsOptions={standardFieldsOptions}
			selectedFieldId={grouping}
			label={formatMessage(messages.groupByLabel)}
		/>
	);
}
