import type { IssuePriorities } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-priorities/types.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types';
import {
	type IssuePrioritiesFilter,
	ISSUE_PRIORITIES_FILTER_ID,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { getIssuePriorities } from '../../issue-priorities';
import { getFiltersViewSettings } from '../../view-settings';
import { applyFilter as applyAttributeFilter } from '../attribute-filter';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { ISSUE_PRIORITIES_FILTER_ID } from '../../../state/domain/view-settings/filters/types';

export const applyFilter = (
	issue: Issue,
	filter: IssuePrioritiesFilter,
	optimizedMode: boolean,
): boolean => applyAttributeFilter(issue, filter.value, 'priority', optimizedMode);

export const getIssuePriorityFilterValue = (state: State): IssuePrioritiesFilter =>
	getFiltersViewSettings(state)[ISSUE_PRIORITIES_FILTER_ID] || {
		id: ISSUE_PRIORITIES_FILTER_ID,
		value: [],
	};

export const getIssuePrioritiesFilter = createSelector(
	[getIssuePriorities, getIssuePriorityFilterValue],
	(issuePriorities: IssuePriorities, filter: IssuePrioritiesFilter): IssuePrioritiesFilter => {
		const { value = [] } = filter;
		const filteredIssuePriorities = value.filter((issuePriorityId: string) =>
			Object.values(issuePriorities).some(({ id }) => id === issuePriorityId),
		);
		return {
			id: ISSUE_PRIORITIES_FILTER_ID,
			value: filteredIssuePriorities,
		};
	},
);
