import type { Relation } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import type { RankActionPayload } from '../versions/actions';
import type { Adjustments } from './types';

export const RESET_ADJUSTMENTS = 'state.domain.lexorank.RESET_ADJUSTMENTS' as const;
export const ADJUST_ISSUE_LEXORANK = 'state.domain.lexorank.ADJUST_ISSUE_LEXORANK' as const;
export const BULK_ADJUST_ISSUE_LEXORANK =
	'state.domain.lexorank.BULK_ADJUST_ISSUE_LEXORANK' as const;
export const ADJUST_VERSION_LEXORANK = 'state.domain.lexorank.ADJUST_VERSION_LEXORANK' as const;
export const UPDATE_VERSION_LEXORANK_ADJUSTMENTS =
	'state.domain.lexorank.UPDATE_VERSION_LEXORANK_ADJUSTMENTS' as const;

export type ResetAdjustmentsActionPayload = Adjustments;

export type AdjustLexoRankActionPayload = {
	anchor?: string;
	itemKeys: string[];
	relation: Relation;
	dragAndDrop?: boolean;
};

export type UpdateVersionLexorankAdjustmentsActionPayload = {
	[key: string]: string;
};

export type ResetAdjustmentsAction = {
	type: typeof RESET_ADJUSTMENTS;
	payload: ResetAdjustmentsActionPayload;
};

export type AdjustIssueLexoRankAction = {
	type: typeof ADJUST_ISSUE_LEXORANK;
	payload: AdjustLexoRankActionPayload;
};

export type BulkAdjustIssueLexoRankAction = {
	type: typeof BULK_ADJUST_ISSUE_LEXORANK;
	payload: AdjustLexoRankActionPayload;
};

export type AdjustVersionLexoRankAction = {
	type: typeof ADJUST_VERSION_LEXORANK;
	payload: RankActionPayload;
};

export type UpdateVersionLexorankAdjustmentsAction = {
	type: typeof UPDATE_VERSION_LEXORANK_ADJUSTMENTS;
	payload: UpdateVersionLexorankAdjustmentsActionPayload;
};

export const resetAdjustments = (payload: ResetAdjustmentsActionPayload) => ({
	type: RESET_ADJUSTMENTS,
	payload,
});

export const adjustLexoRank = (payload: AdjustLexoRankActionPayload) => ({
	type: ADJUST_ISSUE_LEXORANK,
	payload,
});

export const bulkAdjustLexoRank = (payload: AdjustLexoRankActionPayload) => ({
	type: BULK_ADJUST_ISSUE_LEXORANK,
	payload,
});

export const adjustVersionLexoRank = (payload: RankActionPayload) => ({
	type: ADJUST_VERSION_LEXORANK,
	payload,
});

export const updateVersionLexorankAdjustments = (
	payload: UpdateVersionLexorankAdjustmentsActionPayload,
) => ({
	type: UPDATE_VERSION_LEXORANK_ADJUSTMENTS,
	payload,
});
