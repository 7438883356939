"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createStructuredSelector = exports.createSelector = exports.createSelectorCreator = exports.windowKey = exports.defaultMemoize = void 0;
// TODO: Should the reselect version in package.json be a wider range to include 4.x
const reselect_1 = require("reselect");
// Re-export functions that don't need updating
var reselect_2 = require("reselect");
Object.defineProperty(exports, "defaultMemoize", { enumerable: true, get: function () { return reselect_2.defaultMemoize; } });
// Name of property to store data on the global window object
exports.windowKey = '__RESELECT_DEBUG__';
// The poor mans UUIDv4
function getUniqueId() {
    const random = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
    // Return number in hex
    return random.toString(16);
}
// Get selector info map, creating if it doesn't exist
function getSelectorMap() {
    if (!window[exports.windowKey]) {
        window[exports.windowKey] = { selectors: new Map() };
    }
    return window[exports.windowKey].selectors;
}
// Get a unique name for this selector, given creation args
function getSelectorName(id, args) {
    // Use name of combiner function if available
    const combiner = args[args.length - 1];
    if (combiner instanceof Function && combiner.name) {
        return combiner.name;
    }
    // Otherwise use anon with id
    return `anonymous ${id}`;
}
// Extract dependency functions from creation args 
function extractDependencies(args) {
    const funcs = args.slice(0, -1);
    if (funcs.length > 0) {
        const dependencies = (Array.isArray(funcs[0]) ? funcs[0] : funcs);
        // TODO: Better typing
        return dependencies.map((f) => {
            if (f.debugId !== undefined) {
                const info = getSelectorMap().get(f.debugId);
                if (info) {
                    return {
                        type: "selector",
                        id: f.debugId,
                        name: info.name,
                        functionName: f.name,
                    };
                }
                else {
                    throw new Error("Failed to lookup dependency name");
                }
            }
            return { type: "function", name: f.name };
        });
    }
    return [];
}
// Store selector information in global state
function storeSelectorInfo(id, name, dependencies) {
    const info = {
        id: id,
        name: name,
        recomputations: 0,
        savedUsage: 0,
        dependencies: dependencies,
        combinerArguments: [],
    };
    getSelectorMap().set(id, info);
}
exports.createSelectorCreator = ((...args) => {
    const selectorCreator = (0, reselect_1.createSelectorCreator)(...args);
    return ((...args) => {
        const selectorId = getUniqueId();
        const initialSelectorName = getSelectorName(selectorId, args);
        const combiner = args[args.length - 1];
        const funcs = args.slice(0, -1);
        const wrappedCombiner = (...args) => {
            const info = getSelectorMap().get(selectorId);
            if (info) {
                info.combinerArguments.push(args);
            }
            return combiner(...args);
        };
        // @ts-expect-error The type system doesn't understand we have just wrapped the last argument
        const selector = selectorCreator(...funcs, wrappedCombiner);
        storeSelectorInfo(selectorId, initialSelectorName, extractDependencies(args));
        const wrappedSelector = (...args) => {
            // Compute result, keeping track of recomputations
            const beforeRecomputations = selector.recomputations();
            const result = selector(...args);
            const afterRecomputations = selector.recomputations();
            // Update selector info with new data
            const info = getSelectorMap().get(selectorId);
            if (info) {
                if (afterRecomputations > beforeRecomputations) {
                    info.recomputations = afterRecomputations;
                }
                else {
                    info.savedUsage++;
                }
            }
            return result;
        };
        // Add functions that were on the original selector
        wrappedSelector.recomputations = selector.recomputations;
        wrappedSelector.resetRecomputations = selector.resetRecomputations;
        wrappedSelector.resultFunc = selector.resultFunc;
        // Add the debug id so we can map dependencies
        wrappedSelector.debugId = selectorId;
        // Function to set a custom debug name
        wrappedSelector.setDebugName = (name) => {
            // TODO: Currently using this function will result in a type error due to re-use of
            // reselect library types
            const info = getSelectorMap().get(selectorId);
            if (info) {
                info.name = name;
            }
        };
        return wrappedSelector;
    });
    // Use this "as" so we expose a function with the same horrifying overloaded types as the original
    // Unfortunately we can't just export a function using Paramaters and ReturnType due to
    // https://github.com/microsoft/TypeScript/issues/43731
});
exports.createSelector = (0, exports.createSelectorCreator)(reselect_1.defaultMemoize);
exports.createStructuredSelector = ((...args) => {
    // Extract creation function from args or use default from this library
    const creationFunction = args[1] === undefined
        ? exports.createSelector
        : args[1];
    // Wrap the create selector function so we can capture it's return value
    let innerSelector;
    const wrappedCreateSelector = (...args) => {
        innerSelector = creationFunction(...args);
        return innerSelector;
    };
    const creationArgs = Array.from(args);
    creationArgs[1] = wrappedCreateSelector;
    // @ts-expect-error Cannot use Parameters due to overloaded function as above
    const structuredSelector = (0, reselect_1.createStructuredSelector)(...creationArgs);
    // Add debug id and set name from the original selector if present. Possible for this to be
    // missing if using a creation function not from this library
    if (innerSelector.debugId && innerSelector.setDebugName) {
        structuredSelector.debugId = innerSelector.debugId;
        structuredSelector.setDebugName = innerSelector.setDebugName;
    }
    else {
        console.warn("Not instrumenting structured selector created with custom function");
    }
    return structuredSelector;
});
