import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import SelectWireframe from '@atlassian/jira-portfolio-3-common/src/select-wireframe/index.tsx';
import { selectIssueOption } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import type { OptionType, ValueType } from '@atlassian/jira-portfolio-3-common/src/select/types';
import { getIssueOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues/utils';
import {
	isIssueSubTaskType,
	getPossibleParentIssues,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/util/issue-helper';
import { withSlots, slots } from '@atlassian/jira-portfolio-3-portfolio/src/common/component-slots';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import Cell from '../../column/cell/view';
import messages from './messages';
import type { Props } from './types';

export const makeHandleSelection =
	({ onChange, issue }: { onChange: Props['onChange']; issue: Props['issue'] }) =>
	(selection: ValueType) => {
		// @ts-expect-error - TS2551 - Property 'value' does not exist on type 'OptionType | OptionsType'. Did you mean 'values'?
		if (selection && typeof selection.value === 'string') {
			// @ts-expect-error - TS2551 - Property 'value' does not exist on type 'OptionType | OptionsType'. Did you mean 'values'?
			onChange(issue, selection.value);
		} else {
			onChange(issue, null);
		}
	};

export const ParentCell = ({
	issue,
	issues,
	issueTypesById,
	projectsById,
	onChange,
	isScrolling = false,
	isReadOnly = false,
	FieldSelect,
}: Props) => {
	const { formatMessage } = useIntl();
	const handleSelection = makeHandleSelection({ onChange, issue });

	const getParentSelectOptions = (): OptionType[] =>
		// eslint-disable-next-line @typescript-eslint/no-shadow
		getPossibleParentIssues({ issue, issues, issueTypesById, projectsById }).map((issue) =>
			getIssueOption(issue, issueTypesById, projectsById),
		);

	const select = () => {
		const { parent, summary } = issue;
		const items = getParentSelectOptions();
		const valueItem = items.find((option) => option.value === parent);

		return (
			<Cell attribute="parent" issue={issue} isScrolling={isScrolling}>
				<Tooltip content={valueItem ? valueItem.label : null}>
					<FieldSelect
						aria-label={formatMessage({ ...messages.parentCellLabel }, { issue: summary })}
						attribute="parent"
						formatOptionLabel={selectIssueOption}
						isClearable={isDefined(parent) && !isIssueSubTaskType({ issue, issueTypesById })}
						isDisabled={isReadOnly}
						issueId={issue.id}
						onChange={handleSelection}
						options={items}
						placeholder={formatMessage(messages.placeholder)}
						value={valueItem || null}
					/>
				</Tooltip>
			</Cell>
		);
	};

	const wireframe = () => {
		const parent = issue.parent;
		const items = getParentSelectOptions();
		const selectedItem = items.find((item) => item.value === parent);
		return <SelectWireframe content={selectedItem && selectIssueOption(selectedItem)} />;
	};

	let cell = null;
	cell = isScrolling ? wireframe() : select();

	return <>{cell}</>;
};

export default withSlots({ FieldSelect: slots.FieldSelect })(ParentCell);
