import { type ChangeActiveFilterViewAction, CHANGE_ACTIVE_FILTER_VIEW } from './actions';
import type { ActiveFilterViewState } from './types';

type Action = ChangeActiveFilterViewAction;

const reducer = (
	state: ActiveFilterViewState = 'timeline',
	action: Action,
): ActiveFilterViewState => {
	switch (action.type) {
		case CHANGE_ACTIVE_FILTER_VIEW:
			return action.payload;
		default:
			return state;
	}
};

export default reducer;
