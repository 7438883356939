import React, { Component } from 'react';
import find from 'lodash/find';
import * as R from 'ramda';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { DropdownItemGroup as DropMenuItemGroup } from '@atlaskit/dropdown-menu';
import AddIcon from '@atlaskit/icon/glyph/editor/add';
import { SpotlightTarget } from '@atlaskit/onboarding';
import Tooltip from '@atlaskit/tooltip';
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types';
import { injectIntl, FormattedMessage } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import { CtpSpotlights } from '@atlassian/jira-portfolio-3-onboarding/src/controllers/ctp-spotlights';
import { CttSpotlights } from '@atlassian/jira-portfolio-3-onboarding/src/controllers/ctt-spotlights';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types';
import type { EnrichedHierarchyLevel } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/hierarchy/types';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import type {
	Group,
	ScopeIssue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import { getInlineCreateIssueTypeId } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/util';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import {
	GROUPING,
	COMPLETED_RELEASES_GROUP,
	RELEASE,
	PROJECT,
	CustomFields,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import {
	getCustomFieldIdFromCustomFieldGrouping,
	isRoadmapGroupedByCustomField,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/custom-fields';
import { getGroupForAnalyticEvent } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/grouping';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import MenuItem from './menu-item/view.tsx';
import messages from './messages';
import NestedMenu from './nested-menu';
import { type HierarchyItem, type Props, type State, ISSUE_CREATE_SUPPORTED_GROUPS } from './types';
import { isCrossTeamPlanningTemplateExperimentEnabled } from './utils';

// eslint-disable-next-line jira/react/no-class-components
export class AddIssueMenu extends Component<Props, State> {
	static defaultProps = {
		isDefaultOpen: false,
		NestedMenu,
		MenuItem,
	};

	state = { isMenuOpen: this.props.isDefaultOpen };

	isPlanGroupedBySingleValueCustomField = () => {
		const { customFieldsById, visualisationGrouping = GROUPING.NONE } = this.props;

		if (isRoadmapGroupedByCustomField(visualisationGrouping)) {
			const fieldId = parseInt(getCustomFieldIdFromCustomFieldGrouping(visualisationGrouping), 10);
			const customField = customFieldsById[fieldId];

			if (customField) {
				return CustomFields.GroupableSingleValueFields.includes(customField.type.key);
			}
			return false;
		}
		return false;
	};

	allowGlobalAddIssueForGroups = (): boolean =>
		(this.props.visualisationGrouping !== undefined &&
			ISSUE_CREATE_SUPPORTED_GROUPS.includes(this.props.visualisationGrouping)) ||
		this.isPlanGroupedBySingleValueCustomField();

	getProjectsForCurrentIssueTypeLevel = (level: number): Project[] => {
		const { projects, enrichedHierarchyLevels } = this.props;
		const enrichedLevel: EnrichedHierarchyLevel | null | undefined = R.find(
			R.propEq('level', level),
			enrichedHierarchyLevels,
		);
		const orderedIssueTypes = enrichedLevel ? enrichedLevel.issueTypes : [];
		const currentLevelIssueTypes = orderedIssueTypes.map(({ id }) => id);
		return projects.filter(
			(project) => R.intersection(project.issueTypeIds, currentLevelIssueTypes).length > 0,
		);
	};

	triggerInlineCreateIssueAnalytics = (
		analyticsEvent: UIAnalyticsEvent,
		issueTypeId: number,
		visualisationGrouping?: string | null,
	) => {
		const { customFieldsById } = this.props;
		const analyticsKey = PRODUCT_ANALYTICS_EVENT_NAMES.CREATED_ISSUE_FROM_HEADER;
		const [actionSubject, eventAction] = analyticsKey.split(' ');
		const grouping = getGroupForAnalyticEvent(
			visualisationGrouping || '',
			Object.values(customFieldsById),
		);
		fireUIAnalytics(analyticsEvent.update({ action: eventAction, actionSubject }), analyticsKey, {
			issueType: issueTypeId,
			grouping,
		});
	};

	handleCreateClick = (
		hierarchyLevel: HierarchyItem,
		project: Project,
		clickedGroup: Group<ScopeIssue> | null | undefined,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		const {
			issueTypeId: currentIssueTypeId,
			IssueTypeIdForHierarchy,
			startInlineCreate,
			setIssueTypeIdForHierarchy,
			setGlobalCreateIssue,
			setDefaultProject,
			toggleExpandGroup,
			typeToLevel,
			visualisationGrouping,
		} = this.props;

		const issueTypeId = getInlineCreateIssueTypeId(hierarchyLevel.value, project, {
			issueTypeId: currentIssueTypeId,
			IssueTypeIdForHierarchy,
			typeToLevel,
		});
		setIssueTypeIdForHierarchy(hierarchyLevel.value, issueTypeId);
		setGlobalCreateIssue();

		/**
		 * If there is only one project in the plan, that project is marked as default project during the initial load.
		 * If there are multiple projects in the plan, we present the user with a select box to select the default project while
		 * creating an issue using the inline create.
		 *
		 * But there could be a situation where even though there are multiple projects in the plan but if the user creates an issue
		 * in a hierarchy level which is only supported by one project, then we don't present the user with the select box
		 * to select the default project so we set the default project manually below.
		 */
		const projectsForCurrentIssueTypeLevel = this.getProjectsForCurrentIssueTypeLevel(
			hierarchyLevel.value,
		);

		if (clickedGroup) {
			const { groupCombination, grouping, group, startInlineCreateInEmptyGroup, parentGroup } =
				clickedGroup;
			const projectId: number =
				(grouping === PROJECT && groupCombination && Number(groupCombination.project)) ||
				project.id;
			if (projectsForCurrentIssueTypeLevel.length === 1) {
				setDefaultProject(projectId);
			}
			if (grouping === RELEASE && parentGroup === COMPLETED_RELEASES_GROUP) {
				toggleExpandGroup({ expand: true, groupName: COMPLETED_RELEASES_GROUP, grouping });
			}
			toggleExpandGroup({ expand: true, groupName: group, grouping });

			// triggering analytics
			this.triggerInlineCreateIssueAnalytics(analyticsEvent, issueTypeId, visualisationGrouping);

			startInlineCreate({
				parentId: null,
				siblingId: null,
				hierarchyLevel: hierarchyLevel.value,
				issueTypeId,
				projectId,
				source: 'HEADER',
				groupCombination,
				grouping,
				group,
				startInlineCreateInEmptyGroup,
			});
		} else {
			if (projectsForCurrentIssueTypeLevel.length === 1) {
				setDefaultProject(project.id);
			}

			// triggering analytics
			this.triggerInlineCreateIssueAnalytics(analyticsEvent, issueTypeId, visualisationGrouping);

			startInlineCreate({
				parentId: null,
				siblingId: null,
				hierarchyLevel: hierarchyLevel.value,
				issueTypeId,
				projectId: project.id,
				source: 'HEADER',
			});
		}
	};

	handleHierarchyItemClick = (
		item: {
			id: number;
			title: string;
			value: number;
			visible?: boolean;
		},
		isDisabled: boolean,
		projectsForLevel: Array<Project>,
		group: Group<ScopeIssue> | null | undefined,
		analyticsEvent: AnalyticsEvent,
	) => {
		if (!isDisabled) {
			let project: Project | undefined =
				find(projectsForLevel, ['id', this.props.defaultProjectId]) || projectsForLevel[0];

			if (group && group.grouping === PROJECT) {
				project = projectsForLevel.find((p) => p.id === group.groupCombination?.project);
			}

			if (!isDefined(project)) {
				throw new Error(
					'No project available for this level of hierarchy. It should be disabled in this menu.',
				);
			} else {
				this.handleCreateClick(item, project, group, analyticsEvent);
				this.toggleMenu(false);
			}
		}
	};

	renderMenu = () => {
		const {
			intl,
			projects,
			typeToLevel,
			currentHierarchyLevels,
			visualisationGrouping,
			groups,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			MenuItem,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			NestedMenu,
		} = this.props;

		return (
			<DropMenuItemGroup testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.add-issue.issue-types">
				{currentHierarchyLevels.map((item) => {
					const projectsForLevel = projects.filter((project) =>
						project.issueTypeIds.some((id) => typeToLevel(id) === item.value),
					);
					const isDisabled = projectsForLevel.length === 0 || !item.visible;
					let tooltipContent =
						projectsForLevel.length > 0
							? messages.createIssueHiddenEntryTooltip
							: messages.createIssueTypeDisabledTooltip;
					if (
						projectsForLevel.length === 0 &&
						!projects.some((project) => project.isSimplified === false)
					) {
						tooltipContent = messages.createIssueTypeDisabledTmpTooltip;
					}
					return visualisationGrouping === GROUPING.NONE ? (
						<MenuItem
							key={item.id}
							menuItem={item}
							isDisabled={isDisabled}
							tooltip={intl.formatMessage(tooltipContent, {
								hierarchy: item.title,
							})}
							onMenuItemClick={(analyticsEvent: AnalyticsEvent) => {
								this.handleHierarchyItemClick(
									item,
									isDisabled,
									projectsForLevel,
									undefined,
									analyticsEvent,
								);
							}}
						>
							{item.title}
						</MenuItem>
					) : (
						<NestedMenu
							key={item.id}
							hierarchyItem={item}
							onGroupClick={(group: Group<ScopeIssue>, analyticsEvent: AnalyticsEvent) => {
								this.handleHierarchyItemClick(
									item,
									isDisabled,
									projectsForLevel,
									group,
									analyticsEvent,
								);
							}}
							isDisabled={isDisabled}
							groups={groups}
							projectsForLevel={projectsForLevel}
							triggerTooltip={intl.formatMessage(tooltipContent, {
								hierarchy: item.title,
							})}
						/>
					);
				})}
			</DropMenuItemGroup>
		);
	};

	toggleMenu = (isOpen?: boolean) => {
		if (isDefined(isOpen)) {
			this.setState(() => ({ isMenuOpen: isOpen }));
		} else {
			this.setState((prevState) => ({ isMenuOpen: !prevState.isMenuOpen }));
		}
	};

	render() {
		const {
			intl: { formatMessage },
		} = this.props;
		const { isMenuOpen } = this.state;
		return (
			<InlineDialog
				content={this.renderMenu()}
				isOpen={isMenuOpen}
				placement="bottom-start"
				onClose={() => this.toggleMenu()}
				noPaddings
			>
				<Tooltip
					content={
						!this.allowGlobalAddIssueForGroups()
							? formatMessage(messages.addIssueDisabledTooltip)
							: ''
					}
				>
					{isCrossTeamPlanningTemplateExperimentEnabled() ? (
						<SpotlightTarget name={CttSpotlights.CreateIssue}>
							<SpotlightTarget name={CtpSpotlights.CreateIssue}>
								<Button
									iconBefore={<AddIcon label="" />}
									isSelected={isMenuOpen}
									appearance="link"
									onClick={() => this.toggleMenu()}
									isDisabled={!this.allowGlobalAddIssueForGroups()}
									testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.add-issue.button"
								>
									<FormattedMessage {...messages.createIssue} />
								</Button>
							</SpotlightTarget>
						</SpotlightTarget>
					) : (
						<SpotlightTarget name={CttSpotlights.CreateIssue}>
							<Button
								iconBefore={<AddIcon label="" />}
								isSelected={isMenuOpen}
								appearance="link"
								onClick={() => this.toggleMenu()}
								isDisabled={!this.allowGlobalAddIssueForGroups()}
								testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.add-issue.button"
							>
								<FormattedMessage {...messages.createIssue} />
							</Button>
						</SpotlightTarget>
					)}
				</Tooltip>
			</InlineDialog>
		);
	}
}

export default injectIntl(AddIssueMenu);
