import memoizeOne from 'memoize-one';
import {
	INITIATIVE_LEVEL,
	EPIC_LEVEL,
} from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import {
	getTypeToLevel,
	getEnrichedHierarchyLevels,
	getHierarchyRange,
} from '../../query/hierarchy';
import { getInlineCreateState } from '../../query/inline-create';
import { getTeamManagedProjectByIssueTypeId } from '../../query/issue-types';
import { getIssuesByLevel } from '../../query/issues';
import { getPlan } from '../../query/plan';
import { getProjects, getDefaultProjectId } from '../../query/projects';
import { getVisualisationGrouping } from '../../query/visualisations';
import type { State } from '../../state/types';

const mapStateToPropsFactory = () => {
	const initativeLevelIssuesFactory = memoizeOne((state: State) =>
		getIssuesByLevel(state, INITIATIVE_LEVEL),
	);
	const epicLevelIssuesFactory = memoizeOne((state: State) => getIssuesByLevel(state, EPIC_LEVEL));

	return (state: State) => {
		const { issueTypeId, IssueTypeIdForHierarchy } = getInlineCreateState(state);

		return {
			planId: getPlan(state).id,
			typeToLevel: getTypeToLevel(state),
			projects: getProjects(state),
			issueTypeId,
			IssueTypeIdForHierarchy,
			defaultProjectId: getDefaultProjectId(state),
			enrichedHierarchyLevels: getEnrichedHierarchyLevels(state),
			visualisationGrouping: getVisualisationGrouping(state),
			initativeLevelIssues: initativeLevelIssuesFactory(state),
			epicLevelIssues: epicLevelIssuesFactory(state),
			hierarchyRange: getHierarchyRange(state),
			tmpIssueTypeIds: getTeamManagedProjectByIssueTypeId(state),
		};
	};
};

export const mapStateToProps = mapStateToPropsFactory;
