import {
	ISSUE_HIERARCHY_LEVEL_BASE,
	ISSUE_HIERARCHY_LEVEL_EPIC,
	ISSUE_HIERARCHY_LEVEL_GOAL,
	ISSUE_HIERARCHY_LEVEL_INITIATIVE,
	ISSUE_HIERARCHY_LEVEL_STRATEGY,
	ISSUE_HIERARCHY_LEVEL_SUBTASK,
	type HierarchyLevel,
} from '@atlassian/jira-issue-type-hierarchies';

export const SUB_TASK_LEVEL: HierarchyLevel = ISSUE_HIERARCHY_LEVEL_SUBTASK;
export const STORY_LEVEL: HierarchyLevel = ISSUE_HIERARCHY_LEVEL_BASE;
export const EPIC_LEVEL: HierarchyLevel = ISSUE_HIERARCHY_LEVEL_EPIC;
export const INITIATIVE_LEVEL: HierarchyLevel = ISSUE_HIERARCHY_LEVEL_INITIATIVE;
export const STRATEGY_LEVEL: HierarchyLevel = ISSUE_HIERARCHY_LEVEL_STRATEGY;
export const GOAL_LEVEL: HierarchyLevel = ISSUE_HIERARCHY_LEVEL_GOAL;
