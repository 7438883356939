import type { ComponentType, ReactNode } from 'react';
import type { ReportTimeToInteractivePayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/time-to-interactive/types';
import type { DependencyLinks } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/dependencies/types';
import type { ProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/types.tsx';
import type { Mode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import type { Sprint } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/sprints/types.tsx';
import type { IssueLinkType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/system/types.tsx';
import type { Team } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/teams/types';
import { GroupBy } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/dependencies/filters/types';
import type { ReportStatus } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/dependencies/report-state/types';
import type { StagePosAndScale } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/dependencies/stage-position-scale/types';
import type { OwnProps as ViewExperienceFailureTrackerProps } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/failure-tracker/types';
import type {
	IssueLink,
	IssueStatus,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import type { InjectedProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/experience-tracking/with-view-experience-tracker/index.tsx';
import type { Props as ConnectionProps } from './connections/types';
import type { OwnProps as FiltersProps } from './filters/types';
import type { OwnProps as EpicClusterProps } from './groups/epic/types';
import type { Props as ProjectClusterProps } from './groups/project/types';
import type { Props as TeamClusterProps } from './groups/team/types';
import type { OwnProps as IssueProps } from './issue/types';
import type { OwnProps as OtherIssueProps } from './other-issues/types';
import type { OwnProps as TeamViewProps } from './team-item/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { GroupBy };

export type Measurements = {
	height: number;
	width: number;
};

export type Rect = Measurements & {
	left: number;
	top: number;
};

export type Links = {
	[id: string]: IssueLink[];
};

export const NONE = 'none';
export const PROJECT = 'project';
export const TEAM = 'team';
export const EPIC_LEVEL = 'epic_level';
export const HIERARCHY = 'hierarchy';
export const SPRINT = 'sprint';

export const ROLLUP_BY_OPTION = {
	NONE,
	HIERARCHY,
	TEAM,
};

export const UNASSIGNED = 'unassigned';

export const NO_PARENT_GROUP_SUFFIX = 'no_parent_group';

export const OTHER_GROUP_ID = 'other';

export type StateProps = {
	isLoading: boolean;
	projectsById: ProjectsById;
	issueLinkTypesById: Record<number | string, IssueLinkType>;
	issueStatusesById: {
		[id: string]: IssueStatus;
	};
	issuesById: {
		[id: string]: Issue;
	};
	issueInFocus: string | null;
	dependencyLinks: DependencyLinks;
	teamsById: {
		[key: string]: Team;
	};
	groupBy: GroupBy | null;
	rollupBy: string | null;
	mode: Mode;
	syncStartEnabled: boolean;
	initialStagePosAndScale: StagePosAndScale;
	sprintsById: {
		[key: string]: Sprint;
	};
};

export type DispatchProps = {
	reportTimeToInteractive: (arg1: ReportTimeToInteractivePayload) => void;
	focusIssueLink: (issueId?: string) => void;
	onDependenciesView: (arg1: Mode) => void;
	onLineClick: (linksLength: number) => void;
	onCardClick: (id: string) => void;
	setIssueFilter: (issueId: string | null) => void;
	setStagePositionAndScale: (pos: StagePosAndScale) => void;
	setReportStatus: (status: ReportStatus) => void;
};

export type InjectedComponentProps = {
	IssueView?: ComponentType<IssueProps>;
	TeamView?: ComponentType<TeamViewProps>;
	OtherIssue?: ComponentType<OtherIssueProps>;
	TeamCluster?: ComponentType<TeamClusterProps>;
	ProjectCluster?: ComponentType<ProjectClusterProps>;
	EpicCluster?: ComponentType<EpicClusterProps>;
	Connections?: ComponentType<ConnectionProps>;
	Filters?: ComponentType<FiltersProps>;
	ViewExperienceFailureTracker?: ComponentType<ViewExperienceFailureTrackerProps>;
};

export type OwnProps = {
	legendToggleButton?: ReactNode;
};

export type Props = StateProps & DispatchProps & InjectedComponentProps & OwnProps & InjectedProps;

export const GROUP_BY_SPRINT_FIELD = 'groupBySprintField';
export type IssueWithGroupBySprintProp = Issue & {
	[GROUP_BY_SPRINT_FIELD]?: number | string;
};

export type GroupByNotNoneType = 'groupBySprintField' | Exclude<GroupBy, 'none' | 'epic_level'>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type LayoutErrorResponse = { ranker: string; error: any };
