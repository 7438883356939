import React from 'react';
import { Text } from '@atlaskit/primitives';
import { injectIntl } from '@atlassian/jira-intl';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import type { Props } from './types';

function Release({ intl, fixVersions = [], versions }: Props) {
	const versionNames =
		fixVersions &&
		fixVersions.map((fixVersion) => {
			const targetVersion = versions.find((currentVersion) => currentVersion.id === fixVersion);
			if (targetVersion) {
				return targetVersion.name;
			}
			return intl.formatMessage(commonMessages.notInPlan);
		});
	const placeholder = <Text>-</Text>;

	return versionNames ? (
		<EllipsedWithTooltip content={versionNames.join(', ')}>
			<span>{versionNames.join(', ')}</span>
		</EllipsedWithTooltip>
	) : (
		placeholder
	);
}

export default injectIntl(Release);
