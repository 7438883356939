import * as R from 'ramda';
import {
	RESET,
	type ResetAction,
	CLEAR_ALL,
	type ClearAllAction,
	ADD,
	type AddAction,
	UPDATE,
	type UpdateAction,
	REMOVE,
	type RemoveAction,
} from './actions';
import { type CustomFieldValuesGroupsState, ALL_OTHER_ISSUES } from './types';

export type Action = ResetAction | ClearAllAction | AddAction | UpdateAction | RemoveAction;

const initialState: CustomFieldValuesGroupsState = {};

const getAllOtherIssuesGroup = (grouping: string) => ({
	id: ALL_OTHER_ISSUES,
	name: '',
	isExpanded: false,
	[grouping]: [],
});

const reducer = (
	state: CustomFieldValuesGroupsState = initialState,
	action: Action,
): CustomFieldValuesGroupsState => {
	switch (action.type) {
		case RESET: {
			return { ...state, [action.payload.grouping]: action.payload.groups };
		}
		case CLEAR_ALL: {
			return {
				...state,
				[action.payload.grouping]: [getAllOtherIssuesGroup(action.payload.grouping)],
			};
		}
		case ADD: {
			const groups = state[action.payload.grouping] || [
				getAllOtherIssuesGroup(action.payload.grouping),
			];
			return {
				...state,
				[action.payload.grouping]: [...groups, action.payload.group],
			};
		}
		case UPDATE: {
			const { payload } = action;
			const groups = state[payload.grouping] || [];

			const index = groups.findIndex(({ id }) => id === action.payload.id);

			return {
				...state,
				[payload.grouping]: R.adjust(index, (group) => R.merge(group, payload.patch), groups),
			};
		}
		case REMOVE: {
			const { payload } = action;
			const groups = state[payload.grouping] || [];

			return {
				...state,
				[payload.grouping]: groups.filter(({ id }) => id !== payload.id),
			};
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};

export default reducer;
