import type React from 'react';
import { useMemo, useRef } from 'react';
import partition from 'lodash/fp/partition';
import type { RowConfig } from '../../../common/types';
import { useVisibleColumns } from '../../../controllers/common';
import { useIsScrollingY } from '../../../controllers/container';
import { useVirtualized } from '../../../controllers/virtualized';

type RowParams = [RowConfig, number];

interface Props {
	rowConfigs: RowConfig[];
	children: (virtualParams: {
		head: RowParams[];
		body: RowParams[];
		liteRowIndexes: number[];
		visibleCols: Record<string, boolean>;
	}) => ReturnType<React.FC>;
}

const EMPTY_LIST: number[] = [];
const EMPTY_COLS: Record<string, boolean> = {};
export const Virtualized = ({ rowConfigs, children }: Props) => {
	const virtualized = useVirtualized();
	const [isScrollingY] = useIsScrollingY();
	const columnsVisible = useVisibleColumns();
	const virtualizedRef = useRef<Set<number> | undefined>(undefined);
	const visibleColsRef = useRef(EMPTY_COLS);

	const [head, body] = useMemo(
		() =>
			partition(([rowConfig]: [RowConfig, number]) => rowConfig.thead)(
				rowConfigs
					.map((rowConfig, index) => [rowConfig, index] as const)
					.filter(([, index]) => virtualized.includes(index)),
			),
		[virtualized, rowConfigs],
	);

	const prevVirtualized = useMemo(
		() => (isScrollingY ? virtualizedRef.current : undefined),
		[isScrollingY],
	);
	virtualizedRef.current = new Set(virtualized);
	visibleColsRef.current = columnsVisible;
	/**
	 * Generate row indexes for lite-row rendering
	 * Optimization - Render lite row only for the addition rows while scrolling
	 */
	const liteRowIndexes = useMemo(
		() =>
			prevVirtualized !== undefined
				? virtualized.filter((index) => !prevVirtualized.has(index))
				: EMPTY_LIST,
		[virtualized, prevVirtualized],
	);

	/**
	 * Clear visibleCols when user is not scrolling, keep track of scrolling with prevVirtualized
	 * Visible columns calculation is needed by lite rows only.
	 */
	const visibleCols = useMemo(
		() => (prevVirtualized !== undefined ? visibleColsRef.current : EMPTY_COLS),
		[prevVirtualized],
	);

	return children({ head, body, liteRowIndexes, visibleCols });
};
