import React, { useState, useMemo } from 'react';
import ColorPicker from '@atlaskit/color-picker';
import { useIntl } from '@atlassian/jira-intl';
import { colourPickerPalette, colourPickerPaletteLabels } from '../colours';
import type { Props } from './types';

const ColourPicker = ({
	colour,
	position = 'left',
	isDisabled = false,
	selectedColourSwatchSize = 'small',
	onColourChange,
	ariaLabel,
}: Props) => {
	const [color, setColor] = useState(colour);
	const { formatMessage } = useIntl();

	const colorPalette = useMemo(
		() =>
			// eslint-disable-next-line @typescript-eslint/no-shadow
			colourPickerPalette.map((colour) => ({
				label: formatMessage(colourPickerPaletteLabels[colour]),
				value: colour,
			})),
		[formatMessage],
	);

	return (
		<ColorPicker
			palette={colorPalette}
			selectedColor={color}
			showDefaultSwatchColor={!!color}
			selectedColourSwatchSize={selectedColourSwatchSize}
			isDisabledSelectedSwatch={isDisabled}
			popperProps={{
				strategy: 'fixed',
				placement: position,
				modifiers: [
					{
						name: 'offset',
						options: {
							offset: [0, 10],
						},
					},
				],
			}}
			cols={colorPalette.length / 2}
			onChange={(newColor: string) => {
				setColor(newColor);
				onColourChange(newColor);
			}}
			label={ariaLabel}
		/>
	);
};

export default ColourPicker;
