import { useMemo } from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { traverse } from '@atlaskit/adf-utils/traverse';
import type { ADFEntity } from '@atlaskit/adf-utils/types';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useSearchCustomFieldKeys } from '@atlassian/jira-issue-field-base/src/services/custom-field-key-service/index.tsx';
import {
	useFieldValue,
	useMultiFieldValuesForIssues,
} from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { TEXT_AREA_CF_TYPE } from '@atlassian/jira-platform-field-config';
import {
	DescriptionKey,
	SummaryKey,
} from '@atlassian/jira-providers-issue/src/model/issue-system-fields.tsx';

export const Errors = {
	EMPTY: 'ADF_EMPTY',
	LIMIT_EXCEEDED: 'ADF_LIMIT_EXCEEDED',
};

// A faster version to count ADF chars, using traverse instead of reduce
export const countADFChars = (value: ADF | undefined) => {
	if (!value) return 0;

	let chars = 0;
	traverse(value, {
		text: (node: ADFEntity) => {
			if (node.text) {
				chars += node.text.length;
			}
		},
		emoji: ({ attrs }) => {
			if (attrs?.text) {
				chars += attrs.text.length;
			}
		},
		paragraph: () => {
			chars += 1;
		},
	});

	// Subtract 1 due to doc node
	return chars ? chars - 1 : chars;
};

type TextFieldCharLengths = {
	textFieldCharLengths: {
		summary: number;
		description: number;
		[customField: string]: number;
	};
};

/**
 * Get the length of summary, description and any paragraph or rich text fields associated with the issue
 */
export const useTextFieldCharLengths = (issueKey: string): TextFieldCharLengths => {
	const [descriptionValue] = useFieldValue({
		issueKey,
		fieldKey: DescriptionKey,
	});

	const [summaryValue] = useFieldValue({
		issueKey,
		fieldKey: SummaryKey,
	});

	// Covers paragraph fields in CMP and Rich text fields in TMP
	const textKeys = useSearchCustomFieldKeys(issueKey, TEXT_AREA_CF_TYPE);
	const [textFields] = useMultiFieldValuesForIssues({
		issueKeys: [issueKey],
		fieldKeys: textKeys,
	});

	const descriptionLength = useMemo(() => {
		if (!descriptionValue) return 0;

		// parse and stringify needed since description is treated as readonly
		return countADFChars(JSON.parse(JSON.stringify(descriptionValue)));
	}, [descriptionValue]);

	const customTextFieldLengths = useMemo(() => {
		const textValues = textFields && textFields?.[issueKey];

		let customFieldLength = 0;
		if (textValues) {
			Object.keys(textValues).forEach((key) => {
				if (typeof textValues[key] === 'string') {
					// For paragraph fields in CMP
					customFieldLength += textValues[key].length;
				} else {
					// For rich text fields
					customFieldLength += countADFChars(textValues[key]);
				}
			});
		}

		return customFieldLength;
	}, [issueKey, textFields]);

	return useMemo(
		() => ({
			textFieldCharLengths: {
				description: descriptionLength,
				summary: summaryValue && typeof summaryValue === 'string' ? summaryValue.length : 0,
				customTextFields: customTextFieldLengths,
			},
		}),
		[customTextFieldLengths, descriptionLength, summaryValue],
	);
};

export const countAdfUrlNum = (value: ADF | undefined) => {
	if (!value) return 0;
	let numOfUrl = 0;
	traverse(value, {
		text: (node: ADFEntity) => {
			if (node.marks?.some((mark) => mark.type === 'link')) numOfUrl++;
		},
		inlineCard: (node: ADFEntity) => {
			if (node.attrs?.url) numOfUrl++;
		},
		blockCard: (node: ADFEntity) => {
			if (node.attrs?.url) numOfUrl++;
		},
		embedCard: (node: ADFEntity) => {
			if (node.attrs?.url) numOfUrl++;
		},
	});
	return numOfUrl;
};

export const useDescriptionUrlNumber = () => {
	const issueKey = useIssueKey();
	const [descriptionValue] = useFieldValue({
		issueKey,
		fieldKey: DescriptionKey,
	});

	const numOfUrl = useMemo(() => {
		if (!descriptionValue) return 0;
		return countAdfUrlNum(descriptionValue);
	}, [descriptionValue]);

	return numOfUrl;
};
