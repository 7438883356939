import React, { type ElementRef, type CSSProperties } from 'react';
import * as R from 'ramda';
import { token } from '@atlaskit/tokens';
import type { PropsWithStyles, CommonProps, ClassNamesState } from './types';

export type InputProps = PropsWithStyles &
	CommonProps & {
		cx: (
			arg1: string | null | undefined | null,
			arg2?: ClassNamesState | null | undefined,
			arg3?: string | null | undefined,
		) => string | undefined;
		/** Reference to the internal element */
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		innerRef: (arg1: ElementRef<any>) => void;
		/** Set whether the input should be visible. Does not affect input size. */
		isHidden: boolean;
		/** Whether the input is disabled */
		isDisabled?: boolean;
		className?: string;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		selectProps: any;
	};

const inputStyle = (isHidden: boolean): CSSProperties => ({
	background: 0,
	border: 0,
	font: token('font.body'),
	opacity: isHidden ? 0 : 1,
	outline: 0,
	padding: 0,
	color: 'inherit',
	boxSizing: 'content-box',
	width: '100%',
});

const Input = ({
	className,
	cx,
	getStyles,
	innerRef,
	isHidden,
	isDisabled,

	/** This property gets passed to our Input component by atlaskit when it doesn't understand the
	 *  the property. We don't need this property for Input, it is only used the select component
	 *  So we are removing it.
	 */

	selectProps,

	...props
}: InputProps) => {
	/* Not all the props forward from select are valid HTML attributes.
	 * When forwarded to the DOM, these cause warnings in the console.
	 */
	const {
		isMulti,
		isRtl,
		hasValue,
		setValue,
		getValue,
		clearValue,
		selectOption,
		...validHTMLProps
	} = props;

	return (
		<div
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				...getStyles('input', props),
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				gridTemplateColumns: 'unset', // Make sure the customize styles can work at anytime
			}}
		>
			<input
				// if the menu is open so should autofocus,if the menu is closed but there are some value need to autofocus too
				autoFocus={!R.isEmpty(selectProps.value) && selectProps.menuIsOpen}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={cx(null, { input: true }, className)}
				ref={innerRef}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={inputStyle(isHidden)}
				disabled={isDisabled}
				{...validHTMLProps}
				aria-describedby={selectProps['aria-describedby']}
			/>
		</div>
	);
};

export default Input;
