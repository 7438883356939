import type { ExternalIssues } from './types';

export const ADD = 'state.domain.external-issues.ADD' as const;

export type AddAction = {
	type: typeof ADD;
	payload: ExternalIssues;
};

export const add = (payload: ExternalIssues): AddAction => ({
	type: ADD,
	payload,
});
