import React, { useState, type ReactNode } from 'react';
import StandardButton from '@atlaskit/button/standard-button';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalHeader,
	ModalTitle,
	ModalBody,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import { Text } from '@atlaskit/primitives';
import { Radio } from '@atlaskit/radio';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

export default function NonExistingDialog({
	onClose,
	onRequestDiscardChanges,
	onRequestSaveAs,
	viewName,
}: Props) {
	const { formatMessage } = useIntl();

	const options = [
		{
			message: messages.saveAs,
			action: onRequestSaveAs,
		},
		{
			message: messages.discardChanges,
			action: onRequestDiscardChanges,
		},
	];
	const [selectedIndex, setSelectedIndex] = useState<number>(-1);
	const handleRadioButtonChange = (idx: number) => () => setSelectedIndex(idx);

	return (
		<ShortcutScope>
			<ModalDialog
				autoFocus
				shouldScrollInViewport
				onClose={onClose}
				shouldCloseOnOverlayClick
				shouldCloseOnEscapePress
				width="small"
			>
				<ModalHeader>
					<ModalTitle>{formatMessage(messages.heading)}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					{formatMessage(messages.message, {
						name: viewName,
						strong: (chunks: ReactNode[]) => <Text as="strong">{chunks}</Text>,
					})}
					<div
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={styles.actionGroup}
						data-testid="portfolio-3-portfolio.app-simple-plans.top.view-bar.non-existing-dialog.not-existing-dialog"
					>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.actionGroupHeader}>
							{formatMessage(messages.actionGroupHeader)}
						</div>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.actionGroupRadios}>
							{options.map(({ message }, idx) => (
								<Radio
									isChecked={idx === selectedIndex}
									key={idx}
									label={formatMessage(message)}
									onChange={handleRadioButtonChange(idx)}
								/>
							))}
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<StandardButton appearance="subtle" onClick={onClose}>
						{formatMessage(commonMessages.cancel)}
					</StandardButton>
					<StandardButton
						appearance="primary"
						isDisabled={selectedIndex === -1}
						onClick={options[selectedIndex] && options[selectedIndex].action}
						testId="portfolio-3-portfolio.app-simple-plans.top.view-bar.non-existing-dialog.view-non-existing-dialog-confirm-button"
					>
						{formatMessage(commonMessages.confirm)}
					</StandardButton>
				</ModalFooter>
			</ModalDialog>
		</ShortcutScope>
	);
}
