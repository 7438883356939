import { useEffect, useState } from 'react';
import { useCttOnboardingTour } from '../../../controllers/ctt-onboarding';
import {
	CttOnboardingStage,
	CttOnboardingVariant,
	type CttOnboardingVariantType,
} from '../../../controllers/ctt-onboarding/types';

interface Props {
	setInlineCreate: () => void;
	setClearInlineCreate: () => void;
	setPrimeRoadmap: () => void;
	variant: CttOnboardingVariantType;
	hasAnyInitiatives: boolean;
}

export const StepInputTitle = ({
	setInlineCreate,
	setClearInlineCreate,
	setPrimeRoadmap,
	variant,
	hasAnyInitiatives,
}: Props) => {
	const assertUnreachable = (_: never): never => {
		throw new Error("Didn't expect to get here");
	};

	const [, { nextCttStage }] = useCttOnboardingTour();

	const [hasStartedInlineCreate, setHasStartedInlineCreate] = useState(false);
	const [hasPrimedRoadmap, setHasPrimedRoadmap] = useState(false);

	useEffect(() => {
		try {
			switch (variant) {
				case CttOnboardingVariant.NO_INITIATIVE:
					nextCttStage();
					break;
				case CttOnboardingVariant.EPIC:
				case CttOnboardingVariant.NO_EPIC:
				case CttOnboardingVariant.TMP_EPIC:
					if (!hasPrimedRoadmap) {
						setPrimeRoadmap();
						setHasPrimedRoadmap(true);
						break;
					}

					if (hasAnyInitiatives) {
						setClearInlineCreate();
						nextCttStage();
						break;
					}

					if (!hasStartedInlineCreate) {
						// In the event that there is some unforeseen other failure, skip the user input.
						try {
							setInlineCreate();
							setHasStartedInlineCreate(true);
						} catch (e) {
							nextCttStage(CttOnboardingStage.NAVIGATE_ISSUE_SOURCES);
						}
					}
					break;
				default:
					assertUnreachable(variant);
					break;
			}
		} catch (e) {
			nextCttStage();
		}
	}, [
		setClearInlineCreate,
		hasAnyInitiatives,
		hasPrimedRoadmap,
		hasStartedInlineCreate,
		nextCttStage,
		variant,
		setPrimeRoadmap,
		setInlineCreate,
	]);

	return null;
};
