import type { CustomFieldValuesGroup } from './types';

export const RESET = 'state.domain.view-settings.custom-field-values-groups.RESET' as const;

export const CLEAR_ALL = 'state.domain.view-settings.custom-field-values-groups.CLEAR_ALL' as const;

export const ADD = 'state.domain.view-settings.custom-field-values-groups.ADD' as const;

export const UPDATE = 'state.domain.view-settings.custom-field-values-groups.UPDATE' as const;

export const REMOVE = 'state.domain.view-settings.custom-field-values-groups.REMOVE' as const;

export type ResetActionPayload = { grouping: string; groups: CustomFieldValuesGroup[] };

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export type ClearAllActionPayload = { grouping: string };

export type ClearAllAction = {
	type: typeof CLEAR_ALL;
	payload: ClearAllActionPayload;
};

export type AddActionPayload = { grouping: string; group: CustomFieldValuesGroup };

export type AddAction = {
	type: typeof ADD;
	payload: AddActionPayload;
};

export type UpdateActionPayload = {
	grouping: string;
	id: string;
	patch: CustomFieldValuesGroup;
};

export type UpdateAction = {
	type: typeof UPDATE;
	payload: UpdateActionPayload;
};

export type RemoveAction = {
	type: typeof REMOVE;
	payload: { grouping: string; id: string };
};

export const reset = (payload: ResetActionPayload): ResetAction => ({
	type: RESET,
	payload,
});

export const clearAll = (payload: ClearAllActionPayload): ClearAllAction => ({
	type: CLEAR_ALL,
	payload,
});

export const add = (payload: AddActionPayload): AddAction => ({
	type: ADD,
	payload,
});

export const update = (payload: UpdateActionPayload): UpdateAction => ({
	type: UPDATE,
	payload,
});

export const remove = (payload: { grouping: string; id: string }): RemoveAction => ({
	type: REMOVE,
	payload,
});
