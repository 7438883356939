import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types';
import {
	GOAL_FILTER_ID,
	type GoalFilter,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { getIssueGoalARIs } from '../../issue-goals';
import { getFiltersViewSettings } from '../../view-settings';
import { applyFilter as applyAttributeFilter } from '../attribute-filter';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { GOAL_FILTER_ID } from '../../../state/domain/view-settings/filters/types';

export const applyFilter = (issue: Issue, filter: GoalFilter, optimizedMode: boolean): boolean =>
	applyAttributeFilter(issue, filter.value, 'goals', optimizedMode);

export const getAppliedGoalFilter = (state: State): GoalFilter =>
	getFiltersViewSettings(state)[GOAL_FILTER_ID] || {
		id: GOAL_FILTER_ID,
		value: [],
	};

export const getGoalFilter = createSelector(
	[getIssueGoalARIs, getAppliedGoalFilter],
	(issueGoalARIs: string[], pureFilter: GoalFilter): GoalFilter => {
		const { value = [] } = pureFilter;
		const filteredGoals = value.filter((goalARI: String) =>
			issueGoalARIs.some((issueGoalARI) => issueGoalARI === goalARI),
		);
		return {
			id: GOAL_FILTER_ID,
			value: filteredGoals,
		};
	},
);
