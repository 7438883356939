import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useCell } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/cell/index.tsx';
import {
	useColumnWidth,
	useColumnStickyOffset,
} from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/index.tsx';

interface Props {
	top: number;
}

/** Stripe background for column texts */
export const Stripe = ({ top }: Props) => {
	const [{ column }] = useCell();
	const [leftColumnOffset = 0] = useColumnStickyOffset({ column: column - 1, preview: true });
	const [leftColumnWidth = 0] = useColumnWidth({ column: column - 1, preview: true });
	const backgroundOffset = -(leftColumnOffset + leftColumnWidth + top) % 40;

	return (
		<Box
			xcss={containerStyles}
			style={{
				left: `${backgroundOffset}px`,
				backgroundImage: `repeating-linear-gradient(
    45deg,
    transparent,
    ${token('color.background.neutral', 'rgba(101, 84, 192, 0.1)')} 1px,
    ${token('color.background.neutral', 'rgba(101, 84, 192, 0.1)')} 16px,
    transparent 17px,
    transparent 28px
)`,
			}}
		/>
	);
};

const containerStyles = xcss({
	position: 'absolute',
	inset: 'space.0',
	backgroundColor: 'elevation.surface',
});
