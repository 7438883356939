import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { connect } from '@atlassian/jira-react-redux';
import { getErrors } from '../../query/errors';
import type { State } from '../../state/types';
import type { StateProps } from './types';
import View from './view';

const mapStateToProps = createStructuredSelector<State, StateProps>({ errors: getErrors });

export default connect(mapStateToProps, {})(View);
