import {
	clear,
	change,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/filters';
import { ISSUE_TYPE_KEY_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import type { IssueTypeKeyFilterValue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { DispatchProps } from './types';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	changeIssueTypeKeyFilter: (value: IssueTypeKeyFilterValue) => {
		dispatch(change({ id: ISSUE_TYPE_KEY_FILTER_ID, value }));
	},
	clearIssueTypeKeyFilter: () => {
		dispatch(clear(ISSUE_TYPE_KEY_FILTER_ID));
	},
});

export default mapDispatchToProps;
