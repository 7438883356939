import {
	SORT_DIRECTION,
	ISSUE_FIELD_TYPES,
	type Grouping,
	type SortDirection,
	type IssueFieldTypes,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import { ISSUE_FIELDS } from '../../issues/types';

export type SortingV0 = {
	field: string;
	type: IssueFieldTypes;
	direction: SortDirection;
	isCustomField?: boolean;
};
export type Sorting = SortingV0;

export type GroupingExpandedItem = {
	[key: string]: boolean;
};

export type VisualisationsStateV0 = {
	grouping: Grouping;
	sorting: SortingV0;
	expandedItems: Partial<Record<Grouping, GroupingExpandedItem>>;
	showSprints: boolean;
};

export type VisualisationsState = VisualisationsStateV0;

export const defaultSorting: SortingV0 = {
	field: ISSUE_FIELDS.RANK,
	type: ISSUE_FIELD_TYPES.STRING,
	direction: SORT_DIRECTION.ASCENDING,
};
