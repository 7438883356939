import React from 'react';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import { G300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	INLINE_DIALOG,
	fireUIAnalytics,
	ContextualAnalyticsData,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { Flag } from '../../../common/ui/flag';
import { useCttOnboardingTour } from '../../../controllers/ctt-onboarding';
import { messages } from './messages';

export const FinishFlag = () => {
	const { formatMessage } = useIntl();

	const [, { closeCttOnboarding, setCttTakingTour }] = useCttOnboardingTour();

	return (
		<ContextualAnalyticsData sourceName="topLevelPlanningFinishFlag" sourceType={INLINE_DIALOG}>
			<Flag
				isAutoDismissed
				icon={
					<SuccessIcon
						primaryColor={token('color.icon.success', G300)}
						label={formatMessage(messages.successLabel)}
					/>
				}
				onDismissed={() => {
					closeCttOnboarding({ silent: false });
				}}
				title={`🎉 ${formatMessage(messages.spotlightFinishedFlagTitle)} 🎉`}
				description={formatMessage(messages.spotlightFinishedFlagBodyPlans)}
				actions={[
					{
						content: formatMessage(messages.spotlightFinishedFlagTakeFullTour),
						onClick: (_, analyticsEvent) => {
							fireUIAnalytics(analyticsEvent, 'topLevelPlanningFinishFlagTakeFullTour');
							closeCttOnboarding({ silent: false });
							setCttTakingTour();
						},
					},
					{
						content: formatMessage(messages.spotlightFinishedFlagReadTheDocumentation),
						href: ' https://support.atlassian.com/jira-software-cloud/docs/get-started-with-advanced-roadmaps/',
						target: '_blank',
						onClick: (_, analyticsEvent) => {
							fireUIAnalytics(analyticsEvent, 'topLevelPlanningFinishFlagDocumentation');
							closeCttOnboarding({ silent: false });
						},
					},
				]}
			/>
			<FireScreenAnalytics />
		</ContextualAnalyticsData>
	);
};
