import {
	clear,
	change,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/filters';
import {
	type AttributeFilterValue,
	ASSIGNEE_FILTER_ID,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { DispatchProps } from './types';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	onRequestChange: (payload: AttributeFilterValue) =>
		dispatch(change({ id: ASSIGNEE_FILTER_ID, value: payload })),

	onRequestClear: () => dispatch(clear(ASSIGNEE_FILTER_ID)),
});

export default mapDispatchToProps;
