import { getAssociatedIssueIds } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-associated-issues';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

const querySelector = createStructuredSelector<State, StateProps>({
	associatedIssueIds: getAssociatedIssueIds,
});

export default querySelector;
