import {
	getSprints,
	getExternalSprintsById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import type { StateProps } from './types';

const querySelector = (state: State): StateProps => ({
	sprints: getSprints(state),
	externalSprintsById: getExternalSprintsById(state),
});

export default querySelector;
