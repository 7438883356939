import React, { Component } from 'react';
import * as R from 'ramda';
import AddIcon from '@atlaskit/icon/glyph/editor/add';
import { injectIntl, FormattedMessage } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { AkSelect } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import type { OptionType, ValueType } from '@atlassian/jira-portfolio-3-common/src/select/types';
import type { SelectOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/select-options/types';
import type { ColourSelect } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/colour-by/types';
import { getBody } from '@atlassian/jira-portfolio-3-portfolio/src/common/dom';
import { chain } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import ColourPicker from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colour-picker/view.tsx';
import { DEFAULT_COLOR } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colours';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import viewSettingsMessages from '../messages';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, State } from './types';

export const optionToReactSelectOptions = (
	values: string[],
	options: SelectOption[],
): OptionType[] =>
	values.map((value) => {
		const label = options.find((option) => option.id.toString() === value);
		return {
			value,
			label: label ? label.value : value,
		};
	});

const optionsToLabels = (options: OptionType[]): string[] => options.map((x) => x.value);

// eslint-disable-next-line jira/react/no-class-components
class ColourBySelect extends Component<Props, State> {
	state = {
		// "keySuffix" was added to force re-render when all labels are removed from any Select to remove its whole row.
		// Reason: to avoid regression when clearing some Select the last Select with the same colour is removed.
		// Also this implementation lets keep focus on Select while editing it from keyboard -
		// https://stash.atlassian.com/plugins/servlet/jira-integration/issues/JPOS-4624
		keySuffix: 0,
	};

	// label select must not show labels already picked for any colour
	getAvailableOptions = (): string[] => {
		const { selectOptionsFromIssues } = this.props;
		const usedOptions = new Set(chain((x) => x.options, this.getValues()));
		return selectOptionsFromIssues
			.filter((option) => !usedOptions.has(option))
			.map((option) => option.toString());
	};

	onLabelsChange = (index: number, options: ValueType) => {
		const { updateSelectColour, removeSelectColour } = this.props;
		if (R.isNil(options) || R.isEmpty(options)) {
			removeSelectColour({ index });
			this.setState((prevState) => ({ keySuffix: prevState.keySuffix + 1 }));
		} else {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			updateSelectColour({ index, options: optionsToLabels(options as OptionType[]) });
		}
	};

	onColourChange = (index: number) => (colour?: string | null) => {
		const { updateSelectColour, removeSelectColour } = this.props;
		if (colour) {
			updateSelectColour({ index, colour });
		} else {
			removeSelectColour({ index });
		}
	};

	renderRow = (colourSelect: ColourSelect, index: number) => {
		const { intl, onMenuToggle, selectOptions } = this.props;
		const { colour, options } = colourSelect;
		const availableOptions = this.getAvailableOptions();

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.row} key={`${index}-${colour || ''}-${this.state.keySuffix}`}>
				<ColourPicker colour={colour} onColourChange={this.onColourChange(index)} position="left" />
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.labelSelect}>
					<AkSelect
						classNamePrefix={`portfolio-3-portfolio_app-simple-plans_main_tabs_roadmap_view-settings_colour-by-select_select-select-${index}`}
						defaultValue={optionToReactSelectOptions(options, selectOptions)}
						isDisabled={!colour}
						isMulti
						menuPlacement="auto"
						menuPortalTarget={getBody()}
						// eslint-disable-next-line @typescript-eslint/no-shadow
						onChange={(options: ValueType) => this.onLabelsChange(index, options)}
						onMenuClose={() => {
							onMenuToggle(false);
						}}
						onMenuOpen={() => {
							onMenuToggle(true);
						}}
						options={optionToReactSelectOptions(availableOptions, selectOptions)}
						placeholder={intl.formatMessage(colour ? messages.findLabel : messages.selectLabel)}
						styles={{
							// eslint-disable-next-line @typescript-eslint/no-shadow
							menuPortal: (styles) => ({
								...styles,
								zIndex: 1000,
							}),
						}}
						aria-label={intl.formatMessage(messages.selectLabel)}
					/>
				</div>
			</div>
		);
	};

	renderAddLabel = () => {
		const { addSelectColour } = this.props;
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={`${styles.addLabelButton} ${styles.row}`}>
				<Button
					appearance="link"
					iconBefore={<AddIcon label="" />}
					onClick={(e) => {
						e.preventDefault();
						addSelectColour();
					}}
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-select.add-color-link"
				>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<span className={styles.addLabel}>
						<FormattedMessage {...messages.addLabel} />
					</span>
				</Button>
			</div>
		);
	};

	getValues = () => {
		const {
			colourByConfiguration: { colourSelects, colourByValue },
		} = this.props;
		return colourSelects[colourByValue] || [];
	};

	render() {
		const { intl } = this.props;
		const values = this.getValues();
		const isAddingNewLabelColour = values.some(({ colour }) => !colour);
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.list}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.row}>
					<Button
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={styles.allOtherIssuesButton}
						isDisabled
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						iconAfter={<div className={styles.swatch} style={{ backgroundColor: DEFAULT_COLOR }} />}
						ariaLabel={intl.formatMessage(viewSettingsMessages.allOtherIssuesButtonLabel)}
					/>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.allOtherIssues}>
						<FormattedMessage {...commonMessages.allOtherIssues} />
					</div>
				</div>
				{values.map(this.renderRow)}
				{isAddingNewLabelColour ? null : this.renderAddLabel()}
			</div>
		);
	}
}

export default injectIntl(ColourBySelect);
