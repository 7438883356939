import React, { type ComponentPropsWithoutRef } from 'react';
import RetryIcon from '@atlaskit/icon/glyph/retry';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';
import type { Props } from './types';

const ResetSortingOrder = ({ onClick }: Props) => {
	const { formatMessage } = useIntl();

	const onHandleClick: ComponentPropsWithoutRef<typeof Button>['onClick'] = (_, analyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'resetSortingOrder');
		onClick();
	};

	const resetSortingOrderFormatted = formatMessage(messages.resetSortingOrder);

	return (
		<Tooltip content={resetSortingOrderFormatted}>
			<Button
				appearance="subtle"
				spacing="none"
				onClick={onHandleClick}
				ariaLabel={resetSortingOrderFormatted}
			>
				<RetryIcon label="" />
			</Button>
		</Tooltip>
	);
};

export default ResetSortingOrder;
