import React, { memo } from 'react';
import { DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

const BulkActionsMenu = memo(
	({
		dropMenuItems,
		moreDropMenuItems,
	}: {
		dropMenuItems: React.JSX.Element[];
		moreDropMenuItems?: React.JSX.Element[];
	}) => {
		const { formatMessage } = useIntl();
		return (
			<div data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.bulk-actions-menu.menu-container">
				<DropdownItemGroup title={formatMessage(messages.setFieldGroupTitle)}>
					{dropMenuItems}
				</DropdownItemGroup>
				{moreDropMenuItems && (
					<DropdownItemGroup title={formatMessage(messages.moreActionsGroupTitle)}>
						{moreDropMenuItems}
					</DropdownItemGroup>
				)}
			</div>
		);
	},
);

export default BulkActionsMenu;
