import {
	ZOOM_LEVELS,
	NUM_OF_SCALABLE_COLUMNS,
	ESTIMATED_MIN_COLUMN_WIDTH_WEEKS,
	ESTIMATED_MIN_COLUMN_WIDTH_MONTHS,
	ESTIMATED_MIN_COLUMN_WIDTH_QUARTERS,
	ESTIMATED_MIN_COLUMN_WIDTH_YEARS,
} from '../constants';
import type { Duration, ZoomLevel } from '../types';

/**
 * Gets the container width in pixels.
 * The container moves horizontally in the viewport to simulate the scrolling behavior.
 */
export function getContainerWidth(zoomLevel: ZoomLevel) {
	switch (zoomLevel) {
		case ZOOM_LEVELS.WEEKS:
			return NUM_OF_SCALABLE_COLUMNS * ESTIMATED_MIN_COLUMN_WIDTH_WEEKS;
		case ZOOM_LEVELS.MONTHS:
			return NUM_OF_SCALABLE_COLUMNS * ESTIMATED_MIN_COLUMN_WIDTH_MONTHS;
		case ZOOM_LEVELS.QUARTERS:
			return NUM_OF_SCALABLE_COLUMNS * ESTIMATED_MIN_COLUMN_WIDTH_QUARTERS;
		case ZOOM_LEVELS.YEARS:
			return NUM_OF_SCALABLE_COLUMNS * ESTIMATED_MIN_COLUMN_WIDTH_YEARS;
		default:
			return NUM_OF_SCALABLE_COLUMNS * ESTIMATED_MIN_COLUMN_WIDTH_MONTHS;
	}
}

/**
 * Gets the timeline total duration that the container is wrapping.
 * The container moves horizontally in the viewport to simulate the scrolling behavior.
 */
export function getContainerDuration(zoomLevel: ZoomLevel): Duration {
	const msPerDay = 24 * 60 * 60 * 1e3;
	/* Needs to be the end of the last day. */
	const adjustment = -1;

	switch (zoomLevel) {
		case 'weeks':
			return NUM_OF_SCALABLE_COLUMNS * 7 * msPerDay + adjustment;
		case 'months':
			return NUM_OF_SCALABLE_COLUMNS * 30 * msPerDay + adjustment; /* approx 1 month. */
		case 'quarters':
			return NUM_OF_SCALABLE_COLUMNS * 91 * msPerDay + adjustment; /* approx 3 months. */
		case 'years':
			return NUM_OF_SCALABLE_COLUMNS * 365 * msPerDay + adjustment; /* approx 1 year. */
		default:
			// prettier-ignore
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return (zoomLevel as never); /* This magic will save the missing cases. */
	}
}

export function getZoomLevel(timeScaleSelection?: string | null): ZoomLevel | undefined {
	switch (timeScaleSelection) {
		case 'weeks':
		case 'months':
		case 'quarters':
		case 'years':
			return timeScaleSelection;
		default:
			return undefined;
	}
}
