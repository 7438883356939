import { useEffect } from 'react';
import { useAssociatedIssuesState } from '@atlassian/jira-portfolio-3-associated-issues/src/controllers/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-associated-issues/src/controllers/types.tsx';
import { setAssociatedIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/associated-issues/actions.tsx';
import { connect } from '@atlassian/jira-react-redux';

type Props = {
	setAssociatedIssues: (payload: State) => void;
};

export const AssociatedIssuesSyncWrapper = ({
	setAssociatedIssues: dispatchSetAssociatedIssues,
}: Props) => {
	const [state] = useAssociatedIssuesState();

	useEffect(() => {
		dispatchSetAssociatedIssues(state);
	}, [state, dispatchSetAssociatedIssues]);

	return null;
};

export default connect(null, { setAssociatedIssues })(AssociatedIssuesSyncWrapper);
