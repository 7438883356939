import {
	VIEW_MODES,
	type ViewMode,
} from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import {
	ADD_FIELDS,
	SCOPE,
	TIMELINE,
	EMPTY,
	SECTION_COLLAPSE_THRESHOLD,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/grid/constants';
import type { GridColumn } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/grid/types';
import {
	getDefaultWidth as getFieldColumnDefaultWidth,
	getMinWidth as getFieldColumnMinWidth,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/fields/utils';

export const getColumnDefaultWidths = (
	columns: GridColumn[],
	viewMode: ViewMode,
	hasScrollableTimeline: boolean,
): number[] =>
	columns.map((column) => {
		if (column.tag === SCOPE) {
			return 300;
		}

		if (column.tag === EMPTY) {
			return 136;
		}

		if (column.tag === ADD_FIELDS) {
			return 0;
		}

		if (column.tag === TIMELINE) {
			return hasScrollableTimeline ? 4e6 : SECTION_COLLAPSE_THRESHOLD;
		}

		return getFieldColumnDefaultWidth(column.payload.id, viewMode);
	});

export const getColumnMinWidths = (
	columns: GridColumn[],
	viewMode: ViewMode,
	hasScrollableTimeline: boolean,
	isExportMode: boolean,
): number[] =>
	columns.map((column, index) => {
		if (viewMode === VIEW_MODES.TIMELINE && columns.length === 3 && index === 1) {
			return SECTION_COLLAPSE_THRESHOLD;
		}

		if (column.tag === SCOPE) {
			return 300;
		}

		if (column.tag === EMPTY) {
			return 136;
		}

		if (column.tag === ADD_FIELDS) {
			return 136;
		}

		if (column.tag === TIMELINE) {
			if (isExportMode) {
				return 0;
			}

			return hasScrollableTimeline ? 4e6 : SECTION_COLLAPSE_THRESHOLD;
		}

		const isCollapsible = viewMode === VIEW_MODES.TIMELINE;

		return getFieldColumnMinWidth(column.payload.id, isCollapsible, viewMode);
	});
