import { combineReducers } from '@atlassian/jira-portfolio-3-portfolio/src/common/redux';
import AddDependency from './add-dependency/reducer';
import Filters from './filters/reducer';
import ReportState from './report-state/reducer';
import StagePosAndScale from './stage-position-scale/reducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default combineReducers<any, any>({
	AddDependency,
	Filters,
	ReportState,
	StagePosAndScale,
});
