import React, { Component } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Avatar from '@atlaskit/avatar';
import { DropdownItemGroup as DropMenuItemGroup } from '@atlaskit/dropdown-menu';
import { injectIntl, FormattedMessage } from '@atlassian/jira-intl';
import { DialogMenuContainer } from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
import Spinner from '@atlassian/jira-portfolio-3-common/src/spinner/index.tsx';
import { UNASSIGNED_GROUP } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope';
import type { GroupOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import SearchField from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field';
import MenuItem from '../../menu-item/view.tsx';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, State } from './types';

// eslint-disable-next-line jira/react/no-class-components
export class DialogContent extends Component<Props, State> {
	static defaultProps = { MenuItem, SearchField };

	state = {
		searchQuery: '',
	};

	filterGroupsWithSearchQuery = (group: GroupOption) => {
		const groupName = group.groupName.length ? group.groupName : UNASSIGNED_GROUP;

		return groupName.toLowerCase().includes(this.state.searchQuery.toLowerCase());
	};

	searchOptions = (searchQuery: string) => {
		const { onQueryChange } = this.props;
		this.setState(() => ({
			searchQuery,
		}));
		if (onQueryChange) {
			onQueryChange(searchQuery);
		}
	};

	render() {
		const { intl, groupOptions, onGroupClick, hierarchyItem, searchPlaceholder, isLoading } =
			this.props;
		const groupsToDisplay = groupOptions.filter(this.filterGroupsWithSearchQuery);

		return (
			<DialogMenuContainer>
				<SearchField
					placeholder={searchPlaceholder}
					searchQuery={this.state.searchQuery}
					onQueryChange={this.searchOptions}
				/>
				{isLoading === true ? (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={styles.spinner}>
						<Spinner size="medium" />
					</div>
				) : (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={styles['inline-dialog-child']}>
						{groupsToDisplay.length ? (
							<DropMenuItemGroup title={intl.formatMessage(messages.where)}>
								{groupsToDisplay.map((group, i) => {
									const {
										groupName,
										groupUrl,
										isDisabled,
										formattedTitle,
										group: groupKey,
										groupTooltip,
									} = group;
									return (
										<MenuItem
											menuItem={hierarchyItem}
											key={`${hierarchyItem.id}-${groupKey}-${i}`}
											isDisabled={isDisabled}
											onMenuItemClick={(analyticsEvent: UIAnalyticsEvent) =>
												onGroupClick(group, analyticsEvent)
											}
											tooltip={isDisabled === true ? groupTooltip : ''}
										>
											<div
												// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
												className={styles.option}
												data-testid={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.add-issue.nested-menu.dialog-content.${groupKey}`}
											>
												{isDefined(groupUrl) && (
													// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
													<div className={styles.avatar}>
														<Avatar size="xsmall" src={groupUrl} />
													</div>
												)}
												{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
												<div className={styles.label}>{formattedTitle || groupName}</div>
											</div>
										</MenuItem>
									);
								})}
							</DropMenuItemGroup>
						) : (
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							<div className={styles['no-matches']}>
								<FormattedMessage {...messages.noMatchFoundText} />
							</div>
						)}
					</div>
				)}
			</DialogMenuContainer>
		);
	}
}

export default injectIntl(DialogContent);
