import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types';
import type { Version } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/versions/types';
import {
	type ReleaseFilter,
	RELEASE_FILTER_ID,
	type PersistableReleaseFilter,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import type { CrossProjectReleaseFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { UNDEFINED_KEY } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import { getVersions } from '../../versions';
import { getFiltersViewSettings } from '../../view-settings';
import { applyFilter as applyAttributeFilter } from '../attribute-filter';
import { getCrossProjectReleaseFilter } from '../cross-project-release-filter';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { RELEASE_FILTER_ID } from '../../../state/domain/view-settings/filters/types';

export const NO_RELEASE_ID = UNDEFINED_KEY;

export const EMPTY_CPR_ID = 'EMPTY_CPR_ID' as const;

export const applyFilter = (
	issue: Issue,
	filter: ReleaseFilter,
	optimizedMode: boolean,
): boolean => {
	const { value, versionsFromCrossProjectReleaseFilterValue } = filter;
	const filterValue = isDefined(versionsFromCrossProjectReleaseFilterValue)
		? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			[...value, ...(versionsFromCrossProjectReleaseFilterValue as string[])]
		: value;
	return applyAttributeFilter(issue, filterValue, 'fixVersions', optimizedMode);
};

export const getReleaseFilterPure = (state: State): PersistableReleaseFilter =>
	getFiltersViewSettings(state)[RELEASE_FILTER_ID] || {
		id: RELEASE_FILTER_ID,
		value: [],
	};

export const getReleaseFilter = createSelector(
	[getVersions, getCrossProjectReleaseFilter, getReleaseFilterPure],
	(
		versions: Version[],
		crossProjectReleaseFilter: CrossProjectReleaseFilter,
		pureFilter: PersistableReleaseFilter,
	): ReleaseFilter => {
		const { value = [] } = pureFilter;
		const filteredVersions = value.filter(
			(versionId: string) =>
				versions.some(({ id }) => id === versionId) || versionId === NO_RELEASE_ID,
		);

		const { crossProjectVersionsById = {}, value: crossProjectReleaseFilterValue = [] } =
			crossProjectReleaseFilter;
		const versionsFromCrossProjectReleaseFilterValue: string[] =
			crossProjectReleaseFilterValue.reduce((acc: string[], cprId: string) => {
				const crossProjectVersion = crossProjectVersionsById[cprId];
				if (crossProjectVersion) {
					acc.push(...crossProjectVersion.versions);
				}
				return acc;
			}, []);

		if (
			crossProjectReleaseFilterValue.length !== 0 &&
			versionsFromCrossProjectReleaseFilterValue.length === 0
		) {
			versionsFromCrossProjectReleaseFilterValue.push(EMPTY_CPR_ID);
		}
		return {
			id: RELEASE_FILTER_ID,
			value: filteredVersions,
			versionsFromCrossProjectReleaseFilterValue,
		};
	},
);
