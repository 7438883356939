import {
	getCrossProjectVersions,
	getCrossProjectVersionsById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/cross-project-versions';
import { getFilters } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters';
import { getProjects } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects';
import { getVersionsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions';
import {
	CROSS_PROJECT_RELEASE_FILTER_ID,
	RELEASE_FILTER_ID,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import { sortAsLowerCaseByNameProp } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/composed';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';

const mapStateToProps = createSelector(
	[getProjects, getCrossProjectVersions, getCrossProjectVersionsById, getVersionsById, getFilters],
	(
		projects,
		crossProjectVersions,
		crossProjectVersionsById,
		versionsById,
		{
			[RELEASE_FILTER_ID]: { id, value },
			[CROSS_PROJECT_RELEASE_FILTER_ID]: { value: crossProjectReleaseFilterValue = [] },
		},
	) => ({
		id,
		value,
		crossProjectReleaseFilterValue,
		crossProjectVersions: sortAsLowerCaseByNameProp(crossProjectVersions),
		projects: sortAsLowerCaseByNameProp(projects),
		versionsById,
		crossProjectVersionsById,
	}),
);

export default mapStateToProps;
