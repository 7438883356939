import React, { forwardRef, useCallback } from 'react';
import Popup, { type PopupComponentProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { SuggestedChildIssue } from '@atlassian/jira-issue-fetch-services/src/services/issue-breakdown-data-fetcher/types';
import { useFetchSuggestedChildIssues } from '@atlassian/jira-issue-view-services/src/child-issues/use-fetch-suggested-child-issues';
import { useAiWorkBreakDownAnalytics } from '@atlassian/jira-portfolio-3-ai-work-breakdown/src/common/analytics/index.tsx';
import {
	steps,
	useIssueBreakdown,
} from '@atlassian/jira-portfolio-3-ai-work-breakdown/src/controllers/context.tsx';
import { IssueBreakdownDraftList } from '@atlassian/jira-portfolio-3-ai-work-breakdown/src/ui/issue-breakdown-draft-list';
import type { Props } from './types';

const CustomPopupContainer = forwardRef<HTMLDivElement, PopupComponentProps>(
	({ children, 'data-testid': testId, xcss: _xcss, ...props }, ref) => (
		// Note: this is the recommended way from AK documentation: https://atlassian.design/components/popup/examples#customization
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Box xcss={customPopupContainerStyles} testId={testId} {...props} ref={ref}>
			{children}
		</Box>
	),
);

const AiWorkBreakdownDialog = ({
	createSuggestedIssue,
	groupCombination,
	isOpen,
	issue,
	issueTypes,
	renderIssueSummary,
	startInlineCreate,
	toggleAiWorkBreakdownPopup,
}: Props) => {
	const [
		{ channelId, currentStep, isStreaming, promptValue },
		{
			clearError,
			resetIssueBreakdownState,
			setHeaderPromptValue,
			setIssueBreakdownStep,
			setPromptValue,
			updateStreamingStatus,
		},
	] = useIssueBreakdown();
	const { fireTrack } = useAiWorkBreakDownAnalytics({ channelId });

	const onFetchSuggestedIssuesSuccess = useCallback(() => {
		setIssueBreakdownStep(steps.draftListStep);
		setPromptValue(''); // clear the prompt if fetching was successful
	}, [setIssueBreakdownStep, setPromptValue]);

	const onFetchSuggestedIssuesFailure = useCallback(() => {
		setIssueBreakdownStep(steps.errorStep);
	}, [setIssueBreakdownStep]);

	const { isFetchingSuggestedChildIssues } = useFetchSuggestedChildIssues(
		onFetchSuggestedIssuesSuccess,
		onFetchSuggestedIssuesFailure,
	);

	/**
	 * Reset and close issue breakdown whenever it is cancelled.
	 */
	const cancelIssueBreakdown = useCallback(() => {
		fireTrack('aiInteraction dismissed', 'cancelIssueBreakdown', { isStreaming });
		resetIssueBreakdownState();
		toggleAiWorkBreakdownPopup({ isOpen: false });
	}, [fireTrack, isStreaming, toggleAiWorkBreakdownPopup, resetIssueBreakdownState]);

	/**
	 * Reset and close issue breakdown whenever the popup is closed.
	 */
	const handleCloseAiWorkBreakdownPopup = () => {
		fireTrack('aiInteraction dismissed', 'closeIssueBreakdownPopup', { isStreaming });
		resetIssueBreakdownState();
		toggleAiWorkBreakdownPopup({ isOpen: false });
	};

	const onSubmitFetchSuggestedChildIssues = useCallback(() => {
		if (isFetchingSuggestedChildIssues) {
			return;
		}

		// Only change the header prompt if there's a prompt value
		if (promptValue) {
			fireTrack('aiResult actioned', 'promptValueChanged', { aiResultAction: 'promptRefined' });
			setHeaderPromptValue(promptValue);
		}

		updateStreamingStatus(true);
		clearError();
		currentStep !== steps.draftListStep && setIssueBreakdownStep(steps.draftListStep);
	}, [
		clearError,
		currentStep,
		fireTrack,
		isFetchingSuggestedChildIssues,
		promptValue,
		setHeaderPromptValue,
		setIssueBreakdownStep,
		updateStreamingStatus,
	]);

	const onCreateSuggestedChildIssues = useCallback(
		(issues: Array<SuggestedChildIssue>) => {
			issues.forEach((suggestedIssue) => {
				const issueTypeId = Number(suggestedIssue.issueTypeId);

				startInlineCreate({
					group: issue.group,
					groupCombination,
					hierarchyLevel: issue.level - 1,
					issueTypeId,
					parentId: issue.id,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					projectId: issue.project.id,
					siblingId: null,
					source: 'INLINE',
				});

				createSuggestedIssue(suggestedIssue.summary, suggestedIssue.description);
			});
		},
		[createSuggestedIssue, groupCombination, issue, startInlineCreate],
	);

	const childIssueTypes = issueTypes
		.filter(({ level }) => level === issue.level - 1)
		.map(({ id, iconUrl, name }) => ({
			description: '',
			hasEpicLinkField: false,
			hasRequiredField: false,
			hasWorkflowValidators: false,
			iconUrl,
			id: String(id),
			name,
			requiredFields: [],
		}));

	const parentIssueDetails = {
		id: issue.id,
		issueKey: issue.issueKey,
		level: issue.level,
		projectKey: issue.project.key,
	};

	return (
		<Popup
			autoFocus={false}
			content={() => (
				<IssueBreakdownDraftList
					childIssueTypes={childIssueTypes}
					onChangePrompt={setPromptValue}
					onCreate={onCreateSuggestedChildIssues}
					onDiscard={cancelIssueBreakdown}
					onRetry={onSubmitFetchSuggestedChildIssues}
					onSubmit={onSubmitFetchSuggestedChildIssues}
					parentIssueDetails={parentIssueDetails}
				/>
			)}
			isOpen={isOpen}
			onClose={handleCloseAiWorkBreakdownPopup}
			placement="bottom-end"
			popupComponent={CustomPopupContainer}
			trigger={renderIssueSummary}
		/>
	);
};

export default AiWorkBreakdownDialog;

const customPopupContainerStyles = xcss({
	borderRadius: token('border.radius', '6px'),
});
