import {
	commitTimelinePreview,
	hoverTimelineIssue,
	moveTimelineIssue,
	resizeTimelineIssue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/timeline-preview';
import {
	suspend,
	resume,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/enhancers/suspender';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { DispatchProps, OwnProps } from './types';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps, OwnProps> = (
	dispatch,
	ownProps,
) => ({
	commitTimelinePreview: () => {
		dispatch(commitTimelinePreview());
	},
	moveTimelineIssue: (delta: number, targetStart?: number | null, targetEnd?: number | null) => {
		dispatch(suspend());
		dispatch(hoverTimelineIssue(ownProps.issue.id, targetStart, targetEnd));
		dispatch(moveTimelineIssue(delta, targetStart, targetEnd));
		dispatch(resume());
	},
	resizeTimelineIssue: (baselineStart?: number | null, baselineEnd?: number | null) => {
		dispatch(suspend());
		dispatch(hoverTimelineIssue(ownProps.issue.id, baselineStart, baselineEnd));
		dispatch(resizeTimelineIssue(baselineStart, baselineEnd));
		dispatch(resume());
	},
});

export default mapDispatchToProps;
