import { getFiscalMonth } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/system';
import { getTimelineRange } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/timeline';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { OwnProps, StateProps } from './types';

export default createStructuredSelector<State, OwnProps, StateProps>({
	timelineRange: getTimelineRange,
	fiscalMonth: getFiscalMonth,
});
