import * as R from 'ramda';
import {
	sort,
	transformPersons,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/assignees/utils';
import type { Person } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/assignees/types.tsx';
import type { GroupedUserOptions } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/user-picker/types';
import messages from './messages';
import type { Props } from './types';

// NOTE: To satisfy Flow we have to indicate that void is a possible return value as Flow
//       cannot guarantee that we will find the desired item in the array no matter
//       how confident we are that it will be there.
export const findAssigneeItem = (list: Person[], name: string): Person | null | undefined =>
	list.find((assignee) => assignee.jiraUser.accountId === name);

export const getUserTitle = (persons: Person[], userId: string): string => {
	const targetPerson = persons.find((person) => person.jiraUser.accountId === userId);
	if (targetPerson) {
		return targetPerson.jiraUser.title;
	}
	return userId;
};

export const getGroupedAssignees = ({
	assigneeList,
	currentAssignees,
}: {
	assigneeList: Props['assigneeList'];
	currentAssignees: Props['assigneeList'];
}) => {
	const unassigned = R.pipe(R.propEq('personId', 'unassigned'), R.not);

	const allAssigneeOptions = R.pipe(
		R.differenceWith((p1: Person, p2: Person) => p1.personId === p2.personId, assigneeList),
		R.filter(unassigned),
		sort,
		transformPersons,
	)(currentAssignees);

	const currentlyAssignedOptions = R.pipe(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		R.filter<Person, any>(unassigned),
		sort,
		transformPersons,
	)(currentAssignees);

	const unassignedUser = R.find(R.propEq('personId', 'unassigned'), assigneeList);

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return [
		{
			options: (unassignedUser && transformPersons([unassignedUser])) || [],
		},
		{
			label: messages.currentlyAssignedGroupTitle,
			options: currentlyAssignedOptions,
		},
		{
			label: messages.allAssigneesGroupTitle,
			options: allAssigneeOptions,
		},
	] as GroupedUserOptions[];
};
