import {
	getAllStatusKeyOfCategoryChecked,
	getStatusKeyFilterOptionsByCategory,
	getStatusKeyFilterValue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/status-key-filter';
import { getCategoryNameById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-status-categories';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { StateProps } from './types';

const mapStateToProps: MapStateToProps<StateProps> = (state) => ({
	statusOptionsByCategory: getStatusKeyFilterOptionsByCategory(state),
	categoryNameById: getCategoryNameById(state),
	value: getStatusKeyFilterValue(state),
	allCategoryCheckedState: getAllStatusKeyOfCategoryChecked(state),
});

export default mapStateToProps;
