import type { Action } from '@atlassian/react-sweet-state';
import type { DragTarget, DropTarget, State, Callbacks } from '../types';

export const actions = {
	initialize:
		(callbacks: Callbacks): Action<State> =>
		({ setState }) => {
			setState({
				...callbacks,
			});
		},
	onDragStart:
		(dragTarget: DragTarget): Action<State> =>
		({ getState, setState }) => {
			const { onDragStart } = getState();

			setState({
				dragTarget,
			});

			onDragStart?.(dragTarget);
		},
	onDrag:
		(dropTarget?: DropTarget): Action<State> =>
		({ getState, setState }) => {
			const { dragTarget, onDrag, getIndicators } = getState();

			setState({
				dropTarget,
			});

			if (!dragTarget) throw Error('No drag target found.');

			onDrag?.(dragTarget, dropTarget);

			setState({
				indicators: getIndicators?.(dragTarget, dropTarget),
			});
		},
	onDropTargetChange:
		(dropTarget?: DropTarget): Action<State> =>
		({ getState, setState }) => {
			const { dragTarget, getIndicators } = getState();

			setState({
				dropTarget,
			});

			if (!dragTarget) throw Error('No drag target found.');

			setState({
				indicators: getIndicators?.(dragTarget, dropTarget),
			});
		},
	onDrop:
		(dragTarget: DragTarget, dropTarget?: DropTarget): Action<State> =>
		({ getState, setState }) => {
			const { onDrop } = getState();

			setState({
				dragTarget: undefined,
				dropTarget: undefined,
				indicators: undefined,
			});

			if (!dropTarget) return;

			onDrop?.(dragTarget, dropTarget);
		},
};
