import {
	TOGGLE_SCENARIOS_DIALOG_VISIBILITY,
	type ToggleDialogVisibilityAction,
	INITIATE_REQUEST,
	type InitiateRequestAction,
	COMPLETE_REQUEST,
	type CompleteRequestAction,
} from './actions';
import type { Scenarios } from './types';

const initialState = {
	isModalDialogOpen: false,
	isProcessingRequest: false,
} as const;

type Action = ToggleDialogVisibilityAction | InitiateRequestAction | CompleteRequestAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: Scenarios = initialState, action: Action) => {
	switch (action.type) {
		case TOGGLE_SCENARIOS_DIALOG_VISIBILITY:
			return {
				...state,
				isModalDialogOpen: action.payload,
			};
		case INITIATE_REQUEST:
			return {
				...state,
				isProcessingRequest: true,
			};
		case COMPLETE_REQUEST:
			return {
				...state,
				isProcessingRequest: false,
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
