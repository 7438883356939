import { combineReducers } from '@atlassian/jira-portfolio-3-portfolio/src/common/redux';
import Header from './header/reducer';
import InlineCreate from './inline-create/reducer';
import Issues from './issues/reducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default combineReducers<any, any>({
	Issues,
	InlineCreate,
	Header,
});
