import { getIssueStatusById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-statuses';
import { getProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

const querySelector = createStructuredSelector<State, StateProps>({
	issueStatuses: getIssueStatusById,
	projectsById: getProjectsById,
});

export default querySelector;
