import type { GroupOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/types';
import type { Sprint } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/sprints/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import {
	SPRINT_STATES,
	GROUPING,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import type { StateProps, Groups, SourceToGroupOptionTuple, SourceId } from './types';

export const getGroupOptionsFromSprints = ({
	groups,
	sprints,
	sprintsByIdMap,
	externalSprintsById,
}: {
	groups: Groups;
	sprints: StateProps['sprints'];
	sprintsByIdMap: StateProps['sprintsByIdMap'];
	externalSprintsById: StateProps['externalSprintsById'];
}): SourceToGroupOptionTuple[] => {
	const isSprintExternal = (sprintId: string) => isDefined(externalSprintsById[sprintId]);

	const createGroupOptionFromSprint = (sprint: Sprint): GroupOption => ({
		group: `sprint-${sprint.id}`,
		groupCombination: { sprint: sprint.id },
		groupName: sprint.title,
		grouping: GROUPING.SPRINT,
		startInlineCreateInEmptyGroup: true,
		isDisabled: isSprintExternal(sprint.id),
	});

	const sourceIdToGroupOptionsMap = new Map<SourceId, SourceToGroupOptionTuple>();
	// We use a set to keep track of which releases we have already handled, because
	// we are handling a map of issueSourceId to Option, neither of which are easy to filter through
	const processedSprintIds: Set<string> = new Set();

	for (const group of groups) {
		const { scope, ...groupOption } = group;
		if (
			isDefined(groupOption.groupCombination) &&
			isDefined(groupOption.groupCombination.sprint) &&
			!processedSprintIds.has(groupOption.groupCombination.sprint) &&
			sprintsByIdMap[groupOption.groupCombination.sprint].state !== SPRINT_STATES.CLOSED // Don't render completed sprints in the options as you can't modify closed sprints
		) {
			const sprintId = groupOption.groupCombination.sprint;
			processedSprintIds.add(groupOption.groupCombination.sprint);

			const sprint = sprintsByIdMap[sprintId];

			if (sprint === undefined) {
				throw Error('Illegal state - sprint was not found in map of ids to sprints');
			}

			// External sprint does not have an issue source
			// We assign all external sprints an undefined issue source, so they can be
			// rendered at the bottom of the list as a list of external sprints
			const issueSourceId =
				sprint.issueSources[0] !== undefined ? sprint.issueSources[0] : undefined;

			const [, sprintOptions] = sourceIdToGroupOptionsMap.get(issueSourceId) ?? [undefined, []];

			const sprintOption = {
				...groupOption,
				isDisabled: !!(
					isDefined(groupOption.groupCombination) &&
					isDefined(groupOption.groupCombination.sprint) &&
					isSprintExternal(groupOption.groupCombination.sprint)
				),
			};

			sourceIdToGroupOptionsMap.set(issueSourceId, [
				issueSourceId,
				[...sprintOptions, sprintOption],
			]);
		}
	}

	// Create option for sprints that aren't currently assigned to any issues
	// Which therefore currently do not have a group.
	for (const sprint of sprints) {
		if (!processedSprintIds.has(sprint.id) && sprint.state !== SPRINT_STATES.CLOSED) {
			const option = createGroupOptionFromSprint(sprint);
			const issueSourceId = sprint.issueSources !== undefined ? sprint.issueSources[0] : undefined;

			const [, sprintOptions] = sourceIdToGroupOptionsMap.get(issueSourceId) ?? [undefined, []];

			const sprintOption = {
				...option,
				isDisabled: !!isSprintExternal(sprint.id),
			};

			sourceIdToGroupOptionsMap.set(issueSourceId, [
				issueSourceId,
				[...sprintOptions, sprintOption],
			]);
		}
	}

	return [...sourceIdToGroupOptionsMap.values()];
};
