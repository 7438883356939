import React from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import RoadmapAsync from '../main/tabs/roadmap/async';
import { PlanTable } from '../main/tabs/roadmap/table';
import { TableSkeleton } from '../main/tabs/roadmap/table/skeleton';
import UseLocalStorageCommand from '../main/tabs/roadmap/use-local-storage-command';
import type { Props } from './types';

// When cleaning up FF com.atlassian.rm.jpo.transposition we can remove this component entirely
const RoadmapWrapper = ({ isLoading, ...props }: Props) => {
	if (ff('com.atlassian.rm.jpo.transposition')) {
		return isLoading ? (
			<TableSkeleton />
		) : (
			<>
				<PlanTable />
				<UseLocalStorageCommand />
			</>
		);
	}

	return <RoadmapAsync {...props} />;
};

export default RoadmapWrapper;
