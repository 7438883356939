import React from 'react';
import * as R from 'ramda';
import { fg } from '@atlassian/jira-feature-gating';
import { CustomFieldTypes } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import Cell from '../../column/cell/view';
import DatePickerRenderer from './renderers/date-picker';
import DisabledCustomFieldRender from './renderers/disabled-custom-field';
import MultiSelect, { LabelRenderer } from './renderers/multi-select';
import NumberTextRender from './renderers/number-text';
import SingleSelectRenderer from './renderers/single-select';
import TextRendererDI from './renderers/text';
import UrlRenderer from './renderers/url';
import UserPickerRenderer from './renderers/user-picker';
import type { Props } from './types';
import { shouldCustomFieldBeDisabledForScopedIssue } from './utils';

export const CustomField = (props: Props) => {
	const {
		attribute,
		customFieldValue,
		isReadOnly,
		isScrolling,
		issue,
		showOptimizations = false,
		TextRenderer = TextRendererDI,
	} = props;

	if (!customFieldValue) return null;

	if (shouldCustomFieldBeDisabledForScopedIssue(customFieldValue, issue.type, issue.project)) {
		// this is just a placeholder component as design is not finalised yet
		// currently return back a disabled TextField with N/A as placeholder message
		return <DisabledCustomFieldRender customField={customFieldValue} {...props} />;
	}
	const customFieldType = customFieldValue.type.key;

	const content = (() => {
		switch (customFieldType) {
			case CustomFieldTypes.TextField:
				return <TextRenderer attribute={attribute} issue={issue} isReadOnly={isReadOnly} />;
			case CustomFieldTypes.SingleSelect:
			case CustomFieldTypes.RadioButtons:
				return <SingleSelectRenderer {...props} />;
			case CustomFieldTypes.Labels:
				return <LabelRenderer {...props} useValueAsLabel />;
			case CustomFieldTypes.MultiSelect:
			case CustomFieldTypes.MultiCheckboxes: {
				return <MultiSelect {...props} />;
			}
			case CustomFieldTypes.NumberField:
				return <NumberTextRender attribute={attribute} issue={issue} isReadOnly={isReadOnly} />;
			case CustomFieldTypes.DatePicker:
				return <DatePickerRenderer {...props} />;
			case CustomFieldTypes.UserPicker:
				return (
					<UserPickerRenderer
						attribute={attribute}
						isReadOnly={isReadOnly}
						issue={issue}
						isScrolling={isScrolling}
					/>
				);
			case CustomFieldTypes.Url:
				return <UrlRenderer {...props} />;
			case CustomFieldTypes.AssetObject:
				// This is a permanent feature flag and its feature only enabled for our internal hello customers
				if (fg('asset-custom-field-internal-support')) {
					return <MultiSelect {...props} />;
				}
				return null;
			default:
				return null;
		}
	})();

	const isCellDirty = () => {
		const fieldId = attribute;
		if (issue.originals && issue.originals.customFields) {
			return issue.originals.customFields && R.has(fieldId, issue.originals.customFields);
		}
		return false;
	};

	return (
		<Cell
			attribute="customFields"
			issue={issue}
			isScrolling={false}
			showOptimizations={showOptimizations}
			isCellDirty={isCellDirty}
		>
			{content}
		</Cell>
	);
};

export default CustomField;
