import React from 'react';
import ShowFullHierarchyOption from './show-full-hierarchy';

const FilterOptions = () => (
	<>
		<ShowFullHierarchyOption />
	</>
);

export default FilterOptions;
