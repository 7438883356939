import {
	change,
	clear,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/filters';
import { TEAM_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import type { AttributeFilterValue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { DispatchProps } from './types';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	changeTeamFilter: (payload: AttributeFilterValue) => {
		dispatch(change({ id: TEAM_FILTER_ID, value: payload }));
	},
	clearTeamFilter: () => {
		dispatch(clear(TEAM_FILTER_ID));
	},
});

export default mapDispatchToProps;
