import { connect } from '@atlassian/jira-react-redux';
import mapDispatchToProps from './command';
import mapStateToProps from './query';
import SelectableIssue from './view';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { TargetTypes } from '../../../../../../../state/ui/main/tabs/roadmap/scope/issues/selectable-issue/actions';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { TargetType } from '../../../../../../../state/ui/main/tabs/roadmap/scope/issues/selectable-issue/types.tsx';

export default connect(mapStateToProps, mapDispatchToProps)(SelectableIssue);
