import { isSameDay, startOfDay, subDays } from 'date-fns';
import {
	ONE_DAY,
	dateDiffFromUTC,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import { isExportMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app';
import {
	getTimelineRange,
	getTodayDate,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/timeline';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { TimelineRange } from '@atlassian/jira-portfolio-3-portfolio/src/common/types';

export const getStaticAxisPositionPure = (timelineRange: TimelineRange) => {
	const { start, end } = timelineRange;

	const startDate = new Date(start);
	const endDate = new Date(end);
	const todayDate = getTodayDate();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let staticAxisPosition: Record<string, any> = {};
	// "today" might not necessarily be in the selected custom date range and should not be rendered if that's the case
	if (todayDate.getTime() < startDate.getTime() || todayDate.getTime() > endDate.getTime()) {
		return staticAxisPosition;
	}

	const totalDays = (end - start) / ONE_DAY;

	let startTime = start;
	const widthUnit = 100 / totalDays;
	let width = 0;
	for (let day = 0; day < Math.round(totalDays); day++) {
		if (isSameDay(startOfDay(startTime), startOfDay(subDays(todayDate, dateDiffFromUTC())))) {
			staticAxisPosition = {
				offset: width,
				widthPercentage: widthUnit,
			};
			break;
		}
		startTime += ONE_DAY;
		width += widthUnit;
	}
	return staticAxisPosition;
};

// eslint-disable-next-line @typescript-eslint/no-shadow
export default createSelector([getTimelineRange, isExportMode], (timelineRange, isExportMode) => ({
	isExportMode,
	staticAxisPosition: getStaticAxisPositionPure(timelineRange),
}));
