import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import {
	DialogMenuItem,
	DialogMenuContainer,
} from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import { GOAL_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import SearchField from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field';
import { GoalIcon } from '@atlassian/jira-software-goals/src/ui/goal/icon/index.tsx';
import { GoalTracker } from '@atlassian/jira-software-goals/src/ui/goal/tracker/index.tsx';
import { FILTER_MAX_WIDTH, FILTER_WIDTH } from '../common';
import ClearFilterButton from '../common/clear-filter/index.tsx';
import FilterText from '../common/filter-text/index.tsx';
import NoMatchFound from '../common/no-match-text/index.tsx';
import TriggerButton from '../common/trigger-button/index.tsx';
import filterMessages from '../messages';
import messages from './messages';
import type { Props } from './types';

const GoalFilter = ({
	clearGoalsFilter,
	value,
	searchQuery,
	onOpenChange,
	isOpen,
	onQueryChange,
	changeGoalsFilter,
	issueGoalARIs,
	definedGoalsByARI,
}: Props) => {
	const { formatMessage } = useIntl();
	const isGoalsSelected = !isEmpty(definedGoalsByARI) && value.length > 0;

	const onGoalClick = (id: string): void => {
		if (isGoalSelected(id)) {
			changeGoalsFilter(value.filter((goalId) => goalId !== id));
		} else {
			changeGoalsFilter([...value, id]);
		}
	};

	const constructFilterText = () => {
		const goalNames = value.flatMap((goalARI) => {
			const definedGoal = definedGoalsByARI[goalARI];
			return definedGoal.name;
		});
		const sortedGoalNames = goalNames.sort();
		return sortedGoalNames.join(', ');
	};

	const renderFilterText = () => {
		if (isGoalsSelected) {
			const filterText = constructFilterText();
			return <FilterText text={filterText} />;
		}
		return <FormattedMessage {...messages.emptyPlaceholder} />;
	};

	const ariaText = () => {
		const filterText = isGoalsSelected
			? constructFilterText()
			: formatMessage(messages.emptyPlaceholder);

		return `${formatMessage(filterMessages[GOAL_FILTER_ID])}, ${filterText} ${formatMessage(
			filterMessages.selected,
		)}`;
	};

	const isGoalSelected = (goalARI: string) => value.includes(goalARI);

	const filterGoalsWithSearchQuery = (goalARI: string) => {
		const definedGoal = definedGoalsByARI[goalARI];
		// goalARI could refer to a goal that has been unlinked
		return definedGoal ? definedGoal.name.toLowerCase().includes(searchQuery.toLowerCase()) : false;
	};
	const filteredGoalARIs = issueGoalARIs.filter(filterGoalsWithSearchQuery);
	return (
		<InlineDialog
			noPaddings
			maxWidth={FILTER_MAX_WIDTH}
			minWidth={FILTER_WIDTH}
			onClose={onOpenChange}
			isOpen={isOpen}
			content={
				<DialogMenuContainer>
					<ClearFilterButton isVisible={isGoalsSelected} onClearClick={clearGoalsFilter} />
					<SearchField
						placeholder={formatMessage(messages.searchGoalsPlaceholder)}
						searchQuery={searchQuery}
						onQueryChange={onQueryChange}
					/>
					{filteredGoalARIs.map((goalARI) => {
						const { name, state } = definedGoalsByARI[goalARI];
						return (
							<DialogMenuItem key={goalARI}>
								<Checkbox
									id={`${goalARI}`}
									isChecked={isGoalSelected(goalARI)}
									onChange={() => onGoalClick(goalARI)}
									label={
										state !== undefined ? (
											<Box xcss={filterItemContentStyles}>
												<GoalIcon state={state.value} />
												<Inline xcss={goalNameWrapperStyles}>{name}</Inline>
												<GoalTracker label={state.label} value={state.value} score={state.score} />
											</Box>
										) : (
											name
										)
									}
								/>
							</DialogMenuItem>
						);
					})}
					{filteredGoalARIs.length === 0 && <NoMatchFound />}
				</DialogMenuContainer>
			}
			testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.goal-filter"
		>
			<TriggerButton
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				triggerButtonText={renderFilterText()}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.goal-filter.trigger-btn"
				ariaLabel={ariaText()}
			/>
		</InlineDialog>
	);
};

const filterItemContentStyles = xcss({
	lineHeight: '24px',
	display: 'flex',
	flexDirection: 'row',
	maxWidth: '390px',
});

const goalNameWrapperStyles = xcss({
	display: 'inline',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	marginLeft: 'space.050',
	marginRight: 'space.050',
});

export default GoalFilter;
