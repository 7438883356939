import type { ShareAs } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';

export const OPEN_SHARE_REPORT_DIALOG =
	'state.ui.top.title-bar.share-report.OPEN_SHARE_REPORT_DIALOG' as const;

export const CLOSE_SHARE_REPORT_DIALOG =
	'state.ui.top.title-bar.share-report.CLOSE_SHARE_REPORT_DIALOG' as const;

export const LOADING_SHARE_REPORT_URL =
	'state.ui.top.title-bar.share-report.LOADING_SHARE_REPORT_URL' as const;

export const UPDATE_SHARE_REPORT_URL =
	'state.ui.top.title-bar.share-report.UPDATE_SHARE_REPORT_URL' as const;

export const UPDATE_SHARE_AS = 'state.ui.top.title-bar.share-report.UPDATE_SHARE_AS' as const;

/** OPEN_SHARE_REPORT_DIALOG */
export type OpenDialogAction = {
	type: typeof OPEN_SHARE_REPORT_DIALOG;
};

/** CLOSE_SHARE_REPORT_DIALOG */
export type CloseDialogAction = {
	type: typeof CLOSE_SHARE_REPORT_DIALOG;
};

/** LOADING_SHARE_REPORT_URL */
export type LoadingShareReportUrlActionPayload = {
	isLoading: boolean;
};

export type LoadingShareReportUrlAction = {
	type: typeof LOADING_SHARE_REPORT_URL;
	payload: LoadingShareReportUrlActionPayload;
};

export const setLoadingShareReport = (isLoading: boolean) => ({
	type: LOADING_SHARE_REPORT_URL,
	payload: { isLoading },
});

/** UPDATE_SHARE_REPORT_URL */
export type UpdateShareReportUrlActionPayload = {
	url: string;
};

export type UpdateShareReportUrlAction = {
	type: typeof UPDATE_SHARE_REPORT_URL;
	payload: UpdateShareReportUrlActionPayload;
};

export const updateShareReportUrl = (url: string) => ({
	type: UPDATE_SHARE_REPORT_URL,
	payload: { url },
});

export type UpdateShareAsActionPayload = {
	shareAs: ShareAs;
};

export type UpdateShareAsAction = {
	type: typeof UPDATE_SHARE_AS;
	payload: UpdateShareAsActionPayload;
};

export const updateShareAs = (shareAs: string) => ({
	type: UPDATE_SHARE_AS,
	payload: { shareAs },
});
