import {
	selectAllChanges,
	deselectAllChanges,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/title-bar/update-jira/actions.tsx';
import type { SelectedChanges } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/title-bar/update-jira/types.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { DispatchProps } from './types';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	selectAll: (changes: Partial<SelectedChanges>) => {
		dispatch(selectAllChanges(changes));
	},
	deselectAll: () => {
		dispatch(deselectAllChanges());
	},
});

export default mapDispatchToProps;
