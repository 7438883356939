import React, { useState } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { FormattedMessage } from '@atlassian/jira-intl';
import { FeedbackButtonLazyComponent } from '@atlassian/jira-portfolio-3-feedback-collector/src/ui/async.tsx';
import { OnboardingHeaderModeUpdater } from '@atlassian/jira-portfolio-3-onboarding/src/controllers/spotlights';
import PageHeader from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/page-header';
import AutoSchedulerSwitch from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/page-header/autoscheduler-switch';
import HeaderToggle from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/page-header/header-toggle';
import type { HeaderMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/page-header/header-toggle/types';
import Toolbar from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/page-header/toolbar/index.tsx';
import Optimize from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/title-bar/optimize';
import ShareReport from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/title-bar/share-report';
import UpdateJira from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/title-bar/update-jira';
import { TIMELINE_SHARE_TYPE } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import messages from './messages';

export const HeaderBar = () => {
	const [headerMode, setHeaderMode] = useState<HeaderMode>('visible');

	return (
		<>
			{headerMode !== 'hidden' && (
				<HeaderContent>
					{headerMode !== 'toolbar' && (
						<PageHeader
							title={<FormattedMessage {...messages.timeline} />}
							actions={
								<>
									<FeedbackButtonLazyComponent />
									<ShareReport confluenceMacroType={TIMELINE_SHARE_TYPE} />
									<AutoSchedulerSwitch>{(enabled) => enabled && <Optimize />}</AutoSchedulerSwitch>
									<UpdateJira />
								</>
							}
						/>
					)}
					<Toolbar headerMode={headerMode} />
				</HeaderContent>
			)}
			<HeaderToggle mode={headerMode} setMode={setHeaderMode} />
			<OnboardingHeaderModeUpdater headerMode={headerMode} setHeaderMode={setHeaderMode} />
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderContent = styled.div({
	paddingTop: token('space.300', '24px'),
	paddingRight: token('space.300', '24px'),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		marginTop: 0,
	},
});
