import { useContext } from 'react';
import { getOutgoingLinks } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-links';
import { getDependencySettingsInfo } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/system';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { connect } from '@atlassian/jira-react-redux';
import mapDispatchToProps from './command';
import type { StateProps } from './types';
import { context } from './utils';
import View from './view';

export const useDepLinesDrag = () => useContext(context);

const mapStateToProps = createStructuredSelector<State, StateProps>({
	outgoingLinks: getOutgoingLinks,
	dependencySettings: getDependencySettingsInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
