import pick from 'lodash/fp/pick';
import type { Action } from '@atlassian/react-sweet-state';
import { type State, type ScrollEventHandler, DOWNWARD, UPWARD } from './types';

export const actions = {
	setRect:
		(rect: Pick<State, 'top' | 'left' | 'width' | 'height'>): Action<State> =>
		({ setState }) =>
			setState(rect),

	scrollTo:
		({
			x,
			y,
			smooth,
			passive = false,
		}: {
			x?: number;
			y?: number;
			smooth?: boolean;
			/** Determines whether it should mutate DOM directly or not. */
			passive?: boolean;
		}): Action<State> =>
		({ setState, getState }) => {
			const { scrollY, scrollYDirection, scrollTo, scrollEventHandlers } = getState();

			if (!passive) {
				scrollTo({ x, y, smooth });
			}

			const nextScrollYDirection = (() => {
				if (y === undefined) {
					return undefined;
				}

				if (y > scrollY) {
					return DOWNWARD;
				}

				if (y < scrollY) {
					return UPWARD;
				}

				return scrollYDirection;
			})();

			const newState = {
				...(x !== undefined && { scrollX: x }),
				...(y !== undefined && { scrollY: y }),
				...(nextScrollYDirection !== undefined && { scrollYDirection: nextScrollYDirection }),
			};

			setState(newState);
			scrollEventHandlers.forEach((callback) =>
				callback(
					pick(['scrollX', 'scrollY'], {
						...getState(),
						...newState,
					}),
				),
			);
		},
	addScrollHandler:
		(callback: ScrollEventHandler): Action<State> =>
		({ getState, setState }) => {
			setState({
				scrollEventHandlers: getState().scrollEventHandlers.concat(callback),
			});
		},
	removeScrollHandler:
		(callback: ScrollEventHandler): Action<State> =>
		({ getState, setState }) => {
			setState({
				scrollEventHandlers: getState().scrollEventHandlers.filter(
					(eventHandler) => eventHandler !== callback,
				),
			});
		},
	updateIsScrollingY:
		(isScrollingY: boolean): Action<State> =>
		({ setState }) =>
			setState({ isScrollingY }),
};
