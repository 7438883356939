import { TABLE_ISSUE } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table';
import type { LastSelectedIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/scope/issues/selectable-issue/types.tsx';
import type { Issues, Item } from '../types';

export function getId(item: Item): string | null | undefined {
	if (item.tag !== TABLE_ISSUE) {
		return null;
	}

	return item.value.id;
}

const getGroup = (item: Item): string | null | undefined => item.group;

export function getIssuesInRange({
	issues,
	lastSelected,
	issueId,
	group,
}: {
	issues: Issues;
	lastSelected: LastSelectedIssue;
	issueId: string;
	group: string;
}): Issues {
	const lastSelectedIndex = issues.findIndex((issue) =>
		!getGroup(issue)
			? getId(issue) === lastSelected.id
			: lastSelected.group === getGroup(issue) && getId(issue) === lastSelected.id,
	);
	const thisSelectedIndex = issues.findIndex((issue) =>
		!getGroup(issue)
			? getId(issue) === issueId
			: group === getGroup(issue) && getId(issue) === issueId,
	);

	const isSelectingForwards: boolean = thisSelectedIndex > lastSelectedIndex;
	const start: number = isSelectingForwards ? lastSelectedIndex : thisSelectedIndex;
	const end: number = isSelectingForwards ? thisSelectedIndex : lastSelectedIndex;

	const inBetween = issues.slice(start, end + 1);

	if (!isSelectingForwards) {
		inBetween.reverse();
	}

	return inBetween;
}
