import React, { useMemo } from 'react';
import { useScrollYDirection, useViewport } from '../../../controllers/container';
import { VirtualizedProvider } from '../../../controllers/virtualized';
import type { Props } from './types';
import { getExtractedRanges, getRanges, useScrollOffset } from './utils';

/** Provider virtualized rows and the placeholder spaces */
export const Virtualizer = ({
	rowConfigs,
	rangeExtractor = ({ above, within, below }) => [...above, ...within, ...below],
	scrollThreshold,
	overscan,
	children,
}: Props) => {
	const [{ height }] = useViewport();
	const [scrollYDirection] = useScrollYDirection();
	const scrollHeight = useMemo(
		// eslint-disable-next-line @typescript-eslint/no-shadow
		() => rowConfigs.reduce((acc, { height }) => acc + height, 0),
		[rowConfigs],
	);

	const scrollOffset = useScrollOffset(height, scrollHeight, scrollThreshold);
	const overscanAbove = scrollYDirection === 'upward' ? overscan : 0;
	const overscanBelow = scrollYDirection === 'downward' ? overscan : 0;

	const [above, within, below] = getRanges(
		height,
		scrollOffset,
		(index) => rowConfigs[index]?.height,
		overscanAbove,
		overscanBelow,
	);

	const unextracted = [...above, ...within, ...below];
	const virtualized = getExtractedRanges(above, within, below, rangeExtractor);

	/** The indexes of the additional sticky rows */
	const additionalSticky = virtualized.slice(
		0,
		Math.max(virtualized.indexOf(unextracted[0]?.index ?? 0), 0),
	);

	const placeholder = {
		top:
			(unextracted.at(0)?.top ?? 0) -
			additionalSticky.reduce((acc, index) => acc + (rowConfigs[index]?.height ?? 0), 0),
		bottom:
			Math.max(height, scrollHeight) -
			((unextracted.at(-1)?.top ?? 0) + (unextracted.at(-1)?.height ?? 0)),
	};

	return <VirtualizedProvider value={virtualized}>{children(placeholder)}</VirtualizedProvider>;
};
