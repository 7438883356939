import { getCustomFieldsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields';
import { getIssueTypesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types';
import { getSelectedIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues';
import { getDateConfiguration } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan';
import { getProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects';
import { getChildrenByParent } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/issues-tree.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { Props, StateProps } from './types';

export default createStructuredSelector<State, Props, StateProps>({
	childrenByParent: getChildrenByParent,
	customFieldsById: getCustomFieldsById,
	dateConfiguration: getDateConfiguration,
	issueTypesById: getIssueTypesById,
	projectsById: getProjectsById,
	selectedIssues: getSelectedIssues,
});
