import { getCustomFields } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields';
import { isAtlasConnectInstalled } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/system';
import { getVisualisationGrouping } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { Props, StateProps } from './types';

export const mapStateToProps = createStructuredSelector<State, Props, StateProps>({
	customFields: getCustomFields,
	grouping: getVisualisationGrouping,
	isAtlasConnectInstalled,
});
