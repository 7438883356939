import React from 'react';
import { injectIntl } from '@atlassian/jira-intl';
import { useDateFormatter } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import { DATE_METHODS } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/types.tsx';
import { getEndDateMethod } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/utils.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import commonMessages from '../../messages';
import type { Props } from './types';

function ReleaseDate(props: Props) {
	const { intl, end, emptyMessage } = props;
	const { formatTimestamp } = useDateFormatter();

	if (end === undefined) {
		return <span>{emptyMessage}</span>;
	}

	const { method } = getEndDateMethod(end);

	if (method === DATE_METHODS.FIXED_DATE && isDefined(end)) {
		return <span>{formatTimestamp(end)}</span>;
	}

	return <span>{intl.formatMessage(commonMessages.afterAllIssuesAreCompleted)}</span>;
}

export default injectIntl(ReleaseDate);
