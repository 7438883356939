import { SET_RENAME_SAVING, type SetRenameSavingAction } from './row/actions';
import type { Views } from './types';

type Action = SetRenameSavingAction;
const initialState: Views = { renameSaving: false };

export default function reducer(state: Views = initialState, action: Action): Views {
	switch (action.type) {
		case SET_RENAME_SAVING:
			return {
				...state,
				renameSaving: action.payload,
			};

		default:
			return state;
	}
}
