import type { DependencySettingsState } from './types';

export const CHANGE_DISPLAY = 'state.domain.view-settings.dependency-settings.DISPLAY' as const;

export type ChangeDisplayPayload = {
	display: DependencySettingsState['display'];
};

export type ChangeDisplayAction = {
	type: typeof CHANGE_DISPLAY;
	payload: ChangeDisplayPayload;
};

export const changeDisplay = (payload: ChangeDisplayPayload): ChangeDisplayAction => ({
	type: CHANGE_DISPLAY,
	payload,
});
