import type { Effect } from 'redux-saga';
import { saveAs } from 'file-saver';
import * as R from 'ramda';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { ff } from '@atlassian/jira-feature-flagging';
import { formatDateUTC } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import { indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { DefaultDateFormat } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import { getAssigneesById } from '../../query/assignees';
import { getEstimateFromHistoryIssues } from '../../query/breakdown';
import { getComponentsById } from '../../query/components';
import {
	getCustomFields,
	getSelectOptionsById,
	getUserPickerOptions,
} from '../../query/custom-fields';
import { getEstimateRollupValue, getEstimateValue } from '../../query/estimation';
import { getScopeItemsForExport } from '../../query/export';
import { getIssuePriorities } from '../../query/issue-priorities';
import { getIssueStatusById } from '../../query/issue-statuses';
import { getIssueTypesById } from '../../query/issue-types';
import { getDescendantIdsByParent, getHistoryIssues } from '../../query/issues';
import { getDateConfiguration, getPlan } from '../../query/plan';
import { getProjectsById } from '../../query/projects';
import { getIssueMapById, getIssues } from '../../query/raw-issues/index.tsx';
import { getRollupMap } from '../../query/raw-issues/issues-tree';
import { getReportersById } from '../../query/reporters';
import { getExternalSprintsById, getSprintByIdMap } from '../../query/sprints';
import {
	getSystemInfo,
	isAtlasConnectInstalled as getIsAtlasConnectInstalled,
} from '../../query/system';
import { getAdditionalTeamsById, getTeamsById } from '../../query/teams';
import { getTodayDate } from '../../query/timeline';
import { getVersions } from '../../query/versions';
import { getShowRolledUpOthers, getShowRolledUpDates } from '../../query/view-settings';
import type { CustomField } from '../../state/domain/custom-fields/types.tsx';
import type { DateConfiguration } from '../../state/domain/plan/types';
import type { ScopeIssue } from '../../state/domain/scope/types.tsx';
import { getHistoricalDoneIssues } from '../issue';
import { getGoalValues } from './goal/utils';
import { getIdeaValues } from './idea/utils';
import type { CsvIntlMessages, AdditionalData } from './types';
import {
	getExportCsvFileName,
	getIssueAssigneeName,
	getIssueReporterName,
	getIssueCountProgressBreakdown,
	getIssueCustomFieldValue,
	getIssueEstimateProgressBreakdown,
	getIssueInferredDates,
	getIssueKey,
	getIssueParentSummary,
	getIssuePriorityName,
	getIssueProjectName,
	getIssueRollUpSprintName,
	getIssueRollupTeamNames,
	getIssueReleaseName,
	getIssueSprintName,
	getIssueStatusName,
	getIssueTeamName,
	getIssueTypeName,
	joinIssueComponents,
	joinIssueLabels,
	prepareCSVData,
} from './utils';

export const CLICK_EXPORT_TO_CSV = 'command.export-roadmap.CLICK_EXPORT_TO_CSV' as const;

export const CLICK_EXPORT_TO_CSV_ANALYTICS =
	'command.export-roadmap.CLICK_EXPORT_TO_CSV_ANALYTICS' as const;

export const PLAN_EXPORTED_ANALYTICS = 'command.export-roadmap.PLAN_EXPORTED_ANALYTICS' as const;

export const planExportedAnalytics = (payload: Attributes) => ({
	type: PLAN_EXPORTED_ANALYTICS,
	payload,
});

export const EXPORT_EVENT_TYPE = {
	PNG: '.png', // https://hello.atlassian.net/wiki/spaces/DQ/pages/1021070478/List+of+product+entity+actions#exported
	CSV: '.csv',
	LINK: 'Link',
	EMBED: 'Embed',
	CONFLUENCE: 'Confluence',
	DEPENDENCY_REPORT_CONFLUENCE: 'DependencyReportConfluence',
} as const;

export const getExportCsvScopeItems = (
	csvIntlMessages: CsvIntlMessages,
	additionalData?: AdditionalData,
) => ({
	type: CLICK_EXPORT_TO_CSV,
	csvIntlMessages,
	additionalData,
});

type ExportCsvScopeItemsAction = {
	type: typeof CLICK_EXPORT_TO_CSV;
	csvIntlMessages: CsvIntlMessages;
	additionalData?: AdditionalData;
};

/**
 * This function generates the CSV file and triggers the download of the file.
 */
export function* doGetExportCsvScopeItems({
	csvIntlMessages,
	additionalData: { goalsByARI, associatedIssues } = { goalsByARI: {}, associatedIssues: {} }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: ExportCsvScopeItemsAction): Generator<Effect, void, any> {
	const assigneesById = yield select(getAssigneesById);
	const reportersById = yield select(getReportersById);
	const componentsById = yield select(getComponentsById);
	const allCustomFields: CustomField[] = yield select(getCustomFields);
	const dateConfiguration: DateConfiguration = yield select(getDateConfiguration);
	const estimateFromHistoryIssues = yield select(getEstimateFromHistoryIssues);
	const externalSprintsById = yield select(getExternalSprintsById);
	const externalTeamsById = yield select(getAdditionalTeamsById);
	const issueIdToDescendantsIdMap = yield select(getDescendantIdsByParent);
	const issueMapById = yield select(getIssueMapById);
	const issuePrioritiesById = yield select(getIssuePriorities);
	const issueStatusesById = yield select(getIssueStatusById);
	const issues = yield select(getIssues);
	const issueTypesById = yield select(getIssueTypesById);
	const plan = yield select(getPlan);
	const projectsById = yield select(getProjectsById);
	const rollUpMap = yield select(getRollupMap);
	const scopeItemsForExport: ScopeIssue[] = yield select(getScopeItemsForExport);
	const showRolledUpDates = yield select(getShowRolledUpDates);
	const showRolledUpOthers = yield select(getShowRolledUpOthers);
	const selectOptionsById = yield select(getSelectOptionsById);
	const sprintsById = yield select(getSprintByIdMap);
	const systemInfo = yield select(getSystemInfo);
	const teamsById = yield select(getTeamsById);
	const userPickerOptions = yield select(getUserPickerOptions);
	const isAtlasConnectInstalled = yield select(getIsAtlasConnectInstalled);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let userPickerOptionsById: Record<string, any> = {};
	if (userPickerOptions?.userList) {
		userPickerOptionsById = indexBy(R.prop('value'), userPickerOptions.userList);
	}
	const versions = yield select(getVersions);

	yield call(getHistoricalDoneIssues);
	const historyIssues = yield select(getHistoryIssues);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const scopeItems: Record<string, any>[] = scopeItemsForExport.map((issue) => {
		const {
			assignee,
			reporter,
			components,
			dueDate,
			fixVersions,
			id,
			labels,
			parent,
			priority,
			project,
			status,
			summary,
			targetEnd,
			targetStart,
			team,
			type,
			customFields,
			goals,
			associatedIssueIds,
		} = issue;
		// standard fields data
		const dataRecordStandardFields: Record<string, string | number | null> = {
			assignee: assignee ? getIssueAssigneeName(assignee, assigneesById) : '',
			reporter: reporter ? getIssueReporterName(reporter, reportersById) : '',
			components:
				components && components.length ? joinIssueComponents(components, componentsById) : '',
			dueDate: dueDate ? formatDateUTC(dueDate, DefaultDateFormat) : '',
			estimate: getEstimateValue(plan.planningUnit, issue, systemInfo.jiraHoursPerDay) || '',
			key: getIssueKey(issue, projectsById) || '',
			labels: labels ? joinIssueLabels(labels) : '',
			parent: parent ? getIssueParentSummary(parent, issueMapById) : '',
			priority: priority ? getIssuePriorityName(priority, issuePrioritiesById) : '',
			project: project ? getIssueProjectName(project, projectsById) : '',
			release: fixVersions ? getIssueReleaseName(fixVersions, versions) : '',
			sprint: getIssueSprintName(issue, sprintsById, externalSprintsById, csvIntlMessages) || '',
			status: status ? getIssueStatusName(status, issueStatusesById) : '',
			summary: summary || '',
			targetEnd: targetEnd ? formatDateUTC(targetEnd, DefaultDateFormat) : '',
			targetStart: targetStart ? formatDateUTC(targetStart, DefaultDateFormat) : '',
			team: team ? getIssueTeamName(team, teamsById, externalTeamsById, csvIntlMessages) : '',
			type: type ? getIssueTypeName(type, issueTypesById) : '',
		};

		// rolled-up / inferred data for date fields
		let dataRecordRolledUpOrInferredDateFields: Record<string, string> = {};

		// if the setting "Configuration > Use sprint dates when issues don't have start and end dates" is enabled OR
		// if view settings > Roll-up > Dates is enabled
		if (plan.issueInferredDateSelection || showRolledUpDates) {
			dataRecordRolledUpOrInferredDateFields = getIssueInferredDates(issue, dateConfiguration);
		}

		// rolled-up data for other standard fields
		let dataRecordRollupOtherFields: Record<string, string | number | null> = {};

		if (showRolledUpOthers) {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const { sprint, estimate, release, team } = rollUpMap[id];

			dataRecordRollupOtherFields = {
				sprintRollUp: sprint
					? getIssueRollUpSprintName(
							sprint,
							sprintsById,
							externalSprintsById,
							csvIntlMessages.labels.completed,
						)
					: '',
				estimateRollUp:
					getEstimateRollupValue(estimate, plan.planningUnit, systemInfo.jiraHoursPerDay) || '',
				releaseRollUp: release ? getIssueReleaseName(release, versions) : '',
				teamRollUp: team
					? getIssueRollupTeamNames(team, teamsById, externalTeamsById, csvIntlMessages)
					: '',
			};
		}

		// issue progress estimate breakdown
		const dataRecordProgressEstimateFields = getIssueEstimateProgressBreakdown(
			issue,
			issueIdToDescendantsIdMap,
			issueStatusesById,
			issueMapById,
			plan.planningUnit,
			systemInfo.jiraHoursPerDay,
			estimateFromHistoryIssues,
		);

		// issue progress count breakdown
		const dataRecordProgressCountFields = getIssueCountProgressBreakdown(
			issue,
			issues,
			issueStatusesById,
			issueMapById,
			historyIssues,
		);

		// custom fields - ordered the same way as they appear in the Custom fields management page
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const dataRecordCustomFields: Record<number, any> =
			allCustomFields && allCustomFields.length && customFields
				? // eslint-disable-next-line @typescript-eslint/no-explicit-any
					allCustomFields.reduce<Record<string, any>>((accumulator, customField) => {
						accumulator[customField.id] =
							getIssueCustomFieldValue(
								customFields,
								customField,
								selectOptionsById,
								userPickerOptionsById,
							) || '';
						return accumulator;
					}, {})
				: {};

		return {
			...dataRecordStandardFields,
			...dataRecordProgressEstimateFields,
			...dataRecordProgressCountFields,
			...dataRecordCustomFields,
			...dataRecordRolledUpOrInferredDateFields,
			...dataRecordRollupOtherFields,
			...(isAtlasConnectInstalled ? getGoalValues(goals, goalsByARI) : {}),
			...(ff('polaris-arj-ideas')
				? getIdeaValues(associatedIssueIds || [], associatedIssues || {})
				: {}),
		};
	});

	// triggering analytics event
	yield put({
		type: CLICK_EXPORT_TO_CSV_ANALYTICS,
		payload: { issueExportCount: scopeItems && scopeItems.length ? scopeItems.length : 0 },
	});

	// transforming data into CSV format
	const csvData = new Blob(
		[
			prepareCSVData(
				allCustomFields,
				plan.planningUnit,
				csvIntlMessages,
				showRolledUpOthers,
				showRolledUpDates,
				dateConfiguration,
				scopeItems,
				isAtlasConnectInstalled,
			),
		],
		{
			type: 'text/csv;charset=utf-8;',
		},
	);

	// triggering the download of the CSV file
	const exportName = csvIntlMessages.exportName;
	saveAs(csvData, getExportCsvFileName(exportName, getTodayDate()));

	// triggering plan exported analytics event
	yield put(planExportedAnalytics({ exportFormat: EXPORT_EVENT_TYPE.CSV }));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchGetExportCsvScopeItems(): Generator<Effect, void, any> {
	yield takeEvery(CLICK_EXPORT_TO_CSV, doGetExportCsvScopeItems);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, void, any> {
	yield fork(watchGetExportCsvScopeItems);
}
