import type { ReleaseStatus } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import { RELEASE_STATUSES } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ReleaseStatus, ReleaseStatusValue } from '../../../../common/api/types';

export const offTrack: ReleaseStatus = {
	id: RELEASE_STATUSES.OFFTRACK,
	name: 'Off track',
};

export const onTrack: ReleaseStatus = {
	id: RELEASE_STATUSES.ONTRACK,
	name: 'On track',
};
