import React, { useCallback, useState } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import {
	SECTION_COLLAPSE_THRESHOLD,
	COLUMN_COLLAPSE_THRESHOLD,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/grid/constants';
import { useCell } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/cell/index.tsx';
import { useViewport } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/container/index.tsx';
import {
	useColumnWidth,
	useColumnStickyOffset,
} from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/index.tsx';
import { Stripe } from './stripe';
import type { Props } from './types';

const COLUMN_BORDER_WIDTH = 1;

export const ColumnText = ({
	title,
	column,
	icon,
	useColumnToggle,
	appearance = 'field',
	hasStripe,
}: Props) => {
	const [viewport] = useViewport();
	const [columnWidth = 0] = useColumnWidth({ column, preview: true });
	const [hovered, setHovered] = useState(false);
	const [focused, setFocused] = useState(false);
	const handleHoverIn = useCallback(() => setHovered(true), []);
	const handleHoverOut = useCallback(() => setHovered(false), []);
	const handleFocus = useCallback(() => setFocused(true), []);
	const handleBlur = useCallback(() => setFocused(false), []);
	const toggle = useColumnToggle();
	const expand = useCallback(() => toggle(true), [toggle]);

	const [top, containerWidth, textHeight, paddingTop] = (() => {
		switch (appearance) {
			case 'section':
				return [0, SECTION_COLLAPSE_THRESHOLD, SECTION_COLLAPSE_THRESHOLD, 0];
			case 'field':
			default:
				return ['100%', columnWidth, COLUMN_COLLAPSE_THRESHOLD, 90];
		}
	})();

	return (
		<Box
			role="button"
			xcss={wrapperStyles}
			onMouseMove={handleHoverIn}
			onMouseLeave={handleHoverOut}
			onFocus={handleFocus}
			onBlur={handleBlur}
			onClick={expand}
			tabIndex={0}
			style={{
				top,
				height: containerWidth - COLUMN_BORDER_WIDTH /* to not cover the column right border */,

				width: viewport.height - paddingTop,
				transform: `rotate(90deg) translateX(${-(containerWidth - COLUMN_BORDER_WIDTH)}px)`,
			}}
		>
			<Box
				xcss={blanketStyles}
				style={{
					height: textHeight - COLUMN_BORDER_WIDTH /* to not cover the column right border */,
				}}
			/>
			{hasStripe && <Stripe top={paddingTop} />}
			{(hovered || focused) && <Box xcss={hoveredStyles} />}
			<Box
				xcss={innerStyles}
				style={{
					height: textHeight - COLUMN_BORDER_WIDTH /* to not cover the column right border */,
				}}
			>
				{title}
				{icon}
			</Box>
		</Box>
	);
};

export const TimelineColumnText = (props: Props) => {
	const [{ column }] = useCell();
	const [leftColumnOffset = 0] = useColumnStickyOffset({ column: column - 1, preview: true });
	const [leftColumnWidth = 0] = useColumnWidth({ column: column - 1, preview: true });

	return (
		<Box xcss={timelineWrapperStyles} style={{ left: leftColumnOffset + leftColumnWidth }}>
			<ColumnText {...props} />
		</Box>
	);
};

const timelineWrapperStyles = xcss({
	position: 'sticky',
	width: '32px',
});

const wrapperStyles = xcss({
	position: 'absolute',
	overflow: 'hidden',
	transformOrigin: 'bottom left',
	cursor: 'pointer',
	outline: 'none',
	backgroundColor: 'elevation.surface',
});

const hoveredStyles = xcss({
	position: 'absolute',
	inset: 'space.0',
	backgroundColor: 'color.background.neutral.subtle.hovered',
});

const blanketStyles = xcss({
	position: 'absolute',
	left: 'space.200',
	right: 'space.0',
	bottom: 'space.0',
	display: 'flex',
	pointerEvents: 'none',
	whiteSpace: 'nowrap',
	backgroundColor: 'elevation.surface',
});

const innerStyles = xcss({
	position: 'absolute',
	left: 'space.200',
	right: 'space.0',
	bottom: 'space.0',
	display: 'flex',
	alignItems: 'center',
	textTransform: 'uppercase',
	font: token('font.body.small'),
	fontWeight: 'bold',
	color: 'color.text.subtle',
	pointerEvents: 'none',
	whiteSpace: 'nowrap',

	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
});
