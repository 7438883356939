import React, { Component as ReactComponent } from 'react';
import type { Component } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import Tags from '@atlassian/jira-portfolio-3-portfolio/src/common/view/tags';
import type { Props } from './types';

// eslint-disable-next-line jira/react/no-class-components
export default class Components extends ReactComponent<Props> {
	getComponents = () => {
		const { componentDetails, components, project } = this.props;
		if (!components || !project) {
			return [];
		}
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return componentDetails[project.toString()].filter((component) =>
			components.includes(component.id),
		) as Component[];
	};

	render() {
		const title = this.getComponents()
			.map((component) => component.name)
			.join(', ');

		return (
			<Tags
				tooltip={title}
				tags={this.getComponents().map((component) => component.name)}
				isRemoved={!this.props.isAdded}
			/>
		);
	}
}
