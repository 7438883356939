import type { ColumnHeading } from '../types';

const NEW_LINE = '\r\n';

/**
 * Build the CSV string.
 * Loops through each {@param scopeItems} (row),
 * then loops through each {@param headers} (column) and extracts the corresponding issue field value.
 * Note: Even if an issue has a defined field value, it will only appear in the CSV is the corresponding header exists.
 *
 * This function will try to cast every field value to a string,
 * unless it is an array, where it would join with the delimiter ",".
 * Any raw quote characters '"' will be escaped because the final string is wrapped with in quotes '"'.
 *
 * @param headers Array of headers. Each header corresponds to a column in the CSV.
 * @param scopeItems Array of issue objects. Each issue corresponds to a row in the CSV.
 *      Each issue object is essentially a map of field keys to field values.
 *      The field key must match a header, otherwise it will be ignored.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const buildCSV = (headers: ColumnHeading[], scopeItems: Record<string, any>[]) => {
	const visibleHeaders = headers.filter(({ show }) => show);

	const lines = [];

	// header
	lines.push(`${visibleHeaders.map(({ label }) => label).join(',')}`);
	// body
	const headerKeys: ColumnHeading['value'][] = visibleHeaders.map(({ value }) => value);
	scopeItems.forEach((scopeItem) => {
		const line = headerKeys
			.map((headerKey) => {
				const scopeItemValue = scopeItem[headerKey];
				if (scopeItemValue === undefined || scopeItemValue === null) {
					return '';
				}
				if (Array.isArray(scopeItemValue)) {
					return `"${scopeItemValue.join(',').replaceAll('"', '""')}"`;
				}
				return `"${`${scopeItemValue}`.replaceAll('"', '""')}"`;
			})
			.join(',');
		lines.push(line);
	});

	return lines.join(NEW_LINE);
};
