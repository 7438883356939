import type { Dispatch } from 'redux';
import { fetchHistoricalDoneIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue';
import {
	HIDE_COLUMN,
	SHOW_COLUMN,
	move,
	AFM_HIDE_FIELD,
	AFM_DRAG_FIELD,
	AFM_ADD_FIELD,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/field-columns/actions';
import type { MapDispatchToProps } from './types';

const dispatchMethods: MapDispatchToProps = (dispatch: Dispatch) => ({
	hideColumn: (id: string) => {
		dispatch({
			type: HIDE_COLUMN,
			id,
			meta: { source: AFM_HIDE_FIELD },
		});
	},
	showColumn: (id: string) => {
		dispatch({
			type: SHOW_COLUMN,
			id,
			meta: { source: AFM_ADD_FIELD },
		});

		if (id === 'breakdown' || id === 'progressByEstimation') {
			dispatch(fetchHistoricalDoneIssues());
		}
	},
	moveColumn: (columnToMove: string, moveToTheIndexOf: string) => {
		dispatch(
			move(
				{
					columnToMove,
					moveToTheIndexOf,
				},
				{ source: AFM_DRAG_FIELD },
			),
		);
	},
});

export default dispatchMethods;
