import {
	WARNING_FILTER_ID,
	type WarningFilter,
	type PersistableWarningFilter,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { getFiltersViewSettings } from '../../view-settings';
import { getIssueIdsWithWarnings } from '../../warnings';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { WarningFilter } from '../../../state/domain/view-settings/filters/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { WARNING_FILTER_ID } from '../../../state/domain/view-settings/filters/types';

export const applyFilter = (): boolean => true;

export const getWarningFilterPure = (state: State): PersistableWarningFilter =>
	getFiltersViewSettings(state)[WARNING_FILTER_ID] || {
		id: WARNING_FILTER_ID,
		issueIdsWithWarnings: [],
		value: false,
	};

export const getWarningFilter = createSelector(
	[getIssueIdsWithWarnings],
	(issueIdsWithWarnings: string[]): WarningFilter => {
		return {
			id: WARNING_FILTER_ID,
			value: false,
			issueIdsWithWarnings,
		};
	},
);
