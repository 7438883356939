import { combineReducers } from '@atlassian/jira-portfolio-3-portfolio/src/common/redux';
import AlignDatesDialog from './align-dates-dialog/reducer';
import DeleteReleaseDialog from './delete-release-dialog/reducer';
import ReleaseDialog from './release-dialog/reducer';
import ReleasesTable from './releases-table/reducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default combineReducers<any, any>({
	ReleaseDialog,
	ReleasesTable,
	AlignDatesDialog,
	DeleteReleaseDialog,
});
