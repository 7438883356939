import * as R from 'ramda';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import type { Timestamp } from '@atlassian/jira-portfolio-3-portfolio/src/common/types';

export const getForcedGradients = (
	{
		baselineStart,
		baselineEnd,
	}: {
		baselineStart: Timestamp | null | undefined;
		baselineEnd: Timestamp | null | undefined;
	},
	children?: Issue[],
) =>
	R.reduce(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow
		(acc: R.Evolvable<R.Evolver<any>>, { baselineStart, baselineEnd }) =>
			R.evolve(
				{
					earliestStart: (earliestStart) =>
						Math.min(
							earliestStart,
							R.defaultTo(Infinity, baselineStart),
							R.defaultTo(Infinity, baselineEnd),
						),
					earliestEnd: (earliestEnd) =>
						isDefined(baselineStart)
							? earliestEnd
							: Math.min(earliestEnd, R.defaultTo(Infinity, baselineEnd)),
					latestStart: (latestStart) =>
						isDefined(baselineEnd)
							? latestStart
							: Math.max(latestStart, R.defaultTo(-Infinity, baselineStart)),
					latestEnd: (latestEnd) =>
						Math.max(
							latestEnd,
							R.defaultTo(-Infinity, baselineStart),
							R.defaultTo(-Infinity, baselineEnd),
						),
				},
				acc,
			),
		{
			earliestStart: R.defaultTo(Infinity, baselineStart),
			earliestEnd: R.defaultTo(Infinity, baselineStart),
			latestStart: R.defaultTo(-Infinity, baselineEnd),
			latestEnd: R.defaultTo(-Infinity, baselineEnd),
			left: !isDefined(baselineStart),
			right: !isDefined(baselineEnd),
		},
		children || [],
	);
