/* eslint-disable jira/react/no-style-attribute */
import React, { type Ref, forwardRef } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { CellProvider } from '../../controllers/cell';
import { useColSpan, useColumnStickyOffset } from '../../controllers/grid';
import { useRow } from '../../controllers/row';
import type { Props } from './types';

export const Cell = forwardRef(
	(
		{ column, colSpan, style, top, xcss: xcssStyles, ...restProps }: Props,
		ref: Ref<HTMLTableCellElement>,
	) => {
		const { row, height } = useRow();
		const [left] = useColumnStickyOffset({ column, preview: true });
		const [colSpanForZeroWidthColumns] = useColSpan({ row, column, preview: true });

		const isSticky = top !== undefined || left !== undefined;

		if (colSpanForZeroWidthColumns === 0) {
			return null;
		}

		const finalColSpan = colSpan ?? colSpanForZeroWidthColumns;

		return (
			<CellProvider row={row} column={column} colSpan={finalColSpan}>
				<Box
					as="td"
					ref={ref}
					colSpan={finalColSpan}
					xcss={[
						cellStyles,
						isSticky && stickyStyles,
						...(Array.isArray(xcssStyles) ? xcssStyles : [xcssStyles]),
					]}
					style={{
						top,
						left,
						height,
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						...style,
					}}
					// This spreading is to allow the consumer to customize the tr attributes
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...restProps}
				/>
			</CellProvider>
		);
	},
);

const cellStyles = xcss({
	margin: 'space.0',
	padding: 'space.0',
	position: 'relative',
});

const stickyStyles = xcss({
	position: 'sticky',
});
