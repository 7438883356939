import { GROUPING } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import {
	RESET,
	type ResetAction,
	CHANGE_GROUP,
	type ChangeGroupAction,
	CHANGE_SORT,
	type ChangeSortAction,
	TOGGLE_EXPAND_GROUP,
	type ToggleExpandGroupAction,
	TOGGLE_SHOW_SPRINTS,
	type ToggleShowSprintsAction,
} from './actions';
import { type VisualisationsState, defaultSorting } from './types';

export type Action =
	| ResetAction
	| ChangeGroupAction
	| ChangeSortAction
	| ToggleExpandGroupAction
	| ToggleShowSprintsAction;

export const initialState: VisualisationsState = {
	sorting: defaultSorting,
	grouping: GROUPING.NONE,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	expandedItems: Object.keys(GROUPING).reduce<Record<string, any>>(
		(result, group: string) =>
			Object.assign(result, {
				[group]: {},
			}),
		{},
	),
	showSprints: false,
};

const reducer = (
	state: VisualisationsState = initialState,
	action: Action,
): VisualisationsState => {
	switch (action.type) {
		case RESET: {
			return {
				...action.payload,
			};
		}
		case CHANGE_GROUP: {
			return {
				...state,
				grouping: action.payload.grouping,
			};
		}
		case CHANGE_SORT: {
			return {
				...state,
				sorting: action.payload.sorting,
			};
		}
		case TOGGLE_EXPAND_GROUP: {
			const { grouping, groupName, expand } = action.payload;
			const { expandedItems } = state;

			return {
				...state,
				expandedItems: {
					...expandedItems,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					[grouping as string]: { ...expandedItems[grouping], [groupName]: expand },
				},
			};
		}
		case TOGGLE_SHOW_SPRINTS: {
			const { showSprints } = action.payload;
			return {
				...state,
				showSprints,
			};
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};

export default reducer;
