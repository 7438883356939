import React from 'react';
import { injectIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import type { FilterId } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

function ClearFilterForIssueSearchWarning({
	intl,
	filters,
	clearFilter,
	clearCustomFieldFilter,
}: Props) {
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.clearButton}>
			<Button
				appearance="link"
				onClick={() => {
					filters.forEach((item) => {
						if (item.type === 'custom') {
							clearCustomFieldFilter(item.filters);
						} else {
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							clearFilter(item.id as FilterId);
						}
					});
				}}
			>
				{intl.formatMessage(messages.clearFilterLink, {
					filterCount:
						filters &&
						filters.reduce(
							(acc, item) => acc + (item.type === 'custom' ? item.filters.length : 1),
							0,
						),
				})}
			</Button>
		</div>
	);
}

export default injectIntl(ClearFilterForIssueSearchWarning);
