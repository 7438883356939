import { getSelectOptions } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { OwnProps, StateProps } from './types';

const mapStateToProps: MapStateToProps<StateProps, OwnProps> = (state, { issue, attribute }) => {
	const customFieldId = Number(attribute);
	const attrValue =
		issue.customFields && issue.customFields[attribute] && Number(issue.customFields[attribute]);

	const selectedOption = getSelectOptions(state).find(
		(option) => option.fieldId === customFieldId && Number(option.id) === attrValue,
	);

	return {
		selectedOption,
	};
};

export default mapStateToProps;
