import type { ComponentGroup, ComponentGroupsState } from './types';

export const RESET = 'state.domain.view-settings.component-groups.RESET' as const;

export const CLEAR_ALL = 'state.domain.view-settings.component-groups.CLEAR_ALL' as const;

export const ADD = 'state.domain.view-settings.component-groups.ADD' as const;

export const UPDATE = 'state.domain.view-settings.component-groups.UPDATE' as const;

export const REMOVE = 'state.domain.view-settings.component-groups.REMOVE' as const;

export type ResetAction = {
	type: typeof RESET;
	payload: ComponentGroupsState;
};

export type ClearAllAction = {
	type: typeof CLEAR_ALL;
};

export type AddAction = {
	type: typeof ADD;
	payload: ComponentGroup;
};

export type UpdateActionPayload = {
	id: string;
	patch: Partial<ComponentGroup>;
};

export type UpdateAction = {
	type: typeof UPDATE;
	payload: UpdateActionPayload;
};

export type RemoveAction = {
	type: typeof REMOVE;
	payload: string;
};

export const reset = (payload: ComponentGroupsState): ResetAction => ({
	type: RESET,
	payload,
});

export const clearAll = (): ClearAllAction => ({
	type: CLEAR_ALL,
});

export const add = (payload: ComponentGroup): AddAction => ({
	type: ADD,
	payload,
});

export const update = (payload: UpdateActionPayload): UpdateAction => ({
	type: UPDATE,
	payload,
});

export const remove = (payload: string): RemoveAction => ({
	type: REMOVE,
	payload,
});
