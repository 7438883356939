/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	description: {
		id: 'error-boundary.page.description',

		defaultMessage:
			'Our team has been notified. If the problem persists, please contact Atlassian Support.',

		description: '',
	},
	header: {
		id: 'error-boundary.page.header',
		defaultMessage: "Something's gone wrong",
		description: '',
	},
	reloadPageButton: {
		id: 'error-boundary.page.reload-page-button',
		defaultMessage: 'Reload page',
		description: '',
	},
	contactSupportButton: {
		id: 'error-boundary.page.contact-support-button',
		defaultMessage: 'Contact support',
		description: '',
	},
});
