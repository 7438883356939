import { getComponentsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/components';
import { getProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects';
import { getComponentGroupsViewSettings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { Props, StateProps } from './types';

export default createStructuredSelector<State, Props, StateProps>({
	componentGroups: getComponentGroupsViewSettings,
	componentsById: getComponentsById,
	projectsById: getProjectsById,
});
