import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import {
	ADD,
	type AddAction,
	RESET,
	type ResetAction,
	ADD_VERSION_IN_PROJECT,
	type AddVersionInProjectAction,
	REMOVE_VERSION_FROM_PROJECT,
	type RemoveVersionFromProjectAction,
	SET_DEFAULT_PROJECT,
	type SetDefaultProjectAction,
} from './actions';
import type { Project } from './types';

const initialState: Project[] = [];

type Action =
	| AddAction
	| ResetAction
	| AddVersionInProjectAction
	| RemoveVersionFromProjectAction
	| SetDefaultProjectAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: Project[] = initialState, action: Action): Project[] => {
	switch (action.type) {
		case RESET:
			// there are many projects , so there isn't any default
			if (action.payload.length > 1) {
				return action.payload.map(
					(project: Project): Project => ({
						...project,
						isDefault: false,
					}),
				);
			}
			// if there is only one project ,this project is the default one
			return action.payload.map(
				(project: Project): Project => ({
					...project,
					isDefault: true,
				}),
			);
		case ADD:
			return [...state, ...action.payload];
		case ADD_VERSION_IN_PROJECT: {
			const { projectId, versionId } = action.payload;
			const project = state.find(({ id }: Project) => id === projectId);

			if (isDefined(project)) {
				const restOfTheProjects = state.filter(({ id }: Project) => id !== projectId);
				return [
					...restOfTheProjects,
					{
						...project,
						versions: [...project.versions, versionId],
					},
				];
			}
			return state;
		}
		case SET_DEFAULT_PROJECT: {
			const projectId = action.payload.projectId;
			return state.map(
				(project: Project): Project => ({
					...project,
					isDefault: project.id === projectId,
				}),
			);
		}
		case REMOVE_VERSION_FROM_PROJECT: {
			const { projectId, versionId } = action.payload;
			const project = state.find(({ id }: Project) => id === projectId);
			if (isDefined(project)) {
				const newVersions = project.versions.filter((id: string) => id !== versionId);
				const restOfTheProjects = state.filter(({ id }: Project) => id !== projectId);
				return [
					...restOfTheProjects,
					{
						...project,
						versions: [...newVersions],
					},
				];
			}
			return state;
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
