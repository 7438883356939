import { type ChangeDisplayAction, CHANGE_DISPLAY } from './actions';
import type { DependencySettingsState } from './types';

type Action = ChangeDisplayAction;

const initialState: DependencySettingsState = {
	display: 'default',
};

const reducer = (
	state: DependencySettingsState = initialState,
	action: Action,
): DependencySettingsState => {
	switch (action.type) {
		case CHANGE_DISPLAY:
			return { ...state, display: action.payload.display };
		default:
			return { ...state, display: state.display || 'default' };
	}
};

export default reducer;
