import React, { Component, createRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import * as R from 'ramda';
import ButtonGroup from '@atlaskit/button/button-group';
import Form, { RequiredAsterisk, ErrorMessage, Field, HelperMessage } from '@atlaskit/form';
import Heading from '@atlaskit/heading';
import InformationIcon from '@atlaskit/icon/core/migration/information--editor-panel';
import Lozenge from '@atlaskit/lozenge';
import { Box, Stack, Text, xcss } from '@atlaskit/primitives';
import AkTextField from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import { AkSelect } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import type {
	FormatOptionLabelMeta,
	OptionType,
} from '@atlassian/jira-portfolio-3-common/src/select/types';
import planCommonMessages from '@atlassian/jira-portfolio-3-plan-increment-common/src/messages.tsx';
import type { EnhancedTeam } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/teams/api';
import { CREATE_MODE } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/teams/list/actions.tsx';
import type {
	IssueSourceType,
	Resource,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import MembersSelect from '@atlassian/jira-portfolio-3-portfolio/src/common/form-renderer/members-select';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import {
	type SchedulingMode,
	PLANNING_UNITS,
	SCENARIO_TYPE,
	SCHEDULE_MODE,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import TeamProfile from './team-profile';
import type { PropsWithIntl as Props, State, SubmitCreation } from './types';

interface Option {
	label: SchedulingMode;
	value: SchedulingMode;
	isDisabled: boolean;
}

// eslint-disable-next-line jira/react/no-class-components
class TeamForm extends Component<Props, State> {
	static defaultProps = {
		dialogMode: CREATE_MODE,
		TeamProfileComponent: TeamProfile,
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			isEstimateFieldValid: true,
			isIterationLengthFieldValid: true,
			isTeamNameFieldValid: false, // defaulted to false since the field is required
		};
	}

	buttonRef = createRef<HTMLButtonElement>();

	setTeamNameFieldValidity = (isValid: boolean) => {
		this.setState({ isTeamNameFieldValid: isValid });
	};

	setEstimateFieldValidity = (isValid: boolean) => {
		this.setState({ isEstimateFieldValid: isValid });
	};

	setIterationLengthFieldValidity = (isValid: boolean) => {
		this.setState({ isIterationLengthFieldValid: isValid });
	};

	isCreateMode = (): boolean => this.props.dialogMode === CREATE_MODE;

	mapTeamMembers = (resources: Resource[]) => {
		const { persons } = this.props;
		return resources
			.filter((res) => res.scenarioType !== SCENARIO_TYPE.DELETED)
			.map((el) => {
				// eslint-disable-next-line @typescript-eslint/no-shadow
				const person = persons.find((person) => person.itemKey === el.personItemKey);
				return person
					? {
							value: person.itemKey,
							label: person.values.title,
							icon: person.values.avatarUrl,
							accountId: person.jiraUser?.accountId,
						}
					: undefined;
			})
			.filter((el) => el);
	};

	getEnhancedTeamData = (): EnhancedTeam | null | undefined => {
		const { team } = this.props;
		if (!this.isCreateMode() && team) {
			const members = this.mapTeamMembers(team.resources);

			return {
				...team,
				members,
			};
		}
		return null;
	};

	isTWPTeam = () => {
		const teamFields = this.getEnhancedTeamData();
		return isDefined(teamFields) && !!teamFields.externalId;
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	submitForm = (fieldValues: any) => {
		const { onSubmitForm } = this.props;
		const enhancedTeam = this.getEnhancedTeamData();

		// let's format the field values before sending it to the backend
		const payload = {
			...fieldValues,
			issueSource: fieldValues.issueSource && fieldValues.issueSource.value,
			schedulingMode: fieldValues.schedulingMode && fieldValues.schedulingMode.value,
			...(this.isTWPTeam()
				? {
						title: isDefined(enhancedTeam) ? enhancedTeam.title : '',
						members: isDefined(enhancedTeam) ? enhancedTeam.members : [],
					}
				: { title: fieldValues.title.trim() }),
		};
		if (!this.isCreateMode() && enhancedTeam) {
			onSubmitForm(payload, enhancedTeam);
		} else {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(onSubmitForm as SubmitCreation)(payload);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getIssueSourceHelperMessage = (formValues: any) => {
		const {
			intl: { formatMessage },
		} = this.props;

		/**
		 * if form values are defined
		 * and issue source is either "undefined" OR defined (but different from "None")
		 * and Team type is "Scrum"
		 */
		if (
			isDefined(formValues) &&
			!R.isEmpty(formValues) &&
			(!isDefined(formValues.issueSource) ||
				(isDefined(formValues.issueSource) && formValues.issueSource.value !== null)) &&
			isDefined(formValues.schedulingMode) &&
			!R.isEmpty(formValues.schedulingMode) &&
			formValues.schedulingMode.value === SCHEDULE_MODE.scrum
		) {
			return formatMessage(messages.issueSourceTooltipScrumNew);
		}
		// Do not show the help text if issueSource is None
		if (
			isDefined(formValues) &&
			!R.isEmpty(formValues) &&
			isDefined(formValues.issueSource) &&
			formValues.issueSource.value === null
		) {
			return null;
		}
		return formatMessage(messages.issueSourceTooltipNotScrum);
	};

	validateTeamNameLength = (teamName: string) => teamName.length <= 80;

	validateTeamName = (teamName: string) => {
		const {
			intl: { formatMessage },
		} = this.props;

		if (!isDefined(teamName)) {
			return;
		}

		if (teamName.trim() === '') {
			this.setTeamNameFieldValidity(false);
			return formatMessage(messages.teamNameEmpty);
		}
		const isTeamNameLengthValid = this.validateTeamNameLength(teamName);
		this.setTeamNameFieldValidity(isTeamNameLengthValid);
		if (!isTeamNameLengthValid) {
			return formatMessage(messages.teamNameMaxLength);
		}
	};

	validateEstimate = (estimate: string) => {
		const {
			intl: { formatMessage },
		} = this.props;

		if (!isDefined(estimate) || estimate === '') {
			this.setEstimateFieldValidity(false);
			return formatMessage(messages.capacityEmptyValidation);
		}

		if (!estimate.toString().match(/^$|^\d+(\.\d+)?$/)) {
			this.setEstimateFieldValidity(false);
			return formatMessage(messages.capacityValidation);
		}

		// @ts-expect-error - TS2365 - Operator '<' cannot be applied to types 'string' and 'number'. | TS2365 - Operator '>' cannot be applied to types 'string' and 'number'.
		if (estimate < 0 || estimate > 9999) {
			this.setEstimateFieldValidity(false);
			return formatMessage(messages.capacityValueValidation);
		}

		this.setEstimateFieldValidity(true);
	};

	validateIterationLength = (iterationLength: string) => {
		const {
			intl: { formatMessage },
		} = this.props;

		if (!isDefined(iterationLength) || iterationLength === '') {
			this.setIterationLengthFieldValidity(false);
			return formatMessage(messages.iterationEmptyValidation);
		}

		if (!iterationLength.toString().match('^([\\d]*)$')) {
			this.setIterationLengthFieldValidity(false);
			return formatMessage(messages.iterationLengthValidation);
		}

		// @ts-expect-error - TS2365 - Operator '<' cannot be applied to types 'string' and 'number'. | TS2365 - Operator '>' cannot be applied to types 'string' and 'number'.
		if (iterationLength < 1 || iterationLength > 99) {
			this.setIterationLengthFieldValidity(false);
			return formatMessage(messages.iterationLengthValueValidation);
		}

		this.setIterationLengthFieldValidity(true);
	};

	getIssueSourceOptions = () => {
		const {
			intl: { formatMessage },
			issueSources = [],
		} = this.props;

		// sorting the list of unique issue sources alphabetically, with "None" set as the first option
		return [
			...[{ label: formatMessage(commonMessages.none), value: null }],
			...R.uniq(
				issueSources.map(({ title, id }) => ({
					label: isDefined(title) ? title : formatMessage(messages.noAccess),
					value: id,
				})),
			).sort((a, b) => a.label.localeCompare(b.label)),
		] as const;
	};

	getIssueSourceOptionsGrouped = () => {
		const {
			intl: { formatMessage },
			issueSources,
		} = this.props;

		const customSortOrder = ['Board', 'Project', 'Filter'];

		// Define a function to map types to messages
		const getTypeLabel = (type: IssueSourceType) => {
			switch (type) {
				case 'Board':
					return formatMessage(messages.issueSourceTypeBoards);
				case 'Project':
					return formatMessage(messages.issueSourceTypeProjects);
				case 'Filter':
					return formatMessage(messages.issueSourceTypeFilters);
				default:
					return '';
			}
		};

		// Extract unique types and sort them based on custom order
		const issueSourcesTypes = orderBy(
			Array.from(new Set(issueSources.map(({ type }) => type))),
			(type) => customSortOrder.indexOf(type),
		); // Sort by custom order

		// Create the grouped options
		const groupedOptions = issueSourcesTypes.map((type) => ({
			label: getTypeLabel(type),
			options: uniqBy(
				issueSources
					.filter(({ type: issueType }) => issueType === type)
					.map(({ title, id }) => ({
						label: title || formatMessage(messages.noAccess),
						value: id,
					})),
				'value',
			),
		}));

		// Return the final options array, including the 'none' option
		return [{ label: formatMessage(commonMessages.none), value: null }, ...groupedOptions];
	};

	getIssueSourceDefaultValue = (teamFields: EnhancedTeam | null | undefined) => {
		if (!isDefined(teamFields)) {
			return undefined;
		}
		return this.getIssueSourceOptions().find(
			(issueSource) => issueSource.value === teamFields.issueSource,
		);
	};

	getPlanningStyleOptions = () => {
		const { planningUnit } = this.props;
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return [
			{ isDisabled: false, label: SCHEDULE_MODE.scrum, value: SCHEDULE_MODE.scrum },
			{
				isDisabled: planningUnit === PLANNING_UNITS.STORY_POINTS,
				label: SCHEDULE_MODE.kanban,
				value: SCHEDULE_MODE.kanban,
			},
		] as Option[];
	};

	getSchedulingModeDefaultValue = (teamFields: EnhancedTeam | null | undefined) => {
		if (isDefined(teamFields) && isDefined(teamFields.schedulingMode)) {
			return {
				label: teamFields.schedulingMode,
				value: teamFields.schedulingMode,
			};
		}
		return {
			label: SCHEDULE_MODE.scrum,
			value: SCHEDULE_MODE.scrum,
		};
	};

	isFormInvalid = () => {
		const { isTeamNameFieldValid, isEstimateFieldValid, isIterationLengthFieldValid } = this.state;

		return this.isTWPTeam()
			? !(isEstimateFieldValid && isIterationLengthFieldValid)
			: !(isTeamNameFieldValid && isEstimateFieldValid && isIterationLengthFieldValid);
	};

	formatPlanningStyleOptionLabel = (option: OptionType, { context }: FormatOptionLabelMeta) => {
		if (context === 'menu') {
			const {
				intl: { formatMessage },
				planningUnit,
			} = this.props;
			if (option.value === SCHEDULE_MODE.kanban && planningUnit === PLANNING_UNITS.STORY_POINTS) {
				return (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={styles.planningStyleOption}>
						<span>{option.label}</span>
						<span>
							<Tooltip
								content={formatMessage(messages.planningStyleDisabledOptionTooltip)}
								position="top"
							>
								<InformationIcon
									label={formatMessage(messages.planningStyleDisabledOptionTooltip)}
									LEGACY_primaryColor={token('color.blanket', colors.N500)}
									color={token('color.icon.subtlest')}
								/>
							</Tooltip>
						</span>
					</div>
				);
			}
			return option.label;
		}
		return option.label;
	};

	getTeamFormContent = () => {
		const {
			intl: { formatMessage },
			isProcessingRequest,
			planDefaults,
			issueSources,
			planningUnit,
			team,
			modalFooterDivElement,
			onCloseForm,
			TeamProfileComponent,
			formHeading,
			formBody,
		} = this.props;

		const teamFields = this.getEnhancedTeamData();

		return (
			<>
				<Form onSubmit={this.submitForm}>
					{({ formProps, getState, setFieldValue }) => (
						<form {...formProps}>
							{this.isTWPTeam() && TeamProfileComponent ? (
								<TeamProfileComponent
									teamId={isDefined(teamFields) ? teamFields.externalId : undefined}
									members={isDefined(teamFields) ? teamFields.members : []}
								/>
							) : (
								<>
									{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
									<p className={styles.explainRequiredFields}>
										{formatMessage(messages.requiredFields)}
										<RequiredAsterisk />
									</p>
									{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
									<div className={styles.teamInfoHeadingCont}>
										<Heading size="xsmall" as="h2">
											{formatMessage(messages.teamInfoHeading)}
										</Heading>

										{(this.isCreateMode() || (isDefined(team) && !team.shareable)) && (
											<Lozenge testId="portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.plan-only-lozenge">
												{formatMessage(planCommonMessages.planOnlyLozenge)}
											</Lozenge>
										)}
									</div>
									<Field
										aria-required
										defaultValue={isDefined(teamFields) ? teamFields.title : undefined}
										isDisabled={isProcessingRequest}
										isRequired
										label={formatMessage(messages.teamNameLabel)}
										name="title"
										// @ts-expect-error - TS2322 - Type '(teamName: string) => string | undefined' is not assignable to type '(value: string | undefined, formState: Object, fieldState: Meta) => string | void | Promise<string | void>'.
										validate={this.validateTeamName}
									>
										{({ fieldProps, meta }) => (
											<>
												<AkTextField
													{...fieldProps}
													autoFocus
													onBlur={fieldProps.onChange}
													testId="portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.team-title"
												/>
												{isDefined(meta.error) && <ErrorMessage>{meta.error}</ErrorMessage>}
											</>
										)}
									</Field>
									<Field
										defaultValue={isDefined(teamFields) ? teamFields.members : undefined}
										isDisabled={isProcessingRequest}
										label={formatMessage(messages.teamMembersLabel)}
										name="members"
									>
										{({ fieldProps, meta }) => (
											<>
												<MembersSelect
													id={fieldProps.id}
													// @ts-expect-error - TS2322 - Type 'HTMLElement' is not assignable to type 'HTMLBodyElement'.

													// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
													menuPortalTarget={document.body}
													onChange={fieldProps.onChange}
													onFocus={fieldProps.onFocus}
													placeholder={formatMessage(messages.startTyping)}
													styles={{
														menuPortal: (base) => ({
															...base,
															zIndex: 9999,
														}),
													}}
													value={fieldProps.value}
													validationState={isDefined(meta.error) ? 'error' : 'default'}
												/>
												{isDefined(meta.error) && <ErrorMessage>{meta.error}</ErrorMessage>}
											</>
										)}
									</Field>
								</>
							)}

							<Box xcss={planningHeadingStyles}>
								{fg('eou_open_team_dialog_in_timeline') ? (
									<Stack space="space.050">
										<Heading size="xsmall" as="h2">
											{formatMessage(formHeading || messages.planningHeading)}
										</Heading>
										{formBody && <Text>{formatMessage(formBody)}</Text>}
									</Stack>
								) : (
									<Heading size="xsmall" as="h2">
										{formatMessage(messages.planningHeading)}
									</Heading>
								)}
							</Box>

							<Field
								defaultValue={this.getIssueSourceDefaultValue(teamFields)}
								isDisabled={isProcessingRequest}
								label={formatMessage(commonMessages.issueSource)}
								name="issueSource"
							>
								{({ fieldProps }) => {
									const value = fieldProps?.value?.value;

									useEffect(() => {
										if (!isDefined(value)) return;
										if (!fg('eou_plans_auto-set_default_planning_style')) return;

										const issueSource = issueSources.find((issue) => issue.id === value);

										const schedulingModes = {
											Project: { label: SCHEDULE_MODE.kanban, value: SCHEDULE_MODE.kanban },
											Board: { label: SCHEDULE_MODE.scrum, value: SCHEDULE_MODE.scrum },
											Filter: false,
										};

										const schedulingMode = issueSource?.type && schedulingModes[issueSource?.type];

										if (schedulingMode) {
											setFieldValue('schedulingMode', schedulingMode);
										}
									}, [value]);

									return (
										<>
											{/* @ts-expect-error - TS2322 - Type '{ classNamePrefix: string; options: readonly [{ readonly label: string; readonly value: null; }, ...{ label: string | undefined; value: number; }[]]; placeholder: string; menuPortalTarget: HTMLElement; ... 11 more ...; 'aria-labelledby': string; }' is not assignable to type 'Readonly<SelectProps<{ label: string | undefined; value: number; } | { readonly label: string; readonly value: null; }, false>>'. */}
											<AkSelect
												{...fieldProps}
												classNamePrefix="portfolio-3-portfolio_app-simple-plans_main_tabs_teams_list_team-dialog_team-form_issue-source"
												options={
													fg('eou_plans_auto-set_default_planning_style')
														? this.getIssueSourceOptionsGrouped()
														: this.getIssueSourceOptions()
												}
												placeholder={formatMessage(messages.issueSourceFieldPlaceholderNew)}
												// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
												menuPortalTarget={document.body}
												styles={{
													menuPortal: (base) => ({
														...base,
														zIndex: 9999,
													}),
												}}
											/>
											<HelperMessage>
												{this.getIssueSourceHelperMessage(getState().values)}
											</HelperMessage>
										</>
									);
								}}
							</Field>
							<Field
								defaultValue={this.getSchedulingModeDefaultValue(teamFields)}
								isDisabled={isProcessingRequest}
								label={formatMessage(messages.planningStyleLabel)}
								name="schedulingMode"
							>
								{({ fieldProps, meta }) => (
									<>
										{/* @ts-expect-error - TS2322 - Type '{ classNamePrefix: string; formatOptionLabel: (option: OptionType, { context }: FormatOptionLabelMeta) => any; isOptionDisabled: (option: { label: string; value: string; }) => any; ... 14 more ...; 'aria-labelledby': string; }' is not assignable to type 'Readonly<SelectProps<{ label: string; value: string; }, false>>'. */}
										<AkSelect
											{...fieldProps}
											classNamePrefix="portfolio-3-portfolio_app-simple-plans_main_tabs_teams_list_team-dialog_team-form_scheduling-mode"
											formatOptionLabel={this.formatPlanningStyleOptionLabel}
											isOptionDisabled={(option) =>
												// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
												(option as Option).isDisabled
											}
											// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
											menuPortalTarget={document.body}
											options={this.getPlanningStyleOptions()}
											styles={{
												// note: z-index is set to 510 to match the modal dialog z-index; so that the tooltip displayed
												// when hovering over the info icon of the disabled "Kanban" option renders above the picklist menu
												menuPortal: (base) => ({
													...base,
													zIndex: 510,
												}),
											}}
											testId="portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.scheduling-mode"
										/>
										{isDefined(meta.error) && <ErrorMessage>{meta.error}</ErrorMessage>}
									</>
								)}
							</Field>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<div className={styles.planningFields}>
								{!R.isEmpty(getState().values) &&
									getState().values.schedulingMode.value === SCHEDULE_MODE.scrum && (
										<Field
											// @ts-expect-error - TS2322 - Type 'number | null | undefined' is not assignable to type 'string | ((currentDefaultValue?: string | undefined) => string) | undefined'.
											defaultValue={
												isDefined(teamFields) && isDefined(teamFields.iterationLength)
													? teamFields.iterationLength
													: planDefaults?.defaultIterationLength
											}
											isDisabled={isProcessingRequest}
											label={formatMessage(messages.sprintLengthLabel)}
											name="iterationLength"
											// @ts-expect-error - TS2322 - Type '(iterationLength: string) => string | undefined' is not assignable to type '(value: string | undefined, formState: Object, fieldState: Meta) => string | void | Promise<string | void>'.
											validate={this.validateIterationLength}
										>
											{({ fieldProps, meta }) => (
												<>
													<AkTextField
														{...fieldProps}
														placeholder={formatMessage(messages.teamFormPlaceholder, {
															default: planDefaults?.defaultIterationLength,
														})}
														testId="portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.iteration-length"
													/>
													{isDefined(meta.error) && <ErrorMessage>{meta.error}</ErrorMessage>}
												</>
											)}
										</Field>
									)}
								{planningUnit !== PLANNING_UNITS.STORY_POINTS ? (
									<Field
										// @ts-expect-error - TS2322 - Type 'number | null | undefined' is not assignable to type 'string | ((currentDefaultValue?: string | undefined) => string) | undefined'.
										defaultValue={
											isDefined(teamFields) && isDefined(teamFields.weeklyHours)
												? teamFields.weeklyHours
												: planDefaults?.defaultTeamWeeklyHours
										}
										isDisabled={isProcessingRequest}
										label={formatMessage(messages.weeklyHoursLabel)}
										name="weeklyHours"
										// @ts-expect-error - TS2322 - Type '(estimate: string) => string | undefined' is not assignable to type '(value: string | undefined, formState: Object, fieldState: Meta) => string | void | Promise<string | void>'.
										validate={this.validateEstimate}
									>
										{({ fieldProps, meta }) => (
											<>
												<AkTextField
													{...fieldProps}
													placeholder={formatMessage(messages.teamFormPlaceholder, {
														default: planDefaults?.defaultTeamWeeklyHours,
													})}
													testId="portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.weekly-hours"
												/>
												{isDefined(meta.error) && <ErrorMessage>{meta.error}</ErrorMessage>}
											</>
										)}
									</Field>
								) : (
									<Field
										// @ts-expect-error - TS2322 - Type 'number | null | undefined' is not assignable to type 'string | ((currentDefaultValue?: string | undefined) => string) | undefined'.
										defaultValue={
											isDefined(teamFields) && isDefined(teamFields.velocity)
												? teamFields.velocity
												: planDefaults?.defaultTeamVelocity
										}
										isDisabled={isProcessingRequest}
										label={formatMessage(messages.capacityPointsLabel)}
										name="velocity"
										// @ts-expect-error - TS2322 - Type '(estimate: string) => string | undefined' is not assignable to type '(value: string | undefined, formState: Object, fieldState: Meta) => string | void | Promise<string | void>'.
										validate={this.validateEstimate}
									>
										{({ fieldProps, meta }) => (
											<>
												<AkTextField
													{...fieldProps}
													placeholder={formatMessage(messages.teamFormPlaceholder, {
														default: planDefaults?.defaultTeamVelocity,
													})}
													testId="portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.velocity"
												/>
												{isDefined(meta.error) && <ErrorMessage>{meta.error}</ErrorMessage>}
											</>
										)}
									</Field>
								)}
							</div>
							{/**
							 * This button below is added to create a bridge between the form and the submit button in the modal footer.
							 * When the button in the modal footer is clicked, it instantiates a click on this button which leads to form submit action.
							 * This is done since only a button of type submit triggers the submission of the form, and the modal footer buttons are
							 * not wrapped within the <form> - they are rendered outside of it.
							 */}
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<div className={styles.hiddenSubmitButton}>
								{/* eslint-disable-next-line @atlaskit/design-system/no-html-button */}
								<button type="submit" ref={this.buttonRef} aria-hidden />
							</div>
						</form>
					)}
				</Form>
				{modalFooterDivElement &&
					createPortal(
						<ButtonGroup>
							<Button appearance="subtle" isDisabled={isProcessingRequest} onClick={onCloseForm}>
								{formatMessage(commonMessages.cancel)}
							</Button>
							<Button
								appearance="primary"
								isDisabled={this.isFormInvalid()}
								isLoading={isProcessingRequest}
								onClick={() => {
									if (this.buttonRef.current) {
										this.buttonRef.current.click();
									}
								}}
								testId="portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.save"
							>
								{formatMessage(this.isCreateMode() ? commonMessages.create : commonMessages.done)}
							</Button>
						</ButtonGroup>,
						modalFooterDivElement,
					)}
			</>
		);
	};

	render() {
		return this.getTeamFormContent();
	}
}

export default injectIntl(TeamForm);

const planningHeadingStyles = xcss({
	marginTop: 'space.300',
	marginBottom: 'space.100',
});
