import type { IssuePriorities } from './types';

export const RESET = 'state.domain.issue-priorities.RESET' as const;
export const ADD = 'state.domain.issue-priorities.ADD' as const;

export type ResetActionPayload = IssuePriorities;

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export type AddAction = {
	type: typeof ADD;
	payload: ResetActionPayload;
};

export const reset = (payload: ResetActionPayload) => ({
	type: RESET,
	payload,
});

export const add = (payload: ResetActionPayload) => ({
	type: ADD,
	payload,
});
