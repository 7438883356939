import React, { type ComponentType, Component } from 'react';
import { connect } from '@atlassian/jira-react-redux';
import mapDispatchToProps from './command';
import mapStateToProps from './query';
import type { DateFieldTypes, Props, State } from './types';
import View from './view';

export default connect(mapStateToProps, mapDispatchToProps)(View);

export const createDateCell = (DateCellComponent: ComponentType<Props>, type: DateFieldTypes) =>
	// eslint-disable-next-line jira/react/no-class-components
	class extends Component<Props, State> {
		render() {
			return <DateCellComponent {...this.props} type={type} />;
		}
	};
