import { WARNING_CENTER_AVAILABLE_TYPES } from '../../warnings/types';
import {
	type ResetShowWarningAction,
	type ToggleShowWarningAction,
	type ToggleWarningByTypeAction,
	type ResetEnabledWarningTypeAction,
	RESET_SHOW_WARNING,
	TOGGLE_SHOW_WARNING,
	TOGGLE_WARNING_TYPE,
	RESET_ENABLED_WARNING_TYPE,
} from './actions';
import type { WarningSettingsState } from './types';

type Action =
	| ResetShowWarningAction
	| ToggleShowWarningAction
	| ToggleWarningByTypeAction
	| ResetEnabledWarningTypeAction;

const initialState: WarningSettingsState = {
	showWarning: false,
	disabledWarnings: [],
};

const reducer = (
	stateArg: WarningSettingsState = initialState,
	action: Action,
): WarningSettingsState => {
	switch (action.type) {
		case RESET_SHOW_WARNING:
			return { ...stateArg, showWarning: action.payload.showWarning };
		case TOGGLE_SHOW_WARNING:
			return { ...stateArg, showWarning: !stateArg.showWarning };
		case TOGGLE_WARNING_TYPE: {
			if ((stateArg.disabledWarnings || []).includes(action.payload)) {
				const warningsWithPayloadItemRemoved = stateArg.disabledWarnings.filter(
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					(item) => item !== (action as ToggleWarningByTypeAction).payload,
				);
				return {
					...stateArg,
					disabledWarnings: warningsWithPayloadItemRemoved,
				};
			}

			return {
				...stateArg,
				disabledWarnings: [...stateArg.disabledWarnings, action.payload],
			};
		}
		case RESET_ENABLED_WARNING_TYPE:
			return {
				...stateArg,
				disabledWarnings: WARNING_CENTER_AVAILABLE_TYPES.filter(
					(warningType) => !action.payload.has(warningType),
				),
			};
		default: {
			const _exhaustiveCheck: never = action;
			return stateArg;
		}
	}
};

export default reducer;
