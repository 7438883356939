import { isExportMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app';
import { getDateConfiguration } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

const querySelector = createStructuredSelector<State, StateProps>({
	dateConfiguration: getDateConfiguration,
	isExportMode,
});

export default querySelector;
