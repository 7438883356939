import React, { memo, useRef } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useDropTarget } from '@atlassian/jira-portfolio-3-issue-drag-and-drop/src/bindings/index.tsx';
import { useCell } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/cell/index.tsx';

export const OrphanDropTarget = memo(() => {
	const ref = useRef<HTMLDivElement>(null);
	const [{ row }] = useCell();

	useDropTarget(ref, row);

	return (
		<Box
			ref={ref}
			xcss={containerStyles}
			testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.body.orphan-drop-target"
		/>
	);
});

const containerStyles = xcss({
	width: '100%',
	height: '100%',
});
