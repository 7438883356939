import { connect } from '@atlassian/jira-react-redux';
import {
	mapDispatchSelect,
	mapDispatchAsyncSelect,
	mapDispatchAsyncRollupSelect,
	mapDispatchRollupSelect,
} from './command';
import { mapStateRollupSelect } from './query';
import Select, {
	SelectForColumnCell as ColumnCellSelect,
	AsyncSelectForColumnCell as ColumnCellAsyncSelect,
} from './view';
import ColumnCellSelectWithRollup, { AsyncSelectWithRollup } from './with-roll-up';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { selectUtils } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export default Select;
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { AsyncSelect, MIN_SELECT_WIDTH } from './view';

export const SelectForColumnCell = connect(null, mapDispatchSelect)(ColumnCellSelect);

export const CellSelectWithRollup = connect(
	mapStateRollupSelect,
	mapDispatchRollupSelect,
)(ColumnCellSelectWithRollup);

export const AsyncSelectForColumnCell = connect(
	null,
	mapDispatchAsyncSelect,
)(ColumnCellAsyncSelect);

export const AsyncCellSelectWithRollup = connect(
	mapStateRollupSelect,
	mapDispatchAsyncRollupSelect,
)(AsyncSelectWithRollup);
