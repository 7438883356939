import type { Effect } from 'redux-saga';
import * as R from 'ramda';
import { call, put, select } from 'redux-saga/effects';
import {
	ARJ_EARLY_PRELOAD_PREFIX,
	monitor,
} from '@atlassian/jira-portfolio-3-common/src/analytics/performance';
import { extractStartTime } from '@atlassian/jira-portfolio-3-common/src/analytics/utils';
import type { Backlog } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch';
import { resetBacklog, afterResetBacklog } from '../../state/domain/actions';
import { setAdditionalTeams } from '../../state/domain/teams-additional/actions';
import { prepareTeam } from '../../state/domain/util';
import type { State } from '../../state/types';
import { POST, parseError } from '../api';
import batch from '../batch';
import { genericError } from '../errors';
import { calculateStatusOfAllReleases } from '../versions';
import { calculateWarnings } from '../warnings';
import { urls, backlogBody } from './api';

const defaultHeaders = {
	'Content-Type': 'application/json',
	Accept: 'application/json',
};

type Marks = {
	startMark: string;
	endMark: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* getBacklog(isInitialLoad = false): Generator<Effect, any, any> {
	try {
		const {
			domain: {
				plan: { id, currentScenarioId, includeCompletedIssuesFor },
			},
		}: State = yield select(R.identity);
		const url = urls.backlog;
		let response: Response;
		let body;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const preloadedRequest = window.jpoPreloadedRequests && window.jpoPreloadedRequests.backlog;
		if (isInitialLoad && preloadedRequest) {
			body = preloadedRequest.request.body;
			response = yield preloadedRequest.request;

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			delete window.jpoPreloadedRequests.backlog;
		} else {
			body = yield call(backlogBody, {
				id,
				currentScenarioId,
				includeCompletedIssuesFor,
			});
			response = yield call(fetch, url, {
				method: POST,
				body,
				profile: url,
			});
		}
		if (response.ok) {
			yield* batch(function* () {
				const backlog: Backlog = yield call(response.json.bind(response));
				yield put(resetBacklog(backlog));
				const teams = backlog.teams?.map(prepareTeam) ?? [];
				yield put(setAdditionalTeams(teams));
				yield put(afterResetBacklog());
				yield put(calculateWarnings());
				yield put(calculateStatusOfAllReleases());
			});
		} else {
			yield put(
				genericError({
					...parseError(response, yield call(response.text.bind(response))),
					requestInfo: {
						url,
						type: POST,
						status: response.status,
						body,
					},
				}),
			);
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		yield put(genericError({ message: e.message, stackTrace: e.stack }));
	}
}

function* fetchBacklogUrl(
	url: string,
	planId: number,
	scenarioId: number,
	isInitialLoad: boolean,
	marks: Marks,
	headers: Record<string, string> = defaultHeaders, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect | Promise<Response>, any, any> {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const earlyPreloadBacklogPromise = window.__ARJ_BACKLOG_PROMISE__;

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	delete window.__ARJ_BACKLOG_PROMISE__;
	if (isInitialLoad && earlyPreloadBacklogPromise) {
		const response: Response = yield earlyPreloadBacklogPromise;

		/**
		 * Recording the performance information of the API before returning
		 * the response.
		 */
		const start = Math.floor(extractStartTime(marks.startMark));
		const end = Math.ceil(extractStartTime(marks.endMark));
		monitor.recordCustomProfile(url, {
			start,
			end,
		});

		return response;
	}

	const body = yield call(backlogBody, {
		id: planId,
		currentScenarioId: scenarioId,
	});
	return yield call(fetch, url, {
		method: POST,
		body,
		profile: url,
		headers,
	});
}

export function* fetchBacklog(
	isInitialLoad = false,
	planId: number,
	scenarioId: number, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect | Promise<Response>, any, any> {
	return yield* fetchBacklogUrl(urls.backlog, planId, scenarioId, isInitialLoad, {
		startMark: `${ARJ_EARLY_PRELOAD_PREFIX}.backlog:start`,
		endMark: `${ARJ_EARLY_PRELOAD_PREFIX}.backlog:end`,
	});
}
