import React, { Component, Fragment } from 'react';
import * as R from 'ramda';
import AddIcon from '@atlaskit/icon/glyph/editor/add';
import { injectIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { AkSelect } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import type {
	OptionType,
	ValueType,
} from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import {
	type ComponentGroup,
	ALL_OTHER_ISSUES,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/component-groups/types.tsx';
import type { Component as IssueComponentType } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import { getBody } from '@atlassian/jira-portfolio-3-portfolio/src/common/dom';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import Group from '../common/group';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, State } from './types';

const trimName = (name: string): string => {
	const maxLength = 254;
	return name.length > maxLength ? name.substring(0, maxLength) : name;
};

// eslint-disable-next-line jira/react/no-class-components
class GroupByComponents extends Component<Props, State> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	constructor(props: any) {
		super(props);
		this.state = {
			isCreating: false,
		};
	}

	isNewGroupNameValid = (newName: string): boolean => {
		const allGroupNames = new Set(this.props.componentGroups.map(({ name }) => name));
		return !allGroupNames.has(newName);
	};

	isAllOtherIssuesGroup = (id: string): boolean => id === ALL_OTHER_ISSUES;

	onCreateGroup = () =>
		this.setState({
			isCreating: true,
		});

	onCancelCreateGroup = () =>
		this.setState({
			isCreating: false,
		});

	onConfirmingCreateGroup = (newName: string): void => {
		this.onCancelCreateGroup();

		if (newName.length === 0 || !this.isNewGroupNameValid(newName)) {
			return;
		}
		this.props.addGroup({
			id: trimName(newName).toLowerCase(),
			name: trimName(newName),
			isExpanded: true,
			components: [],
		});
	};

	onConfirmingUpdateGroup = (id: string, newName: string): void => {
		if (newName.length === 0 || !this.isNewGroupNameValid(newName)) {
			return;
		}
		this.props.updateGroup(id, {
			id: trimName(newName).toLowerCase(),
			name: trimName(newName),
		});
	};

	onUpdatingComponentsInGroup = (id: string, selectValue: ValueType): void => {
		if (isDefined(selectValue)) {
			this.props.updateGroup(id, {
				components: selectValue.map(({ value }: OptionType) => value),
			});
		}
	};

	toggleExpanded = (id: string, isExpanded: boolean): void =>
		this.props.updateGroup(id, {
			isExpanded,
		});

	renderComponentSelect = (groupId: string, components: number[]) => {
		const {
			componentsById,
			intl: { formatMessage },
			onMenuToggle,
			projectsById,
		} = this.props;
		const allComponents: IssueComponentType[] = Object.keys(componentsById).map(
			(k: string) => componentsById[parseFloat(k)],
		);
		const options = R.sortBy(R.compose(R.toLower, R.prop('name')))(allComponents).map(
			({ id, name, projectId }): OptionType => ({
				value: id,
				label: `${name} (${projectsById[projectId].key})`,
			}),
		);

		const valueOptions = options.filter(({ value }) => components.includes(value));

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<tr className={styles.row}>
				<td />
				<td colSpan={2}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.componentSelect}>
						<AkSelect
							autoFocus
							classNamePrefix="group-by-custom-options-select"
							defaultValue={valueOptions}
							isMulti
							menuPlacement="auto"
							menuPortalTarget={getBody()}
							onChange={(value: ValueType) => this.onUpdatingComponentsInGroup(groupId, value)}
							onMenuClose={() => {
								onMenuToggle(false);
							}}
							onMenuOpen={() => {
								onMenuToggle(true);
							}}
							options={options}
							aria-label={formatMessage(messages.componentPlaceholder)}
							placeholder={formatMessage(messages.componentPlaceholder)}
							// eslint-disable-next-line @typescript-eslint/no-shadow
							styles={{ menuPortal: (styles) => ({ ...styles, zIndex: 1000 }) }}
						/>
					</div>
				</td>
			</tr>
		);
	};

	renderGroup = (group: ComponentGroup) => {
		const { id, isExpanded, components } = group;

		return (
			<Fragment key={id}>
				<tr
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles.row}
					data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.group-by.components.group"
				>
					<td />
					<td colSpan={2}>
						<Group
							groupName={group.name}
							groupId={id}
							isExpanded={isExpanded}
							onConfirm={(newName: string) => {
								this.onConfirmingUpdateGroup(id, newName);
							}}
							onCancel={this.onCancelCreateGroup}
							onDelete={this.props.removeGroup}
							onToggleExpanded={this.toggleExpanded}
						/>
					</td>
				</tr>
				{!this.isAllOtherIssuesGroup(id) &&
					isExpanded &&
					this.renderComponentSelect(id, components)}
			</Fragment>
		);
	};

	render() {
		const {
			intl: { formatMessage },
		} = this.props;
		return (
			<>
				{this.props.componentGroups.map(this.renderGroup)}
				{this.state.isCreating && (
					<tr
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={styles.row}
						data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.group-by.components.creating-group-row"
					>
						<td />
						<td colSpan={2}>
							<Group
								shouldCreateGroup
								onConfirm={this.onConfirmingCreateGroup}
								onCancel={this.onCancelCreateGroup}
							/>
						</td>
					</tr>
				)}
				<tr>
					<td />
					<td colSpan={2}>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.componentGroup}>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<div className={`${styles.icon} ${styles.blue}`}>
								<AddIcon label="" />
							</div>
							<Button
								appearance="link"
								onClick={this.onCreateGroup}
								testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.group-by.components.create-components-group"
							>
								{formatMessage(messages.createGroup)}
							</Button>
						</div>
					</td>
				</tr>
			</>
		);
	}
}

export default injectIntl(GroupByComponents);
