import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import uniq from 'lodash/uniq';
import Avatar from '@atlaskit/avatar';
import AddIcon from '@atlaskit/icon/glyph/editor/add';
import { Text } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { AkSelect } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import type {
	FormatOptionLabelMeta,
	OptionType,
	ValueType,
} from '@atlassian/jira-portfolio-3-common/src/select/types';
import type { ColourIssueType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/colour-by/types';
import { getBody } from '@atlassian/jira-portfolio-3-portfolio/src/common/dom';
import { chain, isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import ColourPicker from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colour-picker/view.tsx';
import {
	colourPickerPaletteLabels,
	DEFAULT_COLOR,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colours';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import viewSettingsMessages from '../messages';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';
import { getIssueTypeNamesWithMoreThanOneOccurrence } from './utils';

export function ColourByIssueType(props: Props) {
	const {
		colourIssueTypes,
		issueTypesById,
		issueTypeIdsOfCompanyManagedProjects,
		teamManagedProjectsSortedByName,
		onMenuToggle,
		removeIssueTypeColour,
		updateIssueTypeColour,
		issueTypesByName,
		addIssueTypeColour,
		issueTypeIds,
		projectsById,
		teamManagedProjectByIssueTypeId,
		issueTypeIconByName,
	} = props;

	const { formatMessage } = useIntl();

	const sortOptionsByLabel = (options: number[]): OptionType[] =>
		options
			.map((issueTypeId) => ({
				label: issueTypesById[issueTypeId].name,
				value: issueTypeId,
			}))
			.sort((a, b) => a.label.localeCompare(b.label));

	// this function returns the label and value of all selected issue types based on their issue type id
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const getIssueTypesValues = (issueTypeIds: number[]): OptionType[] =>
		issueTypeIds.reduce<Array<{ label: string; value: number }>>((acc, issueTypeId) => {
			if (issueTypesById[issueTypeId]?.name) {
				acc.push({ label: issueTypesById[issueTypeId].name, value: issueTypeId });
			}
			return acc;
		}, []);

	// this function returns the issue types options grouped by project in the dropdown menu
	// top section includes shortcut to select all (TMP) issue types matching name
	// issue types from company-managed projects are grouped into one group
	// issue types from teams-managed projects are grouped per team-managed project
	const getIssueTypesOptions = (availableIssueTypeIds: Set<number>): OptionType[] => {
		if (teamManagedProjectsSortedByName.length) {
			const allIssueTypeNameOptions = {
				label: undefined,
				options: getIssueTypeNamesWithMoreThanOneOccurrence(
					Array.from(availableIssueTypeIds),
					issueTypesById,
				)
					.sort((name, otherName) => name.localeCompare(otherName))
					.map((name) => ({
						label: formatMessage(messages.allIssueTypesOption, {
							issueTypeName: <Text as="strong">{name}</Text>,
						}),
						value: name,
					})),
			};
			const issueTypesOptions = teamManagedProjectsSortedByName.reduce<
				Array<{ label: number | string | undefined; options: OptionType[] }>
			>((acc, project) => {
				const availableProjectIssueTypeIds = project.issueTypeIds.filter((issueTypeId) =>
					availableIssueTypeIds.has(issueTypeId),
				);
				// showing a project "group" only if some of its issue types are not yet selected for any colour
				if (availableProjectIssueTypeIds.length) {
					acc.push({
						// the group label is not formatted here but rather in the formatIssueTypeGroupLabel() function,
						// so here we are just passing the project id as "label"
						label: project.id,
						options: sortOptionsByLabel(availableProjectIssueTypeIds),
					});
				}
				return acc;
			}, []);

			// if there are company-managed projects, that group should be displayed first
			if (issueTypeIdsOfCompanyManagedProjects.length) {
				const availableProjectIssueTypeIds = issueTypeIdsOfCompanyManagedProjects.filter(
					(issueTypeId) => availableIssueTypeIds.has(issueTypeId),
				);
				issueTypesOptions.unshift({
					label: formatMessage(messages.companyManagedProjectsLabel),
					options: sortOptionsByLabel(availableProjectIssueTypeIds),
				});
			}

			// all issue type name fixed to top, before CMP and TMP
			issueTypesOptions.unshift(allIssueTypeNameOptions);

			return issueTypesOptions;
		}
		const availableProjectIssueTypeIds = issueTypeIdsOfCompanyManagedProjects.filter(
			(issueTypeId) => availableIssueTypeIds.has(issueTypeId),
		);
		return sortOptionsByLabel(availableProjectIssueTypeIds);
	};

	const onIssueTypesChange = (index: number, options: ValueType) => {
		if (isNil(options) || isEmpty(options)) {
			removeIssueTypeColour({ index });
		} else {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const issueTypeIds: number[] = [];
			// ambiguous type
			if (Array.isArray(options)) {
				options.forEach(({ value }) => {
					if (typeof value === 'number') {
						// issue type id
						issueTypeIds.push(value);
					} else if (typeof value === 'string') {
						// issue type name
						(issueTypesByName[value] ?? []).forEach(({ id }) => issueTypeIds.push(id));
					}
				});
				updateIssueTypeColour({
					index,
					issueTypes: uniq(issueTypeIds),
				});
			}
		}
	};

	const onColourChange = (index: number) => (colour?: string | null) => {
		if (isDefined(colour)) {
			updateIssueTypeColour({ index, colour });
		} else {
			removeIssueTypeColour({ index });
		}
	};

	// this function formats group labels in the menu
	const formatIssueTypeGroupLabel = (option: OptionType) => {
		// option is either
		// { label: project.id, options: [{ label: option.label, value: option.id }] } for team-managed projects
		// { label: groupLabel, options: [{ label: option.label, value: option.id }] } for company-managed projects
		// for team-managed projects
		if (projectsById[option.label]) {
			return (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				<div className={styles.projectHeading}>
					<Avatar appearance="square" size="xsmall" src={projectsById[option.label]?.avatarUrl} />

					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.projectName}>{projectsById[option.label].name}</div>
				</div>
			);
		}
		// for company-managed projects
		return option.label;
	};

	// this function formats option labels in the menu
	const formatIssueTypeOptionLabel = (option: OptionType, { context }: FormatOptionLabelMeta) => {
		// FF not necessary here because the typeof option.value can only be number when FF disabled
		const iconUrl =
			typeof option.value === 'string'
				? `url(${issueTypeIconByName[option.value]})`
				: `url(${issueTypesById[option.value]?.iconUrl})`;
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.issueTypeOption}>
				<div
					style={{
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						backgroundImage: iconUrl,
					}}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles.issueTypeOptionIcon}
				/>
				<div>
					{/* we display the project key only for issue types of team-managed projects */}
					{context === 'value' && teamManagedProjectByIssueTypeId[option.value]?.key
						? `${option.label} (${teamManagedProjectByIssueTypeId[option.value].key})`
						: option.label}
				</div>
			</div>
		);
	};

	const availableIssueTypeIds = useMemo(() => {
		// this function ensures that the dropdown list of issue types does not include
		// issue types that were already selected for any colour
		const usedIssueTypeIds = new Set(chain((x) => x.issueTypes, colourIssueTypes));
		return new Set(issueTypeIds.filter((issueTypeId) => !usedIssueTypeIds.has(issueTypeId)));
	}, [colourIssueTypes, issueTypeIds]);

	const renderRow = (colourIssueType: ColourIssueType, index: number) => {
		const { colour, issueTypes = [] } = colourIssueType;

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div key={`${index}-${colour || ''}`} className={styles.row}>
				<ColourPicker colour={colour} onColourChange={onColourChange(index)} position="left" />
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.issueTypeSelect}>
					<AkSelect
						aria-label={formatMessage(
							isDefined(colour) ? messages.selectIssueType : messages.selectColour,
						)}
						classNamePrefix={`portfolio-3-portfolio_app-simple-plans_main_tabs_roadmap_view-settings_colour-by-issue-type_select-issue-type-${index}`}
						closeMenuOnSelect={false}
						defaultValue={getIssueTypesValues(issueTypes)}
						formatGroupLabel={formatIssueTypeGroupLabel}
						formatOptionLabel={formatIssueTypeOptionLabel}
						isDisabled={!colour}
						isMulti
						menuPlacement="auto"
						menuPortalTarget={getBody()}
						onChange={(options: ValueType) => onIssueTypesChange(index, options)}
						onMenuClose={() => onMenuToggle(false)}
						onMenuOpen={() => onMenuToggle(true)}
						options={getIssueTypesOptions(availableIssueTypeIds)}
						placeholder={formatMessage(
							isDefined(colour) ? messages.chooseIssueType : messages.chooseColour,
						)}
						styles={{
							// eslint-disable-next-line @typescript-eslint/no-shadow
							menuPortal: (styles) => ({
								...styles,
								zIndex: 1000,
							}),
						}}
						inputId="portfolio-3.simple-plans_main_tabs_roadmap.view-settings.selectIssueType"
					/>
				</div>
			</div>
		);
	};

	const renderAddColourLink = () => (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.addColourLink}>
			<Button
				appearance="link"
				iconBefore={<AddIcon label={formatMessage(messages.addColour)} />}
				onClick={() => addIssueTypeColour()}
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-issue-type.add-color-link"
			>
				{formatMessage(messages.addColour)}
			</Button>
		</div>
	);

	const isAddingNewIssueTypeColour = colourIssueTypes.some(({ colour }) => !colour);

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.list}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.row}>
				<Button
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles.allOtherIssuesButton}
					iconAfter={
						<Tooltip
							content={formatMessage(colourPickerPaletteLabels[DEFAULT_COLOR])}
							position="top"
						>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */}
							<div className={styles.swatch} style={{ backgroundColor: DEFAULT_COLOR }} />
						</Tooltip>
					}
					isDisabled
					ariaLabel={formatMessage(viewSettingsMessages.allOtherIssuesButtonLabel)}
				/>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.allOtherIssues}>{formatMessage(commonMessages.allOtherIssues)}</div>
			</div>
			{colourIssueTypes.map(renderRow)}
			{!isAddingNewIssueTypeColour && renderAddColourLink()}
		</div>
	);
}

export default ColourByIssueType;
