import React from 'react';
import Tooltip from '@atlaskit/tooltip'; // eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

export const UrlRenderer = ({ issue, attribute }: Props) => {
	const value = issue.customFields && issue.customFields[attribute];

	if (!value) {
		return null;
	}

	return (
		<Tooltip content={<>{value}</>}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.container}>
				<a href={value} target="_blank" rel="noopener noreferrer">
					{value}
				</a>
			</div>
		</Tooltip>
	);
};

export default UrlRenderer;
