import React, { useState, type ReactElement } from 'react';
import isNil from 'lodash/isNil';
import DropdownMenu, { type CustomTriggerProps } from '@atlaskit/dropdown-menu';
import type { MenuComponent, Props } from './types';

const NestedMenu = <MenuOptions extends { [key: string]: unknown }>({
	trigger,
	menus,
	currentMenu,
	getMenu,
	onSelectOptions,
	selectedOptions = {},
}: Props<MenuOptions>) => {
	const [isOpen, setIsOpen] = useState(false);

	const menu = currentMenu ?? getMenu(selectedOptions);

	if (isNil(menu)) throw Error('Menu could not be determined.');

	const CurrentMenuComponent: MenuComponent<MenuOptions, MenuOptions[keyof MenuOptions]> =
		menus[menu];

	const handleSelectOptions = (newSelectedOptions: Partial<MenuOptions>) => {
		onSelectOptions(newSelectedOptions);
		setIsOpen(false);
	};

	const renderOption = (
		option: MenuOptions[keyof MenuOptions],
		optionTrigger: (triggerProps: Partial<CustomTriggerProps>) => ReactElement,
	) => {
		const newSelectedOptions = { ...selectedOptions, [menu]: option };

		const nextMenu = getMenu(newSelectedOptions);

		if (isNil(nextMenu))
			return optionTrigger({
				onClick: () => handleSelectOptions(newSelectedOptions),
			});

		return (
			<NestedMenu
				trigger={optionTrigger}
				menus={menus}
				currentMenu={nextMenu}
				getMenu={getMenu}
				onSelectOptions={handleSelectOptions}
				selectedOptions={newSelectedOptions}
			/>
		);
	};

	return (
		<DropdownMenu
			trigger={trigger}
			isOpen={isOpen}
			onOpenChange={(attrs) => setIsOpen(attrs.isOpen)}
			placement={isNil(currentMenu) ? 'bottom-start' : 'right-start'}
			shouldRenderToParent
		>
			<CurrentMenuComponent selectedOptions={selectedOptions} renderOption={renderOption} />
		</DropdownMenu>
	);
};

export default NestedMenu;
