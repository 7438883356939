import React, { Component, type ReactNode } from 'react';
import { ExperienceFailureTracker as ViewExperienceFailureTracker } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracker-consumer/result-declared/index.tsx';
import { ErrorPage as ErrorBoundaryPage } from '@atlassian/jira-error-boundary/src/ui/error-page';
import { connect } from '@atlassian/jira-react-redux';
import { genericError, type GenericErrorActionPayload } from '../command/errors';

type Props = {
	children: ReactNode;
	genericError: (arg1: GenericErrorActionPayload) => void;
};

const mapDispatchToProps = {
	genericError,
} as const;

class PortfolioErrorBoundary extends Component<
	Props,
	{
		hasError: boolean;
		error: Error | null;
	}
> {
	// eslint-disable-next-line react/sort-comp
	constructor(props: Props) {
		super(props);
		this.state = { hasError: false, error: null };
	}

	static getDerivedStateFromError(error: Error) {
		return { hasError: true, error };
	}

	componentDidCatch({ message, stack: stackTrace }: Error) {
		this.props.genericError({
			message,
			stackTrace,
			shouldReportError: false,
		});
	}

	render() {
		if (this.state.hasError) {
			return (
				<>
					<ViewExperienceFailureTracker location="portfolio-3.simple-plans.view-error" />
					<ErrorBoundaryPage error={this.state.error} />
				</>
			);
		}

		return this.props.children;
	}
}

export default connect(null, mapDispatchToProps)(PortfolioErrorBoundary);
