import { createMigrate, incremental } from '../common/migrate';
import type { LabelGroupsStateV0 } from './types';

export default createMigrate(
	...incremental(
		{
			V0: function fromV0ToV0(prevState: LabelGroupsStateV0): LabelGroupsStateV0 {
				return prevState;
			},
		},
		{ prefix: 'labelGroups', from: 'V0' },
	),
);
