import React from 'react';
import { token } from '@atlaskit/tokens';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import {
	useRenderingWindow,
	useZoomLevel,
} from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import { connect } from '@atlassian/jira-react-redux';
import RowContainer from '../row/container/index.tsx';
import mapStateToProps from './query';
import type { OwnProps } from './types';
import View from './view';

// @ts-expect-error - TS7006 - Parameter 'ownProps' implicitly has an 'any' type.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function mergeProps(stateProps: any, dispatchProps: Record<any, any>, ownProps) {
	return { ...ownProps, ...stateProps, ...dispatchProps };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps: Record<string, any> = {};

const Sprints = connect(mapStateToProps, mapDispatchToProps, mergeProps)(View);

export const SprintsWithRenderingWindow = ({
	timelineRange: containerTimelineRange,
	...rest
}: OwnProps) => {
	const [zoomLevel] = useZoomLevel();
	const [{ timelineRange: viewportTimelineRange }] = useRenderingWindow();

	return (
		<RowContainer
			bgColor={token('elevation.surface', colors.N0)}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			style={{ boxShadow: `0 -2px ${token('elevation.shadow.raised', colors.N40)}` }}
		>
			{/* @ts-expect-error - TS2740: Type 'Element[]' is missing the following properties from type 'ReactElement<any, any>': type, props, key */}
			<Sprints
				{...rest}
				timelineRange={zoomLevel ? viewportTimelineRange : containerTimelineRange}
				zoomLevel={zoomLevel}
			/>
		</RowContainer>
	);
};

export default SprintsWithRenderingWindow;
