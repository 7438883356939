import { getExpandedChanges } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { Props, StateProps } from './types';

const querySelector = createStructuredSelector<State, Props, StateProps>({
	expandedChanges: getExpandedChanges,
});

export default querySelector;
