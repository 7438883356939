import type { ComponentType } from 'react';
import type { Dispatch } from 'redux';
import type { IntlShape } from '@atlassian/jira-intl';
import type { FlagData } from '@atlassian/jira-portfolio-3-common/src/flag/types';
import type { DateConfiguration } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/plan/types.tsx';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';

export const DateTypes = {
	Start: 'START',
	End: 'END',
	Other: 'OTHER',
} as const;

export type DateFieldTypes = 'START' | 'END' | 'OTHER';

export type State = {
	isHovered: boolean;
	isValid: boolean;
	isActive: boolean;
	flags: FlagData[];
	flagCount: number;
};

export type StateProps = {
	dateConfiguration: DateConfiguration;
	isExportMode: boolean;
};

export type OnChange = (arg1: {
	issue: ScopeIssue;
	field: string;
	date: number | null | undefined;
}) => void;

export type Props = StateProps & {
	attribute: string;
	issue: ScopeIssue;
	type: DateFieldTypes;
	onChange: OnChange;
	isReadOnly?: boolean;
	isScrolling: boolean;
	showOptimizations?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	DatePicker: ComponentType<any>;
};

export type MapDispatchToProps = (dispatch: Dispatch) => Partial<Props>;

export type HandleChangeProps = {
	onChange: Props['onChange'];
	issue: Props['issue'];
	type: Props['type'];
	attribute: Props['attribute'];
	flags: FlagData[];
	setFlags: React.Dispatch<React.SetStateAction<FlagData[]>>;
	intl: IntlShape;
	setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
};
