import {
	add as addComponentGroup,
	update as updateComponentGroup,
	remove as removeComponentGroup,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/component-groups/actions.tsx';
import type { ComponentGroup } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/component-groups/types.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { DispatchProps } from './types';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	addGroup: (group: ComponentGroup) => {
		dispatch(addComponentGroup(group));
	},
	updateGroup: (groupId: string, patch: Partial<ComponentGroup>) => {
		dispatch(
			updateComponentGroup({
				id: groupId,
				patch,
			}),
		);
	},
	removeGroup: (groupId: string) => {
		dispatch(removeComponentGroup(groupId));
	},
});

export default mapDispatchToProps;
