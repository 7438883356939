import {
	getAllSprints,
	getExternalSprints,
	getSprintByIdMap,
	getSprintStatesCategories,
	getSprintsAndTeamsByIssueSources,
	getUniqueFilterSprints,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { StateProps } from './types';

const mapStateToProps: MapStateToProps<StateProps> = (state) => ({
	allSprints: getAllSprints(state),
	externalSprints: getExternalSprints(state),
	filterSprints: getUniqueFilterSprints(state),
	sprintsAndTeamsByIssueSources: getSprintsAndTeamsByIssueSources(state),
	sprintsByIdMap: getSprintByIdMap(state),
	sprintStatesCategories: getSprintStatesCategories(state),
});

export default mapStateToProps;
