import React, { Component } from 'react';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import {
	CustomFieldTypes,
	GROUPING,
	type Grouping,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import {
	getCustomFieldIdFromCustomFieldGrouping,
	isRoadmapGroupedByCustomField,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/custom-fields';
import MenuItem from '../menu-item/view.tsx';
import AssigneeOptions from './assignee-options';
import CustomFieldOptions from './custom-field-options';
import CustomUserPickerOptions from './custom-user-picker-options';
import ProjectOptions from './project-options';
import ReleaseOptions from './release-options';
import SprintOptions from './sprint-options';
import TeamOptions from './team-options';
import type { Props, State } from './types';

// eslint-disable-next-line jira/react/no-class-components
class NestedMenu extends Component<Props, State> {
	static defaultProps = {
		isDefaultOpen: false,
		AssigneeOptions,
		CustomFieldOptions,
		CustomUserPickerOptions,
		MenuItem,
		ProjectOptions,
		TeamOptions,
	};

	state = {
		isMenuOpen: this.props.isDefaultOpen,
	};

	renderMenuContent = () => {
		const {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			AssigneeOptions,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			CustomFieldOptions,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			CustomUserPickerOptions,
			grouping,
			groups,
			hierarchyItem,
			onGroupClick,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			ProjectOptions,
			projectsForLevel,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			TeamOptions,
		} = this.props;
		switch (grouping) {
			case GROUPING.TEAM: {
				return (
					<TeamOptions groups={groups} hierarchyItem={hierarchyItem} onGroupClick={onGroupClick} />
				);
			}
			case GROUPING.PROJECT: {
				return (
					<ProjectOptions
						groups={groups}
						hierarchyItem={hierarchyItem}
						onGroupClick={onGroupClick}
						projectsForLevel={projectsForLevel}
					/>
				);
			}
			case GROUPING.SPRINT: {
				return (
					// @ts-expect-error - TS2739 - Type '{ groups: Group<ScopeIssue>[]; hierarchyItem: HierarchyItem; onGroupClick: (group: GroupOption) => void; }' is missing the following properties from type 'Readonly<Omit<OwnProps & StateProps, "groups" | "sprints" | "externalSprintsById" | "hierarchyItem" | "onGroupClick" | "sprintsByIdMap" | "SprintDialogContent"> & OwnProps & StateProps>': sprints, externalSprintsById, sprintsByIdMap
					<SprintOptions
						groups={groups}
						hierarchyItem={hierarchyItem}
						onGroupClick={onGroupClick}
					/>
				);
			}
			case GROUPING.RELEASE: {
				return (
					<ReleaseOptions
						groups={groups}
						hierarchyItem={hierarchyItem}
						onGroupClick={onGroupClick}
					/>
				);
			}
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			case (isRoadmapGroupedByCustomField(grouping as Grouping) && grouping) || '': {
				const fieldId = parseInt(
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					getCustomFieldIdFromCustomFieldGrouping(grouping as Grouping),
					10,
				);

				if (
					isDefined(this.props.customFieldsById) &&
					isDefined(this.props.customFieldsById[fieldId]) &&
					this.props.customFieldsById[fieldId].type.key === CustomFieldTypes.UserPicker
				) {
					return (
						<CustomUserPickerOptions
							grouping={grouping}
							groups={groups}
							hierarchyItem={hierarchyItem}
							onGroupClick={onGroupClick}
						/>
					);
				}
				return (
					<CustomFieldOptions
						grouping={grouping}
						groups={groups}
						hierarchyItem={hierarchyItem}
						onGroupClick={onGroupClick}
					/>
				);
			}
			default: {
				return (
					<AssigneeOptions
						groups={groups}
						hierarchyItem={hierarchyItem}
						onGroupClick={onGroupClick}
					/>
				);
			}
		}
	};

	toggleMenu = () => {
		this.setState((prevState) => ({ isMenuOpen: !prevState.isMenuOpen }));
	};

	render() {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const { hierarchyItem, isDisabled, triggerTooltip, MenuItem } = this.props;

		return (
			<InlineDialog
				key={`${hierarchyItem.id}-nested-dialog`}
				content={this.renderMenuContent()}
				isOpen={this.state.isMenuOpen}
				placement="right-start"
				onClose={() => this.toggleMenu()}
				noPaddings
			>
				<MenuItem
					menuItem={hierarchyItem}
					key={hierarchyItem.id}
					isDisabled={isDisabled}
					tooltip={triggerTooltip}
					isSelected={this.state.isMenuOpen}
					onMenuItemClick={() => this.toggleMenu()}
				>
					{hierarchyItem.title}
				</MenuItem>
			</InlineDialog>
		);
	}
}

export default NestedMenu;
