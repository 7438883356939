import { fg } from '@atlassian/jira-feature-gating';
import {
	startOfUtcDay,
	endOfUtcDay,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import type {
	TimelineRange,
	Timestamp,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/types';

const GRADIENT_LENGTH_PERCENTAGE = 30;
const MINIMUM_LEFT_FORCED_GRADIENT_PERCENTAGE = 12;
const MAXIMUM_RIGHT_FORCED_GRADIENT_PERCENTAGE = 100 - MINIMUM_LEFT_FORCED_GRADIENT_PERCENTAGE;

type ForcedGradients = {
	earliestStart: Timestamp;
	earliestEnd: Timestamp;
	latestStart: Timestamp;
	latestEnd: Timestamp;
	left: boolean;
	right: boolean;
};

// Applies for issues and previews
type BaselineDates = {
	readonly baselineStart?: Timestamp | null | undefined;
	readonly baselineEnd?: Timestamp | null | undefined;
};

export const getPositionsForBar = (
	{ baselineStart, baselineEnd }: BaselineDates,
	timelineRange: TimelineRange,
	forcedGradients?: ForcedGradients,
): {
	leftPositionPercentage: number;
	rightPositionPercentage: number;
	leftAnchorPercentage: number;
	rightAnchorPercentage: number;
} => {
	const timelineSize = timelineRange.end - timelineRange.start;
	const timestampToTimelineLeftPercentage = (time: Timestamp) =>
		((startOfUtcDay(time) - timelineRange.start) / timelineSize) * 100;

	const timestampToTimelineRightPercentage = (time: Timestamp) =>
		((endOfUtcDay(time) - timelineRange.start) / timelineSize) * 100;

	// Relative to the *left* edge of *timeline* as a percentage of *timeline* width.
	let leftPositionPercentage = 0;
	// Relative to the *right* edge of *timeline* as a percentage of *timeline* width.
	let rightPositionPercentage = 0;
	// Relative to the *left* edge of *bar* as a percentage of *bar* width.
	let leftAnchorPercentage = 0;
	// Relative to the *left* (sic!) edge of *bar* as a percentage of *bar* width.
	let rightAnchorPercentage = 100;

	if (isDefined(baselineStart) && isDefined(baselineEnd)) {
		// If baselineEnd is before baselineStart, we swap them for display in the timeline to ensure the
		// dependency line does not appear 'broken' - but display an appropriate warning next to the issue key.
		const minBaselineStart = fg('due_date_warning_on_plan_timeline')
			? Math.min(baselineStart, baselineEnd)
			: baselineStart;
		const maxBaselineEnd = fg('due_date_warning_on_plan_timeline')
			? Math.max(baselineStart, baselineEnd)
			: baselineEnd;
		leftPositionPercentage = timestampToTimelineLeftPercentage(minBaselineStart);
		rightPositionPercentage = 100 - timestampToTimelineRightPercentage(maxBaselineEnd);
	} else if (isDefined(baselineStart)) {
		leftPositionPercentage = timestampToTimelineLeftPercentage(baselineStart);
		rightPositionPercentage = 100 - (leftPositionPercentage + GRADIENT_LENGTH_PERCENTAGE);
		rightAnchorPercentage = 0;
	} else if (isDefined(baselineEnd)) {
		rightPositionPercentage = 100 - timestampToTimelineRightPercentage(baselineEnd);
		leftPositionPercentage = 100 - (rightPositionPercentage + GRADIENT_LENGTH_PERCENTAGE);
		leftAnchorPercentage = 100;
	}

	if (forcedGradients) {
		const earliestStartPercentage = timestampToTimelineLeftPercentage(
			forcedGradients.earliestStart,
		);

		const latestEndPercentage = timestampToTimelineRightPercentage(forcedGradients.latestEnd);

		if (forcedGradients.left) {
			leftPositionPercentage = Math.min(
				timestampToTimelineRightPercentage(forcedGradients.earliestEnd) -
					GRADIENT_LENGTH_PERCENTAGE,
				earliestStartPercentage,
			);
		}

		if (forcedGradients.right) {
			rightPositionPercentage =
				100 -
				Math.max(
					timestampToTimelineLeftPercentage(forcedGradients.latestStart) +
						GRADIENT_LENGTH_PERCENTAGE,
					latestEndPercentage,
				);
		}

		const totalPercentage = 100 - (leftPositionPercentage + rightPositionPercentage);

		if (forcedGradients.left) {
			leftAnchorPercentage = Math.max(
				MINIMUM_LEFT_FORCED_GRADIENT_PERCENTAGE,
				(100 * (earliestStartPercentage - leftPositionPercentage)) / totalPercentage,
			);
		}

		if (forcedGradients.right) {
			rightAnchorPercentage = Math.min(
				MAXIMUM_RIGHT_FORCED_GRADIENT_PERCENTAGE,
				(100 * (latestEndPercentage - leftPositionPercentage)) / totalPercentage,
			);
		}
	}

	return {
		leftPositionPercentage,
		rightPositionPercentage,
		leftAnchorPercentage,
		rightAnchorPercentage,
	};
};
