import React from 'react';
import Avatar from '@atlaskit/avatar';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

export default function TitleWithAvatar({
	avatarUrl,
	name,
	projectKey,
	id,
	appearance = 'circle',
	size = 'small',
}: Props) {
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.projectTitleContainer}>
			<Avatar appearance={appearance} size={size} src={avatarUrl} />
			<EllipsedWithTooltip id={id} content={name}>
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={`${
						size === 'small' ? styles['small-spacing'] : styles['xsmall-spacing']
					} ${styles.projectTitle}`}
				>
					{name} {projectKey && `(${projectKey})`}
				</div>
			</EllipsedWithTooltip>
		</div>
	);
}
