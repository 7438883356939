import performance from './performance-accessor';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	/**
	 * Computes the duration of the current page load. This uses the Navigation Timing API, and starts recording
	 * when the browser starts making the request, not from when JavaScript actually starts executing. The event
	 * is completed when the window fires the `load` event (e.g. DCL has fired, and all images and frames are also
	 * complete).
	 *
	 * @returns The total load time in milliseconds, or undefined if the page is still loading (i.e. the load event
	 * has not yet fired).
	 */
	lastFullPageLoad(): number | null | undefined {
		const timing = performance.timing;
		const isCurrentPageLoadComplete = timing.loadEventEnd > 0;
		return isCurrentPageLoadComplete ? timing.loadEventEnd - timing.navigationStart : undefined;
	},

	/**
	 * @returns The elapsed time in milliseconds between when the page started loading (timing.navigationStart), and
	 * the current point in time.
	 */
	now(): number {
		return performance.now();
	},
};
