import type { Effect } from 'redux-saga';
import memoize from 'lodash/memoize';
import { fork, takeEvery, put, select, call } from 'redux-saga/effects';
import type * as Api from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch';
import type { SPRINT_STATES } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import { getPlan } from '../../query/plan';
import { getAllSprintIds } from '../../query/sprints';
import * as disabledSprintsActions from '../../state/domain/disabled-sprints/actions';
import * as externalSprintsActions from '../../state/domain/external-sprints/actions';
import { GET } from '../api';
import { jsonOrError } from '../http';
import { updateIssueOrInlineCreate } from '../issue';
import { urls } from './api';

export const GET_SPRINTS_FOR_IDS = 'command.sprint.GET_SPRINTS_FOR_IDS' as const;

type GetSprintsForIdsPayload = string[];
type GetSprintsForIdsAction = {
	type: typeof GET_SPRINTS_FOR_IDS;
	payload: GetSprintsForIdsPayload;
};

export type SprintType = (typeof SPRINT_STATES)[keyof typeof SPRINT_STATES] | 'PROJECTED';

export const getSprintsForIds = (payload: GetSprintsForIdsPayload): GetSprintsForIdsAction => ({
	type: GET_SPRINTS_FOR_IDS,
	payload,
});

const UPDATE_ISSUE_SPRINT = 'command.issue-status.UPDATE_ISSUE_SPRINT' as const;

type UpdateIssueSprintPayload = {
	id: string;
	value?: string | null;
	sprint?: Api.Sprint;
};

type UpdateIssueSprintAction = {
	type: typeof UPDATE_ISSUE_SPRINT;
	payload: UpdateIssueSprintPayload;
};

export const updateIssueSprint = (payload: UpdateIssueSprintPayload) => ({
	type: UPDATE_ISSUE_SPRINT,
	payload,
});

export function* handleExternalSprintsInternal(
	allExternalSprints: Api.Sprint[], // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, void, any> {
	const disabledSprints: Api.Sprint[] = [];
	const externalSprints: Api.Sprint[] = [];
	allExternalSprints.forEach((sprint) => {
		if (sprint.isDisabled === true) {
			disabledSprints.push(sprint);
		} else {
			externalSprints.push(sprint);
		}
	});

	yield put(externalSprintsActions.add(externalSprints));
	yield put(disabledSprintsActions.add(disabledSprints));
}

const memoizedEmptySprintSearch = memoize(() => doSprintSearch(''));

export async function doSprintSearchWithEmptySearchCache(query: string): Promise<Api.Sprint[]> {
	if (query === '') {
		try {
			return await memoizedEmptySprintSearch();
		} catch (e) {
			// do not want to memoize failed searches, or it will never reload
			memoizedEmptySprintSearch.cache.clear && memoizedEmptySprintSearch.cache.clear();
			throw e;
		}
	}
	return doSprintSearch(query);
}

export async function doSprintSearch(query: string): Promise<Api.Sprint[]> {
	const url = urls.sprintSearch(query);
	const response = await fetch(url, { method: GET });
	if (!response.ok) {
		throw Error('Failed to do sprint search');
	}
	return response.json();
}

export function* doGetSprintsForIds({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: GetSprintsForIdsAction): Generator<Effect, void, any> {
	const { id: planId, currentScenarioId: scenarioId } = yield select(getPlan);
	const response = yield* jsonOrError({
		url: urls.sprintsForIds,
		method: 'POST',
		body: { planId, scenarioId, itemKeys: payload },
	});
	if (response.ok) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const externalSprints = response.data as Api.Sprint[];
		yield call(handleExternalSprintsInternal, externalSprints);
	}
}

export function* doUpdateIssueSprint({
	payload: { id, value, sprint }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: UpdateIssueSprintAction): Generator<Effect, void, any> {
	// if we are setting the sprint value to an external sprint that isn't in the state, we need to add it
	if (value != null) {
		const sprintIds = yield select(getAllSprintIds);
		if (!sprintIds.includes(value)) {
			// if sprint present in the request, use it, or else fetch and store
			if (sprint) {
				yield call(handleExternalSprintsInternal, [sprint]);
			} else {
				yield put(getSprintsForIds([value]));
			}
		}
	}

	yield put(
		updateIssueOrInlineCreate({
			id,
			sprint: value,
		}),
	);
}

export function* populateExternalSprints(
	allSprints: Api.Sprint[],
	planSprints: Api.Sprint[], // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, void, any> {
	const planSprintIds: Set<string> = new Set(planSprints.map((it) => it.id));
	const externalSprints: Api.Sprint[] = allSprints.filter((it) => !planSprintIds.has(it.id));

	yield call(handleExternalSprintsInternal, externalSprints);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchUpdateIssueSprint(): Generator<Effect, void, any> {
	yield takeEvery(UPDATE_ISSUE_SPRINT, doUpdateIssueSprint);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchGetSprintsForIds(): Generator<Effect, any, any> {
	yield takeEvery(GET_SPRINTS_FOR_IDS, doGetSprintsForIds);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchGetSprintsForIds);
	yield fork(watchUpdateIssueSprint);
}
