import React, { type ReactElement } from 'react';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import { ErrorHash } from '@atlassian/jira-errors-handling/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import imageUrl from './assets/error-modal.svg';
import messages from './messages';

export type ErrorPageProps = {
	error: Error | null;
};

// @ts-expect-error - TS2554 - Expected 0 arguments, but got 1.

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const reloadPage = () => window.location.reload(true);

const contactSupport = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.location.href = 'https://support.atlassian.com/contact/';
};

export const ErrorPage = ({ error }: ErrorPageProps) => {
	const { formatMessage } = useIntl();

	let description: ReactElement | string = formatMessage(messages.description);
	if (error) {
		description = (
			<>
				<div>{description}</div>
				<ErrorHash error={error} />
			</>
		);
	}

	return (
		<EmptyState
			imageUrl={imageUrl}
			size="narrow"
			header={formatMessage(messages.header)}
			description={description}
			primaryAction={
				<Button
					appearance="primary"
					onClick={reloadPage}
					testId="error-boundary.ui.error-page.primary-action-button"
				>
					{formatMessage(messages.reloadPageButton)}
				</Button>
			}
			tertiaryAction={
				<Button
					appearance="link"
					onClick={contactSupport}
					testId="error-boundary.ui.error-page.tertiary-action-button"
				>
					{formatMessage(messages.contactSupportButton)}
				</Button>
			}
		/>
	);
};
