import * as R from 'ramda';
import { getSelectOptions } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields';
import { getCustomFieldFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/custom-field-filter';
import type { SelectOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/select-options/types';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { OwnProps, StateProps } from './types';

const getOptions = (state: State, { customField }: OwnProps): SelectOption[] =>
	getSelectOptions(state).filter((opt) => opt.fieldId === customField.id);

const getValue = (state: State, { customField }: OwnProps): string[] => {
	const customFieldFilter = getCustomFieldFilter(state);
	return R.path(['value', String(customField.id)], customFieldFilter) || [];
};

export const mapStateToProps = createStructuredSelector<State, OwnProps, StateProps>({
	options: getOptions,
	value: getValue,
});
