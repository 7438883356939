import type { Entity } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import type { Warning } from '@atlassian/jira-portfolio-3-portfolio/src/common/warning-details/types';

export const ADD = 'state.domain.update-jira.warnings.ADD' as const;

export type AddActionPayload = {
	category: Entity;
	itemId: string;
	warnings: Warning[];
};

export type AddAction = {
	type: typeof ADD;
	payload: AddActionPayload;
};

export const add = (payload: AddActionPayload) => ({
	type: ADD,
	payload,
});
