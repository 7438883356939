import type { OriginalTeams } from './types';

export const RESET = 'state.domain.original-teams.RESET' as const;
export const UPDATE = 'state.domain.original-teams.UPDATE' as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UpdateActionPayload = any;

export type ResetActionPayload = OriginalTeams;

export type UpdateAction = {
	type: typeof UPDATE;
	payload: UpdateActionPayload;
};
export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export const update = (payload: UpdateActionPayload) => ({
	type: UPDATE,
	payload,
});
export const reset = (payload: ResetActionPayload) => ({
	type: RESET,
	payload,
});
