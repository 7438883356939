import {
	CHANGE_MODE,
	timeScaleCustomDatesChange,
	type ChangeCustomDatesActionPayload,
	type ChangeModeActionPayload,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/time-scale/actions';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { DispatchProps } from './types';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	onTimeScaleCustomDatesChange: (payload: ChangeCustomDatesActionPayload) => {
		dispatch(timeScaleCustomDatesChange(payload));
	},
	onModeChange: (payload: ChangeModeActionPayload) => {
		dispatch({
			type: CHANGE_MODE,
			payload,
		});
	},
});

export default mapDispatchToProps;
