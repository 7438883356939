import { combineReducers } from '@atlassian/jira-portfolio-3-portfolio/src/common/redux';
import CrossProjectReleases from './cross-project-releases/reducer';
import ReleasesFilter from './filter/reducer';
import ProjectReleases from './project-releases/reducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default combineReducers<any, any>({
	CrossProjectReleases,
	ProjectReleases,
	ReleasesFilter,
});
