import {
	type BridgeEventValueType,
	BridgeEventValue,
	type BridgePayload,
	type BridgeEvent,
} from './types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { type BridgeEventValueType } from './types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { BridgeEventValue } from './types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { BridgeEvent, BridgePayload } from './types';

class PlanBridge {
	private isReady = false;

	private eventBus: BridgeEvent[] = [];

	private eventHandlers: { [eventType: string]: (event: BridgeEvent) => void } = {};

	dispatchBridgeEvent = (eventType: BridgeEventValueType, payload: BridgePayload) => {
		const promise = new Promise((resolve, reject) => {
			this.eventBus.push({
				type: eventType,
				payload,
				resolve,
				reject,
			});
			if (this.isReady) {
				this.executeEvents();
			}
		});
		return promise;
	};

	subscribe = (eventType: BridgeEventValueType, handler: (event: BridgeEvent) => void) => {
		this.eventHandlers[eventType] = handler;
	};

	unsubscribe = (eventType: BridgeEventValueType) => {
		delete this.eventHandlers[eventType];
	};

	executeEvents = () => {
		const lastEvent = this.eventBus.pop();
		if (lastEvent && this.eventHandlers[lastEvent.type]) {
			try {
				this.eventHandlers[lastEvent.type](lastEvent);
			} catch (e) {
				lastEvent.reject(e);
			}
		}
		if (this.eventBus.length) {
			this.executeEvents();
		}
	};

	ready = () => {
		this.isReady = true;
		this.executeEvents();
	};

	actions = {
		// Actions from the Consumer to the Plan store
		addIssue: (payload: BridgePayload) =>
			this.dispatchBridgeEvent(BridgeEventValue.ADD_ISSUE, payload),
		updateIssue: (payload: BridgePayload) =>
			this.dispatchBridgeEvent(BridgeEventValue.UPDATE_ISSUE, payload),
		rankIssue: (payload: BridgePayload) =>
			this.dispatchBridgeEvent(BridgeEventValue.RANK_ISSUE, payload),
		bulkUpdateIssues: (payload: BridgePayload) =>
			this.dispatchBridgeEvent(BridgeEventValue.UPDATE_ISSUES, payload),
		addIssueLink: (payload: BridgePayload) =>
			this.dispatchBridgeEvent(BridgeEventValue.ADD_ISSUE_LINK, payload),
		removeIssueLink: (payload: BridgePayload) =>
			this.dispatchBridgeEvent(BridgeEventValue.REMOVE_ISSUE_LINK, payload),
		updatePlannedCapacity: (payload: BridgePayload) =>
			this.dispatchBridgeEvent(BridgeEventValue.UPDATE_PLANNED_CAPACITY, payload),
		removePlannedCapacity: (payload: BridgePayload) =>
			this.dispatchBridgeEvent(BridgeEventValue.REMOVE_PLANNED_CAPACITY, payload),
		updateIssueDatesOptimistic: (payload: BridgePayload) =>
			this.dispatchBridgeEvent(BridgeEventValue.UPDATE_ISSUE_DATES_OPTIMISTIC, payload),
		// Actions from the Plan store to the Consumer
		reviewChanges: () => this.dispatchBridgeEvent(BridgeEventValue.REVIEW_CHANGES, {}),
	};
}

const planBridge = new PlanBridge();

export default planBridge;
