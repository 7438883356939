import React, { type ReactNode, type RefObject, useRef } from 'react';
import {
	useDragTarget,
	useDropTarget,
} from '@atlassian/jira-portfolio-3-issue-drag-and-drop/src/bindings/index.tsx';
import { useCell } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/cell/index.tsx';

type Props = {
	children: (ref: RefObject<HTMLDivElement>, isDragging: boolean) => ReactNode;
};

const DraggableIssue = ({ children }: Props) => {
	const ref = useRef<HTMLDivElement>(null);
	const [{ row }] = useCell();

	const { isDragging } = useDragTarget(ref, row);
	useDropTarget(ref, row);

	return <>{children(ref, isDragging)}</>;
};

export default DraggableIssue;
