// This module is not supposed to be used from View or Command,
// and exists solely for resolving circular dependencies in the Query
// as issue-related selectors form complex graph of dependencies
// with no clear ownership sometimes.
import pipe from 'lodash/fp/pipe';
import groupBy from 'lodash/groupBy';
import isFinite from 'lodash/isFinite';
import * as R from 'ramda';
import {
	type IssueInferredDateSelection,
	ISSUE_INFERRED_DATE_SELECTION,
	DATEFIELD_TYPES,
	type DateField,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import {
	indexBy,
	isDefined,
	filterMap,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { IssueId, Timestamp } from '@atlassian/jira-portfolio-3-portfolio/src/common/types';
import type { Issue } from '../../state/domain/issues/types';
import type { OriginalIssues } from '../../state/domain/original-issues/types';
import type { DateConfiguration } from '../../state/domain/plan/types.tsx';
import type { Project } from '../../state/domain/projects/types';
import type { Solution } from '../../state/domain/solution/types';
import type { Team } from '../../state/domain/teams/types.tsx';
import type { State } from '../../state/types';
import { isOptimizedMode } from '../app';
import { getIssueStatusById } from '../issue-statuses';
import type { IssueStatusesById } from '../issue-statuses/types.tsx';
import { getPlanIssueInferredDateSelection, getDateConfiguration } from '../plan';
import { getProjects } from '../projects';
import { getOptimizedFixVersion, getOptimizedSprint } from '../scope/util';
import { getSolution } from '../solution';
import { getSprintsWithFutureDates, getFutureSprintsForIssue } from '../sprints/common';
import type { SprintsWithFutureDates } from '../sprints/types';
import { getTargetDatesFromSprints } from '../sprints/utils';
import { getAllTeams } from '../teams';
import { getRemovedVersionsById, getVersionsById } from '../versions';
import type { VersionsById } from '../versions/types';
import { getTargetDatesFromReleases } from '../versions/utils';
import { getShowRolledUpDates } from '../view-settings';

export type IssueMap = Record<IssueId, Issue>;

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Issue } from '../../state/domain/issues/types';

/** Be careful when using this selector as is doesn't give you fields like originals,
 * optimized, inferred, baselineStart and baselineEnd
 */
export const getAllIssues = (state: State): Issue[] => state.domain.issues;
export const getOriginalIssues = (state: State): OriginalIssues => state.domain.originalIssues;

/** Be careful when using this selector as is doesn't give you fields like originals,
 * optimized, inferred, baselineStart and baselineEnd
 */
export const getScenarioRemovedIssues = (state: State): Issue[] =>
	state.domain.scenarioRemovedIssues;

const doesNotBelongToRemovedVersion = (
	fixVersions: string[] | null | undefined,
	removedVersionsById: VersionsById,
): boolean =>
	!isDefined(fixVersions) ||
	fixVersions.length === 0 ||
	fixVersions.some((vid) => !Object.prototype.hasOwnProperty.call(removedVersionsById, vid));

/** When both start and end dates of the issue are empty try to infer them from
 *  the dates of the latest sprint assigned to the issue.
 */
const inferSprintDates =
	(sprintsWithFutureDates: SprintsWithFutureDates, issueStatusesById?: IssueStatusesById) =>
	(issue: Issue): Issue => {
		if (isDefined(issue.baselineStart) || isDefined(issue.baselineEnd)) {
			return issue;
		}
		const sprints = getFutureSprintsForIssue(issue, sprintsWithFutureDates);
		if (R.isEmpty(sprints)) {
			return issue;
		}

		const { rawStartDate, rawEndDate } = getTargetDatesFromSprints(
			issue,
			sprints,
			issueStatusesById,
		);
		return {
			...issue,
			baselineStart: rawStartDate,
			baselineEnd: rawEndDate,
			inferred: {
				baselineStart: ISSUE_INFERRED_DATE_SELECTION.SPRINT,
				baselineEnd: ISSUE_INFERRED_DATE_SELECTION.SPRINT,
			},
		};
	};

const inferReleaseDates =
	(versions: VersionsById) =>
	(issue: Issue): Issue => {
		if (isDefined(issue.baselineStart) || isDefined(issue.baselineEnd)) {
			return issue;
		}

		const targetDates = getTargetDatesFromReleases(issue, versions);

		if (!isDefined(targetDates)) {
			return issue;
		}

		const { startDate, endDate } = targetDates;

		if (!isDefined(startDate) && !isDefined(endDate)) {
			return issue;
		}

		return {
			...issue,
			baselineStart: startDate,
			baselineEnd: endDate,
			inferred: {
				baselineStart: startDate
					? ISSUE_INFERRED_DATE_SELECTION.RELEASE
					: ISSUE_INFERRED_DATE_SELECTION.NONE,
				baselineEnd: endDate
					? ISSUE_INFERRED_DATE_SELECTION.RELEASE
					: ISSUE_INFERRED_DATE_SELECTION.NONE,
			},
		};
	};

const appendOptimizedValues =
	(
		dateConfiguration: DateConfiguration,
		selectedIssuesForCalculation: string[],
		solution: Solution,
	) =>
	(issue: Issue): Issue => {
		const { assignments, id } = issue;

		if (selectedIssuesForCalculation.length === 0 || selectedIssuesForCalculation.includes(id)) {
			const undefinedIfInsane = (num: number): number | null | undefined =>
				!isFinite(num) ? undefined : num;

			const baselineStart = undefinedIfInsane(
				assignments.reduce((acc, { start }) => Math.min(acc, start || Infinity), Infinity),
			);
			const baselineEnd = undefinedIfInsane(
				assignments.reduce((acc, { end }) => Math.max(acc, end || -Infinity), -Infinity),
			);
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const { team } = assignments.find(({ team }) => isDefined(team)) || issue;
			const optimized = {
				baselineStart: isDefined(baselineStart) ? baselineStart : issue.baselineStart,
				baselineEnd: isDefined(baselineEnd) ? baselineEnd : issue.baselineEnd,
				team,
				fixVersions: getOptimizedFixVersion(issue, solution),
				sprint: getOptimizedSprint(issue, solution),
			};
			return {
				...issue,
				optimized: {
					...optimized,
					[dateConfiguration.baselineStartField.key]: optimized.baselineStart,
					[dateConfiguration.baselineEndField.key]: optimized.baselineEnd,
				},
			};
		}

		return {
			...issue,
			optimized: {},
		};
	};

const getConfiguredDate = (dateField: DateField, issue: Issue): Timestamp | null | undefined => {
	if (dateField.type === DATEFIELD_TYPES.BUILT_IN) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return issue[dateField.key as keyof Issue];
	}
	if (dateField.type === DATEFIELD_TYPES.CUSTOM && isDefined(issue.customFields)) {
		return issue.customFields[dateField.key];
	}
	return null;
};

const seedRolledUpDates = R.defaultTo({
	baselineStart: Infinity,
	baselineEnd: -Infinity,
});

export const getIssuesWithRolledUpDates = (issues: Issue[]): Issue[] => {
	const rolledUpDatesById: {
		[parentId: string]: {
			baselineStart: Timestamp;
			baselineEnd: Timestamp;
		};
	} = {};
	const issuesSortedByLevelFromLowerToHigher: Issue[] = R.sortBy(R.prop('level'), issues);

	const rollUpDatesForAnIssue = ({ parent, baselineStart, baselineEnd }: Issue): void => {
		const hasAtLeastOneDate = isDefined(baselineStart) || isDefined(baselineEnd);
		// Children without both dates are ignored during roll-up
		if (isDefined(parent) && hasAtLeastOneDate) {
			// defaultTo values are chosen such that a single missing child date will lead to "no date" roll-up.
			rolledUpDatesById[parent] = R.evolve(
				{
					baselineStart: R.min(R.defaultTo(-Infinity, baselineStart)),
					baselineEnd: R.max(R.defaultTo(Infinity, baselineEnd)),
				},
				seedRolledUpDates(rolledUpDatesById[parent]),
			);
		}
	};

	return issuesSortedByLevelFromLowerToHigher.map((issue) => {
		const rolledUpDates = rolledUpDatesById[issue.id];

		if (!rolledUpDates || (isDefined(issue.baselineStart) && isDefined(issue.baselineEnd))) {
			rollUpDatesForAnIssue(issue);
			return issue;
		}

		const transformedIssue = { ...issue, inferred: issue.inferred || {} };

		for (const date of ['baselineStart', 'baselineEnd'] as const) {
			if (!isDefined(issue[date])) {
				transformedIssue.inferred[date] = ISSUE_INFERRED_DATE_SELECTION.ROLL_UP;
				if (Number.isFinite(rolledUpDates[date])) {
					transformedIssue[date] = rolledUpDates[date];
				}
			}
		}

		rollUpDatesForAnIssue(transformedIssue);
		return transformedIssue;
	});
};

export const getIssuesPure = (
	issues: Issue[],
	projects: Project[],
	versionsById: VersionsById,
	removedVersionsById: VersionsById,
	allTeams: Team[],
	sprintsWithFutureDates: SprintsWithFutureDates,
	issueInferredDateSelection: IssueInferredDateSelection,
	dateConfiguration: DateConfiguration,
	issueStatusesById: IssueStatusesById,
	shouldShowRollUpDates: boolean,
	// eslint-disable-next-line @typescript-eslint/no-shadow
	isOptimizedMode: boolean,
	solution: Solution,
): Issue[] => {
	const { baselineStartField, baselineEndField } = dateConfiguration;
	const projectIds = new Set(projects.map((x) => x.id));
	const allTeamIds = new Set(allTeams.map((x) => x.id));
	const isNotRemoved = ({
		project,
		fixVersions,
	}: {
		project: number;
		fixVersions?: string[] | null;
	}) =>
		R.isEmpty(removedVersionsById)
			? projectIds.has(project)
			: projectIds.has(project) && doesNotBelongToRemovedVersion(fixVersions, removedVersionsById);
	const removeNotExistingTeam = (issue: Issue) => {
		if (issue.team && !allTeamIds.has(issue.team)) {
			return { ...issue, team: undefined };
		}
		return issue;
	};
	const configureBaselineDates = (issue: Issue) => ({
		...issue,
		baselineStart: getConfiguredDate(baselineStartField, issue),
		baselineEnd: getConfiguredDate(baselineEndField, issue),
	});

	const transformations: Array<(issue: Issue) => Issue> = [
		removeNotExistingTeam,
		configureBaselineDates,
	];

	if (issueInferredDateSelection === ISSUE_INFERRED_DATE_SELECTION.SPRINT) {
		transformations.push(inferSprintDates(sprintsWithFutureDates, issueStatusesById));
	} else if (issueInferredDateSelection === ISSUE_INFERRED_DATE_SELECTION.RELEASE) {
		transformations.push(inferReleaseDates(versionsById));
	}

	if (isOptimizedMode) {
		const selectedIssuesForCalculation =
			solution.calculationConfiguration && solution.calculationConfiguration.selectedIssueIds
				? solution.calculationConfiguration.selectedIssueIds
				: [];
		transformations.push(
			appendOptimizedValues(dateConfiguration, selectedIssuesForCalculation, solution),
		);
	}

	const transformedIssues = filterMap(isNotRemoved, pipe(transformations), issues);

	return shouldShowRollUpDates ? getIssuesWithRolledUpDates(transformedIssues) : transformedIssues;
};

export const getIssues = createSelector(
	[
		getAllIssues,
		getProjects,
		getVersionsById,
		getRemovedVersionsById,
		getAllTeams,
		getSprintsWithFutureDates,
		getPlanIssueInferredDateSelection,
		getDateConfiguration,
		getIssueStatusById,
		getShowRolledUpDates,
		isOptimizedMode,
		getSolution,
	],
	getIssuesPure,
);

export const createIssueMapByIdPure = (issues: Issue[]): IssueMap => indexBy(R.prop('id'), issues);

export const getIssueMapById = createSelector([getIssues], createIssueMapByIdPure);

export const getScenarioRemovedIssueMapById = createSelector(
	[getScenarioRemovedIssues],
	createIssueMapByIdPure,
);

export const createTeamsWorkingWithSprintsSetPure = (
	issues: Issue[],
): IssueMapByTeamWithSprints => {
	const groupedByTeam = groupBy(issues, 'team');
	return new Set(
		Object.entries(groupedByTeam)
			.filter(([, teamIssues]) => teamIssues.some((issue) => issue.sprint))
			.map(([teamId]) => teamId),
	);
};
/**
 * Selector returns a set of teams that have issues associated with sprints.
 *
 * @returns {Set} - Set of team IDs.
 */
export const getTeamsWorkingWithSprints = createSelector(
	[getIssues],
	createTeamsWorkingWithSprintsSetPure,
);

export type IssueMapByTeamWithSprints = Set<string>;
