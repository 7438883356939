import { fg } from '@atlassian/jira-feature-gating';
import type { CustomField } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { CustomFieldTypes } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import type { StringAttributeFilterValue } from '../types';
import { CUSTOM_FIELD_FILTER_ID, type CustomFieldFilter } from '../types.tsx';
import {
	RESET,
	UPDATE,
	CLEAR,
	CLEAR_ALL,
	type ResetAction,
	type UpdateAction,
	type ClearAction,
	type ClearAllAction,
} from './actions';

type Action = ResetAction | UpdateAction | ClearAction | ClearAllAction;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function filterValueFor(customField: CustomField, desirable: any) {
	switch (customField.type.key) {
		case CustomFieldTypes.Labels:
		case CustomFieldTypes.MultiCheckboxes:
		case CustomFieldTypes.AssetObject:
		case CustomFieldTypes.MultiSelect:
		case CustomFieldTypes.RadioButtons:
		case CustomFieldTypes.SingleSelect:
			if (!fg('asset-custom-field-internal-support')) {
				if (customField.type.key === CustomFieldTypes.AssetObject) {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					return [] as StringAttributeFilterValue;
				}
			}

			// Make it array
			if (!isDefined(desirable)) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				return [] as StringAttributeFilterValue;
			}

			if (!Array.isArray(desirable)) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				return [] as StringAttributeFilterValue;
			}

			return desirable;
		default:
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return [] as StringAttributeFilterValue;
	}
}

function updateFilterValue(
	state: CustomFieldFilter,
	fieldId: string,
	updateFn: (value: StringAttributeFilterValue) => StringAttributeFilterValue,
): CustomFieldFilter {
	return {
		...state,
		value: {
			...state.value,
			[fieldId]: updateFn(state.value[fieldId]),
		},
	};
}

export default function customFieldFilterReducer(
	state: CustomFieldFilter = {
		id: CUSTOM_FIELD_FILTER_ID,
		value: {},
	},
	action: Action,
): CustomFieldFilter {
	const { value } = state;

	switch (action.type) {
		case RESET: {
			const { customFields } = action.payload;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const nextValue = customFields.reduce<Record<string, any>>((acc, customField) => {
				const id = String(customField.id);

				if (!customField.filteringAllowed) {
					return acc;
				}

				return Object.assign(acc, {
					[id]: filterValueFor(customField, value[id]),
				});
			}, {});
			return {
				...state,
				value: nextValue,
			};
		}

		case UPDATE: {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const { value, customField } = action.payload;
			const id = String(customField.id);

			return updateFilterValue(state, id, () => filterValueFor(customField, value));
		}

		case CLEAR: {
			const { customField } = action.payload;
			const id = String(customField.id);

			return updateFilterValue(state, id, () => filterValueFor(customField, null));
		}

		case CLEAR_ALL: {
			const { customFields } = action.payload;
			return customFields.reduce((acc, customField) => {
				const id = String(customField.id);
				return updateFilterValue(acc, id, () => filterValueFor(customField, null));
			}, state);
		}
		default:
			return state;
	}
}
