import type { Dispatch } from 'redux';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import {
	getChangesMetadata,
	commitChanges,
	revertChanges,
	updateLastCommittedTimestamp,
	getNotificationPreference,
	saveNotificationPreference,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/update-jira';
import { interrupt as interruptCommitting } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/update-jira/commit/actions.tsx';
import { interrupt as interruptReverting } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/update-jira/revert/actions.tsx';
import {
	openUpdateJiraDialog,
	closeUpdateJiraDialog,
	closeCommitWarningFlag,
	deselectAllChanges,
	updateNotificationPreference,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/title-bar/update-jira/actions';
import { KEYS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/interaction-constants';
import traceUFOPress from '@atlassian/react-ufo/trace-press';
import type { DispatchProps } from './types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	dispatch: Dispatch<{
		type: string;
	}>,
): DispatchProps => ({
	openDialog: (e: React.MouseEvent<Element> | KeyboardEvent) => {
		traceUFOPress(KEYS.OPEN_REVIEW_CHANGES, e.timeStamp);
		dispatch(getChangesMetadata());
		dispatch(getNotificationPreference());
		dispatch(openUpdateJiraDialog());
	},
	closeDialog: () => {
		dispatch(closeUpdateJiraDialog());
	},
	updateJira: (analyticsEvent: UIAnalyticsEvent, notifyWatchers: boolean) => {
		dispatch(
			commitChanges(
				{
					actions: {
						success: [
							saveNotificationPreference({
								shouldNotifyWatchers: notifyWatchers,
							}),
							deselectAllChanges(),
							updateLastCommittedTimestamp(),
						],
					},
					notifyWatchers,
				},
				{ analyticsEvent },
			),
		);
	},
	revertChanges: () => {
		dispatch(
			revertChanges({
				actions: {
					success: [deselectAllChanges()],
				},
			}),
		);
	},
	closeCommitWarningFlag: () => {
		dispatch(closeCommitWarningFlag());
	},
	interruptCommitting: () => {
		dispatch(interruptCommitting());
	},
	interruptReverting: () => {
		dispatch(interruptReverting());
	},
	updateNotificationPreference: (shouldNotifyWatchers: boolean) => {
		dispatch(updateNotificationPreference({ shouldNotifyWatchers }));
	},
});
