import React, { forwardRef } from 'react';
import Tooltip, { TooltipPrimitive, type TooltipPrimitiveProps } from '@atlaskit/tooltip';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { ZOOM_LEVELS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

// JPO-20817 Specify our own tooltip CSS to add space between the cursor and the tooltip. This is so the tooltip doesn't block the sprint bar.
const CustomTooltipComponent = forwardRef<HTMLDivElement, TooltipPrimitiveProps>((props, ref) => {
	const { children, ...rest } = props;
	return (
		<TooltipPrimitive {...rest} ref={ref}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.sprintTooltip}>{children}</div>
		</TooltipPrimitive>
	);
});

const TimelineSprintTooltip = ({ content, children, timelineSettings }: Props) => {
	// Only use mouse position on weeks mode as the bug most likely happens here
	if (isDefined(timelineSettings) && timelineSettings.mode === ZOOM_LEVELS.WEEKS) {
		return (
			<Tooltip
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.sprint-tooltip"
				position="mouse"
				// Customize the tooltip style as per https://atlassian.design/components/tooltip/examples#customization
				component={CustomTooltipComponent}
				content={content}
				hideTooltipOnMouseDown
			>
				{children}
			</Tooltip>
		);
	}

	// Otherwise, we use bottom position as it has better user experience
	return (
		<Tooltip position="bottom" content={content} hideTooltipOnMouseDown>
			{children}
		</Tooltip>
	);
};

export default TimelineSprintTooltip;
