import React, { Component } from 'react';
import {
	convertSecondsToHours,
	convertSecondsToDays,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/estimation';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import type { Props } from './types';

const planningUnits = {
	storyPoints: 'StoryPoints',
	days: 'Days',
	hours: 'Hours',
} as const;

// eslint-disable-next-line jira/react/no-class-components
export default class Estimate extends Component<Props> {
	round = (number: number): number => Math.round(number * 1000) / 1000;

	getEstimateValue(planningUnit: string, timeEstimate: number): number {
		switch (planningUnit) {
			case planningUnits.days:
				return convertSecondsToDays(timeEstimate, this.props.workingHours);
			case planningUnits.hours:
				return convertSecondsToHours(timeEstimate);
			case planningUnits.storyPoints:
				// We can't accurately convert a timeEstimate for a points plan (it should be filtered out anyway)
				return 0;
			default:
				throw new Error(`Unexpected planningUnit: ${planningUnit}`);
		}
	}

	getEstimateText(): string {
		const {
			plan: { planningUnit },
			timeEstimate,
		} = this.props;

		const estimateValue = this.getEstimateValue(planningUnit, timeEstimate);
		return this.round(estimateValue).toString();
	}

	render() {
		const { timeEstimate } = this.props;
		if (!isDefined(timeEstimate)) {
			return <div>-</div>;
		}

		return <div>{this.getEstimateText()}</div>;
	}
}
