import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { connect } from '@atlassian/jira-react-redux';
import mapDispatchToProps from './command';
import mapStateToProps from './query';
import type { CommandProps, OwnProps, QueryProps } from './types';
import View from './view';

export default connect<QueryProps, CommandProps, OwnProps, State>(
	mapStateToProps,
	mapDispatchToProps,
)(View);
