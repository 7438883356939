import React from 'react';
import Avatar from '@atlaskit/avatar';
import NotInPlan from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/title-bar/update-jira/changes-table/value-change/not-in-plan';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

export default function UserPicker(props: Props) {
	const { value, userList } = props;
	if (!isDefined(value)) {
		return <div>-</div>;
	}
	const user = userList.find((person) => person.value === value);
	if (user) {
		return (
			<EllipsedWithTooltip content={user.label} id={value}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<span className={styles.avatar}>
					<Avatar size="xsmall" src={user.icon} />
				</span>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<span className={styles.title}>{user.label}</span>
			</EllipsedWithTooltip>
		);
	}
	return (
		<div>
			<NotInPlan />
		</div>
	);
}
