import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	errorFlagTitle: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3.associated-issues.src.controllers.action.error-flag-title',
		defaultMessage: 'Something went wrong',
		description: 'Title of error flag.',
	},
	errorFlagDescription: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3.associated-issues.src.controllers.actions.error-flag.error-flag-description',
		defaultMessage: 'There was an error when loading ideas. Please try refreshing the page.',
		description: 'Description of error flag.',
	},
	fetchLimitErrorFlagTitle: {
		id: 'portfolio-3-associated-issues.controllers.actions.fetch-limit-error-flag-title',
		defaultMessage: 'Limit exceeded',
		description: 'Title of flag indicating that there are too many linked ideas in this plan.',
	},
	fetchLimitErrorFlagDescription: {
		id: 'portfolio-3-associated-issues.controllers.actions.fetch-limit-error-flag-description',
		defaultMessage: 'We could not fetch all of the linked ideas for this plan.',
		description:
			'Description of flag indicating that there are too many linked ideas in this plan.',
	},
	errorFlagIconLabel: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3.associated-issues.src.controllers.action.error-flag-icon-label',
		defaultMessage: 'Error',
		description: 'ARIA label for an error icon.',
	},
});

export default messages;
