import { SUB_TASK_LEVEL } from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import { DEFAULT_PROJECT_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/util';
import { getPlanPrefixedKey } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/persistence';
import { persistReducer } from '@atlassian/jira-portfolio-3-portfolio/src/common/redux/persistence.tsx';
import {
	CLEAR_INLINE_CREATE,
	SET_ISSUE_TYPE_ID,
	SET_PROJECT_ID,
	START_INLINE_CREATE,
	UPDATE_INLINE_CREATE,
	SET_SIBLING_ID,
	SET_ISSUE_TYPE_ID_FOR_HIERARCHY,
	SET_GLOBAL_CREATE_ISSUE,
	SET_OPEN_LOCKED,
	type ClearInlineCreateAction,
	type SetIssueTypeIdAction,
	type SetProjectIdAction,
	type StartInlineCreateAction,
	type UpdateInlineCreateAction,
	type SetSiblingIdAction,
	type SetGlobalCreateIssueAction,
	type SetOpenLockedAction,
	type SetIssueTypeIdForHierarchyAction,
} from './actions';
import type { InlineCreateState, Values } from './types';

type Action =
	| ClearInlineCreateAction
	| SetIssueTypeIdAction
	| SetProjectIdAction
	| StartInlineCreateAction
	| UpdateInlineCreateAction
	| SetSiblingIdAction
	| SetOpenLockedAction
	| SetGlobalCreateIssueAction
	| SetIssueTypeIdForHierarchyAction;

export const INLINE_CREATE_ID = '__INLINE_CREATE_ID__';

const defaultValues = {
	project: DEFAULT_PROJECT_ID,
	assignee: 'unassigned',
	assignments: [],
} as const;

export const initialState: InlineCreateState = {
	id: INLINE_CREATE_ID,
	hierarchyLevel: SUB_TASK_LEVEL,
	isOpen: false,
	openLockedMap: {},
	issueTypeId: 0,
	parentId: null,
	siblingId: null,
	projectId: 0,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	values: defaultValues as unknown as Values,
	IssueTypeIdForHierarchy: {},
	isGlobalCreateIssue: true,
};

const reducer = (state: InlineCreateState = initialState, action: Action) => {
	switch (action.type) {
		case CLEAR_INLINE_CREATE: {
			return {
				...state,
				isOpen: false,
				startInlineCreateInEmptyGroup: false,
			};
		}
		case SET_GLOBAL_CREATE_ISSUE: {
			return {
				...state,
				isGlobalCreateIssue: action.payload.isGlobalCreateIssue,
			};
		}
		case SET_ISSUE_TYPE_ID: {
			const {
				payload: { issueTypeId },
			} = action;
			return {
				...state,
				issueTypeId,
			};
		}
		case SET_PROJECT_ID: {
			const {
				payload: { projectId },
			} = action;
			return {
				...state,
				projectId,
				values: {
					...state.values,
					project: projectId,
				},
			};
		}
		case SET_SIBLING_ID: {
			const {
				payload: { siblingId },
			} = action;
			return {
				...state,
				siblingId,
			};
		}
		case START_INLINE_CREATE: {
			const {
				payload: {
					parentId,
					issueTypeId,
					hierarchyLevel,
					projectId,
					siblingId,
					group,
					groupCombination,
					startInlineCreateInEmptyGroup,
					valuesFromGrouping,
				},
			} = action;
			return {
				...state,
				group,
				groupCombination,
				isOpen: true,
				parentId,
				siblingId,
				issueTypeId,
				hierarchyLevel,
				projectId,
				startInlineCreateInEmptyGroup,
				values: {
					...defaultValues,
					project: projectId,
					...valuesFromGrouping,
				},
			};
		}
		case UPDATE_INLINE_CREATE: {
			return {
				...state,
				values: {
					...state.values,
					...action.payload,
				},
			};
		}

		case SET_OPEN_LOCKED: {
			const { attribute, isLocked } = action.payload;
			return {
				...state,
				openLockedMap: {
					...state.openLockedMap,
					[attribute]: isLocked,
				},
			};
		}

		case SET_ISSUE_TYPE_ID_FOR_HIERARCHY: {
			const { hierarchyLevel, issueTypeId } = action.payload;
			return {
				...state,
				IssueTypeIdForHierarchy: {
					...state.IssueTypeIdForHierarchy,
					[hierarchyLevel]: issueTypeId,
				},
			};
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};

// this persisted reducer is being removed with view-switching
// eslint-disable-next-line jira/redux/redux-persist-whitelist
export default persistReducer(
	{
		key: getPlanPrefixedKey('issues-type-for-hierarchy'),
		whitelist: ['IssueTypeIdForHierarchy'],
	},
	reducer,
);
