import * as R from 'ramda';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import type {
	IssueSource,
	DateField,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import { indexBy, isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { PlanningUnit } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import type { PlanInfo, PlanType } from '../../state/domain/plan/types';
import type { State } from '../../state/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { PlanInfo, DateConfiguration } from '../../state/domain/plan/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Scenario as PlanScenario, DateField } from '../../../common/api/types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { DATEFIELD_TYPES, BUILT_IN_DATE_FIELDS } from '../../../common/api/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { IssueSource } from '../../../common/api/types';

export const getPlan = (state: State): PlanInfo => state.domain.plan;

export const getPlanType = (state: State): PlanType => {
	switch (state.domain.plan.planType) {
		case 'CROSS_TEAM':
		case 'CROSS_TEAM_PLANNING':
		case 'SAFE':
			return state.domain.plan.planType;
		default:
			return 'STANDARD';
	}
};

export const getIsSamplePlan = (state: State): boolean => !!state.domain.plan.isSamplePlan;

export const getIsCrossTeamPlanning = (state: State): boolean =>
	state.domain.plan.planType === 'CROSS_TEAM_PLANNING';

export const getPlanTitle = (state: State): PlanInfo['title'] => state.domain.plan.title;

export const getAutoSchedulerEnabled = (state: State): boolean =>
	!!state.domain.plan.autoSchedulerEnabled;

export const getPlanStatus = (state: State): boolean => !!state.domain.plan.planStatus;

export const getIssueSources = (state: State) => state.domain.plan.issueSources;
export const getPlanningUnit = (state: State): PlanningUnit => state.domain.plan.planningUnit;
export const getSyncStartEnabled = (state: State): boolean => state.domain.plan.syncStartEnabled;

export const getIssueSourceById = (state: State, issueSourceId?: number | null) => {
	const issueSources: IssueSource[] = getIssueSources(state);
	return issueSources.find((issueSource) => issueSource.id === issueSourceId);
};

export const getIssueSourcesByIdPure = (
	issueSources: IssueSource[],
): {
	[key: number]: IssueSource;
} => indexBy(R.prop('id'), issueSources);

export const getIssueSourcesById = createSelector([getIssueSources], getIssueSourcesByIdPure);

export const findIssueSourceById = (issueSources: IssueSource[], issueSourceId?: number | null) =>
	issueSources.find((issueSource) => issueSource.id === issueSourceId);

export const getPlanScenarios = (state: State) => state.domain.plan.scenarios ?? [];

export const isMultiScenarioEnabled = (state: State) => state.domain.plan.multiScenarioEnabled;

export const currentScenarioId = (state: State) => state.domain.plan.currentScenarioId;

export const getCurrentScenarioColor = createSelector(
	[getPlanScenarios, currentScenarioId, isMultiScenarioEnabled],
	// eslint-disable-next-line @typescript-eslint/no-shadow
	(scenarios, currentScenarioId, isMultiScenarioEnabled) => {
		if (scenarios && isMultiScenarioEnabled) {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const scenario = scenarios.find((scenario) => scenario.id === currentScenarioId);
			// scenario colours are not moved to tokens
			return scenario ? scenario.color : colors.Y400;
		}
		return colors.Y400;
	},
);

export const getAutoScheduleConfiguration = (state: State) =>
	state.domain.plan.autoScheduleConfiguration;

export const getCreatedTimestamp = (state: State) => state.domain.plan.createdTimestamp;

export const getPlanIssueInferredDateSelection = (state: State) =>
	state.domain.plan.issueInferredDateSelection;

export const getBaselineStartField = (state: State): DateField =>
	state.domain.plan.baselineStartField;
export const getBaselineEndField = (state: State): DateField => state.domain.plan.baselineEndField;

export const getDateConfiguration = createSelector(
	[getBaselineStartField, getBaselineEndField],
	(baselineStartField, baselineEndField) => ({
		baselineStartField,
		baselineEndField,
	}),
);

/**
 * Checks whether one or both configured dates in the plan include a warning.
 * Warnings typically occur when configured dates are custom fields which either
 * got deleted in Jira or are unavailable at the time of loading a plan.
 * Returns true or false.
 */
export const hasWarningInConfiguredDates = createSelector(
	[getDateConfiguration],
	(dateConfiguration) => {
		const { baselineStartField, baselineEndField } = dateConfiguration;
		return isDefined(baselineStartField.warning) || isDefined(baselineEndField.warning);
	},
);
