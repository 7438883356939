import React from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import AkTabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

export default function Tabs(props: Props) {
	const selectedTabIndex = props.tabs.findIndex((tab) => tab && tab.id === props.selected?.id);
	const curatedSelectedTabIndex = selectedTabIndex === -1 ? 0 : selectedTabIndex;
	const defaultSelectedTabIndex = props.tabs.findIndex(
		(tab) => tab && tab.id === props.defaultSelected?.id,
	);
	const curatedDefaultSelectedTabIndex =
		defaultSelectedTabIndex === -1 ? 0 : defaultSelectedTabIndex;

	const handleChange = (index: number, analyticsEvent: UIAnalyticsEvent) => {
		const tab = props.tabs[index];
		props.onSelect && props.onSelect(tab, index, analyticsEvent);
	};

	const tabs = () => (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles['tabs-container']}>
			<AkTabs
				{...props}
				selected={props.selected ? curatedSelectedTabIndex : undefined}
				defaultSelected={props.defaultSelected ? curatedDefaultSelectedTabIndex : undefined}
				onChange={handleChange}
				id="portfolio-common-tabs"
				shouldUnmountTabPanelOnChange
			>
				<TabList>
					{props.tabs.map((tab, index) => (
						<Tab testId={tab?.testId} key={index}>
							{tab?.label}
						</Tab>
					))}
				</TabList>
				{props.tabs.map((tab, index) => (
					<TabPanel key={index}>{tab?.content}</TabPanel>
				))}
			</AkTabs>
		</div>
	);

	return props.direction === 'column' ? (
		tabs()
	) : (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles['tabs-row-wrapper']}>{tabs()}</div>
	);
}

Tabs.defaultProps = {
	direction: 'column',
};
