import {
	getMode,
	isReadOnly,
	isReportMode,
	getPlanModeForAnalytics,
	getAnalyticsEvent,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app';
import { getAssociatedIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/associated-issues';
import { getDependencyReportStatus } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/dependencies';
import { getDefinedGoals } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-goals';
import { getIsIssuesLoading } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues';
import { getPlanTitle } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan';
import { getShareAsState } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/share-report';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { getEnhancedIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/issues/query';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export const getDialogVisibilityState = (state: State) =>
	state.ui.Top.TitleBar.ShareReport.isDialogOpen;

export const isLoading = (state: State) => state.ui.Top.TitleBar.ShareReport.isLoading;

export const getShareReportUrl = (state: State) => state.ui.Top.TitleBar.ShareReport.url;

export default createStructuredSelector<State, StateProps>({
	isLoading,
	isOpen: getDialogVisibilityState,
	mode: getMode,
	noIssuesInPlan: (state: State) => getEnhancedIssues(state).length === 0,
	readOnly: isReadOnly,
	isReportMode,
	planModeForAnalytics: getPlanModeForAnalytics,
	dependencyReportStatus: getDependencyReportStatus,
	shareAs: getShareAsState,
	url: getShareReportUrl,
	analyticsEvent: getAnalyticsEvent,
	isIssuesLoading: getIsIssuesLoading,
	planTitle: getPlanTitle,
	definedGoalsByARI: getDefinedGoals,
	associatedIssues: getAssociatedIssues,
});
