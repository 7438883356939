import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import { ISSUE_HIERARCHY_LEVEL_BASE } from '@atlassian/jira-issue-type-hierarchies';
import { SUB_TASK_LEVEL } from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import type { HierarchyLevel } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { IssueTypeWithLevel } from '../../state/domain/hierarchy/types';
import {
	getTypeToLevelPure,
	getEnrichedHierarchyLevelsPure,
} from '../../state/domain/hierarchy/util';
import type { IssueType } from '../../state/domain/issue-types/types.tsx';
import type { State } from '../../state/types';
import { getIssueTypes } from '../issue-types';

export const getHierarchy = (state: State): HierarchyLevel[] => state.domain.hierarchy.levels;

export const getHierarchyLevelsPure = (hierarchy: HierarchyLevel[]): HierarchyLevel[] => {
	// making a copy to prevent mutation of the original hierarchy
	const hierarchyLevels = [...hierarchy];
	// sorting the hierarchy levels in descending order (top-down)
	return hierarchyLevels.sort((a, b) => b.level - a.level);
};

export const getHierarchyLevels = createSelector([getHierarchy], getHierarchyLevelsPure);

export const getHierarchyLevelsByLevelPure = (
	hierarchy: HierarchyLevel[],
): { [level: number]: HierarchyLevel } => {
	const hierarchyLevels = [...hierarchy];
	return keyBy(hierarchyLevels, 'level');
};

export const getHierarchyLevelsByLevel = createSelector(
	[getHierarchy],
	getHierarchyLevelsByLevelPure,
);

/**
 * @Deprecated Use getIssueTypeIdToLevel instead.
 * Warning: Does NOT return Jira platform level. This is ARJ's hierarchy level, meaning sub-task = 0, and story = 1, etc.
 */
export const getTypeToLevel = createSelector([getHierarchy, getIssueTypes], getTypeToLevelPure);

export const getIssueTypeIdToLevelPure = (
	issueTypes: IssueType[],
	hierarchyLevels: HierarchyLevel[],
): Record<string, number> => {
	const issueTypeIdToLevel = issueTypes.reduce<Record<string, number>>((acc, { id, level }) => {
		acc[`${id}`] = level;
		return acc;
	}, {});

	// fallback to hierarchy data if issue type id does not exist, i.e. some CMP issue types
	// eslint-disable-next-line @typescript-eslint/no-shadow
	hierarchyLevels.forEach(({ level, issueTypes = [] }) => {
		issueTypes.forEach((issueTypeId) => {
			if (issueTypeIdToLevel[issueTypeId] === undefined) {
				issueTypeIdToLevel[issueTypeId] = level;
			}
		});
	});

	return issueTypeIdToLevel;
};

/**
 * Returns map of issueTypeId: hierarchyLevel. Uses Jira platform hierarchy level convention, where base-level (Story) = 0.
 */
export const getIssueTypeIdToLevel = createSelector<
	State,
	IssueType[],
	HierarchyLevel[],
	Record<string, number>
>([getIssueTypes, getHierarchy], getIssueTypeIdToLevelPure);

export const getIssueTypeWithLevelByIdPure = (
	issueTypes: IssueType[],
	issueTypeIdToLevel: Record<string, number>,
): Record<number, IssueTypeWithLevel> =>
	issueTypes.reduce<Record<number, IssueTypeWithLevel>>((acc, { id, name, iconUrl }) => {
		// should never be possible for issue type without id, but default to base-level 0 anyway
		const level = issueTypeIdToLevel[`${id}`] ?? ISSUE_HIERARCHY_LEVEL_BASE;
		acc[id] = { id, name, iconUrl, level };
		return acc;
	}, {});

export const getIssueTypeWithLevelById = createSelector<
	State,
	IssueType[],
	Record<string, number>,
	Record<number, IssueTypeWithLevel>
>([getIssueTypes, getIssueTypeIdToLevel], getIssueTypeWithLevelByIdPure);

export const getIssueTypeByLevelPure = (
	issueTypes: IssueType[],
	issueTypeIdToLevel: Record<string, number>,
): Record<number, IssueType[]> =>
	groupBy(issueTypes, (issueType) => issueTypeIdToLevel[`${issueType.id}`]);

export const getIssueTypesByLevel = createSelector<
	State,
	IssueType[],
	Record<string, number>,
	Record<number, IssueType[]>
>([getIssueTypes, getIssueTypeIdToLevel], getIssueTypeByLevelPure);

export const getEnrichedHierarchyLevels = createSelector(
	[getHierarchy, getIssueTypes],
	getEnrichedHierarchyLevelsPure,
);

export const getHierarchyRangePure = (levels: HierarchyLevel[]) => {
	if (levels.length > 0) {
		const hierarchyRangeLevels = levels.map(({ level }) => level);
		return {
			max: Math.max(...hierarchyRangeLevels),
			min: Math.min(...hierarchyRangeLevels),
		};
	}
	return {
		max: SUB_TASK_LEVEL,
		min: SUB_TASK_LEVEL,
	};
};

export type HierarchyRange = {
	min: number;
	max: number;
};

export const getHierarchyRange = createSelector([getHierarchy], getHierarchyRangePure);

export const getHierarchyTitlesPure = (levels: HierarchyLevel[]): Record<number, string> =>
	levels.reduce<Record<number, string>>((acc, { level, title }) => {
		acc[level] = title;
		return acc;
	}, {});

export const getHierarchyTitles = createSelector<State, HierarchyLevel[], Record<number, string>>(
	[getHierarchy],
	getHierarchyTitlesPure,
);
