import sum from 'lodash/fp/sum';
import type { State } from './types';

export const getColumnWidths = (state: State, preview: boolean) =>
	(preview ? state.preview : undefined) ?? state.widths;

export const selectColumnStickyOffset = (
	state: State,
	{ column, preview }: { column: number; preview: boolean },
) =>
	state.columns[column].sticky
		? getColumnWidths(state, preview)
				.slice(0, column)
				.reduce((offset = 0, width) => offset + width, state.stickyOffset.horizontal)
		: undefined;

/** Returns the <col /> width attribute */
export const getColWidth = (
	state: State,
	viewportWidth: number,
	column: number,
): number | string | undefined => {
	const columnWidths = getColumnWidths(state, true);
	const columnWidth = columnWidths.at(column) ?? 0;
	const lastIndex = columnWidths.length - 1;

	if (columnWidth === 0) {
		return undefined;
	}

	if (state.layout === 'auto') {
		return columnWidth;
	}

	if (column < lastIndex) {
		return columnWidth;
	}

	const hasHorizontalScrolling = sum(columnWidths) > viewportWidth;

	if (hasHorizontalScrolling) {
		return columnWidth;
	}

	return '100%';
};

export const selectRowStickyOffset = (
	state: State,
	{ row, virtualized }: { row: number; virtualized: number[] },
) => {
	const sticky = virtualized.filter((index) => state.rows[index].sticky);
	if (!sticky.includes(row)) {
		return undefined;
	}

	return sticky
		.slice(0, sticky.indexOf(row))
		.map((index) => state.rows[index])
		.reduce((acc, { height }) => acc + height, state.stickyOffset.vertical);
};

export const selectColSpan = (
	state: State,
	{ row, column, preview }: { row: number; column: number; preview: boolean },
) => state.colspan?.({ column, row, columnWidths: getColumnWidths(state, preview) }) ?? 1;

export const selectResizerVisible = (
	state: State,
	{ column, scrollX, preview }: { column: number; scrollX: number; preview: boolean },
) => {
	const isSticky = (col: number) => state.columns[col].sticky;

	if (isSticky(column)) return true;

	return (
		getColumnWidths(state, preview)
			.slice(0, column + 1)
			.filter((_, index) => !isSticky(index))
			.reduce((visible, width) => visible + width, -scrollX) > 0
	);
};
