import { useEffect, useCallback, type RefObject } from 'react';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { useViewport } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/container/index.tsx';
import { useScheduler } from './utils';

const MAX_SPEED = 0.5;

type Inset = {
	top?: number;
	bottom?: number;
	left?: number;
	right?: number;
};

type Args = { pageX: number; pageY: number };

export type MonitorCallback = (scheduler: ReturnType<typeof useScheduler<Args>>) => () => void;

type Props = {
	containerRef: RefObject<HTMLElement | null>;
	allowedAxis: 'vertical' | 'horizontal' | 'both';
	insetHitbox: Inset;
	monitors: MonitorCallback[];
};

export const DragAutoScroll = ({ containerRef, allowedAxis, insetHitbox, monitors }: Props) => {
	const [viewport] = useViewport();

	const autoscroll = useCallback(
		(timeSinceLastFrame: number, { pageX, pageY }: Args) => {
			const { top = 0, bottom = 0, left = 0, right = 0 } = insetHitbox;

			const edgeDistances = {
				top: pageY - viewport.top - top,
				bottom: viewport.top + viewport.height - pageY - bottom,
				left: pageX - viewport.left - left,
				right: viewport.left + viewport.width - pageX - right,
			};

			const velocity = (distanceToTheEdge: number) =>
				distanceToTheEdge <= 0 ? 0 : Math.max(MAX_SPEED - distanceToTheEdge / 200, 0);

			const containerEl = containerRef.current;

			if (containerEl) {
				if (allowedAxis === 'horizontal' || allowedAxis === 'both') {
					let xAmount = velocity(edgeDistances.right) * timeSinceLastFrame;

					if (xAmount === 0) xAmount = -velocity(edgeDistances.left) * timeSinceLastFrame;

					if (!Number.isNaN(xAmount) && xAmount !== 0) containerEl.scrollLeft += xAmount;
				}

				if (allowedAxis === 'vertical' || allowedAxis === 'both') {
					let yAmount = velocity(edgeDistances.bottom) * timeSinceLastFrame;

					if (yAmount === 0) yAmount = -velocity(edgeDistances.top) * timeSinceLastFrame;

					if (!Number.isNaN(yAmount) && yAmount !== 0) containerEl.scrollTop += yAmount;
				}
			}
		},
		[
			allowedAxis,
			containerRef,
			insetHitbox,
			viewport.height,
			viewport.left,
			viewport.top,
			viewport.width,
		],
	);

	const { start, end, update } = useScheduler(autoscroll);

	useEffect(
		() => combine(...monitors.map((monitor) => monitor({ start, update, end }))),
		[start, end, update, monitors],
	);

	return null;
};
