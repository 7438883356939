import * as R from 'ramda';
import { SCENARIO_TYPE } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import {
	ADD,
	RESET,
	REMOVE,
	UPDATE,
	UPDATE_RESOURCE,
	ADD_MEMBER,
	REMOVE_MEMBER,
	SET_SHARED,
	type AddAction,
	type ResetAction,
	type RemoveAction,
	type UpdateAction,
	type UpdateResourceAction,
	type UpdateActionPayload,
	type UpdateResourceActionPayload,
	type AddMemberAction,
	type RemoveMemberAction,
	type RemoveActionPayload,
	type AddMemberActionPayload,
	type RemoveMemberActionPayload,
	type SetSharedAction,
} from './actions';
import type { Team } from './types';

const initialState: Team[] = [];

type Action =
	| AddAction
	| ResetAction
	| RemoveAction
	| UpdateAction
	| AddMemberAction
	| RemoveMemberAction
	| UpdateResourceAction
	| SetSharedAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: Team[] = initialState, action: Action): Team[] => {
	switch (action.type) {
		case ADD:
			return [action.payload, ...state];
		case RESET:
			return [...action.payload];
		case REMOVE: {
			const removePayload: RemoveActionPayload = action.payload;
			if (removePayload.action === 'COMMIT') {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				return state.filter(
					(team) =>
						!(
							removePayload.id === team.id &&
							(team.scenarioType === SCENARIO_TYPE.DELETED ||
								team.scenarioType === SCENARIO_TYPE.EXCLUDED)
						),
				) as Team[];
			}

			return state.filter(
				(team) =>
					!(
						removePayload.id === team.id &&
						(team.scenarioType === SCENARIO_TYPE.ADDED ||
							team.scenarioType === SCENARIO_TYPE.ADDEDEXISTING)
					),
			);
		}
		case UPDATE: {
			const updatePayload: UpdateActionPayload = action.payload;
			return state.map((team: Team) => {
				if (team.id === updatePayload.teamId) {
					return {
						...team,
						...updatePayload.patch,
					};
				}
				return team;
			});
		}
		case UPDATE_RESOURCE: {
			const { teamId, resourceId, itemKey }: UpdateResourceActionPayload = action.payload;
			return state.map((team: Team) => {
				if (team.id === teamId) {
					const resources = team.resources.map((resource) => {
						if (resource.itemKey === resourceId) {
							return {
								...resource,
								scenarioType: undefined,
								values: R.merge(resource.values, resource.originals),
								originals: {},
								itemKey,
							};
						}
						return resource;
					});
					return { ...team, resources };
				}
				return team;
			});
		}
		case ADD_MEMBER: {
			const payload: AddMemberActionPayload = action.payload;
			return state.map((team: Team) => {
				if (team.id === payload.teamId) {
					return {
						...team,
						resources: [...team.resources, payload.resource],
					};
				}
				return team;
			});
		}
		case REMOVE_MEMBER: {
			const payload: RemoveMemberActionPayload = action.payload;
			return state.map((team: Team) => {
				if (team.id === payload.teamId) {
					if (payload.resource.scenarioType === SCENARIO_TYPE.ADDED) {
						return {
							...team,
							resources: team.resources.filter(
								(resource) => resource.itemKey !== payload.resource.itemKey,
							),
						};
					}

					return {
						...team,
						resources: team.resources.map((resource) =>
							resource.itemKey === payload.resource.itemKey
								? {
										...resource,
										scenarioType: SCENARIO_TYPE.DELETED,
									}
								: resource,
						),
					};
				}
				return team;
			});
		}
		case SET_SHARED: {
			const { teamId, externalId } = action;
			return state.map((team: Team) => {
				if (team.id === teamId) {
					return {
						...team,
						externalId,
						shareable: true,
					};
				}
				return team;
			});
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
