import React from 'react';
import type { Props } from './types';
import { useOffsets } from './utils';

export default function MarkerBadgePosition({
	containerWidth,
	startOrEnd,
	issueBarPosition,
	active,
	children,
}: Props) {
	const offsets = useOffsets({
		containerWidth: containerWidth ?? 0,
		width: 16 /** TODO: FIX THIS */,
		startOrEnd,
		issueBar: issueBarPosition,
	});

	if (offsets == null) {
		return null;
	}

	const { offsetActive, offsetInactive } = offsets;
	const translateX = active ? offsetActive - offsetInactive : 0;
	const left = offsetInactive;

	return <>{children({ left, translateX })}</>;
}
