import React from 'react';
import Lozenge from '@atlaskit/lozenge';
import { ISSUE_STATUS_APPEARANCE } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import type { Props } from './types';

export default function StatusCell({ row: { status } }: Props) {
	if (!status) {
		return null;
	}
	const { name, categoryId } = status;

	return <Lozenge appearance={ISSUE_STATUS_APPEARANCE[categoryId]}>{name}</Lozenge>;
}
