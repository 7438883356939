import { createHook, createStore } from '@atlassian/react-sweet-state';
import { actions } from './actions';
import type { State } from './types';

const store = createStore<State, typeof actions>({
	initialState: {
		column: undefined,
		hovered: false,
		active: false,
	},
	actions,
});

export const useResize = createHook(store);
