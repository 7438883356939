import type { Mode } from './types';

export const SET_MODE =
	'state.ui.main.tabs.releases.project-releases.release-dialog.SET_MODE' as const;

export const OPEN_DIALOG =
	'state.ui.main.tabs.releases.project-releases.release-dialog.OPEN_DIALOG' as const;

export const CLOSE_DIALOG =
	'state.ui.main.tabs.releases.project-releases.release-dialog.CLOSE_DIALOG' as const;

export const INITIATE_REQUEST =
	'state.ui.main.tabs.releases.project-releases.release-dialog.INITIATE_REQUEST' as const;

export const RESET_REQUEST =
	'state.ui.main.tabs.releases.project-releases.release-dialog.RESET_REQUEST' as const;

/** SET_MODE */
export type SetModeAction = {
	type: typeof SET_MODE;
	payload: Mode;
};

/** OPEN_DIALOG */
export type OpenDialogActionPayload = {
	projectId: number;
	versionId?: string;
};

export type OpenDialogAction = {
	type: typeof OPEN_DIALOG;
	payload: OpenDialogActionPayload;
};

/** CLOSE_DIALOG */
export type CloseDialogAction = {
	type: typeof CLOSE_DIALOG;
};

/** INITIATE_REQUEST */
export type InitiateRequsetAction = {
	type: typeof INITIATE_REQUEST;
};

/** RESET_REQUEST */
export type ResetRequsetAction = {
	type: typeof RESET_REQUEST;
};

export const setMode = (payload: Mode): SetModeAction => ({
	type: SET_MODE,
	payload,
});

export const openDialog = (projectId: number, versionId?: string) => ({
	type: OPEN_DIALOG,
	payload: {
		projectId,
		versionId,
	},
});

export const closeDialog = () => ({
	type: CLOSE_DIALOG,
});

export const initiateRequest = () => ({
	type: INITIATE_REQUEST,
});

export const resetRequest = () => ({
	type: RESET_REQUEST,
});
