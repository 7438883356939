import React, { type ReactNode } from 'react';
import * as R from 'ramda';
import Lozenge from '@atlaskit/lozenge';
import type { IntlShape, MessageDescriptor } from '@atlassian/jira-intl';
import type { OptionType } from '@atlassian/jira-portfolio-3-common/src/select/types';
import { getCustomFieldById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields';
import type { CustomField } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/custom-fields/types.tsx';
import {
	ISSUE_FIELD_TO_TYPE_MAP,
	ISSUE_FIELDS,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import type { DateConfiguration } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/plan/types.tsx';
import type { ColumnId } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/field-columns/types.tsx';
import { BUILT_IN_DATE_FIELDS } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { filterMap } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import {
	type PlanningUnit,
	CustomFieldTypesToDataType,
	SORT_DIRECTION,
	type SortDirection,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import { customFieldColumnProperties } from '../../fields/column-properties';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';

export const isCustomField = (fieldId: ColumnId): boolean =>
	!BUILT_IN_DATE_FIELDS.includes(fieldId);

export const getSortConfiguration = (
	fieldId: string,
	customFields: CustomField[],
	// ascending is the default sort direction when choosing a sort type
	direction: SortDirection = SORT_DIRECTION.ASCENDING,
) => {
	// Technically the type of fieldId is string here but we want to test if it is included in the BUILT_IN_DATE_FIELDS which is a specific set of strings.
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	if (isCustomField(fieldId as ColumnId)) {
		const customField = getCustomFieldById(Number(fieldId), customFields);
		if (customField.length === 1) {
			return {
				field: fieldId,
				type: CustomFieldTypesToDataType[
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					customField[0].type.key as keyof typeof CustomFieldTypesToDataType
				],
				direction,
				isCustomField: true,
			};
		}
	}

	return {
		field: fieldId,
		type: ISSUE_FIELD_TO_TYPE_MAP[fieldId],
		direction,
	};
};

export const getOptionContent = (
	attribute: string,
	title: string,
	dateConfiguration: DateConfiguration,
) => {
	const { baselineStartField, baselineEndField } = dateConfiguration;
	if (attribute === baselineStartField.key || attribute === baselineEndField.key) {
		return (
			<span>
				{title}
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<span className={styles.configuredDateLozenge}>
					<Lozenge>D</Lozenge>
				</span>
			</span>
		);
	}
	return title;
};

const isCustomFieldSortable = (field: CustomField): boolean =>
	!!customFieldColumnProperties(R.path(['type', 'key'], field)).isSortable;

type OptionLabels = {
	[key: string]: string;
};

export const getOptionValueLabels = (
	intl: IntlShape,
	customFields: CustomField[],
	planningUnit: PlanningUnit,
): OptionLabels => ({
	...customFields.reduce<OptionLabels>(
		(prev, curr) =>
			Object.assign(prev, {
				[curr.id]: curr.title,
			}),
		{},
	),
	[ISSUE_FIELDS.RANK]: intl.formatMessage(messages.rank),
	[ISSUE_FIELDS.ASSIGNEE_FIELD]: intl.formatMessage(commonMessages.assignee),
	[ISSUE_FIELDS.REPORTER_FIELD]: intl.formatMessage(commonMessages.reporter),
	[ISSUE_FIELDS.DUE_DATE]: intl.formatMessage(commonMessages.dueDate),
	[ISSUE_FIELDS.PRIORITY_FIELD]: intl.formatMessage(commonMessages.priority),
	[ISSUE_FIELDS.STATUS_FIELD]: intl.formatMessage(commonMessages.status),
	[ISSUE_FIELDS.ESTIMATE]: intl.formatMessage(messages[planningUnit]),
	[ISSUE_FIELDS.TARGET_START_FIELD]: intl.formatMessage(commonMessages.targetStart),
	[ISSUE_FIELDS.TARGET_END_FIELD]: intl.formatMessage(commonMessages.targetEnd),
	[ISSUE_FIELDS.TEAM_FIELD]: intl.formatMessage(commonMessages.team),
});

type Option = {
	id: string | number;
	value: string | number;
	content: ReactNode;
	defaultDirection?: SortDirection;
};

export const getStandardFieldsOptions = (
	dateConfiguration: DateConfiguration,
	labels: OptionLabels,
	issuePrioritiesIdList: string[] | null,
): OptionType[] => {
	const makeOption = (
		key: string,
		defaultDirection?: SortDirection,
		isDisabled?: boolean,
		tooltipContent?: MessageDescriptor,
	): OptionType => ({
		content: getOptionContent(key, labels[key], dateConfiguration),
		id: key,
		isDisabled: isDisabled ?? false,
		label: labels[key],
		tooltipContent: tooltipContent ?? null,
		value: key,
		...(defaultDirection ? { defaultDirection } : {}),
	});

	const systemFieldsOptions: OptionType[] = [
		makeOption(ISSUE_FIELDS.RANK),
		makeOption(ISSUE_FIELDS.ASSIGNEE_FIELD),
		makeOption(ISSUE_FIELDS.REPORTER_FIELD),
		makeOption(ISSUE_FIELDS.DUE_DATE),
		makeOption(
			ISSUE_FIELDS.PRIORITY_FIELD,
			SORT_DIRECTION.DESCENDING,
			!issuePrioritiesIdList,
			commonMessages.disabledSortByPriorityTooltip,
		),
		makeOption(ISSUE_FIELDS.STATUS_FIELD),
		makeOption(ISSUE_FIELDS.ESTIMATE),
		makeOption(ISSUE_FIELDS.TARGET_START_FIELD),
		makeOption(ISSUE_FIELDS.TARGET_END_FIELD),
		makeOption(ISSUE_FIELDS.TEAM_FIELD),
	];

	return systemFieldsOptions;
};

export const getCustomFieldsOptions = (
	customFields: CustomField[],
	dateConfiguration: DateConfiguration,
): Option[] => {
	const customFieldsOptions = filterMap(
		isCustomFieldSortable,
		({ id, title }) => ({
			id: String(id),
			content: getOptionContent(String(id), title, dateConfiguration),
			label: title,
			value: String(id),
		}),
		customFields,
	);

	return customFieldsOptions;
};
