import { getCustomFields } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields';
import { getPlan } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan';
import { getIsSimplifiedProjectAvailable } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects';
import {
	getDependencySettings,
	getColorByViewSettings,
	getRollupViewSettings,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings';
import {
	getVisualisationGrouping,
	getVisualisationSorting,
	getShowSprints,
	isGroupByMultiValueCustomField,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import {
	createStructuredSelector,
	createSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { getGroupForAnalyticEvent } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/grouping';
import type { OwnProps, StateProps } from './types';

const getAnalyticsAttributes = createSelector(
	[
		getPlan,
		getIsSimplifiedProjectAvailable,
		getColorByViewSettings,
		getVisualisationGrouping,
		getVisualisationSorting,
		getShowSprints,
		getDependencySettings,
		getRollupViewSettings,
		getCustomFields,
	],
	(
		plan,
		planContainsNextGenIssues,
		colorBy,
		grouping,
		sorting,
		showSprints,
		dependencySettings,
		rollUpSettings,
		customFields,
	) => ({
		viewSettingsDetails: {
			colorBy: colorBy.colourByValue,
			sortBy: sorting.field,
			groupBy: getGroupForAnalyticEvent(grouping, customFields),
			showCapacityOnTimeline: showSprints,
			dependencyStyle: dependencySettings.display,
			rollUpDates: rollUpSettings.showingRolledUpDate,
			rollUpOthers: rollUpSettings.showRolledUpOthers,
		},
		planId: plan.id,
		planContainsNextGenIssues,
	}),
);

export default createStructuredSelector<State, OwnProps, StateProps>({
	analyticsAttributes: getAnalyticsAttributes,
	colourByConfiguration: getColorByViewSettings,
	grouping: getVisualisationGrouping,
	groupingByMultiValueCustomField: isGroupByMultiValueCustomField,
	showSprints: getShowSprints,
});
