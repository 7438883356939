import type { Team } from './types';

export const SET = 'state.domain.teams-additional.SET' as const;
export type SetActionPayload = Team[];

export type SetAction = {
	type: typeof SET;
	payload: SetActionPayload;
};

export const setAdditionalTeams = (payload: SetActionPayload) => ({
	type: SET,
	payload,
});
