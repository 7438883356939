import React from 'react';
import { RadioGroup } from '@atlaskit/radio';
import { useIntl } from '@atlassian/jira-intl';
import type { OptionType } from '@atlassian/jira-portfolio-3-common/src/select/types';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types';
import { getFieldKey } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/sort-utils';
import { ISSUE_FIELDS } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import {
	SORT_DIRECTION,
	type SortDirection,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import { KEYS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/interaction-constants';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import traceUFOPress from '@atlassian/react-ufo/trace-press';
import ViewSettingSelect from '../view-setting-select';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';
import {
	getSortConfiguration,
	getOptionValueLabels,
	getStandardFieldsOptions,
	getCustomFieldsOptions,
} from './utils';

function SortBy({
	changeSort,
	customFields,
	dateConfiguration,
	issuePrioritiesIdList,
	isDefaultMenuOpen = false,
	onMenuToggle,
	planningUnit,
	sorting,
}: Props) {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleOnChange = ({ defaultDirection, value }: OptionType) => {
		traceUFOPress(KEYS.SORT_BY_FIELD);

		const sortConfiguration = getSortConfiguration(String(value), customFields, defaultDirection);
		changeSort(sortConfiguration);

		const [actionSubject, action, actionSubjectId] =
			PRODUCT_ANALYTICS_EVENT_NAMES.SORT_BY_CLICKED.split(' ');
		fireUIAnalytics(createAnalyticsEvent({ actionSubject, action }), actionSubjectId, {
			newPlanSettingValue: sortConfiguration.isCustomField
				? `customfield_${sortConfiguration.type}`
				: getFieldKey(sortConfiguration.field, planningUnit),
		});
	};

	const onDirectionSelect = (direction: SortDirection): void => {
		changeSort({ ...sorting, direction });
	};

	const optionLabels = getOptionValueLabels(intl, customFields, planningUnit);

	const standardFieldsOptions = getStandardFieldsOptions(
		dateConfiguration,
		optionLabels,
		issuePrioritiesIdList,
	);
	const customFieldsOptions = getCustomFieldsOptions(customFields, dateConfiguration);

	const sortDirectionsOptions = [
		{
			name: 'sortDirection',
			value: SORT_DIRECTION.ASCENDING,
			label: intl.formatMessage(messages.ascending),
			testId:
				'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.sort-by.ascending',
		},
		{
			name: 'sortDirection',
			value: SORT_DIRECTION.DESCENDING,
			label: intl.formatMessage(messages.descending),
			testId:
				'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.sort-by.descending',
		},
	];

	return (
		<>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<tr className={sorting.field !== ISSUE_FIELDS.RANK ? '' : styles.row}>
				<td>{intl.formatMessage(messages.sortByLabel)}</td>
				<td colSpan={2}>
					<ViewSettingSelect
						classNamePrefix="portfolio-3-portfolio_app-simple-plans_main_tabs_roadmap_view-settings_sort-by"
						customFieldsOptions={customFieldsOptions}
						handleOnChange={handleOnChange}
						isDefaultMenuOpen={isDefaultMenuOpen}
						onMenuToggle={onMenuToggle}
						standardFieldsOptions={standardFieldsOptions}
						selectedFieldId={sorting.field}
						label={intl.formatMessage(messages.sortByLabel)}
					/>
				</td>
			</tr>
			{sorting.field !== ISSUE_FIELDS.RANK && (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				<tr className={styles.row}>
					<td />
					<td colSpan={2}>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.radioOptions}>
							<RadioGroup
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								onChange={(e) => onDirectionSelect(e.target.value as SortDirection)}
								options={sortDirectionsOptions}
								value={sorting.direction}
							/>
						</div>
					</td>
				</tr>
			)}
		</>
	);
}

export default SortBy;
