import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types';
import {
	type ComponentFilter,
	COMPONENT_FILTER_ID,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import type { Component } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { getComponents } from '../../components';
import { getFiltersViewSettings } from '../../view-settings';
import { applyFilter as applyAttributeFilter } from '../attribute-filter';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ComponentFilter } from '../../../state/domain/view-settings/filters/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { COMPONENT_FILTER_ID } from '../../../state/domain/view-settings/filters/types';
export const applyFilter = (
	issue: Issue,
	filter: ComponentFilter,
	optimizedMode: boolean,
): boolean => applyAttributeFilter(issue, filter.value, 'components', optimizedMode);

export const getComponentFilterPure = (state: State): ComponentFilter =>
	getFiltersViewSettings(state)[COMPONENT_FILTER_ID] || {
		id: COMPONENT_FILTER_ID,
		value: [],
	};

export const getComponentFilter = createSelector(
	[getComponents, getComponentFilterPure],
	(components: Component[], pureFilter: ComponentFilter): ComponentFilter => {
		const { value = [] } = pureFilter;
		const componentIds = new Set(components.map(({ id }) => id));
		const existingComponents = value.filter((component: number) => componentIds.has(component));
		return {
			id: COMPONENT_FILTER_ID,
			value: existingComponents,
		};
	},
);
