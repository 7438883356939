import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { getFilterOptionsViewSettings } from '../../view-settings';

export const applyOption = (
	filteredIssues: Issue[] = [],
	ancestorsAndDescendants: {
		[key: string]: {
			ancestors: Issue[];
			descendants: Issue[];
		};
	},
	showFullHierarchy: boolean,
): Issue[] => {
	const result: Issue[] = [];

	const uniqIds = new Set<string>();
	const addItem = (item: Issue) => {
		if (!uniqIds.has(item.id)) {
			uniqIds.add(item.id);
			result.push(item);
		}
	};

	for (const issue of filteredIssues) {
		const { ancestors, descendants } = ancestorsAndDescendants[issue.id];
		ancestors.forEach((ancestor) => addItem(ancestor));

		addItem(issue);

		if (showFullHierarchy) {
			descendants.forEach((descendant) => addItem(descendant));
		}
	}

	return result;
};

export const getShowFullHierarchy = (state: State): boolean =>
	getFilterOptionsViewSettings(state).includeDescendants;
