import {
	getRowConfigs,
	getScrollIntoViewIndex,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/grid';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export default createStructuredSelector<State, StateProps>({
	scrollIntoViewIndex: getScrollIntoViewIndex,
	rowConfigs: getRowConfigs,
});
