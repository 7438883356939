import { getIssueSources, getPlan } from '../../query/plan';
import { getProjects } from '../../query/projects';
import type { State } from '../../state/types';
import type { StateProps } from './types';

export const mapStateToProps = (state: State): StateProps => ({
	planId: getPlan(state).id,
	projects: getProjects(state),
	issueSources: getIssueSources(state),
});
