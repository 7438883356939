import {
	SET_TEXT_FILTER,
	SET_STATUS_FILTER,
	type SetTextFilterAction,
	type SetStatusFilterAction,
} from './actions';
import type { ReleasesFilter } from './types';

const initialState: ReleasesFilter = {
	textFilter: '',
	statusFilter: [],
};

type Action = SetTextFilterAction | SetStatusFilterAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: ReleasesFilter = initialState, action: Action) => {
	switch (action.type) {
		case SET_TEXT_FILTER:
			return {
				...state,
				textFilter: action.textFilter,
			};
		case SET_STATUS_FILTER:
			return {
				...state,
				statusFilter: action.statusFilter,
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
