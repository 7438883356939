import type { Dispatch } from 'redux';
import { updateIssueOrInlineCreate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { DispatchProps } from './types';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch: Dispatch) => ({
	onChange: (issue: ScopeIssue, associatedIssuesIds: string[]) => {
		dispatch(
			updateIssueOrInlineCreate({
				id: issue.id,
				// field to update data in BE (associatedIssues instead of associatedIssueIds)
				associatedIssues: associatedIssuesIds,
				// field to update data in FE store
				associatedIssueIds: associatedIssuesIds,
			}),
		);
	},
});

export default mapDispatchToProps;
