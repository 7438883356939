/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import CheckIcon from '@atlaskit/icon/core/migration/check-mark--editor-done';
import CrossIcon from '@atlaskit/icon/core/migration/close--editor-close';
import { Inline, Text, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { SuggestedChildIssue } from '@atlassian/jira-issue-fetch-services/src/services/issue-breakdown-data-fetcher/types';
import type { ChildIssueType } from '@atlassian/jira-issue-view-common-types/src/child-issue-type';
import { useAiWorkBreakDownAnalytics } from '../../../common/analytics';
import { Dropdown } from './dropdown';
import { IconButton } from './icon-button';
import messages from './messages';
import { getAnalyticsIssueTypesCount } from './utils';

type Props = {
	item: SuggestedChildIssue;
	selectedIssueType: ChildIssueType | undefined;
	childIssueTypes: ChildIssueType[];
	channelId: string | null;
	onClickAccept(issues: Array<SuggestedChildIssue>): void;
	onClickRemove: () => void;
	setSuggestedChildIssueType: (updatedItem: SuggestedChildIssue) => void;
	isLoadingBulkAccept?: boolean;
};

const componentSelector =
	'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.suggestion-list-item.icon-button';

export const SuggestionListItem = ({
	item,
	selectedIssueType,
	childIssueTypes,
	channelId,
	onClickAccept,
	onClickRemove,
	setSuggestedChildIssueType,
	isLoadingBulkAccept,
}: Props) => {
	const { formatMessage } = useIntl();
	const { fireTrack } = useAiWorkBreakDownAnalytics({ channelId });

	if (!selectedIssueType) {
		return null;
	}

	const handleClickAccept = () => {
		const issueTypes = getAnalyticsIssueTypesCount(childIssueTypes, [item]);
		fireTrack('aiResult actioned', 'suggestedIssueAccepted', {
			aiResultAction: 'recommendationAccepted',
			issueTypes,
		});
		onClickAccept([item]);
	};

	return (
		<div
			css={cardStyles}
			data-testid="portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.suggestion-list-item.card"
		>
			<Inline xcss={[inlineCardItemStyles]} space="space.100" alignBlock="center">
				<Dropdown
					item={item}
					selectedIssueType={selectedIssueType}
					childIssueTypes={childIssueTypes}
					setSuggestedChildIssueType={setSuggestedChildIssueType}
				/>

				<Text maxLines={1}>{item.summary}</Text>
			</Inline>

			<Inline alignBlock="center" space="space.025">
				<IconButton
					icon={CheckIcon}
					testId="portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.suggestion-list-item.accept-button"
					label={formatMessage(messages.actionAccept)}
					componentSelector={componentSelector}
					onClick={handleClickAccept}
					isDisabled={isLoadingBulkAccept}
				/>
				<IconButton
					icon={CrossIcon}
					testId="portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.suggestion-list-item.remove-button"
					label={formatMessage(messages.actionRemove)}
					componentSelector={componentSelector}
					onClick={onClickRemove}
					isDisabled={isLoadingBulkAccept}
				/>
			</Inline>
		</div>
	);
};

const inlineCardItemStyles = xcss({
	width: '100%',
	height: '100%',
});

const cardStyles = css({
	display: 'flex',
	alignItems: 'center',
	position: 'relative',
	lineHeight: '20px',
	height: '40px',
	justifyContent: 'space-between',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('color.background.neutral.subtle', colors.N0),
	padding: `0 ${token('space.200', '16px')}`,
	margin: '0',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderBottom: `1px solid ${token('color.background.accent.gray.subtler', colors.N40)}`,
	'&:hover, &:focus-within': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		background: token('color.background.neutral.subtle.hovered', colors.N30),
		textDecoration: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	"[data-component-selector='ai-work-breakdown.ui.issue-breakdown-draft-list.suggestion-list-item.icon-button']":
		{ opacity: '0' },
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	"&:focus-within [data-component-selector='ai-work-breakdown.ui.issue-breakdown-draft-list.suggestion-list-item.icon-button'], &:hover [data-component-selector='ai-work-breakdown.ui.issue-breakdown-draft-list.suggestion-list-item.icon-button'], &:focus [data-component-selector='ai-work-breakdown.ui.issue-breakdown-draft-list.suggestion-list-item.icon-button'], &:active [data-component-selector='ai-work-breakdown.ui.issue-breakdown-draft-list.suggestion-list-item.icon-button']":
		{ opacity: '1' },
});
