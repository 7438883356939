import React, { useEffect, useState } from 'react';
import FeedbackIcon from '@atlaskit/icon/glyph/feedback';
import { token } from '@atlaskit/tokens';
import {
	setUserProperties,
	getUserProperty,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type FetchError from '@atlassian/jira-fetch/src/utils';
import { AkFlag as Flag, FlagGroup } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { Link, useRouter } from '@atlassian/react-resource-router';
import messages from './messages';

export const ARJ_IDEAS_DISMISSED_PROPERTY_KEY = 'jpd.arjxideas.changeboarding.flag.dismiss';
const READ_MORE_LINK = '';
const JPD_LINK = '/issues/?jql=projectType=product_discovery order by created';

export const AssociatedIssuesChangeboardingFlag = () => {
	const { formatMessage } = useIntl();
	const accountId = useAccountId();
	const [isFlagAvailable, setIsFlagAvailable] = useState(false);
	const [_, routerActions] = useRouter();

	useEffect(() => {
		const fetch = async () => {
			try {
				await getUserProperty(accountId ?? '', ARJ_IDEAS_DISMISSED_PROPERTY_KEY);
			} catch (error) {
				// Show info only when user didin't dismiss the flag (so the preperty is not set -> expected 404 error)
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
				if ((error as any as FetchError).statusCode === 404) {
					setIsFlagAvailable(true);
				}
			}
		};

		fetch();
	}, [accountId]);

	const onDismissChangelogFlag = async () => {
		setIsFlagAvailable(false);
		try {
			// The specific value being set doesn't matter as long as the property *has* been set
			await setUserProperties(accountId ?? '', ARJ_IDEAS_DISMISSED_PROPERTY_KEY, 'true');
		} catch (error) {
			fireErrorAnalytics({
				meta: {
					id: `setUserProperties${ARJ_IDEAS_DISMISSED_PROPERTY_KEY}`,
					teamName: 'jpd-juno',
				},
				error: new Error('Cannot set user property.'),
			});
		}
	};

	const description = formatMessage(messages.changeboardingFlagDescription, {
		a: (chunks: React.ReactNode[]) => (
			<Link href={JPD_LINK} target="_blank" rel="noopener noreferrer">
				{chunks}
			</Link>
		),
	});

	return (
		<FlagGroup onDismissed={onDismissChangelogFlag}>
			{isFlagAvailable ? (
				<Flag
					id="arjx-ideas-integration-changeboarding-flag"
					key="arjx-ideas-integration-changeboarding-flag"
					icon={<FeedbackIcon label="" primaryColor={token('color.text.accent.purple')} />}
					title={formatMessage(messages.changeboardingFlagTitle)}
					description={description}
					actions={[
						{
							content: formatMessage(messages.changeboardingFlagReadMore),
							onClick: () => routerActions.push(READ_MORE_LINK),
						},
						{
							content: formatMessage(messages.changeboardingFlagDismiss),
							onClick: onDismissChangelogFlag,
						},
					]}
				/>
			) : null}
		</FlagGroup>
	);
};
