import type { Sprint } from './types';

export const RESET = 'state.domain.sprints.RESET' as const;

export type ResetActionPayload = Sprint[];

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export const reset = (payload: ResetActionPayload) => ({
	type: RESET,
	payload,
});
