import React, { Component, type KeyboardEvent } from 'react';
import * as R from 'ramda';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import Lozenge from '@atlaskit/lozenge';
import { xcss, Box } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { injectIntl, FormattedMessage, type IntlShape } from '@atlassian/jira-intl';
import { isTeamAndResourceDetails } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira/types';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import {
	ENTITY,
	SCENARIO_TYPE,
	SCHEDULE_MODE,
	PLANNING_UNITS,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import messagesForWhatChanged from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, State } from './types';

const SpanWithTitle = ({ value }: { value: string }) => (
	<EllipsedWithTooltip content={value}>
		<span>{value}</span>
	</EllipsedWithTooltip>
);

const SpanWithTitleAndParenthetical = ({
	value,
	parenthetical,
}: {
	value: string;
	parenthetical: string;
}) => (
	<Tooltip content={`${value} (${parenthetical})`} position="mouse" tag="span">
		{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
		<div className={styles['attribute-with-parenthetical']}>
			<div>{value}&nbsp;</div>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.parenthetical}>
				<div>(</div>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.ellipsed}>{parenthetical}</div>
				<div>)</div>
			</div>
		</div>
	</Tooltip>
);

const ChevronContainer = ({ children }: { children: React.ReactNode }) =>
	isVisualRefreshEnabled() ? (
		<Box as="span" xcss={ChevronContainerStyles}>
			{children}
		</Box>
	) : (
		/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */
		<span className={`${styles.icon} ${styles.toggle}`}>{children}</span>
	);

// eslint-disable-next-line jira/react/no-class-components
class WhatChangedCell extends Component<
	Props & {
		intl: IntlShape;
	},
	State
> {
	isExpanded = () => {
		const { expandedChanges, id, category } = this.props;
		return expandedChanges[category].includes(id);
	};

	renderChevron() {
		const isExpanded = this.isExpanded();
		return (
			<ChevronContainer>
				{isExpanded ? (
					<ChevronDownIcon label="" size="medium" />
				) : (
					<ChevronRightIcon label="" size="medium" />
				)}
			</ChevronContainer>
		);
	}

	onKeyDownHandler(e: KeyboardEvent<EventTarget>) {
		if (e.key === 'Enter') {
			const { expandChange, collapseChange, id, category } = this.props;
			this.isExpanded() ? collapseChange(id, category) : expandChange(id, category);
		}
	}

	render() {
		const {
			attributeName,
			intl,
			changeType,
			changeCount,
			expandChange,
			collapseChange,
			id,
			category,
			changeDetails,
			teamsById,
		} = this.props;
		const messages = {
			...messagesForWhatChanged,
			assignee: commonMessages.assignee,
			crossProjectVersion: commonMessages.crossProjectRelease,
			dueDate: commonMessages.dueDate,
			name: commonMessages.name,
			status: commonMessages.status,
		};
		const isExpanded = this.isExpanded();

		switch (changeType) {
			case SCENARIO_TYPE.ADDED:
			case SCENARIO_TYPE.ADDEDEXISTING: {
				const label =
					changeType === SCENARIO_TYPE.ADDED ? messages.createdLabel : messages.addedLabel;

				if (changeCount > 0) {
					return (
						<button
							aria-expanded={isExpanded}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className={`${styles.multiple} ${styles.toggle}`}
							onClick={() =>
								isExpanded ? collapseChange(id, category) : expandChange(id, category)
							}
							onKeyDown={this.onKeyDownHandler}
						>
							{this.renderChevron()}
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<span className={styles.label}>
								<Lozenge appearance="new">{intl.formatMessage(label)}</Lozenge>
								{' +'}
								<FormattedMessage {...messages.changeCount} values={{ count: changeCount }} />
							</span>
						</button>
					);
				}
				return <Lozenge appearance="new">{intl.formatMessage(label)}</Lozenge>;
			}

			case SCENARIO_TYPE.MULTIPLE: {
				return (
					<button
						aria-expanded={isExpanded}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={`${styles.multiple} ${styles.toggle}`}
						onClick={() => (isExpanded ? collapseChange(id, category) : expandChange(id, category))}
						onKeyDown={this.onKeyDownHandler}
					>
						{this.renderChevron()}
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<span className={styles.label}>
							<FormattedMessage {...messages.changeCount} values={{ count: changeCount }} />
						</span>
					</button>
				);
			}

			case SCENARIO_TYPE.DELETED: {
				if (category === ENTITY.RELEASE || category === ENTITY.ISSUE) {
					return (
						<Lozenge appearance="removed">{intl.formatMessage(messages.excludedLabel)}</Lozenge>
					);
				}
				return <Lozenge appearance="removed">{intl.formatMessage(messages.deletedLabel)}</Lozenge>;
			}

			case 'EXCLUDED':
				return <Lozenge appearance="removed">{intl.formatMessage(messages.excludedLabel)}</Lozenge>;

			default: {
				if (attributeName === 'timeEstimate') {
					const {
						plan: { planningUnit },
					} = this.props;
					if (planningUnit === PLANNING_UNITS.DAYS) {
						return <SpanWithTitle value={intl.formatMessage(messages.timeEstimateDays)} />;
					}
					return <SpanWithTitle value={intl.formatMessage(messages.timeEstimateHours)} />;
				}

				if (category === ENTITY.ISSUE && attributeName === 'associatedIssueIds') {
					const message = intl.formatMessage(messages.ideasChanged);
					return <SpanWithTitle value={message} />;
				}

				if (
					category === ENTITY.RESOURCE &&
					attributeName !== 'addedMember' &&
					attributeName !== 'removedMember'
				) {
					const resourceChange =
						changeDetails &&
						changeDetails.details &&
						isTeamAndResourceDetails(changeDetails) &&
						changeDetails.details.changes.find((change) => change.resourceId === id);

					const additionalInfo =
						resourceChange && resourceChange.personTitle ? `(${resourceChange.personTitle})` : '';

					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					const change = messages[(attributeName as keyof typeof messages) || '']
						? // @ts-expect-error - TS2538 - Type 'undefined' cannot be used as an index type.
							`${intl.formatMessage(messages[attributeName])} ${additionalInfo}`
						: '';
					return <SpanWithTitle value={change} />;
				}

				if (
					category === ENTITY.SPRINT &&
					isDefined(attributeName) &&
					attributeName.startsWith('teamVelocity-')
				) {
					const schedulingMode = R.path(['details', 'values', 'schedulingMode'], changeDetails);
					const teamId: string =
						R.path(['details', 'values', attributeName || '', 'teamId'], changeDetails) || '';
					const team = teamsById[teamId] || {};

					const attrTitle = intl.formatMessage(
						schedulingMode === SCHEDULE_MODE.kanban
							? messages.iterationVelocity
							: messages.plannedCapacity,
					);

					return <SpanWithTitleAndParenthetical value={attrTitle} parenthetical={team.title} />;
				}

				if (attributeName && attributeName.startsWith('issueLink-')) {
					const scenarioType =
						R.path(['details', 'values', attributeName || '', 'scenarioType'], changeDetails) ||
						R.path(['details', 'originals', attributeName || '', 'scenarioType'], changeDetails);
					let message;
					switch (scenarioType) {
						case SCENARIO_TYPE.ADDED:
							message = intl.formatMessage(messages.issueLinkCreated);
							break;
						case SCENARIO_TYPE.DELETED:
							message = intl.formatMessage(messages.issueLinkRemoved);
							break;
						default:
							throw new Error(`Unexpected issue link scenarioType: ${scenarioType || ''}`);
					}
					return <span title={message}>{message}</span>;
				}

				if (attributeName && attributeName.startsWith('customField')) {
					const { customFields } = this.props;
					const customFieldId = attributeName.split('-')[1];
					const field =
						customFields &&
						// eslint-disable-next-line @typescript-eslint/no-shadow
						customFields.find((field) => field.id.toString() === customFieldId);

					const fieldTitle = field ? field.title : '';

					if (attributeName.startsWith('customFieldValueAdded')) {
						return (
							<SpanWithTitle
								value={intl.formatMessage(messages.customFieldAdded, {
									field: fieldTitle,
								})}
							/>
						);
					}

					if (attributeName.startsWith('customFieldValueRemoved')) {
						return (
							<SpanWithTitle
								value={intl.formatMessage(messages.customFieldRemoved, {
									field: fieldTitle,
								})}
							/>
						);
					}

					return field ? <SpanWithTitle value={field.title} /> : '';
				}
				let change = '';
				if (attributeName === 'fixVersions') {
					change = intl.formatMessage(commonMessages.releases);
				} else {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					change = messages[(attributeName as keyof typeof messages) || '']
						? // @ts-expect-error - TS2538 - Type 'undefined' cannot be used as an index type.
							intl.formatMessage(messages[attributeName])
						: '';
				}
				return <SpanWithTitle value={change} />;
			}
		}
	}
}

const ChevronContainerStyles = xcss({
	cursor: 'pointer',
	paddingRight: 'space.050',
	verticalAlign: 'middle',
	fontSize: 'unset',
	color: 'color.text',
});

export default injectIntl(WhatChangedCell);
