import { getIssueTypes } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types';
import { getProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects';
import { getIssueMapById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import type { QueryProps } from './types';

function mapStateToProps(state: State): QueryProps {
	return {
		issuesById: getIssueMapById(state),
		issueTypes: getIssueTypes(state),
		projectsById: getProjectsById(state),
	};
}

export default mapStateToProps;
