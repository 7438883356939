import type { OptionType } from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import {
	getSelectOptions,
	getCustomFieldsById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields';
import { getCustomLabelsByField } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-labels';
import {
	getVisualisationGrouping,
	getCustomFieldValuesGroups,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import {
	createStructuredSelector,
	createSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { CustomFieldTypes } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import {
	getCustomFieldIdFromCustomFieldGrouping,
	isRoadmapGroupedByCustomField,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/custom-fields';
import type { OwnProps, StateProps } from './types';

const getGroupingCustomFieldType = createSelector(
	[getCustomFieldsById, getVisualisationGrouping],
	(customFieldsById, grouping) => {
		if (!isRoadmapGroupedByCustomField(grouping)) {
			return '';
		}

		const fieldId = parseInt(getCustomFieldIdFromCustomFieldGrouping(grouping), 10);
		if (isDefined(customFieldsById)) {
			return customFieldsById[fieldId]?.type.key;
		}
		return '';
	},
);

const getCustomFieldOptions = createSelector(
	[getVisualisationGrouping, getSelectOptions],
	(grouping, selectOptions): OptionType[] => {
		if (!isRoadmapGroupedByCustomField(grouping)) {
			return [];
		}

		const fieldId = parseInt(getCustomFieldIdFromCustomFieldGrouping(grouping), 10);
		return selectOptions.filter((option) => option.fieldId === fieldId);
	},
);

const getCustomLabelFieldOptions = createSelector(
	[getGroupingCustomFieldType, getCustomLabelsByField, getVisualisationGrouping],
	(groupingCustomFieldType, customLabelsByField, grouping): string[] => {
		if (!isRoadmapGroupedByCustomField(grouping)) {
			return [];
		}

		if (groupingCustomFieldType === CustomFieldTypes.Labels) {
			const fieldId = parseInt(getCustomFieldIdFromCustomFieldGrouping(grouping), 10);
			return customLabelsByField[fieldId] || [];
		}
		return [];
	},
);

export default createStructuredSelector<State, OwnProps, StateProps>({
	customFieldValuesGroups: getCustomFieldValuesGroups,
	customLabelFieldOptions: getCustomLabelFieldOptions,
	grouping: getVisualisationGrouping,
	groupingCustomFieldType: getGroupingCustomFieldType,
	selectOptions: getCustomFieldOptions,
});
