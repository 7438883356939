import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type { OptionType } from '@atlassian/jira-portfolio-3-common/src/select/types';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { Component as ApiComponent } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import MultiSelectCell from '../../multi-select-cell/view.tsx';
import messages from './messages';
import type { Props } from './types';

const getComponentOption = (component: ApiComponent): OptionType => ({
	label: component.name,
	value: component.id.toString(),
});

export const getSelectableComponents = ({
	issue,
	componentsByProjectId,
}: {
	issue: Props['issue'];
	componentsByProjectId: Props['componentsByProjectId'];
}): ApiComponent[] => {
	if (!issue.project) {
		return [];
	}

	return componentsByProjectId[issue.project.toString()] || [];
};

export const getIssueComponents = ({
	issue,
	componentsByProjectId,
}: {
	issue: Props['issue'];
	componentsByProjectId: Props['componentsByProjectId'];
}): ApiComponent[] =>
	getSelectableComponents({ issue, componentsByProjectId }).filter((component: ApiComponent) =>
		(issue.components || []).includes(component.id),
	);

export const Components = ({
	attribute,
	issue,
	isDisabled,
	isReadOnly,
	componentsByProjectId,
	onChange,
}: Props) => {
	const intl = useIntl();

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const getIssueComponents = () =>
		getSelectableComponents({ issue, componentsByProjectId }).filter((component: ApiComponent) =>
			(issue.components || []).includes(component.id),
		);

	const fetchOptions = (query: string) =>
		Promise.resolve(
			getSelectableComponents({ issue, componentsByProjectId })
				.map(getComponentOption)
				.filter((option) => option.label.toLowerCase().includes(query.toLowerCase())),
		);

	const handleComponentsChange = (
		// eslint-disable-next-line @typescript-eslint/no-shadow
		issue: ScopeIssue,
		options?: OptionType | OptionType[] | null,
	) => {
		if (!options) {
			return;
		}

		onChange(
			issue,
			options.map((item: OptionType) => Number(item.value)),
		);
	};

	return (
		<MultiSelectCell
			attribute={attribute}
			messages={{
				placeholder: intl.formatMessage(messages.componentsLabel),
				noLabels: intl.formatMessage(messages.noComponents),
				noLabelsLeft: intl.formatMessage(messages.noComponentsLeft),
				noMatch: intl.formatMessage(messages.noMatch),
			}}
			getValues={() => getIssueComponents().map(getComponentOption)}
			fetchOptions={fetchOptions}
			isReadOnly={isReadOnly}
			isDisabled={isDisabled}
			issue={issue}
			onChange={handleComponentsChange}
		/>
	);
};

export default Components;
