import type { ActiveFilterViewState } from './types';

export const CHANGE_ACTIVE_FILTER_VIEW = 'state.domain.views.SET_ACTIVE_FILTER_VIEW' as const;

export type ChangeActiveFilterViewAction = {
	type: typeof CHANGE_ACTIVE_FILTER_VIEW;
	payload: ActiveFilterViewState;
};

export const changeActiveFilterView = (
	payload: ActiveFilterViewState,
): ChangeActiveFilterViewAction => ({
	type: CHANGE_ACTIVE_FILTER_VIEW,
	payload,
});
