import React, { createContext, useContext } from 'react';
import type { Props, Params, StateProps, ZIndexProps } from './types';

const context = createContext<StateProps['zIndex']>(() => 0);

export const ZIndexProvider = ({ children, zIndex }: Props) => (
	<context.Provider value={zIndex}>{children}</context.Provider>
);

export const useZIndex = (params: Params) => useContext(context)(params);

export const ZIndex = (props: ZIndexProps) => {
	const zIndex = useZIndex(props);
	return props.children(zIndex);
};
