import React, { useEffect, useState, type ReactNode } from 'react';
import { SpotlightTarget as AKSpotlightTarget } from '@atlaskit/onboarding';
import { EPIC_LEVEL } from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import type { CtpSpotlights } from '../../controllers/ctp-spotlights';
import { CttSpotlights } from '../../controllers/ctt-spotlights';
import type { Spotlights } from '../../controllers/spotlights';

export const SpotlightTarget = ({
	children,
	delayOn,
	name,
}: {
	children: ReactNode | ReactNode[];
	/** When navigating between Plan settings sub-menu and the main navigation menu,
	 * the animation implementation made the spotlight target behave unexpectedly.
	 *
	 * This property is a dependency to an effect which delays and remounts the spotlight target
	 * whenever the value changes.
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	delayOn?: any;
	name: Spotlights | CttSpotlights | CtpSpotlights;
}) => {
	const [withSpotlight, setWithSpotlight] = useState(true);

	useEffect(() => {
		if (delayOn === undefined) {
			return;
		}

		setWithSpotlight(false);
		setTimeout(() => setWithSpotlight(true), 1000);
	}, [delayOn]);

	return withSpotlight ? (
		<AKSpotlightTarget name={name}>{children}</AKSpotlightTarget>
	) : (
		<>{children}</>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EpicLevelSpotlightTarget = (children: any, isGroupByNone: boolean, level?: number) =>
	level === EPIC_LEVEL && isGroupByNone ? (
		<SpotlightTarget delayOn name={CttSpotlights.EpicSection} key={CttSpotlights.EpicSection}>
			{children}
		</SpotlightTarget>
	) : (
		children
	);
