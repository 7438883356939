import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { connect } from '@atlassian/jira-react-redux';
import { mapDispatchToProps } from './command';
import { mapStateToProps } from './query';
import type { StateProps, DispatchProps, OwnProps } from './types';
import { ColumnDragHandle as View } from './view';

export const ColumnDragHandle = connect<StateProps, DispatchProps, OwnProps, State>(
	mapStateToProps,
	mapDispatchToProps,
)(View);
