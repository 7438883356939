import React, { type FocusEvent } from 'react';
import { withSlots, slots } from '@atlassian/jira-portfolio-3-portfolio/src/common/component-slots';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

export const TextFieldRenderer = ({ attribute, isReadOnly, issue, TextField, onChange }: Props) => {
	const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
		if (isReadOnly) {
			return;
		}
		if (event.target instanceof HTMLInputElement) {
			onChange(issue, attribute, event.target.value);
		}
	};

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={`${styles.textFieldWrapper} ${isReadOnly ? styles.readOnly : ''}`}>
			<TextField
				isReadOnly={isReadOnly}
				isTransparentBackground
				maxLength={255}
				onBlur={handleBlur}
				type="text"
				value={issue.customFields && issue.customFields[attribute]}
			/>
		</div>
	);
};

export default withSlots({ TextField: slots.CellTextField })(TextFieldRenderer);
