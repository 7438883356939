import type { LabelGroup, LabelGroupsState } from './types';

export const RESET = 'state.domain.view-settings.label-groups.RESET' as const;

export const CLEAR_ALL = 'state.domain.view-settings.label-groups.CLEAR_ALL' as const;

export const ADD = 'state.domain.view-settings.label-groups.ADD' as const;

export const UPDATE = 'state.domain.view-settings.label-groups.UPDATE' as const;

export const REMOVE = 'state.domain.view-settings.label-groups.REMOVE' as const;

export type ResetAction = {
	type: typeof RESET;
	payload: LabelGroupsState;
};

export type ClearAllAction = {
	type: typeof CLEAR_ALL;
};

export type AddAction = {
	type: typeof ADD;
	payload: LabelGroup;
};

export type UpdateActionPayload = {
	id: string;
	patch: Partial<LabelGroup>;
};

export type UpdateAction = {
	type: typeof UPDATE;
	payload: UpdateActionPayload;
};

export type RemoveAction = {
	type: typeof REMOVE;
	payload: string;
};

export const reset = (payload: LabelGroupsState): ResetAction => ({
	type: RESET,
	payload,
});

export const clearAll = (): ClearAllAction => ({
	type: CLEAR_ALL,
});

export const add = (payload: LabelGroup): AddAction => ({
	type: ADD,
	payload,
});

export const update = (payload: UpdateActionPayload): UpdateAction => ({
	type: UPDATE,
	payload,
});

export const remove = (payload: string): RemoveAction => ({
	type: REMOVE,
	payload,
});
