import React, { useEffect, useState } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import DialogContent from '../dialog-content/view.tsx';
import messages from './messages';
import type { Props } from './types';
import { getGroupOptionsFromUsers } from './utils';

export default function CustomUserPickerOptions({
	fetchUserList,
	groups,
	grouping,
	hierarchyItem,
	onGroupClick,
	userList,
	userPickerUIState,
}: Props) {
	const { issueId, isFetchUserListInProgress } = userPickerUIState;
	const [isLoading, setIsLoading] = useState(isFetchUserListInProgress);

	useEffect(() => {
		fetchUserList('', issueId);
	}, [fetchUserList, issueId]);

	const previousFetchUserListInProgress = usePrevious(isFetchUserListInProgress);
	useEffect(() => {
		if (previousFetchUserListInProgress === true && isFetchUserListInProgress === false) {
			setIsLoading(false);
		}
	}, [isFetchUserListInProgress, previousFetchUserListInProgress]);

	const queryChange = (query: string) => {
		setIsLoading(true);
		fetchUserList(query, issueId);
	};

	const { formatMessage } = useIntl();

	return (
		<DialogContent
			groupOptions={getGroupOptionsFromUsers(grouping, groups, userList)}
			hierarchyItem={hierarchyItem}
			isLoading={isLoading}
			onGroupClick={onGroupClick}
			onQueryChange={queryChange}
			searchPlaceholder={formatMessage(messages.userSearchPlaceholder)}
		/>
	);
}
