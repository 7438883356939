import React, { useState, useRef, useEffect } from 'react';
import * as R from 'ramda';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import {
	isEstimated,
	getEstimateValue,
	getEstimateRollupValue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/estimation';
import { withSlots, slots } from '@atlassian/jira-portfolio-3-portfolio/src/common/component-slots';
import RollupIcon from '@atlassian/jira-portfolio-3-portfolio/src/common/icons/rollup.tsx';
import {
	PlanningUnits,
	type PlanningUnit,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import Cell from '../../column/cell/view';
import cellMessages from '../messages';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

const round = (number: number): number => Math.round(number * 1000) / 1000;

export const makeUpdate =
	({
		updateEstimate,
		planningUnit,
		issue,
		workingHours,
	}: {
		updateEstimate: Props['updateEstimate'];
		planningUnit: PlanningUnit;
		issue: Props['issue'];
		workingHours: Props['workingHours'];
	}) =>
	(value?: string | number | null): void => {
		let numberValue: number | null = null;
		if (typeof value === 'number') {
			numberValue = value;
		} else if (typeof value === 'string') {
			numberValue = parseFloat(value);
		}
		updateEstimate(
			Number.isNaN(numberValue) || !Number.isFinite(numberValue) ? null : numberValue,
			planningUnit,
			issue.id,
			workingHours,
		);
	};

export const EstimateCell = ({
	estimateRollUpForIssue,
	plan: { planningUnit },
	workingHours,
	issue,
	isScrolling,
	showOptimizations,
	isReadOnly = false,
	updateEstimate,
	showEstimateRollup,
	TextField,
}: Props) => {
	const inputElement = useRef<HTMLDivElement>(null);
	const { formatMessage } = useIntl();
	const [inEditMode, setInEditMode] = useState(false);

	const handleClickOutside = (event: MouseEvent) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		const current = inputElement.current as any;
		if (current && !current.contains(event.target)) {
			current.blur();
		}
	};

	useEffect(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('click', handleClickOutside);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		return () => window.removeEventListener('click', handleClickOutside);
	}, []);

	const estimateText = (() => {
		if (!isEstimated(planningUnit, issue)) {
			return '';
		}
		const estimateValue = getEstimateValue(planningUnit, issue, workingHours);
		return round(estimateValue).toString();
	})();

	const getPlanningUnit = () =>
		planningUnit === PlanningUnits.storyPoints ? 'storyPoints' : 'timeEstimate';

	const update = makeUpdate({ updateEstimate, planningUnit, issue, workingHours });

	const shouldShowRollup = () => {
		if (!estimateRollUpForIssue && planningUnit === PlanningUnits.storyPoints) {
			return false;
		}

		return showEstimateRollup && !inEditMode && !R.isNil(estimateRollUpForIssue);
	};

	const handleClickOnRollup = () => {
		setInEditMode(true);
		if (inputElement.current) {
			inputElement.current.focus();
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const getCellContent = (estimateText: string, issueSummary: string) => (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.container}>
			{shouldShowRollup() && (
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles.rollUp}
					key="rollup"
					onClick={handleClickOnRollup}
					onKeyDown={handleClickOnRollup}
					role="button"
					tabIndex={0}
				>
					<RollupIcon label="" />
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<span className={styles.rollupValue}>
						{getEstimateRollupValue(estimateRollUpForIssue, planningUnit, workingHours)}
					</span>
				</div>
			)}
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={`${styles.estimate} ${isReadOnly ? styles.readOnly : ''}`} ref={inputElement}>
				<TextField
					appearance="subtle"
					isAlignRight
					isReadOnly={isReadOnly}
					isTransparentBackground
					min={0}
					onBlur={() => {
						setInEditMode(false);
					}}
					onDone={update}
					onFocus={() => {
						setInEditMode(true);
					}}
					step="any"
					type="number"
					value={estimateText}
					aria-label={formatMessage(messages.estimateCellLabel, {
						issue: issueSummary,
					})}
				/>
			</div>
		</div>
	);

	const attribute = getPlanningUnit();

	const issueEstimateTooltipText = estimateText ? (
		<>
			{formatMessage(messages.issueEstimateTooltip)}
			{estimateText}
		</>
	) : (
		''
	);

	return (
		<Cell
			attribute={attribute}
			issue={issue}
			isScrolling={isScrolling}
			showOptimizations={!!showOptimizations}
		>
			{/* the tool tip content is intentionally set to null and its a work around instead of conditionally
            rendering the tooltip. Conditionally rendering the tooltip will cause it's children to remount causing
            focus issues in the text box.
            */}
			<Tooltip
				content={
					shouldShowRollup() ? (
						<>
							{issueEstimateTooltipText}
							{formatMessage(cellMessages.rolledUpEstimateToolip, {
								value: getEstimateRollupValue(estimateRollUpForIssue, planningUnit, workingHours),
							})}
						</>
					) : null
				}
			>
				{getCellContent(estimateText, issue.summary)}
			</Tooltip>
		</Cell>
	);
};

export default withSlots({ TextField: slots.CellTextField })(EstimateCell);
