import type { Sequence } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';

export const BulkCommitEntityType = {
	X_PROJECT_VERSION: 'X_PROJECT_VERSION',
	VERSION: 'VERSION',
	TEAM: 'TEAM',
	RESOURCE: 'RESOURCE',
	PLANNED_CAPACITY: 'PLANNED_CAPACITY',
	ISSUE: 'ISSUE',
	ISSUE_LINK: 'ISSUE_LINK',
} as const;

export type BulkCommitEntityType = (typeof BulkCommitEntityType)[keyof typeof BulkCommitEntityType];

export type BulkCommitRequestEntity = {
	generatedItemId?: number;
	itemKey: string;
	entityType: string;
};
export type BulkCommitRequest = {
	planId: number;
	scenarioId: number;
	sequence: Sequence;
	orderedCommitEntities: BulkCommitRequestEntity[];
	notifyWatchers: boolean;
};

export type BulkCommitResponseEntity = {
	itemKey: string;
	entityType: string;
	success: boolean;
	generatedId?: string;
	error?: {
		error: string;
		errorMessages: string[];
	};
	entity: {
		entity?: {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			[key: string]: any;
		};
	};
};

export type BulkCommitResponse = {
	sequenceChanges: Sequence;
	orderedCommitResults: BulkCommitResponseEntity[];
};
