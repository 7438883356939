import type { Dispatch } from 'redux';
import {
	addAtlassianTeam,
	type AddAtlassianTeamAction,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/teams';
import type { AtlassianTeamPayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/teams/api';
import {
	loadCreateTwpForm,
	resetCreateTwpForm,
	type LoadCreateTwpFormAction,
	type ResetCreateTwpFormAction,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/teams/create-team/actions';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	dispatch: Dispatch<AddAtlassianTeamAction | LoadCreateTwpFormAction | ResetCreateTwpFormAction>,
) => ({
	addAtlassianTeam: (payload: AtlassianTeamPayload) => {
		dispatch(addAtlassianTeam(payload));
	},
	initiateRequest: () => {
		dispatch(loadCreateTwpForm());
	},
	resetRequest: () => {
		dispatch(resetCreateTwpForm());
	},
});
