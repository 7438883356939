import { RESET, UPDATE_PARENT, type ResetAction, type UpdateParentAction } from './actions';
import type { HistoryIssues } from './types';

const initialState: HistoryIssues = [];

type Action = ResetAction | UpdateParentAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: HistoryIssues = initialState, action: Action): HistoryIssues => {
	switch (action.type) {
		case RESET:
			return action.payload;
		case UPDATE_PARENT: {
			const { id, parent } = action.payload;
			return state.map((historyIssue) => {
				if (historyIssue.id === id) {
					return {
						...historyIssue,
						parent,
					};
				}
				return historyIssue;
			});
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
