import React, { useCallback, type ComponentType } from 'react';
import { urls } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/custom-fields/api';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch';
import messages from './messages';
import type { Props, InjectProps, InjectedComponentProps } from './types';

export const messageDescriptors = {
	placeholder: messages.componentsLabel,
	noLabels: messages.noLabels,
	noLabelsLeft: messages.noLabelsLeft,
	noMatch: messages.noMatch,
};

export const OptionsProvider = (
	ComposedComponent: ComponentType<Omit<Props, keyof InjectedComponentProps>>,
) =>
	function (props: Omit<Props, keyof InjectProps | keyof InjectedComponentProps>) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const fetchOptions = useCallback(
			(customFieldId: string, projectId: number, issueTypeId: string) =>
				fetch(urls.getCustomFieldSettableOptions, {
					method: 'POST',
					body: { customFieldId, projectId, issueTypeId },
				})
					.then((response) => response.json())
					.then((data) =>
						data.map(({ id, value }: { id: number; value: string }) => ({
							value: String(id),
							label: value,
						})),
					),
			[],
		);

		return (
			<ComposedComponent {...props} fetchOptions={fetchOptions} messages={messageDescriptors} />
		);
	};
