import type { State } from './types';

export const RESET_REDUX_STORE = 'state.RESET_REDUX_STORE' as const;
export const RESTORE_REDUX_STORE = 'state.RESTORE_REDUX_STORE' as const;

export type ResetReduxStoreAction = {
	type: typeof RESET_REDUX_STORE;
};

export type RestoreReduxStoreAction = {
	type: typeof RESTORE_REDUX_STORE;
	payload: State;
};

export const resetReduxStore = (): ResetReduxStoreAction => ({
	type: RESET_REDUX_STORE,
});

export const restoreReduxStore = (initialState: State): RestoreReduxStoreAction => ({
	type: RESTORE_REDUX_STORE,
	payload: initialState,
});
