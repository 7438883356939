import type { Store } from 'redux';
import { getIssueTypesForIds } from '../../command/issue-type';
import { getProjectsForIds } from '../../command/project';
import { getSprintsForIds } from '../../command/sprint';
import { getReleasesForIds } from '../../command/versions';
import { getIssueTypesById } from '../../query/issue-types';
import { getProjectsById } from '../../query/projects';
import { getAllIssues } from '../../query/raw-issues/index.tsx';
import { type getSprintByIdMap, getAllSprintsIncludingDisabledByIdMap } from '../../query/sprints';
import { getVersionsById } from '../../query/versions';
import type { State } from '../../state/types';
import { makeRelationsMap, relaxedScheduler, type RelationsMap } from '../util';

/*

This observer scans state for references to missing entities and tries to load these entities.

It is not complete yet! If you hit a situation when you get missing relations, please update
the observer code to handle your case.

Also, we might want to migrate here fetching issue team and assignee as well.

Relations to be checked in state.domain:

+------------------------------+------------------------------+
| From                         | To                           |
|------------------------------|------------------------------|
| issues, originalIssues       | [x] project                  |
|                              | [ ] assignee                 |
|                              | [x] fixVersions              |
|                              | [ ] parent                   |
|                              | [x] type                     |
|                              | [x] externalSprint           |
|                              | [x] external completedSprints|
|                              | ...                          |
|------------------------------|------------------------------|
| versions, originalVersions,  | ...                          |
| crossProjectVersions,        |                              |
| originalCrossProjectVersions |                              |
| removedVersions              |                              |
|------------------------------|------------------------------|
| filters                      | ...                          |
|------------------------------|------------------------------|
| issueLinks                   | ...                          |
|------------------------------|------------------------------|
| projects                     | ...                          |
|------------------------------|------------------------------|
| updateJira                   | ...                          |
|------------------------------|------------------------------|
| teams, originalTeams,        | ...                          |
| teamsAdditional              |                              |
+------------------------------+------------------------------+

*/

const requestActionCreators = {
	issueType: getIssueTypesForIds,
	project: getProjectsForIds,
	sprint: getSprintsForIds,
	version: getReleasesForIds,
} as const;

interface Lookup {
	issueType: ReturnType<typeof getIssueTypesById>;
	project: ReturnType<typeof getProjectsById>;
	sprint: ReturnType<typeof getSprintByIdMap>;
	version: ReturnType<typeof getVersionsById>;
}

// Traverse all entities and check their relations for being missing and unresolved.
// typeToAttributes maps relation type to the entity attribute name or array of names.
// This function handles 1-N relations as well.
export const checkAll = (
	check: <K extends keyof Lookup>(type: K, id: keyof Lookup[K]) => void,
	// `any` is justified here as function is generic over any entity structure
	// `{ [key: string]: any}` would be more appropriate but it triggers Flow to infer object structure
	// which of course is incompatible across various entity types
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	entities: any[],
	typeToAttributes: Partial<Record<keyof RelationsMap, string | string[]>>,
) => {
	for (const entity of entities) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		for (const type of Object.keys(typeToAttributes) as Array<keyof RelationsMap>) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-non-null-assertion
			const attributes = ([] as Array<string>).concat(typeToAttributes[type]!);
			for (const attribute of attributes) {
				const id = entity[attribute];
				if (Array.isArray(id)) {
					// eslint-disable-next-line @typescript-eslint/no-shadow
					id.forEach((id) => check(type, id));
				} else {
					check(type, id);
				}
			}
		}
	}
};

const createObserver = (store: Store<State>) => {
	// Map of entity type to set of ids which we already requested from server.
	// Right now we don't distinguish successful retrieval from failure,
	// we just don't want to ask about the same entity twice in both cases.
	const resolvedRelations = makeRelationsMap();
	const observer = () => {
		const state = store.getState();

		// Lookup tables to quickly check if entity is present in the app state.
		const lookup: Lookup = {
			issueType: getIssueTypesById(state),
			project: getProjectsById(state),
			sprint: getAllSprintsIncludingDisabledByIdMap(state),
			version: getVersionsById(state),
		};

		// Map of entity type to set of ids to request server for.
		const unresolvedRelations = makeRelationsMap();
		// Add id to unresolvedRelations to request for it at the end of the function,
		// and add it to resolvedRelations straight away to mark it as resolved for the next function call.
		const track = <K extends keyof RelationsMap>(
			type: K,
			id: K extends 'sprint' ? string : number,
		) => {
			// not sure why these are expecting `never` =(
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			unresolvedRelations[type].add(id as never);
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			resolvedRelations[type].add(id as never);
		};
		// Check if relation is missing and unresolved and mark it for loading if so.
		const check = <K extends keyof Lookup>(type: K, id: keyof Lookup[K]) => {
			const lookupValue = lookup[type][id];

			// not sure why this is expecting `never` =(
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			if (id && !(lookupValue || resolvedRelations[type].has(id as never)))
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				track(type, id as never);
		};

		// Now let's scan for references to missing entities.

		// state.domain.issues
		checkAll(check, getAllIssues(state), {
			project: 'project',
			issueType: 'type',
			sprint: ['sprint', 'completedSprints'],
			version: 'fixVersions',
		});
		// state.domain.originalIssues
		// Portfolio doesn't support changing project and type of issue,
		// thus we are not checking them in originalIssues.

		// state.domain.versions
		// ...

		// Now let's execute commands to ask server for missing entities.

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		for (const type of Object.keys(unresolvedRelations) as Array<keyof Lookup>) {
			const ids = unresolvedRelations[type];
			if (ids.size > 0) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
				store.dispatch(requestActionCreators[type]([...(ids as any)]));
			}
		}
	};
	return store.subscribe(relaxedScheduler(observer));
};

export default createObserver;
