import React from 'react';
import { FormattedMessage } from '@atlassian/jira-intl';
import messages from './messages';
import type { Props } from './types';

const Description = ({ loadingLimitsInfo }: Props) => (
	<FormattedMessage
		{...messages.descriptionWithLimit}
		values={{ issueLimit: loadingLimitsInfo.absoluteIssueLimit }}
	/>
);

export default Description;
