import {
	type SetRollupSettingAction,
	SET_SHOW_ROLLED_UP_DATE,
	SET_SHOW_ROLLED_UP_OTHERS,
} from './actions';
import type { RollupSettingsState } from './types';

type Action = SetRollupSettingAction;

export const initialState: RollupSettingsState = {
	showingRolledUpDate: true,
	showRolledUpOthers: true,
};

export const reducer = (state: RollupSettingsState = initialState, action: Action) => {
	switch (action.type) {
		case SET_SHOW_ROLLED_UP_DATE: {
			return {
				...state,
				showingRolledUpDate: action.payload.shouldShow,
			};
		}
		case SET_SHOW_ROLLED_UP_OTHERS: {
			return {
				...state,
				showRolledUpOthers: action.payload.shouldShow,
			};
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
export default reducer;
