import React, { Component, Fragment } from 'react';
import * as R from 'ramda';
import ContentLoader from 'react-content-loader';
import { AutoSizer } from 'react-virtualized/dist/commonjs/AutoSizer';
import { ff } from '@atlassian/jira-feature-flagging';
import UFOLoadHold from '@atlassian/react-ufo/load-hold';
import { DEFAULT_CONTENT_LOADER_PROPS, RECT_BORDER_RADIUS, RECT_ROW_HEIGHT } from './common';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { ListSkeletonProps } from './types';

// eslint-disable-next-line jira/react/no-class-components
export default class ListSkeleton extends Component<ListSkeletonProps> {
	static defaultProps = {
		rowHeight: RECT_ROW_HEIGHT,
		sidePadding: 8,
		spaceInBetween: 8,
		topPadding: 8,
		animate: true,
	};

	getSkeletonFragments = (index: number, width: number) => {
		const { rowHeight, sidePadding, spaceInBetween, topPadding } = this.props;
		const rectWidth = width - sidePadding * 2;

		return (
			<Fragment key={index}>
				<rect
					x={sidePadding}
					y={index * (rowHeight + spaceInBetween) + topPadding}
					{...RECT_BORDER_RADIUS}
					width={rectWidth < 0 ? 0 : rectWidth}
					height={rowHeight}
				/>
			</Fragment>
		);
	};

	render() {
		const { numOfRows, ariaLabel, rowHeight, animate } = this.props;
		return (
			<UFOLoadHold name="list-skeleton">
				<AutoSizer>
					{({ width, height }) => {
						const rowCount = numOfRows || Math.floor(height / rowHeight) - 1;

						return (
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							<div className={styles.skeleton} style={{ width, height }}>
								<ContentLoader
									ariaLabel={ariaLabel}
									width={width}
									height={height}
									{...(ff('com.atlassian.rm.jpo.transposition') ? { animate } : {})}
									{...DEFAULT_CONTENT_LOADER_PROPS}
								>
									{R.range(0, rowCount).map((index) => this.getSkeletonFragments(index, width))}
								</ContentLoader>
							</div>
						);
					}}
				</AutoSizer>
			</UFOLoadHold>
		);
	}
}
