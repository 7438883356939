import React, { useEffect, useRef, useState, type KeyboardEvent, type MouseEvent } from 'react';
import * as R from 'ramda';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { UNTITLED_VIEW_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/views/types';
import {
	UpdateErrorConflicted,
	UpdateErrorNotExists,
	SaveAsErrorDuplicateView,
	DeleteErrorDefaultView,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/view-bar/types';
import { KEYS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/interaction-constants';
import traceUFOPress from '@atlassian/react-ufo/trace-press';
import ConflictDialog from './conflict-dialog';
import DeleteErrorNotification from './delete-error-notification';
import DeletingDialog from './deleting-dialog';
import NonExistingDialog from './non-existing-dialog';
import SaveAsDialog from './save-as-dialog';
import SavedViewSwitcher from './saved-view-switcher/view.tsx';
import SavingDialog from './saving-dialog';
import type { Props } from './types';

function getDeleteDefaultViewError(
	errors: Props['errors'],
): DeleteErrorDefaultView | null | undefined {
	const err = errors.find((one) => one instanceof DeleteErrorDefaultView);

	if (!err) {
		return null;
	}

	if (!(err instanceof DeleteErrorDefaultView)) {
		return null;
	}

	return err;
}

function getConflictError(errors: Props['errors']): UpdateErrorConflicted | null | undefined {
	const err = errors.find((one) => one instanceof UpdateErrorConflicted);

	if (!err) {
		return null;
	}

	if (!(err instanceof UpdateErrorConflicted)) {
		return null;
	}

	return err;
}

function getNonExistingError(errors: Props['errors']): UpdateErrorNotExists | null | undefined {
	const err = errors.find((one) => one instanceof UpdateErrorNotExists);

	if (!err) {
		return null;
	}

	if (!(err instanceof UpdateErrorNotExists)) {
		return null;
	}

	return err;
}

function getDuplicateViewError(
	errors: Props['errors'],
): SaveAsErrorDuplicateView | null | undefined {
	const err = errors.find((one) => one instanceof SaveAsErrorDuplicateView);

	if (!err) {
		return null;
	}

	if (!(err instanceof SaveAsErrorDuplicateView)) {
		return null;
	}

	return err;
}

export default function ViewBar({
	views,
	activeView,
	errors,
	saveView,
	saveViewAs,
	discardChanges,
	deleteView,
	removeError,
	duplicateSection,
	saveViewAsDialogStatus,
	setSaveViewAsDialogStatus,
	readOnly,
	switchView,
}: Props) {
	const [saving, setSaving] = useState(false);
	const [deleting, setDeleting] = useState(false);

	useEffect(() => {
		if (saveViewAsDialogStatus !== 'CLOSED') {
			const firstChild = buttonRef.current?.firstElementChild;
			if (firstChild instanceof HTMLElement && firstChild.focus) {
				firstChild.focus();
			}
		}
	}, [saveViewAsDialogStatus]);

	const handleActiveViewChange = (e: KeyboardEvent | MouseEvent, id: number) => {
		if (id !== activeView.id) {
			traceUFOPress(KEYS.SWITCH_VIEW, e.timeStamp);
			switchView(id);
		}
	};

	const openSaveAsDialog = () => {
		setSaveViewAsDialogStatus('OPEN');
	};
	const duplicateViewError = getDuplicateViewError(errors);
	const closeSaveAsDialog = () => {
		setSaveViewAsDialogStatus('CLOSED');
		if (duplicateViewError) {
			removeError(duplicateViewError);
		}
	};

	const buttonRef = useRef<HTMLDivElement>(null);

	const handleRequestSaveAs = ({ name, asDefault }: { name: string; asDefault: boolean }) => {
		saveViewAs({ name, asDefault, duplicateSection });
	};

	const openSavingDialog = () => setSaving(true);
	const closeSavingDialog = () => setSaving(false);
	const confirmSaving = () => {
		if (activeView.id === UNTITLED_VIEW_ID) {
			return openSaveAsDialog();
		}

		saveView({ overwrite: false });
		closeSavingDialog();
	};

	const confirmDiscarding = () => {
		discardChanges(activeView.id);
	};

	const openDeletingDialog = () => setDeleting(true);
	const closeDeletingDialog = () => setDeleting(false);
	const confirmDeleting = () => {
		deleteView(activeView.id);
		closeDeletingDialog();
	};

	const conflictError = getConflictError(errors);
	const closeConflictDialog = () => {
		if (conflictError) {
			removeError(conflictError);
		}
	};

	const handleConflictRequestSaveAs = () => {
		closeConflictDialog();
		openSaveAsDialog();
	};

	const handleConflictRequestDiscardChanges = () => {
		closeConflictDialog();
		discardChanges(activeView.id);
	};

	const handleConflictRequestOverwrite = () => {
		closeConflictDialog();
		saveView({ overwrite: true });
	};

	const nonExistingError = getNonExistingError(errors);
	const closeNonExistingDialog = () => {
		if (nonExistingError) {
			removeError(nonExistingError);
		}
	};

	const handleNonExistingRequestSaveAs = () => {
		closeNonExistingDialog();
		openSaveAsDialog();
	};

	const handleNonExistingRequestDiscardChanges = () => {
		closeNonExistingDialog();
		deleteView(activeView.id, false);
	};

	const handleTitleChange = () => {
		if (duplicateViewError) {
			removeError(duplicateViewError);
		}
	};

	const deleteDefaultViewError = getDeleteDefaultViewError(errors);
	const closeDeleteErrorNotification = () => {
		if (deleteDefaultViewError) {
			removeError(deleteDefaultViewError);
		}
	};

	return (
		<>
			<SpotlightTarget name="view-actions">
				<SavedViewSwitcher
					views={views}
					isReadOnly={readOnly}
					onRequestDiscardChanges={confirmDiscarding}
					onRequestViewChange={handleActiveViewChange}
					onRequestSave={openSavingDialog}
					onRequestSaveAs={openSaveAsDialog}
					onRequestDelete={openDeletingDialog}
					buttonRef={buttonRef}
				/>
				{!R.isEmpty(views) && (
					<>
						{saving && (
							<SavingDialog
								onConfirm={confirmSaving}
								onClose={closeSavingDialog}
								viewName={activeView.name}
							/>
						)}
						{saveViewAsDialogStatus !== 'CLOSED' && (
							<SaveAsDialog
								hasDuplicateViewError={!!duplicateViewError}
								isSaving={saveViewAsDialogStatus === 'SAVING'}
								onChange={handleTitleChange}
								onSubmit={handleRequestSaveAs}
								onClose={closeSaveAsDialog}
							/>
						)}
						{deleting && (
							<DeletingDialog
								viewName={activeView.name}
								onConfirm={confirmDeleting}
								onClose={closeDeletingDialog}
							/>
						)}
						{conflictError && (
							<ConflictDialog
								conflictError={conflictError}
								onRequestOverwrite={handleConflictRequestOverwrite}
								onRequestSaveAs={handleConflictRequestSaveAs}
								onRequestDiscardChanges={handleConflictRequestDiscardChanges}
								onClose={closeConflictDialog}
							/>
						)}
						{nonExistingError && (
							<NonExistingDialog
								viewName={nonExistingError.viewName}
								onRequestSaveAs={handleNonExistingRequestSaveAs}
								onRequestDiscardChanges={handleNonExistingRequestDiscardChanges}
								onClose={closeNonExistingDialog}
							/>
						)}
						{deleteDefaultViewError && (
							<DeleteErrorNotification
								viewName={deleteDefaultViewError.viewName}
								dismissFlag={closeDeleteErrorNotification}
							/>
						)}
					</>
				)}
			</SpotlightTarget>
		</>
	);
}
