import {
	changeQuickSelect,
	replaceSelectedChanges,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/title-bar/update-jira/actions';
import type {
	QuickSelectOption,
	SelectedChanges,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/title-bar/update-jira/types';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { DispatchProps } from './types';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	replaceSelection: (changes: Partial<SelectedChanges>) => {
		dispatch(replaceSelectedChanges(changes));
	},
	changeQuickSelect: (option: QuickSelectOption) => {
		dispatch(changeQuickSelect(option));
	},
});

export default mapDispatchToProps;
