import { getFilters } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters';
import { getReporterForFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/reporter-filter';
import { REPORTER_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';

const mapStateToProps = createSelector(
	[getReporterForFilter, getFilters],
	(reporters, { [REPORTER_FILTER_ID]: { id, value } }) => ({ id, value, reporters }),
);

export default mapStateToProps;
