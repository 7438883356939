import {
	update as updateCustomFieldFilter,
	clear as clearCustomFieldFilter,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/custom-field-filter/actions';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { DispatchProps, OwnProps } from './types';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps, OwnProps> = (
	dispatch,
	props,
) => ({
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onChange(value: any[]) {
		dispatch(
			updateCustomFieldFilter({
				customField: props.customField,
				value,
			}),
		);
	},

	onRequestClear() {
		dispatch(clearCustomFieldFilter({ customField: props.customField }));
	},
});

export default mapDispatchToProps;
