import React from 'react';
import { FormattedMessage } from '@atlassian/jira-intl';
import { ONE_DAY } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import type { Sprint } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/sprints/types';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import type { Timestamp } from '@atlassian/jira-portfolio-3-portfolio/src/common/types';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';

type Props = {
	startDate?: Timestamp | null | undefined;
	endDate?: Timestamp | null | undefined;
	activeSprint?: Sprint;
	completedSprintCount?: number;
	showActiveSprintOnTooltip?: boolean;
};

export default function IssueLengthView(props: Props) {
	const {
		startDate,
		endDate,
		activeSprint,
		completedSprintCount = 0,
		showActiveSprintOnTooltip,
	} = props;
	if (!(isDefined(startDate) && isDefined(endDate))) {
		return null;
	}
	// Adding one to include end date in calculation
	// e.g. Mar 2 - Mar 1 = 1
	// But we want to include both Mar 1 and Mar 2 in our calculations,
	// Therefore + 1 day
	const numDays = Math.floor((endDate - startDate) / ONE_DAY) + 1;
	const text = (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.withSprint}>
			<FormattedMessage {...messages.issueLength} values={{ numDays }} />
			{showActiveSprintOnTooltip === true && (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				<div className={styles.sprintDetails}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.separator} />
					{activeSprint && (
						<FormattedMessage {...messages.sprint} values={{ activeSprint: activeSprint.title }} />
					)}
					{completedSprintCount > 0 && (
						<FormattedMessage
							{...messages.completeSprints}
							values={{ count: completedSprintCount }}
						/>
					)}
				</div>
			)}
		</div>
	);

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
	return <div className={styles.issueLength}>{text}</div>;
}
