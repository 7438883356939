import { clearAll } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/filters';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import { connect } from '@atlassian/jira-react-redux';
import mapStateToProps from './query';
import type { DispatchProps } from './types';
import View from './view';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	clearAllFilters: () => {
		dispatch(clearAll());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
