import { combineReducers } from '@atlassian/jira-portfolio-3-portfolio/src/common/redux';
import Fields from './fields/reducer';
import Scope from './scope/reducer';
import StickyHeader from './sticky-header/reducer';
import TimelinePreview from './timeline-preview/reducer';
import Timeline from './timeline/reducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default combineReducers<any, any>({
	Fields,
	Scope,
	Timeline,
	TimelinePreview,
	StickyHeader,
});
