import React, { useState, useCallback } from 'react';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { statusBreakdownColourMap } from '../colours';
import { ISSUE_STATUS_CATEGORIES, BREAKDOWN_SPRINT_CAPACITY_BY } from '../constant';
import EstimateFlyout from './components/estimate-flyout.tsx';
import StatusFlyout from './components/status-flyout.tsx';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

const { TODO, INPROGRESS, DONE } = ISSUE_STATUS_CATEGORIES;

const StatusBreakdown = (props: Props) => {
	const {
		onlyRenderStatusBar = false,
		breakdownBy = BREAKDOWN_SPRINT_CAPACITY_BY.STATUS,
		total,
		label,
		showToolTip,
		planningUnit,
		byCategoryId,
	} = props;

	const [isOpen, setIsOpen] = useState(false);
	const { formatMessage } = useIntl();

	const onToggle = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	const onClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	const getBreakdown = useCallback(
		() => ({
			todo: byCategoryId[String(TODO)],
			inprogress: byCategoryId[String(INPROGRESS)],
			done: byCategoryId[String(DONE)],
		}),
		[byCategoryId],
	);

	const renderStatusBar = useCallback(() => {
		const { todo, inprogress, done } = getBreakdown();
		const remaining = todo + inprogress;
		const todoWidth = (todo / total) * 100;
		const inProgressWidth = (inprogress / total) * 100;
		const doneWidth = total === 0 ? 0 : (done / total) * 100;
		const remainingWidth = total === 0 ? 100 : (remaining / total) * 100;
		const overBooked = done > total;

		const todoBar = (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			<div style={{ width: `${todoWidth}%`, backgroundColor: statusBreakdownColourMap.TODO }} />
		);

		const inprogressBar = (
			<div
				style={{
					width: `${inProgressWidth}%`,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					backgroundColor: statusBreakdownColourMap.INPROGRESS,
				}}
			/>
		);

		const doneBar = (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			<div style={{ width: `${doneWidth}%`, backgroundColor: statusBreakdownColourMap.DONE }} />
		);

		let bar =
			breakdownBy === 'status' ? (
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles.bar}
					data-testid="portfolio-3-portfolio.common.status-breakdown.status-breakdown-bar"
				>
					{doneWidth > 0 && doneBar}
					{inProgressWidth > 0 && inprogressBar}
					{todoWidth > 0 && todoBar}
				</div>
			) : (
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles.bar}
					data-testid="portfolio-3-portfolio.common.status-breakdown.status-breakdown-bar"
				>
					{doneWidth > 0 && (
						<div
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className={overBooked ? `${styles.completed} ${styles.overbooked}` : styles.completed}
							style={{ width: `${doneWidth}%` }}
						/>
					)}
					{remainingWidth > 0 && (
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						<div className={styles.remaining} style={{ width: `${remainingWidth}%` }} />
					)}
				</div>
			);

		if (showToolTip === true && breakdownBy === 'estimate') {
			bar = (
				<Tooltip
					content={formatMessage(messages.breakdownByEstimateToolTip, {
						done,
						donePercent: total ? Math.round((done * 100) / total) : '0',
						total,
						planningUnit,
					})}
					position="mouse"
				>
					{bar}
				</Tooltip>
			);
		}

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.breakdown}>
				{label}
				{bar}
			</div>
		);
	}, [breakdownBy, formatMessage, getBreakdown, label, planningUnit, showToolTip, total]);

	const renderTrigger = useCallback(
		(triggerProps: TriggerProps) => (
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.container}
				onClick={onToggle}
				tabIndex={0}
				role="button"
				// Replace with lodash/noop
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				onKeyDown={() => {}}
				data-testid="portfolio-3-portfolio.common.status-breakdown.trigger"
				{...triggerProps}
				aria-label={formatMessage(messages.statusBreakdownLabel)}
			>
				{renderStatusBar()}
			</div>
		),
		[onToggle, renderStatusBar, formatMessage],
	);

	const renderContent = () =>
		breakdownBy === BREAKDOWN_SPRINT_CAPACITY_BY.STATUS ? (
			<StatusFlyout {...props} />
		) : (
			<EstimateFlyout {...props} />
		);

	if (total === 0 && breakdownBy === BREAKDOWN_SPRINT_CAPACITY_BY.STATUS) return null;

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
	if (onlyRenderStatusBar) return <div className={styles.container}>{renderStatusBar()}</div>;

	return (
		<Popup
			isOpen={isOpen}
			onClose={onClose}
			trigger={renderTrigger}
			content={renderContent}
			placement="bottom-start"
		/>
	);
};

export default StatusBreakdown;
