import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { connect } from '@atlassian/jira-react-redux';
import mapDispatchToProps from './command';
import mapStateToProps from './query';
import type { StateProps, DispatchProps } from './types';
import View from './view';

export default connect<StateProps, DispatchProps, {}, State>(
	mapStateToProps,
	mapDispatchToProps,
	// @ts-expect-error type boolean is not assigned to type false
)(View);
