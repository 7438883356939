import { combineReducers } from '@atlassian/jira-portfolio-3-portfolio/src/common/redux';
import ConfluenceMacro from './confluence-macro/reducer';
import Errors from './errors/reducer';
import Main from './main/reducer';
import Onboarding from './onboarding/reducer';
import SectionWidths from './section-widths/reducer';
import Settings from './settings/reducer';
import Top from './top/reducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default combineReducers<any, any>({
	Top,
	Main,
	Errors,
	Settings,
	Onboarding,
	SectionWidths,
	ConfluenceMacro,
});
