import React, { useMemo, useState, type FC } from 'react';
import { IconButton } from '@atlaskit/button/new';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { Box, Pressable, xcss, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { useIntl } from '@atlassian/jira-intl';
import { InputPrompt } from './input-prompt';
import messages from './messages';

export type Props = {
	issueHierarchyLevel?: number | undefined;
	isClickable?: boolean;
	isDisabled?: boolean;
	value?: string;
	onCrossIconClick(): void;
	onChange?(value: string): void;
	onSubmit?(): void;
	onFocus?(): void;
};

export const Header: FC<Props> = ({
	issueHierarchyLevel,
	isClickable = false,
	isDisabled = false,
	value,
	onCrossIconClick,
	onChange,
	onFocus,
	onSubmit,
}) => {
	const { formatMessage } = useIntl();

	const renderValue = useMemo(
		() =>
			value ||
			formatMessage(messages.defaultPrompt, {
				issueType: issueHierarchyLevel && issueHierarchyLevel > 0 ? 'issues' : 'tasks',
			}),
		[formatMessage, issueHierarchyLevel, value],
	);

	const [isRefining, setIsRefining] = useState(false);

	const headerContent = useMemo(
		() => (
			<>
				<Box as="span" xcss={headerPromptStyles}>
					<Text testId="portfolio-3-ai-work-breakdown.common.header.value">{renderValue}</Text>
				</Box>
			</>
		),
		[renderValue],
	);

	const renderHeader = useMemo(
		() =>
			isClickable ? (
				<Pressable
					testId="portfolio-3-ai-work-breakdown.common.header.button"
					onClick={() => {
						setIsRefining(true);
					}}
					xcss={[headerButtonStyles, hoverableStyles]}
					aria-label={formatMessage(messages.headerButtonAriaLabel)}
				>
					{headerContent}
				</Pressable>
			) : (
				<Box testId="portfolio-3-ai-work-breakdown.common.header.button" xcss={headerButtonStyles}>
					{headerContent}
				</Box>
			),
		[formatMessage, isClickable, headerContent],
	);

	// Visually hidden when refining for screen readers to be able to focus after tabbing
	const renderCloseButton = useMemo(() => {
		const closeButton = (
			<IconButton
				testId="portfolio-3-ai-work-breakdown.common.header.cross-button"
				appearance="subtle"
				icon={CrossIcon}
				label={formatMessage(messages.labelDiscardSuggestions)}
				onClick={onCrossIconClick}
				isTooltipDisabled={false}
				tooltip={{ position: 'left' }}
			/>
		);

		return isRefining ? <VisuallyHidden>{closeButton}</VisuallyHidden> : closeButton;
	}, [formatMessage, isRefining, onCrossIconClick]);
	return (
		<Box
			testId="portfolio-3-ai-work-breakdown.common.header"
			xcss={
				isClickable && !isRefining
					? [headerContainerStyles, hoverableHeaderStyles, hoverableStyles]
					: headerContainerStyles
			}
		>
			{isRefining && onChange && onSubmit ? (
				<InputPrompt
					placeholder={formatMessage(messages.footerTextDraftList)}
					onChange={onChange}
					onFocus={onFocus}
					onSubmit={onSubmit}
					onBlur={() => setIsRefining(false)}
					autoFocus
					isDisabled={isDisabled}
					customXcss={inputPromptStyles}
				/>
			) : (
				renderHeader
			)}

			{renderCloseButton}
		</Box>
	);
};

const headerContainerStyles = xcss({
	paddingRight: 'space.150',
	display: 'flex',
	borderBottom: `1px solid ${token('color.background.accent.gray.subtler', '#EBECF0')}`,
	alignItems: 'center',
});

const hoverableStyles = xcss({
	cursor: 'pointer',
});

const hoverableHeaderStyles = xcss({
	':hover': {
		background: token('color.background.neutral.subtle.hovered', '#EBECF0'),
		textDecoration: 'none',
	},
});

const headerButtonStyles = xcss({
	background: 'none',
	width: '100%',
	display: 'flex',
	justifyContent: 'baseline',
	alignItems: 'center',
	padding: 'space.200',
});

const headerPromptStyles = xcss({
	borderRadius: token('border.radius.100', '3px'),
	padding: 'space.025',
	paddingTop: '0',
	paddingBottom: '0',
	height: 'space.0',
	width: 'space.100',
	color: 'color.text.subtle',
	background: token('color.background.neutral', '#091E420F'),
	fontWeight: token('font.weight.medium', '400'),
	marginRight: 'space.100',
});

const inputPromptStyles = xcss({
	padding: 'space.150',
});
