import { getIssueTypesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types';
import { getSelectedIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues';
import { getProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects';
import { getIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/index.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import {
	createStructuredSelector,
	createSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import {
	getIssueIdToProjectPure,
	getIsSomeSelectedIssuesFromTMPPure,
	getIsSelectedIssuesFromMultipleProjectsPure,
} from './pure';
import type { OwnProps, StateProps } from './types';

const getIssueIdToProject = createSelector<
	State,
	Issue[],
	Record<number, Project>,
	Record<string, Project>
>([getSelectedIssues, getProjectsById], getIssueIdToProjectPure);

const getIsSomeSelectedIssuesFromTMP = createSelector<
	State,
	Issue[],
	Record<string, Project>,
	boolean
>([getSelectedIssues, getIssueIdToProject], getIsSomeSelectedIssuesFromTMPPure);

const getIsSelectedIssuesFromMultipleProjects = createSelector<State, Issue[], boolean>(
	[getSelectedIssues],
	getIsSelectedIssuesFromMultipleProjectsPure,
);

export default createStructuredSelector<State, OwnProps, StateProps>({
	issues: getIssues,
	issueTypesById: getIssueTypesById,
	projectsById: getProjectsById,
	selectedIssues: getSelectedIssues,
	isSomeSelectedIssuesFromTMP: getIsSomeSelectedIssuesFromTMP,
	isSelectedIssuesFromMultipleProjects: getIsSelectedIssuesFromMultipleProjects,
});
