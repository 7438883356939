import * as R from 'ramda';
import {
	SHOW_ERROR,
	HIDE_ERROR,
	HIDE_ALL_ERRORS,
	SHOW_ERROR_LOG_DIALOG,
	HIDE_ERROR_LOG_DIALOG,
	SET_IS_PROJECT_LIMIT_EXCEEDED,
	type ShowErrorAction,
	type HideErrorAction,
	type HideAllErrorsAction,
	type ShowErrorLogDialogAction,
	type HideErrorLogDialogAction,
	type SetIsProjectLimitExceeded,
} from './actions';
import type { State } from './types';

type Action =
	| ShowErrorAction
	| HideErrorAction
	| HideAllErrorsAction
	| ShowErrorLogDialogAction
	| HideErrorLogDialogAction
	| SetIsProjectLimitExceeded;

export const initialState: State = {
	errors: [],
	isErrorLogDialogOpen: false,
	isProjectLimitExceeded: false,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case SHOW_ERROR: {
			const { title, message, stackTrace, details, locationHash, locationPath, description } =
				action.payload;
			return R.merge(state, {
				errors: [
					{
						id: state.errors.length,
						title,
						message,
						stackTrace,
						details,
						locationHash,
						locationPath,
						description,
					},
					...state.errors,
				],
			});
		}
		case HIDE_ERROR:
			return R.merge(state, {
				errors: state.errors.slice(1),
			});
		case HIDE_ALL_ERRORS:
			return R.merge(state, { errors: [] });
		case SHOW_ERROR_LOG_DIALOG:
			return R.merge(state, { isErrorLogDialogOpen: true });
		case HIDE_ERROR_LOG_DIALOG:
			return R.merge(state, { isErrorLogDialogOpen: false });
		case SET_IS_PROJECT_LIMIT_EXCEEDED:
			return { ...state, isProjectLimitExceeded: true };
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
