import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';

/* This a workaround for the following problem:
https://stash.atlassian.com/projects/JIRACLOUD/repos/jira-frontend/browse/src/packages/development/summary-legacy/src/test-util/mock-dev-detail-dependencies.js
mocks `window.require` and expects the second parameter to be mandatory and to be a function.
At the same time `window.require` which is called by Portfolio at runtime have the second parameter
optional but when it's provided it's expected to be a string which is then split.
*/
// eslint-disable-next-line @typescript-eslint/no-empty-function
const callback = () => {};
callback.split = () => [];
declare global {
	interface Window {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		require?: any;
	}
}

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const getJqueryValue = () => window && window.require && window.require('jquery', callback);

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const getUnderscoreValue = () => window && window.require && window.require('underscore', callback);

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const getJiraValue = () => window && window.JIRA;

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const getAjsValue = () => window && window.AJS;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getGlobal = (getValue: () => any) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let value: any;
	return () => {
		if (!isDefined(value)) {
			value = getValue();
		}
		return value;
	};
};

export const getJquery = getGlobal(getJqueryValue);
export const getUnderscore = getGlobal(getUnderscoreValue);
export const getJira = getGlobal(getJiraValue);
export const getAjs = getGlobal(getAjsValue);
