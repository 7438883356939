import React, { useEffect, useState } from 'react';
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useViewport } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/container/index.tsx';
import { COLUMN } from '../constants';
import type { Props } from './types';
import { shouldShowIndicator } from './utils';

export const HEADER_HEIGHT = 50;

export const ColumnDropIndicator = ({ columnId, columnIds }: Props) => {
	const [{ height }] = useViewport();
	const [showIndicator, setShowIndicator] = useState(false);

	useEffect(
		() =>
			monitorForElements({
				canMonitor: ({ source }) => source.data.type === COLUMN,
				onDrag: ({ source, location }) => {
					const target = location.current.dropTargets[0];

					if (!target) return;

					const sourceColumnId = source.data.columnId;
					const targetColumnId = target.data.columnId;
					const closestEdge = extractClosestEdge(target.data);

					if (
						typeof sourceColumnId !== 'string' ||
						typeof targetColumnId !== 'string' ||
						!closestEdge
					)
						return;

					setShowIndicator(
						shouldShowIndicator(sourceColumnId, targetColumnId, columnIds, closestEdge, columnId),
					);
				},
				onDropTargetChange: ({ location }) => {
					const target = location.current.dropTargets[0];

					if (!target) setShowIndicator(false);
				},
				onDrop: () => setShowIndicator(false),
			}),
		[columnId, columnIds],
	);

	// Hide indicator when columnIds change, since the onDrop event gets triggered inconsistently due to the monitor being unmounted.
	useEffect(() => () => setShowIndicator(false), [columnIds]);

	return showIndicator && <Box xcss={indicatorStyles} style={{ height: height - HEADER_HEIGHT }} />;
};

const indicatorStyles = xcss({
	background: token('color.border.brand'),
	pointerEvents: 'none',
	position: 'absolute',
	width: '2px',
	top: '0',
	right: '-1px',
	'::before': {
		content: '""',
		position: 'absolute',
		height: '0',
		width: '0',
		right: '-3px',
		borderTop: `${token('space.100')} solid ${token('color.border.brand')}`,
		borderLeft: `${token('space.050')} solid transparent`,
		borderRight: `${token('space.050')} solid transparent`,
	},
});
