import React from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import ButtonGroup from '@atlaskit/button/button-group';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import Spinner from '@atlassian/jira-portfolio-3-common/src/spinner/index.tsx';
import { KEYS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/interaction-constants';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { addUFOCustomData } from '@atlassian/react-ufo/custom-data';
import traceUFOPress from '@atlassian/react-ufo/trace-press';
import Progress from '../components/progress/view.tsx';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

function UpdateJiraDialogFooter(props: Props) {
	const {
		actions,
		leftActions = null,
		rightActions,
		selectedChanges,
		selectedChangesCount,
		isSaving,
		commitProgressStats: { numberOfCommittedChanges, totalChanges: totalCommitChanges },
		isReverting,
		revertProgressStats: { numberOfRevertedChanges, totalChanges: totalRevertChanges },
	} = props;

	const onTooltipShowHandler = (analyticsEvent: UIAnalyticsEvent) =>
		fireUIAnalytics(analyticsEvent, 'warningIcon');

	const scalingFactors = Object.fromEntries(
		Object.entries(selectedChanges).map(([entity, ids]) => [entity, ids.length]),
	);

	const getFooterRight = () => {
		if (rightActions) {
			return rightActions;
		}

		return (
			<ButtonGroup>
				{actions
					.filter(({ isVisible = true }) => isVisible)
					.map(
						({
							appearance,
							autoFocus = false,
							isDisabled,
							isLoading,
							onClick,
							testId = '',
							text,
							tooltip,
							type,
						}) => (
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							<div className={styles.buttonWrapper} key={text}>
								{isDisabled && selectedChangesCount > 0 && tooltip && (
									<Tooltip content={tooltip} position="top" onShow={onTooltipShowHandler}>
										<WarningIcon
											primaryColor={token('color.icon.warning', colors.Y500)}
											label={tooltip}
										/>
									</Tooltip>
								)}
								<Button
									appearance={appearance}
									isDisabled={isDisabled}
									onClick={(e, analyticsEvent) => {
										onClick(e, analyticsEvent);
										if (type === 'save' && scalingFactors) {
											traceUFOPress(KEYS.SAVE_REVIEW_CHANGES, e.timeStamp);
											addUFOCustomData(scalingFactors); // Uses a hook so it cannot be done in the onClick handler as its in a class component.
										}
									}}
									iconAfter={isLoading ? <Spinner invertColor={!isDisabled} /> : undefined}
									autoFocus={autoFocus}
									testId={testId}
								>
									{text}
								</Button>
							</div>
						),
					)}
			</ButtonGroup>
		);
	};

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.footer}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.footerLeft}>{leftActions}</div>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.progressContainer}>
				{isSaving && (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={styles.commitProgress}>
						<Progress
							label={{ ...messages.committingChangesLabelNoElipsis }}
							countOfThingsDone={numberOfCommittedChanges}
							countOfThingsToDo={totalCommitChanges}
						/>
					</div>
				)}
				{isReverting && (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={styles.commitProgress}>
						<Progress
							label={{ ...messages.revertingChangesLabelNoElipsis }}
							countOfThingsDone={numberOfRevertedChanges}
							countOfThingsToDo={totalRevertChanges}
						/>
					</div>
				)}
				{getFooterRight()}
			</div>
		</div>
	);
}

export default UpdateJiraDialogFooter;
