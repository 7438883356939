import { getRollupMap } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/issues-tree.tsx';
import { getSprintsForTeam } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints';
import {
	getExistingTeams,
	getAllExistingTeams,
	getAdditionalTeamsById,
	getScenarioRemovedShareTeams,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import type { Props } from './types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State, props: Props) => ({
	teams: getExistingTeams(state),
	allTeams: getAllExistingTeams(state),
	scenarioRemovedShareTeams: getScenarioRemovedShareTeams(state),
	additionalTeamsById: getAdditionalTeamsById(state),
	sprintsForTeam: getSprintsForTeam(state),
	teamRollUpForIssue: isDefined(getRollupMap(state)[props.issue.id])
		? getRollupMap(state)[props.issue.id].team
		: null,
});
