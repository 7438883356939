import type { HistoryIssues } from './types';

export const RESET = 'state.domain.history-issues.RESET' as const;
export const UPDATE_PARENT = 'state.domain.history-issues.UPDATE_PARENT' as const;

export type UpdateActionPayload = {
	id: string;
	parent: string | null | undefined;
	isDragAndDrop: boolean | null | undefined;
};

export type ResetAction = {
	type: typeof RESET;
	payload: HistoryIssues;
};

export type UpdateParentAction = {
	type: typeof UPDATE_PARENT;
	payload: UpdateActionPayload;
};

export const reset = (payload: HistoryIssues): ResetAction => ({
	type: RESET,
	payload,
});

export const updateParent = (payload: UpdateActionPayload): UpdateParentAction => ({
	type: UPDATE_PARENT,
	payload,
});
