import { getFeatureFlagValue } from '@atlassian/jira-portfolio-3-common/src/feature-flags';

/**
 * Get a list of account id's that should be excluded from time to interactive reporting
 */
const getTtiExcludedUsers = (): string[] => {
	// https://app.launchdarkly.com/jira/production/features/com.atlassian.rm.jpo.jpo3cloud.ttiExcludedUsers/targeting
	const flagValue = getFeatureFlagValue<string>(
		'com.atlassian.rm.jpo.jpo3cloud.ttiExcludedUsers',
		'',
	);
	return typeof flagValue === 'string' && flagValue ? flagValue.split(',') : [];
};

export default getTtiExcludedUsers;
