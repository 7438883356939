import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { connect } from '@atlassian/jira-react-redux';
import mapStateToProps from './query';
import type { DispatchProps, OwnProps, StateProps } from './types';
import { ArrowsContainer as View } from './view';

export const Arrows = connect<StateProps, DispatchProps, OwnProps, State>(
	mapStateToProps,
	{},
)(View);
