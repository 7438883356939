import type { ReactElement } from 'react';
import {
	PUSH_FLAG,
	type FlagAction,
	REMOVE_FLAG,
	DISMISS_FLAG,
	type DismissFlagAction,
} from './actions';
import type { Flags, FlagKey } from './types';

type Action = FlagAction | DismissFlagAction;
type State = Flags;

const initialState: State = [];

const pushFlag = (
	state: State,
	key: FlagKey,
	context: Record<string, string | ReactElement> | undefined,
): State =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	([{ key, context }] as Flags).concat(
		state.filter(({ key: previousFlagKey }) => previousFlagKey !== key),
	);

const removeFlagOccurrenceFromState = (state: State, key: FlagKey): State => {
	const filteredState = state.filter(({ key: previousFlagKey }) => previousFlagKey !== key);
	return filteredState;
};

const removeLatestFlag = (state: State): State => {
	const topFlagRemoved = state.slice(1);
	return topFlagRemoved;
};

export default function reducer(state: State = initialState, action: Action) {
	switch (action.type) {
		case PUSH_FLAG: {
			const {
				payload: { key, context },
			} = action;
			return pushFlag(state, key, context);
		}
		// Only called behind FF'd code
		case REMOVE_FLAG:
			return removeFlagOccurrenceFromState(state, action.payload.key);
		case DISMISS_FLAG:
			return removeLatestFlag(state);

		default:
			return state;
	}
}
