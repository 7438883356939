import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { getIsIssuesLoading } from '../issues';
import { getAllIssues, getOriginalIssues } from '../raw-issues/index.tsx';
import { getAllAssociatedIssueIds } from './utils';

export const getAssociatedIssueIds = createSelector(
	[getIsIssuesLoading, getAllIssues, getOriginalIssues],
	(isIssuesLoading, issues, originalIssues) => {
		if (isIssuesLoading && fg('polaris-arj-png-export-bugfix')) {
			return undefined;
		}

		// when removing FF "polaris-arj-png-export-bugfix", left only one "if" with checking for loading (this one above)
		if (!isIssuesLoading && ff('polaris.possibility-of-updating-ideas-in-plans')) {
			return getAllAssociatedIssueIds(issues, originalIssues);
		}

		return issues.map((issue) => issue.associatedIssueIds || []).flat();
	},
);

export const getAssociatedIssues = (state: State) => state.domain.associatedIssues.associatedIssues;
