import React, { Component } from 'react';
import * as R from 'ramda';
import { injectIntl } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import type { SelectedChanges } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/title-bar/update-jira/types.tsx';
import type { Item } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/title-bar/update-jira/types';
import { getExpandedChanges } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/title-bar/update-jira/utils';
import {
	groupBy,
	valuesLength,
	mapValues,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { connect } from '@atlassian/jira-react-redux';
import mapDispatchToProps from './command';
import messages from './messages';
import mapStateToProps from './query';
import type { Props } from './types';

export const CHECKED = 'checked';
export const UNCHECKED = 'unchecked';
export const INDETERMINATE = 'indeterminate';

export const calculateStatusOfSelectedChanges = (
	changes: Item[],
	selectedChanges: SelectedChanges,
): string => {
	const selectedChangesCount = valuesLength(selectedChanges);
	const changesCount = changes.length;

	if (changesCount > 0 && changesCount === selectedChangesCount) {
		return CHECKED;
	}
	if (selectedChangesCount > 0) {
		return INDETERMINATE;
	}

	return UNCHECKED;
};

// eslint-disable-next-line jira/react/no-class-components
export class SelectChangeHeader extends Component<Props> {
	handleChange = () => {
		const { changes, selectAll, deselectAll, selectedChanges } = this.props;
		const expandedChanges = getExpandedChanges(changes);
		const status = calculateStatusOfSelectedChanges(expandedChanges, selectedChanges);

		if (status === CHECKED || status === INDETERMINATE) {
			deselectAll();
		} else {
			const changesByCategory = groupBy(R.prop('category'), expandedChanges);
			selectAll(mapValues(R.prop('id'), changesByCategory));
		}
	};

	render() {
		const { changes, isUpdating, selectedChanges, intl } = this.props;
		const expandedChanges = getExpandedChanges(changes);
		const status = calculateStatusOfSelectedChanges(expandedChanges, selectedChanges);
		return (
			<Checkbox
				isChecked={status === CHECKED || status === INDETERMINATE}
				isIndeterminate={status === INDETERMINATE}
				isDisabled={isUpdating}
				onChange={this.handleChange}
				aria-label={intl.formatMessage(messages.selectHeaderAriaLabel)}
			/>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SelectChangeHeader));
