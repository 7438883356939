import * as R from 'ramda';
import type { View } from './types';

export function trySetActiveView(state: View[]): View[] {
	const hasActive = state.some((one) => one.active);

	if (hasActive) {
		return state;
	}

	const [nextState] = state.reduce(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		([acc, set]: [any, any], view): [View[], boolean] => {
			if (!set && view.isDefault) {
				const nextView = R.assoc('active', true)(view);
				return [[...acc, nextView], true];
			}

			return [[...acc, view], set];
		},
		[[], false],
	);

	return nextState;
}
