/** @jsx jsx */
import React, { type MouseEvent, useMemo } from 'react';
import { css, jsx } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import {
	fireUIAnalytics,
	MODAL,
	ContextualAnalyticsData,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { useCtpOnboardingTour } from '../../../controllers/ctp-onboarding';
import { CtpSpotlights } from '../../../controllers/ctp-spotlights';
import { messages } from './messages';
import type { Props } from './types';
import { getBoardUrl } from './utils';

export const BoardsSpotlight = ({ issueSources, projectKey }: Props) => {
	const { formatMessage } = useIntl();
	const [, { nextCtpStage }] = useCtpOnboardingTour();

	const issueSourceLinks = useMemo(() => {
		if (!projectKey) return null;
		if (!issueSources?.length) return null;

		return (
			<>
				{fg('arj_ctp_template_sort_board_name_in_onboarding')
					? issueSources
							.sort((a, b) => (a.title ?? '').localeCompare(b.title ?? ''))
							.map(({ id, value: boardId, title }) => (
								<a
									key={id}
									target="_blank"
									css={linkButtonStyles}
									href={getBoardUrl(projectKey, boardId)}
								>{`${title} ${formatMessage(messages.board)}`}</a>
							))
					: issueSources.map(({ id, value: boardId, title }) => (
							<a
								key={id}
								target="_blank"
								css={linkButtonStyles}
								href={getBoardUrl(projectKey, boardId)}
							>{`${title} ${formatMessage(messages.board)}`}</a>
						))}
			</>
		);
	}, [issueSources, projectKey, formatMessage]);

	// If we have no board links to display, something has gone wrong during template creation
	// Skip displaying this spotlight
	if (!issueSourceLinks) {
		nextCtpStage();
		return null;
	}

	return (
		<ContextualAnalyticsData sourceName="crossTeamPlanningBoardsSpotlight" sourceType={MODAL}>
			<JiraSpotlight
				actionsBeforeElement="4/4"
				actions={[
					{
						text: formatMessage(messages.ok),
						onClick: (_: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
							fireUIAnalytics(
								analyticsEvent,
								'button clicked',
								'crossTeamPlanningOnboardingFinish',
							);
							nextCtpStage();
						},
					},
				]}
				heading={formatMessage(messages.heading)}
				/* The BoardsSpotlight uses the same anchor point as the TeamsSpotlight */
				target={CtpSpotlights.Teams}
				key={CtpSpotlights.Boards}
				targetBgColor={token('elevation.surface', colors.N0)}
				targetRadius={3}
				dialogPlacement="right top"
				dialogWidth={350}
				messageId="portfolio-3-onboarding.ui.ctp-onboarding.boards-spotlight.jira-spotlight"
				messageType="engagement"
			>
				<FormattedMessage {...messages.bodyFirst} />
				{issueSourceLinks}
			</JiraSpotlight>
			<FireScreenAnalytics />
		</ContextualAnalyticsData>
	);
};

const linkButtonStyles = css({
	display: 'block',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.inverse', colors.N0),
	paddingTop: token('space.100', '8px'),
	maxWidth: '100%',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text.inverse', colors.N0),
	},
	'&:focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text.inverse', colors.N0),
		outline: 'none',
	},
});
