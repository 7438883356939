import {
	clearFilter,
	changeFilter,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/title-bar/update-jira/actions.tsx';
import { USER_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/title-bar/update-jira/types.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { DispatchProps, RequestChangePayload } from './types';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	onRequestChange: ({ value, visibleIssueIds }: RequestChangePayload) =>
		dispatch(changeFilter({ id: USER_FILTER_ID, value, visibleIssueIds })),

	onRequestClear: () => dispatch(clearFilter(USER_FILTER_ID)),
});

export default mapDispatchToProps;
