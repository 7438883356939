import { ADD, type AddAction, RESET, type ResetAction } from './actions';
import type { IssueType } from './types';

const initialState: IssueType[] = [];

type Action = AddAction | ResetAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: IssueType[] = initialState, action: Action): IssueType[] => {
	switch (action.type) {
		case RESET:
			return action.payload.map(
				({ id, ...rest }): IssueType => ({
					...rest,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					id: parseInt(id as unknown as string, 10),
				}),
			);
		case ADD:
			return [
				...state,
				...action.payload.map(
					({ id, ...rest }): IssueType => ({
						...rest,
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						id: parseInt(id as unknown as string, 10),
					}),
				),
			];
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
