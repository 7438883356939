import {
	success,
	fail,
	cancel,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/update-jira/change-status/actions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (dispatch: any) => ({
	success: () => {
		dispatch(success());
	},
	fail: () => {
		dispatch(fail());
	},
	cancel: () => {
		dispatch(cancel());
	},
});
