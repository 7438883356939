import { getFilters } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters';
import type {
	FilterId,
	FilterValue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '../../reselect';
import type { Props, StateProps } from './types';

export default createStructuredSelector<State, Props, StateProps>({
	filterValueFor:
		(state: State) =>
		(id: FilterId): FilterValue =>
			getFilters(state)[id].value,
});
