import { getMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app';
import { getWarningFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/warning-filter';
import { getIssueTypesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types';
import { getProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects';
import { getIssueMapById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues';
import { getWarningViewSettings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings';
import {
	getAggregatedWarnings,
	getDisabledWarnings,
	getWarningsCount,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/warnings';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export default createStructuredSelector<State, {}, StateProps>({
	disabledWarnings: getDisabledWarnings,
	issueTypesById: getIssueTypesById,
	issuesById: getIssueMapById,
	mode: getMode,
	projectsById: getProjectsById,
	showWarning: (state: State) => getWarningViewSettings(state).showWarning,
	warningCount: getWarningsCount,
	warningFilterValue: (state: State) => getWarningFilter(state).value,
	warnings: getAggregatedWarnings,
});
