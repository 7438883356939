import {
	getAllSprints,
	getAllSprintsByIdMap,
	getExternalSprintsById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { Props, StateProps } from './types';

export default createStructuredSelector<State, Props, StateProps>({
	sprints: getAllSprints,
	sprintsByIdMap: getAllSprintsByIdMap,
	externalSprintsById: getExternalSprintsById,
});
