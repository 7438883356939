import React from 'react';
import { getWeek, addDays, isSameDay } from 'date-fns';
import { useIntl } from '@atlassian/jira-intl';
import {
	shortDateFormat,
	weekdayFormatLong,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/constants.tsx';
import { useDateFormatter } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import {
	ONE_DAY,
	ONE_WEEK,
	startOfUtcDay,
	dateDiffFromUTC,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import {
	useHorizontalPercentageOffset,
	useRenderingWindow,
	useHorizontalScrolling,
	useZoomLevel,
} from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import {
	getQuarterLabel,
	getTodayDate,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/timeline';
import {
	calculateFinancialYear,
	formatFY,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/timeline/year';
import { ZOOM_LEVELS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import { getDayUnits, getUnits } from '../../infinite-axis/utils';
import messages from '../messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from '../styles.module.css';
import {
	getTimeUnitsBarTopUnit,
	getTimeUnitsBarTopUnitPortal,
	getTimeUnitsBarBottomUnit,
	testId,
	isWeekendDay,
} from '../utils';

export default function InfiniteAxisUnitsBar({ fiscalMonth }: { fiscalMonth: number }) {
	const [{ getPercentageOffset }] = useHorizontalPercentageOffset();
	const [{ viewport }] = useHorizontalScrolling();
	const [{ today, from, to }] = useRenderingWindow();
	const [zoomLevel] = useZoomLevel();
	const { formatTimestamp } = useDateFormatter();
	const { formatMessage } = useIntl();

	let topBar;
	let bottomBar;

	/**
	 * In the top bar of the timeline header, units are rendered with an absolute positioning:
	 * e.g. | JAN 2022       | FEB 2022       | MAR 2022
	 * The first unit (JAN 2022) is rendered in a portal that sticks to the left edge of the timeline header.
	 * As the user scrolls right, the second unit gets closer to the first unit until it starts to overlap it.
	 * To prevent this overlap, we unmount the "portal" of the first unit and render it like all other units, then we
	 * decrease the left percentage of the first unit so that it slides to the left and makes room for the second unit.
	 * The overlap is unique to each mode "weeks", "months", "quarters" and "years".
	 */
	const calculateTopBarFirstUnitOffset = (overlap: number) =>
		`${getPercentageOffset(viewport.offset - overlap)}%`;

	// Top bar:    | MMM YYYY                |
	// Bottom bar: | D D D D D D D D D ... D |
	if (zoomLevel === ZOOM_LEVELS.WEEKS) {
		// top bar header
		const monthUnits = getUnits({ from, to }, ZOOM_LEVELS.MONTHS);
		topBar = monthUnits.map((unit, index) => {
			const isCurrentYear =
				new Date().getUTCFullYear() === new Date(today + unit.from).getUTCFullYear();
			const monthLabel = formatTimestamp(today + unit.from, {
				month: 'short',
				timeZone: 'UTC',
			});
			const yearLabel = formatTimestamp(today + unit.from, {
				year: '2-digit',
				timeZone: 'UTC',
			});
			const label = `${monthLabel}${isCurrentYear ? '' : ` ’${yearLabel}`}`;
			let offset = `${getPercentageOffset(unit.from)}%`;

			if (viewport.offset >= unit.from && viewport.offset <= unit.to) {
				const overlap = ONE_DAY * 2.25 - (unit.to - viewport.offset);
				if (overlap > 0) {
					offset = calculateTopBarFirstUnitOffset(overlap);
				} else {
					return getTimeUnitsBarTopUnitPortal({
						index,
						label,
					});
				}
			}

			return getTimeUnitsBarTopUnit({
				index,
				label,
				offset,
				width: 0,
				zoomLevel,
			});
		});

		// bottom bar header
		const dayUnits = getDayUnits({ from, to });
		bottomBar = dayUnits.map((unit, index) => {
			const offset = getPercentageOffset(unit.from);
			const width = getPercentageOffset(unit.to) - offset;
			const date = new Date(today + unit.from);
			const isToday = isSameDay(
				startOfUtcDay(date.getTime()),
				startOfUtcDay(getTodayDate().getTime() + dateDiffFromUTC() * ONE_DAY),
			);
			// Monday DD/MM/YYYY
			const tooltipContent = `${formatTimestamp(date.getTime(), weekdayFormatLong)}`;

			return getTimeUnitsBarBottomUnit({
				index,
				isToday,
				isWeekendDay: isWeekendDay(date),
				label: formatTimestamp(date.getTime(), {
					day: 'numeric',
					timeZone: 'UTC',
				}),
				offset,
				// right border on Sunday
				showRightBorder: date.getUTCDay() === 0,
				showLeftBorder: date.getUTCDay() === 1,
				width,
				zoomLevel,
				tooltipContent,
			});
		});
	}

	// Top bar:    | MMM YYYY                     |
	// Bottom bar: | D      D      D  ...  D      |
	else if (zoomLevel === ZOOM_LEVELS.MONTHS) {
		// top bar header
		const monthUnits = getUnits({ from, to }, ZOOM_LEVELS.MONTHS);
		topBar = monthUnits.map((unit, index) => {
			const isCurrentYear =
				new Date().getUTCFullYear() === new Date(today + unit.from).getUTCFullYear();
			const monthLabel = formatTimestamp(today + unit.from, {
				month: 'short',
				timeZone: 'UTC',
			});
			const yearLabel = formatTimestamp(today + unit.from, {
				year: '2-digit',
				timeZone: 'UTC',
			});
			const label = `${monthLabel}${isCurrentYear ? '' : ` ’${yearLabel}`}`;
			let offset = `${getPercentageOffset(unit.from)}%`;

			if (viewport.offset >= unit.from && viewport.offset <= unit.to) {
				const overlap = ONE_WEEK * 1.4 - (unit.to - viewport.offset);
				if (overlap > 0) {
					offset = calculateTopBarFirstUnitOffset(overlap);
				} else {
					return getTimeUnitsBarTopUnitPortal({
						index,
						label,
					});
				}
			}

			return getTimeUnitsBarTopUnit({
				index,
				label,
				offset,
				width: 0,
				zoomLevel,
			});
		});

		// bottom bar header
		const weekUnits = getUnits({ from, to }, ZOOM_LEVELS.WEEKS);
		// eslint-disable-next-line @typescript-eslint/no-shadow
		bottomBar = weekUnits.map(({ from, to }, index) => {
			const offset = getPercentageOffset(from);
			const width = getPercentageOffset(to) - offset;
			const date = new Date(today + from);
			const isToday = isSameDay(
				startOfUtcDay(date.getTime()),
				startOfUtcDay(getTodayDate().getTime() + dateDiffFromUTC() * ONE_DAY),
			);
			// Week X DD/MM/YYYY to DD/MM/YYYY
			const tooltipContent = formatMessage(messages.week, {
				weekNumber: getWeek(date.getTime()),
				firstDay: formatTimestamp(date.getTime(), shortDateFormat),
				lastDay: formatTimestamp(addDays(date, 6).getTime(), shortDateFormat),
			});
			return getTimeUnitsBarBottomUnit({
				alignTextToLeft: true,
				index,
				isToday,
				label: date.getUTCDate(),
				offset,
				showLeftPadding: false,
				showRightBorder: false,
				width,
				zoomLevel,
				tooltipContent,
			});
		});
	}

	// Top bar:    | QQ YYYY         |
	// Bottom bar: | MMM | MMM | MMM |
	else if (zoomLevel === ZOOM_LEVELS.QUARTERS) {
		// top bar header
		const topYearUnits = getUnits({ from, to }, ZOOM_LEVELS.YEARS, fiscalMonth);
		topBar = topYearUnits.map((unit, index) => {
			const date = new Date(today + unit.from);
			const label =
				fiscalMonth !== 1
					? formatMessage(messages.financialYearPrefix, {
							financialYear: formatFY(calculateFinancialYear(date.getTime(), fiscalMonth)),
						})
					: date.getUTCFullYear();
			let offset = `${getPercentageOffset(unit.from)}%`;

			if (viewport.offset >= unit.from && viewport.offset <= unit.to) {
				const overlap = ONE_WEEK * 4 - (unit.to - viewport.offset);
				if (overlap > 0) {
					offset = calculateTopBarFirstUnitOffset(overlap);
				} else {
					return getTimeUnitsBarTopUnitPortal({
						index,
						label,
					});
				}
			}

			return getTimeUnitsBarTopUnit({
				index,
				label,
				offset,
				width: 0,
				zoomLevel,
			});
		});

		// bottom bar header
		const bottomQuarterUnits = getUnits({ from, to }, ZOOM_LEVELS.QUARTERS, fiscalMonth);
		bottomBar = bottomQuarterUnits.map((unit, index) => {
			const offset = getPercentageOffset(unit.from);
			const width = getPercentageOffset(unit.to) - offset;
			const startDate = new Date(today + unit.from);
			const startTime = startDate.getTime();
			const endTime = new Date(today + unit.to - ONE_DAY).getTime();
			const thisMonth = startDate.getUTCMonth();
			const quarterNumber = getQuarterLabel(thisMonth, fiscalMonth);
			const quarterStartMonthShortString = formatTimestamp(today + unit.from, {
				month: 'short',
				timeZone: 'UTC',
			});
			const quarterEndMonthShortString = formatTimestamp(today + unit.to - ONE_DAY, {
				month: 'short',
				timeZone: 'UTC',
			});
			// Quarter X DD/MM/YYYY to DD/MM/YYYY, Financial Year YYYY
			// Quarter X DD/MM/YYYY to DD/MM/YYYY
			const tooltipContent =
				fiscalMonth !== 1
					? formatMessage(messages.financialYearQuarter, {
							quarterNumber,
							firstDay: formatTimestamp(startTime, shortDateFormat),
							lastDay: formatTimestamp(endTime, shortDateFormat),
							financialYear: calculateFinancialYear(startTime, fiscalMonth),
						})
					: formatMessage(messages.calendarYearQuarter, {
							quarterNumber,
							firstDay: formatTimestamp(startTime, shortDateFormat),
							lastDay: formatTimestamp(endTime, shortDateFormat),
						});

			return getTimeUnitsBarBottomUnit({
				index,
				label: formatMessage(messages.quarterDescriptorLong, {
					quarterNumber,
					quarterStartMonthShortString,
					quarterEndMonthShortString,
				}),
				offset,
				showRightBorder: false,
				showLeftBorder: true,
				width,
				zoomLevel: ZOOM_LEVELS.QUARTERS,
				tooltipContent,
			});
		});
	}

	// Top bar:    | YYYY              |
	// Bottom bar: | Q1 | Q2 | Q3 | Q4 |
	else if (zoomLevel === ZOOM_LEVELS.YEARS) {
		// top bar header
		const yearUnits = getUnits({ from, to }, ZOOM_LEVELS.YEARS, fiscalMonth);
		topBar = yearUnits.map((unit, index) => {
			const date = new Date(today + unit.from);
			const label =
				fiscalMonth !== 1
					? formatMessage(messages.financialYearPrefix, {
							financialYear: formatFY(calculateFinancialYear(date.getTime(), fiscalMonth)),
						})
					: date.getUTCFullYear();
			let offset = `${getPercentageOffset(unit.from)}%`;

			if (viewport.offset >= unit.from && viewport.offset <= unit.to) {
				const overlap = ONE_WEEK * 10 - (unit.to - viewport.offset);
				if (overlap > 0) {
					offset = calculateTopBarFirstUnitOffset(overlap);
				} else {
					return getTimeUnitsBarTopUnitPortal({
						index,
						label,
					});
				}
			}

			return getTimeUnitsBarTopUnit({
				index,
				label,
				offset,
				width: 0,
				zoomLevel,
			});
		});

		// bottom bar header
		const quarterUnits = getUnits({ from, to }, ZOOM_LEVELS.QUARTERS, fiscalMonth);
		bottomBar = quarterUnits.map((unit, index) => {
			const offset = getPercentageOffset(unit.from);
			const width = getPercentageOffset(unit.to) - offset;
			const date = new Date(today + unit.from);
			const startTime = date.getTime();
			const endTime = new Date(today + unit.to - ONE_DAY).getTime();
			const quarterNumber = getQuarterLabel(date.getUTCMonth(), fiscalMonth);

			// Quarter X DD/MM/YYYY to DD/MM/YYYY, Financial Year YYYY
			// Quarter X DD/MM/YYYY to DD/MM/YYYY
			const tooltipContent =
				fiscalMonth !== 1
					? formatMessage(messages.financialYearQuarter, {
							quarterNumber,
							firstDay: formatTimestamp(startTime, shortDateFormat),
							lastDay: formatTimestamp(endTime, shortDateFormat),
							financialYear: calculateFinancialYear(startTime, fiscalMonth),
						})
					: formatMessage(messages.calendarYearQuarter, {
							quarterNumber,
							firstDay: formatTimestamp(startTime, shortDateFormat),
							lastDay: formatTimestamp(endTime, shortDateFormat),
						});
			return getTimeUnitsBarBottomUnit({
				index,
				label: formatMessage(messages.quarterDescriptorShort, { quarterNumber }),
				offset,
				// right border on each quarter
				showRightBorder: false,
				showLeftBorder: quarterNumber === '1',
				width,
				zoomLevel,
				tooltipContent,
			});
		});
	}

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.timeUnitsBar}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div data-testid={`${testId}.top`} className={styles.timeUnitsBarTop}>
				{topBar}
			</div>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div data-testid={`${testId}.bottom`} className={styles.timeUnitsBarBottom}>
				{bottomBar}
			</div>
		</div>
	);
}
