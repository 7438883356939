import React, { Component, type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { FormattedMessage } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import { ISSUE_LINK_DIRECTION } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import DependencySelector from './dependency-selector';
import type { DependencyValueType } from './dependency-selector/type-selector/types';
import messages from './messages';
import type { Props, State } from './types';

// eslint-disable-next-line jira/react/no-class-components
export default class AddDependencyRow extends Component<Props, State> {
	state = {
		isAddingNewDependency: this.props.isInitiallyAddingNewDependency,
		addDependencyIssue: null,
		addDependencyType: null,
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onAddDependency = (e: SyntheticEvent<any>) => {
		// prevent InlineDialog from closing
		// it's required because at the moment InlineDialog processes event
		// current button is removed from DOM because view is flipped
		// and InlineDialog thinks that it was click outside
		e.preventDefault();
		this.setState({ isAddingNewDependency: true });
		this.props.updatePopup?.();
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onAddDependencyConfirm = (e: SyntheticEvent<any>, analyticsEvent: UIAnalyticsEvent) => {
		// prevent InlineDialog from closing
		// it's required because at the moment InlineDialog processes event
		// current button is removed from DOM because view is flipped
		// and InlineDialog thinks that it was click outside
		e.preventDefault();
		const { addDependency, direction, issue, issueLinkTypes, context, issueCountByLinkType } =
			this.props;
		const { addDependencyType, addDependencyIssue } = this.state;
		if (!addDependencyIssue) {
			throw new Error('Target issue is not selected!');
		}

		// note: this is a workaround to fix a gnarkly TS issue
		const newIssue: Issue = addDependencyIssue;

		const type = (addDependencyType || issueLinkTypes[0]).id;

		if (direction === ISSUE_LINK_DIRECTION.OUTWARD) {
			addDependency({
				sourceItemKey: issue.id,
				targetItemKey: newIssue.id,
				type,
			});
		} else {
			addDependency({
				sourceItemKey: newIssue.id,
				targetItemKey: issue.id,
				type,
			});
		}
		this.setState({ isAddingNewDependency: false });

		this.props.updatePopup?.();

		// triggering analytics when a user adds a new dependency relationship to an issue in the plan
		const [actionSubject, action] =
			PRODUCT_ANALYTICS_EVENT_NAMES.ADDED_ISSUE_DEPENDENCY_LINK.split(' ');

		const analyticsPayload = {
			source: context,
			type,
			typeCount: issueCountByLinkType[type] || 0,
		};

		fireUIAnalytics(analyticsEvent.update({ action, actionSubject }), analyticsPayload);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onAddDependencyCancel = (e: SyntheticEvent<any>) => {
		// prevent InlineDialog from closing
		// it's required because at the moment InlineDialog processes event
		// current button is removed from DOM because view is flipped
		// and InlineDialog thinks that it was click outside
		e.preventDefault();
		this.setState({ isAddingNewDependency: false });
		this.props.updatePopup?.();
	};

	onChangeDependencyType = (option: DependencyValueType) => {
		if (option?.type === undefined) {
			throw new Error('Make sure that dependency selector items have type injected');
		}

		this.setState({ addDependencyType: option.type });
	};

	onChangeDependencyIssue = (option?: Issue | null) => {
		this.setState({ addDependencyIssue: option });
		this.props.updatePopup?.();
	};

	renderAddDependencyButton = () => (
		<Button
			appearance="link"
			onClick={this.onAddDependency}
			testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.add-dependency"
		>
			<FormattedMessage {...messages.addDependencyLabel} />
		</Button>
	);

	render() {
		const { addDependencyIssue, addDependencyType, isAddingNewDependency } = this.state;
		if (!isAddingNewDependency) {
			return this.renderAddDependencyButton();
		}
		const {
			direction,
			issue,
			issueLinkTypes,
			internalIssueLinks,
			issueTypes,
			issues,
			projectsById,
			isInitiallyAddingNewDependency,
		} = this.props;
		const dependencySelectorProps = {
			addDependencyIssue,
			addDependencyType,
			direction,
			issue,
			issueLinkTypes,
			internalIssueLinks,
			issueTypes,
			issues,
			onAddDependencyCancel: this.onAddDependencyCancel,
			onAddDependencyConfirm: this.onAddDependencyConfirm,
			onChangeDependencyIssue: this.onChangeDependencyIssue,
			onChangeDependencyType: this.onChangeDependencyType,
			projectsById,
			isInitiallyAddingNewDependency,
		};

		return <DependencySelector {...dependencySelectorProps} />;
	}
}
