import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	actionAccept: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.suggestion-list-item.action-accept',
		defaultMessage: 'Accept',
		description: 'Accept suggestions button label.',
	},
	actionRemove: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.suggestion-list-item.action-remove',
		defaultMessage: 'Remove',
		description: 'Remove button label button label.',
	},
});
