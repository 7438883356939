/** @jsx jsx */
import React, { useCallback, useMemo, useState, type FC } from 'react';
import { styled, css, jsx } from '@compiled/react';
import LikeIcon from '@atlaskit/icon/core/migration/thumbs-up--like';
import Link from '@atlaskit/link';
import Lozenge from '@atlaskit/lozenge';
import { N500, N200, N70 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { FeedbackButton } from '@atlassian/jira-atlassian-intelligence/src/common/ui/feedback-button';
import {
	EntryKeys,
	ENTRYPOINT_ID_JIRA_PLATFORM,
} from '@atlassian/jira-feedback-collector/src/constants.tsx';
import { useFeedbackCollectorActions } from '@atlassian/jira-feedback-collector/src/controllers/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import messages from './messages.tsx';

const styles = css({
	marginBottom: token('space.200', '16px'),
});

export type Props = {
	isDisabled?: boolean;
	onFeedbackClicked: (aiFeedbackResult: 'up' | 'down') => void;
	onFeedbackSent: (aiFeedbackResult: 'up' | 'down') => void;
};

export const FeedbackForm: FC<Props> = ({
	isDisabled = false,
	onFeedbackClicked,
	onFeedbackSent,
}) => {
	const [isFeedbackDisabled, setIsFeedbackDisabled] = useState(isDisabled);
	const [wasFeedbackSent, setWasFeedbackSent] = useState(false);

	const cloudId = useCloudId();
	const { openFeedbackCollector } = useFeedbackCollectorActions();
	const { formatMessage } = useIntl();
	const isAdmin = useIsAdmin();
	const locale = useLocale();

	const handleFeedbackSent = useCallback((wasSent: boolean) => {
		setWasFeedbackSent(wasSent);
		setIsFeedbackDisabled(wasSent);
	}, []);

	const getSentiment = useCallback((isPositive: boolean) => (isPositive ? 'good' : 'bad'), []);

	const getContactLabel = useCallback(() => {
		const linkToPrivacyPolicy = 'https://www.atlassian.com/legal/privacy-policy';

		return (
			<p>
				{formatMessage(messages.canContactPrompt)}{' '}
				<Link href={linkToPrivacyPolicy} target="_blank" rel="noopener noreferrer">
					{formatMessage(messages.canContactPromptLink)}
				</Link>
			</p>
		);
	}, [formatMessage]);

	const getResearchLabel = useCallback(() => {
		const linkToARG = 'https://www.atlassian.com/research-group';

		return (
			<p>
				{formatMessage(messages.enrollInResearchPrompt, {
					link: (
						<Link href={linkToARG} target="_blank" rel="noopener noreferrer">
							{formatMessage(messages.enrollInResearchLink)}
						</Link>
					),
				})}
			</p>
		);
	}, [formatMessage]);

	const getDescription = useCallback(
		() => (
			<p css={styles}>
				{formatMessage(messages.plansReportBug, {
					contactSupportLink: (
						<Link href="https://support.atlassian.com/contact/#/" target="_blank">
							{formatMessage(messages.contactSupport)}
						</Link>
					),
				})}
			</p>
		),
		[formatMessage],
	);

	const getFeedbackContext = useCallback(
		(isPositive: boolean) => ({
			canBeContactedLabel: getContactLabel(),
			enrolInResearchLabel: getResearchLabel(),
			entrypointId: ENTRYPOINT_ID_JIRA_PLATFORM,
			feedbackContext: [
				{
					id: EntryKeys.CLOUD_ID,
					value: cloudId,
				},
				{
					id: EntryKeys.FEEDBACK_COLLECTOR_LOCATION,
					value: 'jsw.advanced-roadmaps',
				},
				{
					id: EntryKeys.IS_ADMIN,
					value: !!isAdmin,
				},
				{
					id: EntryKeys.LOCATION,
					value: locale,
				},
				{
					id: EntryKeys.SOURCE,
					value: 'plans-ai-work-breakdown',
				},
				{ id: 'sentiment', value: getSentiment(isPositive) },
			],
			feedbackTitle: formatMessage(messages.giveFeedback),
			feedbackTitleDetails: getDescription(),
			onClose() {
				handleFeedbackSent(false);
			},
			// Doing this allows for feedback to be reenabled if cancel button is clicked on feedback form
			onSubmit() {
				handleFeedbackSent(true);
				const aiFeedbackResult = isPositive ? 'up' : 'down';
				onFeedbackSent(aiFeedbackResult);
			},
		}),
		[
			cloudId,
			formatMessage,
			getContactLabel,
			getDescription,
			getResearchLabel,
			getSentiment,
			handleFeedbackSent,
			isAdmin,
			locale,
			onFeedbackSent,
		],
	);

	const handleOnClick = useCallback(
		(isPositive: boolean) => {
			if (!isFeedbackDisabled) {
				setIsFeedbackDisabled(true);
				openFeedbackCollector(getFeedbackContext(isPositive));
			}
			const aiFeedbackResult = isPositive ? 'up' : 'down';
			onFeedbackClicked(aiFeedbackResult);
			// disable hover pop-up of feedback button
			return true;
		},
		[getFeedbackContext, isFeedbackDisabled, onFeedbackClicked, openFeedbackCollector],
	);

	const likeButton = useMemo(
		() => (
			<LikeIcon
				color={
					isFeedbackDisabled ? token('color.icon.disabled', N70) : token('color.icon.subtle', N200)
				}
				spacing="spacious"
				label={formatMessage(messages.feedbackIconLabel)}
			/>
		),
		[formatMessage, isFeedbackDisabled],
	);

	return (
		<FormContainer data-testid="portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.feedback-form.container">
			<FeedbackGroup>
				{wasFeedbackSent && (
					<Lozenge
						appearance="success"
						testId="portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.feedback-form.beta-lozenge"
					>
						{formatMessage(messages.feedbackSentLozenge)}
					</Lozenge>
				)}

				<ButtonStyle>
					<FeedbackButton
						appearance="subtle"
						iconBefore={likeButton}
						isDisabled={isFeedbackDisabled}
						isPositive
						onClick={() => handleOnClick(true)}
						spacing="compact"
						testId="portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.feedback-form.like-button"
						tooltipContent={formatMessage(messages.tooltipLike)}
					/>
				</ButtonStyle>

				<ButtonStyle isDislike>
					<FeedbackButton
						appearance="subtle"
						iconBefore={likeButton}
						isDisabled={isFeedbackDisabled}
						isPositive={false}
						onClick={() => handleOnClick(false)}
						spacing="compact"
						testId="portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.feedback-form.dislike-button"
						tooltipContent={formatMessage(messages.tooltipDislike)}
					/>
				</ButtonStyle>
			</FeedbackGroup>
		</FormContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const FormContainer = styled.div({
	display: 'flex',
	gap: token('space.050', '4px'),
	borderTopLeftRadius: 'unset',
	borderTopRightRadius: 'unset',
	borderBottomLeftRadius: `${token('border.radius.200', '6px')}`,
	borderBottomRightRadius: `${token('border.radius.200', '6px')}`,
	alignItems: 'center',
	justifyContent: 'space-between',
	color: token('color.text.subtle', N500),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const FeedbackGroup = styled.div({
	display: 'flex',
	flexWrap: 'wrap',
	alignItems: 'center',
	padding: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const ButtonStyle = styled.div<{
	isDislike?: boolean;
}>(
	{
		maxHeight: token('space.300', '24px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isDislike = false }) =>
		isDislike
			? {
					transform: 'rotate(180deg)',
				}
			: {},
);
