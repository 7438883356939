import type { Effect } from 'redux-saga';
import { fork, takeEvery, put, call } from 'redux-saga/effects';
import type * as Api from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch';
import * as issueTypesActions from '../../state/domain/issue-types/actions';
import { parseError } from '../api';
import { genericError } from '../errors';
import { urls } from './api';

const GET_ISSUE_TYPES_FOR_IDS = 'command.issue-type.GET_ISSUE_TYPES_FOR_IDS' as const;

type GetIssueTypesForIdsPayload = number[];
type GetIssueTypesForIdsAction = {
	type: typeof GET_ISSUE_TYPES_FOR_IDS;
	payload: GetIssueTypesForIdsPayload;
};

export const getIssueTypesForIds = (
	payload: GetIssueTypesForIdsPayload,
): GetIssueTypesForIdsAction => ({
	type: GET_ISSUE_TYPES_FOR_IDS,
	payload,
});

export function* doGetIssueTypesForIds({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: GetIssueTypesForIdsAction): Generator<Effect, any, any> {
	const url = urls.issueTypesForIds;
	const body = { ids: payload };

	const response = yield call(fetch, url, {
		method: 'POST',
		body,
	});

	if (response.ok) {
		const issueTypes: Api.IssueType[] = yield call(response.json.bind(response));

		yield put(issueTypesActions.add(issueTypes));
	} else {
		yield put(
			genericError({
				...parseError(response, yield call(response.text.bind(response))),
				requestInfo: {
					url,
					type: 'POST',
					status: response.status,
					body,
				},
			}),
		);
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchGetIssueTypesForIds(): Generator<Effect, any, any> {
	yield takeEvery(GET_ISSUE_TYPES_FOR_IDS, doGetIssueTypesForIds);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchGetIssueTypesForIds);
}
