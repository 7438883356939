import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useDragIndicator } from '@atlassian/jira-portfolio-3-issue-drag-and-drop/src/controller/hooks.tsx';
import { Z_INDEX_LAYER } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/types.tsx';
import { ZIndex } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/view.tsx';
import { useCell } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/cell/index.tsx';

export const DragIndicator = () => {
	const [{ row }] = useCell();
	const indicator = useDragIndicator(row);

	if (!indicator) return null;

	return (
		<ZIndex layer={Z_INDEX_LAYER.ISSUE_BARS}>
			{(zIndex) => <Box xcss={coverStyles} style={{ zIndex }} />}
		</ZIndex>
	);
};

const coverStyles = xcss({
	// @ts-expect-error - TS2322: Type number is not assignable to type
	zIndex: 'auto',
	position: 'absolute',
	width: '100%',
	height: '100%',
	top: 'space.0',
	left: 'space.0',
	backgroundColor: 'color.background.accent.gray.subtler',
	pointerEvents: 'none',
});
