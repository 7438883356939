import type { ComponentType } from 'react';
import type { IntlShape } from '@atlassian/jira-intl';
import type { CustomField } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/custom-fields/types';
import type { EnrichedHierarchyLevel } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/hierarchy/types';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import type {
	Group,
	ScopeIssue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { ToggleExpandGroupActionPayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/visualisations/actions.tsx';
import type { StartInlineCreateActionPayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/scope/inline-create/actions.tsx';
import {
	GROUPING,
	type Grouping,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';

export type HierarchyItem = {
	id: number;
	value: number;
	title: string;
	visible?: boolean;
};

export const ISSUE_CREATE_SUPPORTED_GROUPS: readonly string[] = [
	GROUPING.NONE,
	GROUPING.ASSIGNEE,
	GROUPING.REPORTER,
	GROUPING.PROJECT,
	GROUPING.RELEASE,
	GROUPING.SPRINT,
	GROUPING.TEAM,
] as const;

export type DispatchProps = {
	setDefaultProject: (arg1: number) => void;
	setGlobalCreateIssue: () => void;
	setIssueTypeIdForHierarchy: (hierarchyLevel: number, issueTypeId: number) => void;
	startInlineCreate: (arg1: StartInlineCreateActionPayload) => void;
	toggleExpandGroup: (arg1: ToggleExpandGroupActionPayload) => void;
};

export type Props = DispatchProps & {
	typeToLevel: (arg1: number) => number;
	currentHierarchyLevels: HierarchyItem[];
	intl: IntlShape;
	projects: Project[];
	isDefaultOpen: boolean;
	issueTypeId: number;
	visualisationGrouping?: Grouping;
	groups?: Group<ScopeIssue>[];
	IssueTypeIdForHierarchy?: {
		[hierarchyLevel: number]: number;
	};
	defaultProjectId?: number;
	enrichedHierarchyLevels: EnrichedHierarchyLevel[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	MenuItem: ComponentType<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	NestedMenu: ComponentType<any>;
	customFieldsById: { [id: string]: CustomField };
};

export type State = {
	isMenuOpen: boolean;
};

export type OriginalProps = Flow.Diff<
	Props,
	{
		intl: IntlShape;
	}
>;
