import {
	clearAll,
	change,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/filters';
import type {
	FilterValue,
	FilterId,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import type { MapDispatchToPropsSimple } from '../../types/redux';
import type { DispatchProps } from './types';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	quickFilter: (id: FilterId, value: FilterValue) => {
		dispatch(clearAll());
		dispatch(change({ id, value }));
	},
	clearFilter: () => dispatch(clearAll()),
});

export default mapDispatchToProps;
