import { getSprintFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/sprint-filter';
import { getPlan } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan';
import { getTeamsWorkingWithSprints } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues';
import {
	getSprintStreamsUnitsForTeams,
	getSprintStreamsUnitsGetter,
	getSprintIdsWithDatesIsInconsistent,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints';
import { getTeamsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams';
import { getWarningViewSettings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { Props, StateProps } from './types';

export default createStructuredSelector<State, Props, StateProps>({
	filteredIds: (state) => getSprintFilter(state).value,
	getStreamsUnits: getSprintStreamsUnitsGetter,
	plan: getPlan,
	showWarning: (state) => getWarningViewSettings(state).showWarning,
	sprintIdsWithDatesIsInconsistent: getSprintIdsWithDatesIsInconsistent,
	streams: (state: State, props: Props) => getSprintStreamsUnitsForTeams(state)[props.team],
	teamObject: (state: State, props: Props) => getTeamsById(state)[props.team],
	teamsWithSprints: (state: State) => getTeamsWorkingWithSprints(state),
});
