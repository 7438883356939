import React from 'react';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip';
import type { Props } from './types';

export default function SingleSelectCustomField(props: Props) {
	const { value, singleSelectionOptions } = props;
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const option = singleSelectionOptions.find((option) => option.id.toString() === value);
	if (!option) {
		return <>{value}</>;
	}
	return <EllipsedWithTooltip content={option.value}>{option.value}</EllipsedWithTooltip>;
}
