import React, { Component, useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { useIntl } from '@atlassian/jira-intl';
import HoverObserver from '@atlassian/jira-portfolio-3-common/src/hover-observer/index.tsx';
import ScrollLock from '@atlassian/jira-portfolio-3-common/src/scroll-lock/index.tsx';
import {
	AsyncSelectWithTimelineStyles,
	Select as CommonSelect,
	AsyncSelect as CommonAsyncSelect,
} from '@atlassian/jira-portfolio-3-common/src/select';
import type { Props as SelectProps } from '@atlassian/jira-portfolio-3-common/src/select/types';
import { INLINE_CREATE_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/scope/inline-create/reducer.tsx';
import { getBody } from '../../dom';
import messages from './messages';
import type {
	AsyncSelectForColumnCellProps,
	AsyncSelectOptionsOrGroups,
	SelectForColumnCellProps,
	State,
} from './types';

export const MIN_SELECT_WIDTH = 300;

export function Select({ noOptionsMessage, ...props }: SelectProps) {
	const intl = useIntl();

	const selectProps: SelectProps = {
		...props,
		noOptionsMessage: noOptionsMessage || (() => intl.formatMessage({ ...messages.noMatchesText })),
	};
	return <CommonSelect {...selectProps} />;
}

export const AsyncSelect = (props: SelectProps) => {
	const intl = useIntl();

	const {
		noOptionsMessage = () => intl.formatMessage({ ...messages.noMatchesText }),
		...restProps
	} = props;

	const selectProps: SelectProps = {
		...restProps,
		noOptionsMessage,
	};
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return <CommonAsyncSelect {...(selectProps as unknown as Record<string, string>)} />;
};

// This wrapper layer does a few things:
// 1. it handles setting the default options for the select when the menu is opened. This is possible using basic AsyncSelect props,
// but results in the loadOptions function being called when the component mounts, which is not ideal.
// 2. it handles debouncing the loadOptions function. This restricts us to the using the callback form of loadOptions,
// as the promise returning form does not play well with debounce (you end up with out of sync query/results)
export function AsyncSelectForColumnCell({
	showPlaceholder,
	issueId,
	lockInlineCreate,
	placeholder,
	onMenuClose,
	onMenuOpen,
	styles,
	loadOptions,
	...selectProps
}: AsyncSelectForColumnCellProps) {
	const [defaultOptions, setDefaultOptions] = useState<AsyncSelectOptionsOrGroups>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [menuIsOpen, setMenuIsOpen] = useState(false);
	const [placeholderState, setPlaceholderState] = useState('');

	const intl = useIntl();

	useEffect(() => {
		if (issueId === INLINE_CREATE_ID) {
			lockInlineCreate(false);
		}
	}, [issueId, lockInlineCreate]);

	const handleMenuOpen = useCallback(() => {
		if (issueId === INLINE_CREATE_ID) {
			lockInlineCreate(true);
		}
		setPlaceholderState(placeholder ?? '');
		setMenuIsOpen(true);
		setIsLoading(true);
		loadOptions('', defaultOptionsCallback);

		if (onMenuOpen) {
			onMenuOpen();
		}
	}, [issueId, loadOptions, lockInlineCreate, onMenuOpen, placeholder]);

	const handleMenuClose = () => {
		if (issueId === INLINE_CREATE_ID) {
			lockInlineCreate(false);
		}
		setPlaceholderState('');
		setMenuIsOpen(false);
		if (onMenuClose) {
			onMenuClose();
		}
	};

	const defaultOptionsCallback = (defaultOrganizations: AsyncSelectOptionsOrGroups) => {
		setDefaultOptions && setDefaultOptions(defaultOrganizations);
		setIsLoading(false);
	};

	const loadOptionsDebounced = useMemo(
		() => debounce(loadOptions, 250, { leading: true }),
		[loadOptions],
	);

	return (
		<>
			<ScrollLock enabled={menuIsOpen} />
			<AsyncSelectWithTimelineStyles
				menuPlacement="auto"
				cacheOptions
				menuPortalTarget={getBody()}
				minSelectWidth={MIN_SELECT_WIDTH}
				onMenuClose={handleMenuClose}
				onMenuOpen={handleMenuOpen}
				scrollLock
				placeholder={placeholderState}
				appearance="subtle"
				minMenuHeight={300}
				styles={{
					// eslint-disable-next-line @typescript-eslint/no-shadow
					placeholder: (styles) => ({
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						...styles,
					}),
					...styles,
				}}
				defaultOptions={defaultOptions}
				isLoading={isLoading}
				loadOptions={loadOptionsDebounced}
				noOptionsMessage={() => intl.formatMessage({ ...messages.noMatchesText })}
				{...selectProps}
			/>
		</>
	);
}

// eslint-disable-next-line jira/react/no-class-components
export class SelectForColumnCell extends Component<SelectForColumnCellProps, State> {
	state = {
		// note: showPlaceholder is only used for VR test purposes in order to take a snapshot of the picklist placeholder
		isHovered: this.props.showPlaceholder || false,
		placeholder: '',
	};

	// eslint-disable-next-line react/sort-comp
	setHovered = (isHovered: boolean) => this.setState({ isHovered });

	componentWillUnmount() {
		if (this.props.issueId === INLINE_CREATE_ID) {
			this.props.lockInlineCreate(false);
		}
	}

	onMenuOpen = () => {
		if (this.props.issueId === INLINE_CREATE_ID) {
			this.props.lockInlineCreate(true);
		}
		this.setState({ placeholder: this.props.placeholder ?? '' });
		if (this.props.onMenuOpen) {
			this.props.onMenuOpen();
		}
	};

	onMenuClose = () => {
		if (this.props.issueId === INLINE_CREATE_ID) {
			this.props.lockInlineCreate(false);
		}
		this.setState({ placeholder: '' });
		if (this.props.onMenuClose) {
			this.props.onMenuClose();
		}
	};

	render() {
		const {
			issueId,
			onMenuClose,
			onMenuOpen,
			attribute,
			lockInlineCreate,
			styles,
			placeholder,
			...selectProps
		} = this.props;

		return (
			<HoverObserver onHoverChanged={this.setHovered}>
				<Select
					menuPlacement="auto"
					menuPortalTarget={getBody()}
					minSelectWidth={MIN_SELECT_WIDTH}
					onMenuClose={this.onMenuClose}
					onMenuOpen={this.onMenuOpen}
					scrollLock
					placeholder={this.state.placeholder}
					styles={{
						// eslint-disable-next-line @typescript-eslint/no-shadow
						placeholder: (styles) => ({
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							...styles,
						}),
						...styles,
					}}
					{...selectProps}
				/>
			</HoverObserver>
		);
	}
}

export default Select;

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { selectUtils } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
