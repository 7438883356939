import {
	type CombinedState,
	type ReducersMapObject,
	combineReducers as reduxCombineReducers,
	type Reducer,
	type Dispatch,
} from 'redux';
import levenshtein from 'fast-levenshtein';
import * as R from 'ramda';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { showError } from '../../app-simple-plans/state/ui/errors/actions';
import { PACKAGE_NAME, ERROR_REPORTING_PACKAGE, ERROR_REPORTING_TEAM } from '../view/constant';

/**
   This is an extended version of Redux'es combineReducers. It implements
   support for Composite Pattern. Essentially, it accepts second optional
   argument being a function which can respond to any action with a list of
   actions to be processed by combined reducers. You can think about it as
   batched actions with a few nice properties:

   1. It's straightforward and consistent with `combineReducers` approach to
   structuring state. Initial action is processed on the level which is the
   closest common ancestor to changed branches. No state update directly happens
   on that level, real updates are still in leaves.

   2. It's efficient. It calls reducers straight away without ceremony with
   additional message passing. It returns the same reference if those reducers
   haven't changed branches.

   3. It's versatile. Though you are restricted with what you want to perform
   inside composite (the same restrictions as for reducers for the same good
   reasons), you have access to both state and action payload and can do any
   preparations required to pass data down the tree.

   4. It's easy to test. It's just a pure function with well-defined input
   scope.

   5. It's discoverable. You have one well-defined entry point for you action
   disregard of how it was created, and reducers to follow up for actual state
   update are right there, in the subtree of module where composite was defined.
   In complex cases you can descend tree layer by layer to the desired level of
   specificity. Also your logic is kept in reducers as opposed to moving it out to
   action creators as is done in other solutions.

   6. It's friendly. Whenever you make a typo in a delegated reducer name, you
   get suggestion how to correct it ;-)

   Contrived example:

    export default combineReducers<*, *>({ issues, versions }, (state, action) => {
        switch (action.type) {
            case NUKE_VERSION:
                const versionId = action.payload;
                const issueIds = state.issues
                    .filter(({ version }) => version === versionId)
                    .map(({ id }) => id);
                return {
                    issues: [{ type: DELETE_ISSUES, payload: issueIds }],
                    versions: [{ type: DELETE_VERSIONS, payload: [versionId] }],
                };
            default:
                return {};
        }
    });

*/

// currying helper functions makes their use with Ramda more readable
// compare R.minBy(distance(typo)) vs R.minBy((suggestion) => distance(typo, suggestion))
// R.curryN used instead of R.curry because levenshtein.get can take 3 arguments
const distance = R.curryN(2, levenshtein.get);

// just an aid for developer to spot exact typo without switching contexts
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const speller = (suggestions: Array<any | string>) => (typo: any) =>
	R.reduce(R.minBy(distance(typo)), '', suggestions);

type Composite<S, A, C> = (state: S, action: A) => C;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function combineReducers<S extends Record<string, any>, A extends Action, C extends S = any>(
	reducers: ReducersMapObject<S, A>,
	composite?: Composite<S, A, C>,
): Reducer<CombinedState<S>, A> {
	const combinedReducers = reduxCombineReducers(reducers);
	if (!composite) {
		return combinedReducers;
	}
	const getSuggestion = speller(Object.keys(reducers));
	return (previousState: CombinedState<S> | undefined, action: A) => {
		const state = combinedReducers(previousState, action);
		if (!composite) {
			return state;
		}
		const compositeActions = composite(state, action);
		const compositeActionsKeys = Object.keys(compositeActions);
		const nextState = { ...state };
		let hasChanged = false;
		for (const key of compositeActionsKeys) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const reducer = reducers[key as keyof S];
			if (!reducer) {
				throw new Error(
					`Composite returned missing reducer "${key}", did you mean "${getSuggestion(key)}"?`,
				);
			}
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const stateForKey = state[key as keyof S];
			const nextStateForKey = R.reduce(reducer, stateForKey, compositeActions[key]);
			hasChanged = hasChanged || nextStateForKey !== stateForKey;
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			nextState[key as keyof S] = nextStateForKey;
		}
		return hasChanged ? nextState : state;
	};
}

type Action = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	type: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	payload: any;
};

export const errorReporterMiddleware = () => (next: Dispatch<Action>) => (action: Action) => {
	try {
		return next(action);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		fireErrorAnalytics({
			meta: {
				id: ERROR_REPORTING_PACKAGE.REDUX,
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			error: err,
			attributes: {
				action: action.type,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
		return next(
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			showError(err.message, err.stack, window?.location.hash, window?.location.pathname),
		);
	}
};
