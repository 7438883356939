import { fg } from '@atlassian/jira-feature-gating';
import { selectorWithCondition } from '@atlassian/jira-issue-view-common-utils/src/selector-with-condition/index.tsx';
import {
	type ViewMode,
	VIEW_MODES,
} from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import {
	getColumnConfigs,
	getRowConfigs,
	getRowStickiness,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/grid';
import { getViewMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings';
import { createSelector } from '../../reselect';
import {
	Z_INDEX_LEVEL as Z_INDEX_LEVEL_NEW,
	Z_INDEX_LEVEL_OLD,
	Z_INDEX_LAYER,
	type Params,
	type StateProps,
} from './types';

export const getNumberOfRows = createSelector([getRowConfigs], (rows) => rows.length);
export const getNumberOfColumns = createSelector([getColumnConfigs], (columns) => columns.length);

const STATIC_LEVEL_SIZE = 4;
const BUFFER_SIZE = 1;

/**
 * This function allocates z-index by calculating their relative values, then placing them inside levels.
 *
 * A level is a segment of the UI which has as many z-index values as there are columns on the timeline, with a number
 * reserved for static UI elements.
 *
 * There are an infinite number of levels, since it can be supplied to the `lift` function as a parameter.
 *
 * The buffer is used to create a gap between the z-index values of different levels. This is done by adding the buffer size
 * to the input value in the `lift` function. The buffer size is also added to the number of columns when calculating the size
 * of a level. This ensures that there is always a gap between the z-index values of different levels, preventing overlap.
 *
 * The buffer size is set to 1, meaning there is always a gap of 1 between the z-index values of different levels.
 *
 * For example, a table with 5 columns would have a level structure like this.
 *
 * - Level ...
 *   - ...
 * - Level 2
 *   - Top Buffer (16)
 *   - 4 (15)
 *   - 3 (14)
 *   - 2 (13)
 *   - 1 (12)
 *   - 0 (11)
 *   - Bottom Buffer (10)
 * - Level 1
 *   - Top Buffer (9)
 *   - 4 (8)
 *   - 3 (7)
 *   - 2 (6) <-- `lift(1)(2)`
 *   - 1 (5)
 *   - 0 (4)
 *   - Bottom Buffer (3) <-- `lift(1)(-1)`
 * - Static Level
 *   - 2 (2)
 *   - 1 (1)
 *   - 0 (0)
 */
export const getZIndexPure = (
	numOfColumns: number,
	numOfRows: number,
	rowSticky: boolean[],
	viewMode: ViewMode,
): StateProps['zIndex'] => {
	const Z_INDEX_LEVEL = Z_INDEX_LEVEL_NEW;

	const lift = (level: number) => (input: number) => {
		const levelSize = numOfColumns + BUFFER_SIZE * 2;
		const levelsBelow = level * levelSize;
		const offset = input + BUFFER_SIZE;

		return offset + levelsBelow + STATIC_LEVEL_SIZE;
	};

	return (props: Params) => {
		if ('layer' in props) {
			switch (props.layer) {
				case Z_INDEX_LAYER.DEPENDENCY_LINES: {
					return 1;
				}

				case Z_INDEX_LAYER.ISSUE_BARS:
					return 2;

				case Z_INDEX_LAYER.ISSUE_BARS_ARROWS: {
					return 3;
				}

				case Z_INDEX_LAYER.AXIS_STICKY_TIME_UNIT: {
					return lift(Z_INDEX_LEVEL.HEADER)(1); // Above the final header cell
				}

				case Z_INDEX_LAYER.STICKY_HEADER_SCROLL_SHADOW: {
					return lift(Z_INDEX_LEVEL.SCOPE_SUBHEADER)(-1); // Just under the subheader cells
				}

				case Z_INDEX_LAYER.HORIZONTAL_SCROLL_BAR:
				case Z_INDEX_LAYER.DEPENDENCY_LINE_DRAG_PREVIEW: {
					return lift(Z_INDEX_LEVEL.SUBHEADER)(-1); // Just under the subheader cells
				}

				case Z_INDEX_LAYER.EMPTY_LIST_OVERLAY: {
					return lift(Z_INDEX_LEVEL.SUBHEADER)(-1);
				}

				case Z_INDEX_LAYER.STICKY_GROUP_SCROLL_SHADOW: {
					return lift(Z_INDEX_LEVEL.SCOPE_STICKY)(-1); // Just under the sticky cells
				}

				default:
					return 0;
			}
		}

		const { column, row, level } = props;

		const isTimelineColumn = viewMode === VIEW_MODES.TIMELINE && column === numOfColumns - 1;
		const reversedColumnIndex = numOfColumns - 1 - column;

		if (column === 0) {
			if (row === 0) return lift(Z_INDEX_LEVEL.SCOPE_HEADER)(reversedColumnIndex);

			if (row === 1) return lift(Z_INDEX_LEVEL.SCOPE_SUBHEADER)(reversedColumnIndex);

			if (rowSticky[row]) return lift(Z_INDEX_LEVEL.SCOPE_STICKY)(reversedColumnIndex);

			return lift(Z_INDEX_LEVEL.SCOPE)(reversedColumnIndex);
		}

		if (row === 0) return lift(Z_INDEX_LEVEL.HEADER)(reversedColumnIndex);

		if (row === 1) return lift(Z_INDEX_LEVEL.SUBHEADER)(reversedColumnIndex);

		if (rowSticky[row]) return lift(Z_INDEX_LEVEL.STICKY)(reversedColumnIndex);

		if (isTimelineColumn) return undefined;

		return lift(level ?? Z_INDEX_LEVEL.DEFAULT)(reversedColumnIndex);
	};
};

export const getZIndexPureOld = (
	numOfColumns: number,
	numOfRows: number,
	rowSticky: boolean[],
	viewMode: ViewMode,
): StateProps['zIndex'] => {
	const Z_INDEX_LEVEL = Z_INDEX_LEVEL_OLD;

	const lift = (level: number) => (input: number) => {
		const levelSize = numOfColumns + BUFFER_SIZE * 2;
		const levelsBelow = level * levelSize;
		const offset = input + BUFFER_SIZE;

		return offset + levelsBelow + STATIC_LEVEL_SIZE;
	};

	return (props: Params) => {
		if ('layer' in props) {
			switch (props.layer) {
				case Z_INDEX_LAYER.DEPENDENCY_LINES: {
					return 1;
				}

				case Z_INDEX_LAYER.ISSUE_BARS:
					return 2;

				case Z_INDEX_LAYER.ISSUE_BARS_ARROWS: {
					return 3;
				}

				case Z_INDEX_LAYER.AXIS_STICKY_TIME_UNIT: {
					return lift(Z_INDEX_LEVEL.HEADER)(1); // Above the final header cell
				}

				case Z_INDEX_LAYER.STICKY_HEADER_SCROLL_SHADOW:
				case Z_INDEX_LAYER.HORIZONTAL_SCROLL_BAR:
				case Z_INDEX_LAYER.DEPENDENCY_LINE_DRAG_PREVIEW: {
					return lift(Z_INDEX_LEVEL.SUBHEADER)(-1); // Just under the subheader cells
				}

				case Z_INDEX_LAYER.EMPTY_LIST_OVERLAY: {
					return lift(Z_INDEX_LEVEL.SUBHEADER)(-1);
				}

				case Z_INDEX_LAYER.STICKY_GROUP_SCROLL_SHADOW: {
					return lift(Z_INDEX_LEVEL.STICKY)(-1); // Just under the sticky cells
				}

				default:
					return 0;
			}
		}

		const { column, row, level } = props;

		const isTimelineColumn = viewMode === VIEW_MODES.TIMELINE && column === numOfColumns - 1;
		const reversedColumnIndex = numOfColumns - 1 - column;

		if (row === 0) {
			return lift(Z_INDEX_LEVEL.HEADER)(reversedColumnIndex);
		}

		if (row === 1) {
			return lift(Z_INDEX_LEVEL.SUBHEADER)(reversedColumnIndex);
		}

		if (rowSticky[row]) {
			return lift(Z_INDEX_LEVEL.STICKY)(reversedColumnIndex);
		}

		if (isTimelineColumn) {
			return undefined;
		}

		return lift(level ?? Z_INDEX_LEVEL.DEFAULT)(reversedColumnIndex);
	};
};

export const getZIndexNew = createSelector(
	[getNumberOfColumns, getNumberOfRows, getRowStickiness, getViewMode],
	getZIndexPure,
);

export const getZIndexOld = createSelector(
	[getNumberOfColumns, getNumberOfRows, getRowStickiness, getViewMode],
	getZIndexPureOld,
);

const getZIndex = selectorWithCondition(
	() => fg('plan_timeline_drag_and_drop_field_columns'),
	getZIndexNew,
	getZIndexOld,
);

export const mapStateToProps = createSelector([getZIndex], (zIndex) => ({
	zIndex,
}));
