import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import type { Issues } from '../types';
import { TOGGLE_SELECTED_ISSUES, type Action } from './actions';

const reducer = (state: Issues, action: Action): Issues => {
	switch (action.type) {
		case TOGGLE_SELECTED_ISSUES: {
			const {
				payload: { ids, isSelected, group, toggleAll },
			} = action;

			if (toggleAll === true) {
				return {
					...state,
					selected: isSelected ? new Set<string>(ids) : new Set<string>(),
					lastSelected: {},
				};
			}

			const selected = new Set<string>(state.selected);

			for (const id of ids) {
				if (isSelected) {
					selected.add(id);
				} else {
					selected.delete(id);
				}
			}

			if (isDefined(action.payload.currentIssueId) && ids.length) {
				selected.delete(action.payload.currentIssueId);
			}

			return {
				...state,
				selected,
				lastSelected: {
					id: ids.slice(-1)[0],
					group,
					isSelected,
				},
			};
		}

		default:
			return state;
	}
};

export default reducer;
