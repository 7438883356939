import { getOutgoingLinks } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-links';
import { getDependencySettingsInfo } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/system';
import { getTableItems } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export const mapStateToProps = createStructuredSelector<State, StateProps>({
	items: getTableItems,
	outgoingLinks: getOutgoingLinks,
	dependencySettings: getDependencySettingsInfo,
});
