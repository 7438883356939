import type { SelectOption } from '../../state/domain/select-options/types.tsx';
import type { UserPickerOptionsRequestBody, CustomFieldsTypeForRequest } from './types';

export const urls = {
	addCustomFields: '/rest/jpo/1.0/customfield/add',
	removeCustomField: '/rest/jpo/1.0/customfield/delete',
	getCustomFieldSettableOptions: '/rest/jpo/1.0/customfield/getSettableOptions',
	getCustomFieldVisibleOptions: '/rest/jpo/1.0/customfield/getVisibleOptions',
	getUserPickerCustomFieldOptions: '/rest/teams/1.0/persons/find',
	getDateFields: Object.assign((planId: number) => `/rest/jpo/1.0/plans/${planId}/datefields`, {
		// To use as a key for profiling.
		endpoint: '/rest/jpo/1.0/plans/:id/datefields',
	}),
	updateCustomField: '/rest/jpo/1.0/customfield/update',
	labels: '/rest/jpo/1.0/labels',
} as const;

export const getAddCustomFieldsBody = (
	planId: number,
	planCustomFields: CustomFieldsTypeForRequest[],
) => ({
	planId,
	planCustomFields,
});

export const getUpdateCustomFieldsBody = getAddCustomFieldsBody;

export const getLabelForFieldBody = (
	planId: number,
	customFieldId: number,
	scenarioId?: number,
) => ({
	planId,
	customFieldId: `${customFieldId}`,
	...(scenarioId && { scenarioId }),
});

export const getRemoveCustomFieldBody = (
	planId: number,
	scenarioId: number,
	customFieldId: number,
) => ({
	planId,
	scenarioId,
	customFieldIds: [customFieldId],
});

export type SelectOptionPayload = Array<{ customFieldId: string; options: SelectOption[] }>;

type CustomFieldVisibleOptionsInBulkBody = {
	customFieldIds: number[];
	projectIds: number[];
};

export const getCustomFieldVisibleOptionsBody = (
	customFieldIds: number[],
	projectIds: number[],
): CustomFieldVisibleOptionsInBulkBody => ({
	customFieldIds,
	projectIds,
});

export const getUserPickerCustomFieldOptionsBody = ({
	query = '',
	excludedIds = [],
	maxResults = 50,
}: UserPickerOptionsRequestBody) => ({
	query,
	excludedIds,
	maxResults,
});
