import React, { type ComponentType } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { useTriggerIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { connect } from '@atlassian/jira-react-redux';
import { useAdvancedRoadmapsSummaryDataResource } from '@atlassian/jira-router-resources-advanced-roadmaps-summary';
import mapDispatchToProps from './command';
import mapStateToProps from './query';
import type {
	AdditionalProps,
	DispatchProps,
	OwnProps,
	PropsWithoutIntlWithoutDefaults,
	StateProps,
} from './types';
import View from './view';
import ViewOld from './view-old';

const withGicModalOpen =
	<T extends {}>(WrappedComponent: ComponentType<T & AdditionalProps>) =>
	(props: T) => {
		const [{ isModalOpen }] = useTriggerIssueCreateModal();

		return <WrappedComponent {...props} isGicModalOpen={isModalOpen} />;
	};

const HooksInjector = (props: PropsWithoutIntlWithoutDefaults) => {
	const { clear } = useAdvancedRoadmapsSummaryDataResource();

	if (fg('refactor_update_jira_to_functional_component')) {
		return <View {...props} clearSummaryPageCache={clear} />;
	}
	return <ViewOld {...props} clearSummaryPageCache={clear} />;
};

export default withGicModalOpen(
	connect<StateProps, DispatchProps, OwnProps, State>(
		mapStateToProps,
		mapDispatchToProps,
	)(HooksInjector),
);
