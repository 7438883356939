import type { UserIsOnboarded } from './types';

export const UPDATE = 'state.ui.eap.UPDATE' as const;

export type UpdateEAPAction = {
	type: typeof UPDATE;
	payload: UserIsOnboarded;
};

export const updateUserIsOnboarded = (payload: UserIsOnboarded): UpdateEAPAction => ({
	type: UPDATE,
	payload,
});
