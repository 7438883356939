import React from 'react';
import { FormattedMessage } from '@atlassian/jira-intl';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from '../styles.module.css';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
	<div className={styles.noMatchFoundText}>
		<FormattedMessage {...commonMessages.noMatchFoundText} />
	</div>
);
