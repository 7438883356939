import { createMigrate, incremental } from '../common/migrate';
import type { WarningSettingsStateV0, WarningSettingsStateV1 } from './types';

export default createMigrate(
	...incremental(
		{
			V0: function fromV0ToV1(prevState: WarningSettingsStateV0): WarningSettingsStateV1 {
				return {
					...prevState,
					disabledWarnings: [],
				};
			},
		},
		{ prefix: 'warningSettings', from: 'V0' },
	),
);
