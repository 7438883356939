import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	retryButton: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.section-error-message.retry-button',
		defaultMessage: 'Retry',
		description:
			'Retry button for section error messages when Atlassian Intelligence fails to respond.',
	},
	genericError: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.section-error-message.generic-error',
		defaultMessage:
			'Atlassian Intelligence isn’t responding. Try again later or <a>check the status of Atlassian Intelligence</a>.',
		description: 'Generic error message for when Atlassian Intelligence fails to respond.',
	},
	unethicalContentError: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.section-error-message.unethical-content-error',
		defaultMessage: 'We can’t make suggestions about that topic.',
		description: 'Unethical error message for when user tries to generate unethical content',
	},
	noFurtherSuggestionsHeader: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.section-error-message.no-further-suggestions-header',
		defaultMessage: 'No more suggestions',
		description:
			'When use AI Work Breakdown, the existing child tickets were sufficient to cover all the requirements of the current ticket. No further suggestions are needed.',
	},
	noFurtherSuggestionsBody: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.section-error-message.no-further-suggestions-body',
		defaultMessage: 'We don’t have any other suggestions to make for this parent issue.',
		description:
			'When use AI Work Breakdown, the existing child tickets were sufficient to cover all the requirements of the current ticket. No further suggestions are needed.',
	},
	notEnoughInfoHeader: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.section-error-message.not-enough-info-header',
		defaultMessage: 'We can’t make suggestions for this issue',
		description:
			'When use AI Work Breakdown, there is not enough information to generate suggestions',
	},
	notEnoughInfoBody: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.section-error-message.not-enough-info-body',
		defaultMessage:
			'Try rewriting the issue description or any existing child issues, then try again.',
		description:
			'When use AI Work Breakdown, there is not enough information to generate suggestions',
	},
	refineNotEnoughInfoHeader: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.section-error-message.refine-not-enough-info-header',
		defaultMessage: 'We can’t use that to refine these suggestions',
		description:
			'When use AI Work Breakdown, there is not enough information to generate suggestions',
	},
	refineNotEnoughInfoBody: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.section-error-message.refine-not-enough-info-body',
		defaultMessage: 'Try writing it a different way or choosing another topic.',
		description:
			'When use AI Work Breakdown, there is not enough information to generate suggestions',
	},
	labelRetry: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.section-error-message.label-retry',
		defaultMessage: 'Retry',
		description: 'Retry button for refetch suggested issues from Atlassian Intelligence.',
	},
	labelCancel: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.section-error-message.label-cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel\'s "Cancel" button label.',
	},
	labelEsc: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.section-error-message.label-esc',
		defaultMessage: 'Esc',
		description: 'Esc\'s "Esc" button label.',
	},
	footerTextIsWorking: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.section-error-message.footer-text-is-working',
		defaultMessage: 'Atlassian Intelligence is working...',
		description: 'Footer text in the Issue Breakdown when generating child issues.',
	},
});
