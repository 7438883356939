import React, { useCallback, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import InlineMessage from '@atlaskit/inline-message';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useTeamCentralBaseUrl } from '@atlassian/jira-tenant-context-controller/src/components/team-central-url/index.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import messages from './messages';

const GoalHeaderPermissionCheck = ({ hasAtlasPermissions }: { hasAtlasPermissions: boolean }) => {
	const { formatMessage } = useIntl();
	const { push } = useRouterActions();
	const atlasURL = `${useTeamCentralBaseUrl()}?cloudId=${useCloudId()}`;

	const onClickStopPropagation = useCallback((e: MouseEvent<HTMLElement>): void => {
		e.stopPropagation();
	}, []);

	const onClickLink = useCallback(
		(_event: MouseEvent<HTMLElement>, analyticEvent: UIAnalyticsEvent): void => {
			fireUIAnalytics(analyticEvent, 'jpo-request-atlas');
			push(atlasURL);
		},
		[push, atlasURL],
	);

	if (!hasAtlasPermissions) {
		return (
			<Box xcss={boxStyles} onClick={onClickStopPropagation}>
				<InlineMessage appearance="info">
					<Button appearance="link" spacing="none" onClick={onClickLink}>
						{formatMessage(messages.permissionCheckLink)}
					</Button>
				</InlineMessage>
			</Box>
		);
	}
	return null;
};

const boxStyles = xcss({
	marginLeft: 'space.100',
});

export default GoalHeaderPermissionCheck;
