import { isSameDay } from 'date-fns';
import * as R from 'ramda';
import type { Change } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira/types';
import { groupBy, mapValues } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { getExpandedChanges } from '../utils';

export const filterChangesByUserId = (changes: Change[], myUserId: string | null): Change[] => {
	let filteredChanges: Change[] = [];
	if (myUserId) {
		filteredChanges = changes.filter(
			(change: Change) => change.lastModified && myUserId === change.lastModified.personId,
		);
	}
	return filteredChanges;
};

export const filterChangesByToday = (changes: Change[]) => {
	const now = new Date();
	return changes.filter(
		(change: Change) => change.lastModified && isSameDay(now, new Date(change.lastModified?.date)),
	);
};

export const filterChangesBySelectedOnTimeline = (changes: Change[], selection: Set<string>) =>
	changes.filter((change: Change) => selection.has(change.id));

const getChangeIdsByCategory = (changes: Change[]) => {
	const changesByCategory = groupBy(R.prop('category'), getExpandedChanges(changes));
	return mapValues(R.prop('id'), changesByCategory);
};

export const getMyChanges = (changes: Change[], myUserId: string | null) =>
	getChangeIdsByCategory(filterChangesByUserId(changes, myUserId));

export const getTodaysChanges = (changes: Change[]) =>
	getChangeIdsByCategory(filterChangesByToday(changes));

export const getTimelineChanges = (changes: Change[], selection: Set<string>) =>
	getChangeIdsByCategory(filterChangesBySelectedOnTimeline(changes, selection));

export const getAllChanges = (changes: Change[]) => getChangeIdsByCategory(changes);
