import { sortAsLowerCaseByNameProp } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/composed';
import type { CrossProjectVersion } from '../cross-project-versions/types';
import {
	RESET,
	type ResetAction,
	ADD,
	type AddAction,
	REMOVE,
	type RemoveAction,
	ADD_VERSION_IN_CROSS_PROJECT_VERSION,
	type AddVersionInCrossProjectVersionAction,
	REMOVE_VERSION_FROM_CROSS_PROJECT_VERSION,
	type RemoveVersionFromCrossProjectVersionAction,
} from './actions';

const initialState: CrossProjectVersion[] = [];

type Action =
	| ResetAction
	| AddAction
	| RemoveAction
	| AddVersionInCrossProjectVersionAction
	| RemoveVersionFromCrossProjectVersionAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: CrossProjectVersion[] = initialState,
	action: Action,
): CrossProjectVersion[] => {
	switch (action.type) {
		case RESET:
			return sortAsLowerCaseByNameProp([...action.payload]);
		case ADD: {
			return [...state, action.payload];
		}
		case REMOVE: {
			const removedId = action.versionId;
			return state.filter(({ id }) => id !== removedId);
		}
		case ADD_VERSION_IN_CROSS_PROJECT_VERSION: {
			const { crossProjectVersionId, versionId } = action.payload;
			// Array spreading is done to make flow understand the type.
			return [
				...state.map((crossProjectVersion: CrossProjectVersion) => {
					const { id, versions } = crossProjectVersion;
					if (id === crossProjectVersionId) {
						return {
							...crossProjectVersion,
							versions: [...versions, versionId],
						};
					}
					return crossProjectVersion;
				}),
			];
		}
		case REMOVE_VERSION_FROM_CROSS_PROJECT_VERSION: {
			const { crossProjectVersionId, versionId } = action.payload;
			// Array spreading is done to make flow understand the type.
			return [
				...state.map((crossProjectVersion: CrossProjectVersion) => {
					const { id, versions } = crossProjectVersion;
					if (id === crossProjectVersionId) {
						return {
							...crossProjectVersion,
							versions: versions.filter((vid) => vid !== versionId),
						};
					}
					return crossProjectVersion;
				}),
			];
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
