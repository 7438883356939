import type { ExternalSprint } from './types';

export const ADD = 'state.domain.external-sprints.ADD' as const;
export const RESET = 'state.domain.external-sprints.RESET' as const;

export type AddAction = {
	type: typeof ADD;
	payload: ExternalSprint[];
};

export type ResetAction = {
	type: typeof RESET;
	payload: ExternalSprint[];
};

export const add = (payload: ExternalSprint[]): AddAction => ({
	type: ADD,
	payload,
});

export const reset = (payload: ExternalSprint[]): ResetAction => ({
	type: RESET,
	payload,
});
