import type { DisabledSprint } from './types';

export const ADD = 'state.domain.disabled-sprints.ADD' as const;
export const RESET = 'state.domain.disabled-sprints.RESET' as const;

export type AddAction = {
	type: typeof ADD;
	payload: DisabledSprint[];
};

export type ResetAction = {
	type: typeof RESET;
	payload: DisabledSprint[];
};

export const add = (payload: DisabledSprint[]): AddAction => ({
	type: ADD,
	payload,
});

export const reset = (payload: DisabledSprint[]): ResetAction => ({
	type: RESET,
	payload,
});
