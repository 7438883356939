import React, { memo, type FC } from 'react';
import { useViewport } from '../../../controllers/container';
import { useColWidth } from '../../../controllers/grid';

interface Props {
	count: number;
}

/** Renders the <colgroup /> including all the <col />. */
export const Colgroup: FC<Props> = memo(({ count }) => (
	<colgroup>
		{Array(count)
			.fill(undefined)
			.map((_, index) => (
				<Col key={index} index={index} />
			))}
	</colgroup>
));

/** Renders the <col /> which configure the DOM column widths. */
export const Col = ({ index }: { index: number }) => {
	const [viewport] = useViewport();
	const [width] = useColWidth({ column: index, viewportWidth: viewport.width });

	return <col width={width} />;
};
