import { getCustomFields } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields';
import { getDateConfiguration } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan';
import { getAllSprints } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints';
import { getTeams } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams';
import { getVersions } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export const mapStateToProps = createStructuredSelector<State, StateProps>({
	customFields: getCustomFields,
	dateConfiguration: getDateConfiguration,
	teams: getTeams,
	sprints: getAllSprints,
	versions: getVersions,
});

export default mapStateToProps;
