import { isExportMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app';
import {
	getGoalSearchesInProgress,
	getLazyGoalsByARI,
	getAllLazyGoals,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-goals';
import { getHasAtlasPermissions } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/system';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import type { StateProps } from './types';

const mapStateToProps = (state: State): StateProps => ({
	isExportMode: isExportMode(state),
	hasAtlasPermissions: getHasAtlasPermissions(state),
	lazyGoalsByARI: getLazyGoalsByARI(state),
	searchesInProgress: getGoalSearchesInProgress(state),
	allLazyGoals: getAllLazyGoals(state),
});

export default mapStateToProps;
