import type { Effect } from 'redux-saga';
import * as R from 'ramda';
import { put, select } from 'redux-saga/effects';
import { updateIssueReporterMap } from '../../state/domain/reporters/actions';
import type { Person, JiraUser } from '../../state/domain/reporters/types';
import type { State } from '../../state/types';
import { genericError } from '../errors';

export function* populateReportersFromProfiles(
	reporterProfiles: Record<string, JiraUser>, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	try {
		const {
			domain: { issues },
		}: State = yield select(R.identity);

		// Map of account id to person
		// This construct a map of reporter profiles mapping
		// to make it as ID : Person
		const allPersonsMap: Record<string, Person> = {};

		for (const reporter of Object.values(reporterProfiles)) {
			allPersonsMap[reporter.accountId] = {
				personId: reporter.accountId,
				jiraUser: reporter,
			};
		}

		// loop through each issue and find its reporter person
		// then construct another map to store
		// issue ID : Person
		const issueReporterMap: Record<string, Person> = {};
		issues.forEach((issue) => {
			if (issue.reporter) {
				const reporterPerson = allPersonsMap[issue.reporter];

				if (reporterPerson) {
					issueReporterMap[issue.id] = reporterPerson;
				}
			}
		});
		yield put(updateIssueReporterMap(issueReporterMap));
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		yield put(genericError({ message: e.message, stackTrace: e.stack }));
	}
}
