import type { Effect } from 'redux-saga';
import * as R from 'ramda';
import { call, put, select } from 'redux-saga/effects';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { ENTITY } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import { isScenarioIssue } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/utils/issue';
import { getIssueLinkChangesData } from '../../query/issue-links';
import { getIssueChangesMetaData } from '../../query/issues';
import type { IssueMap } from '../../query/raw-issues';
import { getIssueMapById } from '../../query/raw-issues/index.tsx';
import type { PlannedCapacityChange } from '../../query/sprints/types';
import { update as updateSequence } from '../../state/domain/sequence/actions';
import { incrementNumberOfCommittedChanges } from '../../state/domain/update-jira/commit/actions';
import { reset as resetHiddenIssues } from '../../state/domain/update-jira/hidden-issues/actions';
import { add as addCommitWarning } from '../../state/domain/update-jira/warnings/actions';
import type { State } from '../../state/types';
import type { SelectedChanges } from '../../state/ui/top/title-bar/update-jira/types.tsx';
import { POST } from '../api';
import { inspectForCommitWarnings } from '../commit/warnings';
import * as http from '../http';
import { bulkCommitBody, bulkCommitUrl } from './api';
import {
	type BulkCommitRequestEntity,
	type BulkCommitResponse,
	BulkCommitEntityType,
} from './types';

export const INTERRUPT = 'command.update-jira.INTERRUPT';

export const interrupt = () => ({ type: INTERRUPT });

export function* executeBulkCommitRequest(
	bulkCommitIssues: BulkCommitRequestEntity[],
	notifyWatchers?: boolean, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, BulkCommitResponse | undefined, any> {
	const {
		domain: {
			plan: { id: planId, currentScenarioId },
			sequence,
		},
	}: State = yield select(R.identity);

	const body = bulkCommitBody(
		{ id: planId, currentScenarioId },
		sequence,
		bulkCommitIssues,
		notifyWatchers,
	);

	const response = yield* http.json<BulkCommitResponse>({
		url: bulkCommitUrl,
		profile: bulkCommitUrl,
		method: POST,
		body,
	});

	if (!response.ok) {
		yield put.resolve(interrupt());
		return undefined;
	}
	const responseBody = response.data;

	yield put(updateSequence(responseBody.sequenceChanges));

	return responseBody;
}

// issue and issue link commits are both selected based on the issue in the UI,
// so they need to be separated out before sending to the batch commit endpoint
export function* extractIssueAndIssueLinkChanges(selectedChanges: string[]): Generator<
	Effect,
	{
		issueAndIssueLinkCommitEntities: BulkCommitRequestEntity[];
		issueLinkOnlyIssueChangesCount: number;
		issueLinkToIssueMap: Map<string, string>;
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	any
> {
	yield put(resetHiddenIssues([]));
	const issueChangesMetaData = yield select(getIssueChangesMetaData);

	const issueAndIssueLinkCommitEntities: BulkCommitRequestEntity[] = [];
	let issueLinkOnlyIssueChangesCount = 0; // used to increment count
	const issueIdsBeingCommitted = new Set<string>();

	const issuesById: IssueMap = yield select(getIssueMapById);

	for (const id of selectedChanges) {
		const parentIdOfThisIssue: string | undefined = issuesById[id]?.parent;
		// Don't commit issue when parent issue has not been created in Jira (Still a scenario), as cannot create parent link
		if (
			isScenarioIssue(parentIdOfThisIssue) &&
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			!issueIdsBeingCommitted.has(parentIdOfThisIssue!)
		) {
			const uncommittedWarnings = yield call(inspectForCommitWarnings, undefined, {
				error: 'UNCOMMITTED_PARENT',
				errorMessages: [],
			});
			yield put(
				addCommitWarning({
					category: ENTITY.ISSUE,
					itemId: id,
					warnings: uncommittedWarnings,
				}),
			);
			// We need to increment on fail since warning messages minus one from the committed count.
			yield put(incrementNumberOfCommittedChanges());
			yield put.resolve(interrupt());
			break;
		}

		if (issueChangesMetaData[id]) {
			issueAndIssueLinkCommitEntities.push({
				entityType: BulkCommitEntityType.ISSUE,
				itemKey: id,
			});
			issueIdsBeingCommitted.add(id);
		} else {
			// If issue link change is the only change associated with this issue
			// then issue itself must not be committed, but we still keep track of it to increment the correct number of changes
			issueLinkOnlyIssueChangesCount += 1;
		}
	}
	// It is necessary to commit issue links after actual issue changes to ensure
	// that created issues are committed before creating their links.
	const issueLinkChanges = yield select(getIssueLinkChangesData);
	const issueLinkChangesSet = new Set<string>();
	const issueLinkToIssueMap = new Map<string, string>(); // used for adding failures to issue if issueLink fails.

	for (const issueId of selectedChanges) {
		for (const { itemKey } of issueLinkChanges[issueId] ?? []) {
			if (!issueLinkChangesSet.has(itemKey)) {
				issueAndIssueLinkCommitEntities.push({
					entityType: BulkCommitEntityType.ISSUE_LINK,
					itemKey,
				});
				issueLinkChangesSet.add(itemKey);
				issueLinkToIssueMap.set(itemKey, issueId);
			}
		}
	}
	return { issueAndIssueLinkCommitEntities, issueLinkOnlyIssueChangesCount, issueLinkToIssueMap };
}

// Takes the selected changes and converts them into the correct format for the bulk commit endpoint
// Note: the order of entities is there for a reason, generally to ensure entities referenced by others need to be created first.
// - Releases must be committed after Cross Project Releases as releases contain links to CPRs which cannot be committed if the CPR is a scenario entity.
// - Resources must be committed after Teams, since resources represent assignments of users to Teams
// - Issue Links must be committed after Issues, since Issue Links are stored on the issue.
export function* createBulkCommitRequestEntityList(
	selectedChanges: SelectedChanges,
	selectedIssues: string[],
	allPlannedCapacityChanges: PlannedCapacityChange[],
): Generator<
	Effect,
	{
		bulkCommitEntities: BulkCommitRequestEntity[];
		issueLinkOnlyIssueChangesCount: number;
		issueLinkToIssueMap: Map<string, string>;
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	any
> {
	const bulkCommitEntities: BulkCommitRequestEntity[] = [];

	selectedChanges[ENTITY.CROSS_PROJECT_RELEASE]?.forEach((id) => {
		bulkCommitEntities.push({
			entityType: BulkCommitEntityType.X_PROJECT_VERSION,
			itemKey: id,
		});
	});
	selectedChanges[ENTITY.RELEASE]?.forEach((id) => {
		bulkCommitEntities.push({ entityType: BulkCommitEntityType.VERSION, itemKey: id });
	});
	selectedChanges[ENTITY.TEAM]?.forEach((id) => {
		bulkCommitEntities.push({ entityType: BulkCommitEntityType.TEAM, itemKey: id });
	});
	selectedChanges[ENTITY.RESOURCE]?.forEach((id) => {
		bulkCommitEntities.push({ entityType: BulkCommitEntityType.RESOURCE, itemKey: id });
	});

	// planned capacity changes chosen by iteration, but the itemKey represents the individual team specific capacity change
	selectedChanges[ENTITY.SPRINT]?.forEach((id) => {
		allPlannedCapacityChanges
			.filter(
				(capacityChange) =>
					capacityChange.iterationId === id && isDefined(capacityChange.values.itemKey),
			)
			.forEach((capacityChange) =>
				bulkCommitEntities.push({
					entityType: BulkCommitEntityType.PLANNED_CAPACITY,
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					itemKey: capacityChange.values.itemKey!,
				}),
			);
	});

	const { issueAndIssueLinkCommitEntities, issueLinkOnlyIssueChangesCount, issueLinkToIssueMap } =
		yield call(extractIssueAndIssueLinkChanges, selectedIssues);
	bulkCommitEntities.push(...issueAndIssueLinkCommitEntities);

	return { bulkCommitEntities, issueLinkOnlyIssueChangesCount, issueLinkToIssueMap };
}
