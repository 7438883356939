import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import withViewExperienceTracker from '@atlassian/jira-portfolio-3-portfolio/src/common/view/experience-tracking/with-view-experience-tracker/index.tsx';
import { connect } from '@atlassian/jira-react-redux';
import mapDispatchToProps from './command';
import mapStateToProps from './query';
import type { StateProps, DispatchProps, OwnProps } from './types';
import View from './view';

export default withViewExperienceTracker<OwnProps>(
	connect<StateProps, DispatchProps, OwnProps, State>(mapStateToProps, mapDispatchToProps)(View),
	'portfolio-3.simple-plans.sprints.edit-team-modal',
);
