import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { UNDEFINED_GROUP } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope';
import type { GroupOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/types.tsx';
import { getCustomFieldIdFromCustomFieldGrouping } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/custom-fields';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import DialogContent from '../dialog-content/view.tsx';
import messages from './messages';
import type { Props } from './types';
import { getGroupOptionsFromCustomField } from './utils';

export default function CustomFieldOptions({
	grouping,
	groups,
	hierarchyItem,
	onGroupClick,
	selectOptions,
}: Props) {
	const { formatMessage } = useIntl();

	const customFieldId = parseInt(getCustomFieldIdFromCustomFieldGrouping(grouping), 10);

	const groupOptions = getGroupOptionsFromCustomField(
		grouping,
		groups,
		selectOptions,
		customFieldId,
	);

	const unassigned: GroupOption = {
		group: UNDEFINED_GROUP,
		groupCombination: { [grouping]: null },
		grouping,
		groupName: formatMessage(commonMessages.unassigned),
		isExpanded: true,
		startInlineCreateInEmptyGroup: !groups.some(
			(group) =>
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				group.groupCombination?.[grouping as keyof typeof group.groupCombination] === null,
		),
	};

	const groupOptionsWithUnassigned = [...groupOptions, unassigned];

	return (
		<DialogContent
			groupOptions={groupOptionsWithUnassigned}
			hierarchyItem={hierarchyItem}
			onGroupClick={onGroupClick}
			searchPlaceholder={formatMessage(messages.customFieldSearchPlaceholder)}
		/>
	);
}
