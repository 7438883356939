import type { Dispatch } from 'redux';
import { startInlineCreate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/inline-create';
import { setIssueBeingEdited } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-modal/actions';
import type { StartInlineCreateActionPayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/scope/inline-create/actions.tsx';
import type { Props } from './types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (dispatch: Dispatch): Partial<Props> => ({
	startInlineCreate: (payload: StartInlineCreateActionPayload) => {
		dispatch(startInlineCreate(payload));
	},
	setIssueBeingEdited: (issueId) => {
		dispatch(setIssueBeingEdited(issueId));
	},
});
