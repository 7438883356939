import * as R from 'ramda';
import { formatDateUTC } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import type { IssueValues } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import {
	CustomFieldTypes,
	DefaultDateFormat,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import DialogHelper from './dialog-helper';
import { getJquery, getUnderscore, getJira } from './globals';
import messages from './messages';
import type { LegacyDialogParams } from './types';

const createCreateIssueModel = ({
	projectId,
	issueTypeId,
	parentIssueId,
}: {
	projectId: number;
	issueTypeId: number;
	parentIssueId?: string;
}) => {
	const $ = getJquery();
	const RequiredFieldsCreateIssueModel = getJira().Forms.CreateIssueModel.extend({
		getActiveFieldIds() {
			const deferred = new $.Deferred();

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const activeFieldsIds: Array<any> = [];

			this.getRequiredFields().done((requiredFields: Array<{ id: string }>) => {
				requiredFields.forEach((requiredField) => {
					if (!activeFieldsIds.includes(requiredField.id)) {
						activeFieldsIds.push(requiredField.id);
					}
				});
				deferred.resolve(activeFieldsIds);
			});

			return deferred;
		},

		getUseConfigurableForm() {
			return $.when(true);
		},

		// eslint-disable-next-line @typescript-eslint/no-empty-function
		setUseConfigurableForm() {},
	});

	return new RequiredFieldsCreateIssueModel({
		projectId,
		issueType: issueTypeId,
		parentIssueId,
	});
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const disableSelect = ($el: any) => {
	const $ = getJquery();
	if ($el.length > 0) {
		$el.addClass('aui-disabled');

		$('input', $el).prop('disabled', true);
		// The select also has a span which contains the dropdown symbol. This also needs to separately be disabled.
		$('span', $el).css('pointer-events', 'none');

		if ($('.aui-disabled-blanket').length === 0) {
			$el.append('<div class="aui-disabled-blanket" style="height: 30px;"></div>');
		}
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isCommittedFixVersion = (versionId: any) => R.is(Number, +versionId);

const formatEstimates = ({
	seconds,
	hoursPerDay,
	daysPerWeek,
}: {
	seconds?: number | null;
	hoursPerDay: number;
	daysPerWeek: number;
}) => {
	if (!seconds) {
		return undefined;
	}

	const durations = {
		h: 60 * 60,
		m: 60,
		s: 1,
	};

	const format = [
		{
			unit: 'w',
			seconds: durations.h * hoursPerDay * daysPerWeek,
		},
		{
			unit: 'd',
			seconds: durations.h * hoursPerDay,
		},
		{
			unit: 'h',
			seconds: durations.h,
		},
		{
			unit: 'm',
			seconds: durations.m,
		},
		{
			unit: 's',
			seconds: durations.s,
		},
	];

	let remainingSeconds = seconds;
	let retVal = '';

	format.forEach((entry) => {
		const x = Math.floor(remainingSeconds / entry.seconds);

		if (x > 0) {
			retVal += `${x}${entry.unit} `;
			remainingSeconds -= x * entry.seconds;
		}
	});

	return retVal.trim();
};

const timestampToFormattedDate = (timestampValue: string | number) =>
	formatDateUTC(timestampValue, DefaultDateFormat);

const formatCustomField = (customField: {
	typeKey?: string;
	value?: string | number | string[];
}) => {
	if (customField.typeKey === CustomFieldTypes.DatePicker) {
		// Date picker values are always number
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return timestampToFormattedDate(customField.value as number);
	}
	return customField.value;
};

const isCustomFieldKey = (
	key: string,
): key is
	| `customFieldValueAdded-${number}`
	| `customFieldValueRemoved-${number}`
	| `customField-${number}` => key.startsWith('customField');

export const getPrefilledFieldsForLegacyDialog = ({
	issue,
	agileConfig,
	isIssueSimplified,
}: LegacyDialogParams) => {
	const { epicLabelField, storyPointsField, storyPointEstimateField } = agileConfig;

	const nonScenarioFixVersions = R.filter(
		isCommittedFixVersion,
		R.defaultTo([], issue.values.fixVersions),
	);

	// prefill data to show in the jira issue dialog
	let prefilledFields: {
		summary: string;
		description: string;
		fixVersions: string[];
		issuetype: number;
		[key: `customfield_${string}`]: string | number | string[] | undefined;
	} & Partial<Omit<IssueValues, 'summary' | 'description' | 'fixVersions' | 'issuetype'>> = {
		summary: issue.values.summary,
		description: issue.values.description || '',
		fixVersions: nonScenarioFixVersions,
		issuetype: issue.values.type,
	};

	if (issue.values.dueDate) {
		// prefill expects lower case duedate
		prefilledFields = R.assoc(
			'duedate',
			timestampToFormattedDate(issue.values.dueDate),
			prefilledFields,
		);
	}

	// scan through our issue data to send any fields that have values, so the jira issue dialog will be pre-populated as much as possible

	/* eslint-disable @typescript-eslint/consistent-type-assertions */
	(Object.entries(issue.values) as Array<[keyof IssueValues, IssueValues[keyof IssueValues]]>)
		/* eslint-enable @typescript-eslint/consistent-type-assertions */
		.forEach(([key, value]) => {
			if (key && value) {
				if (isCustomFieldKey(key)) {
					const customField = issue.values[key];
					prefilledFields[`customfield_${customField.id}`] = formatCustomField(customField);
				} else if (!prefilledFields[key]) {
					// not sure why this is expected to be never =(
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					prefilledFields[key] = issue.values[key] as never;
				}
			}
		});

	if (epicLabelField) {
		// not sure why this is expected to be never =(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		prefilledFields[epicLabelField as keyof typeof prefilledFields] = issue.values.summary as never;
	}

	if (issue.values.storyPoints) {
		if (isIssueSimplified && storyPointsField) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			prefilledFields[storyPointsField as keyof typeof prefilledFields] = issue.values
				.storyPoints as never;
		} else if (!isIssueSimplified && storyPointEstimateField) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			prefilledFields[storyPointEstimateField as keyof typeof prefilledFields] = issue.values
				.storyPoints as never;
		}
	}

	return prefilledFields;
};

const create = (params: LegacyDialogParams) => {
	const { issue, issueTypesById, jiraConfig } = params;
	const $ = getJquery();
	const _ = getUnderscore();

	const deferred = new $.Deferred();

	const isSubTask =
		issueTypesById[issue.values.type] && issueTypesById[issue.values.type].subTask === true;

	const isSubTaskCreationPossible = () => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const isCommittedParentId = R.is(Number, +issue.values.parent!);
		return !isSubTask || isCommittedParentId;
	};

	if (!isSubTaskCreationPossible) {
		deferred.reject({ type: 'client-sub-task-creation-impossible' });
		return deferred;
	}

	const prefilledFields = getPrefilledFieldsForLegacyDialog(params);

	const formattedEstimates = formatEstimates({
		seconds: issue.values.timeEstimate,
		hoursPerDay: jiraConfig.hoursPerDay,
		daysPerWeek: jiraConfig.daysPerWeek,
	});

	const parentId = isSubTask ? issue.values.parent : undefined;

	const createIssueModel = createCreateIssueModel({
		projectId: issue.values.project,
		issueTypeId: issue.values.type,
		parentIssueId: parentId,
	});

	const title = params.formatMessage(messages.requiredFieldsDialogTitle);

	DialogHelper.showDialog({
		deferred,

		model: createIssueModel,
		prefilledFields,

		title,

		hideConfigureFields: true,

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onRenderCallback: ($dialog: any) => {
			if (formattedEstimates) {
				$('#timetracking_originalestimate', $dialog).val(formattedEstimates);
				$('#timetracking_remainingestimate', $dialog).val(formattedEstimates);
				$('#timetracking', $dialog).val(formattedEstimates);
			}

			const el = $dialog.get(0);
			params.onRenderCallback(el);
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onContentRefreshedCallback: (quickFormElement: any) => {
			_.defer(() => {
				disableSelect($('#project-single-select', quickFormElement));
				disableSelect($('#issuetype-single-select', quickFormElement));
			});
		},
	});

	return deferred;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default { create };
