import {
	TOGGLE_SHOW_FULL_HIERARCHY,
	RESET_FILTER_OPTIONS,
	type ToggleShowFullHierarchyAction,
	type ResetFilterOptionsAction,
} from './actions';
import type { FilterOptionsState } from './types';

export const initialState: FilterOptionsState = {
	includeAncestors: true,
	includeDescendants: true,
};

type Action = ToggleShowFullHierarchyAction | ResetFilterOptionsAction;

const reducer = (state: FilterOptionsState = initialState, action: Action) => {
	switch (action.type) {
		case RESET_FILTER_OPTIONS:
			return { ...action.payload };
		case TOGGLE_SHOW_FULL_HIERARCHY:
			return { ...state, includeDescendants: !state.includeDescendants };
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};

export default reducer;
