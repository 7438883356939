import { isOptimizedMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app';
import { getIssueStatusById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-statuses';
import { getSyncStartEnabled } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { OwnProps, StateProps } from './types';

export default createStructuredSelector<State, OwnProps, StateProps>({
	isOptimizedMode,
	issueStatusById: getIssueStatusById,
	syncStartEnabled: getSyncStartEnabled,
});
