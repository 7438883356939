import { useIntl } from '@atlassian/jira-intl';
import { UNDEFINED_GROUP } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope';
import type { GroupCombination } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { SelectOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/select-options/types';
import { getCustomFieldIdFromCustomFieldGrouping } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/custom-fields';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import { type Option, UNGROUPED } from '../types';
import { optionSortComparator } from '../utils';
import messages from './messages';
import type { Props } from './types';

const CustomFieldOptionsProvider = ({ grouping, selectOptions, children }: Props) => {
	const { formatMessage } = useIntl();

	const customFieldId = parseInt(getCustomFieldIdFromCustomFieldGrouping(grouping), 10);

	const createOptionFromCustomField = (selectOption: SelectOption): Option<GroupCombination> => ({
		id: `${grouping}-${selectOption.id}`,
		name: selectOption.value,
		value: { [grouping]: selectOption.id },
	});

	const getOptionsFromCustomField = (): Option<GroupCombination>[] => {
		const filteredOptions = selectOptions
			.filter((selectOption) => selectOption.fieldId === customFieldId)
			.map((option) => ({ ...option, id: String(option.id) }));

		const options = filteredOptions.map((option) => createOptionFromCustomField(option));

		return options.sort(optionSortComparator);
	};

	const unassigned: Option<GroupCombination> = {
		id: UNDEFINED_GROUP,
		name: formatMessage(commonMessages.unassigned),
		value: { [grouping]: null },
	};

	const optionsWithUnassigned = [...getOptionsFromCustomField(), unassigned];

	return children({
		options: {
			type: UNGROUPED,
			options: optionsWithUnassigned,
		},
		menuTitle: formatMessage(messages.customFieldMenuTitle),
		searchPlaceholder: formatMessage(messages.customFieldSearchPlaceholder),
	});
};

export default CustomFieldOptionsProvider;
