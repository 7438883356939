import { getIssueTypesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types';
import { getProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects';
import { getIssueMapById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues';
import { searchForIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { OwnProps, StateProps } from './types';

export default createStructuredSelector<State, OwnProps, StateProps>({
	issuesById: getIssueMapById,
	issueSearchResults: searchForIssues,
	issueTypesById: getIssueTypesById,
	projectsById: getProjectsById,
});
