import { ADD, RESET, type AddAction, type ResetAction } from './actions';
import type { IssuePriorities } from './types';

const initialState: IssuePriorities = {};

type Action = ResetAction | AddAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: IssuePriorities = initialState, action: Action) => {
	switch (action.type) {
		case RESET:
			return action.payload;
		case ADD:
			return {
				...state,
				...action.payload,
			};
		default: {
			return state;
		}
	}
};
