import { memo } from 'react';
import isEqual from 'lodash/isEqual';
import type { RowConfig, ColumnConfig, RowRenderer } from '../../../common/types';

type RowProps = {
	renderRow: RowRenderer;
	columnConfigs: ColumnConfig[];
};

type HeadProps = { head: [RowConfig, number][] } & RowProps;
type BodyProps = {
	body: [RowConfig, number][];
	liteRowIndexes: number[];
	visibleCols: Record<string, boolean>;
} & RowProps;

/** The group of rows within the table head, driven by render props
 *  Uses deep memoize since the value equality is far more stable on average than the body
 */
export const Head = memo(
	({ head, renderRow, columnConfigs }: HeadProps) =>
		head.map(([row, rowIndex]) => renderRow(row, columnConfigs, rowIndex, undefined)),
	isEqual,
);

/** The group of rows within the table body, driven by render props */
export const Body = memo(
	({ body, renderRow, columnConfigs, liteRowIndexes, visibleCols }: BodyProps) =>
		body.map(([row, rowIndex]: [RowConfig, number]) =>
			renderRow(
				row,
				columnConfigs,
				rowIndex,
				liteRowIndexes.includes(rowIndex)
					? {
							visibleCols,
						}
					: undefined,
			),
		),
);
