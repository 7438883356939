import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { connect } from '@atlassian/jira-react-redux';
import { mapStateToProps } from './query';
import type { StateProps, OwnProps } from './types';
import { ColumnDropIndicator as View } from './view';

export const ColumnDropIndicator = connect<StateProps, {}, OwnProps, State>(
	mapStateToProps,
	{},
)(View);
