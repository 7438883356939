import * as R from 'ramda';
import { UPDATE, RESET, type UpdateAction, type ResetAction } from './actions';
import type { OriginalTeams } from './types';

type Action = UpdateAction | ResetAction;

const initialState: OriginalTeams = {};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: OriginalTeams = initialState, action: Action) => {
	switch (action.type) {
		case RESET:
			return { ...action.payload };
		case UPDATE: {
			const { id, ...originals } = action.payload;
			return {
				...state,
				// NOTE merging state into "originals" and not vice versa because we want to keep old value if it was already set (real original)
				[id]: R.merge(originals, state[id]),
			};
		}
		default:
			return state;
	}
};
