import * as R from 'ramda';
import { getFilters } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters';
import { getExistingTeams } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams';
import { TEAM_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { StateProps } from './types';

const mapStateToProps: MapStateToProps<StateProps> = createSelector(
	[getExistingTeams, getFilters],
	(teams, { [TEAM_FILTER_ID]: { id, value } }) => ({
		id,
		value,
		teams: R.sortBy(R.compose(R.toLower, R.prop('title')))(teams),
	}),
);

export default mapStateToProps;
