import type { Dispatch } from 'redux';
import { inlineCreateIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/inline-create';
import { setDefaultProject } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/actions';
import {
	clearInlineCreate,
	setIssueTypeId,
	setProjectId,
	setIssueTypeIdForHierarchy,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/scope/inline-create/actions.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (dispatch: Dispatch) => ({
	createIssueWithTitle: (title: string) => {
		if (title) {
			dispatch(inlineCreateIssue(title));
		} else {
			dispatch(clearInlineCreate());
		}
	},
	cancelInlineCreate: () => dispatch(clearInlineCreate()),
	setIssueTypeId: (issueTypeId: number) => dispatch(setIssueTypeId(issueTypeId)),
	setProjectId: (projectId: number) => dispatch(setProjectId(projectId)),
	setIssueTypeIdForHierarchy: (hierarchyLevel: number, issueTypeId: number) => {
		dispatch(setIssueTypeIdForHierarchy(hierarchyLevel, issueTypeId));
	},
	setDefaultProjectId: (projectId: number) => dispatch(setDefaultProject({ projectId })),
});
