import {
	TEAM_TYPE as TEAM_FIELD,
	STATUS_TYPE as STATUS_FIELD,
	ESTIMATE_TYPE as ESTIMATE,
	PRIORITY_TYPE as PRIORITY_FIELD,
	ASSIGNEE_TYPE as ASSIGNEE_FIELD,
	REPORTER_TYPE as REPORTER_FIELD,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	DUE_DATE,
	TARGET_START_FIELD,
	TARGET_END_FIELD,
	type Assignment,
	type Annotation,
	type IssueInferredDateSelection,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import type { Timestamp } from '@atlassian/jira-portfolio-3-portfolio/src/common/types';
import {
	ISSUE_FIELD_TYPES,
	type IssueFieldTypes,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	DUE_DATE,
	TARGET_START_FIELD,
	TARGET_END_FIELD,
	BUILT_IN_DATE_FIELDS,
} from '../../../../common/api/types';

export const RANK = 'lexoRank' as const;

export const ISSUE_FIELDS = {
	DUE_DATE,
	RANK,
	TARGET_START_FIELD,
	TARGET_END_FIELD,
	TEAM_FIELD,
	STATUS_FIELD,
	ESTIMATE,
	PRIORITY_FIELD,
	ASSIGNEE_FIELD,
	REPORTER_FIELD,
} as const;

export const TARGET_DATE_FIELD_TO_API_DATE_FIELD_MAP: {
	[key: string]: string;
} = Object.freeze({
	[TARGET_START_FIELD]: 'baselineStart',
	[TARGET_END_FIELD]: 'baselineEnd',
});

type IssueFieldToTypeMap = {
	[key: string]: IssueFieldTypes;
};

export type InferredFields = {
	baselineStart?: IssueInferredDateSelection;
	baselineEnd?: IssueInferredDateSelection;
};

export const ISSUE_FIELD_TO_TYPE_MAP: IssueFieldToTypeMap = {
	[DUE_DATE]: ISSUE_FIELD_TYPES.DATE,
	[RANK]: ISSUE_FIELD_TYPES.STRING,
	[TARGET_START_FIELD]: ISSUE_FIELD_TYPES.DATE,
	[TARGET_END_FIELD]: ISSUE_FIELD_TYPES.DATE,
	[TEAM_FIELD]: ISSUE_FIELD_TYPES.STRING,
	[STATUS_FIELD]: ISSUE_FIELD_TYPES.STRING,
	[ESTIMATE]: ISSUE_FIELD_TYPES.NUMBER,
	[PRIORITY_FIELD]: ISSUE_FIELD_TYPES.STRING,
	[ASSIGNEE_FIELD]: ISSUE_FIELD_TYPES.STRING,
	[REPORTER_FIELD]: ISSUE_FIELD_TYPES.STRING,
};

// Issue type without fields that are "overridden" in other enhanced issue types
export type BasicIssue = Readonly<{
	/** Represents JIRA assignee for an issue. */
	assignee: string;
	/** Represents JIRA reporter for an issue. */
	reporter?: string | null;
	assignments: Assignment[];
	annotations?: Annotation[];
	/** Represents a placeholder to hold issue start date. */
	baselineStart?: Timestamp | null;
	/** Represents a placeholder to hold issue end date. */
	baselineEnd?: Timestamp | null;
	color?: string | null;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	customFields?: Record<PropertyKey, any>;
	description?: string | null;
	dueDate?: Timestamp | null;
	earliestStart?: Timestamp | null;
	excluded?: boolean;
	fixVersions?: string[] | null;
	goals?: string[];
	associatedIssueIds?: string[];
	id: string;
	issueKey?: number;
	issueSources?: number[];
	labels?: string[] | null;
	/** Represents an issue level such as Initiative, Epic and so on. */
	level: number;
	lexoRank: string;
	originalStoryPoints?: number | null;
	originalTimeEstimate?: number | null;
	parent?: string;
	priority?: string | null;
	sprint?: string | null;
	completedSprints?: string[];
	status?: string;
	storyPoints?: number | null;
	summary: string;
	/**
	 * The backlog request sends baselineStart and baselineEnd properties to represent
	 * target dates. In the FE, we had changes these to targetStart and targetEnd to
	 * accommodate configured dates.
	 */
	targetEnd?: Timestamp | null;
	targetStart?: Timestamp | null;
	/** END */
	timeEstimate?: number | null;
	timeSpent?: number | null;
	team?: string | null;
	components?: number[] | null;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	optimized?: any | null;
	/** Metadata about inferred fields which maps field name to inference source.
	 * This metadata allows interested components to make a distinction between values which
	 * belong to the issue and which are inferred. All other components just treat inferred
	 * values as usual values, because they are put under the same key in the issue object.
	 * This metadata is not stored in the state, but is rather injected in the getIssues selector.
	 */
	inferred?: InferredFields;
}>;

export type Issue = BasicIssue & {
	readonly project: number;
	/** Represents an issue type id. An issue level such as Initiative, Epic and so on
	 * can have multiple issue type under them. Eg. Story can have issue type such as
	 * Bug, Improvement and so on.
	 */
	readonly type: number;
};
