import type { Effect } from 'redux-saga';
import * as R from 'ramda';
import { select } from 'redux-saga/effects';
import type { Project, Team } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import { colourPalette } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colours';
import { getProjects } from '../../query/projects';
import { getAllTeams } from '../../query/teams';
import type { ColourMaps, ColourByOption } from '../../state/domain/view-settings/colour-by/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	setColourMap,
	setColourForNewTeam,
	SET_COLOUR_BY_VALUE,
	reset as resetColorByConfig,
	addComponentColour,
	addIssueTypeColour,
	addLabelColour,
	addPriorityColour,
	addSelectColour,
	updateComponentColour,
	updateIssueTypeColour,
	updateLabelColour,
	updatePriorityColour,
	updateSelectColour,
	removeComponentColour,
	removeIssueTypeColour,
	removeLabelColour,
	removePriorityColour,
	removeSelectColour,
	clearAllComponentColours,
	clearAllIssueTypeColours,
	clearAllLabelColours,
	clearAllPriorityColours,
	clearAllSelectColours,
} from '../../state/domain/view-settings/colour-by/actions';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	SetColourByValueActionPayload,
	SetColourByValueAction,
} from '../../state/domain/view-settings/colour-by/actions';

/**
 * Rebuilds the colorBy map to accommodate new entities that that got added to the plan.
 * The method tries to keep the previous colors as is and assign new colors to the newly added entities.
 * @param colorMapOfViewPreference
 * @param entityIds
 * @param colorByOption
 */
function rebuildColorMap(
	colorMapOfViewPreference: ColourMaps,
	entityIds: string[],
	colorByOption: ColourByOption,
) {
	const colorMap = colorMapOfViewPreference[colorByOption];
	// Since the preferences might contain entities which do not exist in the plan anymore
	// we need to filter these out and make use of these colours
	const colourOfEntitiesInThisPlan = R.props(
		Object.keys(colorMap).filter((p) =>
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(entityIds as Array<string | number>).includes(Number(p)),
		),
		colorMap,
	);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let newColorMap: Record<string, any> = {};
	for (const id of entityIds) {
		// if the entity exists in the preference
		if (colorMap[id]) {
			newColorMap = {
				...newColorMap,
				[id]: colorMap[id],
			};
		} else {
			const freeColors = R.difference(colourPalette, [
				...Object.values(newColorMap),
				...colourOfEntitiesInThisPlan,
			]);
			newColorMap[id] = freeColors[0] || colourPalette[entityIds.length % colourPalette.length];
		}
	}
	return newColorMap;
}

/**
 * If the color value of project doesn't exist in the preferences, need to add it. Otherwise, need to use it or delete it
 * @param projectColorMapOfViewPreference
 * @returns {Generator<SelectEffect<void, []>, void, *>}
 */
export function* updateProjectColorMap(
	colorMapOfViewPreference: ColourMaps, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const projectIds = (yield select(getProjects)).map((p: Project) => p.id);
	return rebuildColorMap(colorMapOfViewPreference, projectIds, 'project');
}

/**
 * If the color value of team doesn't exist in the preferences, need to add it. Otherwise, need to use it or delete it
 * @param {*} colorMapOfViewPreference
 */

export function* updateTeamColorMap(
	colorMapOfViewPreference: ColourMaps, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const teamIds = (yield select(getAllTeams)).map((p: Team) => p.id);
	return rebuildColorMap(colorMapOfViewPreference, teamIds, 'team');
}
