import has from 'lodash/has';
import invert from 'lodash/invert';
import isFinite from 'lodash/isFinite';
import round from 'lodash/round';
import * as R from 'ramda';
import { fg } from '@atlassian/jira-feature-gating';
import type { IntlShape } from '@atlassian/jira-intl';
import {
	dateMonthFormat,
	longDateFormat,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/constants.tsx';
import { formatTimestampWithIntl } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import {
	ONE_WEEK,
	ONE_DAY,
	ONE_HOUR,
	startOfUtcDay,
	endOfUtcDay,
	startOfUtcWeek,
	getWorkingMillionSecondsBetweenTwoDates,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import {
	STORY_LEVEL,
	EPIC_LEVEL,
} from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import type { ZoomLevel } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/common/types.tsx';
import type {
	Assignment,
	Interval,
	IssueSource,
	SchedulingMode,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import {
	ascend,
	sortWith,
	filterMap,
	isDefined,
	indexBy,
	groupBy,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import type { TimelineRange } from '@atlassian/jira-portfolio-3-portfolio/src/common/types';
import {
	CHECKBOX_STATES,
	EXTERNAL_SPRINT,
	PLANNING_UNITS,
	SCENARIO_TYPE,
	SPRINT_STATES,
	ISSUE_STATUS_CATEGORIES,
	PlanningUnits,
	SCHEDULE_MODE,
	ISSUE_SOURCE_TYPES,
	ENTITY,
	type PlanningUnit,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import { type Mode, OPTIMIZED } from '../../state/domain/app/types.tsx';
import type { IssueType } from '../../state/domain/issue-types/types';
import type { Issue } from '../../state/domain/issues/types';
import type { Adjustments } from '../../state/domain/lexorank/types';
import type {
	OriginalPlannedCapacities,
	OriginalPlannedCapacity,
} from '../../state/domain/original-planned-capacities/types';
import type {
	PlannedCapacities,
	PlannedCapacity,
} from '../../state/domain/planned-capacities/types';
import type { ScopeIssue } from '../../state/domain/scope/types.tsx';
import type { Solution } from '../../state/domain/solution/types.tsx';
import type { Sprint } from '../../state/domain/sprints/types';
import type { Team } from '../../state/domain/teams/types.tsx';
import type { ChangeMetadata, EntityMetadata } from '../../state/domain/update-jira/changes/types';
import type { State } from '../../state/types';
import type { IssueStatusesById } from '../issue-statuses/types.tsx';
import type {
	MemberBucket,
	SprintStream,
	SprintStreamsForTeams,
	SprintStreamsUnitsGetter,
	SprintStreamsUnitsForTeams,
	SprintUnit,
	TimelineSprint,
	FutureSprintWithStartandEndDate,
	PlannedCapacityChange,
	SprintChange,
	SprintWithVelocities,
	SprintsWithFutureDates,
	Context,
} from './types';

const { TODO, INPROGRESS, DONE } = ISSUE_STATUS_CATEGORIES;

export const KANBAN_ITERATION_LENGTH = ONE_WEEK;

const isInfiniteTimelineRange = (timelineRange: TimelineRange) =>
	Math.abs(timelineRange.start) + Math.abs(timelineRange.end) === Infinity;

export const filterAndSortSprints = (sprintsInOrder: number[], sprints: Sprint[]) => {
	const sprintOrdering: Record<string | number, string> = invert(sprintsInOrder);
	return sprints
		.filter((sprint: Sprint) => has(sprintOrdering, sprint.id))
		.sort((a: Sprint, b: Sprint) => {
			const indexA = parseInt(sprintOrdering[a.id], 10);
			const indexB = parseInt(sprintOrdering[b.id], 10);
			return indexA - indexB;
		});
};

export const makeAssignedSprintGetter =
	({ teams }: Solution) =>
	({ team, interval }: Assignment): Interval | null | undefined =>
		isDefined(team) && isDefined(interval) ? R.path([team, 'intervals', interval], teams) : null;

export const getSprints = (state: State): Sprint[] => state.domain.sprints;

export const getSprintsForTeamPure = (
	teams: Team[],
	issueSources: IssueSource[],
	sprints: Sprint[],
): {
	[teamId: string]: Sprint[];
} => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const teamsToSprintMap: Record<string, any> = {};

	teams.forEach((team) => {
		let sprintsForTeam;
		const issueSource: IssueSource | null | undefined = issueSources.find(
			// eslint-disable-next-line @typescript-eslint/no-shadow
			(issueSource) => issueSource.id === team.issueSource,
		);

		if (isDefined(issueSource) && isDefined(issueSource.sprintIds)) {
			sprintsForTeam = filterAndSortSprints(issueSource.sprintIds, sprints);
		}
		teamsToSprintMap[team.id] = sprintsForTeam || [];
	});

	return teamsToSprintMap;
};

export const getSprintsForIssueSourcesPure = (
	issueSourcesById: {
		[id: number]: IssueSource;
	},
	sprintsById: {
		[id: string]: Sprint;
	},
	disabledSprintsById: {
		[id: string]: Sprint;
	},
): {
	[issueSourceId: string]: Sprint[];
} =>
	R.map(
		({ sprintIds = [] }) =>
			R.props(
				sprintIds.map((x) => `${x}`).filter((x) => disabledSprintsById[x] === undefined),
				sprintsById,
			),
		issueSourcesById,
	);

// this function loops through all issue sources and stores:
// - sprints with their associated teams
// - unique sprints with no associated teams
export const getSprintsAndTeamsByIssueSourcesPure = (
	issueSourcesById: {
		[id: number]: IssueSource;
	},
	sprintsById: {
		[id: string]: Sprint;
	},
	teams: Team[],
	disabledSprintsById: {
		[id: string]: Sprint;
	},
): {
	sprintsWithTeams: {
		teams: string[];
		sprints: Sprint[];
	}[];
	sprintsWithoutTeams: Sprint[];
} => {
	const sprintsAndTeamsByIssueSources: {
		sprintsWithTeams: Array<{ teams: string[]; sprints: Sprint[] }>;
		sprintsWithoutTeams: Sprint[];
	} = {
		sprintsWithTeams: [],
		sprintsWithoutTeams: [],
	};

	const uniqueSprintIds = new Set<string>();

	for (const [issueSourceId, issueSource] of Object.entries(issueSourcesById)) {
		const sprintIds = R.prop('sprintIds', issueSource);
		if (sprintIds && sprintIds.length) {
			// we get sprint deets for all sprints from the sprintsById object
			const sprints = sprintIds
				.map((x) => `${x}`)
				.filter((x) => disabledSprintsById[x] === undefined)
				.map<Sprint>((sprintId) => sprintsById[sprintId]);
			// if there are teams for that very same issue source
			const teamTitles = filterMap<Team, string>(
				(team) => team.issueSource === Number(issueSourceId),
				(team) => team.title,
				teams,
			);
			if (teamTitles && teamTitles.length) {
				// then that issue source has sprints with associated teams
				sprintsAndTeamsByIssueSources.sprintsWithTeams.push({
					teams: teamTitles,
					sprints,
				});
				// otherwise the issue source has sprints without associated teams
			} else {
				// we store those sprints on the side, but we need to be careful of not having duplicated sprints
				sprints.forEach((sprint) => {
					// so we use the "uniqueSprintIds" set to keep track of unique sprint ids
					if (!uniqueSprintIds.has(sprint.id)) {
						uniqueSprintIds.add(sprint.id);
						// and only store the sprint if we have not encountered it before
						sprintsAndTeamsByIssueSources.sprintsWithoutTeams.push(sprint);
					}
				});
			}
		}
	}
	return sprintsAndTeamsByIssueSources;
};

export const calculateCategoryCheckboxStatus = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	sprintIds: any[],
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	uniqueFilteredSprints: Set<any>,
): (typeof CHECKBOX_STATES)[keyof typeof CHECKBOX_STATES] => {
	// if there are selected sprints in the sprint filter menu
	if (uniqueFilteredSprints.size > 0) {
		// if the length of the sprintIds array is not higher than the length of the array of selected sprints
		// and all sprintIds are included in the array of selected sprints in the sprint filter menu
		// note: checking array lengths first can avoid unnecessary loops if there are less sprints selected
		// in the filters than there are in the array of sprints we're searching in
		// the original version 'sprintIds.every((el) => uniqueFilteredSprints.has(el))' checking of every sprintId is selected, if the sprintIds length is n, the uniqueFilteredSprints length is m
		// it means the calculation cost is m*n
		// the improved '!sprintIds.some((el) => !uniqueFilteredSprints.has(el))', it checks if at least one of the sprintIds is not found in the uniqueFilteredSprints
		// so in the best case, the cost is 1, the worst calculation cost is m*n
		if (
			sprintIds.length > 0 &&
			sprintIds.length <= uniqueFilteredSprints.size &&
			!sprintIds.some((el) => !uniqueFilteredSprints.has(el))
		) {
			return CHECKBOX_STATES.CHECKED;
		}
		if (sprintIds.some((el) => uniqueFilteredSprints.has(el))) {
			return CHECKBOX_STATES.INDETERMINATE;
		}
	}
	return CHECKBOX_STATES.UNCHECKED;
};

export const getSprintStatesCategoriesPure = (
	sprintsIdsByState: {
		[state: string]: string[];
	},
	externalSprintIds: string[],
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	uniqueFilteredSprints: Set<any>,
) => {
	const sprintStateCategories = [];
	// let's start with non-external sprints
	Object.keys(SPRINT_STATES).forEach((sprintState) => {
		// getting all sprints id's for the current sprint state
		const sprintIds = sprintsIdsByState[sprintState] || [];

		sprintStateCategories.push({
			checkboxState: calculateCategoryCheckboxStatus(sprintIds, uniqueFilteredSprints),
			sprintIds,
			sprintState,
		});
	});

	// and finish with the external sprints (if there are any)
	if (externalSprintIds.length) {
		sprintStateCategories.push({
			checkboxState: calculateCategoryCheckboxStatus(externalSprintIds, uniqueFilteredSprints),
			sprintIds: externalSprintIds,
			sprintState: EXTERNAL_SPRINT,
		});
	}
	return sprintStateCategories;
};

export const getSprintById = (sprints: Sprint[], sprintId: number | string) =>
	sprints.find((sprint) => sprint.id === sprintId);

export const getSprintByIdMapPure = (
	sprints: Sprint[],
): {
	[key: string]: Sprint;
} => indexBy(R.prop('id'), sprints);

/* Utility functions for sprints on timeline calculations */

export const calcWidthPercentage = (start: number, end: number, timeline: TimelineRange) => {
	const unitLength = Math.min(timeline.end, end) - Math.max(timeline.start, start);
	const rangeLength = timeline.end - timeline.start;
	return (100 * unitLength) / rangeLength;
};

const calcOffsetPercentage = (startOfUtcDate: number, timeline: TimelineRange) => {
	const offsetLength = Math.max(timeline.start, startOfUtcDate) - timeline.start;
	return (100 * offsetLength) / (timeline.end - timeline.start);
};

const sprintStartsWithinTimeline = (timeline: TimelineRange, sprint: TimelineSprint): boolean =>
	timeline.end >= sprint.startDate && sprint.startDate >= timeline.start;

const sprintEndsWithinTimeline = (timeline: TimelineRange, sprint: TimelineSprint): boolean =>
	sprint.endDate >= timeline.start && timeline.end >= sprint.endDate;

const sprintAppearsOnTimeline = (timeline: TimelineRange, sprint: TimelineSprint) =>
	sprintStartsWithinTimeline(timeline, sprint) || sprintEndsWithinTimeline(timeline, sprint);

// Note that the sprint titles are internationalised later, in the view
const createPastSprint = (
	startDate: number,
	endDate: number,
	schedulingMode: SchedulingMode,
): TimelineSprint => ({
	id: 'PAST',
	title: 'Past Sprint',
	state: SPRINT_STATES.CLOSED,
	startDate,
	endDate,
	issueSources: [],
	schedulingMode,
	rawStartDate: startDate,
	rawEndDate: endDate,
});

// Note that the sprint titles are internationalised later, in the view
const createFutureSprint = (
	startDate: number,
	endDate: number,
	schedulingMode: SchedulingMode,
): TimelineSprint => ({
	id: 'FUTURE',
	title: 'Future Sprint',
	state: SPRINT_STATES.FUTURE,
	startDate,
	endDate,
	issueSources: [],
	schedulingMode,
	rawStartDate: startDate,
	rawEndDate: endDate,
});

export const divideSprintsToNonOverlappingStreams = (sprints: TimelineSprint[]): SprintStream[] => {
	if (sprints.length < 2) return [sprints];
	const streams: Array<SprintStream> = [];
	let pool = sprints;
	let nextPool = [];

	while (pool.length > 0) {
		let cursor = pool[0];
		const stream = [cursor];
		for (const sprint of pool.slice(1)) {
			// if a sprint starts more than one day before the previous sprint ends, it will be rendered
			// in a second stream of sprints as we consider that it is a deliberate choice from the user
			if (
				cursor.rawEndDate &&
				sprint.rawStartDate &&
				endOfUtcDay(cursor.rawEndDate) - startOfUtcDay(sprint.rawStartDate) > ONE_DAY
			) {
				nextPool.push(sprint);
			} else {
				cursor = sprint;
				stream.push(cursor);
			}
		}
		streams.push(stream);
		pool = nextPool;
		nextPool = [];
	}
	return streams;
};

const startDateAscend = ascend((x: { startDate: number }) => x.startDate);
const endDateAscend = ascend((x: { endDate: number }) => x.endDate);

export const getPastAndActiveSprintsSortedByDate = (sprints: TimelineSprint[]): TimelineSprint[] =>
	sprints
		.map((sprint) => {
			const endDate = sprint.endDate && startOfUtcDay(sprint.completeDate || sprint.endDate) - 1;
			return {
				...sprint,
				startDate: startOfUtcDay(sprint.startDate),
				endDate,
				rawStartDate: sprint.startDate,
				rawEndDate: sprint.completeDate || sprint.endDate,
			};
		})
		// Initial order of sprints might be a bit unclear in case of overlapping and parallel sprints
		// thus it's better to sort and allow later code to rely on sprints being sorted by
		// start and end date.
		.sort((a, b) => startDateAscend(a, b) || endDateAscend(a, b));

export const addDatesToFutureSprints = (
	sprints: Sprint[],
	start: number, // start is either the last "real" sprint end date, or 0
	sprintLengthInMillis: number,
): TimelineSprint[] => {
	const startOfTomorrow = startOfUtcDay(Date.now()) + ONE_DAY;
	// Future sprints gonna be in the future. © The Scheduler.
	let currentStart = Math.max(startOfUtcDay(start + 1), startOfTomorrow);

	return sprints.map((sprint) => {
		// If the sprint does not have a start date, just start asap after previous sprint
		let rawStartDate = sprint.startDate || currentStart;
		let startDate = sprint.startDate ? startOfUtcDay(sprint.startDate) : currentStart;

		// If the sprint does not have a start date but has an end date
		// (this is impossible by Jira's design but we want to handle gracefully just in case)
		if (!isDefined(sprint.startDate) && isDefined(sprint.endDate)) {
			rawStartDate = sprint.endDate - sprintLengthInMillis;
			startDate = startOfUtcDay(rawStartDate);
		}

		// Coz we want to end on the day before the start of next sprint... we need to - 1 day.
		const sprintLengthAdjusted = sprintLengthInMillis - ONE_DAY;
		const rawEndDate = sprint.endDate || endOfUtcDay(startDate + sprintLengthAdjusted);
		const endDate = endOfUtcDay(sprint.endDate || startDate + sprintLengthAdjusted);

		currentStart = startOfUtcDay(endDate + ONE_DAY);

		return { ...sprint, startDate, endDate, rawStartDate, rawEndDate };
	});
};

// this function generates "fake" past sprints in order to fill the swimlanes in the timeline
export const generatePastSprints = (
	start: number,
	end: number,
	sprintLengthInMillis: number,
	schedulingMode: SchedulingMode,
): TimelineSprint[] => {
	const sprints: Array<TimelineSprint> = [];

	// Don't generate anything if we have infinite range
	if (isInfiniteTimelineRange({ start, end })) return sprints;

	let endDate = startOfUtcDay(end);

	while (endDate > start) {
		const startDate = startOfUtcDay(endDate - sprintLengthInMillis);
		sprints.push(createPastSprint(startDate, endDate - 1, schedulingMode));
		endDate = startDate;
	}
	return sprints.reverse();
};

// this function generates "fake" future sprints in order to fill the swimlanes in the timeline
export const generateFutureSprints = (
	start: number,
	end: number,
	sprintLengthInMillis: number,
	schedulingMode: SchedulingMode,
): TimelineSprint[] => {
	const sprints: Array<TimelineSprint> = [];

	// Don't generate anything if we have infinite range
	if (isInfiniteTimelineRange({ start, end })) return sprints;

	let startDate = startOfUtcDay(start + 1 * 1000);
	while (startDate < end) {
		const endDate = startOfUtcDay(startDate + sprintLengthInMillis);
		sprints.push(createFutureSprint(startDate, endDate - 1, schedulingMode));
		startDate = endDate;
	}
	return sprints;
};

export const getSprintUnits = (
	sprints: TimelineSprint[],
	timeline: TimelineRange,
	omitGaps = false,
): SprintUnit[] => {
	const units: SprintUnit[] = [];
	let cursor = timeline.start;

	for (const {
		title,
		id,
		state,
		startDate,
		endDate,
		issueSources,
		planned,
		rawStartDate,
		rawEndDate,
		...rest
	} of sprints) {
		// We shouldn't get gaps more than 1 ms when less than a day due to alignment, and those
		// small gaps shouldn't be rendered as they will be too thin and still have a border.
		if (cursor + 1 < startDate && !omitGaps) {
			units.push({
				id: 'GAP',
				widthPercentage: calcWidthPercentage(cursor, startDate, timeline),
				label: {
					display: 'none',
				},
				// offset is used in "app-simple-plans/view/main/tabs/roadmap/timeline/schedule/sprints/sprint/view.tsx"
				// in order to render the sprint box at the right place in the timeline given its position is absolute
				offset: calcOffsetPercentage(startOfUtcDay(startDate), timeline),
				state,
				startDate: cursor,
				endDate: startDate,
				issueSources,
				planned,
				rawStartDate: cursor,
				rawEndDate,
			});
		}
		units.push({
			id,
			widthPercentage: calcWidthPercentage(startDate, endDate, timeline),
			label: {
				display: title,
			},
			// offset is used in "app-simple-plans/view/main/tabs/roadmap/timeline/schedule/sprints/sprint/view.tsx"
			// in order to render the sprint box at the right place in the timeline given its position is absolute
			offset: calcOffsetPercentage(startOfUtcDay(startDate), timeline),
			state,
			startDate,
			endDate,
			issueSources,
			planned,
			rawStartDate,
			rawEndDate,
			...rest,
		});
		cursor = endDate;
	}
	if (cursor < timeline.end && !omitGaps) {
		units.push({
			id: 'GAP',
			widthPercentage: calcWidthPercentage(cursor, timeline.end, timeline),
			label: {
				display: 'none',
			},
			// offset is used in "app-simple-plans/view/main/tabs/roadmap/timeline/schedule/sprints/sprint/view.tsx"
			// in order to render the sprint box at the right place in the timeline given its position is absolute
			offset: calcOffsetPercentage(startOfUtcDay(timeline.end), timeline),
			state: SPRINT_STATES.FUTURE,
			startDate: cursor,
			endDate: timeline.end,
			issueSources: [],
			planned: false,
			rawStartDate: cursor,
			rawEndDate: timeline.end,
		});
	}
	return units;
};

const getIssueDates = (issue: Issue, mode: Mode) => {
	const isOptimized = mode === OPTIMIZED;
	const { assignments, baselineStart, baselineEnd } = issue;
	let start = baselineStart;
	let end = baselineEnd;
	if (isOptimized) {
		const optimizedStart = assignments.reduce(
			// eslint-disable-next-line @typescript-eslint/no-shadow
			(acc, { start }) => Math.min(acc, start || acc),
			Infinity,
		);
		if (isFinite(optimizedStart)) {
			start = optimizedStart;
		}
		const optimizedEnd = assignments.reduce(
			// eslint-disable-next-line @typescript-eslint/no-shadow
			(acc, { end }) => Math.max(acc, end || acc),
			-Infinity,
		);
		if (isFinite(optimizedStart)) {
			end = optimizedEnd;
		}
	}
	return { start, end };
};

export const getSprintsInStream = (
	team: Team,
	sprintsForTeam: {
		[key: string]: Array<Sprint>;
	},
	defaultIterationLength: number | null | undefined,
): TimelineSprint[] => {
	if (!isDefined(team.issueSource)) {
		return [];
	}

	// This is default sprint length for the team.
	// It is required to fill up future and fake sprints with start and end dates.
	const sprintLengthInMillis = (team.iterationLength || defaultIterationLength || 0) * ONE_WEEK;

	// If there is no default sprint length then we are not going to return streams
	// for this team even if all sprints within timeline range are real and non-future
	// (which means that default sprint length will not be used). Just to keep things simple.
	if (sprintLengthInMillis === 0) {
		return [];
	}

	// Splitting sprints into past/active and future ones to divide the former to non-overlapping streams of sprints.
	const [futureSprints, pastAndActiveSprints] = R.partition(
		(x) => x.state === SPRINT_STATES.FUTURE,
		sprintsForTeam[team.id],
	);

	const pastAndActiveSprintsSortedByDate = getPastAndActiveSprintsSortedByDate(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		pastAndActiveSprints as TimelineSprint[],
	);

	// Those dates are required to know where to start fake past and real/fake future sprints.
	// If there are no real sprints with dates then sprints should start from tomorrow.
	// Sprints should not start from today because we cannot reasonably expect work to be started on the current day.
	const lastRealSprint = R.last(pastAndActiveSprintsSortedByDate);
	const lastRealEndDate = (lastRealSprint && lastRealSprint.endDate) || 0;

	// Now it's time to divide overlapping and parallel sprints into non-overlapping streams.
	// It needs to be done only for those real sprints with dates which appear on timeline.
	// Note they are aligned, otherwise even linear sequence of sprints will lead to the
	// stream-per-sprint degenerate case because of one day overlap.
	// Add start and end dates to future sprints to show them on a timeline.
	// Assumptions: future sprints have default sprint length and follow each other without gaps and overlaps.
	const futureSprintsWithDates = addDatesToFutureSprints(
		futureSprints,
		lastRealEndDate,
		sprintLengthInMillis,
	);

	return [...pastAndActiveSprintsSortedByDate, ...futureSprintsWithDates].sort(
		(a, b) => startDateAscend(a, b) || endDateAscend(a, b),
	);
};

export const getScrumCapacityIssueSorter = (mode: Mode, adjustment: Adjustments) =>
	sortWith<Issue>([
		ascend((issue) => getIssueDates(issue, mode).end || 0),
		ascend(({ level }) => level),
		ascend(({ lexoRank }) => lexoRank || ''),
		ascend(({ id }) => adjustment.indexOf(id)),
	]);

// the capacity is allocated to smaller buckets within a sprint based on the team velocity and
// the number of team members
// Example: A team with 4 members and a velocity of 40 will have 4 member buckets each with the capacity of 10
export const getMemberBucketFromTeamCapacity = (
	numberOfMembersInTheTeam: number,
	capacityOfTeam: number,
) => {
	const adjustedNumberOfMembersInTheTeam =
		numberOfMembersInTheTeam === 0 ? 1 : numberOfMembersInTheTeam;

	const roundCapacityPerMember = Math.floor(capacityOfTeam / adjustedNumberOfMembersInTheTeam);
	let remainingCapacity = capacityOfTeam % adjustedNumberOfMembersInTheTeam;

	return R.times<MemberBucket>(() => {
		let availableCapacity = roundCapacityPerMember;
		if (remainingCapacity > 0) {
			availableCapacity += 1;
			remainingCapacity -= 1;
		}

		return {
			availableCapacity,
			usedCapacity: 0,
		};
	}, adjustedNumberOfMembersInTheTeam);
};

export const addSprintCapacityToSprintStream = ({
	defaultTeamVelocity,
	issuesBySprint,
	issueStatusById,
	planningUnit,
	stream,
	team,
	weeklyCapacityByTeam,
	boardId,
	mode,
	issueLexoRankAdjustments,
	issuesByTeam,
	plannedCapacities,
}: {
	defaultTeamVelocity: number;
	issuesBySprint: {
		[key: string]: Issue[];
	};
	issueStatusById: IssueStatusesById;
	planningUnit: string;
	stream: SprintStream;
	team: Team;
	weeklyCapacityByTeam: {
		[key: string]: number;
	};
	boardId?: string;
	mode: Mode;
	issueLexoRankAdjustments: Adjustments;
	issuesByTeam: {
		[key: string]: Issue[];
	};
	plannedCapacities: PlannedCapacities;
}) => {
	const estimateAttribute =
		planningUnit === PlanningUnits.storyPoints ? 'storyPoints' : 'timeEstimate';
	const sorter = getScrumCapacityIssueSorter(mode, issueLexoRankAdjustments);
	const carryOver = new Map<string, number>();
	const updatedStream: Array<TimelineSprint> = [];
	const numberOfMembersInTheTeam =
		team.resources.filter(({ scenarioType }) => scenarioType !== SCENARIO_TYPE.DELETED).length || 1; // if there are no members in the team, we assume the entire team as a single resource

	/**
	 * Helper methods
	 */

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const getCapacityOfTeam = (team: Team, sprint: TimelineSprint) => {
		const iterationId =
			team.schedulingMode === SCHEDULE_MODE.kanban ? String(sprint.startDate) : sprint.id;
		// Explicit planned capacity will always override
		const plannedCapacity = R.path<PlannedCapacities[string][string]>(
			[team.id, iterationId],
			plannedCapacities,
		);
		if (plannedCapacity) {
			return plannedCapacity.capacity;
		}

		if (planningUnit === PlanningUnits.storyPoints) {
			return team.velocity || team.velocity === 0 ? team.velocity : defaultTeamVelocity;
		}
		// WeeklyCapacity is in hours, but we should take weekends into account.
		// For example, if the sprintA.startDate is 29/7/2019(Monday) and the sprintA.endDate is 2/8/2019(Friday),
		// the length of this sprintA is 5 days, there are 5 working day.
		// However, the sprintB.startDate is 30/7/2019(Tuesday) and the sprintB.endDate is 3/8/2019(Saturday), the length of this sprintB is 5 days,
		// but there are 4 working day. So the sprintA and sprintB should have different availableCapacity
		return (
			(ONE_HOUR *
				weeklyCapacityByTeam[team.id] *
				getWorkingMillionSecondsBetweenTwoDates(sprint.startDate, sprint.endDate)) /
			(5 * ONE_DAY) /
			1000
		);
	};

	const getIssuesThatFallInTheSprint = (sprint: TimelineSprint) =>
		(issuesByTeam[team.id] || []).filter((issue) => {
			// note: if the issue is assigned to a sprint
			// start = rawStartDate of the sprint
			// end = rawEndDate of the sprint
			const { start, end } = getIssueDates(issue, mode);

			// ignore if the issues are assigned with a sprint. Their capacity is distributed separately
			if (issue.sprint && team.schedulingMode === SCHEDULE_MODE.scrum) {
				return false;
			}

			if (
				!isDefined(sprint.startDate) ||
				!isDefined(sprint.endDate) ||
				!isDefined(start) ||
				!isDefined(end)
			) {
				return false;
			}

			// if issue does not have a sprint, it might be because it was done in a previous sprint
			// so we check if the issue was done before the start of the sprint
			if (
				!issue.sprint &&
				issue.completedSprints &&
				!R.isEmpty(issue.completedSprints) &&
				issue.status &&
				issueStatusById[issue.status] &&
				issueStatusById[issue.status].categoryId === ISSUE_STATUS_CATEGORIES.DONE &&
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				end < sprint.rawStartDate!
			) {
				return false;
			}

			return (
				(start >= sprint.startDate && start <= sprint.endDate) ||
				(end >= sprint.startDate && end <= sprint.endDate) ||
				(start <= sprint.startDate && end >= sprint.endDate)
			);
		});

	const getIssuesAssignedToTheSprint = (sprint: TimelineSprint) =>
		(issuesByTeam[team.id] || []).filter((issue) => {
			const optimizedSprint = R.path(['optimized', 'sprint'], issue);
			return mode === OPTIMIZED ? optimizedSprint === sprint.id : issue.sprint === sprint.id;
		});

	// finds the next assignable member bucket which is large enough to fit the issue completely but yet small enough
	const getNextAssignableMemberBucket = (
		memberBuckets: MemberBucket[],
		estimateOfIssue: number,
	) => {
		const sortedByCapacity = R.sortBy(R.prop('usedCapacity'), memberBuckets);
		const assignableMiniBucket = sortedByCapacity.find((bucket) => {
			const remainingBucketCapacity = bucket.availableCapacity - bucket.usedCapacity;
			return remainingBucketCapacity >= estimateOfIssue;
		});

		// if we couldn't find a bucket which can fit the issues completely, return the first bucket with any capacity available
		if (!assignableMiniBucket) {
			return sortedByCapacity.find((bucket) => bucket.availableCapacity - bucket.usedCapacity > 0);
		}

		return assignableMiniBucket;
	};

	const isTheLastSprintOfIssue = (issue: Issue, sprint: TimelineSprint) => {
		const { end } = getIssueDates(issue, mode);
		return end && end >= sprint.startDate && end <= sprint.endDate;
	};

	const getAssignableCapacity = ({
		issue,
		nextAssignableMemberBucket,
		estimateOfIssue,
		assignRemainingEstimateToBucket,
	}: {
		issue: Issue;
		nextAssignableMemberBucket?: MemberBucket;
		estimateOfIssue: number;
		assignRemainingEstimateToBucket?: number | boolean | null;
	}) => {
		let usedSprintCapacity = 0;
		const remainingCapacityInBucket = nextAssignableMemberBucket
			? Math.max(
					nextAssignableMemberBucket.availableCapacity - nextAssignableMemberBucket.usedCapacity,
					0,
				)
			: 0;

		// if this is assignRemainingEstimateToBucket is true, we assign the entire remaining estimate to that sprint.
		// this could potentially overbook the sprint but that's the best we can do.
		if (estimateOfIssue <= remainingCapacityInBucket || assignRemainingEstimateToBucket) {
			if (nextAssignableMemberBucket) {
				// eslint-disable-next-line no-param-reassign
				nextAssignableMemberBucket.usedCapacity += estimateOfIssue;
			}
			usedSprintCapacity = estimateOfIssue;
			carryOver.set(issue.id, 0);
		} else {
			if (nextAssignableMemberBucket) {
				// eslint-disable-next-line no-param-reassign
				nextAssignableMemberBucket.usedCapacity += remainingCapacityInBucket;
			}
			usedSprintCapacity = remainingCapacityInBucket;
			carryOver.set(issue.id, estimateOfIssue - remainingCapacityInBucket);
		}

		return usedSprintCapacity;
	};

	for (const sprint of stream) {
		let statusBreakdown: {
			byCategoryId: {
				[key: string]: number;
			};
			total: number;
		} = {
			byCategoryId: {
				[String(TODO)]: 0,
				[String(INPROGRESS)]: 0,
				[String(DONE)]: 0,
			},
			total: 0,
		};

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const allContributingIssues: Record<string, any> = {};
		let issueWithoutSprintCount = 0;
		let usedCapacity = 0;

		const capacityOfTeam = getCapacityOfTeam(team, sprint);
		const memberBuckets = getMemberBucketFromTeamCapacity(
			numberOfMembersInTheTeam,
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			capacityOfTeam!,
		);

		if (sprint.state !== SPRINT_STATES.CLOSED) {
			const issuesInTheSprint = getIssuesThatFallInTheSprint(sprint);
			const issuesInTheSprintSorted = sorter(issuesInTheSprint);
			const issuesAssignedToTheSprint = getIssuesAssignedToTheSprint(sprint);

			// prioritize issuesAssignedToTheSprint before any other issues since they needs to be distributed first
			const issuesToBeDistributed = [...issuesAssignedToTheSprint, ...issuesInTheSprintSorted];

			issuesToBeDistributed.forEach((issue) => {
				const estimate = issue[estimateAttribute];

				// count issues without sprint
				if (!issue.sprint) {
					issueWithoutSprintCount += 1;
				}

				if (!isDefined(estimate)) {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					sprint.unestimatedIssuesCount! += 1;
				}

				// get the estimate of the issue. If the carryOver exists, get it instead of the actual estimate from the issue
				let estimateOfIssue = carryOver.has(issue.id)
					? carryOver.get(issue.id) || 0
					: estimate || 0;

				let nextAssignableMemberBucket = getNextAssignableMemberBucket(
					memberBuckets,
					estimateOfIssue,
				);

				const lastSprintOfTheIssue =
					issuesAssignedToTheSprint.includes(issue) || // if an issue is assigned with a sprint, the work should be done in that sprint
					isTheLastSprintOfIssue(issue, sprint);

				const addCapacityToCategory = (capacity: number) => {
					const category =
						R.path<(typeof issueStatusById)[string]['categoryId']>(
							[issue.status || '', 'categoryId'],
							issueStatusById,
						) || TODO;
					if (!category) {
						return statusBreakdown;
					}
					const currentVal = R.path<(typeof statusBreakdown)['byCategoryId'][string]>(
						['byCategoryId', category],
						statusBreakdown,
					);
					return R.assocPath(
						['byCategoryId', category],
						(currentVal || 0) + capacity,
						statusBreakdown,
					);
				};

				// Only counts the capacity we took from this particular issue for this sprint.
				let contributingCapacity = 0;

				const assignCapacity = (capacity: number) => {
					usedCapacity += capacity;
					contributingCapacity += capacity;
					statusBreakdown = addCapacityToCategory(capacity);
				};

				if (issue.level > STORY_LEVEL) {
					while (
						(estimateOfIssue > 0 && nextAssignableMemberBucket) ||
						(estimateOfIssue > 0 && lastSprintOfTheIssue)
					) {
						const capacity = getAssignableCapacity({
							issue,
							nextAssignableMemberBucket,
							estimateOfIssue,
							assignRemainingEstimateToBucket: lastSprintOfTheIssue && !nextAssignableMemberBucket,
						});
						assignCapacity(capacity);

						estimateOfIssue = carryOver.has(issue.id)
							? carryOver.get(issue.id) || 0
							: estimate || 0;

						nextAssignableMemberBucket = getNextAssignableMemberBucket(
							memberBuckets,
							estimateOfIssue,
						);
					}
				} else if (nextAssignableMemberBucket || lastSprintOfTheIssue) {
					const capacity = getAssignableCapacity({
						issue,
						nextAssignableMemberBucket,
						estimateOfIssue,
						assignRemainingEstimateToBucket: lastSprintOfTheIssue,
					});
					assignCapacity(capacity);
				}

				// Snapshot for allContributingIssues
				allContributingIssues[issue.id] = { ...issue, contributingCapacity };
			});

			// Breakdown by issueCount when we are using time based planningUnit
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			if (([PLANNING_UNITS.HOURS, PLANNING_UNITS.DAYS] as string[]).includes(planningUnit)) {
				statusBreakdown.byCategoryId = R.compose(
					R.merge({ [String(TODO)]: 0, [String(INPROGRESS)]: 0, [String(DONE)]: 0 }),
					R.countBy((issue: Issue) =>
						String(
							R.path<(typeof issueStatusById)[string]['categoryId']>(
								[issue.status || '', 'categoryId'],
								issueStatusById,
							) || TODO,
						),
					),
				)(issuesToBeDistributed);
			}
		}

		// the unestimated issue count on considers issues with the sprint assigned to it
		const unestimatedIssuesCount = (issuesBySprint[sprint.id] || []).filter(
			(issue) => issue.team === team.id && !isDefined(issue[estimateAttribute]),
		).length;

		statusBreakdown.total = R.sum(R.values(statusBreakdown.byCategoryId));

		updatedStream.push({
			...sprint,
			availableCapacity: Number(capacityOfTeam?.toFixed(1)),
			doneEstimate: statusBreakdown.byCategoryId[String(DONE)],
			schedulingMode: team.schedulingMode,
			unestimatedIssuesCount,
			issueWithoutSprintCount,
			usedCapacity,
			statusBreakdown,
			allContributingIssues,
			boardId,
		});
	}

	return updatedStream;
};

const getSprintStreamsForKanbanTeam = ({
	issueStatusById,
	issuesByKanbanIteration,
	mode,
	team,
	timelineRange,
	weeklyCapacityByTeam,
	planningUnit,
	defaultTeamVelocity,
	issueLexoRankAdjustments,
	issuesByTeam,
	boardId,
	plannedCapacities,
}: {
	issueStatusById: IssueStatusesById;
	issuesByKanbanIteration: {
		[key: string]: Issue[];
	};
	mode: Mode;
	team: Team;
	timelineRange: TimelineRange;
	weeklyCapacityByTeam: {
		[key: string]: number;
	};
	planningUnit: string;
	defaultTeamVelocity: number;
	issueLexoRankAdjustments: Adjustments;
	issuesByTeam: {
		[key: string]: Issue[];
	};
	boardId: string;
	plannedCapacities: PlannedCapacities;
}): SprintStream[] => {
	// Iteration length is fixed to one week.
	// We convert it into one week fake sprint.
	// However calculating capacity and its usage is a whole world of complicated stuff involving plan defaults, plan settings and team defaults and settings.
	const activeIterationStart = startOfUtcWeek(Date.now()).getTime();
	const activeIterationEnd = startOfUtcDay(activeIterationStart + KANBAN_ITERATION_LENGTH) - 1;

	const activeSprint = {
		id: 'ACTIVE',
		// note: the sprint title is internationalised later, in the view
		title: 'Active Sprint',
		state: SPRINT_STATES.ACTIVE,
		startDate: activeIterationStart,
		endDate: activeIterationEnd,
		issueSources: [],
		rawStartDate: activeIterationStart,
		rawEndDate: activeIterationEnd,
	};
	const futureSprints = generateFutureSprints(
		activeIterationEnd,
		timelineRange.end,
		KANBAN_ITERATION_LENGTH,
		SCHEDULE_MODE.kanban,
	);

	const allSprints = [activeSprint, ...futureSprints];

	const allSprintsWithCapacity = addSprintCapacityToSprintStream({
		defaultTeamVelocity,
		issuesBySprint: issuesByKanbanIteration,
		issueStatusById,
		planningUnit,
		stream: allSprints,
		team,
		weeklyCapacityByTeam,
		boardId,
		mode,
		issueLexoRankAdjustments,
		issuesByTeam,
		plannedCapacities,
	});

	const sprintsWithCapacity = allSprintsWithCapacity.filter((sprint) =>
		sprintAppearsOnTimeline(timelineRange, sprint),
	);

	return [sprintsWithCapacity];
};

export const getSprintStreamsForTeam =
	({
		defaultIterationLength,
		defaultTeamVelocity,
		issueStatusById,
		issuesByKanbanIteration,
		issuesBySprint,
		mode,
		planningUnit,
		sprintsForTeam,
		timelineRange,
		weeklyCapacityByTeam,
		issueSources,
		issueLexoRankAdjustments,
		issuesByTeam,
		plannedCapacities,
		filteredSprints,
	}: Context) =>
	(team: Team): SprintStream[] => {
		const { value: boardId } = issueSources.find((source) => source.id === team.issueSource) || {};

		// Kanban teams don't have sprints, but to show capacity we are going to generate
		// some fake ones representing iterations.
		if (team.schedulingMode === SCHEDULE_MODE.kanban) {
			return getSprintStreamsForKanbanTeam({
				issueStatusById,
				issuesByKanbanIteration,
				mode,
				team,
				timelineRange,
				weeklyCapacityByTeam,
				planningUnit,
				defaultTeamVelocity,
				issuesByTeam,
				issueLexoRankAdjustments,
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				boardId: boardId!,
				plannedCapacities,
			});
		}

		let sprintsInStream: TimelineSprint[] = [];

		sprintsInStream = getSprintsInStream(team, sprintsForTeam, defaultIterationLength);

		if (!sprintsInStream) {
			return [];
		}

		// Those dates are required to know where to start fake past and real/fake future sprints.

		// If there are no real sprints with dates then sprints should start from tomorrow.
		// Sprints should not start from today because we cannot reasonably expect work to be started on the current day.
		const tomorrow = startOfUtcDay(Date.now() + ONE_DAY);

		// Add start and end dates to future sprints to show them on a timeline.
		// Assumptions: future sprints have default sprint length and follow each other without gaps and overlaps.
		const sprintLengthInMillis = (team.iterationLength || defaultIterationLength || 0) * ONE_WEEK;

		// Now it's time to add fake past/future sprints to fill the gap up to timeline range.
		const firstRealSprint = R.head(sprintsInStream);
		const fakePastSprints = generatePastSprints(
			timelineRange.start,
			(firstRealSprint && firstRealSprint.startDate) || tomorrow,
			sprintLengthInMillis,
			SCHEDULE_MODE.scrum,
		);

		const lastRealSprint = R.last(sprintsInStream);
		const fakeFutureSprints = generateFutureSprints(
			(lastRealSprint && lastRealSprint.endDate) || tomorrow,
			timelineRange.end,
			sprintLengthInMillis,
			SCHEDULE_MODE.scrum,
		);

		// Top stream is the stream we attach fake past/future sprints to.
		const streams = divideSprintsToNonOverlappingStreams(sprintsInStream);
		const topStream = streams[0];
		topStream.splice(0, 0, ...fakePastSprints);
		Array.prototype.push.apply(topStream, fakeFutureSprints);

		const excludeEmptyStreams = (stream: SprintStream) => {
			if (!fg('sprint_stacking_filtering')) {
				return true;
			}
			const hasFilteredSprints = filteredSprints && filteredSprints.size > 0;
			return !hasFilteredSprints || stream.some((sprint) => filteredSprints.has(sprint.id));
		};
		// should filter the sprint in here, if filter the invisible sprint in the getAlignedSprints method, will get a wrong the firstRealStartDate and lastRealEndDate
		// should make sure there is at least a visible sprint in the stream, to fix https://bulldog.internal.atlassian.com/browse/JPOS-4010
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const streamsContainVisibleSprint = streams.filter(excludeEmptyStreams).reduce<Array<any>>(
			// eslint-disable-next-line @typescript-eslint/no-shadow
			(streamsContainVisibleSprint, stream) => {
				const visibleSprint = stream.filter((sprint) =>
					sprintAppearsOnTimeline(timelineRange, sprint),
				);

				if (visibleSprint.length > 0) {
					// we don't want to provide comparison of sprint's iteration length (start and end dates)
					// for teams that have no any issue
					if (!issuesByTeam[team.id] && mode !== OPTIMIZED) {
						return [];
					}

					const allSprintsWithCapacity = addSprintCapacityToSprintStream({
						defaultTeamVelocity,
						issuesBySprint,
						issueStatusById,
						planningUnit,
						stream,
						team,
						weeklyCapacityByTeam,
						boardId,
						mode,
						issueLexoRankAdjustments,
						issuesByTeam,
						plannedCapacities,
					});

					const visibleSprintsWithCapacity = allSprintsWithCapacity.filter((sprint) =>
						sprintAppearsOnTimeline(timelineRange, sprint),
					);

					streamsContainVisibleSprint.push(visibleSprintsWithCapacity);
				}

				return streamsContainVisibleSprint;
			},
			[],
		);

		return streamsContainVisibleSprint;
	};

/* Put Kanban issues into iteration buckets.
Each issues could potentially end up in a several buckets as it could span several iterations.
Bucket is represented by iteration start timestamp.
Assumptions:
* all iterations are of fixed length, the same for each team, and are lined up without gaps
* issues with only either start or end date don't really belong to any iteration
*/
export const getIssuesByKanbanIterationPure = (
	issues: Issue[],
	mode: Mode,
): {
	[key: string]: Issue[];
} => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const issuesByKanbanIteration: Record<string, any> = {};
	for (const issue of issues) {
		const { start, end } = getIssueDates(issue, mode);
		if (isDefined(start) && isDefined(end)) {
			let iterationStart = startOfUtcWeek(start).getTime();
			while (iterationStart < end) {
				if (!issuesByKanbanIteration[iterationStart]) issuesByKanbanIteration[iterationStart] = [];
				issuesByKanbanIteration[iterationStart].push(issue);
				iterationStart = startOfUtcDay(iterationStart + KANBAN_ITERATION_LENGTH);
			}
		}
	}
	return issuesByKanbanIteration;
};

export const getIssuesBySprintPure = (
	issues: Issue[],
): {
	[key: string]: Issue[];
} =>
	groupBy(
		(x) => x.sprint || '',
		issues.filter((x) => x.sprint),
	);

export const getIssuesBySprintOptimizedPure = (issues: Issue[], solution: Solution) => {
	const getAssignedSprint = makeAssignedSprintGetter(solution);
	const getIssueSprint = ({ level, sprint, assignments }: Issue) => {
		if (level > EPIC_LEVEL) return sprint;
		const sprintAssignments = assignments.filter(getAssignedSprint);
		if (sprintAssignments.length === 1) {
			const assignedSprint = getAssignedSprint(sprintAssignments[0]);
			// https://bulldog.internal.atlassian.com/browse/JPOS-3354 fix this bugs, when optimization changes the sprint value  to a sprint which id is undefined
			return assignedSprint && assignedSprint.sprintId;
		}
		return sprint;
	};
	const getIssueTeam = ({ team, assignments }: Issue) => {
		const teamAssignment = assignments.find(getAssignedSprint) || assignments.find((x) => x.team);
		return (teamAssignment && teamAssignment.team) || team;
	};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return groupBy<any, any>(
		(x) => x.sprint || '',
		filterMap(
			(issue) => !!getIssueSprint(issue),
			(issue) => ({ ...issue, sprint: getIssueSprint(issue), team: getIssueTeam(issue) }),
			issues,
		),
	);
};

export type SprintStreamsForTeamsContext = {
	defaultIterationLength: number | null | undefined;
	defaultTeamVelocity: number;
	issueStatusById: IssueStatusesById;
	issuesByKanbanIteration: {
		[key: string]: Issue[];
	};
	issuesBySprint: {
		[key: string]: Issue[];
	};
	mode: Mode;
	planningUnit: string;
	sprintsForTeam: {
		[key: string]: Sprint[];
	};
	timelineRange: TimelineRange;
	weeklyCapacityByTeam: {
		[key: string]: number;
	};
	issues: Issue[];
	issueTypeById: Record<number, IssueType>;
	issueSources: IssueSource[];
	issueLexoRankAdjustments: Adjustments;
	issuesByTeam: {
		[key: string]: Issue[];
	};
	plannedCapacities: PlannedCapacities;
	zoomLevel?: ZoomLevel | undefined;
	filteredSprints: Set<string>;
};

type GetSprintStreamsForTeamsFn<R> = (
	sprintsAreShown: boolean,
	teams: Team[],
	context: SprintStreamsForTeamsContext,
) => R;

export const getSprintStreamsForTeamsPure: GetSprintStreamsForTeamsFn<SprintStreamsForTeams> = (
	sprintsAreShown,
	teams,
	context,
) =>
	sprintsAreShown
		? R.map(
				getSprintStreamsForTeam(context),
				indexBy((x) => x.id, teams),
			)
		: {};

type SprintsStreamsGetter = (arg1: TimelineRange, arg2: Team) => SprintStream[];
export const getSprintsStreamsGetterPure: GetSprintStreamsForTeamsFn<SprintsStreamsGetter> =
	(sprintsAreShown, _, context) => (timelineRange: TimelineRange, team: Team) => {
		const contextWithUpdatedTimelineRange = { ...context, timelineRange };
		return sprintsAreShown ? getSprintStreamsForTeam(contextWithUpdatedTimelineRange)(team) : [];
	};

const getAssignedStartAndEndDateForFutureSprint = (
	streamsUnitsForTeams: SprintStreamsUnitsForTeams,
): {
	[key: string]: FutureSprintWithStartandEndDate[];
} => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const futureSprintsWithStartandEndDate: Record<string, any> = {};
	R.forEachObjIndexed((streams, teamId) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const allFutureSprint = streams.reduce<Array<any>>(
			// eslint-disable-next-line @typescript-eslint/no-shadow
			(allFutureSprint, stream, index) =>
				allFutureSprint.concat(
					stream
						.filter(
							(sprint) =>
								sprint.state === SPRINT_STATES.FUTURE &&
								sprint.id !== 'GAP' &&
								sprint.id !== 'FUTURE',
						)
						.map((sprint) => ({ ...sprint, streamIndex: index })),
				),
			[],
		);
		allFutureSprint.forEach((sprint) => {
			const { id, endDate, startDate, streamIndex } = sprint;
			if (R.isNil(futureSprintsWithStartandEndDate[id])) {
				futureSprintsWithStartandEndDate[id] = [];
			}
			futureSprintsWithStartandEndDate[id].push({
				endDate,
				startDate,
				teamId,
				streamIndex,
			});
		});
	}, streamsUnitsForTeams);
	return futureSprintsWithStartandEndDate;
};

export const getSprintIdsWithDatesIsInconsistentPure = (
	streamsUnitsForTeams: SprintStreamsUnitsForTeams,
) => {
	const futureSprintsWithStartAndEndDate =
		getAssignedStartAndEndDateForFutureSprint(streamsUnitsForTeams);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const sprintAreInconsistent: Array<any> = [];
	R.forEachObjIndexed((dateArray, sprintId) => {
		let isTimeRangeDifferentInTeams = false;
		for (let i = 0, len = dateArray.length; i < len - 1; i++) {
			if (
				!(
					R.eqProps('startDate', dateArray[i], dateArray[i + 1]) &&
					R.eqProps('endDate', dateArray[i], dateArray[i + 1])
				)
			) {
				isTimeRangeDifferentInTeams = true;
				break;
			}
		}
		if (isTimeRangeDifferentInTeams) {
			sprintAreInconsistent.push(sprintId);
		}
	}, futureSprintsWithStartAndEndDate);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return [...new Set(sprintAreInconsistent)] as string[];
};

export const getSprintStreamsUnitsForTeamsPure = (
	streamsForTeams: SprintStreamsForTeams,
	timelineRange: TimelineRange,
): SprintStreamsUnitsForTeams => {
	const omitGaps = !Number.isFinite(timelineRange.start * timelineRange.end);
	return R.map(
		(streams) => streams.map((sprints) => getSprintUnits(sprints, timelineRange, omitGaps)),
		streamsForTeams,
	);
};

export const getSprintStreamsUnitsGetterPure =
	(
		getSprintStreams: (arg1: TimelineRange, arg2: Team) => SprintStream[],
		zoomLevel?: ZoomLevel,
	): SprintStreamsUnitsGetter =>
	(timelineRange, team) => {
		const omitGaps = zoomLevel !== undefined;
		return getSprintStreams(timelineRange, team).map((sprints) =>
			getSprintUnits(sprints, timelineRange, omitGaps),
		);
	};

export const getSprintsWithFutureDatesPure = (
	sprintsByTeam: {
		[key: string]: Sprint[];
	},
	teams: Team[],
	issueSources: IssueSource[],
	sprints: Sprint[],
	defaultIterationLength?: number | null,
): SprintsWithFutureDates => {
	const result: SprintsWithFutureDates = {
		team: {},
		sprint: {},
	};

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const addDates = (sprints: Sprint[], sprintLengthInMillis: number) => {
		const [futureSprints, pastAndActiveSprints] = R.partition(
			(x) => x.state === SPRINT_STATES.FUTURE,
			sprints,
		);

		const pastAndActiveSprintsSortedByDate = getPastAndActiveSprintsSortedByDate(
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			pastAndActiveSprints as TimelineSprint[],
		);

		const lastRealSprint = R.last(pastAndActiveSprintsSortedByDate);
		const lastRealEndDate = (lastRealSprint && lastRealSprint.endDate) || 0;

		const futureSprintsWithDates = addDatesToFutureSprints(
			futureSprints,
			lastRealEndDate,
			sprintLengthInMillis,
		);

		return [...pastAndActiveSprintsSortedByDate, ...futureSprintsWithDates] as const;
	};

	for (const team of teams) {
		const sprintsForTeam = sprintsByTeam[team.id] || [];
		const sprintLengthInMillis = (team.iterationLength || defaultIterationLength || 0) * ONE_WEEK;
		const futureSprintsForTeam = addDates(sprintsForTeam, sprintLengthInMillis);
		if (futureSprintsForTeam.length > 0) {
			result.team[team.id] = [...futureSprintsForTeam];
		}
	}

	issueSources
		.filter((issueSource) => issueSource.type === ISSUE_SOURCE_TYPES.BOARD)
		.forEach((issueSource) => {
			const sprintLengthInMillis = (defaultIterationLength || 0) * ONE_WEEK;
			const sprintForIssueSource = filterAndSortSprints(issueSource.sprintIds || [], sprints);
			const futureSprintsForIssueSource = addDates(
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				sprintForIssueSource as Sprint[],
				sprintLengthInMillis,
			);
			if (futureSprintsForIssueSource.length > 0) {
				// Future sprints are calculated per issue source but the map is indexed by sprintIds from those issue sources
				// because it makes lookup easier by sprint id when consuming
				(issueSource.sprintIds || []).forEach((sprintId) => {
					if (!result.sprint[sprintId]) {
						result.sprint[sprintId] = [...futureSprintsForIssueSource];
					}
				});
			}
		});

	return result;
};
const sortDateAsc = (firstDate: number, secondDate: number): number => firstDate - secondDate;
const sortDateDsc = (firstDate: number, secondDate: number): number => secondDate - firstDate;

export const getDateFromSprints = (
	sprints: TimelineSprint[],
	prop: 'startDate' | 'endDate' | 'rawStartDate' | 'rawEndDate',
): number | undefined => {
	const dates = sprints.map((sprint) => sprint[prop] ?? undefined);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const datesWithoutNulls: number[] = dates.filter((date) => date !== undefined) as number[];
	const sortFn = prop === 'startDate' || prop === 'rawStartDate' ? sortDateAsc : sortDateDsc;
	const sortedArray = R.sort(sortFn)(datesWithoutNulls);
	return sortedArray[0];
};

type TargetDatesFromSprints = Record<
	'startDate' | 'endDate' | 'rawStartDate' | 'rawEndDate',
	number
>;

export const getTargetDatesFromSprints = (
	issue: Issue | ScopeIssue,
	sprints: TimelineSprint[],
	issueStatusById?: IssueStatusesById,
): TargetDatesFromSprints => {
	const sprintsById = indexBy(R.prop('id'), sprints);
	const mapSprints = R.compose(
		R.filter(isDefined),
		R.map((id: string) => sprintsById[id]),
	);

	const statusCategory =
		issueStatusById &&
		R.path<(typeof issueStatusById)[string]['categoryId']>(
			[issue.status || '', 'categoryId'],
			issueStatusById,
		);

	if (!issue.sprint && (statusCategory === TODO || statusCategory === INPROGRESS)) {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const sprints = mapSprints(issue.completedSprints || []);
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return {
			startDate: getDateFromSprints(sprints, 'startDate'),
			endDate: getDateFromSprints(sprints, 'endDate'),
			rawStartDate: getDateFromSprints(sprints, 'rawStartDate'),
			rawEndDate: getDateFromSprints(sprints, 'rawEndDate'),
		} as TargetDatesFromSprints;
	}

	const allSprintsIds: string[] = [
		...(issue.completedSprints || []),
		...(issue.sprint ? [issue.sprint] : []),
	];
	const mappedSprints = mapSprints(allSprintsIds);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return {
		startDate: getDateFromSprints(mappedSprints, 'startDate'),
		endDate: getDateFromSprints(mappedSprints, 'endDate'),
		rawStartDate: getDateFromSprints(mappedSprints, 'rawStartDate'),
		rawEndDate: getDateFromSprints(mappedSprints, 'rawEndDate'),
	} as TargetDatesFromSprints;
};

export const getFutureSprintGroups = (
	teamOfIssue: string | null | undefined,
	sprintOfIssue: string | null | undefined,
	sprintsByIssueSources: {
		[issueSourceId: string]: Sprint[];
	},
	sprintsByTeam: {
		[teamId: string]: Sprint[];
	},
	additionalTeamsById: {
		[teamId: string]: Team;
	},
	teams: Team[],
	teamsById: {
		[teamId: string]: Team;
	},
	otherSprintsLabel: string,
): Map<string, Sprint[]> => {
	const otherSprints: Array<Sprint> = [];
	const result = Object.entries(sprintsByIssueSources).reduce(
		(previous, [issueSource, sprints]) => {
			const currentSprintBelongsToCurrentTeam = (
				(teamOfIssue && sprintsByTeam[teamOfIssue]) ||
				[]
			).some((sprint) => sprint.id === sprintOfIssue);

			const isExternalTeam =
				teamOfIssue && additionalTeamsById && isDefined(additionalTeamsById[teamOfIssue]);

			const issueSourceBelongsToATeam = teams.some(
				(team) => team.issueSource === Number(issueSource),
			);

			if (!issueSourceBelongsToATeam) {
				otherSprints.push(...sprints.filter((sprint) => sprint.state !== SPRINT_STATES.CLOSED));
			}

			const teamsOfIssueSource = teams.filter((team) => {
				if (team.issueSource !== Number(issueSource)) {
					return false;
				}

				if (isExternalTeam) {
					return true; // if an team is external, show all the sprints
				}

				if (
					teamOfIssue &&
					teamsById &&
					teamsById[teamOfIssue] &&
					!teamsById[teamOfIssue].issueSource
				) {
					return true;
				}

				if (sprintOfIssue && teamOfIssue) {
					if (currentSprintBelongsToCurrentTeam) {
						return team.id === teamOfIssue;
					}

					return true; // if team and sprint is selected but the sprint doesn't belong to the team, show all the  sprints
				}
				if (teamOfIssue) {
					return team.id === teamOfIssue; // if team is selected, show only sprints of the team
				}

				return true;
			});

			if (teamsOfIssueSource.length > 0) {
				const label = teamsOfIssueSource.map((team) => team.title).join(', ');
				const futureSprints = sprints.filter((sprint) => sprint.state !== SPRINT_STATES.CLOSED);
				previous.set(label, futureSprints);
			}

			return previous;
		},
		new Map(),
	);

	if (otherSprints.length) {
		return result.set(otherSprintsLabel, otherSprints);
	}

	return result;
};

export const getSprintsCapacityOnTimelinePure = (
	teams: Team[],
	{
		defaultIterationLength,
		defaultTeamVelocity,
		issueStatusById,
		issuesBySprint,
		mode,
		planningUnit,
		sprintsForTeam,
		timelineRange,
		weeklyCapacityByTeam,
		issueSources = [], // Default to [] as we've seen "cannot read property 'find' of undefined" errors
		issueLexoRankAdjustments,
		issuesByTeam,
		plannedCapacities,
	}: {
		defaultIterationLength: number | null | undefined;
		defaultTeamVelocity: number;
		issueStatusById: IssueStatusesById;
		issuesByKanbanIteration: {
			[key: string]: Issue[];
		};
		issuesBySprint: {
			[key: string]: Issue[];
		};
		mode: Mode;
		planningUnit: string;
		sprintsForTeam: {
			[key: string]: Sprint[];
		};
		timelineRange: TimelineRange;
		weeklyCapacityByTeam: {
			[key: string]: number;
		};
		issueSources: IssueSource[];
		issueLexoRankAdjustments: Adjustments;
		issuesByTeam: {
			[key: string]: Issue[];
		};
		plannedCapacities: PlannedCapacities;
	},
): {
	[key: string]: TimelineSprint[];
} => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result: Record<string, any> = {};
	for (const team of teams) {
		if (team.schedulingMode !== SCHEDULE_MODE.kanban) {
			const alignedSprints = getSprintsInStream(team, sprintsForTeam, defaultIterationLength);

			if (isDefined(alignedSprints)) {
				const { value: boardId } =
					issueSources.find((source) => source.id === team.issueSource) || {};

				const sprintsWithCapacity = addSprintCapacityToSprintStream({
					defaultTeamVelocity,
					issuesBySprint,
					issueStatusById,
					planningUnit,
					stream: alignedSprints,
					team,
					weeklyCapacityByTeam,
					boardId,
					mode,
					issueLexoRankAdjustments,
					issuesByTeam,
					plannedCapacities,
				});

				const sprintsWithCapacityById = indexBy((x) => x.id, sprintsWithCapacity);
				result[team.id] = R.map(
					(sprintWithCapacity) => getSprintUnits([sprintWithCapacity], timelineRange),
					sprintsWithCapacityById,
				);
			}
		}
	}

	return result;
};

/**
 * the unit of capacity is seconds if the plan is estimated by days or hours
 */
export const convertCapacityByPlanningUnit = ({
	capacity,
	planningUnit,
	workingHours,
}: {
	capacity: number;
	planningUnit: string;
	workingHours: number;
}): number => {
	const hourInSeconds = ONE_HOUR / 1000;
	const { days, hours } = PlanningUnits;
	switch (planningUnit) {
		case days:
			return round(capacity / (hourInSeconds * workingHours), 2);
		case hours:
			return round(capacity / hourInSeconds, 2);
		default:
			return round(capacity, 2);
	}
};

export const convertCapacityFromPlanningUnitToSeconds = ({
	capacity,
	planningUnit,
	workingHours,
}: {
	capacity: number;
	planningUnit: string;
	workingHours: number;
}): number => {
	const hourInSeconds = ONE_HOUR / 1000;
	const { days, hours } = PlanningUnits;
	switch (planningUnit) {
		case days:
			return capacity * hourInSeconds * workingHours;
		case hours:
			return capacity * hourInSeconds;
		default:
			return capacity;
	}
};

const calculateDefaultSprintCapacity = (
	sprint: TimelineSprint,
	team: Team,
	planningUnit: string,
	weeklyCapacity: number,
	defaultTeamVelocity: number,
) => {
	let availableCapacity = 0;
	if (planningUnit === PlanningUnits.storyPoints) {
		availableCapacity = team.velocity || team.velocity === 0 ? team.velocity : defaultTeamVelocity;
	} else {
		// WeeklyCapacity is in hours, but we should take weekends into account.
		// For example, if the sprintA.startDate is 29/7/2019(Monday) and the sprintA.endDate is 2/8/2019(Friday),
		// the length of this sprintA is 5 days, there are 5 working day.
		// However, the sprintB.startDate is 30/7/2019(Tuesday) and the sprintB.endDate is 3/8/2019(Saturday), the length of this sprintB is 5 days,
		// but there are 4 working day. So the sprintA and sprintB should have different availableCapacity
		availableCapacity =
			(ONE_HOUR *
				weeklyCapacity *
				getWorkingMillionSecondsBetweenTwoDates(sprint.startDate, sprint.endDate)) /
			(5 * ONE_DAY) /
			1000;
	}
	return availableCapacity;
};

export const getPlannedCapacityChangesPure = (
	plannedCapacities: PlannedCapacities,
	originalPlannedCapacities: OriginalPlannedCapacities,
	{ team: sprintsByTeam }: SprintsWithFutureDates,
	planningUnit: PlanningUnit,
	defaultTeamVelocity: number,
	weeklyCapacityByTeam: {
		[key: string]: number;
	},
	teamsById: {
		[key: string]: Team;
	},
	metaData?: EntityMetadata | null,
): PlannedCapacityChange[] => {
	const twoLevelObjectValues = R.pipe(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		R.values as unknown as (a: OriginalPlannedCapacities) => OriginalPlannedCapacities[string],
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		R.chain(R.values) as unknown as (a: OriginalPlannedCapacities[string]) => PlannedCapacities[],
	);

	const flattenedOriginals = twoLevelObjectValues(originalPlannedCapacities);

	const applyDefaultCapacity = <T extends Partial<PlannedCapacity>>(plannedCapacity: T): T => {
		if (Number.isInteger(plannedCapacity.capacity)) {
			return plannedCapacity;
		}

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const weeklyCapacity: number | undefined = weeklyCapacityByTeam[plannedCapacity.teamId!];
		let defaultCapacity: number;

		if (plannedCapacity.schedulingMode === SCHEDULE_MODE.scrum) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const teamSprints = sprintsByTeam[plannedCapacity.teamId!] || [];
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const sprint = teamSprints.find((sprint) => sprint.id === plannedCapacity.iterationId);

			if (!sprint) {
				return plannedCapacity;
			}

			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const team = teamsById[plannedCapacity.teamId!];

			defaultCapacity = calculateDefaultSprintCapacity(
				sprint,
				team,
				planningUnit,
				weeklyCapacity,
				defaultTeamVelocity,
			);
		} else {
			defaultCapacity = (ONE_HOUR * weeklyCapacity) / 1000;
		}

		return {
			...plannedCapacity,
			capacity: defaultCapacity,
		};
	};

	const changes: PlannedCapacityChange[] = flattenedOriginals.map(
		// @ts-expect-error TS2345 - Argument of type '(original: OriginalPlannedCapacity) => { iterationId: string; teamId: string; values: PlannedCapacity; originals: PlannedCapacity; metaData: ChangeMetadata | null; }' is not assignable to parameter of type '(value: PlannedCapacities, index: number, array: PlannedCapacities[]) => { iterationId: string; teamId: string; values: PlannedCapacity; originals: PlannedCapacity; metaData: ChangeMetadata | null; }'.
		(original: OriginalPlannedCapacity) => {
			/*
                If a capacity has been deleted, there will be an original without a current value.
                So we reconstruct the current value by using the original value with a capacity
                of 0.
                Also:
                When a sprint velocity changes, the original value is set in the store before
                the new value is. So it's possible for this query to run when the new value isn't
                set yet. In this case, the reconstructed value will be used for a brief moment.
             */
			const newValue = R.path<PlannedCapacities[string][string]>(
				[original.teamId, original.iterationId],
				plannedCapacities,
			) || {
				...original,
				capacity: null,
			};

			return {
				iterationId: original.iterationId,
				teamId: original.teamId,
				values: applyDefaultCapacity<PlannedCapacity>(newValue),
				originals: applyDefaultCapacity<OriginalPlannedCapacity>(original),
				metaData: metaData && original.itemKey ? metaData[original.itemKey] : null,
			};
		},
	);

	return changes;
};

export const getSprintChangesPure = (
	plannedCapacityChanges: PlannedCapacityChange[],
	{ sprint: sprintsWithDates }: SprintsWithFutureDates,
) => {
	const velocityChangesBySprint = R.groupBy<PlannedCapacityChange>(
		R.prop('iterationId'),
		plannedCapacityChanges,
	);
	const entries = R.toPairs(velocityChangesBySprint);

	const changes: SprintChange[] = entries.map(
		([sprintId, capacityChanges]: [string, PlannedCapacityChange[]]): SprintChange => {
			const changeCount = capacityChanges.length;
			const attributeName =
				changeCount === 1 ? `teamVelocity-${capacityChanges[0].teamId}` : undefined;

			let sprintWithoutCapacities: TimelineSprint;

			if (capacityChanges[0].values.schedulingMode === SCHEDULE_MODE.scrum) {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				sprintWithoutCapacities = sprintsWithDates[sprintId].find(
					(sprint) => sprint.id === sprintId,
				)!;
			} else {
				const iterationStart = +sprintId;
				const iterationEnd = startOfUtcDay(iterationStart + KANBAN_ITERATION_LENGTH) - 1 * 1000;
				sprintWithoutCapacities = createFutureSprint(
					iterationStart,
					iterationEnd,
					SCHEDULE_MODE.kanban,
				);
				sprintWithoutCapacities.id = sprintId;
			}

			// NOTE: SprintWithVelocities extends TimelineSprint type, but it's indistinguishable to TS with an object spread
			const sprintWithNewVelocities: SprintWithVelocities = { ...sprintWithoutCapacities };

			capacityChanges.forEach((change) => {
				sprintWithNewVelocities[`teamVelocity-${change.teamId}`] = change.values;
			});

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const originals: Record<string, any> = {};
			capacityChanges.forEach((change: PlannedCapacityChange) => {
				originals[`teamVelocity-${change.teamId}`] = change.originals;
			});

			let metaData: ChangeMetadata = {
				// Default, in case we can't find metadata for any of the capacity changes
				lastChangeTimestamp: 1579059707222,
				lastChangeUser: '',
				scenarioType: SCENARIO_TYPE.UPDATED,
			};

			// Get the most recent capacity change metadata
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const metaDataObjects = capacityChanges
				.map((change) => ({
					...change.metaData,
					// The capacity may have been added, updated or removed.
					// Since this metadata will now represent the sprint itself,
					// rather than the capacity, we should always consider it updated.
					scenarioType: SCENARIO_TYPE.UPDATED,
				}))
				.filter(R.identity) as ChangeMetadata[]; // NOTE: TS doesn't know filter(R.identity) will remove all objects without metaData

			metaDataObjects.sort((a, b) => b.lastChangeTimestamp - a.lastChangeTimestamp);

			if (metaDataObjects.length) {
				metaData = metaDataObjects[0];
			}

			return {
				attributeName,
				changeCount,
				id: sprintId,
				warnings: [],
				category: ENTITY.SPRINT,
				details: {
					values: sprintWithNewVelocities,
					originals,
				},
				metaData,
			};
		},
	);

	return changes;
};

export const isSameYear = (startTime: number, endTime: number) => {
	const startDate = new Date(startTime);
	const endDate = new Date(endTime);
	return startDate.getUTCFullYear() === endDate.getUTCFullYear();
};

export const formatSprintUTCTime = (intl: IntlShape, startDate: number, endDate: number) => {
	const dateTimeFormatWithoutYearOptions = {
		day: '2-digit',
		month: 'short',
		hour: 'numeric',
		minute: '2-digit',
		hour12: true,
		timeZone: 'UTC',
		timeZoneName: 'short',
	};

	const dateTimeFormatWithYearOptions = { ...dateTimeFormatWithoutYearOptions, year: 'numeric' };

	const formatDateTimeString = isSameYear(startDate, endDate)
		? dateTimeFormatWithoutYearOptions
		: dateTimeFormatWithYearOptions;

	return {
		startDate: formatTimestampWithIntl(intl, startDate, formatDateTimeString),
		endDate: formatTimestampWithIntl(intl, endDate, formatDateTimeString),
	};
};

export const isSameMonth = (startTime: number, endTime: number) => {
	const startDate = new Date(startTime);
	const endDate = new Date(endTime);
	return startDate.getUTCMonth() === endDate.getUTCMonth();
};

export const formatDateRange = (intl: IntlShape, startDate: number, endDate: number) => {
	if (isSameMonth(startDate, endDate)) {
		const startDateOfMonth = new Date(startDate).getUTCDate();
		return `${startDateOfMonth} \u2013 ${formatTimestampWithIntl(intl, endDate, dateMonthFormat)}`;
	}
	const formatDateString = isSameYear(startDate, endDate) ? dateMonthFormat : longDateFormat;
	return `${formatTimestampWithIntl(
		intl,
		startDate,
		formatDateString,
	)} \u2013 ${formatTimestampWithIntl(intl, endDate, formatDateString)} `;
};

export const getLozengeContent =
	(intl: IntlShape) =>
	({
		sprint,
		isFutureProjectedSprint,
	}: {
		sprint: SprintUnit | Sprint;
		isFutureProjectedSprint: boolean;
	}) => {
		if (isFutureProjectedSprint) return intl.formatMessage(commonMessages.futureProjectedSprint);

		switch (sprint.state) {
			case SPRINT_STATES.CLOSED:
				return intl.formatMessage(commonMessages.completedSprint);
			case SPRINT_STATES.ACTIVE:
				return intl.formatMessage(commonMessages.activeSprint);
			case SPRINT_STATES.FUTURE:
				return intl.formatMessage(commonMessages.futureSprint);
			default:
				return '';
		}
	};

export const getLozengeAppearance = (state: string) => {
	switch (state) {
		case SPRINT_STATES.CLOSED:
			return 'success';
		case SPRINT_STATES.ACTIVE:
			return 'inprogress';
		case SPRINT_STATES.FUTURE:
			return 'default';
		default:
			return 'default';
	}
};

export const getSprintStateLozengeContent = (intl: IntlShape) => (state: string) => {
	switch (state) {
		case SPRINT_STATES.CLOSED:
			return intl.formatMessage(commonMessages.completedSprintState);
		case SPRINT_STATES.ACTIVE:
			return intl.formatMessage(commonMessages.activeSprintState);
		case SPRINT_STATES.FUTURE:
			return intl.formatMessage(commonMessages.futureSprintState);
		case EXTERNAL_SPRINT:
			return intl.formatMessage(commonMessages.externalSprintState);
		default:
			return '';
	}
};

export const getSprintStateLabel = (intl: IntlShape) => (state: string) => {
	switch (state) {
		case SPRINT_STATES.CLOSED:
			return intl.formatMessage(commonMessages.completedSprintsLabel);
		case SPRINT_STATES.ACTIVE:
			return intl.formatMessage(commonMessages.activeSprintsLabel);
		case SPRINT_STATES.FUTURE:
			return intl.formatMessage(commonMessages.futureSprintsLabel);
		case EXTERNAL_SPRINT:
			return intl.formatMessage(commonMessages.externalSprintsLabel);
		default:
			return '';
	}
};
