import React, { Component } from 'react';
import * as R from 'ramda';
import { injectIntl, FormattedMessage } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import {
	DialogMenuContainer,
	DialogMenuItem,
} from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import { NO_LABEL_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/label-filter';
import type { IssueLabels } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-labels/types.tsx';
import { LABEL_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip';
import SearchField from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field';
import { FILTER_MAX_WIDTH, FILTER_WIDTH } from '../common';
import ClearFilterButton from '../common/clear-filter/index.tsx';
import FilterText from '../common/filter-text/index.tsx';
import NoMatchFound from '../common/no-match-text/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from '../common/styles.module.css';
import TriggerButton from '../common/trigger-button/index.tsx';
import filterMessages from '../messages';
import messages from './messages';
import type { Props } from './types';

// eslint-disable-next-line jira/react/no-class-components
class LabelFilter extends Component<Props> {
	constructFilterText = () => {
		const { labels, value, intl } = this.props;
		const filteredValues = labels.filter((label) => R.contains(label, value));

		return R.contains(NO_LABEL_ID, value)
			? [intl.formatMessage({ ...messages.noLabelsFilterText }), ...filteredValues].join(', ')
			: filteredValues.join(', ');
	};

	renderFilterText = () => {
		const { value } = this.props;
		const filterText = this.constructFilterText();
		return value.length > 0 ? (
			<FilterText text={filterText} />
		) : (
			<FormattedMessage {...messages.emptyPlaceholder} />
		);
	};

	ariaText = () => {
		const { value, intl } = this.props;
		const filterText = this.constructFilterText();
		// Labels, All
		return `${intl.formatMessage(filterMessages[LABEL_FILTER_ID])}, ${
			value.length > 0 ? filterText : intl.formatMessage(messages.emptyPlaceholder)
		} ${intl.formatMessage(filterMessages.selected)}`;
	};

	isLabelSelected = (label: string) => R.contains(label, this.props.value);

	filterLabelsWithSearchQuery = (label: string) =>
		label.toLowerCase().includes(this.props.searchQuery.toLowerCase());

	onLabelClick = (clickedLabel: string): void => {
		const { value, changeFilter } = this.props;
		if (this.isLabelSelected(clickedLabel)) {
			changeFilter(R.filter((label) => label !== clickedLabel, value));
		} else {
			changeFilter([...value, clickedLabel]);
		}
	};

	renderNoLabel = () => {
		const { intl } = this.props;
		const name = intl.formatMessage({ ...messages.noLabelsFilterText });
		return (
			<DialogMenuItem>
				<Checkbox
					id={NO_LABEL_ID}
					key={NO_LABEL_ID}
					isChecked={this.isLabelSelected(NO_LABEL_ID)}
					onChange={() => this.onLabelClick(NO_LABEL_ID)}
					label={
						<EllipsedWithTooltip content={name} id={NO_LABEL_ID}>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<span className={styles.checkboxLabel}>{name}</span>
						</EllipsedWithTooltip>
					}
				/>
			</DialogMenuItem>
		);
	};

	renderLabels = (searchResultLabels: IssueLabels) => (
		<>
			{searchResultLabels.map((label, index) => (
				<DialogMenuItem key={label}>
					<Checkbox
						id={`${index}`}
						key={index}
						isChecked={this.isLabelSelected(label)}
						onChange={() => this.onLabelClick(label)}
						label={
							<EllipsedWithTooltip content={label} id={index}>
								{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
								<span className={styles.checkboxLabel}>{label}</span>
							</EllipsedWithTooltip>
						}
					/>
				</DialogMenuItem>
			))}
			{searchResultLabels.length === 0 && <NoMatchFound />}
		</>
	);

	render() {
		const { intl, labels, onOpenChange, isOpen, value, clearFilter, searchQuery, onQueryChange } =
			this.props;
		const searchResultLabels = labels.filter(this.filterLabelsWithSearchQuery);

		return (
			<InlineDialog
				noPaddings
				maxWidth={FILTER_MAX_WIDTH}
				minWidth={FILTER_WIDTH}
				onClose={onOpenChange}
				isOpen={isOpen}
				content={
					<DialogMenuContainer>
						<ClearFilterButton isVisible={!!value.length} onClearClick={clearFilter} />
						<SearchField
							placeholder={intl.formatMessage(messages.searchLabelsPlaceholder)}
							searchQuery={searchQuery}
							onQueryChange={onQueryChange}
							ariaLabel={intl.formatMessage(messages.searchLabelsAriaLabel)}
						/>
						{!!searchResultLabels.length && this.renderNoLabel()}
						{this.renderLabels(searchResultLabels)}
					</DialogMenuContainer>
				}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.label-filter"
			>
				<TriggerButton
					isOpen={isOpen}
					onOpenChange={onOpenChange}
					testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.label-filter.trigger-btn"
					triggerButtonText={this.renderFilterText()}
					ariaLabel={this.ariaText()}
				/>
			</InlineDialog>
		);
	}
}

export default injectIntl(LabelFilter);
