import type { ReportStatus } from './types';

export const SET_REPORT_STATUS =
	'state.ui.main.tabs.dependencies.report-state.SET_REPORT_STATUS' as const;

export type SetReportStatusAction = {
	type: typeof SET_REPORT_STATUS;
	payload: ReportStatus;
};

export const setReportStatus = (status: ReportStatus): SetReportStatusAction => ({
	type: SET_REPORT_STATUS,
	payload: status,
});
