import * as R from 'ramda';
import { fg } from '@atlassian/jira-feature-gating';
import { getMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app';
import { getEnrichedCrossProjectVersions } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/cross-project-versions';
import { getCrossProjectReleaseFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/cross-project-release-filter';
import {
	getProjectFilter,
	getFilteredProjects,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/project-filter';
import { getReleaseFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/release-filter';
import { getReleaseBarState } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/release-bar';
import {
	getEnrichedVersions,
	getSolutionVersionsById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions';
import type { EnrichedVersion } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/types';
import type { EnrichedCrossProjectVersion } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/cross-project-versions/types.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import type { Version } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/versions/types.tsx';
import type {
	ReleaseFilter,
	CrossProjectReleaseFilter,
	ProjectFilter,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { OwnProps, StateProps } from './types';
import { isProject } from './utils';

export const getVersionsPure = (
	enrichedVersions: EnrichedVersion[],
	versionFilter: ReleaseFilter,
	projectFilter: ProjectFilter,
): EnrichedVersion[] => {
	const { value, versionsFromCrossProjectReleaseFilterValue } = versionFilter;
	const { value: projectValue } = projectFilter;
	const filterValue = isDefined(versionsFromCrossProjectReleaseFilterValue)
		? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			[...value, ...(versionsFromCrossProjectReleaseFilterValue as string[])]
		: value;

	return enrichedVersions.filter(
		({ id, projects }) =>
			(filterValue.length === 0 || R.contains(id, filterValue)) &&
			(projectValue.length === 0 ||
				projects.some(
					fg('plans-proj-undefined-fix')
						? (project) => {
								if (!isProject(project)) return false;
								return projectValue.includes(project.id);
							}
						: (project) => {
								// Defend against rare occurances of corrupt data
								// Cannot destructure property 'id' of 'undefined' as it is undefined
								if (isProject(project) && project.id === null) {
									return false;
								}
								return isProject(project) && projectValue.includes(project.id);
							},
				)),
	);
};

export const getCrossProjectVersionsPure = (
	enrichedCrossProjectVersions: EnrichedCrossProjectVersion[],
	versionFilter: ReleaseFilter,
	crossProjectVersionFilter: CrossProjectReleaseFilter,
	filteredProjects: Project[],
): EnrichedCrossProjectVersion[] => {
	const { value, versionsFromCrossProjectReleaseFilterValue } = versionFilter;
	const versionFilterValue = isDefined(versionsFromCrossProjectReleaseFilterValue)
		? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			[...value, ...(versionsFromCrossProjectReleaseFilterValue as string[])]
		: value;
	const filteredByProjectVersionsSet = new Set(
		R.flatten(R.map(R.prop('versions'), filteredProjects)),
	);

	const filterByProject = (versions: Version[]) =>
		versions.some(({ id }) => filteredByProjectVersionsSet.has(id));

	return enrichedCrossProjectVersions.filter(({ id, versions }) => {
		if (crossProjectVersionFilter.value.length > 0 || versionFilterValue.length > 0) {
			return R.contains(id, crossProjectVersionFilter.value) && filterByProject(versions);
		}
		return filterByProject(versions);
	});
};

const getCrossProjectVersions = createSelector(
	[
		getEnrichedCrossProjectVersions,
		getReleaseFilter,
		getCrossProjectReleaseFilter,
		getFilteredProjects,
	],
	getCrossProjectVersionsPure,
);

const getVersions = createSelector(
	[getEnrichedVersions, getReleaseFilter, getProjectFilter],
	getVersionsPure,
);

export default createStructuredSelector<State, OwnProps, StateProps>({
	mode: getMode,
	versions: getVersions,
	crossProjectVersions: getCrossProjectVersions,
	releaseBarState: getReleaseBarState,
	solutionVersionsById: getSolutionVersionsById,
});
