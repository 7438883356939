import * as R from 'ramda';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import {
	SET_PREVIEW_ISSUES,
	MOVE_ISSUE_AND_DESCENDENTS,
	SET_ISSUES_START,
	SET_ISSUES_END,
	CLEAR_PREVIEW,
	type SetPreviewIssuesAction,
	type MoveIssueAndDescendentsAction,
	type SetIssuesStartAction,
	type SetIssuesEndAction,
	type ClearPreviewAction,
} from './actions';
import type { TimelinePreview, IssuePreview } from './types';

type Action =
	| SetPreviewIssuesAction
	| MoveIssueAndDescendentsAction
	| SetIssuesStartAction
	| SetIssuesEndAction
	| ClearPreviewAction;

function update(
	oldState: TimelinePreview,
	issueIds: string[],
	operation: (issue: IssuePreview, issueId: string) => IssuePreview,
) {
	const op = ([issueId, issue]: [string, IssuePreview]): [string, IssuePreview] => [
		issueId,
		R.contains(issueId, issueIds) ? operation(issue, issueId) : issue,
	];

	const previews = R.fromPairs(R.map(op, R.toPairs(oldState.previews)));

	return {
		...oldState,
		previews,
	};
}

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: TimelinePreview | null = null,
	action: Action,
): TimelinePreview | null | undefined => {
	if (action.type === SET_PREVIEW_ISSUES) {
		const { activeIssueId, issueAndAncestors, strictDescendents } = action.payload;
		const previews = R.fromPairs(
			R.concat(R.toPairs(issueAndAncestors), R.toPairs(strictDescendents)),
		);

		const strictAncestorIds: string[] = R.reject(
			R.identical(activeIssueId),
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			R.keys(issueAndAncestors) as string[],
		);

		return {
			activeIssueId,
			strictAncestorIds,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			strictDescendentIds: R.keys(strictDescendents) as string[],
			previews,
		};
	}
	if (!state) return state;

	switch (action.type) {
		case MOVE_ISSUE_AND_DESCENDENTS: {
			const { delta, targetStart, targetEnd } = action.payload;
			const { strictDescendentIds, activeIssueId } = state;
			return update(state, [...strictDescendentIds, activeIssueId], (issue, issueId) => {
				const shift = (num: number | null | undefined) =>
					isDefined(num) ? delta + num : undefined;
				const rest = { ...issue };
				// The one we are moving will always be set
				if (issueId === activeIssueId) delete rest.inferred;
				return {
					...rest,
					baselineStart: shift(issueId === activeIssueId ? targetStart : issue.baselineStart),
					baselineEnd: shift(issueId === activeIssueId ? targetEnd : issue.baselineEnd),
				};
			});
		}
		case SET_ISSUES_START: {
			const { issueIds, baselineStart } = action.payload;
			return update(state, issueIds, ({ baselineEnd, shouldSync, inferred }) => {
				const newInferred = inferred;
				if (newInferred) delete newInferred.baselineStart;
				return {
					inferred: newInferred,
					baselineStart,
					baselineEnd,
					shouldSync,
				};
			});
		}
		case SET_ISSUES_END: {
			const { issueIds, baselineEnd } = action.payload;
			return update(state, issueIds, ({ baselineStart, shouldSync, inferred }) => {
				const newInferred = inferred;
				if (newInferred) delete newInferred.baselineEnd;
				return {
					inferred: newInferred,
					baselineStart,
					baselineEnd,
					shouldSync,
				};
			});
		}
		case CLEAR_PREVIEW:
			return null;
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
