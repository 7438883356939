import React, { forwardRef, useRef, useEffect, type Ref } from 'react';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import StandardButton from '@atlaskit/button/standard-button';
import AddIcon from '@atlaskit/icon/glyph/editor/add';
import { useIntl } from '@atlassian/jira-intl';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import type { MenuTriggerProps } from '../advanced-fields-menu/types';
import { SCROLL_OPTIONS } from './constants';

type Props = {
	numberOfVisibleFields: number;
} & Omit<MenuTriggerProps, 'ref'>;

/* This element sits at the end of an overflow container where content can be dynamically added before it.
 * As a result, it can be pushed outside the viewport. Since the focus does not move, the browser will not
 * scroll to its new position automatically, so we have to initiate the scrolling ourselves.
 */
const AdvancedFieldsTrigger = forwardRef<HTMLElement, Props>(
	({ numberOfVisibleFields, isSelected, ...menuTriggerProps }: Props, ref: Ref<HTMLElement>) => {
		const { formatMessage } = useIntl();
		const wrapperRef = useRef<HTMLDivElement | null>(null);
		const previousNumberOfVisibleFields = usePreviousWithInitial(numberOfVisibleFields);

		useEffect(() => {
			const couldExceedViewport = numberOfVisibleFields > previousNumberOfVisibleFields;
			if (wrapperRef.current && isSelected && couldExceedViewport) {
				scrollIntoViewIfNeeded(wrapperRef.current, SCROLL_OPTIONS);
			}
		}, [numberOfVisibleFields, previousNumberOfVisibleFields, isSelected]);

		/* Setting a fixed width for the scrollIntoView anchor provides:
		 * - A small gap so that the button isn't aligned at the edge of the screen
		 * - A consistent jump point, regardless of how wide the header cell is (see SCROLL_OPTIONS)
		 */
		return (
			<div style={{ width: numberOfVisibleFields > 1 ? '56px' : undefined }} ref={wrapperRef}>
				<StandardButton
					ref={ref}
					{...menuTriggerProps}
					isSelected={isSelected}
					appearance="default"
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-trigger"
					iconAfter={<AddIcon label={formatMessage(commonMessages.fields)} />}
				/>
			</div>
		);
	},
);

export default AdvancedFieldsTrigger;
