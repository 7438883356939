import type { Hierarchy } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';

export const RESET = 'state.domain.hierarchy.RESET' as const;

export type ResetActionPayload = Hierarchy;

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export const reset = (payload: ResetActionPayload) => ({
	type: RESET,
	payload,
});
