import React from 'react';
import { DropdownItemGroup as DropMenuItemGroup } from '@atlaskit/dropdown-menu';
import { Box } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { FormattedMessage, injectIntl } from '@atlassian/jira-intl';
import { DropMenuItem } from '@atlassian/jira-portfolio-3-common/src/drop-menu/index.tsx';
import {
	RANK_BEFORE,
	RANK_AFTER,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import messages from './messages';
import type { PropsWithInjected as Props } from './types';

function RankIssueMenuGroup({ intl, bulkUpdateRank, issueId, isSelected }: Props) {
	const rankAboveEl = (
		<Box
			as="span"
			testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-above-option"
		>
			<FormattedMessage {...messages.rankAllSelectedIssuesAbove} />
		</Box>
	);
	const rankBelowEl = (
		<Box
			as="span"
			testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-below-option"
		>
			<FormattedMessage {...messages.rankAllSelectedIssuesBelow} />
		</Box>
	);
	return (
		<DropMenuItemGroup>
			<DropMenuItem
				onClick={() => {
					bulkUpdateRank({ operationType: RANK_BEFORE, anchor: issueId });
				}}
				isDisabled={isSelected}
			>
				{isSelected ? (
					<Tooltip content={intl.formatMessage(messages.noRankItself)}>{rankAboveEl}</Tooltip>
				) : (
					rankAboveEl
				)}
			</DropMenuItem>
			<DropMenuItem
				onClick={() => {
					bulkUpdateRank({ operationType: RANK_AFTER, anchor: issueId });
				}}
				isDisabled={isSelected}
			>
				{isSelected ? (
					<Tooltip content={intl.formatMessage(messages.noRankItself)}>{rankBelowEl}</Tooltip>
				) : (
					rankBelowEl
				)}
			</DropMenuItem>
		</DropMenuItemGroup>
	);
}

export default injectIntl(RankIssueMenuGroup);
