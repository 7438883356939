import React, { forwardRef } from 'react';
import isNil from 'lodash/isNil';
import Avatar from '@atlaskit/avatar';
import { DropdownItem, type DropdownItemProps } from '@atlaskit/dropdown-menu';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import ConditionalTooltip from '../conditional-tooltip';
import type { Option } from '../options-providers/types';

type OwnProps = {
	option: Option<unknown>;
};

type Props = OwnProps & Omit<DropdownItemProps, 'children'>;

export const OptionItem = forwardRef<HTMLElement, Props>(({ option, ...rest }: Props, ref) => {
	const { name, description, url, urlType, isDisabled, disabledTooltip } = option;

	const getElemBefore = () => {
		if (isNil(url)) return;

		if (urlType === 'avatar') return <Avatar size="small" src={url} />;

		return <AsyncIcon url={url} width={24} height={24} />;
	};

	return (
		<ConditionalTooltip isDisabled={!!disabledTooltip && isDisabled} content={disabledTooltip}>
			<DropdownItem
				elemBefore={getElemBefore()}
				description={description}
				isDisabled={isDisabled}
				{...rest}
				ref={ref}
			>
				{name}
			</DropdownItem>
		</ConditionalTooltip>
	);
});
