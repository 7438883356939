import React, { Component, type ComponentType } from 'react';
import type { Props, State } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function withFilterItem(WrappedComponent: ComponentType<any>) {
	// eslint-disable-next-line jira/react/no-class-components
	return class FilterItem extends Component<Props, State> {
		state = {
			searchQuery: '',
			isOpen: !!this.props.isInitiallyOpen,
		};

		componentDidUpdate(_: Props, prevState: State) {
			const { parentScrollContainer } = this.props;
			if (!parentScrollContainer) {
				return;
			}
			// Listen to "scroll" event only when dropdown menu is open
			if (this.state.isOpen && !prevState.isOpen) {
				parentScrollContainer.addEventListener('scroll', this.onParentScroll);
			} else if (!this.state.isOpen && prevState.isOpen) {
				parentScrollContainer.removeEventListener('scroll', this.onParentScroll);
			}
		}

		componentWillUnmount() {
			if (this.props.parentScrollContainer) {
				this.props.parentScrollContainer.removeEventListener('scroll', this.onParentScroll);
			}
		}

		onParentScroll = () => {
			if (this.state.isOpen) {
				this.setState({ isOpen: false });
			}
		};

		onOpenChange = ({ isOpen }: { isOpen: boolean }) => {
			this.setState({ isOpen });
		};

		onQueryChange = (searchQuery: string) => {
			this.setState({ searchQuery });
		};

		render() {
			const { parentScrollContainer, ...restProps } = this.props;
			return (
				<WrappedComponent
					{...this.state}
					{...restProps}
					onOpenChange={this.onOpenChange}
					onQueryChange={this.onQueryChange}
				/>
			);
		}
	};
}

export type FilterItem = ReturnType<typeof withFilterItem>;
