import React, { useCallback, useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import isNil from 'lodash/isNil';
import { DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { MenuGroup } from '@atlaskit/menu';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types';
import { getGroupKey } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/util.tsx';
import type { IssueType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-types/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import SuggestChildIssues from '../ai-suggest-child-issues';
import ChildIcon from './child-icon';
import IssueTypeItem from './issue-type-item';
import messages from './messages';
import type { Props } from './types';

const CreateIssue = ({
	issue,
	startInlineCreate,
	issueTypesByProjectId,
	hierarchyRangeFilter,
	groupCombination,
	isDisabled = false,
	setGlobalCreateIssue,
	setIssueTypeIdForHierarchy,
	toggleAiWorkBreakdownPopup,
}: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const triggerInlineCreateIssueAnalytics = useCallback(
		(issueTypeId: number) => {
			const analyticsKey = PRODUCT_ANALYTICS_EVENT_NAMES.CREATED_ISSUE_INLINE;
			const [actionSubject, action] = analyticsKey.split(' ');

			fireUIAnalytics(createAnalyticsEvent({ action, actionSubject }), analyticsKey, {
				issueType: issueTypeId,
			});
		},
		[createAnalyticsEvent],
	);

	const getGroup = useCallback(
		() =>
			groupCombination && Object.keys(groupCombination).length
				? getGroupKey(groupCombination)
				: issue.group,
		[groupCombination, issue.group],
	);

	const isLevelWithinHierarchyRangeFilter = useCallback(
		(level: number): boolean =>
			level >= hierarchyRangeFilter.value.end && level <= hierarchyRangeFilter.value.start,
		[hierarchyRangeFilter],
	);

	const onItemClick = useCallback(
		(issueType: IssueType, relation: 'parent' | 'child') => {
			const issueTypeId = issueType.id;
			const hierarchyLevel = issueType.level;
			const parentId = relation === 'parent' ? issue.parent : issue.id;
			const siblingId = relation === 'parent' ? issue.id : null;
			const projectId = issue.project.id;
			const group = getGroup();

			setIssueTypeIdForHierarchy(hierarchyLevel, issueTypeId);
			setGlobalCreateIssue();
			triggerInlineCreateIssueAnalytics(issueTypeId);

			return startInlineCreate({
				parentId,
				siblingId,
				projectId,
				hierarchyLevel,
				issueTypeId,
				group,
				groupCombination,
				source: 'INLINE',
			});
		},
		[
			issue,
			startInlineCreate,
			groupCombination,
			setIssueTypeIdForHierarchy,
			setGlobalCreateIssue,
			triggerInlineCreateIssueAnalytics,
			getGroup,
		],
	);

	const issueTypeComparator = useCallback(
		(a: IssueType, b: IssueType) => a.name.localeCompare(b.name),
		[],
	);

	const issueTypesByLevel = useMemo(() => {
		const { project } = issue;

		if (!isDefined(project)) return {};

		const issueTypes = issueTypesByProjectId[project.id];

		return groupBy(issueTypes, (issueType) => issueType.level);
	}, [issue, issueTypesByProjectId]);

	const { type: currentIssueType } = issue;
	const childLevel = currentIssueType.level - 1;

	const childLevelIssueTypes = useMemo(
		() => issueTypesByLevel[childLevel] && issueTypesByLevel[childLevel].sort(issueTypeComparator),
		[issueTypesByLevel, childLevel, issueTypeComparator],
	);

	const suggestChildIssuesComponent = () => {
		return (
			isLevelWithinHierarchyRangeFilter(childLevel) && (
				<SuggestChildIssues
					key="suggest-child-issues-dropdown-menu-item"
					issue={issue}
					toggleAiWorkBreakdownPopup={toggleAiWorkBreakdownPopup}
				/>
			)
		);
	};

	return (
		<MenuGroup maxHeight={350}>
			<DropdownItemGroup isScrollable>
				<IssueTypeItem
					onItemClick={onItemClick}
					issueType={currentIssueType}
					relation="parent"
					isDisabled={isDisabled}
				>
					{formatMessage(messages.createIssue, { issueType: currentIssueType.name })}
				</IssueTypeItem>
				{!isNil(childLevelIssueTypes) &&
					isLevelWithinHierarchyRangeFilter(childLevel) &&
					childLevelIssueTypes.map((issueType) => (
						<IssueTypeItem
							key={issueType.id}
							onItemClick={onItemClick}
							issueType={issueType}
							relation="child"
							isDisabled={isDisabled}
							elemBefore={<ChildIcon label={formatMessage(messages.childIconLabel)} />}
						>
							{formatMessage(messages.createIssue, { issueType: issueType.name })}
						</IssueTypeItem>
					))}
			</DropdownItemGroup>
			{fg('enable-ai-work-breakdown-in-plans') && suggestChildIssuesComponent()}
		</MenuGroup>
	);
};

export default CreateIssue;
