import React from 'react';
import AkSpinner from '@atlaskit/spinner';
import UFOLoadHold from '@atlassian/react-ufo/load-hold';
import type { Props } from './types';

export default function Spinner(props: Props) {
	return (
		<UFOLoadHold name="spinner">
			<AkSpinner {...props} />
		</UFOLoadHold>
	);
}
