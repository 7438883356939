import type { PlannedCapacities, PlannedCapacity, TeamKey, IterationId } from './types';

export const RESET = 'state.domain.planned-capacities.RESET' as const;
export const UPDATE = 'state.domain.planned-capacities.UPDATE' as const;
export const REMOVE = 'state.domain.planned-capacities.REMOVE' as const;
export const UPDATE_TEAM_ID = 'state.domain.planned-capacities.UPDATE_TEAM_ID' as const;

export type ResetAction = {
	type: typeof RESET;
	payload: PlannedCapacities;
};

export type UpdateAction = {
	type: typeof UPDATE;
	payload: PlannedCapacity;
};

export type RemoveAction = {
	type: typeof REMOVE;
	payload: {
		teamId: TeamKey;
		iterationId: IterationId;
	};
};

export type UpdateTeamIdAction = {
	type: typeof UPDATE_TEAM_ID;
	payload: {
		oldId: TeamKey;
		newId: TeamKey;
	};
};

export const reset = (payload: PlannedCapacities) => ({
	type: RESET,
	payload,
});

export const update = (payload: PlannedCapacity) => ({
	type: UPDATE,
	payload,
});

export const remove = (payload: { teamId: TeamKey; iterationId: IterationId }) => ({
	type: REMOVE,
	payload,
});

export const updateTeamId = (payload: { oldId: string; newId: string }) => ({
	type: UPDATE_TEAM_ID,
	payload,
});
