import {
	isExportMode,
	getMode,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app';
import { getHierarchyFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/hierarchy-filter';
import { getHierarchyLevels } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/hierarchy';
import { EDIT } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';

export default createSelector(
	[getHierarchyLevels, getHierarchyFilter, isExportMode, getMode],
	(hierarchy, { value: { start, end } }, exportMode, mode) => ({
		hierarchy,
		start,
		end,
		isReadOnly: mode !== EDIT,
		isExportMode: exportMode,
	}),
);
