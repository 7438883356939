import type { Warning as BaseWarning } from '@atlassian/jira-portfolio-3-portfolio/src/common/warning-details/types';

// Warning Center warnings
export const MISCONFIGURED_CHILDREN = 'state.warnings.MISCONFIGURED_CHILDREN' as const;
export const ENDS_AFTER_PARENT = 'state.warnings.ENDS_AFTER_PARENT' as const;
export const STARTS_BEFORE_PARENT = 'state.warnings.STARTS_BEFORE_PARENT' as const;
export const IGNORES_DEPENDENCY = 'state.warnings.IGNORES_DEPENDENCY' as const;
export const DEPENDENCIES_SHARE_SPRINT = 'state.warnings.DEPENDENCIES_SHARE_SPRINT' as const;
export const TARGET_DATE_BEYOND_DUE_DATE = 'state.warnings.TARGET_DATE_BEYOND_DUE_DATE' as const;
export const NOT_CLOSED_BEYOND_TARGET_END_DATE =
	'state.warnings.NOT_CLOSED_BEYOND_TARGET_END_DATE' as const;
export const IN_PROGRESS_BEYOND_INFERRED_TARGET_END_DATE =
	'state.warnings.IN_PROGRESS_BEYOND_INFERRED_TARGET_END_DATE' as const;
export const TARGET_DATES_ARE_OUTSIDE_OF_SPRINT =
	'state.warnings.TARGET_DATES_ARE_OUTSIDE_OF_SPRINT' as const;
export const ISSUE_ASSIGNED_KANBAN_TEAM_AND_SPRINT =
	'state.warnings.ISSUE_ASSIGNED_KANBAN_TEAM_AND_SPRINT' as const;
export const START_DATE_AFTER_END_DATE = 'state.warnings.START_DATE_AFTER_END_DATE' as const;
// Scheduler warnings
export const UNKNOWN = 'state.warnings.UNKNOWN' as const;
export const NOT_FULLY_SCHEDULED = 'state.warnings.NOT_FULLY_SCHEDULED' as const;
export const HORIZON_REACHED = 'state.warnings.HORIZON_REACHED' as const;
export const RELEASE_CONFLICT = 'state.warnings.RELEASE_CONFLICT' as const;
export const ASSIGNEE_IGNORED = 'state.warnings.ASSIGNEE_IGNORED' as const;
export const EXTERNAL_TEAM = 'state.warnings.EXTERNAL_TEAM' as const;
export const SUBTASK_VIOLATION = 'state.warnings.SUBTASK_VIOLATION' as const;
export const SUBTASK_ASSIGNMENT_VIOLATION = 'state.warnings.SUBTASK_ASSIGNMENT_VIOLATION' as const;
export const SPRINT_EXCEEDED = 'state.warnings.SPRINT_EXCEEDED' as const;
export const INVALID_SPRINT = 'state.warnings.INVALID_SPRINT' as const;
export const RELEASE_SHIFTED = 'state.warnings.RELEASE_SHIFTED' as const;
export const EARLIEST_START_IGNORED = 'state.warnings.EARLIEST_START_IGNORED' as const;
export const OVERBOOKED = 'state.warnings.OVERBOOKED' as const;
export const AVAILABILITY_VIOLATION = 'state.warnings.AVAILABILITY_VIOLATION' as const;
export const VIOLATION_IN_DEPENDENCY = 'state.warnings.VIOLATION_IN_DEPENDENCY' as const;
export const RESOURCE_LIMIT_VIOLATION = 'state.warnings.RESOURCE_LIMIT_VIOLATION' as const;
export const DEPENDENCIES_IGNORED_SPRINT = 'state.warnings.DEPENDENCIES_IGNORED_SPRINT' as const;
export const DEPENDENCIES_IGNORED_RELEASE = 'state.warnings.DEPENDENCIES_IGNORED_RELEASE' as const;
export const CYCLIC_DEPENDENCY = 'state.warnings.CYCLIC_DEPENDENCY' as const;
export const SUBTASK_CYCLIC_DEPENDENCY_IGNORED =
	'state.warnings.SUBTASK_CYCLIC_DEPENDENCY_IGNORED' as const;
export const SUBTASK_NO_SKILLED_RESOURCE = 'state.warnings.SUBTASK_NO_SKILLED_RESOURCE' as const;
export const MISSING_SKILLS = 'state.warnings.MISSING_SKILLS' as const;
export const TOO_SMALL_ESTIMATES = 'state.warnings.TOO_SMALL_ESTIMATES' as const;
export const COMPLETED_PARENT = 'state.warnings.COMPLETED_PARENT' as const;
export const CALCULATION_COMPLEXITY = 'state.warnings.CALCULATION_COMPLEXITY' as const;

export type WarningType = // Warning Center warnings.

		| typeof MISCONFIGURED_CHILDREN
		| typeof ENDS_AFTER_PARENT
		| typeof STARTS_BEFORE_PARENT
		| typeof IGNORES_DEPENDENCY
		| typeof DEPENDENCIES_SHARE_SPRINT
		| typeof TARGET_DATE_BEYOND_DUE_DATE
		| typeof NOT_CLOSED_BEYOND_TARGET_END_DATE
		| typeof IN_PROGRESS_BEYOND_INFERRED_TARGET_END_DATE
		| typeof TARGET_DATES_ARE_OUTSIDE_OF_SPRINT // Scheduler warnings
		| typeof ISSUE_ASSIGNED_KANBAN_TEAM_AND_SPRINT
		| typeof UNKNOWN
		| typeof NOT_FULLY_SCHEDULED
		| typeof HORIZON_REACHED
		| typeof RELEASE_CONFLICT
		| typeof ASSIGNEE_IGNORED
		| typeof EXTERNAL_TEAM
		| typeof SUBTASK_VIOLATION
		| typeof SUBTASK_ASSIGNMENT_VIOLATION
		| typeof VIOLATION_IN_DEPENDENCY
		| typeof SPRINT_EXCEEDED
		| typeof RELEASE_SHIFTED
		| typeof INVALID_SPRINT
		| typeof EARLIEST_START_IGNORED
		| typeof OVERBOOKED
		| typeof AVAILABILITY_VIOLATION
		| typeof RESOURCE_LIMIT_VIOLATION
		| typeof DEPENDENCIES_IGNORED_SPRINT
		| typeof DEPENDENCIES_IGNORED_RELEASE
		| typeof CYCLIC_DEPENDENCY
		| typeof SUBTASK_CYCLIC_DEPENDENCY_IGNORED
		| typeof SUBTASK_NO_SKILLED_RESOURCE
		| typeof MISSING_SKILLS
		| typeof TOO_SMALL_ESTIMATES
		| typeof COMPLETED_PARENT
		| typeof CALCULATION_COMPLEXITY
		| typeof START_DATE_AFTER_END_DATE;

export type Warning = BaseWarning & {
	type: WarningType;
};

export type WarningIssueHash = {
	[issueId: string]: Warning[];
};

export type Warnings = {
	byIssue: WarningIssueHash;
	allWarningsByIssue?: WarningIssueHash;
	transitiveIssueIds: string[];
};

export type WarningsState = {
	/** Warnings by issue excluding disabled warnings in warning center.
	 * This is used for warning icons and the warning center */
	byIssue: WarningIssueHash;
	/** Warnings by issue including disabled warnings in warning center.
	 * This is used for filtering
	 */
	allWarningsByIssue: WarningIssueHash;
	/** Those are issues which don't have warnings themselves but their children do. */
	transitiveIssueIds: string[];
};

// WARNING_TYPE_NUMBER_MAP is used for analytics events
export const WARNING_TYPE_NUMBER_MAP: Record<string, number> = {
	[TARGET_DATE_BEYOND_DUE_DATE]: 1,
	[TARGET_DATES_ARE_OUTSIDE_OF_SPRINT]: 2,
	[NOT_CLOSED_BEYOND_TARGET_END_DATE]: 3,
	[ENDS_AFTER_PARENT]: 5,
	[STARTS_BEFORE_PARENT]: 6,
	[ISSUE_ASSIGNED_KANBAN_TEAM_AND_SPRINT]: 7,
};

export const WARNING_CENTER_AVAILABLE_TYPES = [
	MISCONFIGURED_CHILDREN,
	ENDS_AFTER_PARENT,
	STARTS_BEFORE_PARENT,
	IGNORES_DEPENDENCY,
	DEPENDENCIES_SHARE_SPRINT,
	TARGET_DATE_BEYOND_DUE_DATE,
	NOT_CLOSED_BEYOND_TARGET_END_DATE,
	IN_PROGRESS_BEYOND_INFERRED_TARGET_END_DATE,
	TARGET_DATES_ARE_OUTSIDE_OF_SPRINT,
	ISSUE_ASSIGNED_KANBAN_TEAM_AND_SPRINT,
];
