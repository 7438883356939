import { SHARE_AS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import {
	OPEN_SHARE_REPORT_DIALOG,
	CLOSE_SHARE_REPORT_DIALOG,
	LOADING_SHARE_REPORT_URL,
	UPDATE_SHARE_REPORT_URL,
	UPDATE_SHARE_AS,
	type OpenDialogAction,
	type CloseDialogAction,
	type UpdateShareReportUrlAction,
	type UpdateShareAsAction,
	type LoadingShareReportUrlAction,
} from './actions';
import type { ShareReport } from './types';

const initialState = {
	isDialogOpen: false,
	isLoading: false,
	url: '',
	shareAs: SHARE_AS.LINK,
} as const;

type Action =
	| OpenDialogAction
	| CloseDialogAction
	| LoadingShareReportUrlAction
	| UpdateShareReportUrlAction
	| UpdateShareAsAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: ShareReport = initialState, action: Action) => {
	switch (action.type) {
		case OPEN_SHARE_REPORT_DIALOG:
			return {
				...state,
				isDialogOpen: true,
			};
		case CLOSE_SHARE_REPORT_DIALOG:
			return {
				...state,
				isDialogOpen: false,
			};
		case LOADING_SHARE_REPORT_URL:
			return {
				...state,
				isLoading: action.payload.isLoading,
			};
		case UPDATE_SHARE_REPORT_URL:
			return {
				...state,
				url: action.payload.url,
			};
		case UPDATE_SHARE_AS:
			return {
				...state,
				shareAs: action.payload.shareAs,
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
