import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import uniqWith from 'lodash/uniqWith';
import type { IssueStatusByKey } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-statuses/types.tsx';
import {
	type StringAttributeFilterValue,
	type StatusFilter,
	STATUS_FILTER_ID,
	type StatusKeyFilterValue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import type { IssueStatus } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import {
	CHECKBOX_STATES,
	UNDEFINED_KEY,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { STATUS_KEY_FILTER_ID } from '../../../../state/domain/view-settings/filters/types';

const statusIdToStatusKey = (
	issueStatusById: Record<string, IssueStatus>,
	statusIds: StringAttributeFilterValue,
): StatusKeyFilterValue => {
	const statusKeys = statusIds.reduce<StatusKeyFilterValue>((acc, statusId) => {
		const status = issueStatusById[statusId];
		if (status !== undefined) {
			acc.push({
				category: `${status.categoryId}`,
				name: status.name,
			});
		}
		return acc;
	}, []);
	return uniqWith(statusKeys, isEqual);
};

export const getStatusKeyFilterValuePure = (
	issueStatusById: Record<string, IssueStatus>,
	oldStatusFilterValue: StringAttributeFilterValue,
	newStatusKeyFilterValue: StatusKeyFilterValue,
): StatusKeyFilterValue => {
	if (newStatusKeyFilterValue.length > 0) {
		return newStatusKeyFilterValue;
	}
	return statusIdToStatusKey(issueStatusById, oldStatusFilterValue);
};

export const UNSAVED_STATUS: IssueStatus = { id: UNDEFINED_KEY, categoryId: 2, name: 'UNSAVED' };

export const getStatusKeyFilterOptionsByCategoryPure = (
	statuses: IssueStatus[],
	categoryNameById: Map<string, string>,
): Map<string, string[]> => {
	const statusByCategory = groupBy(statuses, ({ categoryId }) => `${categoryId}`);
	const result = new Map<string, string[]>();
	for (const categoryId of categoryNameById.keys()) {
		const statusNames = (statusByCategory[categoryId] ?? []).map(({ name }) => name);
		// manually add UNSAVED_STATUS as part of "To do" category
		if (categoryId === `${UNSAVED_STATUS.categoryId}`) {
			statusNames.push(UNSAVED_STATUS.name);
		}
		if (statusNames.length > 0) {
			result.set(categoryId, uniq(statusNames));
		}
	}
	return result;
};

export const getIsAllStatusKeyOfCategorySelectedPure = (
	selectedStatusKeys: StatusKeyFilterValue,
	statusNamesByCategory: Map<string, string[]>,
	categoryNameById: Map<string, string>,
): { [key: string]: (typeof CHECKBOX_STATES)[keyof typeof CHECKBOX_STATES] } => {
	const result: { [key: string]: (typeof CHECKBOX_STATES)[keyof typeof CHECKBOX_STATES] } = {};
	for (const categoryId of categoryNameById.keys()) {
		const allStatusNamesOfCategory = statusNamesByCategory.get(categoryId) ?? [];

		const selectedStatusNamesOfCategory = allStatusNamesOfCategory.filter(
			(statusName) =>
				selectedStatusKeys.find(
					({ category, name }) => category === categoryId && name === statusName,
				) !== undefined,
		);

		if (allStatusNamesOfCategory.length === 0 || selectedStatusNamesOfCategory.length === 0) {
			result[categoryId] = CHECKBOX_STATES.UNCHECKED;
		} else if (allStatusNamesOfCategory.length === selectedStatusNamesOfCategory.length) {
			result[categoryId] = CHECKBOX_STATES.CHECKED;
		} else {
			result[categoryId] = CHECKBOX_STATES.INDETERMINATE;
		}
	}
	return result;
};

const statusIdFromStatusKey = (
	issueStatusByKey: IssueStatusByKey,
	statusKeys: StatusKeyFilterValue,
): string[] =>
	statusKeys
		.map(({ category, name }) => issueStatusByKey[`${category} ${name}`]?.map(({ id }) => id) ?? [])
		.flat();

export const getStatusKeyFilterPure = (
	issueStatusById: Record<string, IssueStatus>,
	issueStatusByKey: IssueStatusByKey,
	oldStatusFilterValue: StringAttributeFilterValue,
	newStatusKeyFilterValue: StatusKeyFilterValue,
): StatusFilter => {
	if (newStatusKeyFilterValue.length > 0) {
		// need to add custom UNSAVED status manually as it won't exist in the issueStatusById mapping
		const containsUnsavedStatus = newStatusKeyFilterValue.some(
			({ category, name }) =>
				category === `${UNSAVED_STATUS.categoryId}` && name === UNSAVED_STATUS.name,
		);

		return {
			// the id is not important
			id: STATUS_FILTER_ID,
			value: (containsUnsavedStatus ? [UNSAVED_STATUS.id] : []).concat(
				statusIdFromStatusKey(issueStatusByKey, newStatusKeyFilterValue),
			),
		};
	}

	const containsUnsavedStatus = oldStatusFilterValue.some((id) => id === UNSAVED_STATUS.id);
	return {
		id: STATUS_FILTER_ID,
		// transform to key and then back to id to include ALL statuses of that category
		// otherwise, only _partial_ statuses of a category will be filtered
		value: (containsUnsavedStatus ? [UNSAVED_STATUS.id] : []).concat(
			statusIdFromStatusKey(
				issueStatusByKey,
				statusIdToStatusKey(issueStatusById, oldStatusFilterValue),
			),
		),
	};
};
