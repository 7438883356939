import { ADD, RESET, type AddAction, type ResetAction } from './actions';
import type { SelectOption } from './types';

const initialState: SelectOption[] = [];

type Action = AddAction | ResetAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: SelectOption[] = initialState, action: Action) => {
	switch (action.type) {
		case RESET: {
			return action.payload;
		}
		case ADD: {
			const { payload } = action;
			const newItems = payload.map((item) => item.id);
			return [...payload, ...state.filter((item) => !newItems.includes(item.id))];
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
