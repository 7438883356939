import type { CustomDateRange } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/types.tsx';
import type { TimeScaleOption, ModeOption } from './types';

export const CHANGE_OPTION = 'state.domain.view-settings.time-scale.CHANGE_OPTION' as const;
export const CHANGE_MODE = 'state.domain.view-settings.time-scale.CHANGE_MODE' as const;
export const CHANGE_CUSTOM_DATES =
	'state.domain.view-settings.time-scale.CHANGE_CUSTOM_DATES' as const;

export type ChangeModeActionPayload = ModeOption;
export type ChangeCustomDatesActionPayload = CustomDateRange;

export type ChangeOptionAction = {
	type: typeof CHANGE_OPTION;
	payload: TimeScaleOption;
};

export type ChangeModeAction = {
	type: typeof CHANGE_MODE;
	payload: ChangeModeActionPayload;
};

export type ChangeCustomDatesAction = {
	type: typeof CHANGE_CUSTOM_DATES;
	payload: ChangeCustomDatesActionPayload;
};

export const modeOptionChange = (payload: ChangeModeActionPayload): ChangeModeAction => ({
	type: CHANGE_MODE,
	payload,
});

export const timeScaleCustomDatesChange = (
	payload: ChangeCustomDatesActionPayload,
): ChangeCustomDatesAction => ({
	type: CHANGE_CUSTOM_DATES,
	payload,
});
