import * as R from 'ramda';
import {
	getComponentsById,
	type ComponentsById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/components';
import {
	getSelectOptionsById,
	getCustomFieldsById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields';
import { getIssuePriorities } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-priorities';
import { getStatusCategoryById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-status-categories';
import {
	getIssueTypesById,
	getTeamManagedProjectByIssueTypeId,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types';
import { getProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects';
import type { ProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/types';
import { getAllTeamsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams';
import {
	getColorByValue,
	getColourComponents,
	getColourIssueTypes,
	getColourLabels,
	getColourMaps,
	getColourPriorities,
	getColourSelects,
	isColorByCustomFieldOption,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations';
import type { CustomField } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/custom-fields/types';
import type { IssuePriority } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-priorities/types';
import type { IssueType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-types/types';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import type { SelectOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/select-options/types';
import type { Team } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/teams/types';
import type {
	ColourByOption,
	ColourComponent,
	ColourIssueType,
	ColourLabel,
	ColourMaps,
	ColourPriority,
	ColourSelect,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/colour-by/types';
import type { IssueStatusCategory } from '../../api/types';
import { isDefined } from '../../ramda';
import { createSelector } from '../../reselect';
import { colourByOptions } from '../colours';
import { CustomFieldTypes } from '../constant';
import type { ColorByLegendItem } from './types';

export const getColourByColorListPure = (
	allTeamsById: Record<string, Team>,
	colourByValue: ColourByOption,
	colourComponents: ColourComponent[],
	colourIssueTypes: ColourIssueType[],
	colourLabels: ColourLabel[],
	colourMaps: ColourMaps,
	colourPriorities: ColourPriority[],
	colourSelects: Record<string, ColourSelect[]>,
	componentsById: ComponentsById,
	customFieldsById: Record<string, CustomField>,
	issuePriorities: Record<string, IssuePriority>,
	issueTypesById: Record<number, IssueType>,
	projectsById: ProjectsById,
	selectOptionsById: Record<string, SelectOption>,
	statusCategoryById: Record<number, IssueStatusCategory>,
	teamManagedProjectByIssueTypeId: Record<string, Project>,
) => {
	if (colourByValue === colourByOptions.COMPONENT) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return colourComponents.reduce<Record<string, any>>((acc, colourComponent) => {
			if (
				colourComponent &&
				isDefined(colourComponent.colour) &&
				!R.isEmpty(colourComponent.colour) &&
				colourComponent.components &&
				!R.isEmpty(colourComponent.components)
			) {
				if (!acc[colourComponent.colour]) {
					acc[colourComponent.colour] = [];
				}

				colourComponent.components.forEach((componentId) => {
					if (componentsById[Number(componentId)]) {
						acc[colourComponent.colour || ''].push(componentsById[Number(componentId)].name);
					}
				});
			}
			return acc;
		}, {});
	}

	if (colourByValue === colourByOptions.ISSUE_TYPE) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return colourIssueTypes.reduce<Record<string, any>>((acc, colourIssueType) => {
			if (
				colourIssueType &&
				isDefined(colourIssueType.colour) &&
				!R.isEmpty(colourIssueType.colour) &&
				colourIssueType.issueTypes &&
				!R.isEmpty(colourIssueType.issueTypes)
			) {
				if (!acc[colourIssueType.colour]) {
					acc[colourIssueType.colour] = [];
				}

				colourIssueType.issueTypes.forEach((issueTypeId) => {
					if (issueTypesById[issueTypeId]?.name) {
						let issueTypeName = issueTypesById[issueTypeId].name;
						if (teamManagedProjectByIssueTypeId[String(issueTypeId)]?.key) {
							issueTypeName += ` (${teamManagedProjectByIssueTypeId[String(issueTypeId)].key})`;
						}
						if (isDefined(colourIssueType.colour) && !R.isEmpty(colourIssueType.colour)) {
							acc[colourIssueType.colour].push(issueTypeName);
						}
					}
				});
			}
			return acc;
		}, {});
	}

	if (colourByValue === colourByOptions.LABEL) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return colourLabels.reduce<Record<string, any>>((acc, colourLabel) => {
			if (
				colourLabel &&
				isDefined(colourLabel.colour) &&
				!R.isEmpty(colourLabel.colour) &&
				colourLabel.labels &&
				!R.isEmpty(colourLabel.labels)
			) {
				if (!acc[colourLabel.colour]) {
					acc[colourLabel.colour] = [];
				}

				colourLabel.labels.forEach((label) => {
					acc[colourLabel.colour || ''].push(label);
				});
			}
			return acc;
		}, {});
	}

	if (colourByValue === colourByOptions.PRIORITY) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return colourPriorities.reduce<Record<string, any>>((acc, colourPriority) => {
			if (
				colourPriority &&
				isDefined(colourPriority.colour) &&
				!R.isEmpty(colourPriority.colour) &&
				colourPriority.priorities &&
				!R.isEmpty(colourPriority.priorities)
			) {
				if (!acc[colourPriority.colour]) {
					acc[colourPriority.colour] = [];
				}

				colourPriority.priorities.forEach((priorityId) => {
					if (issuePriorities[priorityId]) {
						acc[colourPriority.colour || ''].push(issuePriorities[priorityId].name);
					}
				});
			}
			return acc;
		}, {});
	}

	if (colourByValue === colourByOptions.PROJECT) {
		const projectColorMap = colourMaps.project;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return Object.entries(projectColorMap).reduce<Record<string, any>>(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(acc, [projectId, colour]: [any, any]) => {
				if (projectsById[projectId]) {
					const projectColour = String(colour);
					if (!acc[projectColour]) {
						acc[projectColour] = [];
					}
					acc[projectColour].push(projectsById[projectId].name);
				}
				return acc;
			},
			{},
		);
	}

	if (colourByValue === colourByOptions.STATUS) {
		const statusColorMap = colourMaps.status;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return Object.entries(statusColorMap).reduce<Record<string, any>>(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(acc, [id, colour]: [any, any]) => {
				const statusId = Number(id);

				if (statusCategoryById[statusId]) {
					const statusColour = String(colour);
					if (!acc[statusColour]) {
						acc[statusColour] = [];
					}
					acc[statusColour].push(statusCategoryById[statusId].name);
				}
				return acc;
			},
			{},
		);
	}

	if (colourByValue === colourByOptions.TEAM) {
		const teamColorMap = colourMaps.team;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return Object.entries(teamColorMap).reduce<Record<string, any>>(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(acc, [teamId, colour]: [any, any]) => {
				// if the team exists
				if (allTeamsById[teamId]) {
					const teamColour = String(colour);
					// adding a colour if the teamColour does not exist
					if (!acc[teamColour]) {
						acc[teamColour] = [];
					}
					// pushing the team title in that color
					acc[teamColour].push(allTeamsById[teamId].title);
				}
				return acc;
			},
			{},
		);
	}

	if (isColorByCustomFieldOption(colourByValue)) {
		// on initial load, customFieldsById will be an empty object - customFieldsById = {}
		if (!R.isEmpty(customFieldsById)) {
			// checking that colourByValue is the id of a custom field to prevent any plan crash
			const customFieldType =
				customFieldsById[colourByValue] &&
				customFieldsById[colourByValue].type &&
				customFieldsById[colourByValue].type.key;
			if (isDefined(customFieldType) && isDefined(colourSelects[colourByValue])) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				return colourSelects[colourByValue].reduce<Record<string, any>>(
					(acc, { colour, options }) => {
						if (!isDefined(colour)) {
							return acc;
						}
						// if there are options
						if (!R.isEmpty(options)) {
							// adding a colour if the colour does not exist

							if (!acc[colour]) {
								acc[colour] = [];
							}

							// in case of a custom Label field, "options" is an array of labels e.g. ["label 1", "label 3", "label 2"]
							if (customFieldType === CustomFieldTypes.Labels) {
								// getting the name of each option
								options.forEach((optionLabel) => {
									acc[colour].push(optionLabel);
								});
							} else {
								// for other custom fields (SingleSelect, MultiCheckboxes, MultiSelect, RadioButtons)
								// "options" is an array of id's e.g. ["10011", "10013"]
								// so we need to find the name of each option based on its id
								options.forEach((optionId) => {
									if (selectOptionsById[optionId]) {
										acc[colour].push(selectOptionsById[optionId].value);
									}
								});
							}
						}
						return acc;
					},
					{},
				);
			}
		}
	}
	return {};
};

// This version of reselect only contains types up to 12 parameters here.
// It is fixed in v4.1.0 until then we will need to be extra careful here to keep the types aligned
export const getColorByList = createSelector(
	[
		// @ts-expect-error - TS2769 - No overload matches this call.
		getAllTeamsById,
		// @ts-expect-error - TS2769 - No overload matches this call.
		getColorByValue,
		// @ts-expect-error - TS2769 - No overload matches this call.
		getColourComponents,
		// @ts-expect-error - TS2769 - No overload matches this call.
		getColourIssueTypes,
		// @ts-expect-error - TS2769 - No overload matches this call.
		getColourLabels,
		// @ts-expect-error - TS2769 - No overload matches this call.
		getColourMaps,
		// @ts-expect-error - TS2769 - No overload matches this call.
		getColourPriorities,
		// @ts-expect-error - TS2769 - No overload matches this call.
		getColourSelects,
		// @ts-expect-error - TS2769 - No overload matches this call.
		getComponentsById,
		// @ts-expect-error - TS2769 - No overload matches this call.
		getCustomFieldsById,
		// @ts-expect-error - TS2769 - No overload matches this call.
		getIssuePriorities,
		// @ts-expect-error - TS2769 - No overload matches this call.
		getIssueTypesById,
		getProjectsById,
		getSelectOptionsById,
		getStatusCategoryById,
		getTeamManagedProjectByIssueTypeId,
	],
	getColourByColorListPure,
);

export const getColorByLegendsPure = (
	colorByValue: ColourByOption,
	colorByList: {
		[key: string]: string[];
	},
): ColorByLegendItem[] => {
	if (colorByValue === colourByOptions.NONE || !colorByValue) {
		return [];
	}

	// if no colors were selected (colorByList = {})
	if (R.isEmpty(colorByList)) {
		return [];
	}

	const colorByLegends: ColorByLegendItem[] = [];

	Object.keys(colorByList).forEach((colorKey) => {
		colorByLegends.push({
			color: colorKey,
			label: colorByList[colorKey].join(', '),
		});
	});
	return colorByLegends;
};

export const getColorByLegends = createSelector(
	[getColorByValue, getColorByList],
	getColorByLegendsPure,
);
