import type { ComponentType } from 'react';
import type { MoveIssuePayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue/types.tsx';
import type {
	TABLE_ISSUE,
	TABLE_INLINE_CREATE,
	TABLE_HEADER,
	TABLE_GROUP,
	TABLE_GROUP_HEADER,
	TABLE_BLANK_ROW,
	GroupHeaderData,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table';
import type { FlagActionPayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/flags/actions';
import type {
	Group,
	ScopeIssue,
	GroupCombination,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types';
import type { Sorting } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/visualisations/types.tsx';
import type { Warnings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/warnings/types';
import type { HierarchyLevel } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import type { Grouping } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import type { Issue } from './issue/drop-menu/create-issue/types';

export type OnToggle = (arg1: { [id: string]: boolean }) => void;

export type OnMove = (arg1: MoveIssuePayload) => void;

export type EnhancedGroupHeaderData = Flow.Diff<GroupHeaderData, { level: number }> & {
	level: HierarchyLevel;
};

export type TableGroupItem = {
	tag: typeof TABLE_GROUP;
	value: string;
	grouping: Grouping;
	url?: string;
	group: string;
	isExpanded: boolean;
	groupCombination: GroupCombination;
	parentGroup?: string | null | undefined;
	childGroups?: Group<ScopeIssue>[] | null | undefined;
	height?: number;
};

export type TableIssueItem = {
	tag: typeof TABLE_ISSUE;
	value: Issue;
	group?: string;
	parentGroup?: string | null | undefined;
	groupCombination?: GroupCombination;
};

export type TableHeaderItem = {
	tag: typeof TABLE_HEADER;
	value: {
		isExpanded: boolean;
		id: string;
		issueCount: number;
	};
	group?: string;
	parentGroup?: string | null | undefined;
};

export type GroupHeaderItem = {
	tag: typeof TABLE_GROUP_HEADER;
	value: EnhancedGroupHeaderData;
	group?: string;
	parentGroup?: string | null | undefined;
};

export type Item =
	| TableIssueItem
	| {
			tag: typeof TABLE_INLINE_CREATE;
			value: ScopeIssue;
			group?: string;
	  }
	| TableHeaderItem
	| GroupHeaderItem
	| {
			tag: typeof TABLE_BLANK_ROW;
			value: undefined;
			group?: string;
	  }
	| TableGroupItem;

export type Issues = Item[];

export type Props = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	SelectableIssueComponent: ComponentType<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	SortableIssueComponent: ComponentType<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	StickyHeaderComponent: ComponentType<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	SortableIssuesWithoutParentComponent: ComponentType<any>;
	noIssuesInPlan: boolean;
	issues: Issues;
	onToggle: OnToggle;
	onMove: OnMove;
	onLoadIssues: () => void;
	scrollToIndex?: number;
	disableDnD?: boolean;
	isReadOnly: boolean;
	width: number;
	rankDigits: number;
	startLevel: number;
	scrollElement: HTMLElement | null | undefined;
	isLoading: boolean;
	showWarning: boolean;
	isDragging?: boolean;
	warnings: Warnings;
	sorting: Sorting;
	onExperienceSuccess: () => void;
	onInvalidRanking: (payload: FlagActionPayload) => void;
	isGroupingByMultiSelectCustomField?: boolean;
	grouping: Grouping;
};

export const DROP_INVALID_SIBLING =
	'view.main.tabs.roadmap.scope.issues.DROP_INVALID_SIBLING' as const;

export const DROP_INVALID_CHILD = 'view.main.tabs.roadmap.scope.issues.DROP_INVALID_CHILD' as const;

export const DROP_SIBLING = 'view.main.tabs.roadmap.scope.issues.DROP_SIBLING' as const;

export const DROP_CHILD = 'view.main.tabs.roadmap.scope.issues.DROP_CHILD' as const;

export const DROP_NONE = 'view.main.tabs.roadmap.scope.issues.DROP_NONE' as const;

export const REPARENT_TMP_TO_EXTERNAL_ERROR: 'REPARENT_TMP_TO_EXTERNAL_ERROR' =
	'REPARENT_TMP_TO_EXTERNAL_ERROR' as const;

export const REPARENT_CMP_TO_TMP_ERROR: 'REPARENT_CMP_TO_TMP_ERROR' =
	'REPARENT_CMP_TO_TMP_ERROR' as const;

// NOTE this is a drop place appearance type,
// which could be rendered as a SIBLING type even if dropped under the parent
// (i.e. when parent has children and is expanded)
export type DropType =
	| typeof DROP_INVALID_SIBLING
	| typeof DROP_INVALID_CHILD
	| typeof DROP_SIBLING
	| typeof DROP_CHILD
	| typeof DROP_NONE;

export type SortDelta = {
	newIndex: number;
	oldIndex: number;
};

export type SortOver = {
	newIndex: number;
	oldIndex: number;
	index: number;
};

export type SortEnd = {
	issues: Issues;
	onMove: OnMove;
	dropType: DropType;
	delta: SortDelta;
};

export type Relation = 'BEFORE' | 'AFTER' | 'LAST' | null;

export type DropErrorType =
	| typeof REPARENT_TMP_TO_EXTERNAL_ERROR
	| typeof REPARENT_CMP_TO_TMP_ERROR
	| null;

export type RelativePosition = { anchor: string | null | undefined; relation: Relation };

export type DropState = {
	type: DropType;
	id: string | null | undefined;
	parentId: string | null | undefined;
	group: string | null | undefined;
	errorType: DropErrorType;
} & RelativePosition;

export type State = {
	drop: DropState;
};
