import * as R from 'ramda';
import { groupBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import type { LocalizedIssueColumn } from './types';

const INCOMING_DEPENDENCY = 'incomingDependencies';
const OUTGOING_DEPENDENCY = 'outgoingDependencies';

export const getListableColumns = (columns: LocalizedIssueColumn[]) =>
	R.values(
		groupBy(
			(column) =>
				[INCOMING_DEPENDENCY, OUTGOING_DEPENDENCY].includes(column.id)
					? 'dependency'
					: `${column.localizedTitle}-${column.id}`,
			columns,
		),
	);
