import * as R from 'ramda';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types';
import type { AttributeFilterValue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { UNDEFINED_KEY } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function matchFilter(filterValue: AttributeFilterValue, attrValue: any[]): boolean {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	return attrValue.some((v) => (filterValue as any[]).includes(v));
}

export const applyFilter = (
	issue: Issue,
	filterValue: AttributeFilterValue,
	attrName: string,
	optimizedMode?: boolean,
) => {
	if (!filterValue || filterValue.length === 0) {
		return true;
	}

	const attrValue = optimizedMode
		? [
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				...issue.assignments.map((el) => el[attrName as keyof typeof el]).filter((el) => el),
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				issue[attrName as keyof typeof issue],
			]
		: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			issue[attrName as keyof typeof issue];

	if (attrValue instanceof Array) {
		const matchable = R.isEmpty(attrValue) ? [UNDEFINED_KEY] : R.flatten(attrValue);
		return matchFilter(filterValue, matchable);
	}

	const matchable = !isDefined(attrValue) ? [UNDEFINED_KEY] : [attrValue];
	return matchFilter(filterValue, matchable);
};
