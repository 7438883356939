import { getHierarchy } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/hierarchy';
import { getInlineCreateState } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/inline-create';
import type { InlineCreateState } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/scope/inline-create/types.tsx';
import type { HierarchyLevel } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';

export default createSelector(
	[getInlineCreateState, getHierarchy],
	({ issueTypeId, IssueTypeIdForHierarchy }: InlineCreateState, hierarchy: HierarchyLevel[]) => ({
		issueTypeId,
		IssueTypeIdForHierarchy,
		hierarchy,
	}),
);
