import React from 'react';
import ArrowRightIcon from '@atlaskit/icon/core/migration/arrow-right';
import { token } from '@atlaskit/tokens';
import { FormattedMessage, injectIntl } from '@atlassian/jira-intl';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

function FieldFlyout(props: Props) {
	const { optimized, intl, current, delta } = props;

	return (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles.flyout}
			data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.column.cell.flyout.changes-flyout"
		>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.column}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.heading}>
					<FormattedMessage {...commonMessages.current} />
				</div>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={current ? styles.value : styles['no-value']}>
					{current || intl.formatMessage({ ...messages.noValue })}
				</div>
			</div>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.column}>
				<ArrowRightIcon label="" color={token('color.icon')} />
			</div>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.column}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.heading}>
					<FormattedMessage {...messages.whenAccepted} />
				</div>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={optimized ? styles.value : styles['no-value']}>
					{optimized ? (
						<span>
							{optimized} {delta}
						</span>
					) : (
						intl.formatMessage({ ...messages.noValue })
					)}
				</div>
			</div>
		</div>
	);
}

FieldFlyout.defaultProps = {
	delta: '',
};

export default injectIntl(FieldFlyout);
