import type { FilterValue, FilterId, Filters } from './types';

export const RESET = 'state.domain.view-settings.filters.RESET' as const;
export const CHANGE = 'state.domain.view-settings.filters.CHANGE' as const;
export const CLEAR = 'state.domain.view-settings.filters.CLEAR' as const;
export const CLEAR_ALL = 'state.domain.view-settings.filters.CLEAR_ALL' as const;

export type ChangeActionPayload = {
	id: FilterId;
	value: FilterValue;
};

export type ResetAction = {
	type: typeof RESET;
	payload: Filters;
};

export type ChangeAction = {
	type: typeof CHANGE;
	payload: ChangeActionPayload;
	bypassAnalytics: boolean;
};

export type ClearAction = {
	type: typeof CLEAR;
	payload: FilterId;
};

export type ClearAllAction = {
	type: typeof CLEAR_ALL;
};

export const reset = (payload: Filters): ResetAction => ({
	type: RESET,
	payload,
});

export const change = (payload: ChangeActionPayload, bypassAnalytics = false): ChangeAction => ({
	type: CHANGE,
	payload,
	bypassAnalytics,
});

export const clear = (payload: FilterId): ClearAction => ({
	type: CLEAR,
	payload,
});

export const clearAll = (): ClearAllAction => ({
	type: CLEAR_ALL,
});
