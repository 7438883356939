import {
	getAssigneeListFetchStatus,
	getAssigneeList,
	getAssigneesOfIssues,
	getIssueIdForFetchAssigneeListProgress,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/assignees';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export const mapStateToProps = createStructuredSelector<State, {}, StateProps>({
	assigneeList: getAssigneeList,
	assigneeListFetchStatus: getAssigneeListFetchStatus,
	currentAssignees: getAssigneesOfIssues,
	issueIdForFetchAssigneeListProgress: getIssueIdForFetchAssigneeListProgress,
});

export default mapStateToProps;
