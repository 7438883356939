import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { Props } from './types';

export default createSelector(
	[(_: State, ownProps: Props) => ownProps],
	(ownProps): Props => ({
		...ownProps,
	}),
);
