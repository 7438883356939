import type { IssueReporterMap } from './types';

export const UPDATE_ISSUE_REPORTER_MAP = 'state.domain.reporters.UPDATE_ISSUE_REPORTER_MAP';

export type UpdateIssueReporterMapActionPayload = IssueReporterMap;

export type UpdateIssueReporterMapAction = {
	type: typeof UPDATE_ISSUE_REPORTER_MAP;
	payload: UpdateIssueReporterMapActionPayload;
};

export const updateIssueReporterMap = (payload: UpdateIssueReporterMapActionPayload) => ({
	type: UPDATE_ISSUE_REPORTER_MAP,
	payload,
});
