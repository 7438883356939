import { colourByOptions } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colours';
import messages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';

export const colorByLabelsMap = {
	[colourByOptions.NONE]: messages.none,
	[colourByOptions.COMPONENT]: messages.component,
	[colourByOptions.EPIC]: messages.epic,
	[colourByOptions.ISSUE_TYPE]: messages.issueType,
	[colourByOptions.LABEL]: messages.label,
	[colourByOptions.PRIORITY]: messages.priority,
	[colourByOptions.PROJECT]: messages.project,
	[colourByOptions.STATUS]: messages.status,
	[colourByOptions.TEAM]: messages.team,
} as const;
