import { useMemo } from 'react';
import { useScrollX, useViewport } from '../container';
import { useColumns } from '../grid';
/**
 * Returns columns visibility according to viewport and scroll X
 * Used in Virtualizer to optimise lite rows rendering
 */
export const useVisibleColumns = () => {
	const [{ columns, widths }] = useColumns();
	const [scrollX] = useScrollX();

	const [{ width }] = useViewport();

	const visibleCols = useMemo(() => {
		const columnsVisibleArr: Record<string, boolean> = {};
		let left = 0;
		columns.forEach((col, index) => {
			const currentWidth = widths[index];

			if (col.sticky || (left >= scrollX && left + currentWidth <= scrollX + width)) {
				columnsVisibleArr[index.toString()] = true;
			} else {
				columnsVisibleArr[index.toString()] = false;
			}
			left += currentWidth;
		});

		return columnsVisibleArr;
	}, [scrollX, width, columns, widths]);

	return visibleCols;
};
