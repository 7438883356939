import isEmpty from 'lodash/isEmpty';
import type { PayloadError } from 'relay-runtime';
import { stopCaptureGraphQlErrors } from '@atlassian/jira-relay-errors';

type PayloadErrorWithExtensions = PayloadError & { extensions?: { statusCode?: number } };

const getGraphQLErrorsForOperation = (
	errorCollector: string,
	operationName: string,
): PayloadErrorWithExtensions[] => {
	const errorsWithMeta = stopCaptureGraphQlErrors(errorCollector);
	if (Array.isArray(errorsWithMeta)) {
		return errorsWithMeta
			.filter((errorWithMeta) => errorWithMeta.meta.operationName === operationName)
			.flatMap((errorWithMeta) => errorWithMeta.errors);
	}
	return [];
};

export const getErrorsAndStopCapture = (
	errorCollector: string,
	graphQLOperationName: string,
): {
	isError: boolean;
	isPermissionError: boolean;
	cause: string;
	statusCode: number | undefined;
} => {
	const relevantErrors = getGraphQLErrorsForOperation(errorCollector, graphQLOperationName);
	const isError = relevantErrors.length > 0;
	// When we call for ideas and there is no ideas associated in the plan, we have response with 400 code
	// from API, what means "wrong jql or no permissions"
	const isPermissionError =
		isError &&
		relevantErrors.some((relevantError) => relevantError?.extensions?.statusCode === 400);

	return {
		isError,
		isPermissionError,
		cause: relevantErrors[0]?.message ?? '',
		statusCode: relevantErrors[0]?.extensions?.statusCode,
	};
};

export const PROJECT_TYPE = 'product_discovery';

const isIssueKey = (query: string): boolean => query.match(/^[A-Za-z0-9]+-\d+$/) !== null;

export const createJqlQuery = (query: string): string => {
	const trimmedQuery = query.trim();
	if (isEmpty(trimmedQuery)) {
		return `projectType=${PROJECT_TYPE}`;
	}
	if (isIssueKey(trimmedQuery)) {
		return `projectType=${PROJECT_TYPE} AND (summary ~ '${trimmedQuery}*' OR issueKey = ${trimmedQuery})`;
	}
	return `projectType=${PROJECT_TYPE} AND summary ~ '${trimmedQuery}*'`;
};
