import React from 'react';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { type IntlShape, useIntl } from '@atlassian/jira-intl';
import { SUB_TASK_LEVEL } from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import type { OptionType, ValueType } from '@atlassian/jira-portfolio-3-common/src/select/types';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import messages from './messages';
import SprintSelectOld, { SprintSelect } from './select';
import type { OwnProps as SprintSelectProps, SprintSelectValue } from './select/types';
import type { Props } from './types';
import Wireframe from './wireframe';

export const getTooltipMessage = (
	isIssueASubtask: boolean,
	intl: IntlShape,
):
	| {
			header: string;
			content: string;
	  }
	| undefined => {
	if (isIssueASubtask) {
		return {
			header: intl.formatMessage(messages.issueIsSubtaskHeader),
			content: intl.formatMessage(messages.issueIsSubtaskContent),
		};
	}
};

// clean up when removing use_sprint_search_in_plan_dropdown FG
export const SprintCellOld = ({
	isScrolling,
	isReadOnly = false,
	issue,
	issue: { team: teamId, level, id: issueId },
	showOptimizations,
	sprints,
	sprintsByTeam,
	teamsById,
	additionalTeamsById,
	issueStatuses,
	teams,
	sprintsByIssueSources,
	externalSprintsById,
	allSprints,
	rollupValues,
	SprintSelectComponent = SprintSelectOld,
	onChangeOld,
}: Props) => {
	const intl = useIntl();
	const { formatMessage } = intl;
	const sprintRollUpForIssue = isDefined(rollupValues[issueId])
		? rollupValues[issueId].sprint
		: null;

	const handleSelection = (selection: ValueType) => {
		// @ts-expect-error - TS2551 - Property 'value' does not exist on type 'OptionType | OptionsType'. Did you mean 'values'?
		if (selection && typeof selection.value === 'string') {
			// @ts-expect-error - TS2551 - Property 'value' does not exist on type 'OptionType | OptionsType'. Did you mean 'values'?
			onChangeOld(issue, selection.value);
		} else {
			onChangeOld(issue, undefined);
		}
	};
	const isIssueASubtask = level === SUB_TASK_LEVEL;

	const team = teamId ? teamsById[teamId] || null : null;
	let sprintsForTeam = sprints;
	if (isDefined(team)) {
		sprintsForTeam = sprintsByTeam[team.id] || [];
	}

	const isSelectReadOnly = isIssueASubtask || isReadOnly;

	const sprintSelectProps = {
		sprintRollUpForIssue,
		handleSelection,
		isScrolling,
		isReadOnly: isSelectReadOnly,
		issue,
		showOptimizations,
		sprints,
		issueStatuses,
		sprintsByTeam,
		teams,
		sprintsByIssueSources,
		additionalTeamsById,
		teamsById,
		allSprints,
		externalSprintsById,
		externalSprintLabel: formatMessage(messages.externalSprint),
		message: !showOptimizations ? getTooltipMessage(isIssueASubtask, intl) : undefined,
	};

	return isScrolling ? (
		<Wireframe
			{...{
				issue,
				showOptimizations,
				sprints: sprintsForTeam,
			}}
		/>
	) : (
		<SprintSelectComponent {...sprintSelectProps} />
	);
};

export const SprintCell = ({
	isScrolling,
	isReadOnly = false,
	issue,
	issue: { team: teamId, level, id: issueId },
	showOptimizations,
	sprints,
	sprintsByTeam,
	teamsById,
	additionalTeamsById,
	issueStatuses,
	teams,
	sprintsByIssueSources,
	internalSprintIds,
	externalSprintsById,
	allSprints,
	rollupValues,
	SprintSelectComponent = SprintSelect,
	onChange,
}: Props) => {
	const intl = useIntl();
	const { formatMessage } = intl;
	const sprintRollUpForIssue = isDefined(rollupValues[issueId])
		? rollupValues[issueId].sprint
		: null;

	const handleSelection = (selection: SprintSelectValue | OptionType | undefined | null) => {
		if (selection) {
			onChange(issue, selection.value, selection.sprint ?? undefined);
		} else {
			onChange(issue, undefined, undefined);
		}
	};
	const isIssueASubtask = level === SUB_TASK_LEVEL;

	const team = teamId ? teamsById[teamId] || null : null;
	let sprintsForTeam = sprints;
	if (isDefined(team)) {
		sprintsForTeam = sprintsByTeam[team.id] || [];
	}

	const sprintSelectProps: SprintSelectProps = {
		sprintRollUpForIssue,
		handleSelection,
		isScrolling,
		isReadOnly: isIssueASubtask || isReadOnly,
		issue,
		showOptimizations,
		sprints,
		issueStatuses,
		sprintsByTeam,
		teams,
		sprintsByIssueSources,
		additionalTeamsById,
		teamsById,
		allSprints,
		internalSprintIds,
		externalSprintsById,
		externalSprintLabel: formatMessage(messages.externalSprint),
		message: !showOptimizations ? getTooltipMessage(isIssueASubtask, intl) : undefined,
	};

	return isScrolling ? (
		<Wireframe
			{...{
				issue,
				showOptimizations,
				sprints: sprintsForTeam,
			}}
		/>
	) : (
		<SprintSelectComponent {...sprintSelectProps} />
	);
};

export default componentWithFG('use_sprint_search_in_plan_dropdown', SprintCell, SprintCellOld);
