import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { getLongTasksMetrics } from '@atlassian/jira-common-long-task-metrics';
import { generateLongTaskAnalyticsSummary } from '@atlassian/jira-common-long-task-metrics/src/reporters/common';
import { genericReporter } from '@atlassian/jira-common-long-task-metrics/src/reporters/software-generic';
import {
	isExpandedObservationData,
	type ObservationData,
} from '@atlassian/jira-common-long-task-metrics/src/types';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { SCROLL_DELAY } from './constants';
import type { LongTaskMetrics, InteractionMetricType } from './types';

export const createScrollReporter =
	(analyticsEvent: UIAnalyticsEvent, offset = 0, additionalAttributes?: Object) =>
	(observationData: ObservationData) => {
		if (isExpandedObservationData(observationData)) {
			const eventStartTimes = observationData.events.map((event) => event.timestamp);
			const startTime = Math.min(observationData.startTimestamp, ...eventStartTimes);
			const measureTimespan = performance.now() - startTime - offset;
			const endTimeStamp = startTime + measureTimespan;
			// Remove events that are triggered after the last scroll event because the user could have triggered another unrelated long task
			const events = observationData.events.filter((event) => event.timestamp <= endTimeStamp);
			const analyticsData = {
				...generateLongTaskAnalyticsSummary(`${observationData.type}`, events),
				measureTimespan,
				events,
				...additionalAttributes,
			};
			fireOperationalAnalytics(analyticsEvent, observationData.type, analyticsData);
		}
	};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (appId: string): LongTaskMetrics => {
	let scrollTrackingTimeout: false | NodeJS.Timeout = false;

	const start = (type: InteractionMetricType) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		getLongTasksMetrics(type as string).start(appId);
	};
	const stop = (
		type: InteractionMetricType,
		reporter: (observationData: ObservationData) => void = genericReporter,
	) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		getLongTasksMetrics(type as string).stop((param) => {
			reporter(param);
		});
	};

	return {
		start,
		stop,
		trackWithTimeout: (
			type: InteractionMetricType,
			createAnalyticsEvent: (arg1: { action: string; actionSubject: string }) => UIAnalyticsEvent,
			wait: number = SCROLL_DELAY,
			additionalAttributes?: Object,
		) => {
			if (!scrollTrackingTimeout) {
				start(type);
			} else {
				clearTimeout(scrollTrackingTimeout);
			}
			scrollTrackingTimeout = setTimeout(() => {
				const [actionSubject, action] = type.split(' ');
				const analyticsEvent = createAnalyticsEvent({ action, actionSubject });
				const scrollReporter = createScrollReporter(analyticsEvent, wait, additionalAttributes);
				stop(type, scrollReporter);
				scrollTrackingTimeout = false;
			}, wait);
		},
		clearTimeout: () => {
			if (scrollTrackingTimeout) {
				clearTimeout(scrollTrackingTimeout);
			}
		},
	};
};
