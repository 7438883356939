import React from 'react';
import Heading from '@atlaskit/heading';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useModal } from '@atlaskit/modal-dialog';

interface ModalHeaderProps {
	children: string;
}

const ModalHeader = ({ children }: ModalHeaderProps) => {
	const { titleId } = useModal();

	return (
		<Heading as="h2" size="medium" id={titleId}>
			{children}
		</Heading>
	);
};

export default ModalHeader;
