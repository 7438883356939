import { ff } from '@atlassian/jira-feature-flagging';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types';
import {
	type IdeaFilter,
	IDEA_FILTER_ID,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { getFiltersViewSettings } from '../../view-settings';
import { applyFilter as applyAttributeFilter } from '../attribute-filter';

const EMPTY_IDEA_FILTER: IdeaFilter = {
	id: IDEA_FILTER_ID,
	value: [],
};

export const applyFilter = (issue: Issue, filter: IdeaFilter, optimizedMode: boolean): boolean =>
	applyAttributeFilter(issue, filter.value, 'associatedIssueIds', optimizedMode);

export const getAppliedFilter = (state: State): IdeaFilter =>
	getFiltersViewSettings(state)[IDEA_FILTER_ID] || EMPTY_IDEA_FILTER;

export const getIdeaFilter = createSelector(
	[getAppliedFilter],
	(pureFilter: IdeaFilter): IdeaFilter => {
		if (!ff('polaris-arj-ideas')) {
			return EMPTY_IDEA_FILTER;
		}
		const { value = [] } = pureFilter;
		return {
			id: IDEA_FILTER_ID,
			value,
		};
	},
);
