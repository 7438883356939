import React from 'react';
import { getIdForChangeCategory } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/title-bar/update-jira/utils';
import { ENTITY } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

export default function CrossProjectReleaseTitle(props: Props) {
	const { name: releaseName, id } = props.crossProjectVersion;

	return (
		<EllipsedWithTooltip content={releaseName}>
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.crossProjectReleaseTitle}
				id={getIdForChangeCategory(id, ENTITY.CROSS_PROJECT_RELEASE)}
				data-testid={`portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.title.cross-project-release.cross-project-release-title-container-${id}`}
			>
				{releaseName}
			</div>
		</EllipsedWithTooltip>
	);
}
