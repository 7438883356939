import type { CustomField } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/custom-fields/types';
import { clear as clearFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/actions';
import { clear as clearCustomFieldFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/custom-field-filter/actions';
import type { FilterId } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { DispatchProps } from './types';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	clearCustomFieldFilter: (customFields: CustomField[]) => {
		customFields.forEach((customField) => dispatch(clearCustomFieldFilter({ customField })));
	},
	clearFilter: (filterId: FilterId) => {
		dispatch(clearFilter(filterId));
	},
});

export default mapDispatchToProps;
