import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { formatDateUTC } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import {
	type CustomField,
	type IssueType,
	type Sprint,
	ISSUE_INFERRED_DATE_SELECTION,
	type IssueInferredDateSelection,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import { isDefined, mapGroupsToIds } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import {
	CustomFieldTypes,
	DefaultDateFormat,
	EXPORT_FILE_DATE_FORMAT,
	ISSUE_STATUS_CATEGORIES,
	PlanningUnits,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import { roundToTwoDigit } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/status-breakdown/components/util';
import type { UserSelectItemProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/user-picker/types';
import type { HistoryIssueTotalEstimationByParentIdMap } from '../../query/breakdown/types';
import type { ComponentsById } from '../../query/components';
import type { IssueStatusesById } from '../../query/issue-statuses/types';
import type { ProjectsById } from '../../query/projects/types';
import { getChildrenByParentPure } from '../../query/raw-issues/issues-tree.tsx';
import type { Person } from '../../state/domain/assignees/types.tsx';
import type { HistoryIssue } from '../../state/domain/history-issues/types';
import type { IssuePriority } from '../../state/domain/issue-priorities/types';
import type { Issue } from '../../state/domain/issues/types';
import type { DateConfiguration } from '../../state/domain/plan/types';
import type { Project } from '../../state/domain/projects/types';
import type { ScopeIssue } from '../../state/domain/scope/types';
import type { SelectOption } from '../../state/domain/select-options/types';
import type { Team } from '../../state/domain/teams/types';
import type { Version } from '../../state/domain/versions/types.tsx';
import {
	getBreakdown,
	getBreakdownByEstimate,
} from '../../view/main/tabs/roadmap/fields/columns/cells/breakdown/query';
import { buildCSV } from './csv/utils';
import { GOAL_NAME, GOAL_STATUS } from './goal/constants';
import { IDEA_NAME } from './idea/constants';
import type { CsvIntlMessages, CsvColumnHeadings, ColumnHeading } from './types';

const DateTypes = {
	Start: 'START',
	End: 'END',
	Other: 'OTHER',
} as const;

type DateFieldTypes = 'START' | 'END' | 'OTHER';

const issueField = {
	[DateTypes.Start]: 'baselineStart',
	[DateTypes.End]: 'baselineEnd',
	[DateTypes.Other]: undefined,
} as const;

/**
 * This function looks for the type name of an issue in the issueTypesById props.
 *
 * @param issueTypeId Issue type id.
 * @param issueTypesById Issue types grouped by id.
 *
 * @returns Returns the type name (i.e. Hierarchy name) of an issue, or null if the issue type id was not found in the issue types.
 */
export const getIssueTypeName = (
	issueTypeId: number,
	issueTypesById: {
		[key: string]: IssueType;
	},
) => {
	const issueType = issueTypesById[String(issueTypeId)];

	if (issueType) {
		return issueType.name;
	}

	return null;
};

/**
 * This function looks for the project name of an issue in the projects props.
 *
 * @param issueProjectId Issue project id.
 * @param projectsById Projects grouped by id.
 *
 * @returns Returns the project name of an issue, or null if the issue project id was not found in the projects.
 */
export const getIssueProjectName = (
	issueProjectId: number,
	projectsById: Array<Project> | Record<string | number, Project>,
) => {
	const issueProject = projectsById[issueProjectId];

	if (issueProject) {
		return issueProject.name;
	}

	return null;
};

/**
 * This function looks for the release name of an issue in the versions props.
 *
 * @param issueFixVersions Issue fix versions.
 * @param versions Versions.
 *
 * @returns Returns the release name of an issue, or null if the issue fix versions were not found in the versions.
 */
export const getIssueReleaseName = (issueFixVersions: string[], versions: Version[]) => {
	const issueRelease = issueFixVersions
		.map((fixVersion) => {
			const issueReleaseData = versions.find(({ id }) => id === fixVersion);
			if (issueReleaseData) {
				return issueReleaseData.name;
			}
			return null;
		})
		.filter(Boolean);

	if (issueRelease && issueRelease.length) {
		return issueRelease.join(', ');
	}

	return null;
};

/**
 * This function looks for the team name of an issue in the teams props.
 *
 * @param issueTeamId Issue team id.
 * @param teamsById Teams grouped by id.
 * @param externalTeamsById External teams grouped by id.
 * @param csvIntlMessages Object of internationalized messages.
 *
 * @returns Returns the team name of an issue, or null if the issue team id was not found in teamsById.
 */
export const getIssueTeamName = (
	issueTeamId: string,
	teamsById: Record<string, Team>,
	externalTeamsById: Record<string, Team>,
	csvIntlMessages: CsvIntlMessages,
) => {
	// if the issue is in an internal team
	if (teamsById[issueTeamId]) {
		return teamsById[issueTeamId].title;
	}

	// if the issue is in an external team
	if (externalTeamsById[issueTeamId]) {
		return `${externalTeamsById[issueTeamId].title} ${csvIntlMessages.labels.external}`;
	}

	return null;
};

/**
 * This function looks for the assignee title of an issue in the assignees props.
 *
 * @param issueAssigneeId Issue assignee id.
 * @param assigneesById Assignees grouped by id.
 *
 * @returns Returns the assignee name of an issue, or null if the issue assignee id is "unassigned" or is not found in assigneesById.
 */
export const getIssueAssigneeName = (
	issueAssigneeId: string,
	assigneesById: {
		[key: string]: Person;
	},
) => {
	if (issueAssigneeId === 'unassigned') {
		return null;
	}

	const assignee = assigneesById[issueAssigneeId];

	if (assignee) {
		return assignee.jiraUser && assignee.jiraUser.title;
	}

	return null;
};

/**
 * This function looks for the reporter title of an issue in the reporters props.
 *
 * @param issueReporterId Issue reporter id.
 * @param reportersById Reporters grouped by id.
 *
 * @returns Returns the reporter name of an issue, or null if the issue reporter id is not found in issueReporterId.
 */
export const getIssueReporterName = (
	issueReporterId: string,
	reportersById: {
		[key: string]: Person;
	},
) => {
	const reporter = reportersById[issueReporterId];

	if (reporter) {
		return reporter.jiraUser && reporter.jiraUser.title;
	}

	return null;
};

/**
 * This function looks for the sprint name(s) of an issue in the sprintsById and externalSprintsById props.
 *
 * @param issue Issue.
 * @param sprintsById Sprints grouped by id.
 * @param externalSprintsById External sprints grouped by id.
 * @param csvIntlMessages Object of internationalized messages.
 *
 * @returns Returns the current / future / completed sprint name(s) of an issue, or null if the issue is not included in any sprint.
 */
export const getIssueSprintName = (
	issue: ScopeIssue,
	sprintsById: Record<string, Sprint>,
	externalSprintsById: Record<string, Sprint>,
	csvIntlMessages: CsvIntlMessages,
) => {
	// this array will include al
	let issueSprintNames: Array<string> = [];

	// if the issue is in a current/future sprint
	if (isDefined(issue.sprint)) {
		// if the issue is in a current/future INTERNAL sprint
		if (sprintsById[issue.sprint]) {
			issueSprintNames.push(sprintsById[issue.sprint].title);

			// if the issue is in a current/future EXTERNAL sprint
		} else if (externalSprintsById[issue.sprint]) {
			issueSprintNames.push(
				`${externalSprintsById[issue.sprint].title} ${csvIntlMessages.labels.external}`,
			);
		}
	}

	// if the issue is in completed sprints
	if (issue.completedSprints && issue.completedSprints.length) {
		// for each completed sprint, we merge the current/future sprint names with the completed sprint names
		issueSprintNames = [
			...issueSprintNames,
			...issue.completedSprints
				.map((completedSprint) => {
					// if the issue is in a completed INTERNAL sprint
					if (sprintsById[completedSprint]) {
						return `${sprintsById[completedSprint].title} ${csvIntlMessages.labels.completed}`;
					}

					// if the issue is in a completed EXTERNAL sprint
					if (externalSprintsById[completedSprint]) {
						return `${externalSprintsById[completedSprint].title} ${csvIntlMessages.labels.external} ${csvIntlMessages.labels.completed}`;
					}

					return null;
				})
				.filter(Boolean),
		];
	}

	// if there are sprint names, we join them with " + " the same way it is done in the timeline of issues
	if (issueSprintNames && issueSprintNames.length) {
		return issueSprintNames.join(' + ');
	}
	return null;
};

/**
 * This function looks for each team name of an rollup issue value.
 *
 * @param teamIds team ids of issue.
 * @param teamsById Teams grouped by id.
 * @param externalTeamsById External teams grouped by id.
 * @param csvIntlMessages Object of internationalized messages.
 *
 * @returns Returns the team name of an issue, or null if the issue team id was not found in teamsById.
 */

export const getIssueRollupTeamNames = (
	teamIds: string[],
	teamsById: Record<string, Team>,
	externalTeamsById: Record<string, Team>,
	csvIntlMessages: CsvIntlMessages,
): string =>
	teamIds
		.map((id) => getIssueTeamName(id, teamsById, externalTeamsById, csvIntlMessages) || '')
		.join(', ');

/**
 * This function looks for the sprint name(s) of an issue in the sprintsById and externalSprintsById props.
 *
 * @param sprintRollUpForIssue Sprints ids.
 * @param sprintsById sprints map by id.
 * @param externalSprintsById External sprints map by id.
 * @param completedSprintsMsg default message when completed sprint name has not been found.
 *
 * @returns Returns sorted array of sprint names.
 */
export const getIssueRollUpSprintName = (
	sprintRollUpForIssue: string[],
	sprintsById: Record<string, Sprint>,
	externalSprintsById: Record<string, Sprint>,
	completedSprintsMsg: string,
): string => {
	const mapSprintNames = (ids: string[]): string[] =>
		(ids || []).map((sprintId) => {
			const externalSprint = externalSprintsById[sprintId];
			const sprint = sprintsById[sprintId] || {};
			return externalSprint ? externalSprint.title : sprint.title || completedSprintsMsg;
		});

	return mapSprintNames(sprintRollUpForIssue)
		.sort((a, b) => a.localeCompare(b))
		.join(', ');
};

/**
 * This function looks for the inferred or roll-up date of an issue and converts it to the default format.
 *
 * @param issue Issue.
 * @param dateType type of date field
`*
 * @returns Returns formatted date.
 */
export const getInferredDate = (issue: ScopeIssue, dateType: DateFieldTypes): string => {
	const issueFieldDateType = issueField[dateType];
	const date = typeof issueFieldDateType !== 'undefined' ? issue[issueFieldDateType] : undefined;
	return isDefined(date) ? formatDateUTC(date, DefaultDateFormat) : '';
};

export const getDateInferenceMethod = (
	issue: ScopeIssue,
	type: DateFieldTypes,
): IssueInferredDateSelection => {
	if (type !== DateTypes.Other) {
		const attribute = issueField[type];
		const inferredFrom = issue?.inferred?.[attribute] ?? ISSUE_INFERRED_DATE_SELECTION.NONE;
		return inferredFrom;
	}

	return ISSUE_INFERRED_DATE_SELECTION.NONE;
};

/**
 * This function looks for the configured dates of the plan and returns their rolled-up or inferred values.
 *
 * @param issue Issue.
 * @param dateConfiguration Object including the "baselineStartField" and "baselineEndField" of the plan.
`*
 * @returns Returns an object including the fields that are configured as start and end dates along with their corresponding values.
 */
export const getIssueInferredDates = (
	issue: ScopeIssue,
	dateConfiguration: DateConfiguration,
) => /**
 * Example 1:
 * "Use sprint dates when issues don't have start and end dates" is enabled
 * "View settings > Roll-up > Dates" is enabled
 * The plan's Start date is "Target start" and the plan's End date is "Due date"
 * The issue is a story with:
 * - no target start, but has one sub-task whose target start is 02/Apr/20
 * - a due date set to 14/Apr/20
 * Then the object returned is {targetStartRollUp: "02/Apr/20", dueDate: "14/Apr/20"}
 *
 * Example 2:
 * "Use sprint dates when issues don't have start and end dates" is enabled
 * "View settings > Roll-up > Dates" is enabled
 * The plan's Start date is "Custom date" (a custom field) and the plan's End date is "Target end"
 * The issue is a story:
 * - with no custom date and no target end date set
 * - assigned to a sprint which starts on 23/Mar/20 and ends on 06/Apr/20
 * Then the object returned is {10202: "23/Mar/20", targetEnd: "06/Apr/20"}
 *
 */ ({
	[`${dateConfiguration.baselineStartField.key}${
		getDateInferenceMethod(issue, DateTypes.Start) === ISSUE_INFERRED_DATE_SELECTION.ROLL_UP
			? 'RollUp'
			: ''
	}`]: getInferredDate(issue, DateTypes.Start),
	[`${dateConfiguration.baselineEndField.key}${
		getDateInferenceMethod(issue, DateTypes.End) === ISSUE_INFERRED_DATE_SELECTION.ROLL_UP
			? 'RollUp'
			: ''
	}`]: getInferredDate(issue, DateTypes.End),
});

/**
 * This function looks for the direct parent of an issue.
 *
 * @param issueParentId Issue parent id.
 * @param issueMapById Issue map by id.
 *
 * @returns Returns the parent summary of the issue, or null if the issue parent id was not found in the issueMapById.
 */
export const getIssueParentSummary = (
	issueParentId: string,
	issueMapById: Record<string, Issue>,
) => {
	const issueParent = issueMapById[issueParentId];

	if (issueParent) {
		return issueParent.summary;
	}

	return null;
};

/**
 * This function looks for the priority name of an issue in the issuePriorities props.
 *
 * @param issuePriorityId Issue priority id.
 * @param issuePriorities Issue priorities.
 *
 * @returns Returns the priority name of the issue, or null if the issue priority id was not found in the issue priorities.
 */
export const getIssuePriorityName = (
	issuePriorityId: string,
	issuePriorities: {
		[key: string]: IssuePriority;
	},
) => {
	const issuePriority = issuePriorities[issuePriorityId];

	if (issuePriority) {
		return issuePriority.name;
	}

	return null;
};

/**
 * This function joins an array of labels of an issue into a string.
 *
 * @param issueLabels Issue labels.
 *
 * @returns Returns the labels of the issue joined by with ", ".
 */
export const joinIssueLabels = (issueLabels: string[]) => issueLabels.join(', ');

/**
 * This function joins the components names of an issue into a string.
 *
 * @param issueComponents Issue components.
 * @param componentsById Components grouped by id.
 *
 * @returns Returns the component names of the issue joined together with ", "; or null if the issue does not have components.
 */
export const joinIssueComponents = (issueComponents: number[], componentsById: ComponentsById) => {
	// the issue can have one to many components stored in an array as follows:
	// 1 components: { issue: { components: [10300] } }
	// 2+ components: { issue: { components: [10301, 10302] } }
	// for each componentId, we look for its name in the componentsById props
	const issueComponentsNames = issueComponents
		.map((componentId) => (componentsById[componentId] ? componentsById[componentId].name : null))
		.filter(Boolean);

	// then we join the components names of the issue with ", " so it renders like "Component 2, Component 1" in the CSV
	if (issueComponentsNames && issueComponentsNames.length) {
		return issueComponentsNames.join(', ');
	}

	return null;
};

/**
 * This function looks for the issue project key in the projects props and mixes it up with the issue key.
 *
 * @param issue Issue.
 * @param projectsById Projects grouped by id.
 *
 * @returns Returns the issue project key and the issue key concatenated with a "-"; or null if the issue project id was not found in projectsById.
 */
export const getIssueKey = (issue: ScopeIssue, projectsById: ProjectsById) => {
	const { issueKey = '', project } = issue;

	if (project && projectsById[project]) {
		return `${projectsById[project].key}${issueKey ? `-${issueKey}` : ''}`;
	}

	return null;
};

/**
 * This function looks for the selected options names of a specific custom field.
 *
 * @param issueCustomFields Issue custom fields.
 * @param customField Custom field.
 * @param selectOptionsById Selected options of custom fields grouped by id.
 * @param userPickerOptionsById User picker options indexed by id.
 *
 * @returns Returns the selected options names of a specific custom field; or null if the issue does not have selected options for that specific custom field.
 */
export const getIssueCustomFieldValue = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	issueCustomFields: Record<string | number, any> | null | undefined,
	customField: CustomField,
	selectOptionsById: Record<string, SelectOption>,
	userPickerOptionsById: Record<string, UserSelectItemProps>,
) => {
	const { id: customFieldId, type: customFieldType } = customField;
	// issueCustomFields is an object including all custom fields of the issue with their respective values e.g.
	// { 10302: "10101", 10500: ["10301", "10302"], 10700: 454545 }
	// in this case the issue has:
	// one SingleSelect custom field (id 10302) with one selected option (id 10101)
	// one MultiSelect custom field (id 10500) with two selected options (ids 10301, 10302)
	// one TextField custom field (id 10700) with value 4545454
	if (!issueCustomFields || !customFieldId || !customFieldType || !customFieldType.key) {
		return null;
	}

	switch (customFieldType.key) {
		// in the issueCustomFields object, the "DatePicker" custom field is stored as a key / value pair such as
		// { 10702: 1581379200000 }
		// the key is the custom field id, the value is a timestamp of the selected date
		case CustomFieldTypes.DatePicker: {
			// returning the custom field value in a formatted date
			return issueCustomFields[customFieldId]
				? formatDateUTC(issueCustomFields[customFieldId], DefaultDateFormat)
				: null;
		}

		// in the issueCustomFields object, the "Labels" custom field is stored as a key / value pair such as
		// 10704: ["Test", "UX", "needs-ix"]
		// the key is the custom field id, the value is an array of strings
		case CustomFieldTypes.Labels: {
			// returning all strings joined by ", "
			return issueCustomFields[customFieldId] ? issueCustomFields[customFieldId].join(', ') : null;
		}

		// in the issueCustomFields object, the "SingleSelect" and "RadioButtons" custom fields are stored as a key / value pair such as
		// { 10302: "10101" }
		// the key is the custom field id, the value is the id of the selected option of the custom field
		case CustomFieldTypes.RadioButtons:
		case CustomFieldTypes.SingleSelect: {
			// in this case we use the id of the selected option of the custom field to find its "value" in the selectOptionsById object
			return issueCustomFields[customFieldId] && selectOptionsById[issueCustomFields[customFieldId]]
				? selectOptionsById[issueCustomFields[customFieldId]].value
				: null;
		}

		// in the issueCustomFields object, the "MultiSelect" and "MultiCheckboxes" custom fields is stored as a key / value pair such as
		// { 10500: ["10301", "10302"] }
		// the key is the custom field id, the value is an array including the id(s) of the selected option(s) of the custom field
		case CustomFieldTypes.MultiCheckboxes:
		case CustomFieldTypes.AssetObject:
		case CustomFieldTypes.MultiSelect: {
			if (!fg('asset-custom-field-internal-support')) {
				if (customFieldType.key === CustomFieldTypes.AssetObject) {
					return issueCustomFields[customFieldId];
				}
			}

			// if the custom field id is included in the custom fields object of the issue
			return issueCustomFields[customFieldId]
				? issueCustomFields[customFieldId]
						// then we loop through the array of id(s) of the selected option(s) of the custom field and retrieve
						// the respective "value" in the selectOptionsById object
						.map((customFieldSelectedOptionId: number) =>
							selectOptionsById[customFieldSelectedOptionId]
								? selectOptionsById[customFieldSelectedOptionId].value
								: null,
						)
						.filter(Boolean)
						.join(', ')
				: null;
		}

		// in the issueCustomFields object, the "UserPicker" custom field is stored as a key / value pair such as
		// { 10711: "5dba90b3de06db0da26c29c4" }
		// the key is the custom field id, the value is the id of a user
		case CustomFieldTypes.UserPicker: {
			if (issueCustomFields[customFieldId] === 'unassigned') {
				return null;
			}

			const user = userPickerOptionsById[issueCustomFields[customFieldId]];

			if (user) {
				return user.label;
			}

			return null;
		}

		// for the other custom fields types ("NumberField", "TextField", "Url"), we simply return the value of the custom field
		// in this example the value "454545" will be returned for the "TextField" custom field
		default:
			return issueCustomFields[customFieldId];
	}
};

/**
 * This function looks for the status name of an issue in the issueStatusesById props.
 *
 * @param issueStatusId Issue status id.
 * @param issueStatusesById Issue statuses grouped by id.
 *
 * @returns Returns the status name of the issue, or null if the issue status id was not found in the issueStatusesById.
 */
export const getIssueStatusName = (issueStatusId: string, issueStatusesById: IssueStatusesById) => {
	const issueStatus = issueStatusesById[issueStatusId];

	if (issueStatus) {
		return issueStatus.name;
	}

	return null;
};

/**
 * This function computes the progress breakdown of an issue.
 *
 * @param issue Issue.
 * @param issues Issues.
 * @param issueIdToDescendantsIdMap Descendants ids of an issue grouped by issue id.
 * @param issueStatusesById Issue statuses grouped by id.
 * @param issueMapById Issue map by id.
 * @param planningUnit Planning unit defined in the plan settings.
 * @param workingHours Working hours per day defined in the Jira systemInfo.
 * @param estimateFromHistoryIssues Estimate from history issues.
 *
 * @returns Returns the issue progress percentage, the amount of issues completed and the amount of issues remaining.
 */
export const getIssueEstimateProgressBreakdown = (
	issue: ScopeIssue,
	issueIdToDescendantsIdMap: {
		[key: string]: string[];
	},
	issueStatusesById: IssueStatusesById,
	issueMapById: {
		[key: string]: Issue;
	},
	planningUnit: string,
	workingHours: number,
	estimateFromHistoryIssues: HistoryIssueTotalEstimationByParentIdMap,
): Record<string, number> => {
	// setting the issue progress to 0 by default
	let progressRemaining = 0;
	let progressCompleted = 0;
	let progressPercentage = 0;

	// getBreakDownByEstimate returns an object that looks like e.g.
	// {
	//     "byCategoryId": {
	//       "2": 0,
	//       "3": 3,
	//       "4": 9
	//     },
	//     "total": 12,
	//     "unestimated": 0,
	//     "issueCount": 3
	//   }
	// in this example, there are:
	// TODO (byCategoryId["2"]) = 0
	// DONE (byCategoryId["3"]) = 3
	// IN PROGRESS (byCategoryId["4"]) = 9
	// TOTAL (total) = 12
	const issueBreakdownByEstimate = getBreakdownByEstimate(
		issue,
		issueIdToDescendantsIdMap,
		issueStatusesById,
		issueMapById,
		planningUnit,
		workingHours,
		estimateFromHistoryIssues,
	);

	if (issueBreakdownByEstimate) {
		const { byCategoryId, total } = issueBreakdownByEstimate;

		// if "byCategoryId" and "total" are defined in the "issueBreakdownByEstimate" object and if the total is higher than 0
		if (isDefined(byCategoryId) && isDefined(total) && total > 0) {
			// from the example above progressRemaining = 0 + 9 = 9
			progressRemaining =
				byCategoryId[ISSUE_STATUS_CATEGORIES.TODO] +
				byCategoryId[ISSUE_STATUS_CATEGORIES.INPROGRESS];

			// from the example above progressCompleted = 3
			progressCompleted = byCategoryId[ISSUE_STATUS_CATEGORIES.DONE];

			// from the example above progressPercentage = roundToTwoDigit( (3 / 12) * 100 ) = 25
			progressPercentage = roundToTwoDigit((progressCompleted / total) * 100);
		}
	}

	return {
		progressPercentage,
		progressCompleted,
		progressRemaining,
	};
};

/**
 * This function computes the progress issue count of an issue.
 *
 * @param issue Issue.
 * @param issues Issues.
 * @param issueStatusesById Issue statuses grouped by id.
 * @param issueMapById Issue map by id.
 * @param historyIssues Issue history.
 *
 * @returns Returns the issue progress percentage, the amount of issues completed and the amount of issues remaining.
 */
export const getIssueCountProgressBreakdown = (
	issue: ScopeIssue,
	issues: Issue[],
	issueStatusesById: IssueStatusesById,
	issueMapById: {
		[key: string]: Issue;
	},
	historyIssues: HistoryIssue[],
): Record<string, number> => {
	// setting the issue progress to 0 by default
	let progressIssueCount = 0;
	let toDoIssueCount = 0;
	let inProgressIssueCount = 0;
	let doneIssueCount = 0;
	let totalIssueCount = 0;

	// getBreakdown returns an object that looks like e.g.
	// {
	//     "byCategoryId": {
	//       "2": 1,
	//       "3": 2,
	//       "4": 4
	//     },
	//     "total": 7,
	//   }
	// in this example, there are:
	// TODO (byCategoryId["2"]) = 1
	// DONE (byCategoryId["3"]) = 2
	// IN PROGRESS (byCategoryId["4"]) = 4
	// TOTAL (total) = 7
	const issueCountBreakdown = getBreakdown(
		issue,
		mapGroupsToIds(getChildrenByParentPure(issues)),
		issueStatusesById,
		issueMapById,
		historyIssues,
	);

	if (issueCountBreakdown) {
		const { byCategoryId, total } = issueCountBreakdown;

		// if "byCategoryId" and "total" are defined in the "issueCountBreakdown" object and if the total is higher than 0
		if (isDefined(byCategoryId) && isDefined(total) && total > 0) {
			toDoIssueCount = byCategoryId[ISSUE_STATUS_CATEGORIES.TODO];
			inProgressIssueCount = byCategoryId[ISSUE_STATUS_CATEGORIES.INPROGRESS];
			doneIssueCount = byCategoryId[ISSUE_STATUS_CATEGORIES.DONE];
			totalIssueCount = total;

			// from the example above progressIssueCount = (2 / 7) * 100 = 28,57
			progressIssueCount = roundToTwoDigit((doneIssueCount / totalIssueCount) * 100);
		}
	}

	return {
		progressIssueCount,
		toDoIssueCount,
		inProgressIssueCount,
		doneIssueCount,
		totalIssueCount,
	};
};

/**
 * This function returns the label to use for the Estimate column heading of the CSV file.
 *
 * @param planningUnit Planning unit.
 * @param csvColumnHeadings Object including the column headings of the CSV file.
 * @param showRolledUpOthers Boolean to show roll-up values.
 *
 * @returns Returns a string including the correct label depending on the planning unit.
 */
export const getCsvEstimateLabelColumnHeading = (
	planningUnit: string,
	csvColumnHeadings: CsvColumnHeadings,
	showRolledUpOthers = false,
) => {
	if (planningUnit === PlanningUnits.days) {
		return showRolledUpOthers
			? csvColumnHeadings.estimateDaysRollUp
			: csvColumnHeadings.estimateDays;
	}

	if (planningUnit === PlanningUnits.hours) {
		return showRolledUpOthers
			? csvColumnHeadings.estimateHoursRollUp
			: csvColumnHeadings.estimateHours;
	}

	return showRolledUpOthers
		? csvColumnHeadings.estimateStoryPointsRollUp
		: csvColumnHeadings.estimateStoryPoints;
};

/**
 * This function returns the label to use for the Progress completed column heading of the CSV file.
 *
 * @param planningUnit Planning unit.
 * @param csvColumnHeadings Object including the column headings of the CSV file.
 *
 * @returns Returns a string including the correct label depending on the planning unit.
 */
export const getCsvProgressCompletedLabelColumnHeading = (
	planningUnit: string,
	csvColumnHeadings: CsvColumnHeadings,
) => {
	if (planningUnit === PlanningUnits.days) {
		return csvColumnHeadings.progressCompletedDays;
	}

	if (planningUnit === PlanningUnits.hours) {
		return csvColumnHeadings.progressCompletedHours;
	}

	return csvColumnHeadings.progressCompletedStoryPoints;
};

/**
 * This function returns the label to use for the Progress remaining column heading of the CSV file.
 *
 * @param planningUnit Planning unit.
 * @param csvColumnHeadings Object including the column headings of the CSV file.
 *
 * @returns Returns a string including the correct label depending on the planning unit.
 */
export const getCsvProgressRemainingLabelColumnHeading = (
	planningUnit: string,
	csvColumnHeadings: CsvColumnHeadings,
) => {
	if (planningUnit === PlanningUnits.days) {
		return csvColumnHeadings.progressRemainingDays;
	}

	if (planningUnit === PlanningUnits.hours) {
		return csvColumnHeadings.progressRemainingHours;
	}

	return csvColumnHeadings.progressRemainingStoryPoints;
};

/**
 * get Standard fields column headings - the order of columns / fields is defined in JPOS-4053
 * @param planningUnit Planning unit.
 * @param csvIntlMessages Object of internationalized messages.
 * @param showRolledUpOthers Boolean to show roll-up others.
 * @param showRolledUpDates Boolean to show roll-up dates.
 * @param dateConfiguration Object including the "baselineStartField" and "baselineEndField" of the plan.
 * @param isAtlasConnectInstalled When Atlas has not been configured, then Atlas fields will be hidden,
 * @returns standard list of columns headings
 */
const getStandardFieldsColumnHeadings = (
	planningUnit: string,
	csvIntlMessages: CsvIntlMessages,
	showRolledUpOthers: boolean,
	showRolledUpDates: boolean,
	dateConfiguration: DateConfiguration,
	isAtlasConnectInstalled: boolean,
) => {
	const { columnHeadings } = csvIntlMessages;
	const { baselineStartField, baselineEndField } = dateConfiguration;

	return (
		[
			{
				label: columnHeadings.type,
				value: 'type',
				show: true,
			},
			{
				label: columnHeadings.summary,
				value: 'summary',
				show: true,
			},
			{
				label: columnHeadings.project,
				value: 'project',
				show: true,
			},
			{
				label: columnHeadings.releaseRollUp,
				value: 'releaseRollUp',
				show: showRolledUpOthers,
			},
			{
				label: columnHeadings.release,
				value: 'release',
				show: true,
			},
			{
				label: columnHeadings.teamRollUp,
				value: 'teamRollUp',
				show: showRolledUpOthers,
			},
			{
				label: columnHeadings.team,
				value: 'team',
				show: true,
			},
			{
				label: columnHeadings.assignee,
				value: 'assignee',
				show: true,
			},
			{
				label: columnHeadings.reporter,
				value: 'reporter',
				show: true,
			},
			{
				label: columnHeadings.sprintRollUp,
				value: 'sprintRollUp',
				show: showRolledUpOthers,
			},
			{
				label: columnHeadings.sprint,
				value: 'sprint',
				show: true,
			},
			{
				label: columnHeadings.targetStartDateRollUp,
				value: 'targetStartRollUp',
				show:
					showRolledUpDates &&
					(baselineStartField.key === 'targetStart' || baselineEndField.key === 'targetStart'),
			},
			{
				label: columnHeadings.targetStartDate,
				value: 'targetStart',
				show: true,
			},
			{
				label: columnHeadings.targetEndDateRollUp,
				value: 'targetEndRollUp',
				show:
					showRolledUpDates &&
					(baselineStartField.key === 'targetEnd' || baselineEndField.key === 'targetEnd'),
			},
			{
				label: columnHeadings.targetEndDate,
				value: 'targetEnd',
				show: true,
			},
			{
				label: columnHeadings.dueDateRollUp,
				value: 'dueDateRollUp',
				show:
					showRolledUpDates &&
					(baselineStartField.key === 'dueDate' || baselineEndField.key === 'dueDate'),
			},
			{
				label: columnHeadings.dueDate,
				value: 'dueDate',
				show: true,
			},
			{
				label: getCsvEstimateLabelColumnHeading(planningUnit, columnHeadings, showRolledUpOthers),
				value: 'estimateRollUp',
				show: showRolledUpOthers,
			},
			{
				label: getCsvEstimateLabelColumnHeading(planningUnit, columnHeadings),
				value: 'estimate',
				show: true,
			},
			{
				label: columnHeadings.parent,
				value: 'parent',
				show: true,
			},
			{
				label: columnHeadings.priority,
				value: 'priority',
				show: true,
			},
			{
				label: columnHeadings.labels,
				value: 'labels',
				show: true,
			},
			{
				label: columnHeadings.components,
				value: 'components',
				show: true,
			},
			{
				label: columnHeadings.key,
				value: 'key',
				show: true,
			},
			{
				label: columnHeadings.status,
				value: 'status',
				show: true,
			},
			{
				label: columnHeadings[GOAL_NAME],
				value: GOAL_NAME,
				show: isAtlasConnectInstalled,
			},
			{
				label: columnHeadings[GOAL_STATUS],
				value: GOAL_STATUS,
				show: isAtlasConnectInstalled,
			},
			{
				label: columnHeadings[IDEA_NAME],
				value: IDEA_NAME,
				show: ff('polaris-arj-ideas'),
			},
		] as const
	).filter((item) => item.show);
};

/**
 * Get column headings for the progress breakdown
 * @param planningUnit Planning unit.
 * @param csvIntlMessages Object of internationalized messages.
 * @returns
 */
const getProgressBreakdownColumnHeadings = (
	planningUnit: string,
	csvIntlMessages: CsvIntlMessages,
) => {
	const { columnHeadings } = csvIntlMessages;
	return [
		{
			label: columnHeadings.progressPercentage,
			value: 'progressPercentage',
			show: true,
		},
		{
			label: getCsvProgressCompletedLabelColumnHeading(planningUnit, columnHeadings),
			value: 'progressCompleted',
			show: true,
		},
		{
			label: getCsvProgressRemainingLabelColumnHeading(planningUnit, columnHeadings),
			value: 'progressRemaining',
			show: true,
		},
	];
};

/**
 * Get column headings for the issue count breakdown
 * @param csvIntlMessages Object of internationalized messages.
 * @returns
 */
const getIssueCountBreakdownColumnHeadings = (csvIntlMessages: CsvIntlMessages) => {
	const { columnHeadings } = csvIntlMessages;
	return [
		{
			label: columnHeadings.progressIssueCount,
			value: 'progressIssueCount',
			show: true,
		},
		{
			label: columnHeadings.toDoIssueCount,
			value: 'toDoIssueCount',
			show: true,
		},
		{
			label: columnHeadings.inProgressIssueCount,
			value: 'inProgressIssueCount',
			show: true,
		},
		{
			label: columnHeadings.doneIssueCount,
			value: 'doneIssueCount',
			show: true,
		},
		{
			label: columnHeadings.totalIssueCount,
			value: 'totalIssueCount',
			show: true,
		},
	];
};

/**
 * This function gathers the column headings of the CSV file, defined as an array of objects.
 * Each object has a "label" that will be displayed in the CSV file and a "value" used to parse
 * the corresponding data returned by the getDataRecord function
 *
 * @param customFields Array of custom fields.
 * @param planningUnit Planning unit.
 * @param csvIntlMessages Object of internationalized messages.
 * @param showRolledUpOthers Boolean to show roll-up others.
 * @param showRolledUpDates Boolean to show roll-up dates.
 * @param dateConfiguration Object including the "baselineStartField" and "baselineEndField" of the plan.
 * @param isAtlasConnectInstalled When Atlas has not been configured, then Atlas fields will be hidden,
 *      e.g. Goal, Goal status
 *
 * @returns Returns an array including all the CSV column headings.
 */
export const getCSVColumnHeadings = (
	customFields: CustomField[],
	planningUnit: string,
	csvIntlMessages: CsvIntlMessages,
	showRolledUpOthers: boolean,
	showRolledUpDates: boolean,
	dateConfiguration: DateConfiguration,
	isAtlasConnectInstalled: boolean,
): ColumnHeading[] => {
	const { columnHeadings } = csvIntlMessages;

	// standard fields column headings - the order of columns / fields is defined in JPOS-4053
	const standardFieldsColumnHeadings = getStandardFieldsColumnHeadings(
		planningUnit,
		csvIntlMessages,
		showRolledUpOthers,
		showRolledUpDates,
		dateConfiguration,
		isAtlasConnectInstalled,
	);

	// column headings for the progress breakdown
	const progressBreakdownColumnHeadings = getProgressBreakdownColumnHeadings(
		planningUnit,
		csvIntlMessages,
	);

	// column headings for the issue count breakdown
	const issueCountBreakdownColumnHeadings = getIssueCountBreakdownColumnHeadings(csvIntlMessages);

	// column headings for the custom fields
	// for custom fields the "label" is the title of the custom field (e.g. "Eligible Cities") and the value is the custom field id (e.g. 10501)
	// By using the custom field id rather than the title as the value, the user can have several custom fields sharing the same title (which JIRA allows)
	const customFieldColumnHeadings = customFields.map((customField) => ({
		label: customField.title,
		value: String(customField.id),
		show: true,
	}));

	// if custom field is defined as one of the configurable date fields, the roll up column needs to be displayed before
	Object.values(dateConfiguration).forEach(({ key }) => {
		const index = customFieldColumnHeadings.findIndex(({ value }) => key === value);
		if (index === -1) {
			return;
		}
		const customField = customFields.find(({ id }) => key === String(id)) || {
			title: undefined,
			id: undefined,
		};

		customFieldColumnHeadings.splice(index, 0, {
			label: `${customField.title} ${columnHeadings.rollUp}`,
			value: `${customField.id}RollUp`,
			show: true,
		});
	});

	// returning all column headings
	return [
		...standardFieldsColumnHeadings,
		...progressBreakdownColumnHeadings,
		...issueCountBreakdownColumnHeadings,
		...customFieldColumnHeadings,
	];
};

/**
 *
 * @param planTitle
 * @param date
 * @param ext
 *
 * @returns Returns a string including the file name.
 */
const getExportFileName = (exportName = '', date: Date, ext = '') => {
	const fileName = [exportName.trim(), formatDateUTC(date.getTime(), EXPORT_FILE_DATE_FORMAT)]
		.filter(Boolean)
		.join('_');

	const safeFileName = fileName.replace(/[^a-z0-9]/gi, '_').toLowerCase();

	return `${safeFileName}.${ext}`;
};

/**
 * This function computes the file name of the CSV based on the Filename and today's date.
 *
 * @param exportName File name to be exported, will be suffixed with datetime.
 * @param date Today's date.
 *
 * @returns Returns a string including the CSV file name.
 */
export const getExportCsvFileName = (exportName = '', date: Date) =>
	getExportFileName(exportName, date, 'csv');

/**
 * This function computes the file name of the CSV based on the Filename and today's date.
 *
 * @param exportName File name to be exported, will be suffixed with datetime.
 * @param date Today's date.
 *
 * @returns Returns a string including the PNG file name.
 */
export const getExportPngFileName = (exportName = '', date: Date) =>
	getExportFileName(exportName, date, 'png');

export const prepareCSVData = (
	customFields: CustomField[],
	planningUnit: string,
	csvIntlMessages: CsvIntlMessages,
	showRolledUpOthers: boolean,
	showRolledUpDates: boolean,
	dateConfiguration: DateConfiguration,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	scopeItemsArray: Record<string, any>[],
	isAtlasConnectInstalled: boolean,
): string => {
	// header
	const headingsArray = getCSVColumnHeadings(
		customFields,
		planningUnit,
		csvIntlMessages,
		showRolledUpOthers,
		showRolledUpDates,
		dateConfiguration,
		isAtlasConnectInstalled,
	);

	return buildCSV(headingsArray, scopeItemsArray);
};
