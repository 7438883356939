import {
	isOptimizedMode,
	isReadOnly,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app';
import { getOnlyEnabledIssueId } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/timeline';
import { getWarnings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/warnings';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { OwnProps, StateProps } from './types';

export default createStructuredSelector<State, OwnProps, StateProps>({
	isReadOnly,
	warnings: getWarnings,
	showOptimizations: isOptimizedMode,
	onlyEnabledIssueId: getOnlyEnabledIssueId,
});
