import React, { Component, type ComponentType } from 'react';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import map from 'lodash/map';
import matchesProperty from 'lodash/matchesProperty';
import * as R from 'ramda';
import Avatar from '@atlaskit/avatar';
import { selectOptionWithAvatar } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import type { FormatOptionLabelMeta } from '@atlassian/jira-portfolio-3-common/src/select/types';
import { withSlots, slots } from '../../component-slots';
import { isDefined } from '../../ramda';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type {
	UserSelectItemProps,
	Props,
	State,
	GroupedUserOptions,
	UserOption,
	UserOptions,
} from './types';
import { filterOption, isGroupedOptions } from './utils';

export const UserSelectItem = ({ icon, email, label }: UserSelectItemProps) => (
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
	<div className={styles.option}>
		{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
		<div className={styles.optionAvatar}>
			<Avatar size="small" src={icon} borderColor="transparent" />
		</div>
		{email && label ? (
			<div>
				<div>{label}</div>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.optionEmail}>{email}</div>
			</div>
		) : (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.optionUnassigned}>{label}</div>
		)}
	</div>
);

export const formatOptionLabel = (opt: UserOption, { context }: FormatOptionLabelMeta) =>
	context === 'value' ? selectOptionWithAvatar(opt) : <UserSelectItem {...opt} />;

// eslint-disable-next-line jira/react/no-class-components
class UserPicker extends Component<
	Props & {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		FieldSelect: ComponentType<any>;
	},
	State
> {
	state = { query: '' };

	getDisplayedUsers(userOptions: UserOptions, query?: string): UserOptions {
		const { maxOptionsPerGroup } = this.props;
		if (maxOptionsPerGroup) {
			const getFullText: (option: UserOption) => string = R.compose<
				UserOption,
				string[],
				string,
				string
			>(
				R.toLower,
				R.join(' '),
				// @ts-expect-error R.Props seems very difficult to make TS happy
				R.props(['label', 'email']),
			);
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const matchQuery = R.curry((query: string, option: UserOption): boolean =>
				R.includes(R.toLower(query))(getFullText(option)),
			);

			const transformOptions = R.compose<UserOption[], UserOption[], UserOption[]>(
				R.take(maxOptionsPerGroup),
				(array: UserOption[]) => (query ? R.filter(matchQuery(query), array) : array),
			);

			return isGroupedOptions(userOptions)
				? R.map<GroupedUserOptions, GroupedUserOptions>(({ options, ...rest }) => ({
						options: transformOptions(options),
						...rest,
					}))(userOptions)
				: transformOptions(userOptions);
		}
		return userOptions;
	}

	onUserChange = (selection: UserOption | null) => {
		const { onUserChange } = this.props;
		if (selection && typeof selection.value === 'string') {
			onUserChange(selection.value);
		} else {
			onUserChange(null);
		}
	};

	onMenuOpen = () => {
		const { onFetchUserList, updateUserOption, user } = this.props;
		onFetchUserList('');
		if (isDefined(updateUserOption)) {
			updateUserOption(user && user.value);
		}
	};

	onMenuClose = () => {
		this.setState(() => ({ query: '' }));
	};

	render() {
		const { FieldSelect, user, userList, selectProps, issueId, attribute, placeholder } =
			this.props;

		const currentUserValue = user?.value || '';

		const selectedUser = isGroupedOptions(userList)
			? find(flatMap(map(userList, 'options')), matchesProperty('value', currentUserValue))
			: find(userList, matchesProperty('value', currentUserValue));

		const onQuerySearch = (query: string, { action }: { action?: string }) => {
			if (action === 'input-change') {
				const { onFetchUserList } = this.props;
				onFetchUserList(query);
				this.setState(() => ({ query }));
			}
		};

		const props = {
			formatOptionLabel,
			options: this.getDisplayedUsers(userList, this.state.query),
			onChange: this.onUserChange,
			onMenuOpen: this.onMenuOpen,
			onMenuClose: this.onMenuClose,
			onInputChange: onQuerySearch,
			value: isDefined(selectedUser) ? selectedUser : null,
			issueId,
			attribute,
			filterOption,
			placeholder,
			...selectProps,
		};

		return <FieldSelect {...props} />;
	}
}

export type { UserPicker as UserPickerComponent };
export default withSlots({ FieldSelect: slots.FieldSelect })(UserPicker);
