import React, { useRef, useEffect } from 'react';
import { useActiveLine } from '@atlassian/jira-portfolio-3-dependency-lines/src/controllers/index.tsx';
import DependencyLinesLineDetail from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/timeline/schedule/dependency-lines/line-detail/index.tsx';
import {
	useScrollX,
	useScrollY,
} from '@atlassian/jira-portfolio-3-treegrid/src/controllers/container/index.tsx';
import type { Props } from './types';

export const DependencyLineDetail = ({ scope }: Props) => {
	const [activeLine, { setActiveLine }] = useActiveLine();
	const [scrollX] = useScrollX();
	const [scrollY] = useScrollY();
	const shouldReset = useRef(false);

	shouldReset.current = activeLine !== null;

	useEffect(() => {
		if (shouldReset.current) {
			setActiveLine(null);
		}
	}, [setActiveLine, scrollX, scrollY]);

	return <DependencyLinesLineDetail scope={scope} />;
};
