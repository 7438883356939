import React from 'react';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import { slots, withSlots } from '@atlassian/jira-portfolio-3-portfolio/src/common/component-slots';
import CommonLabel from '@atlassian/jira-portfolio-3-portfolio/src/common/view/label';
import LabelProvider from './labels-provider';
import type { Props } from './types';

export const LabelRenderer = (props: Props) => {
	const getValues = (issue: ScopeIssue, attribute: string) => {
		// @ts-expect-error - attribute is any string
		if (!issue[attribute] || !Array.isArray(issue[attribute])) {
			return [];
		}
		// @ts-expect-error - TS7006 - Parameter 'label' implicitly has an 'any' type.
		return issue[attribute].map((label: string) => ({
			label,
			value: label,
		}));
	};
	return <CommonLabel {...props} getValues={getValues} />;
};

export default LabelProvider(
	withSlots({
		MultiSelectCell: slots.FieldMultiSelectCell,
	})(LabelRenderer),
);
