import React, { Component } from 'react';
import * as R from 'ramda';
import Popup from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { ff } from '@atlassian/jira-feature-flagging';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import {
	getCurrentValue,
	getOptimizedValue,
	hasValueChanged,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/util';
import ChangeIndicator from '@atlassian/jira-portfolio-3-portfolio/src/common/view/change-indicator';
import Flyout from './flyout/view';
import type { Props, State } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renderFlyout = (current: any, optimized: any, delta: string) => (
	<Flyout current={current} optimized={optimized} delta={delta} />
);

// eslint-disable-next-line jira/react/no-class-components
export default class Cell extends Component<Props, State> {
	static defaultProps = {
		ChangeIndicator,
	};

	state = {
		showFlyout: false,
	};

	showFlyout = () => {
		this.setState({
			showFlyout: true,
		});
	};

	hideFlyout = () => {
		this.setState({
			showFlyout: false,
		});
	};

	render() {
		const { showFlyout } = this.state;
		const {
			issue,
			attribute,
			valueMapper,
			showOptimizations,
			delta,
			isCellDirty,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			ChangeIndicator,
			locale,
			children,
		} = this.props;

		let isDirty = false;
		if (isCellDirty) {
			isDirty = isCellDirty();
		} else {
			isDirty = issue.originals && R.has(attribute, issue.originals);
		}

		const shouldShowChangeIndicator = isDirty && !showOptimizations;

		let shouldShowFlyout = false;
		let flyout = null;

		if (showOptimizations) {
			let optimizedValue = getOptimizedValue(issue, attribute);
			let currentValue = getCurrentValue(issue, attribute);
			const hasOptimizedValue = hasValueChanged(currentValue, optimizedValue);
			const deltaValue =
				typeof delta === 'function' ? delta(currentValue, optimizedValue, locale) : '';

			if (valueMapper) {
				optimizedValue = valueMapper(issue, optimizedValue);
				currentValue = valueMapper(issue, currentValue);
			}
			flyout = renderFlyout(currentValue, optimizedValue, deltaValue);

			shouldShowFlyout = hasOptimizedValue && showFlyout;
		}

		if (ff('com.atlassian.rm.jpo.transposition')) {
			return (
				<Popup
					trigger={({ ref }) => (
						<div
							ref={ref}
							onMouseEnter={this.showFlyout}
							onMouseLeave={this.hideFlyout}
							data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.column.cell.div"
						>
							{children}
							{shouldShowChangeIndicator && ChangeIndicator && <ChangeIndicator />}
						</div>
					)}
					isOpen={shouldShowFlyout}
					content={() => <Box xcss={flyoutWrapperStyles}>{flyout}</Box>}
				/>
			);
		}

		return (
			<div
				onMouseEnter={this.showFlyout}
				onMouseLeave={this.hideFlyout}
				data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.column.cell.div"
			>
				<InlineDialog content={flyout} isOpen={shouldShowFlyout}>
					{this.props.children}
					{shouldShowChangeIndicator && ChangeIndicator && <ChangeIndicator />}
				</InlineDialog>
			</div>
		);
	}
}

const flyoutWrapperStyles = xcss({
	padding: 'space.200',
});
