import { getFilters } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters';
import { IDEA_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { StateProps } from './types';

const mapStateToProps: MapStateToProps<StateProps> = createSelector(
	getFilters,
	({ [IDEA_FILTER_ID]: { id, value } }) => ({
		id,
		value,
	}),
);

export default mapStateToProps;
