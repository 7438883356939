import { useEffect } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import planBridge from '@atlassian/jira-portfolio-3-bridge';
import {
	BridgeEventValue,
	type BridgeEvent,
} from '@atlassian/jira-portfolio-3-bridge/src/types.tsx';
import type { AddActionPayload } from '../../command/issue-links';
import type {
	AddIssuePayload,
	RankIssuePayload,
	UpdateIssueDatesPayload,
} from '../../command/issue/types.tsx';
import type { RemoveActionPayload } from '../../state/domain/issue-links/actions.tsx';
import type { PlannedCapacity } from '../../state/domain/planned-capacities/types';
import type { Props } from './types';

/** subscribe to events from the eventBus using the usePlanBridgeConsumer hook, and call the updateOrInlineCreate action on updateIssue */
const BridgeEventSubscriberView = ({
	updateIssue,
	rankIssue,
	bulkUpdateIssues,
	addIssue,
	addIssueLink,
	removeIssueLink,
	updatePlannedCapacity,
	removePlannedCapacity,
	issueTypesById,
	updateIssueDatesOptimistic,
}: Props) => {
	const createPayload = (event: BridgeEvent): unknown => ({
		...event.payload,
		promise: {
			resolve: event.resolve,
			reject: event.reject,
		},
	});

	useEffect(() => {
		planBridge.subscribe(BridgeEventValue.UPDATE_ISSUE, async (event: BridgeEvent) => {
			updateIssue(createPayload(event));
		});
		planBridge.subscribe(BridgeEventValue.RANK_ISSUE, async (event: BridgeEvent) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			rankIssue(createPayload(event) as RankIssuePayload);
		});
		planBridge.subscribe(BridgeEventValue.UPDATE_ISSUES, async (event: BridgeEvent) => {
			bulkUpdateIssues(createPayload(event));
		});
		planBridge.subscribe(BridgeEventValue.ADD_ISSUE, async (event: BridgeEvent) => {
			const newIssue = event.payload;
			if (
				ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1') &&
				newIssue.level === undefined
			) {
				const issueType = issueTypesById[Number(newIssue.type)];
				if (issueType) {
					newIssue.level = issueType.level;
				}
			}
			addIssue(
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				createPayload({
					...event,
					payload: { issue: newIssue, skipRanking: true },
				}) as AddIssuePayload,
			);
		});
		planBridge.subscribe(BridgeEventValue.ADD_ISSUE_LINK, async (event: BridgeEvent) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			addIssueLink(createPayload(event) as AddActionPayload);
		});
		planBridge.subscribe(BridgeEventValue.REMOVE_ISSUE_LINK, async (event: BridgeEvent) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			removeIssueLink(createPayload(event) as RemoveActionPayload);
		});
		planBridge.subscribe(BridgeEventValue.UPDATE_PLANNED_CAPACITY, async (event: BridgeEvent) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			updatePlannedCapacity(createPayload(event) as PlannedCapacity);
		});
		planBridge.subscribe(BridgeEventValue.REMOVE_PLANNED_CAPACITY, async (event: BridgeEvent) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			removePlannedCapacity(createPayload(event) as PlannedCapacity);
		});
		planBridge.subscribe(
			BridgeEventValue.UPDATE_ISSUE_DATES_OPTIMISTIC,
			async (event: BridgeEvent) => {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				updateIssueDatesOptimistic(createPayload(event) as UpdateIssueDatesPayload);
			},
		);
		planBridge.ready();
	}, [
		updateIssue,
		rankIssue,
		bulkUpdateIssues,
		addIssue,
		addIssueLink,
		removeIssueLink,
		removePlannedCapacity,
		updatePlannedCapacity,
		issueTypesById,
		updateIssueDatesOptimistic,
	]);

	return null;
};

export default BridgeEventSubscriberView;
