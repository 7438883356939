import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	defaultPrompt: {
		id: 'portfolio-3-ai-work-breakdown.common.header.default-prompt',
		defaultMessage:
			'Suggest {issueType, select, tasks {subtasks} issues {child issues} other {child issues}}',
		description: 'Default prompt in Issue Breakdown if no prompt was entered.',
	},
	headerButtonAriaLabel: {
		id: 'portfolio-3-ai-work-breakdown.common.header.header-button-aria-label',
		defaultMessage: 'Click header to edit prompt',
		description: 'Aria label for he',
	},
	betaLozenge: {
		id: 'portfolio-3-ai-work-breakdown.common.header.beta-lozenge',
		defaultMessage: 'Beta',
		description: 'Text in beta lozenge in the footer.',
	},
	labelDiscardSuggestions: {
		id: 'portfolio-3-ai-work-breakdown.common.header.label-discard-suggestions',
		defaultMessage: 'Discard and close',
		description: 'Discard suggestions button label.',
	},
	footerTextDraftList: {
		id: 'portfolio-3-ai-work-breakdown.common.header.footer-text-draft-list',
		defaultMessage: 'Ask AI to refine suggestions.',
		description: 'Footer text in the Issue Breakdown prompt.',
	},
});
