import { connect } from '@atlassian/jira-react-redux';
import type { State } from '../../state/types';
import mapDispatchToProps from './command';
import mapStateToProps from './query';
import type { StateProps, DispatchProps, OwnProps } from './types';
import View from './view';

export default connect<StateProps, DispatchProps, OwnProps, State>(
	mapStateToProps,
	mapDispatchToProps,
)(View);
