import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	scope: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.subheader.scope.scope',
		defaultMessage: 'Scope',
		description: 'Header for the Scope region',
	},
	selectedIssues: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.subheader.scope.selected-issues',
		defaultMessage: '{totalSelected, plural, one {# issue} other {# issues}} selected',
		description: 'Bulk actions dropdown button',
	},
});
