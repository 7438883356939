import { getEnrichedCrossProjectVersionsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/cross-project-versions';
import { getReleaseBarState } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/release-bar';
import { getTimelineRange } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/timeline';
import {
	getSolutionVersionsById,
	getEnrichedVersionsById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions';
import { getHighlightedVersionsViewSettings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export default createStructuredSelector<State, {}, StateProps>({
	solutionVersionsById: getSolutionVersionsById,
	timelineRange: getTimelineRange,
	releaseBarState: getReleaseBarState,
	crossProjectVersionsById: getEnrichedCrossProjectVersionsById,
	versionsById: getEnrichedVersionsById,
	highlightedVersionIds: (state: State) => getHighlightedVersionsViewSettings(state).versions,
});
