import type { Dispatch } from 'redux';
import {
	addAtlassianTeam,
	createTeam,
	deleteTeam,
	type EditedTeam,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/teams';
import type {
	AtlassianTeamPayload,
	DeleteTeamPayload,
	TeamPayload,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/teams/api';
import {
	openTeamsDialog,
	closeTeamsDialog,
	setMode,
	CREATE_MODE,
	EDIT_MODE,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/teams/list/actions';
import type { DispatchProps } from './types';

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	openTeamsDialog: (team?: EditedTeam) => {
		dispatch(openTeamsDialog(team));
		if (team) {
			dispatch(setMode(EDIT_MODE));
		} else {
			dispatch(setMode(CREATE_MODE));
		}
	},
	createTeam: (payload: TeamPayload) => {
		dispatch(createTeam(payload));
	},
	onAddAtlassianTeam: (payload: AtlassianTeamPayload) => {
		dispatch(addAtlassianTeam(payload));
	},
	closeTeamsDialog: () => {
		dispatch(closeTeamsDialog());
	},
	deleteTeam: (payload: DeleteTeamPayload) => {
		dispatch(deleteTeam(payload));
	},
});

export default mapDispatchToProps;
