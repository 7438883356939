import * as R from 'ramda';
import type {
	VersionValues,
	SolutionVersion,
	SolutionProject,
	ReleaseStatus,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import {
	ascend,
	indexBy,
	isDefined,
	descend,
	values,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import {
	RELEASE_STATUSES,
	ENTITY,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import type { Warnings } from '@atlassian/jira-portfolio-3-portfolio/src/common/warning-details/types';
import type { Adjustments } from '../../state/domain/lexorank/types.tsx';
import type {
	OriginalVersion,
	OriginalVersions,
} from '../../state/domain/original-versions/types.tsx';
import type { Project } from '../../state/domain/projects/types.tsx';
import type { Solution } from '../../state/domain/solution/types.tsx';
import type {
	EntityMetadata,
	ChangeMetadata,
} from '../../state/domain/update-jira/changes/types.tsx';
import type { Version } from '../../state/domain/versions/types.tsx';
import type { State } from '../../state/types';
import { getVersionLexoRankAdjustments } from '../lexorank';
import { getProjects, getProjectsById } from '../projects';
import type { ProjectsById } from '../projects/types.tsx';
import { getSolution } from '../solution';
import type {
	EnrichedVersion,
	EnrichedVersionsById,
	VersionChange,
	VersionsById,
	SolutionVersionsById,
	ReleaseStatusesById,
} from './types';
import { getChangedAttributes, getChangedAttributeName } from './utils';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { getStartDateMethod, getEndDateMethod } from './utils';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { DATE_METHODS } from './types';

export const getVersions = (state: State): Version[] => state.domain.versions;

export const getVersionsById = createSelector(
	[getVersions],
	(versions): VersionsById => indexBy(R.prop('id'), versions),
);

export const getRemovedVersions = (state: State): Version[] => state.domain.removedVersions;

export const getRemovedVersionsById = createSelector(
	[getRemovedVersions],
	(versions): VersionsById => indexBy(R.prop('id'), versions),
);

export const getReleaseStatuses = (state: State): ReleaseStatus[] => state.domain.releaseStatuses;

export const getReleaseStatusesById = createSelector(
	[getReleaseStatuses],
	(releaseStatuses: ReleaseStatus[]): ReleaseStatusesById => indexBy(R.prop('id'), releaseStatuses),
);

export const getSortByHighestRankPure =
	(lexoRankAdjustments: Adjustments) =>
	(versions: Version[]): Version[] =>
		R.sortWith([
			descend<Version, string>(({ lexoRank }) => lexoRank || ''),
			descend(({ id }) => lexoRankAdjustments.indexOf(id)),
		])(versions);

export const getSortByHighestRank = createSelector(
	[getVersionLexoRankAdjustments],
	getSortByHighestRankPure,
);

export const getSortByLowestRankPure =
	(lexoRankAdjustments: Adjustments) =>
	(versions: Version[]): Version[] =>
		R.sortWith([
			ascend<Version, string>(({ lexoRank }) => lexoRank || ''),
			ascend(({ id }) => lexoRankAdjustments.indexOf(id)),
		])(versions);

export const getSortByLowestRank = createSelector(
	[getVersionLexoRankAdjustments],
	getSortByLowestRankPure,
);

export const getVersionWithHighestRankPure = (
	versions: Version[],
	sortVersionsByHighestRank: (arg1: Version[]) => Version[],
): Version | null | undefined => {
	if (versions.length === 0) {
		return;
	}
	if (versions.length === 1) {
		return versions[0];
	}

	const sortedVersions: Version[] = sortVersionsByHighestRank(versions);

	return sortedVersions[0];
};

export const getVersionWithHighestRank = createSelector(
	[getVersions, getSortByHighestRank],
	getVersionWithHighestRankPure,
);

export const getVersionWithHighestRankInProject = (state: State, projectId: number) => {
	const versions: Version[] = getVersions(state);
	const versionsInProject = versions.filter((version: Version) =>
		version.projects.includes(projectId),
	);
	return getVersionWithHighestRankPure(versionsInProject, getSortByHighestRank(state));
};

export const getOriginalVersions = (state: State): OriginalVersions =>
	state.domain.originalVersions;

export const getCommitWarningsForVersions = (state: State): Warnings =>
	state.domain.updateJira.warnings[ENTITY.RELEASE];

export const getEnrichedVersionsPure = (
	versions: Version[],
	projects: Project[],
): EnrichedVersion[] => {
	const projectsByIdMap = indexBy(R.prop('id'), projects);

	// eslint-disable-next-line @typescript-eslint/no-shadow
	return versions.map(({ projects, ...rest }) => ({
		...rest,
		projects: projects.map((id) => projectsByIdMap[id]),
	}));
};

export const getEnrichedVersions = createSelector(
	[getVersions, getProjects],
	getEnrichedVersionsPure,
);

export const getSolutionVersionsPure = (solution: Solution): SolutionVersion[] => {
	if (!isDefined(solution)) {
		return [];
	}
	const solutionProjects: SolutionProject[] = values(solution.projects);
	return solutionProjects.flatMap(({ versions }: SolutionProject) => versions);
};

export const getSolutionVersions = createSelector([getSolution], getSolutionVersionsPure);

export const getSolutionVersionsById = createSelector(
	[getSolutionVersions],
	(solutionVersions): SolutionVersionsById => indexBy(R.prop('key'), solutionVersions),
);

export const getVersionChangeCount = ({ domain: { originalVersions } }: State) =>
	Object.keys(originalVersions).length;

export const getVersionChangesMetadata = ({ domain: { updateJira } }: State): EntityMetadata => {
	if (updateJira && updateJira.changes && updateJira.changes.data) {
		return updateJira.changes.data.metaData.versions;
	}
	return {};
};

export const getVersionChangesPure = (
	versions: Version[],
	removedVersions: Version[],
	originalVersions: OriginalVersions,
	versionChangesMetadata: EntityMetadata,
	warnings: Warnings,
	projectsById: ProjectsById,
): VersionChange[] => {
	const enrichedVersions: EnrichedVersion[] = [...versions, ...removedVersions].map(
		({ projects, ...rest }) => ({
			...rest,
			projects: projects.map((id) => projectsById[id]),
		}),
	);
	const versionsByIdMap = indexBy(R.prop('id'), enrichedVersions);
	const changes = Object.keys(versionChangesMetadata)
		.filter((versionId: string) => isDefined(versionsByIdMap[versionId]))
		.filter((versionId: string) => isDefined(originalVersions[versionId]))
		.map((versionId: string) => {
			const version: EnrichedVersion = versionsByIdMap[versionId];
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const { id, projects, ...values } = version;
			const originalValues: OriginalVersion = originalVersions[versionId];
			const metaData: ChangeMetadata = versionChangesMetadata[versionId];
			const { scenarioType } = metaData;
			const changedAttributes: (keyof VersionValues)[] = getChangedAttributes(
				values,
				originalValues,
				scenarioType,
			);
			return {
				id,
				category: ENTITY.RELEASE,
				metaData,
				changeCount: changedAttributes.length,
				warnings: warnings[versionId] || [],
				attributeName: getChangedAttributeName(changedAttributes, scenarioType),
				details: {
					id,
					projects,
					values: {
						...values,
					},
					originals: {
						...originalValues,
					},
				},
			};
		});
	// @ts-expect-error TS2322 - Type '(number | Readonly<{ id: number; key: string; name: string; avatarUrl: string; versions: string[]; components?: Component[] | undefined; issueTypeIds: number[]; isDefault?: boolean | undefined; ... 4 more ...; projectTypeKey: "software" | ... 1 more ... | "service_desk"; }>)[]' is not assignable to type 'number[] & Readonly<{ id: number; key: string; name: string; avatarUrl: string; versions: string[]; components?: Component[] | undefined; issueTypeIds: number[]; isDefault?: boolean | undefined; ... 4 more ...; projectTypeKey: "software" | ... 1 more ... | "service_desk"; }>[]'.
	return changes;
};

export const getVersionChanges = createSelector(
	[
		getVersions,
		getRemovedVersions,
		getOriginalVersions,
		getVersionChangesMetadata,
		getCommitWarningsForVersions,
		getProjectsById,
	],
	getVersionChangesPure,
);

export const getReleasesTableRankingStatus = (state: State) =>
	state.ui.Main.Tabs.Releases.ProjectReleases.ReleasesTable.isRanking;

export const getEnrichedVersionsById = createSelector(
	[getEnrichedVersions],
	(versions): EnrichedVersionsById => indexBy(R.prop('id'), versions),
);

/*
 * This function:
 * 1. Groups input versions by crossProjectVersion (id), and discards versions without a CPV
 * 2. For each group, extract the project (ids) from each version in the group
 * 3. Returns a map of CPV id : list of joined projects from previous step
 */
export const getProjectIdsByCrossProjectVersionsMapPure = (
	versions: Version[],
): Record<string, number[]> =>
	R.pipe(
		R.groupBy((version: Version) => version.crossProjectVersion || 'omit'),
		R.dissoc('omit'),
		(versionsByCPV: Record<string, Version[]>): Record<string, number[]> =>
			Object.keys(versionsByCPV).reduce<Record<string, number[]>>((projectIdsByCPR, cprId) => {
				const versionsWithCPV: Version[] = versionsByCPV[cprId];
				const projects: number[] = R.reduce(
					// eslint-disable-next-line @typescript-eslint/no-shadow
					(projects: number[], v: Version) => R.union(projects, v.projects),
					[],
				)(versionsWithCPV);
				return Object.assign(projectIdsByCPR, {
					[cprId]: projects,
				});
			}, {}),
	)(versions);

export const getProjectIdsByCrossProjectVersionsMap = createSelector<
	State,
	Version[],
	Record<string, number[]>
>([getVersions], getProjectIdsByCrossProjectVersionsMapPure);

const getCompletedVersionsPure = (versions: Array<Version>) =>
	R.filter((version) => version.releaseStatusId === RELEASE_STATUSES.RELEASED, versions);

const getCompletedVersions = createSelector([getVersions], getCompletedVersionsPure);

export const getCompletedVersionsById = createSelector([getCompletedVersions], (completedVersion) =>
	indexBy(R.prop('id'), completedVersion),
);
