import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useHorizontalScrolling } from '../../controllers';

/**
 * This components will act as an overlay above the schedule when the user is scrolling using the scrollbar.
 * This will avoid interactions with the plan when scrolling
 */
const HorizontalScrollbarOverlay = () => {
	const [{ isScrollingWithScrollBar }] = useHorizontalScrolling();

	if (!isScrollingWithScrollBar) {
		return null;
	}
	return (
		<div data-testid="portfolio-3-horizontal-scrolling.ui.scroll-bar-overlay.scrollbar-overlay">
			<Box
				xcss={scrollbarOverlayStyles}
				// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={{ zIndex: 'var(--jpo-z-index-horizontal-scrolling-overlay)' }}
			/>
		</div>
	);
};

export default HorizontalScrollbarOverlay;

const scrollbarOverlayStyles = xcss({
	position: 'absolute',
	left: 'space.0',
	right: 'space.0',
	top: 'space.0',
	bottom: 'space.100',
});
