import React from 'react';
import { ExperienceFailureTracker as ViewExperienceFailureTracker } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracker-consumer/result-declared/index.tsx';
import { isEnvironmentError } from '@atlassian/jira-portfolio-3-portfolio/src/common/error';
import type { Errors } from '../../state/ui/errors/types';
import useBasePath from '../use-base-path';
import type { Props } from './types';

/** Report experience failure in the presence of errors
 * with the errorType depending on the kind of the error.
 *
 * Some errors are more likely to be attributed to the environment rather than
 * malfunction of our application.  We'd like to monitor experience failures
 * caused by these errors anyway and action on their spike but we also would
 * like to exclude them from our reliability SLO calculation, hence assigning
 * them dedicated errorType.
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ errors, location, locationType, locationPrefix }: Props) => {
	const basePath = useBasePath();

	const relevantErrors: Errors = errors.filter((error) => {
		if (locationPrefix === undefined) return true;

		switch (locationType) {
			case 'hash':
				return error.locationHash?.startsWith(locationPrefix);
			case 'path':
				return error.locationPath?.startsWith(`${basePath}/${locationPrefix}`);
			default:
				return true;
		}
	});

	if (relevantErrors.length === 0) return null;

	// If at least one error is not caused by the environment
	// then we consider it a regular failure impacting reliability SLO.
	const failureEventAttributes = relevantErrors.every(isEnvironmentError)
		? // Technically our definition of environment error is wider than network error
			// but in order to keep metric cardinality low we overload meaning of this type.
			{ errorType: 'networkError' }
		: null;

	return (
		<ViewExperienceFailureTracker
			location={location}
			failureEventAttributes={failureEventAttributes}
		/>
	);
};
