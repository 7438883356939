import React from 'react';
import ContentLoader from 'react-content-loader';
import { DEFAULT_CONTENT_LOADER_PROPS, RECT_BORDER_RADIUS, RECT_ROW_HEIGHT } from './common';

export const CellSkeleton = ({
	width,
	height = RECT_ROW_HEIGHT,
}: {
	width: number;
	height?: number;
}) => (
	<ContentLoader width={`${width}px`} height={`${height}px`} {...DEFAULT_CONTENT_LOADER_PROPS}>
		<rect {...RECT_BORDER_RADIUS} width={width} height={height} />
	</ContentLoader>
);
