import type { PersonsRequestBody, UsersRequestBody } from '../../state/domain/assignees/types';

export const urls = {
	assignees: '/rest/teams/1.0/persons/find', //  search for persons by providing some query string,  Returning result also include the virtual users.
	users: '/rest/jpo/1.0/users/get', // get users list by providing a list of user keys. Only returning users are only users which exists in Jira.
} as const;

export const LIMIT_OF_USERS = 50;

export const personsRequestBody = ({
	query = '',
	excludedIds = [],
	maxResults = LIMIT_OF_USERS,
}: PersonsRequestBody) => ({
	query,
	excludedIds,
	maxResults,
});

export const usersRequestBody = ({ keys = [] }: UsersRequestBody) => ({
	keys: Array.from<string>(new Set(keys)),
});
