// eslint-disable-next-line jira/restricted/react-lazy
import React, { lazy, useState, useCallback, useRef } from 'react';
import * as R from 'ramda';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import WarningIcon from '@atlaskit/icon/glyph/warning';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, { ModalBody } from '@atlaskit/modal-dialog';
import AkTextField from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { injectIntl, type IntlShape } from '@atlassian/jira-intl';
import Placeholder from '@atlassian/jira-placeholder';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import InlineEdit from '@atlassian/jira-portfolio-3-common/src/inline-edit/index.tsx';
import Spinner from '@atlassian/jira-portfolio-3-common/src/spinner/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import ChangeIndicator from '@atlassian/jira-portfolio-3-portfolio/src/common/view/change-indicator';
import { SCENARIO_ISSUE_ID_PREFIX } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import {
	useAnalyticsEvents,
	fireUIAnalytics,
	type ActionSubjectAndAction,
} from '@atlassian/jira-product-analytics-bridge';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import Breadcrumbs from './breadcrumbs';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

const LazyDescriptionEditor = lazy(
	() => import(/* webpackChunkName: "arj-description-editor" */ './description-editor'),
);

function IssueModal({
	BreadcrumbsComponent = Breadcrumbs,
	DescriptionEditorComponent = LazyDescriptionEditor,
	issueBeingEdited,
	issuesById,
	setIssueBeingEdited,
	setDescription,
	projectsById,
	intl,
	onChange,
	isOptimizedMode,
	isReadOnly,
	isLoadingIssueDescription,
}: Props & {
	intl: IntlShape;
}) {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isEditingSummary, setIsEditingSummary] = useState<boolean>(false);
	const [isEditingDescription, setIsEditingDescription] = useState<boolean>(false);
	const innerFieldModalIssue = useRef<unknown>(null);

	const { formatMessage } = intl;

	const issue = typeof issueBeingEdited === 'string' ? issuesById[issueBeingEdited] : null;
	const isScenarioIssue = Boolean(
		issue && (!isDefined(issue.issueKey) || issue.id.startsWith(SCENARIO_ISSUE_ID_PREFIX)),
	);
	const issueDescription = isLoadingIssueDescription ? undefined : issue?.description;

	const isSummaryDirty = issue?.originals && R.has('summary', issue.originals);
	const isDescriptionDirty = issue?.originals && R.has('description', issue.originals);

	const validateIssueSummary = (summary: string) => {
		// if the issue summary is not valid, we return a warning message which disables the "confirm" button of <InlineEdit />

		if (summary.trim().length === 0) {
			return formatMessage(messages.blankSummaryInputErrorMessage);
		}

		if (summary.length > 255) {
			return formatMessage(messages.invalidSummaryInput);
		}

		// if the issue summary is valid, we return undefined which enables the "confirm" button of <InlineEdit />
		return undefined;
	};

	const fireAnalytics = useCallback(
		(eventName: ActionSubjectAndAction) => {
			fireUIAnalytics(createAnalyticsEvent({}), eventName, 'arjIssueModalIssueSummary', {
				isScenarioIssue,
			});
		},
		[createAnalyticsEvent, isScenarioIssue],
	);

	const handleSummaryEdit = () => {
		setIsEditingSummary(true);
		fireAnalytics(PRODUCT_ANALYTICS_EVENT_NAMES.OPENED_ISSUE_SUMMARY);
	};

	const handleSummaryChange = useCallback(
		(editedSummary: string) => {
			if (!issue) return;

			if (!issue.id) {
				throw new Error('Issue must have id!');
			}

			onChange({
				id: issue.id,
				summary: (editedSummary && editedSummary.trim()) || issue.summary,
			});

			setIsEditingSummary(false);

			// This callback is fired even when the user clicks away without editing
			// so we add this check to fire the appropriate event
			if (editedSummary !== issue.summary) {
				fireAnalytics(PRODUCT_ANALYTICS_EVENT_NAMES.UPDATED_ISSUE_SUMMARY);
			} else {
				fireAnalytics(PRODUCT_ANALYTICS_EVENT_NAMES.CANCELLED_ISSUE_SUMMARY);
			}
		},
		[fireAnalytics, issue, onChange],
	);

	const handleSummaryCancel = () => {
		setIsEditingSummary(false);
		fireAnalytics(PRODUCT_ANALYTICS_EVENT_NAMES.CANCELLED_ISSUE_SUMMARY);
	};

	const handleClose = () => {
		setIsEditingSummary(false);
		setIsEditingDescription(false);
		setIssueBeingEdited(null);
	};

	const getIssueSummaryReadView = () => (
		<h2
			data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue-modal.summary-read-view"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles.summaryReadView}
		>
			{issue?.summary} {!isOptimizedMode && isSummaryDirty && <ChangeIndicator />}
		</h2>
	);

	if (!isDefined(issueBeingEdited) || !isDefined(issue)) {
		return null;
	}

	return (
		<ShortcutScope>
			<ModalDialog
				width="large"
				autoFocus={false}
				onClose={handleClose}
				shouldCloseOnOverlayClick={isReadOnly}
			>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.modalHeader}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.breadcrumbs}>
						<BreadcrumbsComponent issue={issue} />
					</div>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={isEditingSummary || isEditingDescription ? styles.hiddenButton : ''}>
						<Tooltip content={formatMessage(commonMessages.close)} position="top">
							<Button
								appearance="subtle"
								iconBefore={<CrossIcon label={formatMessage(commonMessages.close)} />}
								onClick={handleClose}
								testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue-modal.modal-close-button"
							/>
						</Tooltip>
					</div>
				</div>
				<ModalBody>
					<div
						data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue-modal.summary"
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={`${styles.summary} ${
							!isReadOnly && isEditingSummary ? styles.summaryBeingEdited : ''
						}`}
					>
						{isReadOnly ? (
							getIssueSummaryReadView()
						) : (
							<InlineEdit
								defaultValue={issue.summary}
								editView={({ errorMessage, ...fieldProps }) => (
									<InlineDialog
										content={errorMessage}
										isOpen={fieldProps.isInvalid}
										placement="right"
									>
										<div data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue-modal.edit-summary">
											<EnterEscapeHandler
												onEscape={() => {
													// empty function
												}}
											>
												<AkTextField
													{...fieldProps}
													autoFocus
													elemAfterInput={
														fieldProps &&
														fieldProps.isInvalid && (
															<WarningIcon
																label=""
																primaryColor={token('color.icon.warning', colors.Y300)}
															/>
														)
													}
													maxLength={256}
													placeholder={formatMessage(messages.summaryPlaceholder)}
													ref={innerFieldModalIssue}
												/>
											</EnterEscapeHandler>
										</div>
									</InlineDialog>
								)}
								onCancel={handleSummaryCancel}
								onConfirm={handleSummaryChange}
								onEdit={handleSummaryEdit}
								readView={getIssueSummaryReadView}
								validate={validateIssueSummary}
							/>
						)}
					</div>
					<div
						data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue-modal.description"
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={styles.description}
					>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<h5 className={styles.descriptionLabel}>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<span className={styles.descriptionLabelInner}>
								{formatMessage(messages.descriptionLabel)}
								{!isOptimizedMode && !!isDescriptionDirty && <ChangeIndicator />}
							</span>
						</h5>
						<Placeholder
							name="arj-description-editor"
							fallback={
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
								<div className={styles.descriptionSpinner}>
									<Spinner />
								</div>
							}
						>
							<DescriptionEditorComponent
								editMode={isEditingDescription}
								setEditMode={setIsEditingDescription}
								onSave={(description) => setDescription(issueBeingEdited, description)}
								issueId={issueBeingEdited}
								projectKey={projectsById[issue.project].key}
								issueKey={
									isScenarioIssue
										? null
										: toIssueKey(`${projectsById[issue.project].key}-${issue.issueKey || 0}`)
								}
								editorContent={issueDescription}
								isReadOnly={isReadOnly}
								isLoadingIssueDescription={isLoadingIssueDescription}
								isScenarioIssue={isScenarioIssue}
								noContentMessage={formatMessage(
									isReadOnly ? messages.noDescriptionReadOnlyMode : messages.noDescription,
								)}
								descriptionFailedMessage={formatMessage(messages.descriptionParsingError)}
							/>
						</Placeholder>
					</div>
				</ModalBody>
			</ModalDialog>
		</ShortcutScope>
	);
}

export default injectIntl(IssueModal);
