import { getHierarchyLevelsByLevel } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/hierarchy';
import { getScope } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope';
import { getVisualisationViewSettings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';

const getRankDigits = createSelector(
	[getScope],
	(scope) => scope.startLevelIssues.rootIssuesCount.toString().length,
);

const getGrouping = createSelector([getVisualisationViewSettings], ({ grouping }) => grouping);

export default createSelector(
	[getHierarchyLevelsByLevel, getRankDigits, getGrouping],
	(hierarchyLevelsByLevel, rankDigits, grouping) => ({
		hierarchyLevelsByLevel,
		rankDigits,
		grouping,
	}),
);
