import type { Solution } from './types';

export const SET_LATEST = 'state.domain.solution.SET_LATEST' as const;
export const SET_VALIDITY = 'state.domain.solution.SET_VALIDITY' as const;
export const RESET = 'state.domain.solution.RESET' as const;
export const SET_PROGRESS = 'state.domain.solution.SET_PROGRESS' as const;

export type SetLatestAction = {
	type: typeof SET_LATEST;
	payload: boolean;
};

export type SetValidityAction = {
	type: typeof SET_VALIDITY;
	payload: boolean;
};

export type ResetActionActionPayload = Flow.Diff<
	// @ts-expect-error - TS2344 - Type 'Readonly<{ id?: number | undefined; isLatest: boolean; isValid: boolean; applyingProgress?: number | null | undefined; projects: Partial<...>; teams: Partial<...>; calculationConfiguration?: CalculationConfigurationFor...' does not satisfy the constraint '{ isValid: boolean; isLatest: boolean; applyingProgress: number | null | undefined; }'.
	Solution,
	{
		isValid: boolean;
		isLatest: boolean;
		applyingProgress: number | null | undefined;
	}
>;

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionActionPayload;
};

export type SetProgressAction = {
	type: typeof SET_PROGRESS;
	payload: number | null | undefined;
};

export const setLatest = (payload: boolean): SetLatestAction => ({
	type: SET_LATEST,
	payload,
});

export const setValidity = (payload: boolean): SetValidityAction => ({
	type: SET_VALIDITY,
	payload,
});

export const reset = (payload: ResetActionActionPayload): ResetAction => ({
	type: RESET,
	payload,
});

export const setProgress = (payload?: number | null): SetProgressAction => ({
	type: SET_PROGRESS,
	payload,
});
