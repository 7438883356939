import React, { useEffect } from 'react';
import { IconButton } from '@atlaskit/button/new';
import CloseIcon from '@atlaskit/icon/core/close';
import { SpotlightCard } from '@atlaskit/onboarding';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	INLINE_DIALOG,
	fireUIAnalytics,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';
import type { Props } from './types';

const ReviewChangesSpotlight = ({ closeSpotlight, updateUserProperty }: Props) => {
	const { formatMessage } = useIntl();

	useEffect(() => {
		updateUserProperty(Date.now());
	}, [updateUserProperty]);

	const handleCloseDialog = (
		_: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		const [actionSubject, action] =
			PRODUCT_ANALYTICS_EVENT_NAMES.CLOSE_UNSAVED_CHANGES_SPOTLIGHT.split(' ');
		fireUIAnalytics(
			analyticsEvent.update({ action, actionSubject }),
			PRODUCT_ANALYTICS_EVENT_NAMES.CLOSE_UNSAVED_CHANGES_SPOTLIGHT,
		);
		closeSpotlight();
	};

	return (
		<ContextualAnalyticsData sourceName="reviewChangesSpotlight" sourceType={INLINE_DIALOG}>
			<FireScreenAnalytics />
			<SpotlightCard
				headingAfterElement={
					<IconButton
						appearance="subtle"
						label={formatMessage(messages.closeSpotlight)}
						spacing="compact"
						icon={(iconProps) => <CloseIcon {...iconProps} color={token('color.icon.inverse')} />}
						onClick={handleCloseDialog}
						testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.review-changes-button-wrapper.review-changes-button.review-changes-spotlight-manager.review-changes-spotlight.selected-field-remove-button"
					/>
				}
				heading={formatMessage(messages.updateJiraSpotlightTitle)}
			>
				{formatMessage(messages.updateJiraSpotlightBody)}
			</SpotlightCard>
		</ContextualAnalyticsData>
	);
};

export default ReviewChangesSpotlight;
