import type { Person, IssueAssigneeMap, JiraUser } from './types';

export const UPDATE_ISSUE_ASSIGNEE_MAP = 'state.domain.assignees.UPDATE_ISSUE_ASSIGNEE_MAP';
export const UPDATE_ASSIGNEES_LIST = 'state.domain.assignees.UPDATE_ASSIGNEES_LIST';

export type UpdateIssueAssigneeMapActionPayload = IssueAssigneeMap;
export type UpdateAssigneesListPayload = {
	people: Person[];
	assigneesOfIssues?: Person[];
};

export type ResetAssigneeProfilesPayload = {
	profileMap: Record<string, JiraUser>;
};

export type UpdateIssueAssigneeMapAction = {
	type: typeof UPDATE_ISSUE_ASSIGNEE_MAP;
	payload: UpdateIssueAssigneeMapActionPayload;
};

export type UpdateAssigneesListAction = {
	type: typeof UPDATE_ASSIGNEES_LIST;
	payload: UpdateAssigneesListPayload;
};

export const updateIssueAssigneeMap = (payload: UpdateIssueAssigneeMapActionPayload) => ({
	type: UPDATE_ISSUE_ASSIGNEE_MAP,
	payload,
});

export const updateAssigneeList = (payload: UpdateAssigneesListPayload) => ({
	type: UPDATE_ASSIGNEES_LIST,
	payload,
});
