import {
	SET_ISSUE_BEING_EDITED,
	SET_LOADING_ISSUE_DESCRIPTION,
	type SetIssueBeingEditedAction,
	type SetLoadingIssueDescriptionAction,
} from './actions';
import type { IssueModalState } from './types';

type Action = SetIssueBeingEditedAction | SetLoadingIssueDescriptionAction;

export const initialState: IssueModalState = {
	issueBeingEdited: null,
	isLoadingIssueDescription: false,
};

const reducer = (state: IssueModalState = initialState, action: Action): IssueModalState => {
	switch (action.type) {
		case SET_ISSUE_BEING_EDITED: {
			return {
				...state,
				issueBeingEdited: action.payload,
			};
		}
		case SET_LOADING_ISSUE_DESCRIPTION: {
			return {
				...state,
				isLoadingIssueDescription: action.payload,
			};
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};

export default reducer;
