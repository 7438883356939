import type { Mode } from './types';

export const SET_MODE = 'state.ui.main.tabs.roadmap.timeline.release-bar.SET_MODE' as const;

export const OPEN_FLYOUT = 'state.ui.main.tabs.roadmap.timeline.release-bar.OPEN_FLYOUT' as const;
export const UPDATE_FLYOUT =
	'state.ui.main.tabs.roadmap.timeline.release-bar.UPDATE_FLYOUT' as const;

export const CLOSE_FLYOUT = 'state.ui.main.tabs.roadmap.timeline.release-bar.CLOSE_FLYOUT' as const;

export const TOGGLE_RELEASE_HIGHLIGHT =
	'state.ui.main.tabs.roadmap.timeline.release-bar.TOGGLE_RELEASE_HIGHLIGHT' as const;
export const RESET_RELEASE_HIGHLIGHTS =
	'state.ui.main.tabs.roadmap.timeline.release-bar.RESET_RELEASE_HIGHLIGHTS' as const;

/** SET_MODE */
export type SetModeAction = {
	type: typeof SET_MODE;
	payload: Mode;
};

/** OPEN_FLYOUT */
export type OpenFlyoutActionPayload = {
	mode: Mode;
	versionIds: string[];
};
export type UpdateFlyoutActionPayload = {
	mode: Mode;
	versionIds: string[];
	type: Mode;
	prevVersionIds?: string[];
};
export type OpenFlyoutAction = {
	type: typeof OPEN_FLYOUT;
	payload: OpenFlyoutActionPayload;
};
export type UpdateFlyoutAction = {
	type: typeof UPDATE_FLYOUT;
	payload: UpdateFlyoutActionPayload;
};

/** CLOSE_FLYOUT */
export type CloseFlyoutAction = {
	type: typeof CLOSE_FLYOUT;
};

export type ToggleReleaseHighlightAction = {
	type: typeof TOGGLE_RELEASE_HIGHLIGHT;
	versionId: string;
};

export type ResetReleaseHighlightsAction = {
	type: typeof RESET_RELEASE_HIGHLIGHTS;
	versionIds: string[];
};

export const setMode = (payload: Mode): SetModeAction => ({
	type: SET_MODE,
	payload,
});

export const openFlyout = (versionIds: string[], type: Mode) => ({
	type: OPEN_FLYOUT,
	payload: {
		mode: type,
		versionIds,
	},
});

export const updateFlyout = (versionIds: string[], type: Mode, prevVersionIds: string[]) => ({
	type: UPDATE_FLYOUT,
	payload: {
		mode: type,
		versionIds,
		prevVersionIds,
	},
});

export const closeFlyout = () => ({
	type: CLOSE_FLYOUT,
});

export const toggleReleaseHighlight = (versionId: string) => ({
	type: TOGGLE_RELEASE_HIGHLIGHT,
	versionId,
});

export const resetHighlightedReleases = (versionIds: Set<string>) => ({
	type: RESET_RELEASE_HIGHLIGHTS,
	versionIds,
});
