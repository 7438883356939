import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { FormattedMessage } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import messages from './messages';
import type { Props } from './types';

export const ScopeSubheader = ({
	totalSelected,
	isSelected,
	isReadOnly,
	isIndeterminate,
	toggleAllSelected,
	rankDigits,
}: Props) => (
	<Box xcss={containerStyles}>
		{!isReadOnly && (
			<>
				<Checkbox
					isChecked={isSelected}
					isIndeterminate={isIndeterminate}
					onChange={toggleAllSelected}
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.subheader.scope.checkbox"
				/>
				{isSelected ? (
					<small
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						style={{ margin: 0 }}
						data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.subheader.scope.issues-selected-count"
					>
						<FormattedMessage {...messages.selectedIssues} values={{ totalSelected }} />
					</small>
				) : (
					<Box
						xcss={rankHeaderStyles}
						style={{ marginRight: 10 * rankDigits }}
						data-name="rank-header"
					>
						#
					</Box>
				)}
			</>
		)}
	</Box>
);

const containerStyles = xcss({
	position: 'absolute',
	inset: 'space.0',
	display: 'flex',
	alignItems: 'center',
	gap: 'space.100',
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
	boxSizing: 'border-box',
});

const rankHeaderStyles = xcss({
	color: 'color.text.subtlest',
	display: 'inline-flex',
});
