import { getFilterCount } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters';
import { getCustomFieldsWithFilteringAllowed } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/custom-field-filter';
import { isAtlasConnectInstalled } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/system';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export default createStructuredSelector<State, {}, StateProps>({
	customFieldsWithFilteringAllowed: getCustomFieldsWithFilteringAllowed,
	filtersAppliedCount: getFilterCount,
	isAtlasConnectInstalled,
});
