import type { IssueLabels } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-labels/types.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types';
import {
	type LabelFilter,
	LABEL_FILTER_ID,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { UNDEFINED_KEY } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import { getIssueLabels } from '../../issue-labels';
import { getFiltersViewSettings } from '../../view-settings';
import { applyFilter as applyAttributeFilter } from '../attribute-filter';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { LabelFilter } from '../../../state/domain/view-settings/filters/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { LABEL_FILTER_ID } from '../../../state/domain/view-settings/filters/types';
export const NO_LABEL_ID = UNDEFINED_KEY;

export const applyFilter = (issue: Issue, filter: LabelFilter, optimizedMode: boolean): boolean =>
	applyAttributeFilter(issue, filter.value, 'labels', optimizedMode);

export const getLabelFilterPure = (state: State): LabelFilter =>
	getFiltersViewSettings(state)[LABEL_FILTER_ID] || {
		id: LABEL_FILTER_ID,
		value: [],
	};

export const getLabelFilter = createSelector(
	[getIssueLabels, getLabelFilterPure],
	(issueLabels: IssueLabels, pureFilter: LabelFilter): LabelFilter => {
		const { value = [] } = pureFilter;
		const existingLabels = value.filter(
			(label: string) =>
				issueLabels.some((issueLabel) => issueLabel === label) || label === NO_LABEL_ID,
		);
		return {
			id: LABEL_FILTER_ID,
			value: existingLabels,
		};
	},
);
