import type { Dispatch } from 'redux';
import {
	toggleExpandGroup,
	type ToggleExpandGroupActionPayload,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/visualisations/actions.tsx';
import type { Action } from './types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (dispatch: Dispatch<Action>) => ({
	toggleExpandGroup: (payload: ToggleExpandGroupActionPayload) =>
		dispatch(toggleExpandGroup(payload)),
});
