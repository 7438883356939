import type { ViewMode } from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import type {
	StatusKeyFilterValue,
	TimelineDispatchProps,
} from '@atlassian/jira-portfolio-3-local-storage-commands/src/types';
import {
	change as changeFilter,
	clearAll as clearAllFilters,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/filters';
import {
	type DependenciesFilterValueV1,
	type DateRangeFilterValue,
	TEAM_FILTER_ID,
	ISSUE_PRIORITIES_FILTER_ID,
	DEPENDENCIES_FILTER_ID,
	ASSIGNEE_FILTER_ID,
	STATUS_KEY_FILTER_ID,
	WARNING_FILTER_ID,
	DATE_RANGE_FILTER_ID,
	HIERARCHY_FILTER_ID,
	HIERARCHY_RANGE_FILTER_ID,
	ISSUE_WARNING_FILTER_ID,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import { changeViewMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/view-mode/actions';
import { changeGroup } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/visualisations/actions.tsx';
import { resetEnabledWarningType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/warning-settings/actions.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { Grouping } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';

const mapDispatchToProps: MapDispatchToPropsSimple<TimelineDispatchProps> = (dispatch) => ({
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	groupBy: (group: string) => group && dispatch(changeGroup({ grouping: group as Grouping })),
	resetHierarchyFilter: () => {
		dispatch(
			changeFilter({
				id: HIERARCHY_FILTER_ID,
				value: { start: undefined, end: undefined },
			}),
		);
		dispatch(
			changeFilter({
				id: HIERARCHY_RANGE_FILTER_ID,
				value: { start: undefined, end: undefined },
			}),
		);
	},
	changeHierarchyFilter: (start: number, end: number) => {
		dispatch(
			changeFilter({
				id: HIERARCHY_FILTER_ID,
				value: { start: start + 1, end: end + 1 },
			}),
		);
		dispatch(
			changeFilter({
				id: HIERARCHY_RANGE_FILTER_ID,
				value: { start, end },
			}),
		);
	},
	changeStatusFilter: (value: StatusKeyFilterValue[]) =>
		dispatch(changeFilter({ id: STATUS_KEY_FILTER_ID, value })),
	changeTeamFilter: (itemKey: string) =>
		dispatch(changeFilter({ id: TEAM_FILTER_ID, value: [itemKey] })),
	changePriorityFilter: (priorityId: string) =>
		dispatch(changeFilter({ id: ISSUE_PRIORITIES_FILTER_ID, value: [priorityId] })),
	changeDependenciesFilter: (value: DependenciesFilterValueV1) =>
		dispatch(changeFilter({ id: DEPENDENCIES_FILTER_ID, value })),
	changeAssigneeFilter: (assigneeId: string) =>
		dispatch(changeFilter({ id: ASSIGNEE_FILTER_ID, value: [assigneeId] })),
	changeWarningFilter: (isEnabled: boolean) =>
		dispatch(changeFilter({ id: WARNING_FILTER_ID, value: isEnabled })),
	changeIssueWarningFilter: (warningTypes: string[]) =>
		dispatch(changeFilter({ id: ISSUE_WARNING_FILTER_ID, value: warningTypes })),
	changeEnabledWarningType: (warningTypes: string[]) =>
		dispatch(resetEnabledWarningType(new Set(warningTypes))),
	changeDateRangeFilter: (dateRange: DateRangeFilterValue) =>
		dispatch(changeFilter({ id: DATE_RANGE_FILTER_ID, value: dateRange })),
	clearFilter: () => dispatch(clearAllFilters()),
	toggleViewMode: (mode: ViewMode) => dispatch(changeViewMode(mode)),
});

export default mapDispatchToProps;
