import React from 'react';
import Heading from '@atlaskit/heading';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

export default function ErrorDetail(props: Props) {
	const { title, sections } = props;
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<pre className={styles['error-details']}>
			<Heading as="h4" size="small">
				{title}
			</Heading>
			{sections.map((section, index) => (
				<div key={index}>
					<b>{section.title}</b>
					{section.info}
				</div>
			))}
		</pre>
	);
}
