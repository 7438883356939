import { getSelectOptions } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

const mapStateToProps = createStructuredSelector<State, StateProps>({
	singleSelectionOptions: getSelectOptions,
});

export default mapStateToProps;
