import { useEffect, useState } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { UNASSIGNED_GROUP } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope';
import type { GroupCombination } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { UserPickerOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/user-picker-options/types';
import { type Option, UNGROUPED } from '../types';
import { optionSortComparator } from '../utils';
import messages from './messages';
import type { Props } from './types';

const CustomUserPickerOptionsProvider = ({
	grouping,
	userList,
	fetchUserPickerOptions,
	userPickerUIState,
	children,
}: Props) => {
	const { formatMessage } = useIntl();
	const { issueId, isFetchUserListInProgress } = userPickerUIState;
	const [isLoading, setIsLoading] = useState(isFetchUserListInProgress);

	useEffect(() => {
		setIsLoading(true);
		fetchUserPickerOptions('', issueId);
	}, [fetchUserPickerOptions, issueId]);

	const previousFetchUserListInProgress = usePrevious(isFetchUserListInProgress);

	useEffect(() => {
		if (previousFetchUserListInProgress && !isFetchUserListInProgress) setIsLoading(false);
	}, [isFetchUserListInProgress, previousFetchUserListInProgress]);

	const handleQueryChange = (query: string) => {
		setIsLoading(true);
		fetchUserPickerOptions(query, issueId);
	};

	const createOptionFromUser = (user: UserPickerOption): Option<GroupCombination> => {
		const { email, icon, label, value } = user;
		const isUnassigned = label.toLowerCase() === UNASSIGNED_GROUP;
		return {
			id: `${grouping}-${value}`,
			name: isUnassigned ? UNASSIGNED_GROUP : [label, email].join(' '),
			value: { [grouping]: value },
			description: email,
			url: icon,
			urlType: 'avatar',
		};
	};

	const getOptionsFromUsers = (): Option<GroupCombination>[] =>
		userList.map(createOptionFromUser).sort(optionSortComparator);

	return children({
		options: {
			type: UNGROUPED,
			options: getOptionsFromUsers(),
		},
		menuTitle: formatMessage(messages.userMenuTitle),
		searchPlaceholder: formatMessage(messages.userSearchPlaceholder),
		onQueryChange: handleQueryChange,
		isLoading,
	});
};

export default CustomUserPickerOptionsProvider;
