import { useCallback } from 'react';
import { useGlobalSearch } from '@atlassian/jira-software-global-search-tracker';

type Props = {
	onFocusSearch: () => void;
};

const HijackBrowserSearch = ({ onFocusSearch }: Props) => {
	const onGlobalSearch = useCallback(
		({ event }: { event: Event }) => {
			event.preventDefault();
			onFocusSearch();
		},
		[onFocusSearch],
	);

	useGlobalSearch(onGlobalSearch);

	return null;
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as KeyboardShortcutTooltip } from './ui/keyboard-shortcut-tooltip/index.tsx';

export default HijackBrowserSearch;
