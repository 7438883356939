import * as R from 'ramda';
import {
	filterMap,
	indexBy,
	isDefined,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import {
	ENTITY,
	SCENARIO_TYPE,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import type { Warnings } from '@atlassian/jira-portfolio-3-portfolio/src/common/warning-details/types';
import type {
	EnrichedCrossProjectVersion,
	CrossProjectVersion,
} from '../../state/domain/cross-project-versions/types';
import type { OriginalCrossProjectVersions } from '../../state/domain/original-cross-project-versions/types';
import type { EntityMetadata } from '../../state/domain/update-jira/changes/types';
import type { State } from '../../state/types';
import { getVersionsById } from '../versions';
import type { VersionsById } from '../versions/types';
import type {
	CrossProjectVersionChange,
	CrossProjectVersionsById,
	EnrichedCrossProjectVersionId,
} from './types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { CrossProjectVersion } from '../../state/domain/cross-project-versions/types.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { EnrichedCrossProjectVersion } from '../../state/domain/cross-project-versions/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	OriginalCrossProjectVersion,
	OriginalCrossProjectVersions,
} from '../../state/domain/original-cross-project-versions/types';

export const getCrossProjectVersions = (state: State): CrossProjectVersion[] =>
	state.domain.crossProjectVersions;

export const getCrossProjectVersionsById = createSelector(
	[getCrossProjectVersions],
	(crossProjectVersions): CrossProjectVersionsById => indexBy(R.prop('id'), crossProjectVersions),
);

export const getRemovedCrossProjectVersions = (state: State): CrossProjectVersion[] =>
	state.domain.removedCrossProjectVersions;

export const getRemovedCrossProjectVersionsById = createSelector(
	[getRemovedCrossProjectVersions],
	(removedCrossProjectVersions): CrossProjectVersionsById =>
		indexBy(R.prop('id'), removedCrossProjectVersions),
);

export const getOriginalCrossProjectVersions = (state: State) =>
	state.domain.originalCrossProjectVersions;

export const getCrossProjectVersionChangeCount = ({
	domain: { originalCrossProjectVersions },
}: State) => Object.keys(originalCrossProjectVersions).length;

export const getCrossProjectVersionChangesMetaData = (state: State): EntityMetadata =>
	R.path(['domain', 'updateJira', 'changes', 'data', 'metaData', 'crossProjectVersions'], state) ||
	{};

export const getCommitWarningsForCrossProjectVersions = (state: State): Warnings =>
	state.domain.updateJira.warnings[ENTITY.CROSS_PROJECT_RELEASE];

export const getCrossProjectVersionChangesPure = (
	crossProjectVersions: CrossProjectVersion[] = [],
	removedCrossProjectVersions: CrossProjectVersion[] = [],
	originalCrossProjectVersions: OriginalCrossProjectVersions = {},
	entityMetaData: EntityMetadata,
	warnings: Warnings,
): CrossProjectVersionChange[] => {
	const crossProjectVersionsById = indexBy(R.prop('id'), [
		...crossProjectVersions,
		...removedCrossProjectVersions,
	]);

	return filterMap(
		(id) => isDefined(crossProjectVersionsById[id]),
		(id) => {
			const crossProjectVersion: CrossProjectVersion = crossProjectVersionsById[id];
			const metaData = entityMetaData[id];
			return {
				// at the moment only name could be changed in CPV
				// and we don't want to show it as a separate field if version was created
				attributeName: metaData.scenarioType === SCENARIO_TYPE.ADDED ? null : 'name',
				category: ENTITY.CROSS_PROJECT_RELEASE,
				changeCount: metaData.scenarioType === SCENARIO_TYPE.ADDED ? 0 : 1,
				details: {
					values: crossProjectVersion,
					originals: R.merge(originalCrossProjectVersions[id], {}),
				},
				id,
				metaData,
				warnings: warnings[id] || [],
			};
		},
		Object.keys(entityMetaData),
	);
};

export const getCrossProjectVersionChanges = createSelector(
	[
		getCrossProjectVersions,
		getRemovedCrossProjectVersions,
		getOriginalCrossProjectVersions,
		getCrossProjectVersionChangesMetaData,
		getCommitWarningsForCrossProjectVersions,
	],
	getCrossProjectVersionChangesPure,
);

export const getEnrichedCrossProjectVersionsPure = (
	crossProjectVersions: CrossProjectVersion[],
	versionsByIdMap: VersionsById,
): EnrichedCrossProjectVersion[] =>
	crossProjectVersions.map(({ versions, ...rest }: CrossProjectVersion) => ({
		...rest,
		versions: versions.map((vid: string) => versionsByIdMap[vid]),
	}));

export const getEnrichedCrossProjectVersions = createSelector(
	[getCrossProjectVersions, getVersionsById],
	getEnrichedCrossProjectVersionsPure,
);

export const getEnrichedCrossProjectVersionsById = createSelector(
	[getEnrichedCrossProjectVersions],
	(versions): EnrichedCrossProjectVersionId => indexBy(R.prop('id'), versions),
);

export const getEnrichedCrossProjectVersionsSortedByNamePure = (
	enrichedCrossProjectVersions: EnrichedCrossProjectVersion[],
): EnrichedCrossProjectVersion[] => R.sortBy(R.prop('name'), enrichedCrossProjectVersions);

export const getEnrichedCrossProjectVersionsSortedByName = createSelector(
	[getEnrichedCrossProjectVersions],
	getEnrichedCrossProjectVersionsSortedByNamePure,
);
