import { isDefined } from '../../common/ramda';
import { getPathParams } from '../../common/window';

export const getUrlParameter = (param: string): string | null | undefined => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const paramMatch = window.location.search.match(`[?&]${param}=([^&]*)`);
	if (paramMatch) {
		return paramMatch[1];
	}
	return undefined;
};

// Will return undefined in the dev server
export const getPlanId = (): number | null | undefined => +getPathParams().id || undefined;

export const getScenarioId = (): number | null | undefined => +getPathParams().sid || undefined;

// @ts-expect-error - TS2533 - Object is possibly 'null' or 'undefined'.
export const getViewId = (): number | null | undefined => +getUrlParameter('vid') || undefined;

export const getReportHash = (): string | null | undefined => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (process.env.NODE_ENV === 'development' && window?.STORYBOOK_ENV) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const url = new URL(window.location.href);
		if (url.searchParams.has('enforceReportMode')) {
			return url.searchParams.get('enforceReportMode'); // returns a dummy hash in dev
		}
	}

	// Path parameter is passed when serving the report view in SPA
	if (isDefined(getPathParams().r)) {
		return getPathParams().r;
	}

	// URL parameter is passed when serving PortfolioEmbeddedReportView.jspa or PlanEmbeddedReportView.jspa
	return getUrlParameter('r');
};
