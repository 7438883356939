import * as R from 'ramda';
import { monitor } from '@atlassian/jira-portfolio-3-common/src/analytics/performance';
import { proxyContextSafeUrl } from '../api';
import { reload } from '../window';

export const XHR_UNAUTHORIZED_ERROR = 'common.fetch.XHR_UNAUTHORIZED_ERROR' as const;
export const RESPONSE_NOT_OK = 'response not ok' as const;

const headers = { Accept: 'application/json', 'Content-Type': 'application/json' } as const;

const defaultOptions = {
	credentials: 'same-origin',
	headers,
} as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const castBody = (body: any) => {
	const type = R.type(body);
	switch (type) {
		case 'String':
			return body;
		case 'Object':
			return JSON.stringify(body);
		case 'Number':
			return `${body}`;
		default:
			throw new Error(`Unsupported body type: ${type}`);
	}
};

const enhanceOptions = R.evolve({ body: castBody });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Body = BodyInit | any;

export type Options = {
	body?: Body;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	cache?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	credentials?: any;
	headers?: HeadersInit;
	integrity?: string;
	isUrlVerbatim?: boolean;
	keepalive?: boolean;
	method?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mode?: any;
	profile?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	redirect?: any;
	referrer?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	referrerPolicy?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	window?: any;
	json?: boolean;
};

const checkAuthorized = (response: Response) => {
	if (!response?.ok && response.status === 401) {
		reload();
		throw new Error(`${XHR_UNAUTHORIZED_ERROR}:${response.url}`);
	}
	return response;
};

function fetch(url: string, { isUrlVerbatim, profile, ...options }: Options) {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const request = window
		.fetch(
			isUrlVerbatim ? url : proxyContextSafeUrl(url),
			enhanceOptions({ ...defaultOptions, ...options }),
		)
		.then(checkAuthorized);
	if (profile) {
		const finishProfile = monitor.startProfileConcurrent(profile);
		return request.then((response: Response) => {
			finishProfile();
			return response;
		});
	}
	return request;
}

export class AgressiveFetchError extends Error {
	request: Request;

	response: Response;

	constructor(
		message: string,
		{
			request,
			response,
		}: {
			request: Request;
			response: Response;
		},
	) {
		super(message);
		this.request = request;
		this.response = response;
	}
}

export async function aggressiveFetch(
	url: string,
	{ profile, ...options }: Options,
	errMessage = 'aggressiveFetch failed',
) {
	const request = new Request(url, enhanceOptions({ ...defaultOptions, ...options }));

	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	let finishProfile = () => {};
	if (profile) {
		finishProfile = monitor.startProfileConcurrent(profile);
	}
	const response = await fetch(url, options);
	finishProfile();

	checkAuthorized(response);

	if (!response?.ok) {
		throw new AgressiveFetchError(`${errMessage}: ${response.status}: ${RESPONSE_NOT_OK}`, {
			request,
			response,
		});
	}

	if (options.json && response.status !== 204) {
		try {
			return await response.json();
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			throw new AgressiveFetchError(`${errMessage}: ${response.status}: ${err.message}`, {
				request,
				response,
			});
		}
	}
}

export default fetch;
