import type { ConfluenceMacroType } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';

export const SET_CONFLUENCE_MACRO_TYPE =
	'state.ui.confluence-macro.SET_CONFLUENCE_MACRO_TYPE' as const;

export type ConfluenceMacroTypeAction = {
	type: typeof SET_CONFLUENCE_MACRO_TYPE;
	payload: ConfluenceMacroType;
};

export const setConfluenceMacroType = (payload: ConfluenceMacroType) => ({
	type: SET_CONFLUENCE_MACRO_TYPE,
	payload,
});
