/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import noop from 'lodash/noop';
import { IconButton as AtlaskitIconButton } from '@atlaskit/button/new';
import type CheckIcon from '@atlaskit/icon/core/migration/check-mark--editor-done';
import type CrossIcon from '@atlaskit/icon/core/migration/close--editor-close';
import type EditFilledIcon from '@atlaskit/icon/core/migration/edit--editor-edit';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

type Props = {
	icon: typeof EditFilledIcon | typeof CheckIcon | typeof CrossIcon;
	label: string;
	componentSelector: string;
	onClick?: () => void;
	testId?: string;
	isDisabled?: boolean;
};

export const IconButton = ({
	icon: Icon,
	label,
	componentSelector,
	onClick,
	testId,
	isDisabled,
}: Props) => (
	<span css={wrapperStyles}>
		<AtlaskitIconButton
			icon={(iconProps) => <Icon {...iconProps} />}
			data-component-selector={componentSelector}
			onClick={isDisabled ? noop : onClick}
			testId={testId}
			label={label}
			isTooltipDisabled={false}
			aria-disabled={isDisabled}
			tooltip={{
				testId:
					'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.suggestion-list-item.icon-button.tooltip',
				position: 'bottom',
			}}
		/>
	</span>
);

const wrapperStyles = css({
	background: token('elevation.surface'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'button[aria-disabled="true"]': {
		// We are replicating the disabled style, but only on aria-disabled, this is becuase
		// when button is fully disabled it becomes not accessible (the screen reader skips it)
		// jira/platform/packages/design-system/button/src/new-button/variants/shared/use-button-base.tsx
		cursor: 'not-allowed',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		background: token('color.background.disabled', colors.N20A),
		color: token('color.text.disabled'),
		'&:hover': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			background: token('color.background.disabled', colors.N20A),
			color: token('color.text.disabled'),
		},
		'&:active': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			background: token('color.background.disabled', colors.N20A),
			color: token('color.text.disabled'),
		},
		'&::after': {
			content: 'none',
		},
	},
});
