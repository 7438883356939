import React, { memo, useRef } from 'react';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { SCROLL_DELAY } from '../constants';
import { longTask } from '../index';
import type { Props } from './types';

const ScrollLongTaskAnalytics = memo(
	({
		attributes,
		analyticsEvent,
		children,
		includeFirstScroll = false,
		delay = SCROLL_DELAY,
	}: Props) => {
		const isInitialTimeout = useRef(!includeFirstScroll);
		const { createAnalyticsEvent } = useAnalyticsEvents();

		const handleScroll = () => {
			if (isInitialTimeout.current) {
				isInitialTimeout.current = false;
				return;
			}
			// @ts-expect-error Argument of type 'UIAnalyticsEvent' is not assignable to parameter of type 'InteractionMetricType'.
			longTask.trackWithTimeout(analyticsEvent, createAnalyticsEvent, delay, attributes);
		};

		return <>{children(handleScroll)}</>;
	},
);

export default ScrollLongTaskAnalytics;
