import { createTransform } from 'redux-persist';
import { getPlanPrefixedKey } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/persistence';
import { persistReducer } from '@atlassian/jira-portfolio-3-portfolio/src/common/redux/persistence.tsx';
import { RELEASE } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import {
	SET_MODE,
	OPEN_FLYOUT,
	CLOSE_FLYOUT,
	UPDATE_FLYOUT,
	TOGGLE_RELEASE_HIGHLIGHT,
	RESET_RELEASE_HIGHLIGHTS,
	type SetModeAction,
	type OpenFlyoutAction,
	type CloseFlyoutAction,
	type UpdateFlyoutAction,
	type ToggleReleaseHighlightAction,
	type ResetReleaseHighlightsAction,
} from './actions';
import type { ReleaseBar } from './types';

export const initialState: ReleaseBar = {
	mode: RELEASE,
	isFlyoutOpen: false,
	versionIds: [],
	prevVersionIds: [],
	highlightedVersionIds: new Set(),
};

type Action =
	| SetModeAction
	| OpenFlyoutAction
	| CloseFlyoutAction
	| UpdateFlyoutAction
	| ToggleReleaseHighlightAction
	| ResetReleaseHighlightsAction;

export const reducer = (state: ReleaseBar = initialState, action: Action) => {
	switch (action.type) {
		case SET_MODE:
			return {
				...state,
				mode: action.payload,
			};
		case OPEN_FLYOUT: {
			const { mode, versionIds } = action.payload;
			return {
				mode,
				isFlyoutOpen: true,
				versionIds,
				highlightedVersionIds: state.highlightedVersionIds,
			};
		}
		case UPDATE_FLYOUT: {
			const { mode, versionIds, prevVersionIds } = action.payload;
			return {
				...state,
				mode,
				versionIds,
				prevVersionIds,
			};
		}
		case CLOSE_FLYOUT:
			return {
				...state,
				isFlyoutOpen: false,
			};
		case TOGGLE_RELEASE_HIGHLIGHT: {
			const { versionId } = action;
			// TODO need to avoid use Set in the redux, as there are serializability concerns（https://github.com/reduxjs/redux/issues/1499）
			// It is related to https://bulldog.internal.atlassian.com/browse/JPOS-3816
			const highlightedVersionIds = new Set<string>(state.highlightedVersionIds);
			if (highlightedVersionIds.has(versionId)) {
				highlightedVersionIds.delete(versionId);
			} else {
				highlightedVersionIds.add(versionId);
			}
			return {
				...state,
				highlightedVersionIds,
			};
		}
		case RESET_RELEASE_HIGHLIGHTS:
			return {
				...state,
				highlightedVersionIds: new Set<string>(action.versionIds),
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};

// Converting Set to Array when persisted, and back to Set when before putting back to redux store.
const SetTransform = createTransform(
	(highlightedVersionIds: Set<string>): string[] => [...highlightedVersionIds],
	(highlightedVersionIds: string[]): Set<string> => new Set(highlightedVersionIds),
	{ whitelist: ['highlightedVersionIds'] },
);

export default persistReducer(
	{
		key: getPlanPrefixedKey('release-bar'),
		whitelist: ['highlightedVersionIds'],
		transforms: [SetTransform],
	},
	reducer,
);
