import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	feedbackSentLozenge: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.feedback-form.feedback-sent-lozenge',
		defaultMessage: 'Feedback Sent',
		description: 'Text in feedback sent lozenge for feedback form.',
	},
	feedbackIconLabel: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.feedback-form.feedback-icon-label',
		defaultMessage: 'Feedback Icon',
		description: 'Feedback icon label in the feedback form.',
	},
	tooltipLike: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.feedback-form.tooltip-like',
		defaultMessage: 'Good response',
		description: 'Tooltip content for the like button in the feedback form.',
	},
	tooltipDislike: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.feedback-form.tooltip-dislike',
		defaultMessage: 'Bad response',
		description: 'Tooltip content for the dislike button in the feedback form.',
	},
	canContactPrompt: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.feedback-form.can-contact-prompt',
		defaultMessage: 'Atlassian may contact me about this feedback.',
		description: 'Prompt asking user if they give consent to be contacted',
	},
	canContactPromptLink: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.feedback-form.can-contact-prompt-link',
		defaultMessage: 'Privacy policy',
		description: 'Privacy policy for user to visit and read',
	},
	contactSupport: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.feedback-form.contact-support',
		defaultMessage: 'contact our support team',
		description: 'Label for contact support team link',
	},
	enrollInResearchPrompt: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.feedback-form.enroll-in-research-prompt',
		defaultMessage: "I'd like to help improve Atlassian products by joining the {link}",
		description: 'A prompt that allows the customer to enrol in product research.',
	},
	enrollInResearchLink: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.feedback-form.enroll-in-research-link',
		defaultMessage: 'Atlassian Research Group',
		description:
			'A link in the prompt that allows the customer to see information about the research group',
	},
	giveFeedback: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.feedback-form.give-feedback',
		defaultMessage: 'Give feedback',
		description: 'It is a label for button that allows users to provide feedback.',
	},
	plansReportBug: {
		id: 'portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.feedback-form.plans-report-bug',
		defaultMessage:
			'If you have technical issues or find a bug in Plans, please {contactSupportLink}.',
		description: 'Text for report Plans Bug',
	},
});

export default messages;
