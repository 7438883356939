import { createMigrate, incremental } from '../common/migrate';
import type { FieldColumnsStateV0 } from './types';

export const migrateListFieldColumns = createMigrate(
	...incremental(
		{
			V0: function fromV0ToV0(prevState: FieldColumnsStateV0): FieldColumnsStateV0 {
				return prevState;
			},
		},
		{ prefix: 'listFieldColumns', from: 'V0' },
	),
);

export default createMigrate(
	...incremental(
		{
			V0: function fromV0ToV0(prevState: FieldColumnsStateV0): FieldColumnsStateV0 {
				return prevState;
			},
		},
		{ prefix: 'fieldColumns', from: 'V0' },
	),
);
