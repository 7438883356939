import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
// Remove with the cleanup of com.atlassian.rm.jpo.transposition
// Also move contents of view.tsx back into index.tsx
import ScrollLockNew from './view';
import ScrollLockOld from './view-old';

const ScrollLock = componentWithCondition(
	() => ff('com.atlassian.rm.jpo.transposition'),
	ScrollLockNew,
	ScrollLockOld,
);

export default ScrollLock;
