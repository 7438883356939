import { getIssuePriorities } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-priorities';
import { getIssuePriorityIdsByProjectId } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { StateProps, OwnProps } from './types';

const querySelector: MapStateToProps<StateProps, OwnProps> = (state, ownProps) => ({
	prioritiesByIdMap: getIssuePriorities(state),
	priorityIdsForProject:
		(ownProps.project && getIssuePriorityIdsByProjectId(state)[ownProps.project]) || [],
});

export default querySelector;
