import * as R from 'ramda';
import {
	getFilteredIssuesWithHierarchy,
	getSelected,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues';
import { getIssueMapById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export default createStructuredSelector<State, StateProps>({
	selectedIssuesIds: getSelected,
	issueMapById: getIssueMapById,
	issuesInFilterById: (state: State) =>
		new Set(R.map(R.prop('id'), getFilteredIssuesWithHierarchy(state))),
});
