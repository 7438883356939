import React from 'react';
import * as R from 'ramda';
import Heading from '@atlaskit/heading';
import Lozenge from '@atlaskit/lozenge';
import { Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { type IntlShape, injectIntl, FormattedMessage } from '@atlassian/jira-intl';
// delete this reference in JPO-19568
import type { Sprint } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/sprints/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import messages from '../../messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles
import * as styles from './styles.module.css';
import type { DisplayLabelProps, MenuLabelProps, LabelNode, NewMenuLabelProps } from './types';

const getTestId = (sprintName?: string | null) =>
	typeof sprintName === 'string'
		? `sprint-select-option-${sprintName.replace(/\s/g, '-')}`
		: undefined;

const ExternalSprint = injectIntl(
	({
		children,
		intl,
		isCompleted,
	}: {
		children: LabelNode | null | undefined;
		intl: IntlShape;
		isCompleted?: boolean;
	}) => {
		const tooltipContent = (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.tooltip}>
				<Heading as="h4" size="small">
					{intl.formatMessage(messages.externalSprintTooltipTitle)}
				</Heading>
				<p>{intl.formatMessage(messages.externalSprintTooltipContent)}</p>
			</div>
		);
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.externalSprint}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<span className={styles.externalSprintText}>{children}</span>
				<span
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles.externalSprintLozenge}
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					onFocus={() => {}}
					onMouseOver={(e) => e.stopPropagation()}
				>
					<Tooltip content={tooltipContent}>
						<Lozenge>
							<FormattedMessage {...commonMessages.externalLozenge} />
						</Lozenge>
					</Tooltip>
					{isCompleted === true && (
						<Lozenge>
							<FormattedMessage {...messages.completedSprintsLozenge} />
						</Lozenge>
					)}
				</span>
			</div>
		);
	},
);

function UncompletedSprint({
	children,
	sprintIsExternal,
}: {
	children: LabelNode | null | undefined;
	sprintIsExternal: boolean;
}) {
	return sprintIsExternal ? (
		<ExternalSprint>{children}</ExternalSprint>
	) : (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<span className={styles.sprintTitle}>{children}</span>
	);
}

function CompletedSprints({
	peak = false,
	prefix,
	sprints,
	sprintIsExternal,
}: {
	peak?: boolean;
	prefix?: string;
	sprints: Sprint[];
	sprintIsExternal?: boolean;
}) {
	if (R.isEmpty(sprints)) {
		return null;
	}

	if (isDefined(prefix)) {
		return (
			<>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<span className={`${styles.completedSprintsChunk} ${styles.sprintTitlePrefix}`}>
					{prefix}
				</span>
				<CompletedSprints sprintIsExternal={sprintIsExternal} sprints={sprints} />
			</>
		);
	}

	if (peak) {
		return (
			<>
				<CompletedSprints sprintIsExternal={sprintIsExternal} sprints={R.slice(0, 1)(sprints)} />
				<CompletedSprints
					sprintIsExternal={sprintIsExternal}
					sprints={R.slice(1, Infinity)(sprints)}
					prefix="+"
				/>
			</>
		);
	}

	if (sprints.length === 1) {
		const sprint = R.head(sprints);
		if (!isDefined(sprint)) return null;

		return sprintIsExternal === true ? (
			<ExternalSprint>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<span className={`${styles.completedSprintsChunk} ${styles.sprintTitle}`}>
					{sprint.title}
				</span>
			</ExternalSprint>
		) : (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<span className={`${styles.completedSprintsChunk} ${styles.sprintTitle}`}>
				{sprint.title}
			</span>
		);
	}

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<span className={`${styles.completedSprintsChunk} ${styles.sprintTitle}`}>
			<FormattedMessage
				{...messages.numCompletedSprints}
				values={{ numCompletedSprints: sprints.length }}
			/>
		</span>
	);
}

export const DisplayLabel = function DisplayLabel({
	issueIsCompleted,
	issueCompletedSprints,
	sprintIsExternal,
	sprintNotSet,
	sprintIsOptimized,
	children,
}: DisplayLabelProps) {
	if (issueIsCompleted) {
		return (
			<CompletedSprints sprintIsExternal={sprintIsExternal} peak sprints={issueCompletedSprints} />
		);
	}

	if (sprintNotSet && !sprintIsOptimized) {
		return (
			<CompletedSprints sprintIsExternal={sprintIsExternal} peak sprints={issueCompletedSprints} />
		);
	}

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.completedAndUncompletedSprints}>
			<UncompletedSprint sprintIsExternal={sprintIsExternal}>{children}</UncompletedSprint>
			<CompletedSprints sprints={issueCompletedSprints} prefix="+" />
		</div>
	);
};

// clean up when removing use_sprint_search_in_plan_dropdown FG
// eslint-disable-next-line @typescript-eslint/no-shadow
export const MenuLabelOld = function DisplayLabel({
	sprintIsExternal,
	sprintIsCompleted,
	children,
}: MenuLabelProps) {
	if (sprintIsExternal) {
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.menuLabelDisabled}>
				<ExternalSprint isCompleted={sprintIsCompleted}>{children}</ExternalSprint>
			</div>
		);
	}

	if (sprintIsCompleted) {
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.menuLabelDisabled}>
				<span>{children}</span>
				<Lozenge>
					<FormattedMessage {...messages.completedSprintsLozenge} />
				</Lozenge>
			</div>
		);
	}

	return <span data-testid={getTestId(children)}>{children}</span>;
};

export const MenuLabel = ({
	sprintIsExternal,
	sprintIsCompleted,
	label,
	boardName,
}: NewMenuLabelProps) => {
	if (sprintIsExternal) {
		return (
			<ExternalSprintInMenu isCompleted={sprintIsCompleted} boardName={boardName}>
				{label}
			</ExternalSprintInMenu>
		);
	}

	if (sprintIsCompleted) {
		return (
			<Inline spread="space-between" alignBlock="center">
				<Text color="color.text.subtlest">{label}</Text>
				<Lozenge>
					<FormattedMessage {...messages.completedSprintsLozenge} />
				</Lozenge>
			</Inline>
		);
	}

	return <span data-testid={getTestId(label)}>{label}</span>;
};

const ExternalSprintInMenu = ({
	children,
	isCompleted,
	boardName,
}: {
	children: LabelNode | null | undefined;
	isCompleted?: boolean;
	boardName: string | null | undefined;
}) => (
	<Stack>
		<Inline alignBlock="center">
			<span>{children}</span>
			<Box xcss={completedLozengeStyles}>
				{isCompleted === true && (
					<Lozenge>
						<FormattedMessage {...messages.completedSprintsLozenge} />
					</Lozenge>
				)}
			</Box>
		</Inline>
		{boardName && (
			<Text size="small" color="color.text.subtlest">
				{boardName}
			</Text>
		)}
	</Stack>
);

const completedLozengeStyles = xcss({
	marginLeft: 'space.100',
});
