import type { Project } from './types';

export const RESET = 'state.domain.projects.RESET' as const;
export const ADD = 'state.domain.projects.ADD' as const;
export const ADD_VERSION_IN_PROJECT = 'state.domain.projects.ADD_VERSION_IN_PROJECT' as const;
export const REMOVE_VERSION_FROM_PROJECT =
	'state.domain.projects.REMOVE_VERSION_FROM_PROJECT' as const;
export const SET_DEFAULT_PROJECT = 'state.domain.projects.SET_DEFAULT_PROJECT' as const;

export type ResetActionPayload = Project[];

export type AddRemoveVersionFromProjectActionPayload = {
	projectId: number;
	versionId: string;
};

export type SetDefaultProjectActionPayload = {
	projectId: number;
};

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export type AddAction = {
	type: typeof ADD;
	payload: ResetActionPayload;
};

export type AddVersionInProjectAction = {
	type: typeof ADD_VERSION_IN_PROJECT;
	payload: AddRemoveVersionFromProjectActionPayload;
};

export type RemoveVersionFromProjectAction = {
	type: typeof REMOVE_VERSION_FROM_PROJECT;
	payload: AddRemoveVersionFromProjectActionPayload;
};

export type SetDefaultProjectAction = {
	type: typeof SET_DEFAULT_PROJECT;
	payload: SetDefaultProjectActionPayload;
};

export const reset = (payload: ResetActionPayload) => ({
	type: RESET,
	payload,
});

export const add = (payload: ResetActionPayload) => ({
	type: ADD,
	payload,
});

export const addVersionInProject = (payload: AddRemoveVersionFromProjectActionPayload) => ({
	type: ADD_VERSION_IN_PROJECT,
	payload,
});

export const removeVersionFromProject = (payload: AddRemoveVersionFromProjectActionPayload) => ({
	type: REMOVE_VERSION_FROM_PROJECT,
	payload,
});

export const setDefaultProject = (payload: SetDefaultProjectActionPayload) => ({
	type: SET_DEFAULT_PROJECT,
	payload,
});
