import React, { useCallback, useState, useRef, type FocusEvent } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { defaultSorting } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/visualisations/types.tsx';
import { getSortConfiguration } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/view-settings/sort-by/utils';
import { SORT_DIRECTION } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import { KEYS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/interaction-constants';
import traceUFOPress from '@atlassian/react-ufo/trace-press';
import { Sort } from './sort';
import { Toggle } from './toggle';
import type { Props } from './types';

export const FieldSubheader = ({
	title,
	icon,
	column,
	useColumnToggle,
	customFields,
	sorting,
	changeSort,
	collapsible,
}: Props) => {
	const [isFocused, setIsFocused] = useState<boolean>(false);
	const containerRef = useRef<HTMLDivElement>(null);

	const isSorted = sorting.field === column.attribute;
	const sortDirection = isSorted ? sorting.direction : SORT_DIRECTION.ASCENDING;

	const updateSort = useCallback(() => {
		const isCurrentFieldSorted = sorting.field === column.attribute;

		if (isCurrentFieldSorted && sorting.direction === SORT_DIRECTION.DESCENDING) {
			// Reset sort to default state
			traceUFOPress(KEYS.SORT_BY_FIELD);
			changeSort(defaultSorting);
		} else {
			const newDirection =
				sorting.direction === SORT_DIRECTION.ASCENDING
					? SORT_DIRECTION.DESCENDING
					: SORT_DIRECTION.ASCENDING;

			const newSort = getSortConfiguration(
				column.attribute,
				customFields,
				isCurrentFieldSorted ? newDirection : SORT_DIRECTION.ASCENDING,
			);

			traceUFOPress(KEYS.SORT_BY_FIELD);
			changeSort(newSort);
		}
	}, [changeSort, column.attribute, customFields, sorting.direction, sorting.field]);

	const focusElement = useCallback(() => {
		setIsFocused(true);
	}, []);

	const blurElement = useCallback(() => {
		setIsFocused(false);
	}, []);

	const toggle = useColumnToggle();
	const collapse = useCallback(() => toggle(false), [toggle]);

	const onContainerBlur = useCallback(
		(event: FocusEvent) => {
			if (
				containerRef.current &&
				!containerRef.current.contains(event.relatedTarget) &&
				containerRef.current !== event.relatedTarget
			) {
				blurElement();
			}
		},
		[blurElement],
	);

	return (
		<Box
			xcss={containerStyles}
			onFocus={focusElement}
			onMouseEnter={focusElement}
			onMouseLeave={blurElement}
			onBlur={onContainerBlur}
			data-test-class="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.subheader.field.header"
		>
			{collapsible && <Toggle onClick={collapse} />}
			<Box xcss={titleStyles}>
				<Box xcss={fieldTitleStyles}>{title}</Box>
				{icon && <Box xcss={headerContentWrapperStyles}>{icon}</Box>}
			</Box>

			{column.isSortable && (isFocused || isSorted) ? (
				<Sort sortDirection={sortDirection} onClick={updateSort} isSorted={isSorted} />
			) : null}
		</Box>
	);
};

const containerStyles = xcss({
	position: 'absolute',
	inset: 'space.0',
	display: 'flex',
	alignItems: 'center',
	paddingLeft: 'space.150',
	paddingRight: 'space.150',
	boxSizing: 'border-box',
});

const headerContentWrapperStyles = xcss({
	pointerEvents: 'initial',
});

const titleStyles = xcss({
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	flex: 1,
	// At the time of the implementation, we don't have size.0 token yet,
	// @ts-expect-error - TS2322: Type number is not assignable to type
	minWidth: 0,
	pointerEvents: 'none',
});

const fieldTitleStyles = xcss({
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
});
