import { useRef } from 'react';
import isEqual from 'lodash/fp/isEqual';
import omit from 'lodash/fp/omit';
import type { ZoomLevel } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/common/types.tsx';
import type { SprintUnit } from './types';

const omitWidth = omit(['widthPercentage']);

export function useMemoedSprint(sprint: SprintUnit, zoomLevel?: ZoomLevel) {
	const sprintRef = useRef(sprint);

	if (!isEqual(omitWidth(sprintRef.current), omitWidth(sprint)) || !zoomLevel) {
		sprintRef.current = sprint;
	}

	return sprintRef.current;
}
