import { getSupportedCustomFields } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields';
import { getPlan } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan';
import { getTeamsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams';
import { getExpandedChanges } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

const querySelector = createStructuredSelector<State, StateProps>({
	plan: getPlan,
	expandedChanges: getExpandedChanges,
	customFields: getSupportedCustomFields,
	teamsById: getTeamsById,
});

export default querySelector;
