import type { Dispatch } from 'redux';
import { loadExternalIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/external-issues';
import {
	addIssueLink,
	type AddActionPayload,
	deleteIssueLink,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue-links';
import type { RemoveActionPayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-links/actions.tsx';
import {
	changeDisplay,
	type ChangeDisplayPayload,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/dependency-settings/actions';
import type { DispatchProps } from './types';

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	addDependency: (payload: AddActionPayload) => {
		dispatch(addIssueLink(payload));
	},
	removeDependency: (payload: RemoveActionPayload) => {
		dispatch(deleteIssueLink(payload));
	},
	loadExternalIssues: (ids: string[]) => {
		dispatch(loadExternalIssues(ids));
	},
	changeDependencySettingsDisplay: (payload: ChangeDisplayPayload) => {
		dispatch(changeDisplay(payload));
	},
});

export default mapDispatchToProps;
