import React from 'react';
import { useDateFormatter } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip';
import type { Props } from './types';

export default function DateCustomField(props: Props) {
	const { value } = props;
	const { formatTimestamp } = useDateFormatter();

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const getFormattedDate = (value: number): string => formatTimestamp(value);

	const date = typeof value === 'number' ? getFormattedDate(value) : value;
	return <EllipsedWithTooltip content={date}>{date}</EllipsedWithTooltip>;
}
