import * as R from 'ramda';
import {
	RESET,
	UPDATE,
	REMOVE,
	UPDATE_TEAM_ID,
	type ResetAction,
	type UpdateAction,
	type RemoveAction,
	type UpdateTeamIdAction,
} from './actions';
import type { PlannedCapacities, PlannedCapacity } from './types';

const initialState: PlannedCapacities = {};

type Action = ResetAction | UpdateAction | RemoveAction | UpdateTeamIdAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: PlannedCapacities = initialState, action: Action): PlannedCapacities => {
	switch (action.type) {
		case RESET: {
			const resetAction: ResetAction = action;
			return { ...resetAction.payload };
		}
		case UPDATE: {
			const updateAction: UpdateAction = action;
			const { iterationId, teamId } = updateAction.payload;
			return R.assocPath([teamId, iterationId], updateAction.payload, state);
		}
		case REMOVE: {
			const {
				payload: { teamId, iterationId },
			}: RemoveAction = action;
			return R.dissocPath([teamId, iterationId], state);
		}
		case UPDATE_TEAM_ID: {
			const {
				payload: { oldId, newId },
			} = // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				action as UpdateTeamIdAction;
			const newState = { ...state };
			if (newState[oldId]) {
				newState[newId] = R.map(
					(capacity: PlannedCapacity) => ({
						...capacity,
						teamId: newId,
					}),
					newState[oldId],
				);
				delete newState[oldId];
			}
			return newState;
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
