import type { Effect } from 'redux-saga';
import { put } from 'redux-saga/effects';
import { initiateFilters } from '../../state/ui/main/tabs/dependencies/filters/actions';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { setIssueFilter } from '../../state/ui/main/tabs/dependencies/filters/actions';

/**
 * Apply the dependency report filters
 * This is called during initialLoad
 */
export function* populateDependencyReportFilterSettings(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	filterSettings: any, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, void, any> {
	yield put(initiateFilters(filterSettings));
}
