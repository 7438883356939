import React from 'react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import {
	MODAL,
	ContextualAnalyticsData,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { useCtpOnboardingTour } from '../../../controllers/ctp-onboarding';
import { CtpSpotlights } from '../../../controllers/ctp-spotlights';
import { messages } from './messages';

export const TimelineSpotlight = () => {
	const { formatMessage } = useIntl();
	const [, { nextCtpStage }] = useCtpOnboardingTour();

	return (
		<ContextualAnalyticsData sourceName="crossTeamPlanningTimelineSpotlight" sourceType={MODAL}>
			<JiraSpotlight
				actionsBeforeElement="1/4"
				actions={[
					{
						text: formatMessage(messages.next),
						onClick: () => nextCtpStage(),
					},
				]}
				heading={formatMessage(messages.heading)}
				target={CtpSpotlights.Timeline}
				key={CtpSpotlights.Timeline}
				targetBgColor={token('elevation.surface', colors.N0)}
				targetRadius={3}
				dialogPlacement="right top"
				dialogWidth={350}
				messageId="portfolio-3-onboarding.ui.ctp-onboarding.timeline-spotlight.jira-spotlight"
				messageType="engagement"
			>
				<FormattedMessage {...messages.bodyFirst} />
				<br />
				<br />
				<FormattedMessage {...messages.bodySecond} />
			</JiraSpotlight>
			<FireScreenAnalytics />
		</ContextualAnalyticsData>
	);
};
