import { useEffect, useRef } from 'react';
import {
	useViewport,
	useScrollY,
} from '@atlassian/jira-portfolio-3-treegrid/src/controllers/container/index.tsx';
import type { Props, VerticalBound } from './types';
import { distanceIntoView, getRowVerticalBound } from './utils';

/** Supports ability to scroll into view for table rows (inline create & Issue search functions). */
export const ScrollIntoView = ({ rowConfigs, scrollIntoViewIndex }: Props) => {
	const [{ height }] = useViewport();
	const [scrollY, { scrollTo }] = useScrollY();
	const nextScrollYRef = useRef(scrollY);

	const viewport: VerticalBound = [
		scrollY + 130 /* excludes possible sticky rows blockage */,
		scrollY +
			height -
			80 /* some padding at bottom for inline-create [Cancel] - [Submit] buttons */,
	];
	const element = scrollIntoViewIndex && getRowVerticalBound(rowConfigs, scrollIntoViewIndex);

	const distance = element && distanceIntoView(viewport, element);
	nextScrollYRef.current = scrollY + (distance ?? 0);

	/* Only take effects when scrollIntoViewIndex changes */
	useEffect(() => {
		const nextScrollY = nextScrollYRef.current;

		if (scrollIntoViewIndex !== undefined) {
			scrollTo({ y: nextScrollY });
		}
	}, [scrollIntoViewIndex, scrollTo]);

	return null;
};
