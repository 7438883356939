import {
	DateUnits,
	CUSTOM_TIME_RANGE_TYPES,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/constants.tsx';
import { getRelativeDates } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import type { CustomDateRange } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/types.tsx';
import { getTodayDate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/timeline';
import type { TimeScaleState } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/time-scale/types';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { TIMELINE_MODES } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';

export const EXPORT_PNG_TIMELINE_WIDTH_OPTIONS = {
	NONE: 'none',
	SMALL: 'small',
	MEDIUM: 'medium',
	LARGE: 'large',
	CUSTOM: 'custom',
} as const;

export const removeTrailingSlash = (str: string) => str.replace(/\/$/, '');

export const getInitialExportDateRange = (
	timescaleViewSettings: TimeScaleState = {
		mode: TIMELINE_MODES.MONTHS,
	},
	todayDate: Date = getTodayDate(),
): CustomDateRange => {
	const { mode, customDateRange } = timescaleViewSettings;

	// "To now range" defaults to 1 unit before today.
	// Note that "to now range" converts to fixed date range "from date".
	const toNowMultiplier = 1;
	// "From now range" defaults to 3 units after today.
	// Note that "from now range" converts to fixed date range "to date".
	const fromNowMultiplier = 3;
	// The default unit is set to 'months'
	let relativeDateRange: CustomDateRange = {
		typeOfCustomDateRange: CUSTOM_TIME_RANGE_TYPES.RELATIVE,
		fromNowUnitCount: fromNowMultiplier,
		toNowUnitCount: toNowMultiplier,
		fromNowUnit: DateUnits.MONTHS,
		toNowUnit: DateUnits.MONTHS,
	};
	switch (mode) {
		case TIMELINE_MODES.CUSTOM:
			if (isDefined(customDateRange)) {
				if (customDateRange.typeOfCustomDateRange === CUSTOM_TIME_RANGE_TYPES.FIXED) {
					return customDateRange;
				}
				relativeDateRange = customDateRange;
			}
			break;
		case TIMELINE_MODES.WEEKS:
			relativeDateRange.fromNowUnit = DateUnits.WEEKS;
			relativeDateRange.toNowUnit = DateUnits.WEEKS;
			break;
		case TIMELINE_MODES.QUARTERS:
			relativeDateRange.fromNowUnit = DateUnits.MONTHS;
			relativeDateRange.toNowUnit = DateUnits.MONTHS;
			relativeDateRange.fromNowUnitCount = 3 * fromNowMultiplier;
			relativeDateRange.toNowUnitCount = 3 * toNowMultiplier;
			break;
		case TIMELINE_MODES.YEARS:
			relativeDateRange.fromNowUnit = DateUnits.MONTHS;
			relativeDateRange.toNowUnit = DateUnits.MONTHS;
			relativeDateRange.fromNowUnitCount = 12 * fromNowMultiplier;
			relativeDateRange.toNowUnitCount = 12 * toNowMultiplier;
			break;
		case TIMELINE_MODES.MONTHS:
		default:
			break;
	}
	const { start, end } = getRelativeDates(relativeDateRange, todayDate);
	return {
		typeOfCustomDateRange: CUSTOM_TIME_RANGE_TYPES.FIXED,
		fromDate: start,
		toDate: end,
	};
};
