import {
	isConfluenceMacro,
	isEmbed,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app';
import {
	getSearchQuery,
	getActiveSearchResultIndex,
	searchForIssues,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export default createStructuredSelector<State, {}, StateProps>({
	searchQuery: getSearchQuery,
	issueSearchResults: searchForIssues,
	activeSearchResultIndex: getActiveSearchResultIndex,
	isConfluenceMacro,
	isEmbed,
});
