import { getCustomFieldsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields';
import { getVisualisationGrouping } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export default createStructuredSelector<State, StateProps>({
	customFieldsById: getCustomFieldsById,
	grouping: getVisualisationGrouping,
});
