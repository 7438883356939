import { isReadOnly } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app';
import {
	getViews,
	getActiveView,
	getErrors,
	getSaveViewAsDialogStatus,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/views';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { Props, StateProps } from './types';

export default createStructuredSelector<State, Props, StateProps>({
	views: getViews,
	activeView: getActiveView,
	errors: getErrors,
	saveViewAsDialogStatus: getSaveViewAsDialogStatus,
	readOnly: isReadOnly,
});
