import {
	getAdditionalTeamsById,
	getEditedTeam,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import type { StateProps } from './types';

const isDialogOpen = (state: State): boolean => state.ui.Main.Tabs.Teams.TeamsList.isDialogOpen;

const mapStateToProps = (state: State): StateProps => ({
	isDialogOpen: isDialogOpen(state),
	editedTeam: getEditedTeam(state),
	additionalTeamsById: getAdditionalTeamsById(state),
});

export default mapStateToProps;
