import { combineReducers } from '@atlassian/jira-portfolio-3-portfolio/src/common/redux';
import Dependencies from './dependencies/reducer';
import Releases from './releases/reducer';
import Roadmap from './roadmap/reducer';
import Teams from './teams/reducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default combineReducers<any, any>({
	Roadmap,
	Releases,
	Teams,
	Dependencies,
});
