import type { SuggestedChildIssue } from '@atlassian/jira-issue-fetch-services/src/services/issue-breakdown-data-fetcher/types';
import type { ChildIssueType } from '@atlassian/jira-issue-view-common-types/src/child-issue-type';

export const getSelectedIssueType = (
	childIssueTypes: ChildIssueType[],
	issueTypeId: string,
): ChildIssueType | undefined => childIssueTypes.find(({ id }) => id === issueTypeId) || undefined;

/**
 *  Works similar to getAnalyticsIssueType from issue-create/issue-create-modal/src/ui/modal/create-form/utils.tsx
 */
const getAnalyticsIssueTypeName = (childIssueTypes: ChildIssueType[], issueTypeId: string) => {
	const systemIssueTypeValues = ['bug', 'story', 'task', 'sub-task', 'epic'];

	// if for some reason issueType is undefined, we will just return 'other'
	const issueType = getSelectedIssueType(childIssueTypes, issueTypeId);
	const issueTypeName = issueType?.name.toLowerCase();
	if (issueTypeName) {
		return systemIssueTypeValues.includes(issueTypeName) ? issueTypeName : 'other';
	}

	return 'other';
};

/**
 * Transforms suggested issue types into string array of names and converts array into object with issue type count
 */
export const getAnalyticsIssueTypesCount = (
	childIssueTypes: ChildIssueType[],
	suggestedIssues: SuggestedChildIssue[] | { issueTypeId: string }[],
) => {
	if (!childIssueTypes || !suggestedIssues || !childIssueTypes.length || !suggestedIssues.length) {
		return undefined;
	}

	const issueTypes = suggestedIssues.map((issue) =>
		getAnalyticsIssueTypeName(childIssueTypes, issue.issueTypeId),
	);

	const issueTypesCount: Record<string, number> = {};

	issueTypes.forEach((issueType) => {
		issueTypesCount[issueType] = (issueTypesCount[issueType] || 0) + 1;
	});

	return issueTypesCount;
};
