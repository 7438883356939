import React, {
	useState,
	useRef,
	useEffect,
	useCallback,
	type KeyboardEvent,
	type MouseEvent,
	type RefObject,
} from 'react';
import { createPortal } from 'react-dom';
import debounce from 'lodash/debounce';
import { saveAs } from 'file-saver';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import ButtonGroup from '@atlaskit/button/button-group';
import InfoIcon from '@atlaskit/icon/glyph/info';
import SectionMessage from '@atlaskit/section-message';
import Tooltip from '@atlaskit/tooltip';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import { monitor } from '@atlassian/jira-portfolio-3-common/src/analytics/performance';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { VIEW_MODES } from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import type { CustomDateRange } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/types.tsx';
import Spinner from '@atlassian/jira-portfolio-3-common/src/spinner/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types';
import { EXPORT_EVENT_TYPE } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/export-roadmap';
import { getExportPngFileName } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/export-roadmap/utils';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import {
	NUM_ISSUES_IN_EXPORT_PREVIEW,
	PERFORMANCE_KEYS,
	PROFILE_KEYS,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import DocLink from '@atlassian/jira-portfolio-3-portfolio/src/common/view/doc-link';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import {
	fireUIAnalytics,
	FireScreenAnalytics,
	useAnalyticsEvents,
	MODAL,
} from '@atlassian/jira-product-analytics-bridge';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge/src';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import TimelineSettings from './timeline-settings';
import type { Props, ExportPngTimelineWidthOptions } from './types';
import { EXPORT_PNG_TIMELINE_WIDTH_OPTIONS } from './util';

const MAX_VISIBLE_ISSUES = 500;
const DAY_MS = 1000 * 60 * 60 * 24;
const WARNING_ISSUE_COUNT_LIMIT = 150;

const presetWidths = {
	none: '0',
	small: '400',
	medium: '700',
	large: '900',
	custom: '',
} as const;

const buildIframeUrls = (
	href: string,
	scopeWidth: number,
	fieldsWidth: number,
	fromDate?: number,
	toDate?: number,
): {
	iframeURL: string;
	downloadModeIframeURL: string;
} => {
	const url = new URL(href);

	url.searchParams.set('scopeWidth', scopeWidth.toString());
	url.searchParams.set('fieldsWidth', fieldsWidth.toString());
	url.searchParams.set('startInPreviewMode', '1');
	if (isDefined(fromDate)) {
		url.searchParams.set('fromDate', fromDate.toString());
	}
	if (isDefined(toDate)) {
		url.searchParams.set('toDate', toDate.toString());
	}
	const { pathname, hash } = url;
	const iframeURL = `${pathname.replace(/\/timeline\/?/, '')}/export/${url.search}${hash}`;

	url.searchParams.set('startInPreviewMode', '0');
	const downloadModeIframeURL = `${pathname.replace(/\/timeline\/?/, '')}/export/${
		url.search
	}${hash}`;

	return {
		iframeURL,
		downloadModeIframeURL,
	};
};

function PngExport({
	fieldsWidth,
	scopeWidth,
	initialTimelineDateRange,
	numIssuesInPlan,
	numIssues,
	onClose,
	planMode,
	planTitle,
	SpinnerComponent = Spinner,
	renderPreviewIframe = true,
	testIframeUrl = null,
	planExportedAnalytics,
	viewMode,
}: Props) {
	const intl = useIntl();
	const didFinishInitialPreview = useRef<boolean>(false);
	const didDownload = useRef<boolean>(false);
	const isUnmounting = useRef<boolean>(false);
	const [isDownloading, setIsDownloading] = useState(false);
	const [isDownloadModeReady, setIsDownloadModeReady] = useState(false);

	const defaultSelection =
		viewMode === VIEW_MODES.TIMELINE
			? EXPORT_PNG_TIMELINE_WIDTH_OPTIONS.LARGE
			: EXPORT_PNG_TIMELINE_WIDTH_OPTIONS.NONE;
	const [selection, setSelection] = useState<ExportPngTimelineWidthOptions>(defaultSelection);
	const [customValue, setCustomValue] = useState<string>(presetWidths.large);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [imgData, setImgData] = useState<string | null>(null);

	const [timelineDateRange, setTimelineDateRange] =
		useState<CustomDateRange>(initialTimelineDateRange);

	const iframeRef = useRef<HTMLIFrameElement>(null);
	const downloadModeIframeRef = useRef<HTMLIFrameElement>(null);
	const isInvalidCustomValue =
		selection === EXPORT_PNG_TIMELINE_WIDTH_OPTIONS.CUSTOM &&
		customValue.trim() !== '' &&
		(Number(customValue) > 5000 || Number(customValue) < 400);
	const timelineDays =
		isDefined(timelineDateRange.toDate) && isDefined(timelineDateRange.fromDate)
			? Math.round((timelineDateRange.toDate - timelineDateRange.fromDate) / DAY_MS)
			: 0;

	const handleSetSelection = (
		type: ExportPngTimelineWidthOptions,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		const [actionSubject, action, actionSubjectId] =
			PRODUCT_ANALYTICS_EVENT_NAMES.EXPORT_PNG_CHANGE_TIMELINE_WIDTH.split(' ');
		fireUIAnalytics(analyticsEvent.update({ action, actionSubject }), actionSubjectId, {
			timelineWidth: type,
		});

		setCustomValue(presetWidths[type]);
		setSelection(type);
	};

	function changeWidth(newWidth: number) {
		if (iframeRef.current !== null && iframeRef.current.contentWindow) {
			if (didFinishInitialPreview.current) {
				monitor.startTask(PERFORMANCE_KEYS.EXPORT_PNG.SUBSEQUENT_PREVIEW, {
					scalingFactors: {
						issuesInPlan: numIssuesInPlan,
						issuesInExport: numIssues,
					},
				});
			}
		}
		const changeWidthOnIframe = (iframe: RefObject<HTMLIFrameElement>) => {
			if (iframe.current !== null && iframe.current.contentWindow) {
				setImgData(null);
				setIsDownloadModeReady(false);
				iframe.current &&
					iframe.current.contentWindow.postMessage({
						type: 'updateTimelineWidth',
						payload: {
							fieldsWidth,
							scopeWidth,
							timelineExport: newWidth,
						},
					});
			}
		};
		changeWidthOnIframe(iframeRef);
		changeWidthOnIframe(downloadModeIframeRef);
	}

	const debouncedChangeWidth = useRef(debounce(changeWidth, 1000));

	const handleChangeTimelineDateRange = (newCustomDates: CustomDateRange) => {
		const range: CustomDateRange = {
			...timelineDateRange,
			...newCustomDates,
		};
		setTimelineDateRange(range);
		if (iframeRef.current !== null && iframeRef.current.contentWindow) {
			monitor.startTask(PERFORMANCE_KEYS.EXPORT_PNG.SUBSEQUENT_PREVIEW, {
				scalingFactors: {
					issuesInPlan: numIssuesInPlan,
					issuesInExport: numIssues,
				},
			});
		}
		const changeDateRangeOnIframe = (iframe: RefObject<HTMLIFrameElement>) => {
			if (iframe.current !== null && iframe.current.contentWindow) {
				setImgData(null);
				setIsDownloadModeReady(false);
				iframe.current?.contentWindow.postMessage({
					type: 'updateTimelineDateRange',
					payload: {
						timelineDateRange: range,
					},
				});
			}
		};

		changeDateRangeOnIframe(iframeRef);
		changeDateRangeOnIframe(downloadModeIframeRef);
	};

	function downloadImageFromIframe() {
		if (downloadModeIframeRef.current && downloadModeIframeRef.current.contentWindow) {
			downloadModeIframeRef.current.contentWindow.postMessage({
				type: 'downloadImage',
			});
		}
	}

	const handleDownload = (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		_: KeyboardEvent<any> | MouseEvent<any>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		monitor.startTask(PERFORMANCE_KEYS.EXPORT_PNG.EXPORT, {
			scalingFactors: {
				issuesInPlan: numIssuesInPlan,
				issuesInExport: numIssues,
			},
		});
		setIsDownloading(true);

		fireUIAnalytics(analyticsEvent, 'exportPngDownload', {
			issuesInPlan: numIssuesInPlan,
			issuesInExport: numIssues,
			timelineWidth: selection,
			timelineDays,
			planMode,
		});

		// The iframe will re-export with all the issues, then post 'downloadImage' message
		// which we listen to here and trigger the download.
		downloadImageFromIframe();
	};

	const doDownload = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-shadow
		async (imgData: string | null) => {
			if (imgData !== null) {
				const res = await fetch(imgData);
				const blob = await res.blob();
				saveAs(
					blob,
					getExportPngFileName(
						intl.formatMessage(messages.pngExportName, { planTitle }),
						new Date(),
					),
				);
				didDownload.current = true;
				planExportedAnalytics({
					exportFormat: EXPORT_EVENT_TYPE.PNG,
					issuesInPlan: numIssuesInPlan,
					issuesInExport: numIssues,
					timelineWidth: selection,
					timelineDays,
				});
			}
			setIsDownloading(false);
		},
		[intl, planTitle, planExportedAnalytics, numIssuesInPlan, numIssues, selection, timelineDays],
	);

	useEffect(() => {
		if (isInvalidCustomValue) {
			return;
		}
		debouncedChangeWidth.current(Number(customValue));
	}, [customValue, isInvalidCustomValue]);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const listener = (event: any) => {
			switch (event.data.type) {
				// profile is triggered for analytics purposes
				case 'profile': {
					const { profileAction, profileKey } = event.data.payload;
					if (!profileAction || !profileKey) {
						throw new Error('profileAction or profileKey missing in profile event');
					}
					const profileFunc =
						profileAction === 'start'
							? monitor.startProfile.bind(monitor)
							: monitor.finishProfile.bind(monitor);
					profileFunc(profileKey);
					break;
				}
				// downloadModeReady is triggered when the download mode iframe has finished loading
				case 'downloadModeReady':
					setIsDownloadModeReady(true);
					break;
				// exportedImage is triggered when the subsequent preview image is generated
				case 'exportedImage':
					setImgData(event.data.payload.image);
					monitor.finishTask(PERFORMANCE_KEYS.EXPORT_PNG.SUBSEQUENT_PREVIEW);
					break;
				// initialExportedImage is triggered when the initial preview image is generated
				case 'initialExportedImage':
					setImgData(event.data.payload.image);
					monitor.finishTask(PERFORMANCE_KEYS.EXPORT_PNG.INITIAL_PREVIEW);
					didFinishInitialPreview.current = true;
					break;
				// fullExportedImage is triggered when the download mode image is generated
				case 'fullExportedImage':
					doDownload(event.data.payload.image);
					monitor.finishTask(PERFORMANCE_KEYS.EXPORT_PNG.EXPORT);
					break;
				default:
					break;
			}
		};

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('message', listener);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		return () => window.removeEventListener('message', listener);
	}, [doDownload]);

	// Setup timer for the initial preview
	useEffect(() => {
		monitor.startTask(PERFORMANCE_KEYS.EXPORT_PNG.INITIAL_PREVIEW, {
			scalingFactors: {
				issuesInPlan: numIssuesInPlan,
				issuesInExport: numIssues,
			},
		});
		// This is the only profile we start from the parent.
		// The rest are started from the iframe using the 'profile' postMessage event.
		monitor.startProfile(PROFILE_KEYS.EXPORT_PNG.INITIAL_LOAD);
	}, [numIssues, numIssuesInPlan]);

	// Send analytics before dismounting
	useEffect(
		() => () => {
			isUnmounting.current = true;
		},
		[],
	);
	useEffect(
		() => () => {
			if (isUnmounting.current) {
				const [actionSubject, action, actionSubjectId] =
					PRODUCT_ANALYTICS_EVENT_NAMES.EXPORT_PNG_MODAL_CLOSED.split(' ');
				fireUIAnalytics(
					createAnalyticsEvent({
						action,
						actionSubject,
					}),
					actionSubjectId,
					{
						issuesInPlan: numIssuesInPlan,
						issuesInExport: numIssues,
						isDownloading,
						isGeneratingPreview:
							imgData === null &&
							!isDownloadModeReady &&
							!isDownloading &&
							numIssues <= MAX_VISIBLE_ISSUES,
						isShowingTooManyIssuesWarning: numIssues > MAX_VISIBLE_ISSUES,
						didDownload: didDownload.current,
						viewMode,
					},
				);
			}
		},
		[
			createAnalyticsEvent,
			imgData,
			isDownloading,
			isDownloadModeReady,
			numIssues,
			numIssuesInPlan,
			viewMode,
		],
	);

	let iframeURL = '';
	let downloadModeIframeURL = '';

	const URLs = buildIframeUrls(
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.location.href,
		scopeWidth,
		fieldsWidth,
		initialTimelineDateRange.fromDate,
		initialTimelineDateRange.toDate,
	);
	iframeURL = isDefined(testIframeUrl) ? `${testIframeUrl}&startInPreviewMode=1` : URLs.iframeURL;
	downloadModeIframeURL = isDefined(testIframeUrl)
		? `${testIframeUrl}&startInPreviewMode=0`
		: URLs.downloadModeIframeURL;

	let invalidMsg = null;
	if (numIssues > MAX_VISIBLE_ISSUES) {
		invalidMsg = (
			<SectionMessage appearance="warning">
				<FireScreenAnalytics
					attributes={{
						viewDisplayed: 'tooManyIssuesWarning',
						issuesInPlan: numIssuesInPlan,
						issuesInExport: numIssues,
					}}
				/>
				<p>
					<FormattedMessage
						{...messages.tooManyIssues}
						values={{
							visibleIssues: numIssues,
						}}
					/>{' '}
					<FormattedMessage
						{...messages.plansMaxIssues}
						values={{ maxIssues: MAX_VISIBLE_ISSUES }}
					/>
				</p>
				<p>
					<FormattedMessage
						{...messages.maxIssuesSuggestion}
						values={{
							maxIssues: MAX_VISIBLE_ISSUES,
						}}
					/>
				</p>
			</SectionMessage>
		);
	} else if (numIssues <= 0) {
		invalidMsg = (
			<SectionMessage appearance="warning">
				<FireScreenAnalytics
					attributes={{
						viewDisplayed: 'noIssuesWarning',
						issuesInPlan: numIssuesInPlan,
						issuesInExport: numIssues,
					}}
				/>
				<FormattedMessage {...messages.noIssues} />
			</SectionMessage>
		);
	}

	return (
		<>
			<ContextualAnalyticsData sourceName="exportPng" sourceType={MODAL}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.container}>
					{invalidMsg || (
						<>
							<FireScreenAnalytics
								attributes={{
									viewDisplayed: 'exportControls',
									issuesInPlan: numIssuesInPlan,
									issuesInExport: numIssues,
								}}
							/>
							{viewMode === VIEW_MODES.TIMELINE && (
								<TimelineSettings
									isDisabled={isDownloading}
									selection={selection}
									setSelection={handleSetSelection}
									customWidth={customValue}
									setCustomWidth={setCustomValue}
									customDateRange={timelineDateRange}
									setCustomDateRange={handleChangeTimelineDateRange}
								/>
							)}
							{isInvalidCustomValue && (
								<div
									data-test-id="portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.invalid-timeline-message"
									// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
									className={styles.errorMessage}
								>
									<span>{intl.formatMessage(messages.customValueErrorMessage)}</span>
								</div>
							)}
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<div className={styles.previewContainer}>
								{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
								<div className={styles.previewBadge}>
									{imgData === null || !isDownloadModeReady || isDownloading ? (
										<>
											{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
											<div className={styles.spinnerContainer}>
												<SpinnerComponent size="small" invertColor />
											</div>
											{intl.formatMessage(
												isDownloading ? messages.exportingMessage : messages.loadingMessage,
												{
													visibleIssues: numIssues,
												},
											)}
										</>
									) : (
										<>
											{numIssues > NUM_ISSUES_IN_EXPORT_PREVIEW ? (
												<FormattedMessage
													{...messages.previewLozenge}
													values={{
														visibleIssues: numIssues,
														previewIssues: NUM_ISSUES_IN_EXPORT_PREVIEW,
													}}
												/>
											) : (
												intl.formatMessage(messages.previewLozengeBasic)
											)}
										</>
									)}
								</div>
								{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
								<div className={styles.previewPane}>
									{imgData !== null && isDownloadModeReady && (
										<img
											data-test-id="portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.preview-image"
											src={imgData}
											// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
											className={styles.previewImage}
											alt={intl.formatMessage(messages.pngExportImageAlt, {
												planTitle,
											})}
										/>
									)}
								</div>
							</div>
						</>
					)}
				</div>

				{numIssues > WARNING_ISSUE_COUNT_LIMIT && numIssues <= MAX_VISIBLE_ISSUES && (
					<Tooltip content={intl.formatMessage(messages.filterIssuesMessage)}>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.warningMessage}>
							<InfoIcon label="" />
							<div>
								{intl.formatMessage(messages.warningMessage)}{' '}
								<DocLink href={DocLink.URLs.exportPng}>
									{intl.formatMessage(commonMessages.learnMore)}
								</DocLink>
							</div>
						</div>
					</Tooltip>
				)}
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.footer}>
					<ButtonGroup>
						<Button
							appearance="primary"
							testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.download"
							isDisabled={
								isInvalidCustomValue || imgData === null || !isDownloadModeReady || isDownloading
							}
							onClick={handleDownload}
						>
							{intl.formatMessage(
								isDownloading ? messages.downloading : messages.downloadButtonLabel,
							)}
						</Button>
						<Button
							testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.cancel-button"
							appearance="subtle"
							onClick={onClose}
						>
							{intl.formatMessage(commonMessages.cancel)}
						</Button>
					</ButtonGroup>
				</div>
			</ContextualAnalyticsData>
			{renderPreviewIframe &&
				numIssues <= MAX_VISIBLE_ISSUES &&
				numIssues > 0 &&
				createPortal(
					<iframe
						ref={iframeRef}
						title={intl.formatMessage(messages.previewModeTitle)}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={styles.collapsedIframe}
						src={iframeURL}
					/>,

					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					document.body || document.createElement('div'),
				)}
			{renderPreviewIframe &&
				numIssues <= MAX_VISIBLE_ISSUES &&
				numIssues > 0 &&
				createPortal(
					<iframe
						ref={downloadModeIframeRef}
						title={intl.formatMessage(messages.downloadModeTitle)}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={styles.collapsedIframe}
						src={downloadModeIframeURL}
					/>,

					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					document.body || document.createElement('div'),
				)}
		</>
	);
}

export default PngExport;
