import {
	OPEN_DIALOG,
	CLOSE_DIALOG,
	type OpenDialogAction,
	type CloseDialogAction,
} from './actions';
import type { AddToCprDialog } from './types';

const initialState: AddToCprDialog = {
	isDialogOpen: false,
	versionId: '',
};

type Action = OpenDialogAction | CloseDialogAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: AddToCprDialog = initialState, action: Action) => {
	switch (action.type) {
		case OPEN_DIALOG:
			return {
				...state,
				isDialogOpen: true,
				versionId: action.versionId,
			};
		case CLOSE_DIALOG:
			return initialState;
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
