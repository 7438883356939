import React, { type ReactNode, type ComponentType } from 'react';
import { Box, Text } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { messages } from './messages';

type SpotlightProps = typeof JiraSpotlight extends ComponentType<infer TProps> ? TProps : unknown;

interface Props {
	target: string;
	actionsBeforeElement: ReactNode;
	headingAfterElement: ReactNode;
	actions: (arg0: { learnMoreURL: string }) => SpotlightProps['actions'];
}

export const ListModeSpotlight = ({
	target,
	actionsBeforeElement,
	headingAfterElement,
	actions,
}: Props) => {
	const { formatMessage } = useIntl();

	return (
		<JiraSpotlight
			target={target}
			targetBgColor={token('elevation.surface', colors.N0)}
			dialogPlacement="bottom right"
			actionsBeforeElement={actionsBeforeElement}
			actions={actions({ learnMoreURL: '' })}
			headingAfterElement={headingAfterElement}
			heading={formatMessage(messages.heading)}
			messageId="portfolio-3-onboarding.ui.onboarding.spotlight-list-mode.jira-spotlight"
			messageType="transactional"
		>
			<Box>
				<Text>
					{formatMessage(messages.description, {
						b: (...chunks: ReactNode[]) => <Text weight="bold">{chunks}</Text>,
					})}
				</Text>
			</Box>
		</JiraSpotlight>
	);
};
