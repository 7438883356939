import type { IntlShape, MessageDescriptor } from '@atlassian/jira-intl';
import type {
	AddAtlassianTeamAction,
	CreateTeamAction,
	DialogMode,
	EditedTeam,
	UpdateTeamAction,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/teams';
import type {
	AtlassianTeamPayload,
	EditTeamPayload,
	TeamPayload,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/teams/api';
import type { TeamPermissions } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/permissions/types.tsx';
import type { Team } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/teams/types.tsx';
import type {
	OpenTeamsDialogAction,
	SetModeAction,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/teams/list/actions';
import type { SchedulingMode } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';

export const EXISTING_TEAM = 'ExistingTeam' as const;
export const NEW_TEAM = 'NewTeam' as const;

export const LANDING_STEP = 'LandingStep' as const;
export const CREATE_COMPANY_SCOPED_TEAM = 'CreateCompanyScopedTeam' as const;
export const SUCCESS_CONFIRMATION = 'SuccessConfirmation' as const;
export const TEAM_SETTINGS = 'TeamSettings' as const;

export type AtlassianTeamOption = {
	avatarUrl: string;
	description: string;
	id: string;
	includesYou: boolean;
	memberCount: number;
	name: string;
	type: string;
};

export type Action =
	| AddAtlassianTeamAction
	| CreateTeamAction
	| UpdateTeamAction
	| OpenTeamsDialogAction
	| SetModeAction;

export type DispatchProps = {
	createTeam: (arg1: TeamPayload) => void;
	editTeam: (arg1: EditTeamPayload) => void;
	onAddAtlassianTeam: (args: AtlassianTeamPayload) => void;
	setEditMode: (team: EditedTeam) => void;
};

export type FormType = typeof EXISTING_TEAM | typeof NEW_TEAM;
export type FormStep =
	| typeof LANDING_STEP
	| typeof CREATE_COMPANY_SCOPED_TEAM
	| typeof SUCCESS_CONFIRMATION
	| typeof TEAM_SETTINGS;

export type TeamDialogCopyOverrides = {
	teamDialogHeading?: MessageDescriptor;
	teamDialogSubheading?: MessageDescriptor;
	teamDialogBody?: MessageDescriptor;
};
export type OwnProps = {
	onClose: () => void;
	recommendedSchedulingMode?: SchedulingMode;
} & TeamDialogCopyOverrides;

export type StateProps = {
	dialogMode: DialogMode | null | undefined;
	editedTeam: EditedTeam | null | undefined;
	getTeamById: (teamId: string) => Team | undefined;
	isProcessingRequest?: boolean;
	teams: Team[];
	addedTWPInfo: {
		teamTitle: string | undefined;
		teamId: string | undefined;
	};
	isCreateTwpFormLoading: boolean;
	teamPermissions: TeamPermissions;
};

export type Props = OwnProps & DispatchProps & StateProps;

export type PropsWithIntl = Props & {
	intl: IntlShape;
	cloudId: string;
	orgId?: string;
};

export type State = {
	formSelected: FormType;
	selectedAtlassianTeam: AtlassianTeamOption | null | undefined;
	selectedTeamScope: string;
	teamFormStep: FormStep;
	modalFooterDivElement: HTMLDivElement | null | undefined;
};
