import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { getContainerWidth } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/common/utils/index.tsx';
import { useZoomLevel } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import { Z_INDEX_LAYER } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/types.tsx';
import { ZIndex } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/view.tsx';
import {
	useColumnWidths,
	useIsResizing,
} from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/index.tsx';
import { TimelineAligned } from '../../body/timeline/aligned';
import DependencyLinesLayer from './layer';
import type { Props } from './types';

const SCROLL_PADDING = 2e6;

export const DependencyLinesContainer = ({ scope, timelineRange }: Props) => {
	const [columnWidths] = useColumnWidths({ preview: false });
	const [isResizing] = useIsResizing();
	const [zoomLevel] = useZoomLevel();
	const width = zoomLevel ? getContainerWidth(zoomLevel) : columnWidths.at(-1) ?? 0;

	const marginLeft = columnWidths.reduce(
		(totalWidth, curWidth, index) =>
			index < columnWidths.length - 1 ? totalWidth + curWidth : totalWidth,
		0,
	);

	if (isResizing) {
		return null;
	}

	return (
		<ZIndex layer={Z_INDEX_LAYER.DEPENDENCY_LINES}>
			{(zIndex) => (
				<Box xcss={containerStyles} style={{ zIndex }}>
					<TimelineAligned offset={SCROLL_PADDING}>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
						<Box xcss={layerAlignedStyles} style={{ width, marginLeft }}>
							<DependencyLinesLayer
								width={width}
								scope={scope}
								startIndex={0}
								stopIndex={Infinity}
								timelineRange={timelineRange}
							/>
						</Box>
					</TimelineAligned>
				</Box>
			)}
		</ZIndex>
	);
};

const containerStyles = xcss({
	position: 'absolute',
	inset: 'space.0',
	pointerEvents: 'none',
});

const layerAlignedStyles = xcss({
	position: 'relative',
	height: '100%',
});
