import { fg } from '@atlassian/jira-feature-gating';
import type { View } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/views/types';
import {
	PRESET_VIEWS_NAMES_V1,
	PRESET_VIEWS_NAMES_V2,
	PRESET_VIEWS_NAMES_V3,
	PRESET_VIEWS_NAMES_V4,
} from './constant';

export const createMigrationMap = (
	currentVersions: Array<Record<string, string>>,
	finalVersion: Record<string, string>,
): Record<string, string> =>
	currentVersions.reduce((migrationMap, currentVersion) => {
		Object.assign(
			migrationMap,
			Object.fromEntries(
				Object.keys(finalVersion)
					.filter((key) => currentVersion[key])
					.map((key) => [currentVersion[key], finalVersion[key]]),
			),
		);

		return migrationMap;
	}, {});

const migrationMapOld = createMigrationMap(
	[PRESET_VIEWS_NAMES_V1, PRESET_VIEWS_NAMES_V2],
	PRESET_VIEWS_NAMES_V3,
);

const migrationMapNew = createMigrationMap(
	[PRESET_VIEWS_NAMES_V1, PRESET_VIEWS_NAMES_V2, PRESET_VIEWS_NAMES_V3],
	PRESET_VIEWS_NAMES_V4,
);

export const getPresetViewNames = () =>
	fg('sprint_capacity_view_default_refactor') ? PRESET_VIEWS_NAMES_V4 : PRESET_VIEWS_NAMES_V3;

/**
 * Map the old default view names to new name with "view" suffix
 */
export const getMigratedPresetViewName = (view: View) => {
	const isUserDefinedOrModifiedPresetView = view.updatedAt;

	if (isUserDefinedOrModifiedPresetView) return view.name;

	const migrationMap = fg('sprint_capacity_view_default_refactor')
		? migrationMapNew
		: migrationMapOld;

	return migrationMap[view.name] || view.name;
};
