import * as R from 'ramda';
import type {
	Resource,
	Person as ActualPerson,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import { isDefined, pickBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import {
	ENTITY,
	SCENARIO_TYPE,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import type { Warnings } from '@atlassian/jira-portfolio-3-portfolio/src/common/warning-details/types';
import type { Team } from '../../state/domain/teams/types';
import type { EntityMetadata } from '../../state/domain/update-jira/changes/types';
import type { State } from '../../state/types';
import { getPersonByItemKey } from '../persons';
import { findTeamAssociatedWithResource, findResourceFromTeam, getTeams } from '../teams';
import type { TeamAndResourceChange } from '../teams/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { OriginalResources } from '../../state/domain/original-resources/types';

type EnhancedResourceValues = Partial<
	Resource & {
		addedMember: string;
		removedMember: string;
		exceptions: boolean;
		weeklyHours: number | null | undefined;
	}
>;

export const getCommitWarningsForResources = ({
	domain: {
		updateJira: { warnings },
	},
}: State) => warnings[ENTITY.RESOURCE];

export const getOriginalResources = (state: State) => state.domain.originalResources;

export const getResourcesChangesMetaData = (state: State): EntityMetadata | undefined =>
	R.path(['domain', 'updateJira', 'changes', 'data', 'metaData', 'resources'], state) || {};

const getValuesForNewResource = (values: Partial<EnhancedResourceValues>) =>
	pickBy(isDefined, values);

export const getResources = (teams: Team[]) =>
	R.reduce<Team, Resource[]>((resources, team) => R.concat(team.resources, resources), [], teams);

export const getAllResources = createSelector([getTeams], getResources);

export function handleExceptionChange(values: EnhancedResourceValues) {
	const copiedValues = values;
	if (copiedValues.exceptions) {
		copiedValues.exceptions = true;
	}
	return copiedValues;
}

export const getResourceChange = (
	resource: Resource,
	resourcesChangeMeta: EntityMetadata,
	team: Team,
	persons: ActualPerson[],
	warnings: Warnings,
): TeamAndResourceChange => {
	let originals: EnhancedResourceValues = resource.originals;
	let values: EnhancedResourceValues = resource.values;
	const resourceId = resource.itemKey;
	const scenarioType = resource.scenarioType;
	const resourceMeta = resourcesChangeMeta[resourceId];

	const person = getPersonByItemKey(persons, resource.personItemKey);
	const personTitle = (person && person.values.title) || '';

	const change: TeamAndResourceChange = {
		id: resourceId,
		changeCount: 0,
		category: ENTITY.RESOURCE,
		type: SCENARIO_TYPE.UPDATED, // Resource is grouped under a team and considered as an update for the team
		metaData: resourceMeta,
		warnings: warnings[resourceId] || [],
		details: {
			changes: [],
			title: {
				name: team.title,
				avatarUrl: team.avatarUrl,
			},
		},
	};

	if (scenarioType === SCENARIO_TYPE.ADDED || scenarioType === SCENARIO_TYPE.DELETED) {
		const attributeName = scenarioType === SCENARIO_TYPE.ADDED ? 'addedMember' : 'removedMember';
		const attributeValue = scenarioType === SCENARIO_TYPE.ADDED ? personTitle : undefined; // new value
		const attributeOriginal = scenarioType === SCENARIO_TYPE.ADDED ? undefined : personTitle; // old value

		values = {
			[attributeName]: attributeValue,
			...values,
		};
		originals = {
			[attributeName]: attributeOriginal,
			...originals,
		};
	}

	values = handleExceptionChange(values);
	let changedAttributes = [];

	if (scenarioType === SCENARIO_TYPE.ADDED) {
		originals = {};
		values = getValuesForNewResource(values);
		changedAttributes = Object.keys(values);
	} else {
		originals = handleExceptionChange(originals);
		changedAttributes = Object.keys(originals);
	}

	change.details.changes = changedAttributes.map((attributeName) => ({
		category: ENTITY.RESOURCE,
		attributeName,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		originalValue: originals[attributeName as keyof typeof originals],
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		currentValue: values[attributeName as keyof typeof values],
		resourceId,
		personTitle,
		metaData: resourceMeta,
	}));

	return change;
};

export const getAllChangesForResource = (
	teams: Team[],
	resourceChangeMeta: EntityMetadata,
	persons: ActualPerson[],
	warnings: Warnings,
): {
	[key: string]: TeamAndResourceChange[];
} => {
	const changes: {
		[key: string]: TeamAndResourceChange[];
	} = {};

	Object.entries(resourceChangeMeta).forEach(([resourceKey]) => {
		const team: Team | null | undefined = findTeamAssociatedWithResource(teams, resourceKey);
		if (!team) {
			return;
		}

		const resource = findResourceFromTeam(team, resourceKey);
		if (!resource) {
			return false;
		}

		const resourceChange = getResourceChange(resource, resourceChangeMeta, team, persons, warnings);

		const resourceChangesForTeam = changes[team.id];
		if (resourceChangesForTeam) {
			resourceChangesForTeam.push(resourceChange);
		} else {
			changes[team.id] = [resourceChange];
		}
	});

	return changes;
};
