import { Component } from 'react';
import ReactDOM from 'react-dom';
import type { Props } from './types';

// This is a higher order component that allows reacting to hover changes of it's children.
// eslint-disable-next-line jira/react/no-class-components
export default class HoverObserver extends Component<Props> {
	// eslint-disable-next-line react/sort-comp
	node: Element | null | undefined = null;

	componentDidMount() {
		this.node = this.getDomNode();
		this.node.addEventListener('mouseenter', this.onMouseEnter);
		this.node.addEventListener('mouseleave', this.onMouseLeave);
	}

	componentWillUnmount() {
		if (this.node) {
			this.node.removeEventListener('mouseenter', this.onMouseEnter);
			this.node.removeEventListener('mouseleave', this.onMouseLeave);
		}
	}

	getDomNode(): Element {
		// findDOMNode is discouraged but is sometimes the best way to implement behavior-based
		// higher order components. See https://github.com/yannickcr/eslint-plugin-react/issues/678#issuecomment-238090846
		// In this case we can't just use a ref from the child, because the child may not be an HTML element, and thus the
		// ref won't point to a DOM node.
		// eslint-disable-next-line react/no-find-dom-node
		const node = ReactDOM.findDOMNode(this);
		if (!(node instanceof Element)) {
			throw new Error('Only plain DOM elements could be observed');
		}
		return node;
	}

	onMouseEnter = () => {
		this.props.onHoverChanged(true);
	};

	onMouseLeave = () => {
		this.props.onHoverChanged(false);
	};

	render() {
		return this.props.children;
	}
}
