/**
 * This module provides various utilities for handling HTTP requests in commands.
 */

import type { Effect } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { tryParseJson } from '@atlassian/jira-portfolio-3-common/src/json/index.tsx';
import fetch, {
	AgressiveFetchError,
	type Options,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch';
import { parseError } from '../api';
import { genericError } from '../errors';

export type JsonResponse<T> =
	| {
			ok: true;
			data: T;
	  }
	| {
			ok: false;
			status: number;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			error: Record<any, any> | string;
	  };

/**
 *  Make a request and return the response parsed as JSON when successful.
 *  Return status and body (parsed as JSON if possible, verbatim otherwise) when failed.
 */
export function* json<T>(
	request: {
		url: string;
	} & Options, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, JsonResponse<T>, any> {
	const { url, method, body, ...options } = request;

	try {
		const response = yield call(fetch, url, {
			method,
			body,
			...options,
		});

		if (response.ok) {
			return { ok: true, data: yield call(response.json.bind(response)) };
		}

		const message = yield call(response.text.bind(response));

		return { ok: false, status: response.status, error: tryParseJson(message) || message };
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		return { ok: false, status: -1, error: e.message };
	}
}

export function* jsonOrError<T>(
	request: {
		url: string;
		method: string;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		body: any;
		profile?: string;
	}, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, JsonResponse<T>, any> {
	const { url, method, body, profile } = request;

	try {
		const response = yield call(fetch, url, {
			method,
			body,
			profile,
		});

		if (response.ok) {
			return { ok: true, data: yield call(response.json.bind(response)) };
		}

		const parsedError = parseError(response, yield call(response.text.bind(response)));
		yield put(
			genericError({
				...parsedError,
				requestInfo: {
					url,
					type: 'POST',
					status: response.status,
					body,
				},
			}),
		);
		return { ok: false, status: response.status, error: parsedError };
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		yield put(genericError({ message: e.message, stackTrace: e.stack }));
		return { ok: false, status: -1, error: e.message };
	}
}

export function* withErrorTolerated(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	block: () => Generator<Effect, any, any>, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	try {
		return yield call(block);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		if (err instanceof AgressiveFetchError) {
			const { request, response } = err;
			const requestInfo = {
				url: request.url,
				type: request.method,
				status: response.status,
				body: request.body ?? undefined,
			};
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const errPayload = (err: Error) => ({ message: err.message, stackTrace: err.stack });

			const { message, stackTrace } = response.bodyUsed
				? errPayload(err)
				: parseError(response, yield call(response.text.bind(response)));

			yield put(
				genericError({
					message,
					stackTrace,
					requestInfo,
				}),
			);
		} else {
			throw err;
		}
	}
}
