import {
	VIEW_MODES,
	type ViewMode,
} from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type {
	ColumnConfig,
	RowConfig,
} from '@atlassian/jira-portfolio-3-treegrid/src/common/types.tsx';
import type { CustomField } from '../../state/domain/custom-fields/types';
import {
	type FieldColumnsState,
	isColumnId,
} from '../../state/domain/view-settings/field-columns/types';
import { getSupportedCustomFields } from '../custom-fields';
import { isAtlasConnectInstalled } from '../system';
import { type TableItem, getTableItems, getScrollToIndex, TABLE_GROUP } from '../table';
import { getFieldColumnsViewSettingsByViewMode, getViewMode } from '../view-settings';
import {
	HEADER,
	SCOPE,
	SUBHEADER,
	TABLE_ITEM,
	ADD_FIELDS,
	FIELD,
	TIMELINE,
	EMPTY,
} from './constants';
import type { GridRow, GridColumn } from './types';
import { getGridRowId } from './utils';

export const getTableItemTagsPure = (tableItems: TableItem[]) => tableItems.map(({ tag }) => tag);
/**
 * Returns an array of tags for the table items.
 *
 * This unlocks the performance onf the getGridRows() (re-calculated on the changes on this string[] only)
 */
export const getTableItemTags = createSelector([getTableItems], getTableItemTagsPure);

export const getGridRowIdsPure = (tableItems: TableItem[]) => [
	HEADER,
	SUBHEADER,
	...tableItems.map(getGridRowId),
];

/** Returns an array of unique for the table items. */
export const getGridRowIds = createSelector([getTableItems], getGridRowIdsPure);

export const getGridRowsPure = (tags: TableItem['tag'][]): GridRow[] => [
	{ tag: HEADER },
	{ tag: SUBHEADER },
	...tags.map((itemTag, index) => ({
		tag: TABLE_ITEM,
		payload: {
			itemIndex: index,
			itemTag,
		},
	})),
];

export const getGridRows = createSelector([getTableItemTags], getGridRowsPure);

export const getGridColumnsPure = (
	viewMode: ViewMode,
	{ columns: columnStates }: FieldColumnsState,
	customFields: CustomField[],
	atlasConnectInstalled: boolean,
): GridColumn[] => {
	// Custom field columns could be added and then removed from a plan, so we filter the available columns
	const columns = columnStates
		.filter((column) => {
			if (column.id === 'goal' && !atlasConnectInstalled) {
				return false;
			}

			return (
				(isColumnId(column.id) || customFields.some((field) => `${field.id}` === column.id)) &&
				column.isVisible
			);
		})
		.map((column) => ({ tag: FIELD, payload: column }));

	return [
		{ tag: SCOPE },
		...columns,
		...(viewMode === VIEW_MODES.LIST ? [{ tag: ADD_FIELDS }] : []),
		...(viewMode === VIEW_MODES.TIMELINE && columns.length === 0 ? [{ tag: EMPTY }] : []),
		...(viewMode === VIEW_MODES.TIMELINE ? [{ tag: TIMELINE }] : []),
	];
};

export const getGridColumns = createSelector(
	[
		getViewMode,
		getFieldColumnsViewSettingsByViewMode,
		getSupportedCustomFields,
		isAtlasConnectInstalled,
	],
	getGridColumnsPure,
);

export const getRowHeightsPure = (tableItems: TableItem[]) =>
	[50 /* header */, 40 /* sub-header */].concat(
		tableItems.map(
			({
				height /* JPO-28647 - This could be an integer larger than 1 when it's a row with overlapping sprints */,
			}) => (height ?? 1) * 40,
		),
	);

export const getRowHeights = createSelector([getTableItems], getRowHeightsPure);

/**
 * Returns the stickiness of rows
 *
 * This allows better performance the the usage at getColumnConfigs()
 */
export const getRowStickinessPure = (gridRows: GridRow[]) =>
	gridRows.map(
		(row) => row.tag === HEADER || row.tag === SUBHEADER || row.payload.itemTag === TABLE_GROUP,
	);
export const getRowStickiness = createSelector([getGridRows], getRowStickinessPure);

export const getRowConfigsPure = (rowsStickiness: boolean[], rowHeights: number[]) => {
	let currentTop = 0;

	return rowsStickiness.map((sticky, index): RowConfig => {
		const height = rowHeights[index];
		const top = currentTop;
		currentTop += height;

		return {
			height,
			top,
			thead: [0, 1].includes(index),
			sticky,
		};
	});
};

export const getRowConfigs = createSelector([getRowStickiness, getRowHeights], getRowConfigsPure);

export const getColumnConfigsPure = (viewMode: ViewMode, gridColumns: GridColumn[]) =>
	gridColumns.map((column): ColumnConfig => {
		if (column.tag === SCOPE) {
			return { id: 'scope', sticky: true };
		}

		if (column.tag === TIMELINE) {
			return { id: 'timeline', sticky: false };
		}

		if (column.tag === EMPTY) {
			return { id: 'empty', sticky: true };
		}

		if (column.tag === ADD_FIELDS && viewMode === VIEW_MODES.TIMELINE) {
			return { id: 'addFields', sticky: true };
		}

		if (column.tag === ADD_FIELDS) {
			return { id: 'addFields', sticky: false };
		}

		return { id: column.payload.id, sticky: viewMode === VIEW_MODES.TIMELINE };
	});

export const getColumnConfigs = createSelector([getViewMode, getGridColumns], getColumnConfigsPure);

export const getScrollIntoViewIndexPure = (index: number | undefined) =>
	index === undefined ? undefined : index + 2; /* header and sub-header */

export const getScrollIntoViewIndex = createSelector(
	[getScrollToIndex],
	getScrollIntoViewIndexPure,
);
