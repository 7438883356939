import React, { type PropsWithChildren, useState, Fragment } from 'react';
import { DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { MenuGroup } from '@atlaskit/menu';
import { Box, Inline } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import Spinner from '@atlassian/jira-portfolio-3-common/src/spinner/index.tsx';
import { UNASSIGNED_GROUP } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope';
import { SearchField } from '@atlassian/jira-searchfield';
import { OptionItem } from '../option-item';
import { type Option, type OptionGroup, UNGROUPED } from '../options-providers/types';
import { ScrollContainer, useScrollLock } from '../scroll-lock';
import messages from './messages';
import type { Props } from './types';

const ContentWrapper = ({ children }: PropsWithChildren) => (
	<Box padding="space.200">
		<Inline alignBlock="center" alignInline="center" grow="fill">
			{children}
		</Inline>
	</Box>
);

const filterGroupOptionsWithSearchQuery = <Value,>(option: Option<Value>, searchQuery: string) => {
	const optionName = option.name ?? UNASSIGNED_GROUP;
	return optionName.toLowerCase().includes(searchQuery.toLowerCase());
};

const getOptionsToDisplay = <Value,>(options: Option<Value>[], searchQuery: string) =>
	options.filter((option) => filterGroupOptionsWithSearchQuery(option, searchQuery));

const getGroupsToDisplay = <Value,>(
	optionGroups: OptionGroup<Value>[],
	searchQuery: string,
): OptionGroup<Value>[] =>
	optionGroups
		.map(({ id, name, options }) => ({
			id,
			name,
			options: getOptionsToDisplay(options, searchQuery),
		}))
		.filter(({ options }) => options.length > 0);

const MenuContent = <Value,>({
	renderOption,
	testId,
	options,
	menuTitle,
	searchPlaceholder,
	isLoading,
	onQueryChange,
}: Props<Value>) => {
	const { formatMessage } = useIntl();

	const [searchQuery, setSearchQuery] = useState('');

	useScrollLock(true);

	const handleQueryChange = (newSearchQuery: string) => {
		setSearchQuery(newSearchQuery);
		onQueryChange?.(newSearchQuery);
	};

	const renderOptions = () => {
		if (options.type === UNGROUPED) {
			const groupOptionsToDisplay = getOptionsToDisplay(options.options, searchQuery);

			if (groupOptionsToDisplay.length === 0)
				return <ContentWrapper>{formatMessage(messages.noMatchFoundText)}</ContentWrapper>;

			return groupOptionsToDisplay.map((option) => (
				<Fragment key={option.id}>
					{renderOption(option, ({ triggerRef, ...triggerProps }) => (
						<OptionItem
							option={option}
							ref={triggerRef}
							{...triggerProps}
							testId={`${testId}.option-item.${option.id}`}
						/>
					))}
				</Fragment>
			));
		}

		const groupsToDisplay = getGroupsToDisplay(options.groups, searchQuery);

		if (groupsToDisplay.length === 0)
			return <ContentWrapper>{formatMessage(messages.noMatchFoundText)}</ContentWrapper>;

		return groupsToDisplay.map(({ id, name, options: groupOptions }, index) => (
			<DropdownItemGroup key={id} id={id} title={name} hasSeparator={index > 0}>
				{groupOptions.map((option) => (
					<Fragment key={option.id}>
						{renderOption(option, ({ triggerRef, ...triggerProps }) => (
							<OptionItem
								option={option}
								ref={triggerRef}
								{...triggerProps}
								testId={`${testId}.option-item.${option.id}`}
							/>
						))}
					</Fragment>
				))}
			</DropdownItemGroup>
		));
	};

	return (
		<MenuGroup maxHeight={600} minWidth={250} testId={testId}>
			<DropdownItemGroup title={menuTitle}>
				<Box paddingBlock="space.100" paddingInline="space.200">
					<SearchField
						value={searchQuery}
						onChange={handleQueryChange}
						placeholder={searchPlaceholder}
						searchIconVisible={false}
						placeholderAlwaysVisible
						shouldFitContainerWidth
						isAutocompleteDisabled
						isAlwaysExpanded
						autoFocus
						describedById={undefined}
						testId={`${testId}.search-field`}
					/>
				</Box>
			</DropdownItemGroup>
			<ScrollContainer>
				{(ref) => (
					<DropdownItemGroup isScrollable hasSeparator ref={ref}>
						{isLoading ? (
							<ContentWrapper>
								<Spinner size="medium" />
							</ContentWrapper>
						) : (
							renderOptions()
						)}
					</DropdownItemGroup>
				)}
			</ScrollContainer>
		</MenuGroup>
	);
};

export default MenuContent;
