import React from 'react';
import * as R from 'ramda';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import DropdownMenu from '@atlassian/jira-portfolio-3-common/src/dropdown-menu/index.tsx';
import RangeSelector from '@atlassian/jira-portfolio-3-common/src/range-selector/index.tsx';
import type {
	RangeSelection,
	OwnProps as RangeSelectorProps,
} from '@atlassian/jira-portfolio-3-common/src/range-selector/types';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import HierarchyLink from './hierarchy-link/view';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

const comparator = R.comparator(R.gt);

function HierarchyFilter({ end, hierarchy, id, onHierarchyChange, start }: Props) {
	const { formatMessage } = useIntl();
	const isAdmin = useIsAdmin();
	const handleOnChange = (selection: RangeSelection) => {
		if (selection.start && selection.end) {
			onHierarchyChange({
				start: selection.start.value,
				end: selection.end.value,
			});
		}
	};

	const hierarchyLevelRangeItems = hierarchy.map(({ level, title }) => ({
		content: title,
		id: level,
		value: level,
	}));

	let rangeSelectorProps: RangeSelectorProps = {
		groups: [{ id, items: hierarchyLevelRangeItems }],
		comparator,
		onChange: handleOnChange,
	};

	const startItem = R.find(R.propEq('value', start), hierarchyLevelRangeItems);
	const endItem = R.find(R.propEq('value', end), hierarchyLevelRangeItems);

	if (startItem) {
		rangeSelectorProps = {
			...rangeSelectorProps,
			start: {
				...startItem,
				...{
					label: formatMessage(messages.topHierarchyLevelLabel),
					name: 'topHierarchyLevel',
					showTooltip: true,
				},
			},
		};
	}

	if (endItem) {
		rangeSelectorProps = {
			...rangeSelectorProps,
			end: {
				...endItem,
				...{
					label: formatMessage(messages.bottomHierarchyLevelLabel),
					name: 'bottomHierarchyLevel',
					showTooltip: true,
				},
			},
		};
	}

	const shouldDisplayHierarchyLink = fg('eou_plans_filters_hierachy_link') && isAdmin;

	return (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
			className={
				fg('eou_plans_filters_hierachy_link')
					? styles.hierarchyFilter
					: styles.hierarchyFilterLegacy
			}
		>
			<RangeSelector {...rangeSelectorProps}>
				<DropdownMenu
					footerContent={shouldDisplayHierarchyLink ? <HierarchyLink /> : undefined}
					testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.hierarchy-filter.from"
					ariaLabel={formatMessage(messages.hierarchyFrom)}
				/>

				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.separator} />

				<DropdownMenu
					footerContent={shouldDisplayHierarchyLink ? <HierarchyLink /> : undefined}
					testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.hierarchy-filter.to"
					ariaLabel={formatMessage(messages.hierarchyTo)}
				/>
			</RangeSelector>
		</div>
	);
}

export default HierarchyFilter;
