import { getDaysInMonth, getDaysInYear, startOfQuarter, endOfQuarter } from 'date-fns';
import { ONE_DAY } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import type { ZoomLevel } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/common/types.tsx';
import { ZOOM_LEVELS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';

/** Function returns number of days in the quarter which 'date' falls in */
export const getDaysInQuarter = (date: Date): number => {
	const startDate = startOfQuarter(date);
	const endDate = endOfQuarter(date);

	// Doing round to account for daylight savings as tart date could be in +1000 but end date could be in +1100
	return Math.round((endDate.getTime() + 1 - startDate.getTime()) / ONE_DAY);
};

/** Function returns length of preview bar in days */
export const getDaysInTimelineView = (date: Date, zoomLevel?: ZoomLevel) => {
	const normalizedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

	switch (zoomLevel) {
		case ZOOM_LEVELS.WEEKS:
			return 7;
		case ZOOM_LEVELS.MONTHS:
			return getDaysInMonth(normalizedDate);
		case ZOOM_LEVELS.QUARTERS:
			return getDaysInQuarter(normalizedDate);
		case ZOOM_LEVELS.YEARS:
			return getDaysInYear(normalizedDate);
		default:
			return 30;
	}
};
