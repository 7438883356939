import { getTimeScaleViewSettings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { StateProps } from './types';

const mapStateToProps: MapStateToProps<StateProps> = (state: State) => ({
	timelineSettings: getTimeScaleViewSettings(state),
});

export default mapStateToProps;
