import React, { useCallback, useRef, useEffect, useMemo } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import DragObserver from '@atlassian/jira-portfolio-3-common/src/drag-observer/index.tsx';
import { subscribeHorizontalScrolling } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import issueBarDragEmitter from '@atlassian/jira-portfolio-3-portfolio/src/common/issue-bar-drag-events';
import { useDndScrolling } from '../../dnd-scrolling/utils.tsx';
import type { Props, Position, DragHandler } from './types';

/** Function computes the delta between drag scroll start and current timeline location.

 * @returns {Array} Returns the drag scrolling delta in px and functions to register and deregister a callback function and reset the calculations
 */
const useScrollingDelta = () => {
	const offsetInPxRef = useRef<number>(0);
	const startOffsetInPxRef = useRef<number>(0);
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const callbackRef = useRef(() => {});
	const deltaRef = useRef<number>(0);

	useEffect(() => {
		const unsubscribe = subscribeHorizontalScrolling(({ viewport, container }) => {
			const offsetInPx = (viewport.offset * container.width) / container.duration || 0;
			offsetInPxRef.current = offsetInPx;
			deltaRef.current = offsetInPxRef.current - startOffsetInPxRef.current;
			callbackRef.current();
		});

		return unsubscribe;
	}, [deltaRef]);

	/** Function used to start and end calculations for deltaRef */
	const dragReset = useCallback(() => {
		startOffsetInPxRef.current = offsetInPxRef.current;
		deltaRef.current = 0;
	}, []);

	/** Register callback to be used when delta changes */
	const register = useCallback((callback: () => void) => {
		callbackRef.current = callback;
	}, []);

	/** Deregister callback */
	const deregister = useCallback(() => {
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		callbackRef.current = () => {};
	}, []);

	return [deltaRef, { dragReset, register, deregister }] as const;
};

type DragPayload = {
	from: Position;
	to: Position;
	start: Position;
};

export default function DragScrollingObserver({ dragHandler, flag, children }: Props) {
	const [, { register: registerDndScrolling, deregister: deregisterDndScrolling }] =
		useDndScrolling();
	const dragPayloadRef = useRef<DragPayload | null>(null);
	const dragHandlerRef = useRef<DragHandler>(dragHandler);

	useEffect(() => {
		dragHandlerRef.current = dragHandler;
	}, [dragHandler]);

	const [
		deltaRef,
		{ dragReset, register: registerDragScrolling, deregister: deregisterDragScrolling },
	] = useScrollingDelta();

	const drag = useCallback(() => {
		const { onDrag } = dragHandlerRef.current;
		const dragPayload = dragPayloadRef.current;

		if (onDrag == null || dragPayload == null) {
			return;
		}

		onDrag(
			dragPayload.from,
			{ ...dragPayload.to, x: dragPayload.to.x + deltaRef.current },
			dragPayload.start,
		);
	}, [deltaRef]);

	const handleDragStart = useCallback(
		(start: Position) => {
			const { onDragStart } = dragHandlerRef.current;

			if (ff('com.atlassian.rm.jpo.transposition')) {
				issueBarDragEmitter.emit('dragstart', { pageX: start.x, pageY: start.y });
			}

			if (onDragStart == null) {
				return;
			}

			dragReset();
			registerDragScrolling(drag);
			registerDndScrolling(flag);
			onDragStart(start);
		},
		[dragReset, registerDragScrolling, drag, registerDndScrolling, flag],
	);

	const handleDrag = useCallback(
		(from: Position, to: Position, start: Position) => {
			const { onDrag } = dragHandlerRef.current;

			if (ff('com.atlassian.rm.jpo.transposition')) {
				issueBarDragEmitter.emit('drag', { pageX: to.x, pageY: to.y });
			}

			if (onDrag == null) {
				return;
			}

			dragPayloadRef.current = { from, to, start };
			drag();
		},
		[drag],
	);

	const handleDragEnd = useCallback(
		(start: Position, end: Position) => {
			const { onDragEnd } = dragHandlerRef.current;

			if (ff('com.atlassian.rm.jpo.transposition')) {
				issueBarDragEmitter.emit('dragend', { pageX: end.x, pageY: end.y });
			}

			if (onDragEnd == null) {
				return;
			}

			dragPayloadRef.current = null;
			deregisterDragScrolling();
			deregisterDndScrolling(flag);
			onDragEnd(start, end);
		},
		[deregisterDndScrolling, deregisterDragScrolling, flag],
	);

	const { onClick: handleClick, onMouseDown: handleMouseDown } = dragHandler;

	const newHandler = useMemo(
		() => ({
			onClick: handleClick,
			onMouseDown: handleMouseDown,
			onDragStart: handleDragStart,
			onDrag: handleDrag,
			onDragEnd: handleDragEnd,
		}),
		[handleClick, handleDrag, handleDragEnd, handleDragStart, handleMouseDown],
	);

	return <DragObserver dragHandler={newHandler}>{children}</DragObserver>;
}
