import type { IssueType } from './types';

export const RESET = 'state.domain.issue-types.RESET' as const;
export const ADD = 'state.domain.issue-types.ADD' as const;

export type ResetActionPayload = IssueType[];

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export type AddAction = {
	type: typeof ADD;
	payload: ResetActionPayload;
};

export const reset = (payload: ResetActionPayload) => ({
	type: RESET,
	payload,
});

export const add = (payload: ResetActionPayload) => ({
	type: ADD,
	payload,
});
