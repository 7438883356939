import React from 'react';
import { useZoomLevel } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import InfiniteAxisUnitsBar from './infinite-axis/view.tsx';
import StaticAxisUnitsBar from './static-axis';

export default function TimeUnitsBar({ fiscalMonth }: { fiscalMonth: number }) {
	const [zoomLevel] = useZoomLevel();
	if (zoomLevel) {
		return <InfiniteAxisUnitsBar fiscalMonth={fiscalMonth} />;
	}

	return <StaticAxisUnitsBar />;
}
