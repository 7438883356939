import type { GroupId } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types';
import { type Grouping, CustomFieldTypes } from '../constant';

export const isRoadmapGroupedByCustomField = (grouping: Grouping): grouping is GroupId =>
	/^customfield_/.test(grouping);

export const getCustomFieldIdFromCustomFieldGrouping = (grouping: Grouping) =>
	grouping.substring('customfield_'.length);

export const isMultiSelectCustomField = (fieldType: string) =>
	fieldType === CustomFieldTypes.MultiSelect;

export const isLabelCustomField = (fieldType: string) => fieldType === CustomFieldTypes.Labels;
