import React, { useEffect, useCallback, type FocusEvent, type ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import { Field } from '@atlaskit/form';
import AkTextField from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { injectIntl, type IntlShape } from '@atlassian/jira-intl';
import { DateUnits } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/constants.tsx';
import { getRelativeDates } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import { AkSelect as Select } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import type { OptionType, ValueType } from '@atlassian/jira-portfolio-3-common/src/select/types';
import messages from './messages';
import type { Props } from './types';
import { getStringFromDates } from './utils';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { getDateString, getStringFromDates } from './utils';

const ToOrFrom = {
	TO: 'to',
	FROM: 'from',
} as const;

type ToOrFrom = (typeof ToOrFrom)[keyof typeof ToOrFrom];

const getSelectOptions = (count: number, intl: IntlShape): OptionType[] => [
	{
		value: DateUnits.WEEKS,
		label: intl.formatMessage(messages.weeks, { count }),
	},
	{ value: DateUnits.MONTHS, label: intl.formatMessage(messages.months, { count }) },
];

const RelativeDateRange = ({
	fromNowUnit = DateUnits.WEEKS,
	fromNowUnitCount = 0,
	intl,
	onRelativeValuesChange,
	showTodayIndicator = true,
	toNowUnit = DateUnits.WEEKS,
	toNowUnitCount = 0,
}: Props) => {
	/**
    We make the max value relative to the chosen unit (10 years)
    We also ensure that the user cannot type a negative
    This function acts as validation so we don't need `max`
    or `isInvalid` on our field components any more
    */
	const validateCount = useCallback(
		(toOrFrom: ToOrFrom, value: string | number): number => {
			const maxValuesMap = {
				[DateUnits.DAYS]: 10 * 365,
				[DateUnits.WEEKS]: 10 * 52,
				[DateUnits.MONTHS]: 10 * 12,
			};
			const index = toOrFrom === ToOrFrom.TO ? toNowUnit : fromNowUnit;
			const max = maxValuesMap[index];
			return Math.min(max, Math.abs(Number(value)));
		},
		[toNowUnit, fromNowUnit],
	);

	const onChangeCount = useCallback(
		({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
			const toOrFrom = name === 'toNowUnitCount' ? ToOrFrom.TO : ToOrFrom.FROM;
			const val = validateCount(toOrFrom, value);
			onRelativeValuesChange(Number(val), name);
		},
		[onRelativeValuesChange, validateCount],
	);

	const onBlurCount = useCallback(
		(e: FocusEvent<HTMLInputElement>) => {
			const { name, value: valueStr } = e.currentTarget;
			const val = Math.round(parseFloat(valueStr));
			onRelativeValuesChange(val, name);
		},
		[onRelativeValuesChange],
	);

	const onChangeUnit = useCallback(
		(value: ValueType, name: string) => {
			if (value && !Array.isArray(value)) {
				onRelativeValuesChange(value.value, name);
			}
		},
		[onRelativeValuesChange],
	);

	const getSelectValue = useCallback(
		(currentValue: string, count: number) =>
			getSelectOptions(count, intl).find(({ value }) => value === currentValue),
		[intl],
	);

	const relativeRange = useCallback(() => {
		const { start, end } = getRelativeDates(
			{ toNowUnitCount, fromNowUnitCount, toNowUnit, fromNowUnit },
			new Date(),
		);
		return getStringFromDates(start, end, intl);
	}, [fromNowUnit, fromNowUnitCount, intl, toNowUnit, toNowUnitCount]);

	// Revalidate the unit counts when units (weeks/months) change
	useEffect(() => {
		const toVal = validateCount(ToOrFrom.TO, toNowUnitCount);
		if (toVal !== toNowUnitCount) {
			onRelativeValuesChange(toVal, 'toNowUnitCount');
		}
		const fromVal = validateCount(ToOrFrom.FROM, fromNowUnitCount);
		if (fromVal !== fromNowUnitCount) {
			onRelativeValuesChange(fromVal, 'fromNowUnitCount');
		}
	}, [
		toNowUnit,
		fromNowUnit,
		toNowUnitCount,
		fromNowUnitCount,
		onRelativeValuesChange,
		validateCount,
	]);

	return (
		<RelativeDateRangeContainer>
			<ColumnsContainer showTodayIndicator={showTodayIndicator}>
				<div data-testid="portfolio-3-custom-date-range.ui.modal-content.relative-time-range.to-now">
					<Column>
						<Field label={intl.formatMessage(messages.toNowHeader)} name="toNowUnitCount">
							{({ fieldProps }) => (
								<Column>
									<AkTextField
										{...fieldProps}
										min={0}
										name="toNowUnitCount"
										onBlur={onBlurCount}
										onChange={onChangeCount}
										type="number"
										value={toNowUnitCount.toString()}
										width={60}
									/>
									<SelectWrapper>
										<Select
											{...fieldProps}
											isTransparentBackground={false}
											isClearable={false}
											options={getSelectOptions(toNowUnitCount, intl)}
											value={getSelectValue(toNowUnit, toNowUnitCount)}
											onChange={(option) => onChangeUnit(option, 'toNowUnit')}
											classNamePrefix="relative-time-range-to-now-select"
										/>
									</SelectWrapper>
								</Column>
							)}
						</Field>
					</Column>
				</div>
				{showTodayIndicator && (
					<NowBarColumn>
						<Field label={intl.formatMessage(messages.todayLabel)} name="todayLabel">
							{() => (
								<NowBar>
									<NowLine topOffset={40} />
								</NowBar>
							)}
						</Field>
					</NowBarColumn>
				)}
				<div data-testid="portfolio-3-custom-date-range.ui.modal-content.relative-time-range.from-now">
					<Column>
						<Field label={intl.formatMessage(messages.fromNowHeader)} name="fromNowUnitCount">
							{({ fieldProps }) => (
								<Column>
									<AkTextField
										{...fieldProps}
										min={0}
										name="fromNowUnitCount"
										onBlur={onBlurCount}
										onChange={onChangeCount}
										type="number"
										value={fromNowUnitCount.toString()}
										width={60}
									/>
									<SelectWrapper>
										<Select
											{...fieldProps}
											classNamePrefix="relative-time-range-from-now-select"
											isClearable={false}
											isTransparentBackground={false}
											onChange={(option) => onChangeUnit(option, 'fromNowUnit')}
											options={getSelectOptions(fromNowUnitCount, intl)}
											value={getSelectValue(fromNowUnit, fromNowUnitCount)}
										/>
									</SelectWrapper>
								</Column>
							)}
						</Field>
					</Column>
				</div>
			</ColumnsContainer>
			<div data-testid="portfolio-3-custom-date-range.ui.modal-content.relative-time-range.selected-time-range">
				{intl.formatMessage(messages.timeRangeText)}
				<TimeRange>{relativeRange()}</TimeRange>
			</div>
		</RelativeDateRangeContainer>
	);
};

export default injectIntl(RelativeDateRange);

const MARKER_LINE_WIDTH = 2;
const POINTER_WIDTH = 8;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RelativeDateRangeContainer = styled.div({
	width: '450px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Column = styled.div({
	display: 'flex',
	flexDirection: 'row',
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TimeRange = styled.span({
	fontWeight: 'bold',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NowBar = styled.div({
	height: '70px',
	left: '50%',
	marginTop: token('space.negative.400', '-32px'),
	position: 'relative',
});

type NowLineProps = { noMarker?: boolean; topOffset?: number };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NowLine = styled.div<NowLineProps>({
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	top: `${(props: NowLineProps) => (props.topOffset ? `${props.topOffset}px` : '0')}`,
	bottom: 0,
	zIndex: 4,
	pointerEvents: 'none',
	width: '2px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderLeft: `${MARKER_LINE_WIDTH}px solid ${token('color.background.accent.orange.subtle', colors.Y400)}`,

	'&::before': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: `${(props: NowLineProps) => (props.noMarker ? 'none' : 'block')}`,
		content: '',
		position: 'sticky',
		height: 0,
		width: 0,
		top: 0,
		marginLeft: `-${(MARKER_LINE_WIDTH + POINTER_WIDTH) / 2}px`,
		borderLeft: `${POINTER_WIDTH / 2}px solid transparent`,
		borderRight: `${POINTER_WIDTH / 2}px solid transparent`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderTop: `${POINTER_WIDTH}px solid ${token('color.background.accent.orange.subtle', colors.Y400)}`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SelectWrapper = styled.div({
	minWidth: '120px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColumnsContainer = styled.div<{ showTodayIndicator?: boolean }>({
	display: 'flex',
	paddingBottom: token('space.200', '16px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	gap: `${({ showTodayIndicator }: Props) =>
		showTodayIndicator ? token('space.400', '32px') : token('space.600', '48px')}`,

	'@media (max-width: 600px)': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		gap: `${token('space.150', '12px')} !important`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NowBarColumn = styled.div({
	alignItems: 'center',
	display: 'flex',
	flexDirection: 'column',
	padding: `0 ${token('space.050', '4px')}`,
});
