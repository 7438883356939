import * as R from 'ramda';
import { getCustomFields } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields';
import { getTypeToLevel } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/hierarchy';
import { getIssueTypes } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types';
import { getPlan } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan';
import { getProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects';
import type { ProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/types';
import { getIssueMapById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/index.tsx';
import {
	getSystemInfo,
	getAgileInfo,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/system';
import { getTeamsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams';
import { getFilteredReviewChanges } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import { indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps, InactiveStateProps, ActiveStateProps } from './types';

// Start date is a custom field
const getStartDateCustomField = createSelector([getCustomFields], (customFields) =>
	customFields.find(({ defaultStartDateCustomField }) => defaultStartDateCustomField),
);

const getRequiredFields = (state: State) => state.domain.updateJira.requiredFields;

/**
 * This function looks for the issue project key in the projects props and mixes it up with the issue key.
 *
 * @param issue Issue.
 * @param projectsById Projects grouped by id.
 *
 * @returns Returns the issue project key and the issue key concatenated with a "-"; or null if the issue project id was not found in projectsById.
 */
const getIssueKey = (issueKey: number, project: number, projectsById: ProjectsById) => {
	if (project && projectsById[project]) {
		return `${projectsById[project].key}${issueKey ? `-${issueKey}` : ''}`;
	}

	return null;
};

export default createSelector(
	[
		getRequiredFields,
		getFilteredReviewChanges,
		getIssueTypes,
		getSystemInfo,
		getAgileInfo,
		getIssueMapById,
		getProjectsById,
		getStartDateCustomField,
		getTypeToLevel,
		getPlan,
		getTeamsById,
	],
	(
		requiredFields,
		reviewChanges,
		issueTypes,
		systemInfo,
		agileInfo,
		issuesMappedById,
		projectsById,
		startDateCustomField,
		getIssueTypeToHierarchyLevel,
		planInfo,
		teamsById,
	): StateProps => {
		const { isWaiting } = requiredFields;

		if (!isWaiting) {
			const inactiveProps: InactiveStateProps = {
				isOpen: false,
			};
			return inactiveProps;
		}

		const { issueId, error } = requiredFields;

		const jiraConfig = {
			hoursPerDay: systemInfo.jiraHoursPerDay,
			daysPerWeek: systemInfo.jiraDaysPerWeek,
		};

		const issueTypesById = indexBy(R.prop('id'), issueTypes);
		const issueChange = R.find((change) => change.id === issueId, reviewChanges);
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const issue = issueChange?.details as Issue;

		const fullIssue = issuesMappedById[issueId];
		if (!issue || !fullIssue) {
			throw new Error('Cannot retrieve issue details for required fields dialog');
		}

		const parentIssue = (() => {
			const pIssue = fullIssue.parent != null ? issuesMappedById[fullIssue.parent] : undefined;

			if (pIssue?.type == null || pIssue?.issueKey == null || pIssue?.project == null) {
				return;
			}

			const parentIssueKey = getIssueKey(pIssue.issueKey, pIssue.project, projectsById);

			if (!parentIssueKey) return;

			return {
				key: parentIssueKey,
				hierarchyLevel: getIssueTypeToHierarchyLevel(pIssue.type),
			};
		})();

		const activeStateProps: ActiveStateProps = {
			isOpen: true,
			issue,
			error,
			issueTypesById,
			agileConfig: agileInfo,
			jiraConfig,
			parentIssue,
			startDateCustomField,
			planInfo,
			projectsById,
			teamsById,
		};

		return activeStateProps;
	},
);
