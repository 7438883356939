import type { Dispatch } from 'redux';
import { updateIssueOrInlineCreate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { DispatchProps } from './types';

const dispatchMethods: MapDispatchToPropsSimple<DispatchProps> = (dispatch: Dispatch) => ({
	onChange: (issue: ScopeIssue, components: number[]) => {
		dispatch(
			updateIssueOrInlineCreate({
				id: issue.id,
				components: components.length === 0 ? null : components,
			}),
		);
	},
});

export default dispatchMethods;
