import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { Warning } from '@atlassian/jira-portfolio-3-portfolio/src/common/warning-details/types';
import type { Warnings, WarningIssueHash } from '../../state/domain/warnings/types';
import type { State } from '../../state/types';
import { getWarningViewSettings } from '../view-settings';

export const getWarnings = (state: State): Warnings => state.domain.warnings;
export const getAllWarningsIssueHash = (state: State): WarningIssueHash =>
	state.domain.warnings.allWarningsByIssue ?? {};

export const getIssueIdsWithWarningsPure = (warnings: Warnings): string[] => {
	const issueIds = Object.keys(warnings.byIssue);
	return issueIds.length ? [...issueIds, ...warnings.transitiveIssueIds] : [];
};

export const getWarningsCount = createSelector([getWarnings], (warnings: Warnings): number =>
	Object.values<Warning[]>(warnings.byIssue).reduce<number>((acc, value) => {
		// eslint-disable-next-line no-param-reassign
		acc += value.length;
		return acc;
	}, 0),
);

export const getAggregatedWarningsPure = (warnings: Warnings): Record<string, string[]> => {
	const byType: Record<string, string[]> = {};
	for (const [issueId, value] of Object.entries(warnings.byIssue)) {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const warnings = value;
		for (const warning of warnings) {
			const type = warning.type;
			if (!byType[type]) {
				byType[type] = [];
			}
			byType[type].push(issueId);
		}
	}
	return byType;
};

export const getAggregatedWarnings = createSelector([getWarnings], getAggregatedWarningsPure);

export const getIssueIdsWithWarnings = createSelector([getWarnings], getIssueIdsWithWarningsPure);

export const getDisabledWarnings = createSelector(
	[getWarningViewSettings],
	(warningViewSettings) => warningViewSettings.disabledWarnings,
);
