import {
	getUserPickerOptionsUserList,
	getUserPickerUIState,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { Props, StateProps } from './types';

export default createStructuredSelector<State, Props, StateProps>({
	userList: getUserPickerOptionsUserList,
	userPickerUIState: getUserPickerUIState,
});
