/* Remove all css styles within this section when FF com.atlassian.rm.jpo.jpo3cloud.revamp-warning-centre is removed */
.content {
	padding: calc(var(--jpo-common-base-spacing) * 2);
	width: 560px;
}

.modalContentHeight {
	max-height: calc(100vh - 188px);
}

.warningTable {
	border-spacing: 10px;
}

.warningDescription {
	color: var(--ds-text-subtle, var(--adg3-color-N400));
	font-size: 12px;
}

.topDescription {
	padding: calc(var(--jpo-common-base-spacing) * 2);
	background-color: var(--ds-background-neutral, var(--adg3-color-N20));
}

.tableHeader {
	font-size: 12px;
	font-weight: 600;
	color: var(--ds-text, var(--adg3-color-N700));
	line-height: 16px;
}

.tableBorderReset {
	border-bottom: none;
}

.statusHeader {
	margin-right: 20px;
	margin-left: 20px;
	width: 150px;
}

.warningEnabledHeader {
	width: 50px;
}

.statusButton {
	margin-left: -10px;
}

.toggleWrapper {
	align-items: center;
	display: flex;
	margin-left: var(--ds-space-negative-075, -6px);
}

.warningCenterHeader {
	margin-bottom: 12px;
	font-size: 16px;
	font-weight: 600;
	color: var(--ds-text, var(--adg3-color-N700));
}

.warningCenterIntro {
	margin-bottom: var(--jpo-common-base-spacing);
}

.issueListHeader {
	margin-bottom: 20px;
}

.issueListContainer {
	max-height: 400px;
	overflow-y: auto;
}

.backButton {
	margin-top: 10px;
}

/* END of css removal when com.atlassian.rm.jpo.jpo3cloud.revamp-warning-centre is removed */

.affectedIssuesCount {
	padding-right: 0;
}

.disabledWarningButton {
	padding: 0;
}
