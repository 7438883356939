import { getIssueLinkIfOverlapping } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-links/util';
import type { IssueMap } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues/types';
import { mergeOptimizedValueToIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues/utils';
import type { ExternalIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/external-issues/types';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types';
import type {
	IssueLink,
	IssueStatus,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';

export const isDependencyOverlapping = (
	position: string,
	issue: ScopeIssue,
	internalIssueLinks: IssueLink[],
	issuesWithOptimizedById: IssueMap,
	issueStatusById: {
		[id: string]: IssueStatus;
	},
	isOptimizedMode: boolean,
	syncStartEnabled: boolean,
) => {
	if (position === 'start') {
		return internalIssueLinks.some(({ sourceItemKey }) => {
			const sourceIssue = mergeOptimizedValueToIssue(
				issuesWithOptimizedById[sourceItemKey],
				isOptimizedMode,
			);

			const targetIssue = mergeOptimizedValueToIssue(
				issuesWithOptimizedById[issue.id],
				isOptimizedMode,
			);

			return getIssueLinkIfOverlapping(sourceIssue, targetIssue, issueStatusById, syncStartEnabled);
		});
	}
	return internalIssueLinks.some(({ targetItemKey }) => {
		const sourceIssue = mergeOptimizedValueToIssue(
			issuesWithOptimizedById[issue.id],
			isOptimizedMode,
		);

		const targetIssue = mergeOptimizedValueToIssue(
			issuesWithOptimizedById[targetItemKey],
			isOptimizedMode,
		);

		return getIssueLinkIfOverlapping(sourceIssue, targetIssue, issueStatusById, syncStartEnabled);
	});
};

export const isExternalDependencyOverlapping = (
	position: string,
	issue: ScopeIssue,
	issueStatusById: {
		[id: string]: IssueStatus;
	},
	externalIssueLinks: IssueLink[],
	externalIssues: ExternalIssues,
	issuesWithOptimizedById: IssueMap,
	isOptimizedMode: boolean,
	syncStartEnabled: boolean,
) => {
	if (position === 'start') {
		return externalIssueLinks.some(({ sourceItemKey }) => {
			const targetIssue = mergeOptimizedValueToIssue(
				issuesWithOptimizedById[issue.id],
				isOptimizedMode,
			);
			const sourceIssue = externalIssues[sourceItemKey];
			return getIssueLinkIfOverlapping(sourceIssue, targetIssue, issueStatusById, syncStartEnabled);
		});
	}
	return externalIssueLinks.some(({ targetItemKey }) => {
		const sourceIssue = mergeOptimizedValueToIssue(
			issuesWithOptimizedById[issue.id],
			isOptimizedMode,
		);
		const targetIssue = externalIssues[targetItemKey];
		return getIssueLinkIfOverlapping(sourceIssue, targetIssue, issueStatusById, syncStartEnabled);
	});
};
