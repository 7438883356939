import React from 'react';
import { Box, Flex } from '@atlaskit/primitives';
import type { AssociatedIssue } from '@atlassian/jira-portfolio-3-associated-issues/src/common/types.tsx';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import Cell from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/fields/columns/column/cell/view';
import IdeaIssue from './idea-issue';
import IdeasCellSelect from './ideas-cell-select';
import { calculateFlexShrink, isCellDirty, getAssociatedIssuesUpdate } from './utils';

type Props = {
	associatedIssues: AssociatedIssue[];
	isReadOnly?: boolean;
	isDisabled?: boolean;
	isExportMode?: boolean;
	issue: ScopeIssue;
	onChange: (issue: ScopeIssue, ideas: string[]) => void;
	originalIssues?: string[];
};

const renderMultipleIdeas = (ideas: AssociatedIssue[]) => {
	const names = ideas.map((el) => el.summary);
	const values = calculateFlexShrink(names);

	return (
		<>
			{ideas.map((associatedIssue, index) => (
				<IdeaIssue key={associatedIssue.key} data={associatedIssue} flexShrink={values[index]} />
			))}
		</>
	);
};

export const IdeasCell = ({
	associatedIssues,
	isDisabled,
	isReadOnly,
	isExportMode,
	issue,
	onChange,
	originalIssues,
}: Props) => {
	const handleChangeIdeas = (changedAssociatedIssues: AssociatedIssue[]) => {
		const associatedIssuesUpdate = getAssociatedIssuesUpdate(
			changedAssociatedIssues.map(({ id }) => id),
			issue.associatedIssueIds || [],
			associatedIssues.map(({ id }) => id),
		);
		if (!associatedIssuesUpdate) {
			return;
		}
		onChange(issue, associatedIssuesUpdate);
	};

	const isReadonlyMode = isDisabled || isReadOnly || isExportMode;
	return (
		<Box>
			{isReadonlyMode ? (
				<Flex>{renderMultipleIdeas(associatedIssues)}</Flex>
			) : (
				<Cell
					attribute="ideas"
					issue={issue}
					isScrolling={false}
					isCellDirty={() => isCellDirty(originalIssues, associatedIssues)}
				>
					<IdeasCellSelect
						renderMultipleIdeas={renderMultipleIdeas}
						currentAssociatedIssues={associatedIssues}
						onUpdateIdeas={handleChangeIdeas}
						issue={issue}
					/>
				</Cell>
			)}
		</Box>
	);
};
