import * as R from 'ramda';
import {
	VIEW_MODES,
	type ViewMode,
} from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import { indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { ComponentGroup } from '../../state/domain/view-settings/component-groups/types';
import type { FieldColumnsState } from '../../state/domain/view-settings/field-columns/types';
import type { LabelGroup } from '../../state/domain/view-settings/label-groups/types';
import type { State } from '../../state/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { ALL_OTHER_ISSUES } from '../../state/domain/view-settings/component-groups/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	ComponentGroup,
	ComponentGroupsState as ComponentGroups,
} from '../../state/domain/view-settings/component-groups/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	LabelGroup,
	LabelGroupsState as LabelGroups,
} from '../../state/domain/view-settings/label-groups/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	CustomFieldValuesGroup,
	CustomFieldValuesGroupsState as CustomFieldValuesGroups,
} from '../../state/domain/view-settings/custom-field-values-groups/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { RollupSettingsState as RollupSettings } from '../../state/domain/view-settings/rollup-settings/types';

export const getViewSettings = (state: State) => state.domain.viewSettings;

export const getIsCalendarView = (state: State) =>
	state.domain.viewSettings.activeFilterViewV0 === 'calendar';

// Start of View settings top level slice queries
export const getFiltersViewSettings = (state: State) =>
	getIsCalendarView(state)
		? state.domain.viewSettings.calendarFiltersV1
		: state.domain.viewSettings.filtersV1;

export const getColorByViewSettings = (state: State) => state.domain.viewSettings.colourByV2;

export const getTimeScaleViewSettings = (state: State) => state.domain.viewSettings.timeScaleV1;
export const getIssueExpansionsViewSettings = (state: State) =>
	state.domain.viewSettings.issueExpansionsV0;

export const getFieldColumnsViewSettings = (state: State): FieldColumnsState =>
	state.domain.viewSettings.fieldColumnsV0;

const getListFieldColumnsViewSettings = (state: State): FieldColumnsState =>
	state.domain.viewSettings.listFieldColumnsV0;

export const getFilterOptionsViewSettings = (state: State) =>
	state.domain.viewSettings.filterOptionsV1;

export const getRollupViewSettings = (state: State) => state.domain.viewSettings.rollupSettingsV0;
export const getHighlightedVersionsViewSettings = (state: State) =>
	state.domain.viewSettings.highlightedVersionsV0;

export const getComponentGroupsViewSettings = (state: State) =>
	state.domain.viewSettings.componentGroupsV0;
export const getLabelsGroupViewSettings = (state: State) => state.domain.viewSettings.labelGroupsV0;
export const getCustomFieldValuesGroupsViewSettings = (state: State) =>
	state.domain.viewSettings.customFieldValuesGroupsV0;
export const getWarningViewSettings = (state: State) => state.domain.viewSettings.warningSettingsV1;
export const getDateConstraintViewSettings = (state: State) =>
	state.domain.viewSettings.dateConstraintsV0;

export const getVisualisationViewSettings = (state: State) =>
	state.domain.viewSettings.visualisationsV0;

export const getShowRolledUpDates = (state: State): boolean =>
	getRollupViewSettings(state).showingRolledUpDate;

export const getDependencySettings = (state: State) =>
	state.domain.viewSettings.dependencySettingsV0;

const getViewModeViewSettingsDefault = { mode: VIEW_MODES.TIMELINE };

export const getViewModeViewSettings = (state: State) =>
	state.domain.viewSettings.viewModeV0 || getViewModeViewSettingsDefault;

// End of View settings top level slice queries

export type ComponentGroupsById = Record<string, ComponentGroup>;
export type LabelGroupsById = Record<string, LabelGroup>;

export const getShowRolledUpOthers = createSelector(
	[getRollupViewSettings],
	(rollupViewSettings): boolean => rollupViewSettings.showRolledUpOthers,
);

export const getDependencySettingsDisplay = createSelector(
	[getDependencySettings],
	(dependencySettings) => dependencySettings.display,
);

export const getComponentGroupsById = createSelector(
	[getComponentGroupsViewSettings],
	(componentGroupsViewSettings): ComponentGroupsById =>
		indexBy(R.prop('id'), componentGroupsViewSettings),
);

export const getLabelGroupsById = createSelector(
	[getLabelsGroupViewSettings],
	(labelsGroupViewSettings): LabelGroupsById => indexBy(R.prop('id'), labelsGroupViewSettings),
);

export const getViewMode = createSelector([getViewModeViewSettings], ({ mode }): ViewMode => mode);

export const getFieldColumnsViewSettingsByViewMode = createSelector(
	[getFieldColumnsViewSettings, getListFieldColumnsViewSettings, getViewMode],
	(fieldColumns, listFieldColumns, viewMode): FieldColumnsState =>
		viewMode === VIEW_MODES.LIST ? listFieldColumns : fieldColumns,
);
