import { type UpdateEAPAction, UPDATE } from './actions';
import type { UserIsOnboarded } from './types';

const initialState: UserIsOnboarded = null;

type Action = UpdateEAPAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: UserIsOnboarded = initialState, action: Action) => {
	switch (action.type) {
		case UPDATE:
			return action.payload;
		default:
			return state;
	}
};
