import type { ViewSettingsState } from './types';

export const RESET = 'state.domain.view-settings.RESET' as const;
export type ResetActionPayload = ViewSettingsState;
export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export const reset = (payload: ResetActionPayload): ResetAction => ({ type: RESET, payload });
