import React, { Component } from 'react';
import Avatar from '@atlaskit/avatar';
import { Text } from '@atlaskit/primitives';
import { injectIntl } from '@atlassian/jira-intl';
import {
	AsyncSelect,
	selectComponents,
} from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import fetch from '../../fetch';
import { isDefined, filterMap } from '../../ramda';
import { UserSelectItem } from '../../view/user-picker';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, MembersOption, MultiValueProps, Person } from './types';

export const TeamLabel = ({ label, icon }: MembersOption) => (
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
	<div className={styles['members-option']}>
		<Avatar size="xsmall" src={icon} />
		{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
		<span className={styles.title}>{label}</span>
	</div>
);

export const MultiValue = (props: MultiValueProps<MembersOption, true>) => {
	const { data, components } = props;
	return (
		<selectComponents.MultiValue
			{...props}
			// @ts-expect-error - styles doesn't exist on the MultiValue props only getStyles exists
			styles={{ borderRadius: '10px' }}
			components={{
				...components,
				Label: () => <TeamLabel {...data} />,
			}}
		/>
	);
};

export const DropdownIndicator = () => <Text>&nbsp;</Text>;

export const findPersons = (query: string): Promise<MembersOption[]> =>
	fetch('/rest/teams/1.0/persons/find', {
		method: 'POST',
		body: { query, maxResults: 50, excludedIds: [] },
	})
		.then((response) => response.json())
		.then((data) =>
			data.results
				? filterMap(
						(result: Person) => isDefined(result.jiraUser),
						(result: Person) => ({
							label: result.jiraUser.title,
							value: result.personId,
							icon: result.jiraUser.avatarUrl,
							email: result.jiraUser.email,
						}),
						data.results,
					)
				: [],
		);

export const formatOptionLabel = (
	option: MembersOption,
	{
		context,
	}: {
		context: 'value' | 'menu';
	},
) => {
	if (context === 'menu') {
		return <UserSelectItem {...option} />;
	}
	return option.value;
};

// eslint-disable-next-line jira/react/no-class-components, @typescript-eslint/no-explicit-any
class MembersSelect extends Component<Props, any> {
	static defaultProps = {
		findPersons,
	};

	isOptionSelected = (option: MembersOption, values: ReadonlyArray<MembersOption>): boolean =>
		values.some((val) => val.value.toString() === option.value.toString());

	render() {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const { intl, findPersons, onChange, onFocus, id, value, ariaLabel, ...restProps } = this.props;
		return (
			<AsyncSelect
				hideSelectedOptions
				isMulti
				isTransparentBackground={false}
				loadOptions={findPersons}
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				onChange={onChange as (value: ReadonlyArray<MembersOption>) => void} // NOTE: Select component only take readonly array shape while the data shape in the callback passed down needs to be normal array (src/packages/portfolio-3/portfolio/src/app-simple-plans/view/main/tabs/teams/list/team-dialog/team-form/view.tsx)
				onFocus={onFocus}
				inputId={id}
				value={value}
				formatOptionLabel={formatOptionLabel}
				components={{ MultiValue, DropdownIndicator }}
				cacheOptions
				defaultOptions
				isOptionSelected={this.isOptionSelected}
				styles={{
					// eslint-disable-next-line @typescript-eslint/no-shadow
					multiValue: (styles) => ({
						...styles,
						borderRadius: '10px',
					}),
				}}
				noOptionsMessage={() => intl.formatMessage(messages.noMatches)}
				{...restProps}
				aria-label={ariaLabel}
			/>
		);
	}
}

export default injectIntl(MembersSelect);
