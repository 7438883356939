import React, { Component } from 'react';
import { injectIntl } from '@atlassian/jira-intl';
import { convertCapacityByPlanningUnit } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints/utils.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import messages from './messages';
import type { PropsWithIntl } from './types';

const hoursPerDay = 8;

// eslint-disable-next-line jira/react/no-class-components
class PlannedCapacity extends Component<PropsWithIntl> {
	getConvertedValue(): number {
		const { capacity, planningUnit } = this.props.plannedCapacity;

		return convertCapacityByPlanningUnit({
			capacity: capacity || 0,
			planningUnit,
			workingHours: hoursPerDay,
		});
	}

	render() {
		const { intl, plannedCapacity } = this.props;
		if (!isDefined(plannedCapacity)) {
			return <div>-</div>;
		}

		return (
			<span>
				{this.getConvertedValue()}{' '}
				{intl.formatMessage(messages.plannedCapacityUnits, {
					planningUnit: plannedCapacity.planningUnit,
				})}
			</span>
		);
	}
}

export default injectIntl(PlannedCapacity);
