import { useEffect, useState } from 'react';
import { triggerPostMoveFlash } from '@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash';
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { COLUMN } from '../constants';
import type { Props } from './types';
import { getDestinationColumnId } from './utils';

export const ColumnDragManager = ({ columnIds, moveColumn }: Props) => {
	const [lastDropped, setLastDropped] = useState<string | null>(null);

	useEffect(() => {
		if (!lastDropped) return;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const element = document.querySelector<HTMLElement>(`[data-column-id="${lastDropped}"]`);

		if (!element) return;

		triggerPostMoveFlash(element);
		setLastDropped(null);
	}, [lastDropped]);

	useEffect(() => {
		return monitorForElements({
			canMonitor: ({ source }) => source.data.type === COLUMN,
			onDrop: ({ source, location }) => {
				const target = location.current.dropTargets[0];

				if (!target) return;

				const sourceColumnId = source.data.columnId;
				const targetColumnId = target.data.columnId;
				const closestEdge = extractClosestEdge(target.data);

				if (
					typeof sourceColumnId !== 'string' ||
					typeof targetColumnId !== 'string' ||
					!closestEdge
				)
					return;

				const destinationColumnId = getDestinationColumnId(
					sourceColumnId,
					targetColumnId,
					columnIds,
					closestEdge,
				);

				moveColumn(sourceColumnId, destinationColumnId);

				setLastDropped(sourceColumnId);
			},
		});
	}, [columnIds, moveColumn]);

	return null;
};
