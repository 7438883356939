import type { PlanDefaults } from './types';

export const SET_PLAN_DEFAULTS = 'state.domain.planDefaults.SET_PLAN_DEFAULTS' as const;

export type SetPlanDefaultsAction = {
	type: typeof SET_PLAN_DEFAULTS;
	payload: PlanDefaults;
};

export const setPlanDefaults = (payload: PlanDefaults): SetPlanDefaultsAction => ({
	type: SET_PLAN_DEFAULTS,
	payload,
});
