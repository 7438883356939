import noop from 'lodash/fp/noop';
import {
	createActionsHook,
	createContainer,
	createHook,
	createStore,
} from '@atlassian/react-sweet-state';
import { actions } from './actions';
import { type State, DOWNWARD } from './types';

const store = createStore<State, typeof actions>({
	initialState: {
		top: 0,
		left: 0,
		width: 0,
		height: 0,
		scrollX: 0,
		scrollY: 0,
		scrollTo: noop,
		isScrollingY: false,
		scrollYDirection: DOWNWARD,
		scrollEventHandlers: [],
	},
	actions,
});

export const useViewport = createHook(store, {
	selector: ({ top, left, width, height }) => ({ top, left, width, height }),
});

export const useScrollX = createHook(store, { selector: ({ scrollX }) => scrollX });
export const useScrollY = createHook(store, { selector: ({ scrollY }) => scrollY });
export const useScrollYDirection = createHook(store, {
	selector: ({ scrollYDirection }) => scrollYDirection,
});
export const useIsScrollingY = createHook(store, { selector: ({ isScrollingY }) => isScrollingY });
export const useContainerActions = createActionsHook(store);

export const ContainerProvider = createContainer(store, {
	onInit:
		() =>
		({ setState }, state: Partial<State>) =>
			setState(state),
	onUpdate:
		() =>
		({ setState }, state: Partial<State>) =>
			setState(state),
});

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { DOWNWARD, UPWARD } from './types';
