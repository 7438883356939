import type { Dispatch } from 'redux';
import {
	genericError,
	type GenericErrorActionPayload,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/errors';
import type { DispatchProps, Action } from './types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (dispatch: Dispatch<Action>): DispatchProps => ({
	genericError: (payload: GenericErrorActionPayload) => {
		dispatch(genericError(payload));
	},
});
