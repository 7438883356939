import type { FieldColumnsState } from './types';

export const RESET = 'state.domain.view-settings.field-columns.RESET' as const;
export const SHOW_COLUMN = 'state.domain.view-settings.field-columns.SHOW_COLUMN' as const;
export const HIDE_COLUMN = 'state.domain.view-settings.field-columns.HIDE_COLUMN' as const;
export const MOVE_COLUMN = 'state.domain.view-settings.field-columns.MOVE_COLUMN' as const;

export const AFM_DRAG_FIELD = 'afm-drag-field';
export const AFM_HIDE_FIELD = 'afm-hide-field';
export const AFM_ADD_FIELD = 'afm-add-field';

export const DRAG_FIELD = 'drag-field';
export const HIDE_FIELD = 'hide-field';

type Source =
	| typeof AFM_DRAG_FIELD
	| typeof AFM_HIDE_FIELD
	| typeof AFM_ADD_FIELD
	| typeof DRAG_FIELD
	| typeof HIDE_FIELD;

type ColumnActionMeta = {
	source: Source;
};

export type ResetAction = {
	type: typeof RESET;
	payload: FieldColumnsState;
};

export type ShowColumnAction = {
	type: typeof SHOW_COLUMN;
	id: string;
	meta: ColumnActionMeta;
};

export type HideColumnAction = {
	type: typeof HIDE_COLUMN;
	id: string;
	meta: ColumnActionMeta;
};

export type MoveColumnActionPayload = {
	columnToMove: string;
	moveToTheIndexOf: string;
};

export type MoveColumnAction = {
	type: typeof MOVE_COLUMN;
	payload: MoveColumnActionPayload;
	meta: ColumnActionMeta;
};

export const reset = (payload: FieldColumnsState): ResetAction => ({
	type: RESET,
	payload,
});

export const move = (
	payload: MoveColumnActionPayload,
	meta: ColumnActionMeta,
): MoveColumnAction => ({
	type: MOVE_COLUMN,
	payload,
	meta,
});
