/**
 * @generated SignedSource<<dea1e37a236d91d669fac3f29267fa64>>
 * @relayHash 5ad0e379e8b207c569ddef74af84a50c
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 35ef0ade1f54adfebb7e1dabfc228fc696600ca354d73e5228e19151bdae46c1

import type { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type JiraSuggestedChildIssueStatusType = "COMPLETE" | "REFINING_SUGGESTED_ISSUES" | "REFORMATTING_ISSUES" | "REMOVING_DUPLICATE_ISSUES" | "RETRIEVING_SOURCE_CONTEXT" | "SUGGESTING_INITIAL_ISSUES" | "%future added value";
export type JiraSuggestedIssueErrorType = "COMMUNICATIONS_ERROR" | "NOT_ENOUGH_INFORMATION" | "NO_FURTHER_SUGGESTIONS" | "UNCLASSIFIED" | "UNETHICAL_CONTENT" | "%future added value";
export type JiraSuggestedIssueInput = {
  description?: string | null | undefined;
  summary?: string | null | undefined;
};
export type issueBreakdownNewForPortfolio3Subscription$variables = {
  additionalContext?: string | null | undefined;
  channelId?: string | null | undefined;
  excludeSimilarIssues?: ReadonlyArray<JiraSuggestedIssueInput> | null | undefined;
  issueTypeIds?: ReadonlyArray<string> | null | undefined;
  sourceIssueId: string;
};
export type issueBreakdownNewForPortfolio3Subscription$data = {
  readonly jira: {
    readonly onSuggestedChildIssue: {
      readonly __typename: "JiraSuggestedChildIssueError";
      readonly error: JiraSuggestedIssueErrorType | null | undefined;
    } | {
      readonly __typename: "JiraSuggestedChildIssueStatus";
      readonly channelId: string | null | undefined;
      readonly status: JiraSuggestedChildIssueStatusType | null | undefined;
    } | {
      readonly __typename: "JiraSuggestedIssue";
      readonly description: string | null | undefined;
      readonly issueTypeId: string | null | undefined;
      readonly summary: string | null | undefined;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null | undefined;
  } | null | undefined;
};
export type issueBreakdownNewForPortfolio3Subscription = {
  response: issueBreakdownNewForPortfolio3Subscription$data;
  variables: issueBreakdownNewForPortfolio3Subscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "additionalContext"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "channelId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "excludeSimilarIssues"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueTypeIds"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceIssueId"
},
v5 = [
  {
    "concreteType": "JiraSubscription",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "additionalContext",
            "variableName": "additionalContext"
          },
          {
            "kind": "Variable",
            "name": "channelId",
            "variableName": "channelId"
          },
          {
            "kind": "Variable",
            "name": "excludeSimilarIssues",
            "variableName": "excludeSimilarIssues"
          },
          {
            "kind": "Variable",
            "name": "issueTypeIds",
            "variableName": "issueTypeIds"
          },
          {
            "kind": "Variable",
            "name": "sourceIssueId",
            "variableName": "sourceIssueId"
          }
        ],
        "kind": "LinkedField",
        "name": "onSuggestedChildIssue",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "__typename"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "ScalarField",
                "name": "summary"
              },
              {
                "kind": "ScalarField",
                "name": "description"
              },
              {
                "kind": "ScalarField",
                "name": "issueTypeId"
              }
            ],
            "type": "JiraSuggestedIssue"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "ScalarField",
                "name": "status"
              },
              {
                "kind": "ScalarField",
                "name": "channelId"
              }
            ],
            "type": "JiraSuggestedChildIssueStatus"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "ScalarField",
                "name": "error"
              }
            ],
            "type": "JiraSuggestedChildIssueError"
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "name": "issueBreakdownNewForPortfolio3Subscription",
    "selections": (v5/*: any*/),
    "type": "Subscription"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "issueBreakdownNewForPortfolio3Subscription",
    "selections": (v5/*: any*/)
  },
  "params": {
    "id": "35ef0ade1f54adfebb7e1dabfc228fc696600ca354d73e5228e19151bdae46c1",
    "metadata": {},
    "name": "issueBreakdownNewForPortfolio3Subscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "9ac1585a2037d5751db81a0001bfc81c";

export default node;
