import {
	UPDATE_COLUMN_WIDTHS,
	type UpdateColumnWidthsAction,
	type UpdateColumnWidthsActionPayload,
} from './types';

export const updateColumnWidths = (
	payload: UpdateColumnWidthsActionPayload,
): UpdateColumnWidthsAction => ({
	type: UPDATE_COLUMN_WIDTHS,
	payload,
});
