import { TOGGLE, RESET, type ToggleAction, type ResetAction } from './actions';
import type { HighlightedVersionsState } from './types';

export const initialState: HighlightedVersionsState = {
	versions: [],
};

type Action = ToggleAction | ResetAction;

export const reducer = (state: HighlightedVersionsState = initialState, action: Action) => {
	switch (action.type) {
		case TOGGLE: {
			const { versionId } = action;
			const highlightedVersionIds = new Set<string>(state.versions);
			if (highlightedVersionIds.has(versionId)) {
				highlightedVersionIds.delete(versionId);
			} else {
				highlightedVersionIds.add(versionId);
			}
			return {
				versions: [...highlightedVersionIds],
			};
		}

		case RESET:
			return { versions: action.versionIds };

		default:
			return state;
	}
};

export default reducer;
