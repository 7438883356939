.selectRollupContainer {
	&:hover .onlyRollup {
		width: 50%;
		flex-basis: 0;
	}
}

.openedRollup {
	position: relative;
	z-index: 1;
}

.rollupWrapper {
	display: flex;
	width: 100%;
}

.disabledRollupWrapper {
	&:focus,
	&:active {
		outline: none;
	}
}

.disabledRollup {
	opacity: 0.5;
}

.childrenWrapper {
	width: 100%;
	display: flex;
	align-items: center;
	flex: 1 1 0;
	padding-top: 2px;
	padding-bottom: 2px;
}

.childrenWrapperWithRollupValue {
	justify-content: flex-end;
}

.placeholder {
	position: relative;
}

.rollup {
	display: flex;
	padding-right: var(--jpo-common-base-spacing);
	font-style: italic;
	color: var(--ds-text-subtle, var(--adg3-color-N400));
	width: 50%;
	align-items: center;
	cursor: pointer;
	flex: 1 1 0;
	&.onlyRollup {
		width: 100%;
		flex-basis: 100%;
	}
	&.onlyRollupReadOnly {
		width: 100%;
		flex-basis: 100%;
	}
}

.rollupValue {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 2px;
}
