import React, { useEffect, useState } from 'react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	MODAL,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { AnalyticsEvent } from '@atlassian/jira-product-analytics-bridge/src/types';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { useCttOnboardingTour } from '../../../controllers/ctt-onboarding';
import {
	CttOnboardingVariant,
	CttOnboardingStage,
	type CttOnboardingVariantType,
} from '../../../controllers/ctt-onboarding/types';
import { CttSpotlights } from '../../../controllers/ctt-spotlights';
import { messages } from './messages';

interface Props {
	planId: number;
	variant: CttOnboardingVariantType;
	setPrimeRoadmap: () => void;
	setRedirectToSpaPlanPage: (route: string) => void;
	isRoadmapGroupedByNone: boolean;
	setOpenHorizontalPlanActionMenu?: () => void; // remove optional when cleaning getWillShowNav4
}

export const EpicReparentSpotlight = ({
	planId,
	variant,
	setPrimeRoadmap,
	setRedirectToSpaPlanPage,
	isRoadmapGroupedByNone,
	setOpenHorizontalPlanActionMenu,
}: Props) => {
	const { formatMessage } = useIntl();

	const [, { nextCttStage, setSpotlight, resetSpotlight }] = useCttOnboardingTour();
	const [hasPrimedForReparenting, setHasPrimedForReparenting] = useState(false);

	const assertUnreachable = (_: never): never => {
		throw new Error("Didn't expect to get here");
	};

	useEffect(() => {
		if (getWillShowNav4()) {
			setOpenHorizontalPlanActionMenu?.();
		}
	}, [setOpenHorizontalPlanActionMenu, planId]);

	useEffect(() => {
		if (!hasPrimedForReparenting) {
			setPrimeRoadmap();
			setHasPrimedForReparenting(true);
		}
	}, [hasPrimedForReparenting, setPrimeRoadmap]);

	useEffect(() => {
		if (
			hasPrimedForReparenting &&
			(variant === CttOnboardingVariant.TMP_EPIC || variant === CttOnboardingVariant.NO_EPIC)
		) {
			setSpotlight(CttSpotlights.Scope);
		}

		if (hasPrimedForReparenting && !isRoadmapGroupedByNone) {
			// Something went wrong skip this step
			nextCttStage(CttOnboardingStage.REVIEW_CHANGES);
		}

		return () => {
			resetSpotlight();
		};
	}, [
		hasPrimedForReparenting,
		isRoadmapGroupedByNone,
		nextCttStage,
		resetSpotlight,
		setSpotlight,
		variant,
	]);

	if (!hasPrimedForReparenting) return null;

	const fireSkippedButtonEvent = (analyticsEvent: AnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'topLevelPlanningEpicReparentSkip', {
			variant,
		});
	};

	const fireGoBackToIssueSourcesButtonEvent = (analyticsEvent: AnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'topLevelPlanningEpicReparentGoBackToIssueSources', {
			variant,
		});
	};

	const getSpotLight = () => {
		const spotlightTarget = getWillShowNav4() ? CttSpotlights.Settings : CttSpotlights.Scope;
		try {
			switch (variant) {
				case CttOnboardingVariant.TMP_EPIC:
					return (
						<JiraSpotlight
							actions={[
								{
									text: formatMessage(messages.spotlightGoToIssueSources),
									onClick: (_, analyticsEvent) => {
										fireGoBackToIssueSourcesButtonEvent(analyticsEvent);
										resetSpotlight();
										nextCttStage(CttOnboardingStage.NAVIGATE_PLAN);
										setRedirectToSpaPlanPage('/settings/issue-sources');
									},
								},
								{
									text: formatMessage(messages.spotlightContinueAnyway),
									appearance: 'subtle',
									onClick: (_, analyticsEvent) => {
										fireSkippedButtonEvent(analyticsEvent);
										resetSpotlight();
										nextCttStage(CttOnboardingStage.REVIEW_CHANGES);
									},
								},
							]}
							target={spotlightTarget}
							key={spotlightTarget}
							targetBgColor={token('elevation.surface', colors.N0)}
							targetRadius={3}
							messageId="portfolio-3-onboarding.ui.ctt-onboarding.spotlight-epic-reparent.jira-spotlight"
							messageType="engagement"
						>
							<FormattedMessage {...messages.spotlightTmpEpicBody} />
						</JiraSpotlight>
					);
				case CttOnboardingVariant.NO_INITIATIVE:
				case CttOnboardingVariant.NO_EPIC:
					return (
						<JiraSpotlight
							actions={[
								{
									text: formatMessage(messages.spotlightGoToIssueSources),
									onClick: (_, analyticsEvent) => {
										fireGoBackToIssueSourcesButtonEvent(analyticsEvent);
										resetSpotlight();
										nextCttStage(CttOnboardingStage.NAVIGATE_PLAN);
										setRedirectToSpaPlanPage('/settings/issue-sources');
									},
								},
								{
									text: formatMessage(messages.spotlightSkip),
									appearance: 'subtle',
									onClick: (_, analyticsEvent) => {
										fireSkippedButtonEvent(analyticsEvent);
										resetSpotlight();
										nextCttStage(CttOnboardingStage.REVIEW_CHANGES);
									},
								},
							]}
							target={spotlightTarget}
							key={spotlightTarget}
							targetBgColor={token('elevation.surface', colors.N0)}
							targetRadius={3}
							messageId="portfolio-3-onboarding.ui.ctt-onboarding.spotlight-epic-reparent.jira-spotlight.1"
							messageType="engagement"
						>
							<FormattedMessage {...messages.spotlightNoEpicsBody} />
						</JiraSpotlight>
					);
				case CttOnboardingVariant.EPIC:
					return isRoadmapGroupedByNone ? (
						<JiraSpotlight
							actions={[
								{
									text: formatMessage(messages.spotlightGotIt),
									onClick: (_, analyticsEvent) => {
										fireUIAnalytics(analyticsEvent, 'topLevelPlanningEpicReparentGotIt', {
											variant,
										});
										nextCttStage();
									},
								},
							]}
							target={CttSpotlights.EpicSection}
							key={CttSpotlights.EpicSection}
							targetRadius={3}
							targetBgColor={token('elevation.surface', colors.N0)}
							messageId="portfolio-3-onboarding.ui.ctt-onboarding.spotlight-epic-reparent.jira-spotlight.2"
							messageType="engagement"
						>
							<FormattedMessage {...messages.spotlightEpicReparentBody} />
						</JiraSpotlight>
					) : null;
				default:
					assertUnreachable(variant);
					return null;
			}
		} catch (e) {
			return null;
		}
	};
	return (
		<ContextualAnalyticsData sourceName="topLevelPlanningEpicReparentSpotlight" sourceType={MODAL}>
			{getSpotLight()}
			<FireScreenAnalytics
				attributes={{
					hasOnlyTmpEpics: variant === CttOnboardingVariant.TMP_EPIC,
					hasNoEpics: variant === CttOnboardingVariant.NO_EPIC,
					hasNoInitiativesConfigured: variant === CttOnboardingVariant.NO_INITIATIVE,
				}}
			/>
		</ContextualAnalyticsData>
	);
};
