import type { CrossProjectVersion } from './types';

export const RESET = 'state.domain.cross-project-versions.RESET' as const;

export const ADD = 'state.domain.cross-project-versions.ADD' as const;

export const REMOVE = 'state.domain.cross-project-versions.REMOVE' as const;

export const BULK_UPDATE = 'state.domain.cross-project-versions.BULK_UPDATE' as const;

export const ADD_VERSION_IN_CROSS_PROJECT_VERSION =
	'state.domain.cross-project-versions.ADD_VERSION_IN_CROSS_PROJECT_VERSION' as const;

export const REMOVE_VERSION_FROM_CROSS_PROJECT_VERSION =
	'state.domain.cross-project-versions.REMOVE_VERSION_FROM_CROSS_PROJECT_VERSION' as const;

export const UPDATE_REFERENCES = 'state.domain.cross-project-versions.UPDATE_REFERENCES' as const;

export type ResetActionPayload = CrossProjectVersion[];
export type AddActionPayload = CrossProjectVersion;
export type BulkUpdatePayload = {
	[key: string]: Partial<CrossProjectVersion>;
};
export type AddRemoveVersionFromCrossProjectVersionActionPayload = {
	crossProjectVersionId: string;
	versionId: string;
};
export type UpdateReferencesPayload = {
	[key: string]: [string | null | undefined, string | null | undefined][];
};

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export type AddAction = {
	type: typeof ADD;
	payload: AddActionPayload;
};

export type RemoveAction = {
	type: typeof REMOVE;
	payload: string;
};

export type BulkUpdateAction = {
	type: typeof BULK_UPDATE;
	payload: BulkUpdatePayload;
};

export type AddVersionInCrossProjectVersionAction = {
	type: typeof ADD_VERSION_IN_CROSS_PROJECT_VERSION;
	payload: AddRemoveVersionFromCrossProjectVersionActionPayload;
};

export type RemoveVersionFromCrossProjectVersionAction = {
	type: typeof REMOVE_VERSION_FROM_CROSS_PROJECT_VERSION;
	payload: AddRemoveVersionFromCrossProjectVersionActionPayload;
};

export type UpdateReferencesAction = {
	type: typeof UPDATE_REFERENCES;
	payload: UpdateReferencesPayload;
};

export const reset = (payload: ResetActionPayload): ResetAction => ({
	type: RESET,
	payload,
});

export const add: (arg1: AddActionPayload) => AddAction = (payload) => ({
	type: ADD,
	payload,
});

export const remove = (payload: string): RemoveAction => ({
	type: REMOVE,
	payload,
});

export const bulkUpdate = (payload: BulkUpdatePayload): BulkUpdateAction => ({
	type: BULK_UPDATE,
	payload,
});

export const updateReferences = (payload: UpdateReferencesPayload): UpdateReferencesAction => ({
	type: UPDATE_REFERENCES,
	payload,
});

export const addVersionInCrossProjectVersion = (
	payload: AddRemoveVersionFromCrossProjectVersionActionPayload,
): AddVersionInCrossProjectVersionAction => ({
	type: ADD_VERSION_IN_CROSS_PROJECT_VERSION,
	payload,
});

export const removeVersionFromCrossProjectVersion = (
	payload: AddRemoveVersionFromCrossProjectVersionActionPayload,
): RemoveVersionFromCrossProjectVersionAction => ({
	type: REMOVE_VERSION_FROM_CROSS_PROJECT_VERSION,
	payload,
});
