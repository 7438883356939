import {
	getAssigneeList,
	getAssigneesById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/assignees';
import { getSelectedIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export default createStructuredSelector<State, StateProps>({
	selectedIssues: getSelectedIssues,
	assignees: getAssigneeList,
	assigneesById: getAssigneesById,
});
