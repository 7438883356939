import { createAri } from '@atlassian/jira-platform-ari';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import { getClient } from '../../common/utils/client';
import type {
	FetchSuggestedChildIssueParams,
	SuggestedChildIssuesGiraData,
	SuggestedChildIssuesResponse,
} from './types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	ERROR_NOT_ENOUGH_INFORMATION,
	ERROR_NO_FURTHER_SUGGESTIONS,
	ERROR_UNCLASSIFIED,
} from './types';

export const suggestChildIssuesQuery = () => `
    query IssueBreakdownDataFetcher($issueTypeIds:[ID!], $parentIssueIdAri: ID!, $channelId: String, $additionalContext: String, $acceptedChildIssues: [JiraSuggestedIssueInput], $rejectedChildIssues: [JiraSuggestedIssueInput]) {
        jira  { 
            issueById(id: $parentIssueIdAri) {
                suggestedChildIssues(issueTypeIds:$issueTypeIds, channelId:$channelId, additionalContext: $additionalContext, acceptedChildIssues: $acceptedChildIssues, rejectedChildIssues: $rejectedChildIssues) {
                    channelId,
                    error,
                    suggestedChildIssues {
                        summary
                        description
                        issueTypeId
                    }
                }
            }
        }
    }
`;

export const fetchSuggestedChildIssuesData = async (
	cloudId: CloudId,
	{
		channelId,
		parentIssueId,
		additionalContext,
		acceptedChildIssues,
		rejectedChildIssues,
		issueTypeIds,
	}: FetchSuggestedChildIssueParams,
	signal: AbortSignal,
): Promise<SuggestedChildIssuesGiraData> => {
	const parentIssueIdAri = createAri({
		resourceOwner: 'jira',
		resourceType: 'issue',
		resourceId: parentIssueId,
		cloudId,
	});

	const { data, errors }: SuggestedChildIssuesResponse = await getClient('').query({
		operationName: 'IssueBreakdownDataFetcher',
		query: suggestChildIssuesQuery(),
		variables: {
			parentIssueIdAri,
			issueTypeIds,
			channelId,
			additionalContext,
			acceptedChildIssues,
			rejectedChildIssues,
		},
		signal,
	});

	const dataError = data?.jira?.issueById?.suggestedChildIssues?.error;

	// GraphQL Response Error
	if (errors != null && errors.length > 0) {
		const [{ message }] = errors;
		throw new Error(message);
	}
	// AIMate data error, no more suggestions, not enough information
	// see: SuggestedChildIssuesError
	if (dataError) {
		throw new Error(dataError);
	}

	return {
		error: null,
		suggestedChildIssues: data?.jira?.issueById?.suggestedChildIssues?.suggestedChildIssues || [],
		channelId: data?.jira?.issueById?.suggestedChildIssues?.channelId || '',
	};
};
