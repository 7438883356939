import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { OwnProps } from './types';

const LazyComponent = lazyForPaint(() =>
	import(/* webpackChunkName: "async-portfolio-3-roadmap" */ './index').then(
		(module) => module.default,
	),
);

const AsyncComponent = (props: OwnProps) => (
	<Placeholder name="plan-roadmap">
		<LazyComponent {...props} />
	</Placeholder>
);

export default AsyncComponent;
