import React, { type ComponentType } from 'react';
import { lazy } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { Props as CrossProjectReleaseProps } from './cross-project-release/types';
import type { Props as NavigationProps } from './navigation/types';
import type { Props as OverviewReleasesProps } from './overview-releases/types';
import type { Props as ProjectReleaseProps } from './project-release/types';
import type { Props as QuickFilterByReleaseProps } from './quick-filter-by-release/types';
import type { Props as ReleaseDatesProps } from './release-dates/types';

const async = () =>
	import(/* webpackChunkName: "async-advanced-roadmaps-release-flyouts" */ './index');

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AsyncCrossProjectRelease = lazy<ComponentType<CrossProjectReleaseProps>>(() =>
	async().then(({ CrossProjectRelease }) => CrossProjectRelease),
);

export const CrossProjectRelease = (props: CrossProjectReleaseProps) => (
	<Placeholder name="cross-project-release" fallback={null}>
		<AsyncCrossProjectRelease {...props} />
	</Placeholder>
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AsyncNavigation = lazy<ComponentType<NavigationProps>>(() =>
	async().then(({ Navigation }) => Navigation),
);

export const Navigation = (props: NavigationProps) => (
	<Placeholder name="navigation" fallback={null}>
		<AsyncNavigation {...props} />
	</Placeholder>
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AsyncOverviewReleases = lazy<ComponentType<OverviewReleasesProps>>(() =>
	async().then(({ OverviewReleases }) => OverviewReleases),
);

export const OverviewReleases = (props: OverviewReleasesProps) => (
	<Placeholder name="overview-releases" fallback={null}>
		<AsyncOverviewReleases {...props} />
	</Placeholder>
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AsyncProjectRelease = lazy<ComponentType<ProjectReleaseProps>>(() =>
	async().then(({ ProjectRelease }) => ProjectRelease),
);

export const ProjectRelease = (props: ProjectReleaseProps) => (
	<Placeholder name="project-release" fallback={null}>
		<AsyncProjectRelease {...props} />
	</Placeholder>
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AsyncQuickFilterByRelease = lazy<ComponentType<QuickFilterByReleaseProps>>(() =>
	async().then(({ QuickFilterByRelease }) => QuickFilterByRelease),
);

export const QuickFilterByRelease = (props: QuickFilterByReleaseProps) => (
	<Placeholder name="quick-filter-by-release" fallback={null}>
		<AsyncQuickFilterByRelease {...props} />
	</Placeholder>
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AsyncReleaseDates = lazy<ComponentType<ReleaseDatesProps>>(() =>
	async().then(({ ReleaseDates }) => ReleaseDates),
);

export const ReleaseDates = (props: ReleaseDatesProps) => (
	<Placeholder name="release-dates" fallback={null}>
		<AsyncReleaseDates {...props} />
	</Placeholder>
);

export const preload = () => {
	AsyncCrossProjectRelease.preload();
	AsyncNavigation.preload();
	AsyncOverviewReleases.preload();
	AsyncProjectRelease.preload();
	AsyncQuickFilterByRelease.preload();
	AsyncReleaseDates.preload();
};
