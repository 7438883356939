import React from 'react';
import StandardButton from '@atlaskit/button/standard-button';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalHeader,
	ModalTitle,
	ModalBody,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import messages from './messages';
import type { Props } from './types';

export default function DiscardConfirmation({ isOpen, onConfirm, onCancel }: Props) {
	const { formatMessage } = useIntl();

	if (!isOpen) return null;

	return (
		<ShortcutScope>
			<ModalDialog autoFocus onClose={onCancel}>
				<ModalHeader>
					<ModalTitle>{formatMessage(messages.title)}</ModalTitle>
				</ModalHeader>
				<ModalBody>{formatMessage(messages.message)}</ModalBody>
				<ModalFooter>
					<StandardButton
						appearance="subtle"
						onClick={onCancel}
						testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.discard-confirmation.cancel"
					>
						{formatMessage(commonMessages.cancel)}
					</StandardButton>
					<StandardButton
						appearance="primary"
						onClick={onConfirm}
						testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.discard-confirmation.confirm"
					>
						{formatMessage(messages.confirm)}
					</StandardButton>
				</ModalFooter>
			</ModalDialog>
		</ShortcutScope>
	);
}
