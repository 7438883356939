import React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

export default function ProgressBar(props: Props) {
	const { percentage } = props;
	if (percentage < 0 || percentage > 100) {
		throw new Error(`Percentage must be within [0, 100] range, got ${percentage}`);
	}
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.container}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.bar} style={{ width: `${percentage}%` }} />
		</div>
	);
}
