import React from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import StandardButton from '@atlaskit/button/standard-button';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalHeader,
	ModalTitle,
	ModalBody,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import { Text } from '@atlaskit/primitives';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import messages from './messages';
import type { Props } from './types';

const RemoveIssuesDialog = ({ toggleModalDialog, bulkActionSuccess, removeFromPlan }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<ShortcutScope>
			<ModalDialog shouldScrollInViewport onClose={toggleModalDialog} autoFocus={false}>
				<ModalHeader>
					<ModalTitle appearance="danger">{formatMessage(messages.removeFromPlanTitle)}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<Text>
						<FormattedMessage
							{...messages.removeIssueFromPlanMessage}
							values={{
								b: (chunks) => <b>{chunks}</b>,
							}}
						/>
					</Text>
				</ModalBody>
				<ModalFooter>
					<StandardButton appearance="subtle" onClick={toggleModalDialog}>
						{formatMessage(commonMessages.cancel)}
					</StandardButton>
					<StandardButton
						appearance="primary"
						onClick={(_, analyticsEvent: UIAnalyticsEvent) => {
							removeFromPlan();
							bulkActionSuccess(analyticsEvent);
						}}
					>
						{formatMessage(messages.removeFromPlanActionButton)}
					</StandardButton>
				</ModalFooter>
			</ModalDialog>
		</ShortcutScope>
	);
};

export default RemoveIssuesDialog;
