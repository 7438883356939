import React, { Component } from 'react';
import { formatDateUTC } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import { getIdForChangeCategory } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/title-bar/update-jira/utils';
import { proxyContextSafeUrl } from '@atlassian/jira-portfolio-3-portfolio/src/common/api';
import SprintIcon from '@atlassian/jira-portfolio-3-portfolio/src/common/icons/sprint/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import {
	SCHEDULE_MODE,
	SPRINT_STATES,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

// eslint-disable-next-line jira/react/no-class-components
class SprintTitle extends Component<Props> {
	generateSprintUrl() {
		const { boardId, sprint } = this.props;
		if (sprint && isDefined(boardId) && boardId !== '') {
			let url = proxyContextSafeUrl(`/secure/RapidBoard.jspa?rapidView=${boardId}`);
			if (sprint.state === SPRINT_STATES.CLOSED) {
				url += `&view=reporting&chart=sprintRetrospective&sprint=${sprint.id}`;
			} else if (sprint.state === SPRINT_STATES.FUTURE) {
				url += '&view=planning';
			}
			return url;
		}
	}

	render() {
		const { sprint, boardId, id, category } = this.props;
		let sprintName;
		if (sprint.schedulingMode === SCHEDULE_MODE.kanban) {
			sprintName =
				`${formatDateUTC(sprint.startDate, 'DD MMM')} - ` +
				`${formatDateUTC(sprint.endDate, 'DD MMM')}`;
		} else {
			sprintName = sprint.title;
		}
		const sprintUrl =
			sprint.schedulingMode === SCHEDULE_MODE.scrum &&
			isDefined(boardId) &&
			boardId !== '' &&
			this.generateSprintUrl();

		return (
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.sprintTitle}
				id={getIdForChangeCategory(id, category)}
				data-testid={`portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.title.sprint.sprint-title-container-${id}`}
			>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.projectIcon}>
					<SprintIcon />
				</div>
				<EllipsedWithTooltip content={`${sprintName}`}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.titleText}>
						{isDefined(sprintUrl) && sprintUrl !== '' ? (
							<a href={this.generateSprintUrl()} target="_blank" rel="noopener noreferrer">
								{sprintName}
							</a>
						) : (
							`${sprintName}`
						)}
					</div>
				</EllipsedWithTooltip>
			</div>
		);
	}
}

export default SprintTitle;
