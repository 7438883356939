import * as R from 'ramda';
import { fg } from '@atlassian/jira-feature-gating';
import type { CustomField } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/custom-fields/types.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types';
import {
	type CustomFieldFilter,
	CUSTOM_FIELD_FILTER_ID,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import {
	CustomFieldTypes,
	UNDEFINED_KEY,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import { getCustomFields, getFilterableCustomFields } from '../../custom-fields';
import { getFiltersViewSettings } from '../../view-settings';
import { matchFilter } from '../attribute-filter';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { CUSTOM_FIELD_FILTER_ID } from '../../../state/domain/view-settings/filters/types';

export const NO_CUSTOM_FIELD_VALUE_ID = UNDEFINED_KEY;

export const applyFilter = (
	issue: Issue,
	customFieldFilter: CustomFieldFilter,
	customFields: CustomField[],
): boolean => {
	const matches = Object.keys(customFieldFilter.value).map((fieldId) => {
		const customField = customFields.find(({ id }) => id === Number(fieldId));
		const filterValue = customFieldFilter.value[fieldId];
		const attribute = issue.customFields && issue.customFields[fieldId];

		if (!isDefined(customField)) {
			throw new Error('customField not found for the filter');
		}

		switch (customField.type.key) {
			case CustomFieldTypes.Labels:
			case CustomFieldTypes.MultiCheckboxes:
			case CustomFieldTypes.AssetObject:
			case CustomFieldTypes.MultiSelect: {
				if (!fg('asset-custom-field-internal-support')) {
					if (customField.type.key === CustomFieldTypes.AssetObject) {
						return true;
					}
				}

				const emptyFilter = filterValue.length === 0;

				if (emptyFilter) {
					return true;
				}

				const matchable =
					attribute instanceof Array && !R.isEmpty(attribute)
						? attribute
						: [NO_CUSTOM_FIELD_VALUE_ID];
				return matchFilter(filterValue, matchable);
			}
			case CustomFieldTypes.RadioButtons:
			case CustomFieldTypes.SingleSelect: {
				if (!Array.isArray(filterValue)) {
					throw new Error(`value of filter for ${customField.title} is not an array`);
				}

				const emptyFilter = filterValue.length === 0;

				if (emptyFilter) {
					return true;
				}

				const matchable = attribute ? [attribute] : [NO_CUSTOM_FIELD_VALUE_ID];
				return matchFilter(filterValue, matchable);
			}

			default:
				return true;
		}
	});

	return matches.reduce((a, b) => a && b, true);
};

const getRawCustomFieldFilter = (
	state: State,
): CustomFieldFilter => // Clean up with ViewSwitchingFeatureFlag removal
	getFiltersViewSettings(state)[CUSTOM_FIELD_FILTER_ID] || {
		id: CUSTOM_FIELD_FILTER_ID,
		value: {},
	};
export const getCustomFieldFilter = createSelector(
	[getRawCustomFieldFilter, getCustomFields],
	(customFieldFilter, customFields) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const value = customFields.reduce<Record<string, any>>((acc, customField) => {
			const id = String(customField.id);
			const filterValue = customFieldFilter.value[id];

			switch (customField.type.key) {
				case CustomFieldTypes.Labels:
				case CustomFieldTypes.MultiCheckboxes:
				case CustomFieldTypes.AssetObject:
				case CustomFieldTypes.MultiSelect:
				case CustomFieldTypes.RadioButtons:
				case CustomFieldTypes.SingleSelect: {
					if (!fg('asset-custom-field-internal-support')) {
						if (customField.type.key === CustomFieldTypes.AssetObject) {
							return acc;
						}
					}

					if (!Array.isArray(filterValue)) {
						return Object.assign(acc, {
							[id]: [],
						});
					}

					return Object.assign(acc, {
						[id]: filterValue,
					});
				}

				default:
					return acc;
			}
		}, {});

		return { ...customFieldFilter, value };
	},
);

const getCustomFieldFilterCountPure = (
	customFieldFilter: CustomFieldFilter,
	customFields: CustomField[],
): number => {
	if (customFields.length === 0) {
		return 0;
	}

	return Object.keys(customFieldFilter.value).reduce((count, fieldId) => {
		const customField = customFields.find(({ id }) => id === Number(fieldId));
		const filterValue = customFieldFilter.value[fieldId];

		if (!isDefined(customField)) {
			throw new Error('customField not found for the filter');
		}

		switch (customField.type.key) {
			case CustomFieldTypes.Labels:
			case CustomFieldTypes.MultiCheckboxes:
			case CustomFieldTypes.AssetObject:
			case CustomFieldTypes.MultiSelect:
			case CustomFieldTypes.RadioButtons:
			case CustomFieldTypes.SingleSelect: {
				if (!fg('asset-custom-field-internal-support')) {
					if (customField.type.key === CustomFieldTypes.AssetObject) {
						return count;
					}
				}

				if (!Array.isArray(filterValue)) {
					throw new Error(`value of filter for ${customField.title} is not an array`);
				}

				return filterValue.length > 0 ? count + 1 : count;
			}

			default:
				return count;
		}
	}, 0);
};

export const getCustomFieldFilterCount = createSelector(
	[getCustomFieldFilter, getCustomFields],
	(customFieldFilter, customFields) =>
		getCustomFieldFilterCountPure(customFieldFilter, customFields),
);

export const getCustomFieldsWithFilteringAllowed = createSelector(
	[getFilterableCustomFields],
	(customFields) => customFields.filter((field) => field.filteringAllowed),
);
