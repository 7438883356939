import type { MessageDescriptor } from '@atlassian/jira-intl';
import type { SchedulingMode } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import messages from './messages';

interface CapacityPlanningSettings {
	projectType: SchedulingMode | undefined;
	teamAdded: boolean;
	teamHasIssueSource: boolean;
	planHasBoards: boolean;
}

export function getCapacityPlanningMessage(settings: CapacityPlanningSettings): MessageDescriptor {
	const { projectType, teamAdded, teamHasIssueSource, planHasBoards } = settings;

	if (projectType === 'Kanban') {
		if (!teamAdded) {
			return messages.kanbanNoTeam;
		}
		if (!teamHasIssueSource) {
			return messages.kanbanTeamNotLinked;
		}
	} else if (projectType === 'Scrum') {
		// If the team is scrum and there is no scrum board, redirect them to the issue sources
		if (!planHasBoards) {
			return messages.scrumNoBoardTeamAdded;
		}

		if (!teamAdded) {
			return messages.scrumNoBoardNoTeam;
		}

		if (!teamHasIssueSource) {
			return messages.scrumBoardNoTeam;
		}
	}

	// Default message if no specific scenario is matched
	return messages.associateTeamToIssueSource;
}
