import React, { type ReactNode } from 'react';
import StandardButton from '@atlaskit/button/standard-button';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalHeader,
	ModalTitle,
	ModalBody,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import { Text } from '@atlaskit/primitives';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import messages from './messages';
import type { Props } from './types';

export default function ConfirmationDialog({ viewName, onConfirm, onClose }: Props) {
	const { formatMessage } = useIntl();

	return (
		<ShortcutScope>
			<ModalDialog
				autoFocus
				shouldScrollInViewport
				onClose={onClose}
				shouldCloseOnOverlayClick
				shouldCloseOnEscapePress
			>
				<ModalHeader>
					<ModalTitle appearance="warning">{formatMessage(messages.heading)}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					{formatMessage(messages.message, {
						name: viewName,
						strong: (chunks: ReactNode[]) => <Text as="strong">{chunks}</Text>,
					})}
				</ModalBody>
				<ModalFooter>
					<StandardButton
						appearance="subtle"
						onClick={onClose}
						testId="portfolio-3-portfolio.app-simple-plans.top.view-bar.deleting-dialog.delete-view-config-dialog-cancel-button"
					>
						{formatMessage(commonMessages.cancel)}
					</StandardButton>
					<StandardButton
						appearance="warning"
						onClick={onConfirm}
						testId="portfolio-3-portfolio.app-simple-plans.top.view-bar.deleting-dialog.delete-view-config-dialog-delete-button"
					>
						{formatMessage(commonMessages.delete)}
					</StandardButton>
				</ModalFooter>
			</ModalDialog>
		</ShortcutScope>
	);
}
