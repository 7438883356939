import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { UNDEFINED_GROUP } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope';
import type { GroupOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/types';
import { GROUPING } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import SprintDialogContentDI from './sprint-dialog-content';
import type { GroupOptionsBySourceName } from './sprint-dialog-content/types.tsx';
import type { Props } from './types';
import { getGroupOptionsFromSprints } from './utils';

export default function SprintOptions({
	onGroupClick,
	hierarchyItem,
	groups,
	sprints,
	sprintsByIdMap,
	externalSprintsById,
	SprintDialogContent = SprintDialogContentDI,
}: Props) {
	const { formatMessage } = useIntl();

	const unassigned: GroupOption = {
		groupName: formatMessage(commonMessages.unassigned),
		grouping: GROUPING.SPRINT,
		isExpanded: true,
		group: UNDEFINED_GROUP,
		groupCombination: { sprint: null },
		startInlineCreateInEmptyGroup: !groups.some(({ group }) => group === UNDEFINED_GROUP),
	};

	const groupOptions = getGroupOptionsFromSprints({
		groups,
		sprints,
		sprintsByIdMap,
		externalSprintsById,
	});

	const groupOptionsWithUnassigned: GroupOptionsBySourceName = [
		...groupOptions,
		[null, [unassigned]],
	];

	return (
		<SprintDialogContent
			groupOptions={groupOptionsWithUnassigned}
			hierarchyItem={hierarchyItem}
			onGroupClick={onGroupClick}
		/>
	);
}
