import React from 'react';
import * as R from 'ramda';
import { indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { BREAKDOWN_SPRINT_CAPACITY_BY } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import StatusBreakdown from '@atlassian/jira-portfolio-3-portfolio/src/common/view/status-breakdown';
import { getBreakdown, getBreakdownByEstimate } from './query';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

export const BreakdownCell = ({
	breakdownBy,
	estimateFromHistoryIssues,
	historyIssues,
	isLoading,
	isScrolling,
	issue,
	issueIdToChildIdsMap,
	issueIdToDescendantsIdMap,
	issueMapById,
	issueStatuses,
	issueTypes,
	planningUnit,
	projects,
	workingHours,
}: Props) => {
	if (isLoading) {
		return null;
	}

	const { issueKey, id, summary, project, type } = issue;
	const projectsByIdMap = indexBy(R.prop('id'), projects);
	const issueTypesByIdMap = indexBy(R.prop('id'), issueTypes);

	const projectKey = project ? projectsByIdMap[project]?.key || '' : '';
	const issueType = issueTypesByIdMap[type];

	const issueTitleData = {
		issueKey,
		summary,
		id,
		issueType,
		projectKey,
	};

	const breakdownProps = {
		onlyRenderStatusBar: isScrolling,
		planningUnit: breakdownBy === BREAKDOWN_SPRINT_CAPACITY_BY.STATUS ? 'issueCount' : planningUnit,
		breakdownBy,
	};

	const statusBreakdownProps =
		breakdownBy === BREAKDOWN_SPRINT_CAPACITY_BY.ESTIMATE
			? {
					...breakdownProps,
					...getBreakdownByEstimate(
						issue,
						issueIdToDescendantsIdMap,
						issueStatuses,
						issueMapById,
						planningUnit,
						workingHours,
						estimateFromHistoryIssues,
					),
					issueTitleData,
				}
			: {
					...breakdownProps,
					...getBreakdown(issue, issueIdToChildIdsMap, issueStatuses, issueMapById, historyIssues),
				};

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles['breakdown-container']}>
			<StatusBreakdown {...statusBreakdownProps} />
		</div>
	);
};

export default BreakdownCell;
