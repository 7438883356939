import type { Effect } from 'redux-saga';
import * as R from 'ramda';
import { fork, takeEvery, put, call, select } from 'redux-saga/effects';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch';
import {
	type ShareAs,
	SHARE_AS,
	TIMELINE_SHARE_TYPE,
	DEPENDENCY_REPORT_SHARE_TYPE,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import { getLocation } from '@atlassian/jira-portfolio-3-portfolio/src/common/window';
import { getDependencyFilters } from '../../query/dependencies';
import { getShareAsState } from '../../query/share-report';
import { getViewSettings } from '../../query/view-settings';
import type { State } from '../../state/types';
import {
	updateShareReportUrl,
	setLoadingShareReport,
} from '../../state/ui/top/title-bar/share-report/actions';
import { POST, parseError } from '../api';
import { genericError } from '../errors';
import { urls } from './api';

export const CREATE_SHARE_REPORT_URL = 'command.share-report.CREATE_SHARE_REPORT_URL' as const;

export const createShareReportUrl = () => ({
	type: CREATE_SHARE_REPORT_URL,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getViewConfig(shareAs: ShareAs): Generator<Effect, any, any> {
	switch (shareAs) {
		case SHARE_AS.DEPENDENCY_REPORT_CONFLUENCE:
			return { filtersV1: yield select(getDependencyFilters) };
		default:
			return yield select(getViewSettings);
	}
}

function getViewType(shareAs: ShareAs) {
	switch (shareAs) {
		case SHARE_AS.DEPENDENCY_REPORT_CONFLUENCE:
			return DEPENDENCY_REPORT_SHARE_TYPE;
		default:
			return TIMELINE_SHARE_TYPE;
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doCreateShareReportUrl(): Generator<Effect, any, any> {
	const {
		domain: {
			plan: { id, currentScenarioId },
		},
	}: State = yield select(R.identity);

	const shareAs: ShareAs = yield select(getShareAsState);
	const viewConfig = yield call(getViewConfig, shareAs);

	if (!id) {
		throw new Error('No plan to share');
	}
	try {
		yield put(setLoadingShareReport(true));
		const url = urls.share;
		const body = {
			entityId: id,
			entityType: 'PLAN',
			parameters: {
				// Add your configurations here!
				viewConfig,
				scenarioId: currentScenarioId,
				version: 3,
				view: getViewType(shareAs),
			},
		};
		const response = yield call(fetch, url, {
			method: POST,
			body,
		});
		if (response.ok) {
			const json = yield call(response.json.bind(response));
			const location = yield call(getLocation);
			const domain = location.origin;
			const hash = json.hash;
			// eslint-disable-next-line @typescript-eslint/no-shadow
			let url = `${domain}/jira/plans/reports/${hash}`;
			if (shareAs === SHARE_AS.EMBED) {
				url = `${domain}/secure/PlanEmbeddedReport.jspa?r=${hash}`;
			}

			if (shareAs === SHARE_AS.CONFLUENCE || shareAs === SHARE_AS.DEPENDENCY_REPORT_CONFLUENCE) {
				url = `${domain}/secure/PortfolioRoadmapConfluence.jspa?r=${hash}`;
			}

			yield put(updateShareReportUrl(url));
		} else {
			yield put(
				genericError({
					...parseError(response, yield call(response.text.bind(response))),
					requestInfo: {
						url,
						type: POST,
						status: response.status,
						body,
					},
				}),
			);
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		yield put(genericError({ message: e.message, stackTrace: e.stack }));
	} finally {
		yield put(setLoadingShareReport(false));
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchCreateShareReportUrl(): Generator<Effect, any, any> {
	yield takeEvery(CREATE_SHARE_REPORT_URL, doCreateShareReportUrl);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchCreateShareReportUrl);
}
