import * as R from 'ramda';
import { getChangedAttributes as getChangedAttributesForIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues';
import { expandChangesForTeam } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams/changes';
import type { Change } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira/types';
import { getChangedAttributes as getChangedAttributesForVersion } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/utils';
import type { State as WarningsState } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/update-jira/warnings/types';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import type {
	SortDirection,
	ExpandedChanges,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/title-bar/update-jira/types.tsx';
import {
	ENTITY,
	SCENARIO_TYPE,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import type { CommitWarningMeta } from '../types';

export const getDialogVisibilityState = (state: State): boolean =>
	state.ui.Top.TitleBar.UpdateJira.isDialogOpen;

export const getCommitWarningMeta = (
	commitWarnings: WarningsState,
	changesWarnings: WarningsState,
	isSaving: boolean,
	committedChangesCount: number,
	isCommitWarningFlagClosed: boolean,
	isLoaded: boolean,
): CommitWarningMeta => {
	const failedCommitCount = R.reduce(
		(numWarnings, warnings) => numWarnings + (warnings ? Object.keys(warnings).length : 0),
		0,
		R.values(commitWarnings),
	);

	return {
		commitWarningsExists:
			!R.all(R.isEmpty)(R.values(commitWarnings)) || !R.all(R.isEmpty)(R.values(changesWarnings)),
		shouldShowCommitWarningFlag: isLoaded && R.not(isSaving) && R.not(isCommitWarningFlagClosed),
		successfulCommitCount: committedChangesCount - failedCommitCount,
		failedCommitCount,
	};
};

export const compareLastModifiedDescending = (changeA: Change, changeB: Change): number => {
	if (changeA.lastModified && changeB.lastModified) {
		return changeA.lastModified.date < changeB.lastModified.date ? -1 : 1;
	}
	return 0;
};

export const compareLastModifiedAscending = (changeA: Change, changeB: Change): number => {
	if (changeA.lastModified && changeB.lastModified) {
		return changeA.lastModified.date > changeB.lastModified.date ? -1 : 1;
	}
	return 0;
};

export const sortChanges = (changes: Change[], sortDirection: SortDirection): Change[] => {
	if (sortDirection === 'ASC') {
		return R.sort(compareLastModifiedAscending, changes);
	}
	return R.sort(compareLastModifiedDescending, changes);
};

export const getAttributeChanges = (change: Change): Change[] => {
	const { category, lastModified, warnings, ...rest } = change;
	let changedAttributes: string[];

	switch (change.category) {
		case ENTITY.ISSUE: {
			const { type, details } = change;
			changedAttributes = [...getChangedAttributesForIssue(details, type)];
			break;
		}
		case ENTITY.RELEASE: {
			const {
				type,
				details: { values, originals },
			} = change;
			changedAttributes = [...getChangedAttributesForVersion(values, originals, type)];
			break;
		}
		case ENTITY.TEAM:
		case ENTITY.RESOURCE:
			return expandChangesForTeam(change);
		case ENTITY.SPRINT:
			changedAttributes = Object.keys(change.details.originals);
			break;
		default:
			return [];
	}

	return changedAttributes.map(
		(attributeName) =>
			// NOTE due to Flow limitations in handling sum types we need to do unsafe cast here
			// therefore please pay extra attention to keep this code correct
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			({
				...rest,
				type: SCENARIO_TYPE.NONE,
				category,
				attributeName,
				warnings: [],
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			}) as any,
	);
};

export const expandChanges = (
	collapsedChanges: Change[],
	expandedChanges: ExpandedChanges,
): Change[] => {
	const changes: Change[] = [];
	collapsedChanges.forEach((change) => {
		const { id, category, changeCount } = change;
		changes.push(change);
		const isExpanded = expandedChanges[category].includes(id);
		if (isExpanded && (change.type === SCENARIO_TYPE.ADDED || changeCount > 1)) {
			changes.push(...getAttributeChanges(change));
		}
	});
	return changes;
};
