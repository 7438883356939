import { getSelectedIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues';
import { getIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/index.tsx';
import {
	getSprints,
	getSprintsForIssueSources,
	getSprintsForTeam as getSprintsForTeamQuery,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints';
import {
	getTeamsById,
	getTeams,
	getAdditionalTeamsById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export default createStructuredSelector<State, StateProps>({
	selectedIssues: getSelectedIssues,
	issues: getIssues,
	sprints: getSprints,
	sprintsByTeam: getSprintsForTeamQuery,
	teamsById: getTeamsById,
	teams: getTeams,
	sprintsByIssueSources: getSprintsForIssueSources,
	additionalTeamsById: getAdditionalTeamsById,
});
