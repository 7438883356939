import React from 'react';
import StandardButton from '@atlaskit/button/standard-button';
import Icon from '@atlaskit/icon';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalHeader,
	ModalTitle,
	ModalBody,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import refreshSvg from '@atlassian/jira-portfolio-3-portfolio/src/common/icons/refresh.raw.svg';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import { reload } from '@atlassian/jira-portfolio-3-portfolio/src/common/window';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

export default function OptimizationDialog({ closeDialog }: Props) {
	const { formatMessage } = useIntl();

	const refreshButton = (
		<Button onClick={reload} appearance="link" spacing="none">
			{formatMessage(messages.modalRefreshBtn)}
		</Button>
	);

	return (
		<ShortcutScope>
			<ModalDialog autoFocus onClose={closeDialog}>
				<ModalHeader>
					<ModalTitle>{formatMessage(messages.modalHeader)}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles['modal-body']}>
						<Icon label="" dangerouslySetGlyph={refreshSvg} />
						<br />
						<FormattedMessage {...messages.notLatestMessage} />
						<br />
						<FormattedMessage {...messages.refreshMessage} values={{ refreshButton }} />
					</div>
				</ModalBody>
				<ModalFooter>
					<StandardButton appearance="subtle" onClick={closeDialog}>
						{formatMessage(commonMessages.cancel)}
					</StandardButton>
				</ModalFooter>
			</ModalDialog>
		</ShortcutScope>
	);
}
