import type { IntlShape } from '@atlassian/jira-intl';
import { shortDateFormat } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/constants.tsx';
import { formatTimestampWithIntl } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import messages from './messages';

export const getDateString: (
	date: string | number,
	intl: IntlShape,
	dateFormat?: string,
) => string = (date, intl) => {
	if (typeof date === 'number') {
		return formatTimestampWithIntl(intl, date, shortDateFormat);
	}
	return date;
};

export const getStringFromDates = (
	fromDateTimestamp: number | null | undefined,
	toDateTimestamp: number | null | undefined,
	intl: IntlShape,
): string => {
	if (fromDateTimestamp && toDateTimestamp) {
		return `${getDateString(fromDateTimestamp, intl)} – ${getDateString(toDateTimestamp, intl)}`;
	}
	if (fromDateTimestamp) {
		return intl.formatMessage(messages.timescaleFrom, {
			fromDate: getDateString(fromDateTimestamp, intl),
		});
	}
	if (toDateTimestamp) {
		return intl.formatMessage(messages.timescaleTo, {
			toDate: getDateString(toDateTimestamp, intl),
		});
	}
	return intl.formatMessage(messages.timescaleCustom);
};
