import React from 'react';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import Overflowed from '../overflowed';
import type { Props } from './types';

/**
 * A wrapper for row which provides an optional bgColor and highlighter.
 * bgColor is the actual row bg color
 */

function RowContainer({ bgColor, highlighter, children, style = {}, testId }: Props) {
	return (
		<div
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				position: 'relative',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				height: '100%',
			}}
			data-testid={testId}
		>
			<Overflowed
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					...(isDefined(bgColor) && { backgroundColor: bgColor }),
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					pointerEvents: 'none',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					...style,
				}}
			>
				{highlighter}
			</Overflowed>

			{children}
		</div>
	);
}

RowContainer.whyDidYouRender = true;
RowContainer.displayName = 'RowContainer';

export default RowContainer;
