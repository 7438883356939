import React from 'react';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import {
	ContextualAnalyticsData,
	INLINE_DIALOG,
	type Attributes,
} from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux';
import mapDispatchToProps from './command';
import mapStateToProps from './query';
import type { StateProps, DispatchProps, OwnProps, Props } from './types';
import View from './view';

const withAnalyticsContext =
	(
		Component: any, // eslint-disable-line @typescript-eslint/no-explicit-any
	) =>
	({
		analyticsAttributes,
		...rest
	}: Props & {
		analyticsAttributes: Attributes;
	}) => (
		<ContextualAnalyticsData
			sourceName="viewSettings"
			sourceType={INLINE_DIALOG}
			attributes={analyticsAttributes}
		>
			<Component {...rest} />
		</ContextualAnalyticsData>
	);

// It has to be done like this else the class methods are not under the context
const ViewWithAnalyticsContext = withAnalyticsContext(View);

export default connect<StateProps, DispatchProps, OwnProps, State>(
	mapStateToProps,
	mapDispatchToProps,
)(ViewWithAnalyticsContext);
