import { getComponents } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/components';
import { getProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects';
import { getColorByViewSettings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings';
import type { UpdateComponentColourActionPayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/colour-by/actions';
import {
	addComponentColour,
	updateComponentColour,
	removeComponentColour,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/colour-by/actions.tsx';
import type {
	MapDispatchToPropsSimple,
	MapStateToProps,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import { connect } from '@atlassian/jira-react-redux';
import type { DispatchProps, StateProps } from './types';
import View from './view';

const mapStateToProps: MapStateToProps<StateProps> = (state) => ({
	colourByConfiguration: getColorByViewSettings(state),
	components: getComponents(state),
	projectsById: getProjectsById(state),
});

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	addComponentColour: () => {
		dispatch(addComponentColour());
	},
	updateComponentColour: (payload: UpdateComponentColourActionPayload) => {
		dispatch(updateComponentColour(payload));
	},
	removeComponentColour: (payload: UpdateComponentColourActionPayload) => {
		dispatch(removeComponentColour(payload));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
