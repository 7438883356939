import { getCrossProjectVersionsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/cross-project-versions';
import { getSelectedIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues';
import {
	getVersions,
	getVersionsById,
	getSortByHighestRank,
	getSortByLowestRank,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export default createStructuredSelector<State, StateProps>({
	selectedIssues: getSelectedIssues,
	versions: getVersions,
	versionsById: getVersionsById,
	crossProjectVersionsById: getCrossProjectVersionsById,
	sortVersionsByHighestRank: getSortByHighestRank,
	sortVersionsByLowestRank: getSortByLowestRank,
});
