import {
	getMode,
	isReadOnly,
	isArchived,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app';
import { getPlan } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { connect } from '@atlassian/jira-react-redux';
import Toolbar from './toolbar';
import type { StateProps } from './types';
import { PageHeader } from './view';

export const getPlanTitle = (state: State) => getPlan(state).title;

const mapStateToProps = createStructuredSelector<State, {}, StateProps>({
	mode: getMode,
	isReadOnly,
	isArchived,
	plan: getPlan,
});

export default connect(mapStateToProps, {})(PageHeader);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { Toolbar };
