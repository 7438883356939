import type { StagePosAndScale } from './types';

export const SET_STAGE_POSITION_SCALE =
	'state.ui.main.tabs.dependencies.stage-position-scale.SET_STAGE_POSITION_SCALE' as const;

export type SetStagePositionScaleAction = {
	type: typeof SET_STAGE_POSITION_SCALE;
	payload: StagePosAndScale;
};

export const setStagePositionAndScale = (pos: StagePosAndScale): SetStagePositionScaleAction => ({
	type: SET_STAGE_POSITION_SCALE,
	payload: pos,
});
