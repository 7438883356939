import React from 'react';
import Avatar from '@atlaskit/avatar';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip';
import NotInPlan from '../not-in-plan';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

export default function Project(props: Props) {
	const { project, projects } = props;
	const targetProject = projects.find((currentProject) => currentProject.id === project);
	if (targetProject) {
		const { avatarUrl, name } = targetProject;
		return (
			<EllipsedWithTooltip id={project} content={name}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<span className={styles.avatar}>
					<Avatar size="xsmall" src={avatarUrl} />
				</span>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<span className={styles.name}>{name}</span>
			</EllipsedWithTooltip>
		);
	}
	return (
		<div>
			<NotInPlan />
		</div>
	);
}
