import { getSelected } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues';
import { getUserFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira/filters';
import { getQuickSelectOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira/quick-select';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import type { UserFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/title-bar/update-jira/types';
import {
	createStructuredSelector,
	createSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps, OwnProps } from './types';

const getUserFilterValuesPure = (userFilter: UserFilter): string[] => userFilter.value;

const getUserFilterValues = createSelector([getUserFilter], getUserFilterValuesPure);

export default createStructuredSelector<State, OwnProps, StateProps>({
	selectedIssues: getSelected,
	userFilter: getUserFilterValues,
	option: getQuickSelectOption,
});
