import type { Effect } from 'redux-saga';
import { put, select, takeEvery } from 'redux-saga/effects';
import { isReadOnly as getIsReadOnly } from '../../query/app';
import { getFlags } from '../../query/flags';
import {
	ADD_FLAG,
	DISMISS_FLAG,
	pushFlag,
	type FlagAction,
	type DismissFlagAction,
} from '../../state/domain/flags/actions';
import {
	type Flags,
	ISSUE_LIMIT_WARNING,
	PROJECT_LIMIT_WARNING,
	REPARENT_TMP_TO_EXTERNAL_WARNING,
	REPARENT_CMP_TO_TMP_WARNING,
	SORTED_PREVENT_ISSUE_RANKING_WARNING,
} from '../../state/domain/flags/types';
import { updateHiddenFlagDate } from '../../state/domain/hidden-flags/actions';
import { transformDismissedFlagName } from './utils';

const EDIT_MODE_ONLY_FLAGS: Flags = [{ key: ISSUE_LIMIT_WARNING }, { key: PROJECT_LIMIT_WARNING }];

const RANKING_WARNING_FLAGS: Flags = [
	{ key: SORTED_PREVENT_ISSUE_RANKING_WARNING },
	{ key: REPARENT_TMP_TO_EXTERNAL_WARNING },
	{ key: REPARENT_CMP_TO_TMP_WARNING },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doPushFlag({ payload }: FlagAction): Generator<Effect, void, any> {
	const isReadOnly = yield select(getIsReadOnly);
	const allFlags = yield select(getFlags);
	const { key } = payload;
	let shouldPushFlag = false;

	if (!isReadOnly) {
		shouldPushFlag = true;
	} else if (!EDIT_MODE_ONLY_FLAGS.some(({ key: editModeOnlyKey }) => editModeOnlyKey === key)) {
		shouldPushFlag = true;
	}
	if (
		RANKING_WARNING_FLAGS.some(({ key: rankingWarningKey }) => rankingWarningKey === key) &&
		!allFlags.includes(key)
	) {
		shouldPushFlag = true;
	}

	if (shouldPushFlag) {
		yield put(pushFlag(payload));
	}
}

// When a flag is dismissed, we should add it to the list of hidden flags and update its dismissal date
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doHideFlag({ payload }: DismissFlagAction): Generator<Effect, void, any> {
	const flagName = transformDismissedFlagName(payload.key);
	if (flagName) {
		yield put(updateHiddenFlagDate({ key: flagName }));
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* watchFlag(): Generator<Effect, void, any> {
	yield takeEvery(ADD_FLAG, doPushFlag);
	yield takeEvery(DISMISS_FLAG, doHideFlag);
}
