import { change } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/filters';
import {
	DATE_RANGE_FILTER_ID,
	type DateRangeFilterValue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { DispatchProps } from './types';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	onDateRangeChange: (payload: DateRangeFilterValue) => {
		dispatch(change({ id: DATE_RANGE_FILTER_ID, value: payload }));
	},
});

export default mapDispatchToProps;
