import type {
	RELEASE,
	CROSS_PROJECT_RELEASE,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';

export const OVERVIEW_RELEASES = 'OVERVIEW_RELEASES' as const;

export type Mode = typeof RELEASE | typeof CROSS_PROJECT_RELEASE | typeof OVERVIEW_RELEASES;

export type ReleaseBar = Readonly<{
	mode: Mode;
	isFlyoutOpen: boolean;
	versionIds: string[];
	prevVersionIds: string[];
	highlightedVersionIds: Set<string>;
}>;
