import type { Effect } from 'redux-saga';
import { fork, take, all, put } from 'redux-saga/effects';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { AFTER_INITIAL_LOAD } from '../actions';
import { viewRoadmap } from '../roadmap';

export const SEND_VIEW_ROADMAP_SCREEN = 'command.analytics.SEND_VIEW_ROADMAP_SCREEN' as const;

export type SendViewRoadmapScreenAnalyticsEventAction = {
	type: typeof SEND_VIEW_ROADMAP_SCREEN;
	payload: UIAnalyticsEvent;
};

export const sendViewRoadmapScreenAnalyticsEvent = (payload: UIAnalyticsEvent) => ({
	type: SEND_VIEW_ROADMAP_SCREEN,
	payload,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchSendViewRoadmapScreen(): Generator<Effect, void, any> {
	// The viewRoadmap analytics event needs plan Id and scenario Id to be available in the store.
	// Here we are waiting for plan load to complete before sending the analytics event so all the
	// required data is available in the store.
	const [{ payload }] = yield all([take(SEND_VIEW_ROADMAP_SCREEN), take(AFTER_INITIAL_LOAD)]);
	yield put(viewRoadmap(payload));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, void, any> {
	yield fork(watchSendViewRoadmapScreen);
}
