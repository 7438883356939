import React from 'react';
import { differenceInDays } from 'date-fns';
import Avatar from '@atlaskit/avatar';
import Tooltip from '@atlaskit/tooltip';
import { useIntl, type IntlShape } from '@atlassian/jira-intl';
import { getRelativeTimeAndUnit } from '@atlassian/jira-intl/src/v2/formatted-relative.tsx';
import { shortDateFormat } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/constants.tsx';
import { formatTimestampWithIntl } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

function LastModified(props: Props) {
	const intl = useIntl();
	const { personId, title, date, avatarUrl } = props;
	const daysDifference = differenceInDays(new Date(), date);

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const formatRelative = (intl: IntlShape, date: number) => {
		const { time, unit } = getRelativeTimeAndUnit(date);
		return intl.formatRelativeTime(time, unit);
	};

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const getDate = (intl: IntlShape, value: number) =>
		formatTimestampWithIntl(intl, value, shortDateFormat);

	const dateDisplayString = daysDifference > 7 ? getDate(intl, date) : formatRelative(intl, date);
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.container}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<span className={styles.label} title={dateDisplayString}>
				{dateDisplayString}
			</span>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.avatar}>
				<Tooltip content={title || personId}>
					<Avatar size="xsmall" src={avatarUrl} name={title || personId} />
				</Tooltip>
			</div>
		</div>
	);
}

export default LastModified;
