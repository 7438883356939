import React from 'react';
import { isSameDay } from 'date-fns';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { startOfUtcDay } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import type { SprintUnit } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { SCHEDULE_MODE } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import RowContainer from '../row/container/index.tsx';
import messages from './messages';
import SprintsPlaceholderLegacy from './placeholder/view-legacy';
import Placeholder from './placeholder/view.tsx';
import Sprint from './sprint';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

const Sprints = ({
	plan,
	sprintIdsWithDatesIsInconsistent = [],
	filteredIds,
	showWarning,
	teamObject,
	team,
	streams,
	timelineRange,
	getStreamsUnits,
	zoomLevel,
	teamsWithSprints,
}: Props) => {
	const { formatMessage } = useIntl();

	/**
	 * Checks if the specified team has associated issues with sprints.
	 *
	 * @param team - The team to check for associated sprints.
	 * @returns A boolean indicating whether the team has sprints.
	 */
	const hasSprints = teamsWithSprints && teamsWithSprints.has(team);

	if (!isDefined(teamObject)) {
		return fg('eou_open_team_dialog_in_timeline') ? (
			<Placeholder teamId={team} plan={plan} hasSprints={hasSprints} />
		) : null;
	}

	const { issueSource, schedulingMode, id } = teamObject;
	if (!isDefined(issueSource)) {
		return fg('eou_open_team_dialog_in_timeline') ? (
			<Placeholder team={teamObject} teamId={team} plan={plan} hasSprints={hasSprints} />
		) : (
			<SprintsPlaceholderLegacy>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.message}>
					{formatMessage(
						SCHEDULE_MODE.scrum === schedulingMode
							? messages.noIssueSourcesScrumTeam
							: messages.noIssueSourcesKanbanTeam,
					)}
				</div>
			</SprintsPlaceholderLegacy>
		);
	}

	const isSprintFiltered = (sprint: SprintUnit) =>
		!!filteredIds.length && !filteredIds.includes(sprint.id);

	// a sprint A is contiguous with a next sprint B if:
	// - sprint A is not the last sprint of the stream; AND
	// - sprint B is not a gap; AND
	// - sprint B is not filtered out; AND
	// - sprint A ends on the same day that sprint B starts
	const isSprintContiguousWithNextSprint = (
		sprint: SprintUnit,
		index: number,
		stream: Array<SprintUnit>,
	) =>
		index < stream.length - 1 &&
		stream[index + 1].id !== 'GAP' &&
		!isSprintFiltered(stream[index + 1]) &&
		isSameDay(
			startOfUtcDay(sprint.rawEndDate || sprint.endDate),
			startOfUtcDay(stream[index + 1].rawStartDate || stream[index + 1].startDate),
		);

	const renderStream = (stream: Array<SprintUnit>, index: number) => (
		<div
			data-name={`team-${(teamObject && teamObject.id) || ''}-sprint-stream-${index}`}
			key={index}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
			className={styles.sprintLabels}
		>
			{
				// eslint-disable-next-line @typescript-eslint/no-shadow
				stream.map((sprint, index) => (
					<Sprint
						isContiguousWithNextSprint={isSprintContiguousWithNextSprint(sprint, index, stream)}
						isFiltered={isSprintFiltered(sprint)}
						isInconsistent={sprintIdsWithDatesIsInconsistent.indexOf(sprint.id) > -1}
						key={`${sprint.startDate}-${sprint.endDate}`}
						plan={plan}
						showWarning={showWarning}
						sprint={sprint}
						teamObject={teamObject}
						zoomLevel={zoomLevel}
					/>
				))
			}
		</div>
	);

	const sprintTableRow = (
		getStreamsUnits ? getStreamsUnits(timelineRange, teamObject) : streams
	).map(renderStream);

	return zoomLevel ? (
		<RowContainer testId={`team-${id}-sprint-header-row`}>{sprintTableRow}</RowContainer>
	) : (
		<>{sprintTableRow}</>
	);
};

export default Sprints;
