import type { Effect } from 'redux-saga';
import { fork, takeEvery, put, call, select } from 'redux-saga/effects';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch';
import { getPlan } from '../../query/plan';
import * as issuePriorityActions from '../../state/domain/issue-priorities/actions';
import * as projectsActions from '../../state/domain/projects/actions';
import * as domainUtil from '../../state/domain/util';
import { parseError } from '../api';
import { genericError } from '../errors';
import { urls } from './api';
import type { GetProjectInfoByIdsResponse } from './types';

const GET_PROJECTS_FOR_IDS = 'command.project.GET_PROJECTS_FOR_IDS' as const;

type GetProjectsForIdsPayload = number[];
type GetProjectsForIdsAction = {
	type: typeof GET_PROJECTS_FOR_IDS;
	payload: GetProjectsForIdsPayload;
};

export const getProjectsForIds = (payload: GetProjectsForIdsPayload): GetProjectsForIdsAction => ({
	type: GET_PROJECTS_FOR_IDS,
	payload,
});

export function* doGetProjectInfoForIds({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: GetProjectsForIdsAction): Generator<Effect, void, any> {
	const { id: planId, currentScenarioId: scenarioId } = yield select(getPlan);

	const body = { planId, scenarioId, ids: payload };

	const response = yield call(fetch, urls.projectInfoForIds, {
		method: 'POST',
		body,
	});

	if (response.ok) {
		const projectInfoByIdResponse: GetProjectInfoByIdsResponse = yield call(
			response.json.bind(response),
		);

		const { projects, issuePriorityInformation } = projectInfoByIdResponse;

		// store projects in the state
		yield put(projectsActions.add(domainUtil.prepareProjects(projects, issuePriorityInformation)));
		// also store issue priority information if there was any missing
		yield put(issuePriorityActions.add(issuePriorityInformation.issuePriorities));
	} else {
		yield put(
			genericError({
				...parseError(response, yield call(response.text.bind(response))),
				requestInfo: {
					url: urls.projectInfoForIds,
					type: 'POST',
					status: response.status,
					body,
				},
			}),
		);
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchGetProjectsForIds(): Generator<Effect, any, any> {
	yield takeEvery(GET_PROJECTS_FOR_IDS, doGetProjectInfoForIds);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchGetProjectsForIds);
}
