import { getAutoSchedulerEnabled } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { connect } from '@atlassian/jira-react-redux';
import View, { type StateProps } from './view';

const mapStateToProps = createStructuredSelector<State, {}, StateProps>({
	isAutoSchedulerEnabled: getAutoSchedulerEnabled,
});

export default connect(mapStateToProps, {})(View);
