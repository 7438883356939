import type { ChangeCustomDatesActionPayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/time-scale/actions';
import {
	CHANGE_OPTION,
	timeScaleCustomDatesChange,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/time-scale/actions.tsx';
import { updateFlyout } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/timeline/release-bar/actions.tsx';
import type { Mode as MarkerMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/timeline/release-bar/types.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { DispatchProps } from './types';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	updateFlyout: (
		versionIds: string[],
		type: MarkerMode,
		prevVersionIds: string[],
		dateRange?: ChangeCustomDatesActionPayload | null,
	) => {
		dispatch(updateFlyout(versionIds, type, prevVersionIds));
		if (dateRange) {
			dispatch(timeScaleCustomDatesChange(dateRange));
			dispatch({
				type: CHANGE_OPTION,
				payload: 'custom',
			});
		}
	},
});

export default mapDispatchToProps;
