import { type MouseEvent, useState, useRef, useCallback, useEffect } from 'react';
import {
	monitorForDependencyCreate,
	draggableForDependencyCreate,
} from '@atlassian/jira-portfolio-3-dependency-line-drag-create/src/ui/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types';
import { useDependenciesFlyout } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/dependencies-flyout';
import { useDndItem } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/timeline/schedule/dependency-lines/dnd-item/provider/index.tsx';
import { useDepLinesDrag } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/timeline/schedule/dependency-lines/drag-preview/provider/index.tsx';
import { useDndScrolling } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/timeline/schedule/dnd-scrolling/utils.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { useRow } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/row/index.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Props } from './types';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import './styles.module.css';

const DND_FLAG = 'DEPENDENCY_MARKER';
const MIN_DRAG_DISTANCE = 2;

type UseDragHandlingParams = {
	direction: Props['direction'];
	isEditMode: Props['isEditMode'];
	onDrop: () => void;
};

/** Returns the event handler for when the user click and drag the issue badge. */
export const useDragHandling = ({ direction, isEditMode, onDrop }: UseDragHandlingParams) => {
	const [, { register: registerDndScrolling, deregister: deregisterDndScrolling }] =
		useDndScrolling();

	const { setDragSource } = useDepLinesDrag();
	const { id: dndItemId } = useDndItem();

	const handleMouseDown = useCallback(
		(e: MouseEvent<HTMLDivElement>) => {
			if (!isEditMode) {
				return;
			}

			let dragStarted = false;
			const mousedownOffset = { x: e.clientX, y: e.clientY };

			// eslint-disable-next-line @typescript-eslint/no-shadow
			const handleMouseMove = (e: MouseEventInit) => {
				// Only start dragging when cursor has moved at least 2 pixels
				if (
					!dragStarted &&
					isDefined(e.clientX) &&
					isDefined(e.clientY) &&
					(Math.abs(e.clientX - mousedownOffset.x) > MIN_DRAG_DISTANCE ||
						Math.abs(e.clientY - mousedownOffset.y) > MIN_DRAG_DISTANCE)
				) {
					setDragSource({
						rowId: dndItemId,
						direction,
					});
					registerDndScrolling(DND_FLAG);
					dragStarted = true;
				}

				/* This is to communicate with dependency-lines/drag-preview/layer component
				 * However this is not the pattern we want, we gonna replace this in JPO-16799
				 */
				const event = new CustomEvent('customized.dragmove', {
					detail: { clientX: e.clientX, clientY: e.clientY },
				});

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.dispatchEvent(event);
			};

			const handleMouseUp = () => {
				onDrop();

				if (dragStarted) {
					setDragSource(undefined);
					deregisterDndScrolling(DND_FLAG);
					dragStarted = false;
				}

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				document.removeEventListener('mousemove', handleMouseMove);

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				document.removeEventListener('mouseup', handleMouseUp);

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				document.removeEventListener('keydown', handleEscapeKey);
			};

			// eslint-disable-next-line @typescript-eslint/no-shadow
			const handleEscapeKey = (e: KeyboardEvent) => {
				if (e.key === 'Escape') {
					handleMouseUp();
				}
			};

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.addEventListener('mousemove', handleMouseMove);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.addEventListener('mouseup', handleMouseUp);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.addEventListener('keydown', handleEscapeKey);
		},
		[
			isEditMode,
			setDragSource,
			dndItemId,
			direction,
			registerDndScrolling,
			onDrop,
			deregisterDndScrolling,
		],
	);

	return { handleMouseDown };
};

type UseDropHandling = {
	issueCountByLinkType: Props['issueCountByLinkType'];
	issueLinkType: Props['issueLinkType'];
};

/** Returns the event handler for when the user stop dragging by release the mouse. */
export const useDropHandling = ({ issueCountByLinkType, issueLinkType }: UseDropHandling) => {
	const { handleDrop } = useDepLinesDrag();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return useCallback(() => {
		const issueLinkAdded = handleDrop({ issueLinkType });

		if (issueLinkAdded) {
			const [actionSubject, action] =
				PRODUCT_ANALYTICS_EVENT_NAMES.ADDED_ISSUE_DEPENDENCY_LINK.split(' ');
			fireUIAnalytics(
				createAnalyticsEvent({
					action,
					actionSubject,
				}),
				{
					source: 'dnd',
					type: issueLinkType,
					typeCount: issueCountByLinkType[issueLinkType] || 0,
				},
			);
		}
	}, [createAnalyticsEvent, handleDrop, issueCountByLinkType, issueLinkType]);
};

/** Handles the dependency line drag preview. */
export const useDragPreview = (
	element: HTMLElement | null,
	containerWidth: number,
	position: Props['position'],
	issueBarPosition: Props['issueBarPosition'],
	issueLinkType: number,
) => {
	const { row } = useRow();
	const [hasDragPreview, setHasDragPreview] = useState(false);

	const hasDragPreviewRef = useRef(false);
	hasDragPreviewRef.current = hasDragPreview;

	useEffect(
		() =>
			monitorForDependencyCreate({
				onDrag: () => {
					if (!hasDragPreviewRef.current) {
						setHasDragPreview(true);
					}
				},
				onDrop: () => setHasDragPreview(false),
			}),
		[],
	);

	useEffect(() => {
		if (!element) {
			return;
		}

		return draggableForDependencyCreate({
			element,
			getInitialData: () => ({
				row,
				position,
				issueLinkType,
				horizontalScrollingContainerX:
					containerWidth *
					(position === 'start'
						? issueBarPosition.leftPositionPercentage / 100
						: 1 - issueBarPosition.rightPositionPercentage / 100),
			}),
		});
	}, [
		element,
		containerWidth,
		row,
		position,
		issueLinkType,
		issueBarPosition.leftPositionPercentage,
		issueBarPosition.rightPositionPercentage,
	]);

	return hasDragPreview;
};

type UseIsActiveParams = {
	issue: Props['issue'];
	direction: Props['direction'];
	force: boolean;
};

/** returns whether the badge should be active or not. */
export const useIsActive = ({ issue, direction, force }: UseIsActiveParams) => {
	const { payload } = useDependenciesFlyout();

	// The badge should be active when it's forced be active (from parent)
	// or the current flyout is the one triggered from this marker.
	if (force) {
		return true;
	}

	return payload?.issue.id === issue.id && payload?.direction === direction;
};
