import { createStore, createHook, createContainer } from '@atlassian/react-sweet-state';
import type { State } from './types';

const store = createStore<State, {}>({
	initialState: {
		row: -1,
		column: -1,
		colSpan: 1,
	},
	actions: {},
});

export const CellProvider = createContainer(store, {
	onInit:
		() =>
		({ setState }, state: State) =>
			setState(state),
	onUpdate:
		() =>
		({ setState }, state: State) =>
			setState(state),
});

export const useCell = createHook(store, {
	selector: ({ row, column, colSpan }) => ({ row, column, colSpan }),
});

export const useCellColumn = createHook(store, {
	selector: ({ column, colSpan }) => ({ column, colSpan }),
});
