import type { Dispatch } from 'redux';
import {
	clear,
	change,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/filters';
import {
	CROSS_PROJECT_RELEASE_FILTER_ID,
	RELEASE_FILTER_ID,
	type AttributeFilterValue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { DispatchProps, FilterId } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (dispatch: Dispatch<any>): DispatchProps => ({
	changeReleaseFilter: (id: FilterId, payload: AttributeFilterValue) => {
		dispatch(change({ id, value: payload }));
	},
	clearReleaseFilter: () => {
		dispatch(clear(CROSS_PROJECT_RELEASE_FILTER_ID));
		dispatch(clear(RELEASE_FILTER_ID));
	},
});
