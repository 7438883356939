import type { View } from './types';

export const RESET = 'state.domain.views.RESET' as const;
export const ADD_VIEW = 'state.domain.views.ADD_VIEW' as const;
export const SET_ACTIVE_VIEW = 'state.domain.views.SET_ACTIVE_VIEW' as const;
export const UPDATE_VIEW = 'state.domain.views.UPDATE_VIEW' as const;
export const MODIFY_VIEW = 'state.domain.views.MODIFY_VIEW' as const;
export const DISCARD_CHANGES = 'state.domain.views.DISCARD_CHANGES' as const;
export const SAVE_VIEW = 'state.domain.views.SAVE_VIEW' as const;
export const CHANGE_ID = 'state.domain.views.CHANGE_ID' as const;
export const REMOVE_VIEW = 'state.domain.views.REMOVE_VIEW' as const;
export const MARK_AS_DEFAULT_VIEW = 'state.domain.views.MARK_AS_DEFAULT_VIEW' as const;

type ViewId = View['id'];

export type ResetPayload = View[];
export type AddViewPayload = View;
export type SetActiveViewPayload = {
	id: ViewId;
};
export type UpdateViewPayload = Partial<View>;
export type ModifyViewPayload = {
	id: ViewId;
	preferences: View['preferences'];
};

export type SaveViewPayload = {
	id: ViewId;
	version: View['version'];
	name: View['name'];
};

export type ResetAction = {
	type: typeof RESET;
	payload: ResetPayload;
};

export type AddViewAction = {
	type: typeof ADD_VIEW;
	payload: AddViewPayload;
};

export type SetActiveViewAction = {
	type: typeof SET_ACTIVE_VIEW;
	payload: SetActiveViewPayload;
};

export type UpdateViewAction = {
	type: typeof UPDATE_VIEW;
	payload: UpdateViewPayload;
};

export type ModifyViewAction = {
	type: typeof MODIFY_VIEW;
	payload: ModifyViewPayload;
};

export type DiscardChangesAction = {
	type: typeof DISCARD_CHANGES;
	payload: {
		id: ViewId;
	};
};

export type SaveViewAction = {
	type: typeof SAVE_VIEW;
	payload: SaveViewPayload;
};

export type ChangeIdAction = {
	type: typeof CHANGE_ID;
	payload: {
		previous: ViewId;
		next: ViewId;
	};
};

export type RemoveViewAction = {
	type: typeof REMOVE_VIEW;
	payload: {
		id: ViewId;
	};
};

export type MarkAsDefaultViewAction = {
	type: typeof MARK_AS_DEFAULT_VIEW;
	payload: {
		id: ViewId;
	};
};

export const reset = (payload: ResetPayload): ResetAction => ({
	type: RESET,
	payload,
});

export const addView = (payload: AddViewPayload): AddViewAction => ({
	type: ADD_VIEW,
	payload,
});

export const setActiveView = (payload: SetActiveViewPayload): SetActiveViewAction => ({
	type: SET_ACTIVE_VIEW,
	payload,
});

export const updateView = (payload: UpdateViewPayload): UpdateViewAction => ({
	type: UPDATE_VIEW,
	payload,
});

export const modifyView = (payload: ModifyViewPayload): ModifyViewAction => ({
	type: MODIFY_VIEW,
	payload,
});

export const discardChanges = (id: ViewId): DiscardChangesAction => ({
	type: DISCARD_CHANGES,
	payload: { id },
});

export const saveView = (
	id: ViewId,
	nextVersion: View['version'],
	name: View['name'],
): SaveViewAction => ({
	type: SAVE_VIEW,
	payload: { id, version: nextVersion, name },
});

export const changeId = (previous: ViewId, next: ViewId): ChangeIdAction => ({
	type: CHANGE_ID,
	payload: { previous, next },
});

export const removeView = (id: ViewId) => ({
	type: REMOVE_VIEW,
	payload: { id },
});

export const markAsDefaultView = (id: ViewId) => ({
	type: MARK_AS_DEFAULT_VIEW,
	payload: { id },
});
