import {
	clearInlineCreate,
	startInlineCreate,
	inlineCreateIssue,
	type StartInlineCreateActionPayload,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/inline-create';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { DispatchProps } from './types';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	createSuggestedIssue: (summary: string, description: string) => {
		dispatch(inlineCreateIssue(summary, description));
		dispatch(clearInlineCreate());
	},
	startInlineCreate: (payload: StartInlineCreateActionPayload) => {
		dispatch(startInlineCreate(payload));
	},
});

export default mapDispatchToProps;
