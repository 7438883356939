import { getEnrichedHierarchyLevels } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/hierarchy';
import { getFilteredIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export const mapStateToProps = createStructuredSelector<State, {}, StateProps>({
	enrichedHierarchyLevels: getEnrichedHierarchyLevels,
	filteredIssues: getFilteredIssues,
});
