import type { Resource } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import type { Team } from './types';

export const ADD = 'state.domain.teams.ADD' as const;
export const RESET = 'state.domain.teams.RESET' as const;
export const REMOVE = 'state.domain.teams.REMOVE' as const;
export const UPDATE = 'state.domain.teams.UPDATE' as const;
export const UPDATE_RESOURCE = 'state.domain.teams.UPDATE_RESOURCE' as const;
export const ADD_MEMBER = 'state.domain.teams.ADD_MEMBER' as const;
export const REMOVE_MEMBER = 'state.domain.teams.REMOVE_MEMBER' as const;
export const SET_SHARED = 'state.domain.teams.SET_SHARED' as const;

export type AddActionPayload = Team;
export type ResetActionPayload = Team[];
export type UpdateResourceActionPayload = {
	itemKey: string;
	resourceId: string;
	teamId: string;
};
export type RemoveActionPayload = {
	id: string;
	action: 'COMMIT' | 'REVERT' | 'DELETE';
};
export type UpdateActionPayload = {
	teamId: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	patch: any;
};
export type AddMemberActionPayload = {
	teamId: string;
	resource: Resource;
};

export type RemoveMemberActionPayload = {
	teamId: string;
	resource: Resource;
};

export type AddAction = {
	type: typeof ADD;
	payload: AddActionPayload;
};

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export type RemoveAction = {
	type: typeof REMOVE;
	payload: RemoveActionPayload;
};

export type UpdateAction = {
	type: typeof UPDATE;
	payload: UpdateActionPayload;
};

export type UpdateResourceAction = {
	type: typeof UPDATE_RESOURCE;
	payload: UpdateResourceActionPayload;
};

export type AddMemberAction = {
	type: typeof ADD_MEMBER;
	payload: AddMemberActionPayload;
};

export type RemoveMemberAction = {
	type: typeof REMOVE_MEMBER;
	payload: RemoveMemberActionPayload;
};

export type SetSharedAction = {
	type: typeof SET_SHARED;
	teamId: string;
	externalId: string;
};

export const add = (payload: AddActionPayload) => ({
	type: ADD,
	payload,
});

export const reset = (payload: ResetActionPayload) => ({
	type: RESET,
	payload,
});

export const remove = (payload: RemoveActionPayload) => ({
	type: REMOVE,
	payload,
});

export const update = (payload: UpdateActionPayload) => ({
	type: UPDATE,
	payload,
});

export const updateResource = (payload: UpdateResourceActionPayload) => ({
	type: UPDATE_RESOURCE,
	payload,
});

export const addMember = (payload: AddMemberActionPayload) => ({
	type: ADD_MEMBER,
	payload,
});

export const removeMember = (payload: RemoveMemberActionPayload) => ({
	type: REMOVE_MEMBER,
	payload,
});

export const setShared = (teamId: string, externalId: string): SetSharedAction => ({
	type: SET_SHARED,
	teamId,
	externalId,
});
