import type { JiraSuggestedChildIssueStatusType } from '@atlassian/jira-relay/src/__generated__/issueBreakdownNewForPortfolio3Subscription.graphql';
import messages from './messages';

export const getLoadingStatusMessage = (status: JiraSuggestedChildIssueStatusType | null) => {
	switch (status) {
		case 'REFINING_SUGGESTED_ISSUES':
			return messages.statusRefiningSuggestedIssuesNonFinal;
		case 'REMOVING_DUPLICATE_ISSUES':
			return messages.statusRemovingDuplicateIssuesNonFinal;
		case 'RETRIEVING_SOURCE_CONTEXT':
			return messages.statusRetrievingSourceContextNonFinal;
		case 'SUGGESTING_INITIAL_ISSUES':
			return messages.statusSuggestingInitialIssuesNonFinal;
		case 'REFORMATTING_ISSUES':
			return messages.statusReformatingIssuesNonFinal;
		default:
			return messages.statusGeneratingDefaultMessageNonFinal;
	}
};
