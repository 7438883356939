import { createMigrate, incremental } from '../common/migrate';
import type { RollupSettingsStateV0 } from './types';

export default createMigrate(
	...incremental(
		{
			V0: function fromV0ToV0(prevState: RollupSettingsStateV0): RollupSettingsStateV0 {
				return prevState;
			},
		},
		{ prefix: 'rollupSetting', from: 'V0' },
	),
);
