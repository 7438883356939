import { updatePlan } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/plan';
import type { AutoScheduleConfiguration } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { DispatchProps } from './types';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	updateAutoScheduleConfiguration: (payload: Partial<AutoScheduleConfiguration>) => {
		dispatch(
			updatePlan({
				autoScheduleConfiguration: payload,
			}),
		);
	},
});

export default mapDispatchToProps;
