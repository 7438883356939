import React, { type ReactNode } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { AssociatedIssuesContainer } from '@atlassian/jira-portfolio-3-associated-issues/src/controllers/index.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { AssociatedIssuesChangeboardingFlag } from './associated-issues-changeboarding-flag';
import AssociatedIssuesSyncWrapper from './associated-issues-sync-wrapper';

// Use the same pattern as for Goals for consistency (use AssociatedIssuesSyncWrapper for sync state)
const Wrapper = ({
	associatedIssueIds,
	children,
}: {
	associatedIssueIds: IssueId[] | undefined;
	children: ReactNode;
}) => {
	const cloudId = useCloudId();
	const { showFlag } = useFlagsService();
	const intlApi = useIntl();

	return (
		<AssociatedIssuesContainer
			associatedIssueIds={associatedIssueIds}
			cloudId={cloudId}
			showFlag={showFlag}
			intlApi={intlApi}
		>
			<AssociatedIssuesSyncWrapper />
			{children}
			{ff('polaris.possibility-of-updating-ideas-in-plans') ? (
				<AssociatedIssuesChangeboardingFlag />
			) : null}
		</AssociatedIssuesContainer>
	);
};

export default Wrapper;
