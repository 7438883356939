import React from 'react';
import { ONE_DAY } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import {
	useHorizontalScrolling,
	useTimelineRuler,
} from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import { ZOOM_LEVELS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import type { Props } from './types';
import { getDaysInTimelineView } from './utils';
import NoDatesRowView from './view';

const usePreviewSizeInMs = () => {
	const [{ zoomLevel, viewport }] = useHorizontalScrolling();
	const [{ pxToMs, msToPx }] = useTimelineRuler();

	// returns the cursor offset from today in ms
	// e.g. if we're in months and this func returns -400 we know we need to be -400ms from today
	const getCursorOffsetFromToday = (relativeMouseX: number) => {
		const viewportOffsetInPx = msToPx(Math.abs(viewport.offset));

		let cursorOffset;
		// relativeMouseX is no longer relative, need to calculate it
		if (relativeMouseX > viewport.width) {
			// if mouse is to the right of initial viewport
			cursorOffset = relativeMouseX - viewportOffsetInPx;
		} else {
			// if mouse is to the left of initial viewport
			cursorOffset = relativeMouseX + viewportOffsetInPx;
		}

		const offset = viewport.offset + pxToMs(cursorOffset);
		return offset;
	};

	const getMousedOverDate = (relativeMouseX: number): Date => {
		const today = Date.now();
		const cursorOffsetInMs = getCursorOffsetFromToday(relativeMouseX);

		const ms = today + cursorOffsetInMs;
		const date = new Date(ms);
		return date;
	};

	const getPreviewSize = (relativeMouseX: number) => {
		// months has ~5px of padding between left edge of the bar and mouse cursor
		// quarters and years has ~1px
		const relativeAccountForPadding =
			zoomLevel === ZOOM_LEVELS.MONTHS ? relativeMouseX - 5 : relativeMouseX - 1;
		const mousedOverDate = getMousedOverDate(relativeAccountForPadding);

		return getDaysInTimelineView(mousedOverDate, zoomLevel) * ONE_DAY - 1;
	};

	return getPreviewSize;
};

export default function NoDatesRowViewFunctional(props: Props) {
	const getPreviewSize = usePreviewSizeInMs();

	return <NoDatesRowView {...props} getPreviewSizeInMs={getPreviewSize} />;
}
