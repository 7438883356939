import { defineMessages } from '@atlassian/jira-intl';

export const shortDateMessages = defineMessages({
	days: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.days',
		defaultMessage: '{days}d',
		description: 'short period description in days',
	},
	weeks: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.weeks',
		defaultMessage: '{weeks}w',
		description: 'short period description in weeks',
	},
	months: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.months',
		defaultMessage: '{months}m',
		description: 'short period description in months',
	},
	years: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.years',
		defaultMessage: '{years}y',
		description: 'short period description in years',
	},
	notClosedBeyondTargetEndDate: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.not-closed-beyond-target-end-date',
		defaultMessage:
			'This issue is still unresolved after the target end date. Check if the status needs updating, or if more time is needed for the issue.',
		description: 'Issue is not closed past its target end date',
	},
});

export const headerMessages = defineMessages({
	// Warning Center warnings
	misconfiguredChildren: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.misconfigured-children',
		defaultMessage: 'Children have warnings',
		description: 'misconfigured children warning',
	},
	// Remove when FF com.atlassian.rm.jpo.jpo3cloud.revamp-warning-centre is removed
	endsAfterParent: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.ends-after-parent',
		defaultMessage: 'Ends after parent',
		description: 'issue ends after parent warning',
	},
	childIssueEndsAfterParent: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.child-issue-ends-after-parent',
		defaultMessage: 'Child issue ends after its parent issue',
		description: 'issue ends after parent warning',
	},
	// Remove when FF com.atlassian.rm.jpo.jpo3cloud.revamp-warning-centre is removed
	startsBeforeParent: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.starts-before-parent',
		defaultMessage: 'Starts before parent',
		description: 'issue starts before parent warning',
	},
	childIssueStartsBeforeParent: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.child-issue-starts-before-parent',
		defaultMessage: 'Child issue starts before its parent issue',
		description: 'issue starts before parent warning',
	},
	// Remove when FF com.atlassian.rm.jpo.jpo3cloud.revamp-warning-centre is removed
	dateBeyondDueDate: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.date-beyond-due-date',
		defaultMessage: 'Issue start date or end date set beyond the due date',
		description: 'issue start date or end date is set after the due date',
	},
	dateAfterDueDate: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.date-after-due-date',
		defaultMessage: 'Issue starts or ends after its due date',
		description: 'issue start date or end date is set after the due date',
	},
	// Remove when FF com.atlassian.rm.jpo.jpo3cloud.revamp-warning-centre is removed
	notClosedBeyondInferredTargetEndDate: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.not-closed-beyond-inferred-target-end-date',
		defaultMessage: 'Unresolved issue after inferred end date',
		description: 'Issue is not closed past its inferred target end date',
	},
	openIssuePastInferredEndDate: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.open-issue-past-inferred-end-date',
		defaultMessage: 'Open issue has passed its inferred end date',
		description: 'Issue is not closed past its inferred target end date',
	},
	// Remove when FF com.atlassian.rm.jpo.jpo3cloud.revamp-warning-centre is removed
	notClosedBeyondEndDate: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.not-closed-beyond-end-date',
		defaultMessage: 'Unresolved issue after end date',
		description: 'Issue is not closed past its end date',
	},
	openIssuePastAssignedEndDate: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.open-issue-past-assigned-end-date',
		defaultMessage: 'Open issue has passed its assigned end date',
		description: 'Issue is not closed past its end date',
	},
	// Remove when FF com.atlassian.rm.jpo.jpo3cloud.revamp-warning-centre is removed
	dateOutsideOfSprint: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.date-outside-of-sprint',
		defaultMessage: 'Issue dates outside of assigned sprint',
		description: 'Issue date is set outside of sprint',
	},
	issueScheduledOutsideOfSprint: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.issue-scheduled-outside-of-sprint',
		defaultMessage: 'Issue scheduled outside of its assigned sprint',
		description: 'Issue date is set outside of sprint',
	},
	// Remove when FF com.atlassian.rm.jpo.jpo3cloud.revamp-warning-centre is removed
	issueAssignedKanbanTeamAndSprint: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.issue-assigned-kanban-team-and-sprint',
		defaultMessage: 'Issue assigned to a Sprint and a Kanban team',
		description: 'Issue has been given both a sprint and a Kanban team',
	},
	kanbanIssueAssignedToSprint: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.kanban-issue-assigned-to-sprint',
		defaultMessage: 'Kanban issue assigned to a sprint',
		description: 'Issue has been given both a sprint and a Kanban team',
	},
	startDateAfterEndDate: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.start-date-after-end-date',
		defaultMessage: 'Issue start date is after its end date',
		description: 'Issue start date is set to after its end date',
	},
	// Scheduler warnings
	unknown: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.unknown',
		defaultMessage: 'There was an error during scheduling',
		description: 'an unexpected scheduler warning',
	},
	notFullyScheduled: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.not-fully-scheduled',
		defaultMessage:
			'This issue is partially scheduled because the assigned people have limited availability',
		description: 'not fully scheduled scheduler warning',
	},
	horizonReached: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.horizon-reached',
		defaultMessage:
			"Your schedule can't be calculated properly because it exceeds the planning horizon",
		description: 'horizon reached scheduler warning',
	},
	releaseConflict: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.release-conflict',
		defaultMessage:
			"This issue can't be scheduled because this issue and its sub-tasks are assigned to different releases",
		description: 'release conflict scheduler warning',
	},
	assigneeIgnored: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.assignee-ignored',
		defaultMessage: "This issue's assignee is ignored because they aren't part of the plan",
		description: 'assignee ignored scheduler warning',
	},
	externalTeam: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.external-team',
		defaultMessage:
			"This issue can't be scheduled properly because the assigned team isn't present in your plan",
		description: 'external team scheduler warning',
	},
	subtaskViolation: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.subtask-violation',
		defaultMessage: 'Problem in sub-task',
		description: 'subtask violation scheduler warning',
	},
	subtaskAssignmentViolation: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.subtask-assignment-violation',
		defaultMessage:
			"This issue can't be scheduled because this issue and its sub-tasks are assigned to different teams",
		description: 'subtask assignment violation scheduler warning',
	},
	sprintExceeded: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.sprint-exceeded',
		defaultMessage: 'More than one sprint is needed to finish this issue',
		description: 'sprint exceeded scheduler warning',
	},
	invalidSprint: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.invalid-sprint',
		defaultMessage: 'Item ignored',
		description: 'invalid sprint scheduler warning',
	},
	releaseShifted: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.release-shifted',
		defaultMessage: 'Release start date shifted.',
		description: 'release shifted scheduler warning',
	},
	earliestStartIgnored: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.earliest-start-ignored',
		defaultMessage: "This issue's earliest start date is ignored because of its sprint assignment",
		description: 'earliest start ignored scheduler warning',
	},
	overbooked: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.overbooked',
		defaultMessage: 'The people working on this issue are overbooked for this sprint',
		description: 'overbooked scheduler warning',
	},
	availabilityViolation: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.availability-violation',
		defaultMessage: "This issue can't be scheduled because no one is available",
		description: 'availability violation scheduler warning',
	},
	resourceLimitViolation: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.resource-limit-violation',
		defaultMessage: 'Resource limit violation',
		description: 'resource limit violation scheduler warning',
	},
	violationInDependency: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.violation-in-dependency',
		defaultMessage: 'Violation in dependency.',
		description: 'violation in dependency scheduler warning',
	},
	dependenciesIgnoredRelease: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.dependencies-ignored-release',
		defaultMessage: 'Dependencies ignored',
		description: 'dependencies ignored release scheduler warning',
	},
	dependenciesIgnoredSprint: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.dependencies-ignored-sprint',
		defaultMessage: 'Dependencies ignored',
		description: 'dependencies ignored sprint scheduler warning',
	},
	cyclicDependency: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.cyclic-dependency',
		defaultMessage: "This issue can't be scheduled due to a cyclic dependency",
		description: 'cyclic dependency scheduler warning',
	},
	subtaskCyclicDependencyIgnored: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.subtask-cyclic-dependency-ignored',
		defaultMessage:
			"We can't process dependencies between sub-tasks within a single issue. Remove these dependencies on this issue and then auto-schedule your plan.",
		description: 'subtask cyclic dependency ignored scheduler warning',
	},
	subtaskNoSkilledResource: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.subtask-no-skilled-resource',
		defaultMessage:
			"This sub-task can't be scheduled because no available single team member has the required skill set",
		description: 'subtask no skilled resource scheduler warning',
	},
	missingSkills: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.missing-skills',
		defaultMessage:
			"This issue can't be scheduled because the available team members don't have the required skill set",
		description: 'missing skills scheduler warning',
	},
	tooSmallEstimates: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.too-small-estimates',
		defaultMessage: "This issue can't be scheduled because this issue's estimate is too small",
		description: 'too small estimates scheduler warning',
	},
	completedParent: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.completed-parent',
		defaultMessage: 'Parent item marked as done',
		description: 'completed parent scheduler warning',
	},
	calculationComplexity: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.header.calculation-complexity',
		defaultMessage: "This issue can't be scheduled because the calculation is too complex",
		description: 'calculation complexity scheduler warning',
	},
});

export const detailedSummaryMessages = defineMessages({
	targetDateBeyondDueDate: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.target-date-beyond-due-date',
		defaultMessage:
			'One or both of the target dates for this issue are set after the due date. Either update the due date, or set the target dates to on or before the due date.',
		description: 'issue target date is set after the due date',
	},
	targetDateOutsideOfSprint: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.target-date-outside-of-sprint',
		defaultMessage: 'The target dates of the issue fall outside of the assigned sprint',
		description: 'Issue target date is set after the due date',
	},
	notClosedBeyondTargetEndDate: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.not-closed-beyond-target-end-date',
		defaultMessage:
			'This issue is still unresolved after the target end date. Check if the status needs updating, or if more time is needed for the issue.',
		description: 'Issue is not closed past its target end date',
	},
	inProgressBeyondInferredTargetEndDate: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.in-progress-beyond-inferred-target-end-date',
		defaultMessage:
			'This issue is still unresolved after its end date. Consider moving the status back to To do, or adding the issue to a current or future sprint, or rescheduling the issue to a later end date.',
		description:
			'Over deadline warning message. Issue has inferred dates and has in progress or done status',
	},
	endsAfterParent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.ends-after-parent',
		defaultMessage: 'A child issue ends after its parent issue',
		description: 'issue ends after parent warning message',
	},
	startsBeforeParent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.starts-before-parent',
		defaultMessage: 'A child issue starts before its parent issue',
		description: 'issue starts before parent warning message',
	},
	issueAssignedKanbanTeamAndSprint: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.issue-assigned-kanban-team-and-sprint',
		defaultMessage: 'Sprints should only be associated with Scrum teams.',
		description:
			'Issue has been given both a sprint and a Kanban team, which are conflicting concepts',
	},
	startDateAfterEndDate: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.start-date-after-end-date',
		defaultMessage:
			'The start date for this issue is set after the end date. Either update the end date, or set the start date to on or before the end date.',
		description: 'Issue start date is set to after its end date',
	},
});

export const bodyMessages = defineMessages({
	// Warning Center warnings
	misconfiguredChildren: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.misconfigured-children',
		defaultMessage: 'This issue is OK, but it has children with warnings',
		description: 'misconfigured children warning message',
	},
	endsAfterParent: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.ends-after-parent',
		defaultMessage: '{issue} ends {amount} after its parent {parent}',
		description: 'issue ends after parent warning message',
	},
	startsBeforeParent: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.starts-before-parent',
		defaultMessage: '{issue} starts {amount} before its parent {parent}',
		description: 'issue starts before parent warning message',
	},
	dateBeyondDueDate: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.date-beyond-due-date',
		defaultMessage:
			'One or both of the start and end dates for this issue are set after the due date. Either update the due date, or set the dates to on or before the due date.',
		description: 'issue date is set after the due date',
	},
	notClosedBeyondEndDate: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.not-closed-beyond-end-date',
		defaultMessage:
			'This issue is still unresolved after its end date. Check if the status needs updating, or if more time is needed for the issue.',
		description: 'Issue is not closed past its end date',
	},
	inProgressBeyondInferredEndDate: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.in-progress-beyond-inferred-end-date',
		defaultMessage:
			'This issue is still unresolved after its end date. Consider moving the status back to To do, adding the issue to a current or future sprint, or rescheduling the issue to a later end date.',
		description:
			'Over deadline warning message. Issue has inferred dates and has in progress or done status',
	},
	dateOutsideOfSprint: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.date-outside-of-sprint',
		defaultMessage: 'The start or end date of the issue fall outside of the assigned sprint',
		description: 'Issue start or end date conflict with sprint assignment',
	},
	issueAssignedKanbanTeamAndSprint: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.issue-assigned-kanban-team-and-sprint',
		defaultMessage:
			'Sprints should only be associated with Scrum teams. The capacity of this issue will still be allocated to the Kanban iterations using the assigned dates, but please verify the Sprint assignment.',
		description:
			'Issue has been given both a sprint and a Kanban team, which are conflicting concepts',
	},
	startDateAfterEndDate: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.start-date-after-end-date',
		defaultMessage:
			'The start date for this issue is set after the end date. Either update the end date, or set the start date to on or before the end date.',
		description: 'Issue start date is set to after its end date',
	},

	// Scheduler warnings
	unknown: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.unknown',
		defaultMessage: 'There was an error during scheduling',
		description: 'an unexpected scheduler warning message',
	},
	notFullyScheduled: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.not-fully-scheduled',
		defaultMessage:
			'Check that someone in your team is available to work on this issue at this particular time and then recalculate your plan.',
		description: 'not fully scheduled scheduler warning message',
	},
	horizonReached: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.horizon-reached',
		defaultMessage:
			"Every plan has a maximum 5 year planning horizon. Decrease the scope of work, add extra people, or increase your team's capacity to reduce the length of your schedule. Then, recalculate your plan.",
		description: 'horizon reached scheduler warning message',
	},
	releaseConflict: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.release-conflict',
		defaultMessage:
			'A story-level issue and its sub-tasks must be assigned to the same release. Check the release assignments are consistent and then recalculate your plan.',
		description: 'release conflict scheduler warning message',
	},
	assigneeIgnored: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.assignee-ignored',
		defaultMessage:
			'This issue is scheduled for a person other than its assignee, as the assignee is not part of any of the teams in your plan. Check that they are part of a team and that their team can be assigned to this issue. Then, recalculate your plan.',
		description: 'assignee ignored scheduler warning message',
	},
	externalTeam: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.external-team',
		defaultMessage:
			"Change the assigned team to one that's in your plan, or add the assigned team to your plan. Then, recalculate your plan.",
		description: 'external team scheduler warning message',
	},
	subtaskViolation: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.subtask-violation',
		defaultMessage:
			'This item could not be scheduled due to a problem with one of its sub-tasks. To schedule this item, alleviate any existing errors on its sub-tasks.',
		description: 'subtask violation scheduler warning message',
	},
	subtaskAssignmentViolation: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.subtask-assignment-violation',
		defaultMessage:
			'A story-level issue and its sub-tasks must be scheduled to the same team. Check the team assignments are consistent and then recalculate your plan.',
		description: 'subtask assignment violation scheduler warning message',
	},
	sprintExceeded: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.sprint-exceeded',
		defaultMessage:
			'Break your story down further so that estimate is reduced, or decrease the scope of the sprint, until this issue fits into the sprint. Then, recalculate your plan.',
		description: 'sprint exceeded scheduler warning message',
	},
	invalidSprint: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.invalid-sprint',
		defaultMessage: 'The item is assigned to an invalid sprint.',
		description: 'invalid sprint scheduler warning message',
	},
	releaseShifted: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.release-shifted',
		defaultMessage:
			'Because of the sprint assignment of this work item the corresponding release start date is shifted.',
		description: 'release shifted scheduler warning message',
	},
	earliestStartIgnored: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.earliest-start-ignored',
		defaultMessage:
			"The start date of this issue's manually assigned sprint takes precedence over this issue's earliest start date. To maintain the earliest start date, set the issue's sprint to 'Calculate'. Or, to remove this message, remove the start date from this issue. Then, recalculate your plan.",
		description: 'earliest start ignored scheduler warning message',
	},
	overbooked: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.overbooked',
		defaultMessage:
			'This issue is manually assigned to a sprint in which the people available to work on it are overbooked. Decrease the scope for those particular people. Or, if there are no people in your team, decrease the scope of the sprint. Then, recalculate your plan.',
		description: 'overbooked scheduler warning message',
	},
	availabilityViolation: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.availability-violation',
		defaultMessage:
			'Check in the team view that someone in your team is available to work on this issue at this time and then recalculate your plan.',
		description: 'availability violation scheduler warning message',
	},
	resourceLimitViolation: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.resource-limit-violation',
		defaultMessage: 'Resource limit violation',
		description: 'resource limit violation scheduler warning message',
	},
	violationInDependency: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.violation-in-dependency',
		defaultMessage: 'A required work item could not be scheduled.',
		description: 'violation in dependency scheduler warning message',
	},
	dependenciesIgnoredRelease: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.dependencies-ignored-release',
		defaultMessage:
			"This work item is assigned to a release which happens earlier than releases that this work item's dependencies are assigned to.",
		description: 'dependencies ignored release scheduler warning message',
	},
	dependenciesIgnoredSprint: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.dependencies-ignored-sprint',
		defaultMessage:
			"This work item is assigned to a fixed sprint which happens earlier than sprints that this work item's dependencies are scheduled in.",
		description: 'dependencies ignored sprint scheduler warning message',
	},
	cyclicDependency: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.cyclic-dependency',
		defaultMessage:
			'This issue depends on an issue that directly (or indirectly) depends back on this issue. Remove that dependency and recalculate your plan.',
		description: 'cyclic dependency scheduler warning message',
	},

	subtaskCyclicDependencyIgnored: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.body.subtask-cyclic-dependency-ignored',
		defaultMessage:
			"We can't process dependencies between sub-tasks within a single issue. Remove these dependencies on this issue and then auto-schedule your plan.",
		description: 'subtask cyclic dependency ignored scheduler warning message',
	},
	subtaskNoSkilledResource: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.subtask-no-skilled-resource',
		defaultMessage:
			"Sub-tasks must to assigned to a single person and can't be assigned across teams or people. Check a single team member has the required skill set and make sure they are available. Or, remove the skill requirements on this issue by refining its estimates. Then, recalculate your plan.",
		description: 'subtask no skilled resource scheduler warning message',
	},
	missingSkills: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.missing-skills',
		defaultMessage:
			"Story-level issues can't be assigned across teams. Check that (a subset of) team members collectively have the required skill set, and that they are available. Then, recalculate your plan.",
		description: 'missing skills scheduler warning message',
	},
	tooSmallEstimates: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.too-small-estimates',
		defaultMessage:
			'Check all estimates on this issue are at least 0.01. This means the total estimate, as well as the estimate for any stage or skill. Then, recalculate your plan.',
		description: 'too small estimates scheduler warning message',
	},
	completedParent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.completed-parent',
		defaultMessage:
			'This work item could not be scheduled because one of its parents has been marked as done. To schedule it, change the parent of this work item or change the status of the parent. Otherwise, to remove this message, mark this item as done as well.',
		description: 'completed parent scheduler warning message',
	},
	calculationComplexity: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.warnings.calculation-complexity',
		defaultMessage:
			'Remove the issue from its assigned sprint, change the required skills, or make the assigned team smaller. Then, recalculate your plan.',
		description: 'calculation complexity scheduler warning message',
	},
});
