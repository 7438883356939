import { combineReducers } from '@atlassian/jira-portfolio-3-portfolio/src/common/redux';
import HiddenIssues from './hidden-issues/reducer';
import PlanSettings from './plan-settings/reducer';
import ShareReport from './share-report/reducer';
import ToolBar from './tool-bar/reducer';
import UpdateJira from './update-jira/reducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default combineReducers<any, any>({
	PlanSettings,
	UpdateJira,
	ShareReport,
	HiddenIssues,
	ToolBar,
});
