import React, { useCallback } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntl } from '@atlassian/jira-intl';
import type { UnMatchedFilters } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/types';
import filterNameMessages from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/filter-bar/messages';
import { withSlots, slots } from '@atlassian/jira-portfolio-3-portfolio/src/common/component-slots';
import Issue from '../issue';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

function Description({
	issueSearchResults: { resultsHiddenByFilter },
	issuesById,
	projectsById,
	issueTypesById,
	intl,
	IssueSearchWarningClearFilter,
}: Props) {
	// if there are up to 2 matching issues hidden by filters, a list of these issues is displayed with:
	// * the issue link
	// * the issue description
	// * the filters hiding the issue
	// * a Clear this (these) filter(s) link
	const showHiddenIssuesList =
		resultsHiddenByFilter && resultsHiddenByFilter.length > 0 && resultsHiddenByFilter.length <= 2;

	// Remove on issue-search-intl-bug-fix
	const unMatchedFiltersOld = useCallback(
		(unmatchedFilter: UnMatchedFilters) => {
			if (unmatchedFilter.type === 'custom') {
				return unmatchedFilter.filters.map((e) => e.title).join(', ');
			}
			return intl.formatMessage(
				filterNameMessages[
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					unmatchedFilter.id as keyof typeof filterNameMessages
				],
			);
		},
		[intl],
	);

	const unMatchedFilters = useCallback(
		(unmatchedFilter: UnMatchedFilters) => {
			if (unmatchedFilter.type === 'custom') {
				return unmatchedFilter.filters.map((e) => e.title).join(', ');
			}

			const { id } = unmatchedFilter;

			if (Object.prototype.hasOwnProperty.call(filterNameMessages, id)) {
				return intl.formatMessage(filterNameMessages[id]);
			}

			// Unit test should have caught this jira/src/packages/portfolio-3/portfolio/src/app-simple-plans/state/domain/view-settings/filters/test.tsx
			log.safeErrorWithoutCustomerData(
				'plans.issue-search-warning-flag.filterNameMessages',
				'unmatchedFilter id not found in filterNameMessages',
			);

			return '';
		},
		[intl],
	);

	return (
		<div data-testid="portfolio-3-portfolio.app-simple-plans.flag-group.issue-search-warning-flag.description.issue-search">
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.messageText}>
				{intl.formatMessage(messages.hiddenByFilterBody, {
					filterCount: resultsHiddenByFilter.length,
				})}
				{!showHiddenIssuesList && intl.formatMessage(messages.hiddenByClearFilterBody)}
			</div>
			{showHiddenIssuesList &&
				resultsHiddenByFilter.map((item, index) => (
					<div key={index}>
						<Issue
							issue={issuesById[item.id]}
							projectKey={projectsById[issuesById[item.id].project.toString()].key}
							issueType={issueTypesById[issuesById[item.id].type.toString()]}
						/>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.filterDetails}>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<div className={styles.filters}>
								{intl.formatMessage(messages.hiddenByFilterLabel)}
								{item.unmatchedFilters
									.map(fg('issue-search-intl-bug-fix') ? unMatchedFilters : unMatchedFiltersOld)
									.join(', ')}
							</div>
							<IssueSearchWarningClearFilter filters={item.unmatchedFilters} />
						</div>
					</div>
				))}
		</div>
	);
}

export default injectIntl(
	withSlots({ IssueSearchWarningClearFilter: slots.IssueSearchWarningClearFilter })(Description),
);
