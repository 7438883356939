import type { Dispatch } from 'redux';
import { change } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/filters';
import { WARNING_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types';
import {
	toggleWarningByType,
	toggleShowWarning,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/warning-settings/actions';
import {
	setSearchQuery,
	toggleIssueSearch,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/scope/header/search/actions';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { Action, DispatchProps } from './types';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (
	dispatch: Dispatch<Action>,
) => ({
	setSearchQuery: (value: string) => {
		dispatch(setSearchQuery(value));
		dispatch(toggleIssueSearch(true));
	},
	toggleShowWarning: () => {
		dispatch(toggleShowWarning());
	},
	toggleWarningByType: (type: string) => {
		dispatch(toggleWarningByType(type));
	},
	toggleWarningsFilter: (value: boolean) => {
		dispatch(change({ id: WARNING_FILTER_ID, value }));
	},
});

export default mapDispatchToProps;
