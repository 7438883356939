import * as R from 'ramda';
import { getFilters } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters';
import { getProjects } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects';
import { PROJECT_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';

export default createSelector(
	[getProjects, getFilters],
	(projects, { [PROJECT_FILTER_ID]: { id, value } }) => ({
		id,
		value,
		projects: R.sortBy(R.compose(R.toLower, R.prop('name')))(projects),
	}),
);
