import * as R from 'ramda';
import { indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { CustomFields } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import { isLabelCustomField } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/custom-fields';
import type { CustomField } from '../../state/domain/custom-fields/types';
import type { SelectOption } from '../../state/domain/select-options/types';
import type {
	UserPickerOption,
	UserPickerOptions,
} from '../../state/domain/user-picker-options/types';
import type { State } from '../../state/types';
import type { UserPicker as UserPickerUI } from '../../state/ui/main/tabs/roadmap/fields/custom-fields/user-picker/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { CustomField } from '../../state/domain/custom-fields/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { SelectOption } from '../../state/domain/select-options/types';

export const getCustomFields = (state: State): CustomField[] => state.domain.customFields;

export const getCustomFieldsById = createSelector(
	[getCustomFields],
	(customFields: CustomField[]) => indexBy(R.prop('id'), customFields),
);

export const getSupportedCustomFields = createSelector(
	[getCustomFields],
	(customFields: CustomField[]) =>
		customFields.filter(
			(field) =>
				CustomFields.WritableFields.includes(
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					field.type.key as (typeof CustomFields)['WritableFields'][number],
				) ||
				CustomFields.ReadOnlyFields.includes(
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					field.type.key as (typeof CustomFields)['ReadOnlyFields'][number],
				),
		),
);

export const getFilterableCustomFields = createSelector(
	[getCustomFields],
	(customFields: CustomField[]) =>
		customFields.filter((field) =>
			CustomFields.FilterableFields.includes(
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				field.type.key as (typeof CustomFields)['FilterableFields'][number],
			),
		),
);

export const getCustomFieldsByKey = (key: string, customFields: CustomField[]): CustomField[] =>
	customFields.filter((customField) => customField.type.key === key);

export const getCustomFieldById = (id: number, customFields: CustomField[]): CustomField[] =>
	customFields.filter((customField) => customField.id === id);

export const getSelectOptions = (state: State) => state.domain.selectOptions;
export const getSelectOptionsById = createSelector(
	[getSelectOptions],
	(selectOptions: SelectOption[]) => indexBy<SelectOption, string>(R.prop('id'), selectOptions),
);

export const getUserPickerOptions = (state: State): UserPickerOptions =>
	state.domain.userPickerOptions;

export const getUserPickerOptionsUserList = (state: State): UserPickerOption[] =>
	state.domain.userPickerOptions.userList;

/** userList is an array which comes in the form of
 * [{ value: '5ebbba3c0590bb0b7b5d6ca', label: 'John Doe', icon: 'iconUrl', email: 'johndoe@atlassian.com'}]
 * 'value' here is actually the user Id, by which the user list is indexed in this selector in order to
 * find the selected user faster than if we were browsing the userList array
 */

export const getUserPickerOptionsUserListById = createSelector(
	[getUserPickerOptionsUserList],
	(userList) => indexBy(R.prop('value'), userList),
);

export const getUserPickerUIState = (state: State): UserPickerUI =>
	state.ui.Main.Tabs.Roadmap.Fields.CustomFields.UserPicker;

export const getCustomLabelFieldIds = createSelector(
	[getCustomFields],
	(customFields: CustomField[]) =>
		customFields
			.filter((customField) => isLabelCustomField(customField.type.key))
			.map((customField) => `${customField.id}`),
);
