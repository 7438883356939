import issueBarDragEmitter from '@atlassian/jira-portfolio-3-portfolio/src/common/issue-bar-drag-events';

export const monitorForIssueBarUpdate = ({
	onDragStart,
	onDrag,
	onDragEnd,
}: {
	onDragStart: () => void;
	onDrag: (arg0: { pageX: number; pageY: number }) => void;
	onDragEnd: () => void;
}) => {
	/** Set pageY params to be NaN to prevent the auto-scroll vertically. */
	const stripPageY =
		(callback: (arg0: { pageX: number; pageY: number }) => void): typeof callback =>
		({ pageX }) =>
			callback({ pageX, pageY: NaN });

	const handleDragStart = stripPageY(onDragStart);
	const handleDrag = stripPageY(onDrag);
	const handleDragEnd = stripPageY(onDragEnd);

	/* eslint-disable @atlaskit/design-system/no-direct-use-of-web-platform-drag-and-drop */
	issueBarDragEmitter.addEventListener('dragstart', handleDragStart);
	issueBarDragEmitter.addEventListener('drag', handleDrag);
	issueBarDragEmitter.addEventListener('dragend', handleDragEnd);

	return () => {
		issueBarDragEmitter.removeEventListener('dragstart', handleDragStart);
		issueBarDragEmitter.removeEventListener('drag', handleDrag);
		issueBarDragEmitter.removeEventListener('dragend', handleDragEnd);
	};
	/* eslint-enable @atlaskit/design-system/no-direct-use-of-web-platform-drag-and-drop */
};
