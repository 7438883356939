import { getTimelineRange } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/timeline';
import { getTimeScaleViewSettings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { TimelineRange } from '@atlassian/jira-portfolio-3-portfolio/src/common/types';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { StateProps } from './types';

const getInitialCustomDateRangePure = ({ start, end }: TimelineRange) => ({
	fromDate: start,
	toDate: end,
});

const getInitialCustomDateRange = createSelector([getTimelineRange], getInitialCustomDateRangePure);

export const getTimeScale: MapStateToProps<StateProps> = (state) => ({
	customDateRange: getTimeScaleViewSettings(state).customDateRange,
	initialCustomDateRange: getInitialCustomDateRange(state),
	mode: getTimeScaleViewSettings(state).mode,
});

export default getTimeScale;
