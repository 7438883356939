import {
	setIssueTypeIdForHierarchy,
	setGlobalCreateIssue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/scope/inline-create/actions.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { DispatchProps } from './types';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	setIssueTypeIdForHierarchy: (hierarchyLevel: number, issueTypeId: number) => {
		dispatch(setIssueTypeIdForHierarchy(hierarchyLevel, issueTypeId));
	},
	setGlobalCreateIssue: () => {
		dispatch(setGlobalCreateIssue(false));
	},
});

export default mapDispatchToProps;
