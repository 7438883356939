import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	suggestChildIssues: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.ai-suggest-child-issues.suggest-child-issues',
		defaultMessage: 'Suggest child issues',
		description: 'Label for drop menu to let atlassian intelligence suggest child issues',
	},
	suggestSubtasks: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.ai-suggest-child-issues.suggest-subtasks',
		defaultMessage: 'Suggest subtasks',
		description: 'Label for drop menu to let atlassian intelligence suggest subtasks',
	},
	tooltipSuggestChildIssues: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.ai-suggest-child-issues.tooltip-suggest-child-issues',
		defaultMessage: 'Ask Atlassian Intelligence to suggest child issues for you',
		description: 'Tooltip for drop menu item to let atlassian intelligence suggest child issues',
	},
	tooltipSuggestSubtasks: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.ai-suggest-child-issues.tooltip-suggest-subtasks',
		defaultMessage: 'Ask Atlassian Intelligence to suggest subtasks for you',
		description: 'Tooltip for drop menu item to let atlassian intelligence suggest subtasks',
	},
	tooltipForDisabledSuggestChildIssues: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.ai-suggest-child-issues.tooltip-for-disabled-suggest-child-issues',
		defaultMessage: 'Please save the changes of this issue to Jira',
		description:
			'Tooltip for disabled drop menu item so users can save their changes to Jira before using AI',
	},
	tooltipForAiOptInDisabled: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.ai-suggest-child-issues.tooltip-for-ai-opt-in-disabled',
		defaultMessage: 'To use Atlassian Intelligence, activate it from Atlassian Administration',
		description:
			'Tooltip for disabled drop menu item to prompt users to enable Atlassian Intelligence',
	},
});
