import { useEffect, useState } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import type { Person } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/assignees/types.tsx';
import type { GroupCombination } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import { type Option, UNGROUPED } from '../types';
import { optionSortComparator } from '../utils';
import messages from './messages';
import type { Props } from './types';

const AssigneeOptionsProvider = ({
	assignees,
	assigneeListFetchStatus,
	fetchAssigneeList,
	children,
}: Props) => {
	const { formatMessage } = useIntl();
	const [isLoading, setIsLoading] = useState(assigneeListFetchStatus);

	useEffect(() => {
		setIsLoading(true);
		fetchAssigneeList({ query: '' });
	}, [fetchAssigneeList]);

	const previousAssigneeListFetchStatus = usePrevious(assigneeListFetchStatus);

	useEffect(() => {
		if (previousAssigneeListFetchStatus && !assigneeListFetchStatus) setIsLoading(false);
	}, [assigneeListFetchStatus, previousAssigneeListFetchStatus]);

	const handleQueryChange = (query: string) => {
		setIsLoading(true);
		fetchAssigneeList({ query });
	};

	const createGroupOptionFromAssignee = (assignee: Person): Option<GroupCombination> => {
		const { title, email, avatarUrl } = assignee.jiraUser;
		return {
			id: `assignee-${assignee.personId}`,
			name: title,
			value: { assignee: assignee.personId },
			description: email,
			url: avatarUrl ?? '',
			urlType: 'avatar',
		};
	};

	const getGroupOptionsFromAssignees = (): Option<GroupCombination>[] =>
		assignees.map(createGroupOptionFromAssignee).sort(optionSortComparator);

	return children({
		options: {
			type: UNGROUPED,
			options: getGroupOptionsFromAssignees(),
		},
		menuTitle: formatMessage(messages.assigneeMenuTitle),
		searchPlaceholder: formatMessage(messages.assigneeSearchPlaceholder),
		onQueryChange: handleQueryChange,
		isLoading,
	});
};

export default AssigneeOptionsProvider;
