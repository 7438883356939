import type { GroupCombination } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { Grouping } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import type { Values } from './types';

export const CLEAR_INLINE_CREATE =
	'state.ui.main.tabs.roadmap.scope.inline-create.CLEAR_INLINE_CREATE' as const;
export const SET_ISSUE_TYPE_ID =
	'state.ui.main.tabs.roadmap.scope.inline-create.SET_ISSUE_TYPE_ID' as const;
export const SET_PROJECT_ID =
	'state.ui.main.tabs.roadmap.scope.inline-create.SET_PROJECT_ID' as const;
export const SET_SIBLING_ID =
	'state.ui.main.tabs.roadmap.scope.inline-create.SET_SIBLING_ID' as const;
export const START_INLINE_CREATE =
	'state.ui.main.tabs.roadmap.scope.inline-create.START_INLINE_CREATE' as const;
export const UPDATE_INLINE_CREATE =
	'state.ui.main.tabs.roadmap.scope.inline-create.UPDATE_INLINE_CREATE' as const;
export const SET_ISSUE_TYPE_ID_FOR_HIERARCHY =
	'state.ui.main.tabs.roadmap.scope.inline-create.SET_ISSUE_TYPE_ID_FOR_HIERARCHY' as const;
export const SET_GLOBAL_CREATE_ISSUE =
	'state.ui.main.tabs.roadmap.scope.inline-create.SET_GLOBAL_CREATE_ISSUE' as const;
export const SET_OPEN_LOCKED =
	'state.ui.main.tabs.roadmap.scope.inline-create.SET_OPEN_LOCKED' as const;

export type SetGlobalCreateActionPayload = {
	isGlobalCreateIssue: boolean;
};

export type SetIssueTypeIdActionPayload = {
	issueTypeId: number;
};

export type SetProjectIdActionPayload = {
	projectId: string;
};

export type SetSiblingIdActionPayload = {
	siblingId: string;
};

export type StartInlineCreateActionPayloadSource = 'HEADER' | 'INLINE';

export type StartInlineCreateActionPayload = {
	parentId: string | null | undefined;
	issueTypeId: number;
	hierarchyLevel: number;
	projectId: number;
	siblingId: string | null | undefined;
	group?: string;
	grouping?: Grouping;
	groupCombination?: GroupCombination;
	source: StartInlineCreateActionPayloadSource;
	startInlineCreateInEmptyGroup?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	valuesFromGrouping?: Record<any, any>;
};

export type UpdateInlineCreateActionPayload = Values;

export type SetOpenLockedActionPayload = {
	attribute: string;
	isLocked: boolean;
};

export type SetIssueTypeIdForHierarchyPayload = { hierarchyLevel: number; issueTypeId: number };

export type ClearInlineCreateAction = {
	type: typeof CLEAR_INLINE_CREATE;
};

export type SetGlobalCreateIssueAction = {
	type: typeof SET_GLOBAL_CREATE_ISSUE;
	payload: SetGlobalCreateActionPayload;
};

export type SetIssueTypeIdAction = {
	type: typeof SET_ISSUE_TYPE_ID;
	payload: SetIssueTypeIdActionPayload;
};

export type SetProjectIdAction = {
	type: typeof SET_PROJECT_ID;
	payload: SetProjectIdActionPayload;
};

export type SetSiblingIdAction = {
	type: typeof SET_SIBLING_ID;
	payload: SetSiblingIdActionPayload;
};

export type StartInlineCreateAction = {
	type: typeof START_INLINE_CREATE;
	payload: StartInlineCreateActionPayload;
};

export type UpdateInlineCreateAction = {
	type: typeof UPDATE_INLINE_CREATE;
	payload: UpdateInlineCreateActionPayload;
};

export type SetOpenLockedAction = {
	type: typeof SET_OPEN_LOCKED;
	payload: SetOpenLockedActionPayload;
};

export type SetIssueTypeIdForHierarchyAction = {
	type: typeof SET_ISSUE_TYPE_ID_FOR_HIERARCHY;
	payload: SetIssueTypeIdForHierarchyPayload;
};

export const clearInlineCreate = () => ({
	type: CLEAR_INLINE_CREATE,
});

export const startInlineCreate = (payload: StartInlineCreateActionPayload) => ({
	type: START_INLINE_CREATE,
	payload,
});

export const setIssueTypeId = (issueTypeId: number) => ({
	type: SET_ISSUE_TYPE_ID,
	payload: { issueTypeId },
});

export const setProjectId = (projectId: number) => ({
	type: SET_PROJECT_ID,
	payload: { projectId },
});

export const setSiblingId = (siblingId?: string | null) => ({
	type: SET_SIBLING_ID,
	payload: { siblingId },
});

export const setIssueTypeIdForHierarchy = (hierarchyLevel: number, issueTypeId: number) => ({
	type: SET_ISSUE_TYPE_ID_FOR_HIERARCHY,
	payload: { hierarchyLevel, issueTypeId },
});

export const updateInlineCreate = (payload: UpdateInlineCreateActionPayload) => ({
	type: UPDATE_INLINE_CREATE,
	payload,
});

export const setGlobalCreateIssue = (isGlobalCreateIssue: boolean) => ({
	type: SET_GLOBAL_CREATE_ISSUE,
	payload: { isGlobalCreateIssue },
});

export const setOpenLocked = (payload: SetOpenLockedActionPayload): SetOpenLockedAction => ({
	type: SET_OPEN_LOCKED,
	payload,
});
