import * as R from 'ramda';
import type { IntlShape } from '@atlassian/jira-intl';
import { formatDateUTC } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import {
	distanceInWordsWithPolarity,
	startOfUtcDay,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import type { CustomField } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/custom-fields/types.tsx';
import type { InferredFields } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types';
import type { DateConfiguration } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/plan/types.tsx';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { Sprint } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/sprints/types';
import type { Team } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/teams/types';
import type { Version } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/versions/types';
import {
	getCurrentValue,
	hasValueChanged,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/util';
import updateJiraWhatChangedMessagesDefault from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/title-bar/update-jira/changes-table/what-changed/messages';
import {
	DATEFIELD_TYPES,
	type DateField,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import {
	indexBy,
	isDefined,
	filterDefined,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
// delete this reference in JPO-19568. We shouldn't refer the messages file of other components, only from the component's own message and '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages'
import messages from './messages';
import type { Context, Generator, GeneratorKey, OptimizedField } from './types';

const updateJiraWhatChangedMessages = {
	...updateJiraWhatChangedMessagesDefault,
	dueDate: commonMessages.dueDate,
} as const;

const getTitleOfConfiguredDateField = (field: DateField, customFields: CustomField[]): string => {
	const customFieldsById = indexBy(R.prop('id'), customFields);
	if (field.type === DATEFIELD_TYPES.CUSTOM) {
		// @ts-expect-error - TS7015 - Element implicitly has an 'any' type because index expression is not of type 'number'. | TS7015 - Element implicitly has an 'any' type because index expression is not of type 'number'.
		return customFieldsById[field.key] ? customFieldsById[field.key].title : '';
	}
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return updateJiraWhatChangedMessages[field.key as keyof typeof updateJiraWhatChangedMessages]
		.defaultMessage;
};

const dateFieldGenerator: Generator = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	current: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	optimized: any,
	{
		dateConfiguration: { baselineStartField, baselineEndField },
		customFields,
		key,
		dateFormat,
		intl,
	}: Context & { key: 'fixVersions' | 'sprint' | 'team' | 'baselineStart' | 'baselineEnd' },
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	inferred: any,
) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const formatDateOrUndefined = (date: any) =>
		isDefined(date) ? formatDateUTC(date, dateFormat) : undefined;
	let message = '';

	if (key === 'baselineStart') {
		message = getTitleOfConfiguredDateField(baselineStartField, customFields);
	} else if (key === 'baselineEnd') {
		message = getTitleOfConfiguredDateField(baselineEndField, customFields);
	}

	let delta;
	if (isDefined(current) && isDefined(optimized)) {
		if (startOfUtcDay(current) !== startOfUtcDay(optimized)) {
			delta = distanceInWordsWithPolarity(current, optimized);
		}
	}

	return {
		label: intl.formatMessage(messages.targetDates, { dateFieldTitle: message }),
		currentValue: formatDateOrUndefined(current),
		optimizedValue: formatDateOrUndefined(optimized),
		delta,
		inferred,
		key,
	};
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const teamFieldGenerator: Generator = (current: any, optimized: any, { teams, intl }: Context) => {
	const getTeamTitleFromId = (id: string) => {
		if (id) {
			const team = teams.find((t: Team) => t.id === id);
			if (team) {
				return team.title;
			}
			return intl.formatMessage(messages.unknownTeam, { id });
		}
	};

	const currentTeamTitle = getTeamTitleFromId(current);
	const optimizedTeamTitle = getTeamTitleFromId(optimized);

	return {
		label: intl.formatMessage(messages.team),
		currentValue: currentTeamTitle,
		optimizedValue: optimizedTeamTitle,
	};
};

const sprintFieldGenerator: Generator = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	current: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	optimized: any,
	{ sprints, intl }: Context,
) => {
	const getSprintTitleFromId = (id: string) => {
		if (id) {
			const sprint = sprints.find((s: Sprint) => s.id === id);
			if (sprint) {
				return sprint.title;
			}
			return intl.formatMessage(messages.unknownSprint, { id });
		}
	};

	const currentSprintTitle = getSprintTitleFromId(current);
	const optimizedSprintTitle = getSprintTitleFromId(optimized);

	return {
		label: intl.formatMessage(commonMessages.sprint),
		currentValue: currentSprintTitle,
		optimizedValue: optimizedSprintTitle,
	};
};

const versionsFieldGenerator: Generator = (
	currentMaybe: string[] | null | undefined,
	optimizedMaybe: string[] | null | undefined,
	{ versions, intl }: Context,
) => {
	const current = currentMaybe || [];
	const optimized = optimizedMaybe || [];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const unknownVersionMessage = (id: any) => intl.formatMessage(messages.unknownVersion, { id });

	const getTitlesOfVersionsWithIds = R.map((id) => {
		const version = versions.find(R.propEq('id', id));
		return version ? version.name : unknownVersionMessage(id);
	});

	const maxNumReleases = Math.max(current.length, optimized.length);

	const currentVersions = getTitlesOfVersionsWithIds(current);
	const optimizedVersions = getTitlesOfVersionsWithIds(optimized);

	const currentVersionsCsv = currentVersions.join(', ');
	const optimizedVersionsCsv = optimizedVersions.join(', ');

	return {
		label: intl.formatMessage(messages.releases, { releaseCount: maxNumReleases }),
		currentValue: currentVersionsCsv || undefined,
		optimizedValue: optimizedVersionsCsv || undefined,
	};
};

const fieldGenerators: Record<GeneratorKey, Generator> = {
	baselineStart: dateFieldGenerator,
	baselineEnd: dateFieldGenerator,
	team: teamFieldGenerator,
	sprint: sprintFieldGenerator,
	fixVersions: versionsFieldGenerator,
};

const getOptimizedFieldEntry = (
	key: GeneratorKey,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	current: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	optimized: any | null,
	context: Context,
	inferred?: InferredFields,
): OptimizedField | null | undefined => {
	const generator: Generator = fieldGenerators[key];

	if (generator) {
		return generator(current, optimized, { ...context, key }, inferred);
	}

	// eslint-disable-next-line no-console
	console.warn(`No generator for optimized field: ${key}`);
};

export const getOptimizedFields = (
	issue: ScopeIssue,
	teams: Team[],
	sprints: Sprint[],
	versions: Version[],
	dateFormat: string,
	dateConfiguration: DateConfiguration,
	customFields: CustomField[],
	intl: IntlShape,
): OptimizedField[] => {
	if (issue.optimized) {
		const changedKeys: Array<keyof typeof fieldGenerators> = [];
		for (const [optimizedKey, optimizedValue] of Object.entries(issue.optimized)) {
			if (hasValueChanged(getCurrentValue(issue, optimizedKey), optimizedValue)) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				changedKeys.push(optimizedKey as keyof typeof fieldGenerators);
			}
		}

		const context = {
			teams,
			sprints,
			versions,
			dateFormat,
			dateConfiguration,
			customFields,
			intl,
		};

		const optimizedFields = R.map(
			(key) =>
				getOptimizedFieldEntry(
					key,
					issue[key],
					(issue.optimized || {})[key],
					context,
					issue.inferred,
				),
			changedKeys,
		);

		const definedOptimizedFields: OptimizedField[] = filterDefined(optimizedFields);

		return definedOptimizedFields;
	}
	return [];
};
