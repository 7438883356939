import React from 'react';
import { token } from '@atlaskit/tokens';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import {
	useRenderingWindow,
	useZoomLevel,
	useHorizontalPercentageOffset,
} from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import { connect } from '@atlassian/jira-react-redux';
import RowContainer from '../row/container/index.tsx';
import mapStateToProps from './query';
import type { OwnProps } from './types';
import View from './view';

const Capacities = connect(mapStateToProps, {})(View);

export const CapacitiesWithHorizontalScrolling = ({
	timelineRange: timelineRangeFromProps,
	...rest
}: OwnProps) => {
	const [{ getPercentageOffsetFromToday }] = useHorizontalPercentageOffset();
	const [zoomLevel] = useZoomLevel();
	const [{ timelineRange }] = useRenderingWindow();

	return (
		<RowContainer bgColor={token('elevation.surface', colors.N0)}>
			<Capacities
				{...rest}
				timelineRange={zoomLevel ? timelineRange : timelineRangeFromProps}
				zoomLevel={zoomLevel}
				getPercentageOffsetFromToday={getPercentageOffsetFromToday}
			/>
		</RowContainer>
	);
};

export default CapacitiesWithHorizontalScrolling;
