import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	blocks: {
		id: 'portfolio-3-dependency-line-detail.common.ui.content.blocks',
		defaultMessage: 'blocks',
		description:
			'Separator line label. Appears on the line between blocked issue and blocker in dependency tooltip.',
	},
	overlapDatesMessage: {
		id: 'portfolio-3-dependency-line-detail.common.ui.content.overlap-dates-message',
		defaultMessage: 'Dates overlap',
		description: 'Warning message to show the overlap dates in dependency report',
	},
	lessThanOneDayLeadTime: {
		id: 'portfolio-3-dependency-line-detail.common.ui.content.less-than-one-day-lead-time',
		defaultMessage: 'less than 1 day',
		description: 'Text for the label when the lead time is less than a day',
	},
});
