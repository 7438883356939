import { TIMELINE_MODES } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import {
	CHANGE_OPTION,
	CHANGE_MODE,
	CHANGE_CUSTOM_DATES,
	type ChangeOptionAction,
	type ChangeCustomDatesAction,
	type ChangeModeAction,
} from './actions';
import type { TimeScaleState } from './types';

type Action = ChangeOptionAction | ChangeCustomDatesAction | ChangeModeAction;

export const initialState: TimeScaleState = { mode: TIMELINE_MODES.MONTHS };

const reducer = (state: TimeScaleState = initialState, action: Action): TimeScaleState => {
	switch (action.type) {
		case CHANGE_OPTION:
			return {
				...state,
				selection: action.payload,
			};
		case CHANGE_MODE:
			return {
				...state,
				mode: action.payload,
			};
		case CHANGE_CUSTOM_DATES:
			return {
				...state,
				customDateRange: { ...state.customDateRange, ...action.payload },
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};

export default reducer;
