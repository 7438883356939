import React from 'react';
import cx from 'classnames';
import RollupIcon from '../../icons/rollup.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

function RollupContainer({
	showRollup,
	children,
	rolledUpValues = [],
	isDisabled = false,
	withValue,
	readonly = false,
	menuIsOpen = false,
	isMultiSelect = false,
}: Props) {
	const styleNameForRollup = cx({
		[styles.rollup]: true,
		[styles.disabledRollup]: isDisabled,
		[styles.onlyRollup]: !withValue && !readonly,
		[styles.onlyRollupReadOnly]: !withValue && readonly,
	});

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={`${styles.rollupWrapper} ${isDisabled ? styles.disabledRollupWrapper : ''}`}>
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styleNameForRollup}
				style={{
					display: !showRollup || menuIsOpen ? 'none' : undefined,
				}}
			>
				<RollupIcon label="" />
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.rollupValue}>{rolledUpValues && rolledUpValues.join(', ')}</div>
			</div>
			<div
				style={{
					flexWrap: isMultiSelect && menuIsOpen ? 'wrap' : 'nowrap',
				}}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={cx(styles.childrenWrapper, {
					[styles.placeholder]: !withValue && showRollup,
					[styles.childrenWrapperWithRollupValue]: rolledUpValues.length > 0 && !menuIsOpen,
				})}
			>
				{children}
			</div>
		</div>
	);
}

export default RollupContainer;
