import type { Dispatch } from 'redux';
import { sortChanges } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/title-bar/update-jira/actions.tsx';
import type {
	SortBy,
	SortDirection,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/title-bar/update-jira/types.tsx';
import type { Action, DispatchProps } from './types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (dispatch: Dispatch<Action>): DispatchProps => ({
	sortChanges: (sortBy: SortBy, sortDirection: SortDirection) => {
		dispatch(sortChanges(sortBy, sortDirection));
	},
});
