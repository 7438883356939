import { getHierarchyRange } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/hierarchy';
import {
	getSelectedIssues,
	getCPRsForSelectedIssues,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues';
import { getProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { OwnProps, StateProps } from './types';

export default createStructuredSelector<State, OwnProps, StateProps>({
	cpVersionsForSelectedIssues: getCPRsForSelectedIssues,
	hierarchyRange: getHierarchyRange,
	projectsById: getProjectsById,
	selectedIssues: getSelectedIssues,
});
