import type { Dispatch } from 'redux';
import { GROUPING } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import { change } from '../../command/filters';
import { startInlineCreate } from '../../command/inline-create';
import { toggleExpansionInHierarchy } from '../../command/scope';
import { setDefaultProject } from '../../state/domain/projects/actions';
import {
	HIERARCHY_FILTER_ID,
	HIERARCHY_RANGE_FILTER_ID,
	type HierarchyFilterValue,
} from '../../state/domain/view-settings/filters/types';
import { changeGroup } from '../../state/domain/view-settings/visualisations/actions.tsx';
import {
	type StartInlineCreateActionPayload,
	setIssueTypeIdForHierarchy,
	setGlobalCreateIssue,
	clearInlineCreate,
} from '../../state/ui/main/tabs/roadmap/scope/inline-create/actions.tsx';

export const mapDispatchToProps = (dispatch: Dispatch) => ({
	startInlineCreate: (payload: StartInlineCreateActionPayload) => {
		dispatch(startInlineCreate(payload));
	},
	clearInlineCreate: () => {
		dispatch(clearInlineCreate());
	},
	setIssueTypeIdForHierarchy: (hierarchyLevel: number, issueTypeId: number) => {
		dispatch(setIssueTypeIdForHierarchy(hierarchyLevel, issueTypeId));
	},
	setGlobalCreateIssue: () => {
		dispatch(setGlobalCreateIssue(true));
	},
	setDefaultProject: (projectId: number) => dispatch(setDefaultProject({ projectId })),
	expandScopeInHierarchy: () => dispatch(toggleExpansionInHierarchy({ isExpanded: true })),
	setGroupNone: () => {
		dispatch(
			changeGroup({
				grouping: GROUPING.NONE,
			}),
		);
	},
	setHierarchyLevels: (payload: HierarchyFilterValue) => {
		dispatch(
			change({
				id: HIERARCHY_FILTER_ID,
				value: { start: payload.start + 1, end: payload.end + 1 },
			}),
		);
		dispatch(
			change({
				id: HIERARCHY_RANGE_FILTER_ID,
				value: payload,
			}),
		);
	},
});
