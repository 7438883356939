import type { HiddenIssue } from './types';

export const RESET = 'state.domain.update-jira.hidden-issues.RESET' as const;

export type ResetActionPayload = HiddenIssue[];

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export const reset = (payload: ResetActionPayload) => ({
	type: RESET,
	payload,
});
