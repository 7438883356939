import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import View from './view';
import ViewOld from './view-old';

export default componentWithCondition(
	() => ff('com.atlassian.rm.jpo.transposition'),
	View,
	ViewOld,
);
