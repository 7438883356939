import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { connect } from '@atlassian/jira-react-redux';
import mapStateToProps from './query';
import type { StateProps } from './types';
import { DependencyLinesContainer as View } from './view';

export const DependencyLinesContainer = connect<StateProps, {}, {}, State>(
	mapStateToProps,
	{},
)(View);
