import React, { type ChangeEvent, type SyntheticEvent } from 'react';
import * as R from 'ramda';
import type UIAnalyticsEvent from '@atlaskit/analytics-next/UIAnalyticsEvent';
import { injectIntl, FormattedMessage } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import {
	DialogMenuContainer,
	DialogMenuItem,
} from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import {
	sort,
	filter,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/assignees/utils';
import type { Person } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/assignees/types';
import { ASSIGNEE_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import SearchField from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field';
import { UserSelectItem } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/user-picker';
import { FILTER_MAX_WIDTH, FILTER_WIDTH } from '../common';
import ClearFilterButton from '../common/clear-filter/index.tsx';
import FilterText from '../common/filter-text/index.tsx';
import NoMatchFound from '../common/no-match-text/index.tsx';
import TriggerButton from '../common/trigger-button/index.tsx';
import filterMessages from '../messages';
import messages from './messages';
import type { Props } from './types';

type CommonEventHandler = (
	e: ChangeEvent<HTMLInputElement>,
	analyticsEvent: UIAnalyticsEvent,
) => void;
export function AssigneeCheckbox({
	person,
	checked,
	onChange,
}: {
	person: Person;
	checked: boolean;
	onChange: CommonEventHandler;
}) {
	const { title, email, avatarUrl } = person.jiraUser;

	return (
		<Checkbox
			value={person.personId}
			isChecked={checked}
			onChange={onChange}
			label={<UserSelectItem email={email} label={title} icon={avatarUrl} />}
		/>
	);
}

function AssigneeFilter({
	intl,
	searchQuery,
	onQueryChange,
	value,
	onRequestChange,
	onRequestClear,
	assignees,
}: Props) {
	const isSelected = (assignee: Person): boolean => R.includes(assignee.personId, value);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleAssigneeCheckboxChange = (e: SyntheticEvent<any>) => {
		const { value: personId, checked } = e.currentTarget;
		const nextValue = ((): typeof value => {
			const withoutTheAssignee = R.filter(R.pipe(R.equals(personId), R.not))(value);

			if (!checked) {
				return withoutTheAssignee;
			}

			return withoutTheAssignee.concat([personId]);
		})();

		onRequestChange(nextValue);
	};

	const assigneesToDisplay = sort(filter(searchQuery, assignees));

	return (
		<>
			<ClearFilterButton isVisible={!!value.length} onClearClick={onRequestClear} />
			<SearchField
				placeholder={intl.formatMessage(messages.searchAssigneePlaceholder)}
				searchQuery={searchQuery}
				onQueryChange={onQueryChange}
				ariaLabel={intl.formatMessage(messages.searchAssigneeLabel)}
			/>
			{assigneesToDisplay.map((assignee, index) => (
				<DialogMenuItem key={index}>
					<AssigneeCheckbox
						checked={isSelected(assignee)}
						onChange={handleAssigneeCheckboxChange}
						person={assignee}
					/>
				</DialogMenuItem>
			))}

			{R.isEmpty(assigneesToDisplay) && <NoMatchFound />}
		</>
	);
}

const getNames = (assignees: Person[], selected: Set<string>) => {
	const selectedAssignees = assignees.filter((person) => selected.has(person.personId));
	const names = selectedAssignees.map((person) => person.jiraUser.title);
	return names.join(', ');
};

export function TriggerButtonText({
	value,
	persons,
}: {
	value: Props['value'];
	persons: Person[];
}) {
	const selected = new Set(value);

	if (selected.size === 0) {
		return <FormattedMessage {...messages.emptyPlaceholder} />;
	}

	return <FilterText text={getNames(persons, selected)} />;
}

function AssigneeFilterDialog(props: Props) {
	const { isOpen, onOpenChange, value, intl, assignees } = props;

	const ariaText = () => {
		const selected = new Set(value);

		// ex: Asignees, All selected
		return `${intl.formatMessage(filterMessages[ASSIGNEE_FILTER_ID])}, ${
			selected.size > 0
				? getNames(assignees, selected)
				: intl.formatMessage(messages.emptyPlaceholder)
		} ${intl.formatMessage(filterMessages.selected)}`;
	};

	return (
		<InlineDialog
			noPaddings
			maxWidth={FILTER_MAX_WIDTH}
			minWidth={FILTER_WIDTH}
			isOpen={isOpen}
			onClose={onOpenChange}
			content={
				<DialogMenuContainer>
					<AssigneeFilter {...props} />
				</DialogMenuContainer>
			}
			testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.assignee-filter"
		>
			<TriggerButton
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.assignee-filter.trigger-btn"
				triggerButtonText={<TriggerButtonText value={props.value} persons={props.assignees} />}
				ariaLabel={ariaText()}
			/>
		</InlineDialog>
	);
}

export default injectIntl(AssigneeFilterDialog);
