import type { PreMigrationBlob, ReportViewSettings } from './types';

/**
 *
 * @param {*} reportViewSettings
 */
export function getReportMigratableBlob(reportViewSettings: ReportViewSettings): PreMigrationBlob {
	if (!reportViewSettings.filtersConfig) {
		// the report view settings has been migrated
		// @ts-expect-error - TS2740 - Type 'ReportViewSettings' is missing the following properties from type 'PreMigrationBlob': releaseBar, colorBy, groupByLabels, groupBy, and 7 more.
		return reportViewSettings;
	}

	return {
		dateConstraintsSetting: undefined, // date constraint settings not seems to be persisted in the report config
		releaseBar: reportViewSettings.releaseBarState,
		colorBy: reportViewSettings.colorByConfig,
		groupByLabels: {
			groups: reportViewSettings.groupByLabelGroups,
		},
		fieldColumns: reportViewSettings.fieldColumnsConfig,
		groupBy: {
			groups: reportViewSettings.groupByComponentGroups,
		},
		filterOptions: reportViewSettings.filterOptionsConfig,
		timeScale: reportViewSettings.timelineConfig,
		warningSetting: reportViewSettings.warningSetting,
		visualisations: reportViewSettings.groupByConfig,
		filters: reportViewSettings.filtersConfig,
		scopeIssues: {
			isExpanded: reportViewSettings.issueExpansionConfig,
			selected: [],
			lastSelected: [],
			isLoading: false,
			isLoadingHistory: false,
			isSavingIssue: false,
		},
		rollupSettings: {
			showingRolledUpDate: reportViewSettings.rollupSettings.showingRolledUpDate || false,
			showRolledUpOthers: reportViewSettings.rollupSettings.showRolledUpOthers || false,
		},
		estimateRollup: {
			showRolledUpEstimate: reportViewSettings.rollupSettings.showRolledUpEstimate || false,
		},
	};
}
