import type { Error } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/request';
import type { Data } from './types';

export const SENT = 'state.domain.update-jira.changes.SENT' as const;
export const SUCCESS = 'state.domain.update-jira.changes.SUCCESS' as const;
export const AFTER_SUCCESS = 'state.domain.update-jira.changes.AFTER_SUCCESS' as const;
export const ERROR = 'state.domain.update-jira.changes.ERROR' as const;

export type SentAction = {
	type: typeof SENT;
};

export type SuccessAction = {
	type: typeof SUCCESS;
	payload: Data;
};

export type ErrorAction = {
	type: typeof ERROR;
	payload: Error;
};

export const sent = () => ({
	type: SENT,
});

export const success = (payload: Data) => ({
	type: SUCCESS,
	payload,
});

export const afterSuccess = () => ({
	type: AFTER_SUCCESS,
});

export const error = (payload: Error) => ({
	type: ERROR,
	payload,
});
