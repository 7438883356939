import type { Effect } from 'redux-saga';
import { all, take, put, select, fork, takeEvery } from 'redux-saga/effects';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { KEYS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/interaction-constants';
import traceUFOPress from '@atlassian/react-ufo/trace-press';
import { getCustomFields } from '../../query/custom-fields';
import { getHierarchyRangeFilterState } from '../../query/filters/hierarchy-range-filter';
import { getInitialFilterValues } from '../../query/issues';
import { RESET_BACKLOG } from '../../state/domain/actions';
import {
	change as changeFilterValue,
	clear as clearFilter,
	clearAll as clearAllFilters,
	type ChangeActionPayload,
} from '../../state/domain/view-settings/filters/actions';
import { clearAll as clearAllCustomFieldFilter } from '../../state/domain/view-settings/filters/custom-field-filter/actions';
import {
	HIERARCHY_FILTER_ID,
	HIERARCHY_RANGE_FILTER_ID,
	type HierarchyFilterValue,
	type FilterId,
} from '../../state/domain/view-settings/filters/types';
import { toggleSelectedIssues } from '../../state/ui/main/tabs/roadmap/scope/issues/selectable-issue/actions.tsx';

export const SET_INITIAL_FILTER_VALUES = 'command.filters.SET_INITIAL_FILTER_VALUES' as const;

export const CHANGE_FILTER_VALUE = 'command.filters.CHANGE_FILTER_VALUE' as const;

export const CLEAR_FILTER = 'command.filters.CLEAR_FILTER' as const;

export const CLEAR_ALL_FILTERS = 'command.filters.CLEAR_ALL_FILTERS' as const;

export type ChangeFilterValueAction = {
	type: typeof CHANGE_FILTER_VALUE;
	payload: ChangeActionPayload;
};

export const change = (payload: ChangeActionPayload) => ({
	type: CHANGE_FILTER_VALUE,
	payload,
});

export type ClearFilterAction = {
	type: typeof CLEAR_FILTER;
	payload: FilterId;
};

export const clear = (payload: FilterId) => ({
	type: CLEAR_FILTER,
	payload,
});

export const clearAll = () => ({
	type: CLEAR_ALL_FILTERS,
});

export const setInitialFilterValues = () => ({
	type: SET_INITIAL_FILTER_VALUES,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doSetInitialFilterValues(): Generator<Effect, any, any> {
	yield put(toggleSelectedIssues({ ids: [], isSelected: false, toggleAll: true }));
	const hierarchyFilter = yield select(getHierarchyRangeFilterState);

	if (!isDefined(hierarchyFilter) || !isDefined(hierarchyFilter.value)) {
		const value: HierarchyFilterValue = yield select(getInitialFilterValues);

		yield put(
			changeFilterValue(
				{ id: HIERARCHY_FILTER_ID, value: { start: value.start + 1, end: value.end + 1 } },
				true,
			),
		);
		yield put(
			changeFilterValue(
				{
					id: HIERARCHY_RANGE_FILTER_ID,
					value,
				},
				true,
			),
		);
	}
}

export function* doChangeValue(
	{
		payload,
	}: {
		payload: ChangeActionPayload;
	}, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	traceUFOPress(KEYS.CHANGE_FILTER_VALUE);
	yield put(changeFilterValue(payload));
	yield put(toggleSelectedIssues({ ids: [], isSelected: false, toggleAll: true }));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doClearFilter({ payload }: { payload: FilterId }): Generator<Effect, any, any> {
	traceUFOPress(KEYS.CHANGE_FILTER_VALUE);
	yield put(clearFilter(payload));
	yield put(toggleSelectedIssues({ ids: [], isSelected: false, toggleAll: true }));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doClearAllFilters(): Generator<Effect, any, any> {
	yield put(clearAllFilters());
	yield put(clearAllCustomFieldFilter({ customFields: yield select(getCustomFields) }));
	yield put(toggleSelectedIssues({ ids: [], isSelected: false, toggleAll: true }));
}

/**
 * After page loaded,
 * Firstly, using the localstorage to init the filters.
 * Secondly, Extracting the report view config which includes the  custom fields filters info.
 * Thirdly, Extracting the custom fields from the WRM data.
 * So after these, we need to init the custom fields filters by the custom fields.
 * If fieldA is not filterable, need to remove it from the filters.
 * If fieldB is filterable, if it doesn't existed in the filters so need to add it. if it existed in the filters so do nothing.
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchSetInitialFilterValues(): Generator<Effect, any, any> {
	yield all([take(SET_INITIAL_FILTER_VALUES), take(RESET_BACKLOG)]);
	yield* doSetInitialFilterValues();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchChangeFilterValue(): Generator<Effect, any, any> {
	yield takeEvery<ChangeFilterValueAction>(CHANGE_FILTER_VALUE, doChangeValue);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchClearFilter(): Generator<Effect, any, any> {
	yield takeEvery<ClearFilterAction>(CLEAR_FILTER, doClearFilter);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchClearAllFilters(): Generator<Effect, any, any> {
	yield takeEvery(CLEAR_ALL_FILTERS, doClearAllFilters);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchSetInitialFilterValues);
	yield fork(watchChangeFilterValue);
	yield fork(watchClearFilter);
	yield fork(watchClearAllFilters);
}
