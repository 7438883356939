import type { LazyGoalsByARI } from './types';

export const FETCH_GOALS_START = 'state.domain.issue-goals.FETCH_GOALS_START' as const;
export const FETCH_GOALS_SUCCESS = 'state.domain.issue-goals.FETCH_GOALS_SUCCESS' as const;
export const FETCH_GOALS_FAIL = 'state.domain.issue-goals.FETCH_GOALS_FAIL' as const;

export const SEARCH_GOALS_START = 'state.domain.issue-goals.SEARCH_GOALS_START' as const;
export const SEARCH_GOALS_SUCCESS = 'state.domain.issue-goals.SEARCH_GOALS_SUCCESS' as const;
export const SEARCH_GOALS_FAIL = 'state.domain.issue-goals.SEARCH_GOALS_FAIL' as const;

export type FetchGoalsStartAction = {
	type: typeof FETCH_GOALS_START;
	payload: LazyGoalsByARI;
};

export type FetchGoalsSuccessAction = {
	type: typeof FETCH_GOALS_SUCCESS;
	payload: LazyGoalsByARI;
};

export type FetchGoalsFailAction = {
	type: typeof FETCH_GOALS_FAIL;
	payload: LazyGoalsByARI;
};

export type SearchGoalsStartAction = {
	type: typeof SEARCH_GOALS_START;
};

export type SearchGoalsSuccessAction = {
	type: typeof SEARCH_GOALS_SUCCESS;
	payload: LazyGoalsByARI;
};

export type SearchGoalsFailAction = {
	type: typeof SEARCH_GOALS_FAIL;
};

export const fetchGoalsStart = (payload: LazyGoalsByARI): FetchGoalsStartAction => ({
	type: FETCH_GOALS_START,
	payload,
});

export const fetchGoalsSuccess = (payload: LazyGoalsByARI): FetchGoalsSuccessAction => ({
	type: FETCH_GOALS_SUCCESS,
	payload,
});

export const fetchGoalsFail = (payload: LazyGoalsByARI): FetchGoalsFailAction => ({
	type: FETCH_GOALS_FAIL,
	payload,
});

export const searchGoalsStart = (): SearchGoalsStartAction => ({
	type: SEARCH_GOALS_START,
});

export const searchGoalsSuccess = (payload: LazyGoalsByARI): SearchGoalsSuccessAction => ({
	type: SEARCH_GOALS_SUCCESS,
	payload,
});

export const searchGoalsFail = (): SearchGoalsFailAction => ({
	type: SEARCH_GOALS_FAIL,
});

export type IssueGoalsActions =
	| FetchGoalsStartAction
	| FetchGoalsSuccessAction
	| FetchGoalsFailAction
	| SearchGoalsStartAction
	| SearchGoalsSuccessAction
	| SearchGoalsFailAction;
