import React from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import type { Props } from './types';

/**
 * Modals in the Chart are portalled out into a fixed position, as not to be clipped.
 */
const ChartModal = ({ position, content, testId, onClose }: Props) => (
	<InlineDialogWrapper
		// eslint-disable-next-line jira/integration/enforce-data-testid-usage
		data-test-id={testId}
	>
		<InlineDialog
			isOpen
			content={
				// We would like to re-enable pointer events on the content of the inline dialog to allow interaction with its content
				<Box
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
					xcss={xcss({
						pointerEvents: 'initial',
					})}
				>
					{content}
				</Box>
			}
			onClose={onClose}
			closeOnScroll
		>
			<ModalAnchor {...position} />
		</InlineDialog>
	</InlineDialogWrapper>
);

ChartModal.defaultProps = {
	testId: undefined,
};

export default ChartModal;

// Child selector is for the nested inline dialog
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineDialogWrapper = styled.div({
	position: 'absolute',
	top: 0,
	left: 0,
	bottom: 0,
	right: 0,
	pointerEvents: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		padding: 0,
		overflow: 'hidden',
		boxShadow: token('elevation.shadow.overlay', '0 2px 4px 0 rgba(0, 0, 0, 0.4)'),
	},
});

type ModalAnchorProps = {
	left: number;
	top: number;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ModalAnchor = styled.div({
	position: 'fixed',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	left: (props: ModalAnchorProps) => `${props.left}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	top: (props: ModalAnchorProps) => `${props.top}px`,
});
