import { useEffect, useRef } from 'react';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';

const ChangesCountMonitorNew = ({
	changesCount,
	onChangeListModified,
	isSaving,
}: {
	changesCount: number;
	onChangeListModified: () => void;
	// Remove "undefined" when cleanup 'platform_calendar_adoption_in_plans' FG
	isSaving: boolean | undefined;
}) => {
	const lastCount = useRef<number | undefined>();
	useEffect(() => {
		if (!isSaving) {
			// No need to trigger it when the component first mounts.
			if (lastCount.current !== undefined && lastCount.current !== changesCount) {
				// trigger callback when changes are saved or discarded.
				onChangeListModified();
			}
			lastCount.current = changesCount;
		}
	}, [changesCount, onChangeListModified, isSaving]);
	return null;
};

const ChangesCountMonitorOld = ({
	changesCount,
	onChangeListModified,
}: {
	changesCount: number;
	onChangeListModified: () => void;
	isSaving: boolean | undefined;
}) => {
	const lastCount = useRef<number | undefined>();
	useEffect(() => {
		// No need to trigger it when the component first mounts.
		if (lastCount.current !== undefined) {
			// trigger callback when changes are saved or discarded.
			onChangeListModified();
		}
		lastCount.current = changesCount;
	}, [changesCount, onChangeListModified]);
	return null;
};

const ChangesCountMonitor = componentWithCondition(
	() => fg('platform_calendar_adoption_in_plans'),
	ChangesCountMonitorNew,
	ChangesCountMonitorOld,
);

export default ChangesCountMonitor;
