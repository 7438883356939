// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, cloneElement } from 'react';
import * as R from 'ramda';
import { Field } from '@atlaskit/form';
import Tooltip from '@atlaskit/tooltip';
import type { RangeSelectorItem } from '../common/types/range-selector-item';
import type { Props } from './types';

// eslint-disable-next-line jira/react/no-class-components
export default class RangeSelector extends PureComponent<Props> {
	static defaultProps = {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		comparator: R.comparator<any>(R.lt),
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onChange: () => {},
	};

	getStartSelectProps() {
		const { groups, start, end, comparator, onChange: onRangeChange } = this.props;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const newGroups = groups.map<any>(({ items, ...group }) => ({
			...group,
			items: items.map((item) => ({
				...item,
				isDisabled: end ? comparator(item.value, end.value) > 0 : false,
			})),
		}));
		return {
			groups: newGroups,
			defaultValue: start,
			onChange: (item: RangeSelectorItem) => onRangeChange({ start: item, end }),
		};
	}

	getEndSelectProps() {
		const { groups, start, end, comparator, onChange: onRangeChange } = this.props;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const newGroups = groups.map<any>(({ items, ...group }) => ({
			...group,
			items: items.map((item) => ({
				...item,
				isDisabled: start ? comparator(start.value, item.value) > 0 : false,
			})),
		}));
		return {
			groups: newGroups,
			defaultValue: end,
			onChange: (item: RangeSelectorItem) => onRangeChange({ start, end: item }),
		};
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getSelectComponent = (level: RangeSelectorItem | null | undefined, itemSelectEl: any) => {
		if (level && level.label && level.name) {
			return (
				<Field label={level.label} name={level.name}>
					{() => {
						if (level.showTooltip) {
							return <Tooltip content={level.content}>{itemSelectEl}</Tooltip>;
						}
						return itemSelectEl;
					}}
				</Field>
			);
		}
		return itemSelectEl;
	};

	render() {
		const { children, end, start } = this.props;
		const [startSelect, separator, endSelect] = children;

		return (
			<>
				{this.getSelectComponent(start, cloneElement(startSelect, this.getStartSelectProps()))}
				{separator}
				{this.getSelectComponent(end, cloneElement(endSelect, this.getEndSelectProps()))}
			</>
		);
	}
}
