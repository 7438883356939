import { useEffect, useState } from 'react';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { COLUMN } from '../constants';

export const useIsColumnDragging = () => {
	const [isDragging, setIsDragging] = useState(false);

	useEffect(
		() =>
			monitorForElements({
				canMonitor: ({ source }) => source.data.type === COLUMN,
				onDragStart: () => setIsDragging(true),
				onDrop: () => setIsDragging(false),
			}),
		[],
	);

	return isDragging;
};
