import { getSelectOptions } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields';
import { getColorByViewSettings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings';
import { getSelectOptionsFromIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations';
import {
	addSelectColour,
	updateSelectColour,
	removeSelectColour,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/colour-by/actions.tsx';
import type {
	MapDispatchToPropsSimple,
	MapStateToProps,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import { connect } from '@atlassian/jira-react-redux';
import type { DispatchProps, StateProps } from './types';
import View from './view';

const mapStateToProps: MapStateToProps<StateProps> = (state) => ({
	colourByConfiguration: getColorByViewSettings(state),
	selectOptionsFromIssues: getSelectOptionsFromIssues(state),
	selectOptions: getSelectOptions(state),
});

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	addSelectColour: () => {
		dispatch(addSelectColour());
	},
	updateSelectColour: (payload) => {
		dispatch(updateSelectColour(payload));
	},
	removeSelectColour: (payload) => {
		dispatch(removeSelectColour(payload));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
