import {
	getUserPickerOptionsUserList,
	getUserPickerOptionsUserListById,
	getUserPickerUIState,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { OwnProps, StateProps } from './types';

export default createStructuredSelector<State, OwnProps, StateProps>({
	userList: getUserPickerOptionsUserList,
	userListById: getUserPickerOptionsUserListById,
	userPickerUIState: getUserPickerUIState,
});
