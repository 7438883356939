import React from 'react';
import { injectIntl } from '@atlassian/jira-intl';
import { useDateFormatter } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import { DATE_METHODS } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/types.tsx';
import { getStartDateMethod } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/utils.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { DIRECTIONS } from '../../constant';
import commonMessages from '../../messages';
import type { Props } from './types';

function StartDate(props: Props) {
	const { intl, start, delta, emptyMessage } = props;
	const { formatTimestamp } = useDateFormatter();

	if (start === undefined && delta === undefined) {
		return <span>{emptyMessage}</span>;
	}

	const {
		method,
		quantity = 0,
		unit = 0,
		direction = DIRECTIONS.AFTER,
	} = getStartDateMethod(start, delta);
	let message;

	if (method === DATE_METHODS.FIXED_DATE && isDefined(start)) {
		return <span>{formatTimestamp(start)}</span>;
	}
	if (method === DATE_METHODS.RELATIVE_TO_PREVIOUS_RELEASE) {
		if (quantity === 0) {
			message = intl.formatMessage(commonMessages.whenPreviousReleaseEnds);
			return <span>{message}</span>;
		}
		message = intl.formatMessage(commonMessages.relativeToPreviousRelease, {
			factor: `${quantity} ${unit} ${intl.formatMessage(commonMessages[direction])}`,
		});
		return <span>{message}</span>;
	}

	message = intl.formatMessage(commonMessages.asEarlyAsPossible);
	return <span>{message}</span>;
}

export default injectIntl(StartDate);
