import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { HistoryIssue } from '../../state/domain/history-issues/types';
import type { Issue } from '../../state/domain/issues/types';
import { isEstimated, getEstimateValue } from '../estimation';
import { getHistoryIssues } from '../issues';
import type { IssueMap } from '../issues/types';
import { getPlanningUnit } from '../plan';
import { getIssueMapById } from '../raw-issues/index.tsx';
import { getJiraHoursPerDay } from '../system';
import type { HistoryIssueTotalEstimationByParentIdMap } from './types';

export const getAncestors = (issue: Issue, issueMap: IssueMap): Issue[] => {
	const ancestorsIds: Array<string> = [];
	const ancestors: Issue[] = [];

	let currentIssue = issue;
	while (currentIssue && currentIssue.parent) {
		currentIssue = issueMap[currentIssue.parent];
		// It is entirely possible for an issue to have a parent, but that parent issue to NOT be in the plan
		if (!currentIssue) {
			break;
		}
		if (ancestorsIds.includes(currentIssue.id)) {
			// prevent getting into infinite loop
			break;
		}
		ancestorsIds.push(currentIssue.id);
		ancestors.push(currentIssue);
	}
	return ancestors;
};

export const getHistoryIssuesWithParentAsMap = (
	issuesById: IssueMap,
	historyIssues: HistoryIssue[],
) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const issueIdToChildIdsMap: Record<string, any> = {};
	historyIssues
		// if the history issue is available in the plan, filter it
		.filter((historyIssue) => !issuesById[historyIssue.id])
		.forEach((historyIssue) => {
			const { parent } = historyIssue;
			if (parent && !issueIdToChildIdsMap[parent]) {
				issueIdToChildIdsMap[parent] = [];
			}
			if (parent) {
				issueIdToChildIdsMap[parent].push(historyIssue);

				const ancestors = getAncestors(issuesById[parent], issuesById);
				ancestors.forEach((ancestor) => {
					if (!issueIdToChildIdsMap[ancestor.id]) {
						issueIdToChildIdsMap[ancestor.id] = [];
					}
					issueIdToChildIdsMap[ancestor.id].push(historyIssue);
				});
			}
		});
	return issueIdToChildIdsMap;
};

export const addChildIdsToArray = (
	targetArray: HistoryIssue[],
	parentId: string,
	historyIssuesWithParent: {
		[parentId: string]: HistoryIssue[];
	},
) => {
	const childHistoryIssues = historyIssuesWithParent[parentId] || [];
	childHistoryIssues.forEach((historyIssue) => {
		targetArray.push(historyIssue);
		addChildIdsToArray(targetArray, historyIssue.id, historyIssuesWithParent);
	});
};

export const getEstimateFromHistoryIssuesPure = (
	issuesById: IssueMap,
	historyIssues: HistoryIssue[],
	planningUnit: string,
	workingHours: number,
): HistoryIssueTotalEstimationByParentIdMap => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const estimateByHistoryIssueParentId: Record<string, any> = {};
	const historyIssuesWithParent = getHistoryIssuesWithParentAsMap(issuesById, historyIssues);

	Object.keys(historyIssuesWithParent).forEach((parentId) => {
		const descendants: HistoryIssue[] = [];

		// recursively get all the descendants for the top level historic issues
		addChildIdsToArray(descendants, parentId, historyIssuesWithParent);

		estimateByHistoryIssueParentId[parentId] = descendants.reduce(
			(acc, item) => {
				if (!isEstimated(planningUnit, item)) {
					acc.unEstimatedCount += 1;
				} else {
					acc.estimate = getEstimateValue(planningUnit, item, workingHours) + acc.estimate;
					acc.numberOfIssues += 1;
				}
				return acc;
			},
			{
				estimate: 0,
				numberOfIssues: 0,
				unEstimatedCount: 0,
			},
		);
	});

	return estimateByHistoryIssueParentId;
};

export const getEstimateFromHistoryIssues = createSelector(
	[getIssueMapById, getHistoryIssues, getPlanningUnit, getJiraHoursPerDay],
	getEstimateFromHistoryIssuesPure,
);
