import {
	getIssueTypesById,
	getIssueTypesByName,
	getIssueTypeIds,
	getIssueTypeIdsOfCompanyManagedProjects,
	getTeamManagedProjectByIssueTypeId,
	getIssueTypeIconByName,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types';
import {
	getProjectsById,
	getTeamManagedProjectsSortedByName,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects';
import { getColourIssueTypes } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { StateProps } from './types';

const mapStateToProps: MapStateToProps<StateProps> = (state) => ({
	colourIssueTypes: getColourIssueTypes(state),
	issueTypeIds: getIssueTypeIds(state),
	issueTypeIdsOfCompanyManagedProjects: getIssueTypeIdsOfCompanyManagedProjects(state),
	issueTypesById: getIssueTypesById(state),
	issueTypesByName: getIssueTypesByName(state),
	projectsById: getProjectsById(state),
	teamManagedProjectByIssueTypeId: getTeamManagedProjectByIssueTypeId(state),
	teamManagedProjectsSortedByName: getTeamManagedProjectsSortedByName(state),
	issueTypeIconByName: getIssueTypeIconByName(state),
});

export default mapStateToProps;
