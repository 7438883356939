import type { Dispatch } from 'redux';
import type { OptionType } from '@atlassian/jira-portfolio-3-common/src/select/types';
import { updateIssueOrInlineCreate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { DispatchProps } from './types';

const dispatchMethods: MapDispatchToPropsSimple<DispatchProps> = (dispatch: Dispatch) => ({
	onChange: (issue: ScopeIssue, labels: OptionType[]) => {
		const labelsValue = labels.map((label) => label.value);
		dispatch(
			updateIssueOrInlineCreate({
				id: issue.id,
				labels: labelsValue,
			}),
		);
	},
});

export default dispatchMethods;
