import { useCallback, useEffect, useMemo, useRef } from 'react';
import { graphql, useSubscription } from 'react-relay';
import { JiraIssueAri, JiraIssueTypeAri } from '@atlassian/ari/jira';
import type { issueBreakdownNewForPortfolio3Subscription } from '@atlassian/jira-relay/src/__generated__/issueBreakdownNewForPortfolio3Subscription.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { UseIssueBreakdownSubParams } from './types';

const SUBSCRIPTION_TIMEOUT = 120 * 1000; // 120s timeout

export function parseAri(issueTypeId: string | null | undefined) {
	return JiraIssueTypeAri.parse(issueTypeId || '');
}

export const useIssueBreakdownSubscription = (params: UseIssueBreakdownSubParams) => {
	const { query, onNextSuggestion, onNextStatus, onSuggestionError, onError, onTimeout } = params;
	const {
		issueTypeIds: issueTypeStringIds,
		acceptedChildIssues = [],
		rejectedChildIssues = [],
		additionalContext,
		channelId,
		issueId,
	} = query;

	const siteId = useCloudId();
	const sourceIssueId = JiraIssueAri.create({ issueId, siteId }).toString();

	const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
	const resetTimeout = useCallback(() => {
		if (onTimeout) {
			clearTimeout(timeoutRef.current);
			timeoutRef.current = setTimeout(() => onTimeout?.(), SUBSCRIPTION_TIMEOUT);
		}
	}, [onTimeout]);

	useEffect(() => {
		resetTimeout();
		return () => clearTimeout(timeoutRef.current);
	}, [resetTimeout]);

	useSubscription<issueBreakdownNewForPortfolio3Subscription>(
		useMemo(
			() => ({
				subscription: graphql`
					subscription issueBreakdownNewForPortfolio3Subscription(
						$sourceIssueId: ID!
						$channelId: String
						$additionalContext: String
						$excludeSimilarIssues: [JiraSuggestedIssueInput!]
						$issueTypeIds: [ID!]
					) {
						jira {
							onSuggestedChildIssue(
								sourceIssueId: $sourceIssueId
								issueTypeIds: $issueTypeIds
								channelId: $channelId
								additionalContext: $additionalContext
								excludeSimilarIssues: $excludeSimilarIssues
							) @optIn(to: "JiraIsIntelligentWorkBreakdownEnabled") {
								__typename
								... on JiraSuggestedIssue {
									summary
									description
									issueTypeId
								}
								... on JiraSuggestedChildIssueStatus {
									status
									channelId
								}
								... on JiraSuggestedChildIssueError {
									error
								}
							}
						}
					}
				`,
				variables: {
					channelId,
					additionalContext,
					sourceIssueId,
					excludeSimilarIssues: acceptedChildIssues.concat(rejectedChildIssues),
					issueTypeIds: issueTypeStringIds?.map((issueTypeId) =>
						JiraIssueTypeAri.create({ siteId, issueTypeId }).toString(),
					),
				},
				onNext: (data) => {
					resetTimeout();
					const subscriptionData = data?.jira?.onSuggestedChildIssue;
					// onNext is called with undefined data when the subscription is first established
					if (!subscriptionData) return;
					const { __typename } = subscriptionData;
					if (__typename === 'JiraSuggestedChildIssueStatus') {
						const { status } = subscriptionData;
						onNextStatus?.({ status, channelId: subscriptionData.channelId });
						return;
					}
					if (__typename === 'JiraSuggestedIssue') {
						const { summary, description, issueTypeId } = subscriptionData;
						const issueTypeAri = parseAri(issueTypeId || '');

						const suggestion = {
							summary: summary || '',
							description: description || '',
							issueTypeId: issueTypeAri.issueTypeId,
						};
						onNextSuggestion?.(suggestion);
						return;
					}
					if (__typename === 'JiraSuggestedChildIssueError') {
						const { error } = subscriptionData;
						onSuggestionError?.(error);
					}
				},
				onError,
			}),
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[sourceIssueId],
		),
	);
};
