import React, { useMemo, useCallback } from 'react';
import Tooltip from '@atlaskit/tooltip';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import { VIEW_MODES } from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import {
	AkSelect,
	selectComponents,
	type OptionProps,
} from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types';
import { getBody } from '@atlassian/jira-portfolio-3-portfolio/src/common/dom';
import {
	DEPENDENCY_SETTINGS,
	type DependencySettings as DependencySettingsType,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

const { LINES, DEFAULT, COUNT } = DEPENDENCY_SETTINGS;

export interface OptionType {
	label: React.ReactElement;
	value: DependencySettingsType;
}

export const displayOptions: OptionType[] = [
	{
		label: <FormattedMessage {...messages.default} />,
		value: DEFAULT,
	},
	{
		label: <FormattedMessage {...messages.lines} />,
		value: LINES,
	},
];

// Extend the default Option to have accessible tags (https://github.com/JedWatson/react-select/pull/4322#issuecomment-749134033)
const Option = (props: OptionProps<OptionType, false>) => {
	const innerProps = { ...props.innerProps, role: 'option', 'aria-disabled': props.isDisabled };
	const Elem: React.ElementType = selectComponents.Option;
	return <Elem {...props} innerProps={innerProps} />;
};

export default function DependencySettings({
	changeDisplay,
	dependencySettings,
	onMenuToggle,
	viewMode,
}: Props) {
	const { formatMessage } = useIntl();
	const { display } = dependencySettings;
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleOptionClick = useCallback(
		(setting: 'count' | 'default' | 'lines') => {
			changeDisplay({ display: setting });
		},
		[changeDisplay],
	);

	const onCheckSelected = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const nextValue = event.target.checked ? COUNT : DEFAULT;

			const [actionSubject, action, actionSubjectId] =
				PRODUCT_ANALYTICS_EVENT_NAMES.DEPENDENCY_TYPE_CLICKED.split(' ');
			fireUIAnalytics(createAnalyticsEvent({ actionSubject, action }), actionSubjectId, {
				newPlanSettingValue: nextValue,
			});

			handleOptionClick(nextValue);
		},
		[createAnalyticsEvent, handleOptionClick],
	);

	const triggerId = display === LINES ? LINES : DEFAULT;
	const selectedValue = useMemo(
		() => displayOptions.find(({ value }) => value === triggerId),
		[triggerId],
	);

	const onSelectChange = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		({ value }: any) => {
			const [actionSubject, action, actionSubjectId] =
				PRODUCT_ANALYTICS_EVENT_NAMES.DEPENDENCY_TYPE_CLICKED.split(' ');
			fireUIAnalytics(createAnalyticsEvent({ actionSubject, action }), actionSubjectId, {
				newPlanSettingValue: value === DEFAULT ? 'badges' : value,
			});

			changeDisplay({ display: value });
		},
		[changeDisplay, createAnalyticsEvent],
	);

	return (
		<>
			<Tooltip
				content={
					viewMode === VIEW_MODES.LIST ? <FormattedMessage {...messages.disabledTooltip} /> : null
				}
			>
				<AkSelect
					blurInputOnSelect
					closeMenuOnSelect
					isMulti={false}
					isDisabled={viewMode === VIEW_MODES.LIST}
					isSearchable={false}
					// Extend the default <Option /> component to render a better accessbile <div /> wrapper and makes easier write testing
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					components={{ Option } as { Option: React.FC }}
					menuPortalTarget={getBody()}
					onChange={onSelectChange}
					onMenuClose={() => onMenuToggle(false)}
					onMenuOpen={() => onMenuToggle(true)}
					options={displayOptions}
					styles={{
						// eslint-disable-next-line @typescript-eslint/no-shadow
						menuPortal: (styles) => ({
							...styles,
							zIndex: 1000,
						}),
					}}
					value={selectedValue}
					aria-label={formatMessage(messages.dependencySettingAriaLabel, {
						selectedOption: selectedValue?.value === DEFAULT ? 'Badges' : selectedValue?.value,
					})}
				/>
			</Tooltip>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.checkboxWrapper}>
				<Checkbox
					isDisabled={display === LINES}
					isChecked={display === COUNT}
					onChange={onCheckSelected}
					label={<FormattedMessage {...messages.count} />}
				/>
			</div>
		</>
	);
}
