import { createSelector } from '@atlassian/jira-portfolio-3-horizontal-scrolling';
import {
	useTimelineRuler,
	useHorizontalScrolling,
	createHorizontalScrollingHook,
} from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';

export type Position = {
	/** The left margin to the container. */
	left: number;
	/** The right margin to the container. */
	right: number;
};

export type PercentagePosition = {
	/** The left position to the container using %. */
	leftPositionPercentage: number;
	/** The right position to the container using %. */
	rightPositionPercentage: number;
};

/** The vertical scrollbar width. */
const SCROLLBAR_WIDTH = 18;

/** The space between the badge and the edge of the issue bar. */
const SPACING = 4;

/** The width of the arrow icons. */
const ARROW_WIDTH = 24;

/** Returns the viewport width for horizontal/non-horizontal mode. */
const useViewportWidth = createHorizontalScrollingHook<number, number>(
	createSelector(
		(_, fallback) => fallback,
		(state) => state.zoomLevel,
		(state) => state.viewport.width,
		(fallback, zoomLevel, viewportWidth) => (zoomLevel === undefined ? fallback : viewportWidth),
	),
);

/** Returns the viewport offset for horizontal/non-horizontal mode. */
const useViewportOffset = createHorizontalScrollingHook<number>(
	createSelector(
		(state) => state.zoomLevel,
		(state) => state.viewport.offset,
		(zoomLevel, viewportOffset) => (zoomLevel === undefined ? 0 : viewportOffset),
	),
);

function flip(position: Position): Position {
	return {
		left: position.right,
		right: position.left,
	};
}

/** Returns the position of the issue bar to the viewport. */
function useIssueBarPosition({
	viewportWidth,
	issueBarPercentagePosition: { leftPositionPercentage, rightPositionPercentage },
}: {
	viewportWidth: number;
	issueBarPercentagePosition: PercentagePosition;
}): Position {
	const [{ zoomLevel, viewport, container }] = useHorizontalScrolling();
	const [{ msToPx }] = useTimelineRuler();

	if (zoomLevel === undefined) {
		const left = (leftPositionPercentage / 100) * viewportWidth;
		const right = (rightPositionPercentage / 100) * viewportWidth;

		return { left, right };
	}

	const viewportOffsetInPx = msToPx(viewport.offset);
	const distanceToContainerStart = (leftPositionPercentage / 100) * container.width;
	const distanceToContainerEnd = (rightPositionPercentage / 100) * container.width;
	const width = container.width - (distanceToContainerEnd + distanceToContainerStart);

	const left = distanceToContainerStart - viewportOffsetInPx;
	const right = viewportWidth - (left + width);

	return { left, right };
}

/**
 * Returns the position of the left badge when it's ative (hovered)
 *
 * It's easier to calculate the possible value of the position when it's ative base on the space it needs
 * and the available space (calculated by the issue bar position and the paddings)
 *
 * The original position (when it's not hoverred) can be detected by whether the ative badge
 * is inside the bar or not.
 */
function getLeftBadgeActivePosition({
	viewportWidth,
	badgeWidth,
	issueBar,
	constraints,
}: {
	viewportWidth: number;
	badgeWidth: number;
	issueBar: Position;
	/** The margin to the edges */
	constraints: Position;
}): Position | null {
	if (issueBar.left === undefined || issueBar.right === undefined) {
		throw new Error('illegal state of issue bar position');
	}
	const hasRightArrow = issueBar.right < 0;
	const maxBarMarginLeft = viewportWidth - ((hasRightArrow ? ARROW_WIDTH : 0) + constraints.right);

	// - The issue bar is OFF the screen - to the right.
	if (issueBar.left >= viewportWidth) {
		return null;
	}

	// - The issue bar is VISIBLE on the viewport.
	// - There is ENOUGH space to display the active badge to the left of the bar.
	if (issueBar.left >= badgeWidth + SPACING + constraints.left) {
		return {
			left:
				Math.min(issueBar.left, maxBarMarginLeft) +
				// The badge width
				-badgeWidth +
				// The space with the issue bar
				-SPACING,
			right: NaN,
		};
	}

	const issueBarInnerSpace =
		viewportWidth +
		-(Math.max(issueBar.left, constraints.left) + Math.max(issueBar.right, constraints.right));

	// - The issue bar is VISIBLE on the viewport.
	// - There is NOT ENOUGH space to display the active badge to the left of the bar.
	// - The visibile part of the bar is width enough.
	if (issueBar.left >= constraints.left && issueBarInnerSpace >= badgeWidth + SPACING) {
		return {
			left: issueBar.left + SPACING,
			right: NaN,
		};
	}

	// - The issue bar is VISIBLE on the viewport.
	// - There is NOT ENOUGH space to display the badge to the left of the bar.
	// - The visibile part of the bar is width enough.
	// - The left arrow appears because the left end of the bar is OFF the screen.
	if (issueBarInnerSpace >= ARROW_WIDTH + badgeWidth + SPACING) {
		return {
			left: ARROW_WIDTH + SPACING + constraints.left,
			right: NaN,
		};
	}

	return null;
}

const getRightBadgeActivePosition: typeof getLeftBadgeActivePosition = (params) => {
	const result = getLeftBadgeActivePosition({
		...params,
		issueBar: flip(params.issueBar),
		constraints: flip(params.constraints),
	});

	if (result === null) {
		return null;
	}

	return flip(result);
};

const getLeftBadgeInactivePosition = ({
	issueBar,
	badgeActive,
}: {
	issueBar: Position;
	badgeActive: Position;
}): Position => {
	// The badge is inside the bar.
	if (badgeActive.left > issueBar.left) {
		return badgeActive;
	}

	return {
		left: issueBar.left + SPACING,
		right: NaN,
	};
};

const getRightBadgeInactivePosition: typeof getLeftBadgeInactivePosition = (params) =>
	flip(
		getLeftBadgeInactivePosition({
			...params,
			issueBar: flip(params.issueBar),
			badgeActive: flip(params.badgeActive),
		}),
	);

/**
 * Returns the "left" offset from the position whether it's the left or right badge.
 */
function getOffset(viewportWidth: number, badgeWidth: number, { left, right }: Position): number {
	// When it's the badge on the right, the `right` value was set from the flip()
	if (Number.isNaN(left) && !Number.isNaN(right)) {
		return viewportWidth - (badgeWidth + right);
	}

	return left;
}

type UseOffsetParams = {
	containerWidth: number;
	startOrEnd: 'start' | 'end';
	width: number;
	issueBar: PercentagePosition;
};

type UseOffsetResult = {
	offsetActive: number;
	offsetInactive: number;
} | null;

/** Returns the active offset and the inactive offset of the marker badges against the coordination origin. */
export const useOffsets = ({
	containerWidth,
	width: badgeWidth,
	startOrEnd,
	issueBar: issueBarPercentagePosition,
}: UseOffsetParams): UseOffsetResult => {
	const [viewportWidth] = useViewportWidth(containerWidth);
	const [viewportOffset] = useViewportOffset();
	const [{ msToPx }] = useTimelineRuler();
	const issueBarPosition = useIssueBarPosition({
		viewportWidth,
		issueBarPercentagePosition,
	});

	const getBadgePosition =
		startOrEnd === 'start' ? getLeftBadgeActivePosition : getRightBadgeActivePosition;

	const badgeActivePosition = getBadgePosition({
		viewportWidth,
		badgeWidth,
		issueBar: issueBarPosition,
		constraints: {
			left: 0,
			right: SCROLLBAR_WIDTH,
		},
	});

	if (badgeActivePosition === null) {
		return null;
	}

	const getBadgeInactivePosition =
		startOrEnd === 'start' ? getLeftBadgeInactivePosition : getRightBadgeInactivePosition;

	const badgeInactivePosition = getBadgeInactivePosition({
		issueBar: issueBarPosition,
		badgeActive: badgeActivePosition,
	});

	const offsetInactive =
		msToPx(viewportOffset) + getOffset(viewportWidth, badgeWidth, badgeInactivePosition);
	const offsetActive =
		msToPx(viewportOffset) + getOffset(viewportWidth, badgeWidth, badgeActivePosition);

	return { offsetInactive, offsetActive };
};
