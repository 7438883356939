import * as R from 'ramda';
import { updateRefs } from '../util';
import {
	RESET,
	type ResetAction,
	RANK,
	type RankAction,
	ADD,
	type AddAction,
	REMOVE,
	type RemoveAction,
	BULK_UPDATE,
	type BulkUpdateAction,
	UPDATE_REFERENCES,
	type UpdateReferencesAction,
} from './actions';
import type { Version } from './types';

const initialState: Version[] = [];

type Action =
	| ResetAction
	| AddAction
	| RemoveAction
	| RankAction
	| BulkUpdateAction
	| UpdateReferencesAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: Version[] = initialState, action: Action): Version[] => {
	switch (action.type) {
		case RESET:
			return [...action.payload];
		case ADD: {
			return [...state, action.payload];
		}
		case REMOVE: {
			const removedId = action.payload;
			return state.filter(({ id }) => id !== removedId);
		}
		case RANK: {
			const {
				payload: { id, anchor },
			} = action;
			if (anchor === undefined) {
				return state;
			}
			const movedVersionIndex = state.findIndex((issue) => issue.id === id);
			const anchorVersion = state.find((version) => version.id === anchor);
			if (!anchorVersion) {
				throw new Error(`Anchor with id ${anchor} not found!`);
			}
			return R.adjust(movedVersionIndex, R.assoc('lexoRank', anchorVersion.lexoRank), state);
		}
		case BULK_UPDATE: {
			const { payload } = action;
			const result: Array<Version> = [];
			for (const item of state) {
				const patch = payload[item.id];
				if (patch) {
					result.push(R.merge(item, patch));
				} else {
					result.push(item);
				}
			}
			return result;
		}
		case UPDATE_REFERENCES:
			return updateRefs(state, action.payload);
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
