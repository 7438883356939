import React, { type KeyboardEvent, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Tooltip from '@atlaskit/tooltip';
import { DropMenuItem } from '@atlassian/jira-portfolio-3-common/src/drop-menu/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

const MenuItem = ({
	menuItem,
	isDisabled,
	isSelected,
	tooltip,
	onMenuItemClick,
	children,
}: Props) => {
	const innerContent = (
		<DropMenuItem
			id={menuItem.id}
			onClick={(_: MouseEvent | KeyboardEvent, analyticsEvent: UIAnalyticsEvent) => {
				onMenuItemClick(analyticsEvent);
			}}
			key={menuItem.id}
			isDisabled={isDisabled}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={`${styles['menu-item']} ${isSelected === true ? styles.selected : ''}`}
		>
			{children}
		</DropMenuItem>
	);

	return isDisabled ? (
		<Tooltip content={tooltip || ''} position="right">
			{innerContent}
		</Tooltip>
	) : (
		innerContent
	);
};

export default MenuItem;
