import { useCallback } from 'react';
import { TargetTypes } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/scope/issues/selectable-issue/actions';
import type { Props, ToggleParams } from './types';
import { getId, getIssuesInRange } from './utils';

export const SelectableIssue = ({
	issues,
	lastSelected,
	descendantIds,
	toggleSelectedIssues,
	issueId,
	group,
	isSelected,
	hasChildSelected,
	children,
}: Props) => {
	const toggleSingle = useCallback(
		(on: boolean, { targetType }: ToggleParams) =>
			toggleSelectedIssues({
				ids: [issueId],
				isSelected: on,
				group,
				targetType,
			}),
		[group, issueId, toggleSelectedIssues],
	);

	const toggleExtending = useCallback(
		(on: boolean, { targetType }: ToggleParams) => {
			const issuesInBetween = getIssuesInRange({
				issues,
				lastSelected,
				issueId,
				group,
			});

			const ids: string[] = issuesInBetween.map((issue) => getId(issue) || '');

			return toggleSelectedIssues({
				ids,
				isSelected: on,
				group,
				targetType,
			});
		},
		[group, issueId, issues, lastSelected, toggleSelectedIssues],
	);

	const toggleDescendants = useCallback(
		(on: boolean, { targetType }: ToggleParams): void => {
			toggleSelectedIssues({
				ids: descendantIds,
				currentIssueId: issueId,
				isSelected: on,
				group,
				targetType,
			});
		},
		[descendantIds, group, issueId, toggleSelectedIssues],
	);

	const toggleSingleAndDescendants = useCallback(
		(on: boolean, { targetType }: ToggleParams): void =>
			toggleSelectedIssues({
				ids: [issueId].concat(descendantIds),
				isSelected: on,
				group,
				targetType,
			}),
		[descendantIds, group, issueId, toggleSelectedIssues],
	);

	const toggle = useCallback(
		(on: boolean, toggleParams: ToggleParams) => {
			const { extending, targetType } = toggleParams;

			if (extending && lastSelected.id) {
				return toggleExtending(on, toggleParams);
			}

			switch (targetType) {
				case TargetTypes.single:
					return toggleSingle(on, toggleParams);

				case TargetTypes.singleAndDescendants:
					return toggleSingleAndDescendants(true, toggleParams);

				case TargetTypes.descendants:
					return toggleDescendants(true, toggleParams);

				default:
					throw new TypeError('unexpected targetType');
			}
		},
		[
			lastSelected?.id,
			toggleDescendants,
			toggleExtending,
			toggleSingle,
			toggleSingleAndDescendants,
		],
	);

	return children({ isSelected, hasChildSelected, toggleSelected: toggle });
};

export default SelectableIssue;
