import {
	resetGoals,
	searchGoals,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue-goals';

const mapDispatchToProps = {
	resetGoals,
	searchGoals,
};

export default mapDispatchToProps;
