import { useCallback, useRef, useState } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fetchSuggestedChildIssuesData } from '@atlassian/jira-issue-fetch-services/src/services/issue-breakdown-data-fetcher/index.tsx';
import type { FetchSuggestedChildIssueParams } from '@atlassian/jira-issue-fetch-services/src/services/issue-breakdown-data-fetcher/types.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { SuggestedChildIssuesGiraResponse } from './types';

/**
 * Hook to fetch suggested child issues
 * @returns
 */
export const useFetchSuggestedChildIssues = (
	onSuccess?: (response: SuggestedChildIssuesGiraResponse) => void,
	onFailure?: () => void,
) => {
	const requestSignal = useRef<null | AbortController>(null);

	const [isFetchingSuggestedChildIssues, setIsFetchingSuggestedChildIssue] =
		useState<boolean>(false);

	const cloudId = useCloudId();

	const cancelFetchSuggestedChildIssues = useCallback(() => {
		if (requestSignal.current && isFetchingSuggestedChildIssues) {
			requestSignal.current.abort();
			setIsFetchingSuggestedChildIssue(false);
		}
	}, [isFetchingSuggestedChildIssues]);

	const triggerFetchSuggestedChildIssues = useCallback(
		async (params: FetchSuggestedChildIssueParams) => {
			if (!params.parentIssueId) {
				log.safeErrorWithoutCustomerData(
					'issue.issue-view-services.src.child-issues.use-fetched-suggested-child-issuses',
					'Error fetching suggested child issues',
				);
				onFailure?.();
				return;
			}

			// If there's currently a request signal, abort so we can set a new one
			if (requestSignal.current) {
				requestSignal.current.abort();
			}
			try {
				requestSignal.current = new AbortController();
				setIsFetchingSuggestedChildIssue(true);
				const response = await fetchSuggestedChildIssuesData(
					cloudId,
					params,
					requestSignal.current.signal,
				);
				onSuccess?.(response);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				if (error.name === 'AbortError') {
					return;
				}

				log.safeErrorWithoutCustomerData(
					'issue.issue-view-services.src.child-issues.use-fetched-suggested-child-issuses',
					'Error fetching suggested child issues',
					error instanceof Error ? error : undefined,
				);

				onFailure?.();
			} finally {
				setIsFetchingSuggestedChildIssue(false);
			}
		},
		[cloudId, onFailure, onSuccess],
	);

	return {
		triggerFetchSuggestedChildIssues,
		cancelFetchSuggestedChildIssues,
		isFetchingSuggestedChildIssues,
	};
};
