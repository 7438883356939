import { getMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app';
import { getIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/index.tsx';
import { EDIT } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { getEnhancedIssues } from '../../scope/issues/query';

export default createSelector(
	[getEnhancedIssues, getMode, getIssues],
	(issues, mode, issuesInPlan) => ({
		isReadOnly: mode !== EDIT,
		issues,
		noIssuesInPlan: issuesInPlan.length === 0,
	}),
);
