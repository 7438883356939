import { getCustomFields } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields';
import { getColorByValue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { getColorByList } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/color-by-legend/query';
import type { StateProps } from './types';

export default createStructuredSelector<State, {}, StateProps>({
	colorByValue: getColorByValue,
	colorByList: getColorByList,
	customFields: getCustomFields,
});
