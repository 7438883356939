import * as R from 'ramda';
import type { Plan } from '../../state/domain/plan/types';
import type { Sequence } from '../../state/domain/sequence/types';

export const urls = {
	getScenarioChangesMetadata: '/rest/jpo/1.0/scenario/metadata',
	updateLastCommittedTimestamp: (planId: number) => `/rest/jpo/1.0/plans/${planId}/lastcommit`,
} as const;

export const sequenceKeys = [
	'issues',
	'versions',
	'teams',
	'persons',
	'resources',
	'xprojectversions',
	'themes',
	'skills',
	'stages',
	'abilities',
	'issuelinks',
	'plannedcapacity',
] as const;

type GetChangesMetadataBody = {
	planId: number;
	scenarioId: number;
	sequence: {
		[key: string]: {
			current: number;
		};
	};
};

export const getChangesMetadataBody = (
	{ id: planId, currentScenarioId: scenarioId }: Plan,
	sequence: Sequence,
) => {
	if (!planId || !scenarioId) {
		throw new Error('Need both plan and scenario to commit or get changes');
	}
	const payload: GetChangesMetadataBody = {
		planId,
		scenarioId,
		sequence: R.fromPairs(
			sequenceKeys.map((key) => [key, { current: sequence[key]?.current || 0 }]),
		),
	};
	return payload;
};
