import { Component, type ChangeEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { ff } from '@atlassian/jira-feature-flagging';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types';
import { TargetTypes } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/scope/issues/selectable-issue/actions.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Props } from './types';

export const NONE_SELECTED = 'roadmap.scope.all-issues-select.NONE_SELECTED' as const;
export const ALL_SELECTED = 'roadmap.scope.all-issues-select.ALL_SELECTED' as const;
export const SOME_SELECTED = 'roadmap.scope.all-issues-select.SOME_SELECTED' as const;

type CheckboxState = typeof NONE_SELECTED | typeof ALL_SELECTED | typeof SOME_SELECTED;

// eslint-disable-next-line jira/react/no-class-components
export default class AllIssuesSelect extends Component<Props> {
	// eslint-disable-next-line react/sort-comp
	toggleAllSelectedOld = (isSelected: boolean) => {
		const { toggleSelectedIssues, issuesInFilterById, selectedIssuesIds } = this.props;
		return toggleSelectedIssues({
			ids: isSelected ? Array.from(issuesInFilterById) : Array.from(selectedIssuesIds),
			isSelected,
			toggleAll: true,
			targetType: TargetTypes.all,
		});
	};

	toggleAllSelected = (_: ChangeEvent<HTMLInputElement>, analyticsEvent: UIAnalyticsEvent) => {
		const checkboxState = this.getCheckboxState();
		const nextState = checkboxState !== ALL_SELECTED ? ALL_SELECTED : NONE_SELECTED;
		const { toggleSelectedIssues, issuesInFilterById, selectedIssuesIds } = this.props;
		const isSelected = nextState === ALL_SELECTED;

		toggleSelectedIssues({
			ids: isSelected ? Array.from(issuesInFilterById) : Array.from(selectedIssuesIds),
			isSelected,
			toggleAll: true,
			targetType: TargetTypes.all,
		});

		if (nextState === ALL_SELECTED) {
			this.triggerAllSelectedAnalytics(analyticsEvent);
		}
	};

	triggerAllSelectedAnalytics = (analyticsEvent: UIAnalyticsEvent) => {
		const [actionSubject, eventAction] =
			PRODUCT_ANALYTICS_EVENT_NAMES.CLICKED_SELECT_ISSUES.split(' ');
		fireUIAnalytics(
			analyticsEvent.update({ action: eventAction, actionSubject }),
			PRODUCT_ANALYTICS_EVENT_NAMES.CLICKED_SELECT_ISSUES,
			{
				selectOption: TargetTypes.all,
			},
		);
	};

	getCheckboxState = (): CheckboxState => {
		const { selectedIssuesIds, issuesInFilterById } = this.props;

		let selectedIssuesInFilter = 0;
		for (const id of selectedIssuesIds) {
			if (issuesInFilterById.has(id)) selectedIssuesInFilter += 1;
		}

		if (selectedIssuesInFilter === 0) {
			return NONE_SELECTED;
		}
		if (selectedIssuesInFilter >= issuesInFilterById.size) {
			return ALL_SELECTED;
		}
		return SOME_SELECTED;
	};

	shouldComponentUpdate(nextProps: Props) {
		return !nextProps.isDragging;
	}

	render() {
		const { selectedIssuesIds } = this.props;
		const checkboxState = this.getCheckboxState();
		const isSelected = checkboxState !== NONE_SELECTED;
		const isIndeterminate = checkboxState === SOME_SELECTED;
		const nextState = checkboxState !== ALL_SELECTED ? ALL_SELECTED : NONE_SELECTED;

		return this.props.children({
			totalSelected: selectedIssuesIds.size,
			isIndeterminate,
			isSelected,
			toggleAllSelected: ff('com.atlassian.rm.jpo.transposition')
				? this.toggleAllSelected
				: // The previous one isn't memoed, make the checkbox re-rendered unnecessarily
					(_, analyticsEvent: UIAnalyticsEvent) => {
						this.toggleAllSelectedOld(nextState === ALL_SELECTED);
						if (nextState === ALL_SELECTED) {
							this.triggerAllSelectedAnalytics(analyticsEvent);
						}
					},
		});
	}
}
