import React from 'react';
import cx from 'classnames';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, DialogMenuGroupProps } from './types';

export function DialogMenuContainer({ children, style, className }: Props) {
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div style={style || {}} className={cx(styles.container, className)}>
			{children}
		</div>
	);
}

export function DialogMenuItem({ children, style, className }: Props) {
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div style={style || {}} className={cx(styles.item, className)}>
			{children}
		</div>
	);
}

export function DialogMenuGroup({ children, heading, style }: DialogMenuGroupProps) {
	return (
		<div>
			{heading && (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				<div className={styles['group-heading']} style={style || {}}>
					{heading}
				</div>
			)}
			{children}
		</div>
	);
}
