import React from 'react';
import DependenciesFlyout from '../../fields/columns/cells/dependencies/flyout';
import type { Props } from './types';

export default function DependenciesFlyoutDialogContent({
	direction,
	issue,
	filterValue,
	externalIssueLinks,
	internalIssueLinks,
	originalIssueLinks,
}: Props) {
	return (
		<DependenciesFlyout
			direction={direction}
			issue={issue}
			externalIssueLinks={externalIssueLinks}
			internalIssueLinks={internalIssueLinks}
			internalOriginalIssueLinks={originalIssueLinks || []}
			context="badge"
			filterValue={filterValue}
		/>
	);
}
