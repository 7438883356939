// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ReactElement, type ComponentProps } from 'react';
import escape from 'lodash/fp/escape';
import memoizeOne from 'memoize-one';
import * as R from 'ramda';
import { Box, xcss } from '@atlaskit/primitives';
import type { IntlShape } from '@atlassian/jira-intl';
import {
	type ViewMode,
	VIEW_MODES,
} from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import type { SectionData } from '@atlassian/jira-portfolio-3-common/src/sections/types.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import {
	SECTION_ITEM_KEYS,
	DEFAULT_FIELD_WIDTH,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import type { MainSectionsData } from './types';

const MIN_VIEWPORT_WIDTH = 1024;
const ONBOARDING_MESSAGE_WIDTH = 320;

export const getHighlightRowId = (
	group: string | null | undefined,
	issueId: string | undefined = '',
	// the _.escape() method is used to convert the characters “&”, “<“, “>”, ‘”‘, and “‘” of given string into their corresponding HTML entities
	// we use it to prevent errors being thrown in the console when the plan is grouped by a multi-select field and a group name has special characters e.g.: Issues with "test"
	// these errors were preventing the grey highlighting to work when hovering over an issue row in the timeline
	// see https://hello.atlassian.net/browse/JPO-23324 for reference
) => escape(isDefined(group) ? `${group}_${issueId}` : issueId);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCurrentValue = (issue: any, attribute: string): any =>
	issue.inferred && R.has(attribute, issue.inferred) ? undefined : issue[attribute];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getOptimizedValue = (issue: any, attribute: string): any =>
	issue.optimized && R.has(attribute, issue.optimized)
		? issue.optimized[attribute]
		: getCurrentValue(issue, attribute);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasValueChanged = (currentValue: any, optimizedValue: any) => {
	if (R.isNil(currentValue) && R.isNil(optimizedValue)) {
		return false;
	}

	return !R.equals(currentValue, optimizedValue);
};

export const getInlineCreateIssueTypeId = (
	level: number,
	inlineCreateProject: Project,
	props: {
		issueTypeId: number;
		IssueTypeIdForHierarchy?: {
			[key: number]: number;
		};
		typeToLevel: (arg1: number) => number;
	},
): number => {
	const { issueTypeId, IssueTypeIdForHierarchy, typeToLevel } = props;

	// Give preference to IssueTypeIdForHierarchy as it stores the most recent user's choice.
	if (
		IssueTypeIdForHierarchy &&
		isDefined(IssueTypeIdForHierarchy[level]) &&
		R.contains(IssueTypeIdForHierarchy[level], inlineCreateProject.issueTypeIds) &&
		typeToLevel(IssueTypeIdForHierarchy[level]) === level
	) {
		return IssueTypeIdForHierarchy[level];
	}

	// Then try given issueTypeId if it fits.
	if (
		R.contains(issueTypeId, inlineCreateProject.issueTypeIds) &&
		typeToLevel(issueTypeId) === level
	) {
		return issueTypeId;
	}

	// And finally try to find anything suitable among project issue types.
	const inlineCreateIssueTypeId = inlineCreateProject.issueTypeIds.find(
		(id) => typeToLevel(id) === level,
	);

	if (!isDefined(inlineCreateIssueTypeId)) {
		throw new Error(`inlineCreateProject doesn't have issue type corresponding to the ${level}`);
	}

	return inlineCreateIssueTypeId;
};

export const toggleRowHighlighter = (id: string, isOn: boolean) => {
	const opacity = isOn ? '0.08' : '0';
	const query = `[data-highlight-row="${id}"]`;
	// Unable to use @ts-expect-error it conflicts with typecheck with project references according to which ts-expect error is not needed and existing typecheck requires it, hence ignoring it till TS project references are enabled
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore - error TS2488: Type 'NodeListOf<Element>' must have a '[Symbol.iterator]()' method that returns an iterator.

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	for (const element of document.querySelectorAll(query)) {
		// Unable to use @ts-expect-error it conflicts with typecheck with project references according to which ts-expect error is not needed and existing typecheck requires it, hence ignoring it till TS project references are enabled
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore - TS2339 - Property 'style' does not exist on type 'Element'.
		element.style.opacity = opacity;
	}
};

export const shouldSetOnboardingWidth = (section: SectionData, containerWidth: number) => {
	if (containerWidth < MIN_VIEWPORT_WIDTH) {
		return false;
	}
	if (section.id === 'scope-column' && section.width + ONBOARDING_MESSAGE_WIDTH >= containerWidth) {
		return true;
	}
	if (
		section.id === 'fields-column' &&
		(section.width <= section.minWidth ||
			section.width + 2 * ONBOARDING_MESSAGE_WIDTH > containerWidth)
	) {
		return true;
	}
	return section.id === 'timeline-column' && section.width <= section.minWidth;
};

export const getSectionsData = memoizeOne(({ formatMessage }: IntlShape, viewMode: ViewMode) => {
	const sections: MainSectionsData[] = [
		{
			initialWidth: 300,
			minWidth: 300,
			isFixed: viewMode === VIEW_MODES.TIMELINE,
			id: SECTION_ITEM_KEYS.SCOPE,
			title: formatMessage(commonMessages.scope),
			collapsible: false,
			onboardingWidth: viewMode === VIEW_MODES.TIMELINE ? 30 : 50,
		},
		{
			initialWidth: 3 * DEFAULT_FIELD_WIDTH.LARGE,
			minWidth: 32,
			isFixed: viewMode === VIEW_MODES.TIMELINE,
			id: SECTION_ITEM_KEYS.FIELDS,
			title: formatMessage(commonMessages.fields),
			collapsible: viewMode === VIEW_MODES.TIMELINE,
			onboardingWidth: viewMode === VIEW_MODES.TIMELINE ? 35 : 50,
		},
	];

	if (viewMode === VIEW_MODES.TIMELINE) {
		sections.push({
			initialWidth: 100,
			minWidth: 32,
			isFixed: false,
			id: SECTION_ITEM_KEYS.TIMELINE,
			title: formatMessage(commonMessages.timeline),
			collapsible: true,
			onboardingWidth: 35,
		});
	}

	return sections;
});

export const getExperienceName = (
	isReportMode: boolean,
	isConfluenceMacro: boolean,
	isEmbedReportView: boolean,
): string => {
	if (isEmbedReportView) {
		return 'viewPortfolio3Embed';
	}
	if (isConfluenceMacro) {
		return 'viewPortfolio3ConfluenceEmbed';
	}
	if (isReportMode) {
		return 'viewPortfolio3Report';
	}
	return 'viewPortfolio3Roadmap';
};

export const getRowHighlighter = (
	group: string | null | undefined,
	issueId: string,
): ReactElement<ComponentProps<'div'>> => (
	<Box xcss={getRowHighlighterStyles} data-highlight-row={getHighlightRowId(group, issueId)} />
);
const getRowHighlighterStyles = xcss({
	position: 'absolute',
	pointerEvents: 'none',
	backgroundColor: 'color.background.neutral.bold.pressed',
	height: '100%',
	width: '100%',
	opacity: 0,
});
