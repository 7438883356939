/*
 * Copied from src/packages/portfolio-3/portfolio/src/app-simple-plans/view/main/tabs/roadmap/fields/utils.tsx
 */
import {
	VIEW_MODES,
	type ViewMode,
} from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import { DEFAULT_FIELD_WIDTH } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import {
	defaultWidthOfColumnList,
	defaultWidthOfColumnTimeline,
} from '../../state/domain/view-settings/field-columns/types';
import {
	type TableItem,
	TABLE_ISSUE,
	TABLE_INLINE_CREATE,
	TABLE_HEADER,
	TABLE_GROUP_HEADER,
	TABLE_BLANK_ROW,
	TABLE_GROUP,
} from '../table';

export const getDefaultWidth = (id: string | undefined, viewMode: ViewMode) => {
	const defaultWidthOfColumn =
		viewMode === VIEW_MODES.TIMELINE ? defaultWidthOfColumnTimeline : defaultWidthOfColumnList;

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const columnId = (id as keyof typeof defaultWidthOfColumn) ?? 'DEFAULT';
	const columnWidth = defaultWidthOfColumn[columnId];

	return columnWidth ?? defaultWidthOfColumn.DEFAULT;
};

export const getMinWidth = (id: string | undefined, isCollapsible: boolean, viewMode: ViewMode) => {
	if (isCollapsible) return 24;
	if (viewMode === VIEW_MODES.TIMELINE) return DEFAULT_FIELD_WIDTH.MEDIUM;

	return getDefaultWidth(id, viewMode);
};

export const getGridRowId = (item: TableItem, index: number): string => {
	switch (item.tag) {
		case TABLE_ISSUE:
			return `${item.tag}-${item.group || ''}-${item.value.id}`;

		case TABLE_INLINE_CREATE:
			return `${item.tag}-${item.group || ''}-${item.value.id}`;

		case TABLE_HEADER:
		case TABLE_GROUP_HEADER:
		case TABLE_BLANK_ROW:
		case TABLE_GROUP:
			return `${item.tag}-${index}`;

		default:
			return `${index}`;
	}
};
