import { type Effect, delay } from 'redux-saga';
import * as R from 'ramda';
import { call, select, takeLatest, takeEvery, fork, put } from 'redux-saga/effects';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type * as Api from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import type { AnalyticsEventMeta } from '../../analytics/types';
import { getMode } from '../../query/app';
import { getSelectedIssues } from '../../query/issues';
import { getPlan } from '../../query/plan';
import {
	getSolution,
	getIsSolutionLatest,
	getIsSolutionValid,
	getApplyingProgress,
} from '../../query/solution';
import { setMode } from '../../state/domain/app/actions';
import { EDIT, OPTIMIZED, OPTIMIZING } from '../../state/domain/app/types';
import { update as updateSequence } from '../../state/domain/sequence/actions';
import {
	setProgress,
	setLatest,
	setValidity as setSolutionValidity,
} from '../../state/domain/solution/actions';
import { GET, POST, parseError } from '../api';
import { getBacklog } from '../backlog';
import { genericError, type GenericErrorActionPayload } from '../errors';
import { getPlanInfo } from '../plan';
import { getChangesMetadata } from '../update-jira';
import { urls } from './api';

export const START_OPTIMIZE = 'command.optimize.START_OPTIMIZE' as const;
export const CANCEL_OPTIMIZE = 'command.optimize.CANCEL_OPTIMIZE' as const;

export const APPLY_CHANGE = 'command.optimize.APPLY_CHANGE' as const;
export const BEFORE_APPLY_CHANGE = 'command.optimize.BEFORE_APPLY_CHANGE' as const;
export const AFTER_APPLY_CHANGE = 'command.optimize.AFTER_APPLY_CHANGE' as const;
export const BEFORE_OPTIMIZE_CALCULATION = 'command.optimize.BEFORE_OPTIMIZE_CALCULATION' as const;
export const AFTER_OPTIMIZE_CALCULATION = 'command.optimize.AFTER_OPTIMIZE_CALCULATION' as const;

export type StartOptimizeAction = {
	type: typeof START_OPTIMIZE;
	meta: AnalyticsEventMeta;
};

export type CancelOptimizeAction = {
	type: typeof CANCEL_OPTIMIZE;
};

export type BeforeApplyChangeAction = {
	type: typeof BEFORE_APPLY_CHANGE;
};

export type AfterApplyChangeAction = {
	type: typeof AFTER_APPLY_CHANGE;
};
export type BeforeOptimizeCalculationAction = {
	type: typeof BEFORE_OPTIMIZE_CALCULATION;
};

export type AfterOptimizeCalculationAction = {
	type: typeof AFTER_OPTIMIZE_CALCULATION;
	meta: AnalyticsEventMeta;
};
export type ApplyChangeAction = {
	type: typeof APPLY_CHANGE;
};
export const startOptimize = (analyticsEvent: UIAnalyticsEvent): StartOptimizeAction => ({
	type: START_OPTIMIZE,
	meta: { analyticsEvent },
});

export const cancelOptimize = (): CancelOptimizeAction => ({
	type: CANCEL_OPTIMIZE,
});

export const applyChange = (): ApplyChangeAction => ({
	type: APPLY_CHANGE,
});

export const beforeApplyChange = (): BeforeApplyChangeAction => ({
	type: BEFORE_APPLY_CHANGE,
});
export const afterApplyChange = (): AfterApplyChangeAction => ({
	type: AFTER_APPLY_CHANGE,
});
export const beforeOptimizeCalculation = (): BeforeOptimizeCalculationAction => ({
	type: BEFORE_OPTIMIZE_CALCULATION,
});
export const afterOptimizeCalculation = (
	analyticsEvent: UIAnalyticsEvent,
): AfterOptimizeCalculationAction => ({
	type: AFTER_OPTIMIZE_CALCULATION,
	meta: { analyticsEvent },
});
const APPLY_CHANGES_POLL_DELAY = 1000;
const AUTO_SCHEDULE_FIRST_POLL_DELAY = 300;
const AUTO_SCHEDULE_NEXT_POLL_DELAY = 1000;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* optimizeError(error: GenericErrorActionPayload): Generator<Effect, any, any> {
	yield put(genericError(error));
	yield put(cancelOptimize());
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* checkSolutionLatest(): Generator<Effect, any, any> {
	const url = urls.pollOptimize;
	const { id: planId, currentScenarioId: scenarioId } = yield select(getPlan);
	const body = { planId, scenarioId };
	const calculationId = (yield select(getSolution)).id;

	try {
		const response = yield call(fetch, url, {
			method: POST,
			body,
		});

		if (response.ok) {
			const { done, running } = yield call(response.json.bind(response));
			if (done && done.id === calculationId && !running) {
				yield put(setLatest(true));
			} else {
				yield put(setLatest(false));
			}
		} else {
			yield call(optimizeError, {
				...parseError(response, yield call(response.text.bind(response))),
				requestInfo: {
					url,
					type: POST,
					status: response.status,
					body,
				},
			});
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		const errorPayload: GenericErrorActionPayload = { message: e.message, stackTrace: e.stack };
		yield call(optimizeError, errorPayload);
	}
}

export function* pollOptimize(
	id: number,
	body: {
		planId: number;
		scenarioId: number;
	},
	meta: AnalyticsEventMeta, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const url = urls.pollOptimize;
	yield call(delay, AUTO_SCHEDULE_FIRST_POLL_DELAY);
	while (true) {
		if ((yield select(getMode)) !== OPTIMIZING) break;

		const response = yield call(fetch, url, {
			method: POST,
			body,
		});

		if ((yield select(getMode)) !== OPTIMIZING) break;

		if (response.ok) {
			const { done, running } = yield call(response.json.bind(response));
			// done.id >= id  --> there may be multiple calculations running at the same time for this plan, so we may get 'id' which is greater
			if (done && done.id >= id) {
				yield put(afterOptimizeCalculation(meta.analyticsEvent));
				yield call(getBacklog);
				yield put(setSolutionValidity(true));
				yield put(setMode(OPTIMIZED));
				break;
			} else if (!(running && running.id >= id)) {
				yield call(optimizeError, { message: 'Unexpected response' });
				break;
			}
		} else {
			yield call(optimizeError, {
				...parseError(response, yield call(response.text.bind(response))),
				requestInfo: {
					url,
					type: POST,
					status: response.status,
					body,
				},
			});
			break;
		}
		yield call(delay, AUTO_SCHEDULE_NEXT_POLL_DELAY);
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doOptimize(meta: AnalyticsEventMeta): Generator<Effect, any, any> {
	yield put(setMode(OPTIMIZING));
	const url = urls.startOptimize;
	const {
		id: planId,
		currentScenarioId: scenarioId,
		autoScheduleConfiguration: basicCalculationConfiguration,
	} = yield select(getPlan);

	const selectedIssueIdsToSchedule = R.map((issue) => issue.id, yield select(getSelectedIssues));

	const calculationConfiguration =
		selectedIssueIdsToSchedule.length > 0
			? R.assoc('selectedIssueIds', selectedIssueIdsToSchedule, basicCalculationConfiguration)
			: basicCalculationConfiguration;

	const body = { planId, scenarioId, calculationConfiguration };
	try {
		yield put(beforeOptimizeCalculation());
		const response = yield call(fetch, url, {
			method: POST,
			body,
		});
		if (response.ok) {
			const { id } = yield call(response.json.bind(response));
			yield call(pollOptimize, id, body, meta);
		} else if (response.status === 404) {
			yield call(optimizeError, { message: '', description: commonMessages.planNotFound });
		} else {
			yield call(optimizeError, {
				...parseError(response, yield call(response.text.bind(response))),
				requestInfo: {
					url,
					type: POST,
					status: response.status,
					body,
				},
			});
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		const errorPayload: GenericErrorActionPayload = { message: e.message, stackTrace: e.stack };
		yield call(optimizeError, errorPayload);
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* applyError(error: GenericErrorActionPayload): Generator<Effect, any, any> {
	yield put(genericError(error));
	yield put(setProgress(null));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* pollApply(id: string): Generator<Effect, any, any> {
	const url = urls.pollApplying(id);
	while (true) {
		yield call(delay, APPLY_CHANGES_POLL_DELAY);

		if ((yield select(getMode)) !== OPTIMIZED) {
			yield put(setProgress(null)); // Cancel.
			break;
		}

		const response = yield call(fetch, url, {
			method: GET,
		});

		if (response.ok) {
			const { done, progressPercent: progress } = yield call(response.json.bind(response));

			if (done) {
				yield call(getBacklog);
				const result: Api.PlanInfo = yield call(getPlanInfo);
				if (!result) return;
				const { sequence } = result;
				yield put(updateSequence(sequence));

				yield put(getChangesMetadata());

				yield put.resolve(afterApplyChange());
				yield put(setMode(EDIT));
				break;
			} else if (isDefined(progress)) {
				yield put(setProgress(progress));
			} else {
				yield call(applyError, { message: 'Unexpected response' });
				break;
			}
		} else {
			yield call(applyError, {
				...parseError(response, yield call(response.text.bind(response))),
				requestInfo: {
					url,
					type: GET,
					status: response.status,
				},
			});
			break;
		}
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doApply(): Generator<Effect, any, any> {
	// Abort if we have no calculation id (nothing to commit) or we're already in progress.
	const calculationId = (yield select(getSolution)).id;
	const progress = yield select(getApplyingProgress);
	if (!isDefined(calculationId) || isDefined(progress)) {
		return;
	}

	yield call(checkSolutionLatest);
	const isSolutionLatest = yield select(getIsSolutionLatest);

	// Start progress!
	yield put(setProgress(0));

	if (!isSolutionLatest) {
		// Instead of introducing another UI state property to show 'Out of sync' notification,
		// we use 'Active applying' and 'Not latest solution' state properties to trigger a modal
		// window notification. This is why we start and then stop progress before the return
		// statement.
		yield put(setProgress(null));
		return;
	}

	const url = urls.applyChange;
	const { id: planId, currentScenarioId: scenarioId } = yield select(getPlan);
	const body = { planId, scenarioId, calculationId };
	try {
		yield put(beforeApplyChange());
		const response = yield call(fetch, url, {
			method: POST,
			body,
		});
		if (response.ok) {
			const { taskId: id } = yield call(response.json.bind(response));
			yield call(pollApply, id);
		} else {
			yield call(applyError, {
				...parseError(response, yield call(response.text.bind(response))),
				requestInfo: {
					url,
					type: POST,
					status: response.status,
					body,
				},
			});
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		const errorPayload: GenericErrorActionPayload = { message: e.message, stackTrace: e.stack };
		yield call(applyError, errorPayload);
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doStartOptimize({ meta }: StartOptimizeAction): Generator<Effect, any, any> {
	yield put(setMode(OPTIMIZING));
	const isSolutionValid = yield select(getIsSolutionValid);
	// checkSolutionLatest is heavy as it performs a REST call -> so we're skipping it if it's not needed
	if (isSolutionValid) {
		yield call(checkSolutionLatest);
	}
	const isSolutionLatest = yield select(getIsSolutionLatest);
	if (isSolutionValid && isSolutionLatest) {
		yield put(setMode(OPTIMIZED));
	} else {
		yield call(doOptimize, meta);
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doCancelOptimize(): Generator<Effect, any, any> {
	yield put(setMode(EDIT));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchStartOptimize(): Generator<Effect, any, any> {
	yield takeLatest(START_OPTIMIZE, doStartOptimize);
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchCancelOptimize(): Generator<Effect, any, any> {
	yield takeLatest(CANCEL_OPTIMIZE, doCancelOptimize);
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchStartApplying(): Generator<Effect, any, any> {
	yield takeEvery(APPLY_CHANGE, doApply);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchStartOptimize);
	yield fork(watchCancelOptimize);
	yield fork(watchStartApplying);
}
