import { VIEW_MODES } from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { getNonTimelineWidth } from '@atlassian/jira-portfolio-3-portfolio/src/common/window';
import type { State } from '../../state/types';
import { isReportMode } from '../app';
import { getViewMode } from '../view-settings';

export const getSectionWidths = (state: State) => state.ui.SectionWidths;

export const getScopeWidth = createSelector(
	[getSectionWidths],
	(sectionWidths) => sectionWidths.scope,
);

export const getFieldsWidth = createSelector(
	[getSectionWidths],
	(sectionWidths) => sectionWidths.fields,
);

export const getTimelineWidth = createSelector(
	[getSectionWidths],
	(sectionWidths) => sectionWidths.timeline,
);

export const getTimelineExportWidth = createSelector(
	[getSectionWidths],
	(sectionWidths) => sectionWidths.timelineExport,
);

export const getExportAppWidthPure = (
	viewMode: ReturnType<typeof getViewMode>,
	reportMode: ReturnType<typeof isReportMode>,
	timelineExportWidth: ReturnType<typeof getTimelineExportWidth>,
) => {
	if (!reportMode) {
		return undefined;
	}

	return getNonTimelineWidth() + (viewMode === VIEW_MODES.TIMELINE ? timelineExportWidth : 0);
};

export const getExportAppWidth = createSelector(
	[getViewMode, isReportMode, getTimelineExportWidth],
	getExportAppWidthPure,
);
