import type { Dispatch } from 'redux';
import { updateIssueOrInlineCreate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { Action, DispatchProps } from './types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (dispatch: Dispatch<Action>): DispatchProps => ({
	onChange: (issue: ScopeIssue, customFieldId: string, customFieldValue: string) => {
		dispatch(
			updateIssueOrInlineCreate({
				id: issue.id,
				customFields: {
					[customFieldId]: customFieldValue,
				},
			}),
		);
	},
});
