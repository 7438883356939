import type {
	RANK_BEFORE,
	RANK_AFTER,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import type { Version } from './types';

export const RESET = 'state.domain.versions.RESET' as const;
export const ADD = 'state.domain.versions.ADD' as const;
export const REMOVE = 'state.domain.versions.REMOVE' as const;
export const RANK = 'state.domain.versions.RANK' as const;
export const BULK_UPDATE = 'state.domain.versions.BULK_UPDATE' as const;
export const UPDATE_REFERENCES = 'state.domain.versions.UPDATE_REFERENCES' as const;

export type Relation = typeof RANK_BEFORE | typeof RANK_AFTER;

export type ResetActionPayload = Version[];
export type RankActionPayload = {
	id: string;
	anchor: string;
	relation: Relation;
};
export type BulkUpdatePayload = {
	[key: string]: Partial<Version>;
};
export type UpdateReferencesPayload = {
	[key: string]: [string | null | undefined, string | null | undefined][];
};

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export type RankAction = {
	type: typeof RANK;
	payload: RankActionPayload;
};

export type AddActionPayload = Version;

export type AddAction = {
	type: typeof ADD;
	payload: AddActionPayload;
};

export type RemoveAction = {
	type: typeof REMOVE;
	payload: string;
};

export type BulkUpdateAction = {
	type: typeof BULK_UPDATE;
	payload: BulkUpdatePayload;
};

export type UpdateReferencesAction = {
	type: typeof UPDATE_REFERENCES;
	payload: UpdateReferencesPayload;
};

export const reset = (payload: ResetActionPayload) => ({
	type: RESET,
	payload,
});

export const rank: (arg1: RankActionPayload) => RankAction = (payload) => ({
	type: RANK,
	payload,
});

export const add: (arg1: AddActionPayload) => AddAction = (payload) => ({
	type: ADD,
	payload,
});

export const remove = (payload: string): RemoveAction => ({
	type: REMOVE,
	payload,
});

export const bulkUpdate = (payload: BulkUpdatePayload): BulkUpdateAction => ({
	type: BULK_UPDATE,
	payload,
});

export const updateReferences = (payload: UpdateReferencesPayload): UpdateReferencesAction => ({
	type: UPDATE_REFERENCES,
	payload,
});
