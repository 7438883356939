import type { SelectOption } from './types';

export const ADD = 'state.domain.select-options.ADD' as const;
export const RESET = 'state.domain.select-options.RESET' as const;

export type AddAction = {
	type: typeof ADD;
	payload: SelectOption[];
};

export type ResetAction = {
	type: typeof RESET;
	payload: SelectOption[];
};

export const add = (payload: SelectOption[]): AddAction => ({
	type: ADD,
	payload,
});

export const reset = (payload: SelectOption[]): ResetAction => ({
	type: RESET,
	payload,
});
