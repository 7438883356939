import { createSelector } from 'reselect';
import memoizeOne from 'memoize-one';
import * as R from 'ramda';
import { ISSUE_HIERARCHY_LEVEL_BASE } from '@atlassian/jira-issue-type-hierarchies';
import {
	getMode,
	isOptimizedMode,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app';
import { getAssigneesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/assignees';
import { getExternalIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/external-issues';
import {
	getDependencyIssueLinkTypes,
	getIssueCountByLinkType,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-links';
import {
	getIssueLinkIfOverlapping,
	getIssueLinkLeadTime,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-links/util';
import { getIssueStatusById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-statuses';
import { getIssueTypes } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types';
import { mergeOptimizedValueToIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues/utils';
import { getSyncStartEnabled } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan';
import { getProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects';
import { getIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/index.tsx';
import { getAllIssuesWithOriginalAndOptimizedById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope';
import { getDependencySettings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings';
import { EDIT } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import type { ExternalIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/external-issues/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { filterMap } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { ISSUE_LINK_DIRECTION } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import type { OwnProps, Row, StateProps, StatePropsForPreprocess } from './types';

const preprocess = createStructuredSelector<State, StatePropsForPreprocess>({
	assigneesById: getAssigneesById,
	externalIssues: getExternalIssues,
	issues: getIssues,
	issueLinkTypes: getDependencyIssueLinkTypes,
	issueCountByLinkType: getIssueCountByLinkType,
	issueStatusById: getIssueStatusById,
	issueTypes: getIssueTypes,
	mode: getMode,
	projectsById: getProjectsById,
	isOptimizedMode,
	issuesWithOptimizedById: getAllIssuesWithOriginalAndOptimizedById,
	syncStartEnabled: getSyncStartEnabled,
	dependencyViewSettings: getDependencySettings,
});

type OherIssueKeyType = 'targetItemKey' | 'sourceItemKey';
const preprocessMemoized = () => createSelector(preprocess, (res) => res);
const mapStateToPropsFactory = () => {
	const preprocessInstance = preprocessMemoized();
	const missingExternalIssuesFactory = memoizeOne(
		(
			externalIssueLinks: OwnProps['externalIssueLinks'],
			otherIssueKey: OherIssueKeyType,
			externalIssues: ExternalIssues,
		) =>
			externalIssueLinks
				.map((link) => link[otherIssueKey])
				.filter((issueId) => !R.has(issueId, externalIssues)),
	);
	const externalRowsFactory = memoizeOne(
		(
			missingExternalIssues: string[],
			externalIssues: ExternalIssues,
			otherIssueKey: OherIssueKeyType,
			externalIssueLinks: OwnProps['externalIssueLinks'],
			issueLinkTypes: StatePropsForPreprocess['issueLinkTypes'],
			direction: OwnProps['direction'],
			issuesWithOptimizedById: StatePropsForPreprocess['issuesWithOptimizedById'],
			originalIssueId: string,
			statePropsIsOptimizedMode: boolean,
			issueStatusById: StatePropsForPreprocess['issueStatusById'],
			syncStartEnabled: StatePropsForPreprocess['syncStartEnabled'],
		) =>
			missingExternalIssues.length > 0
				? []
				: filterMap(
						(link) => !!externalIssues[link[otherIssueKey]],
						(link) => {
							let isOverlapping = false;
							const { itemKey, type: typeId } = link;
							const type = issueLinkTypes.find(({ id }) => id === typeId);
							if (!type) {
								throw new Error('Unknown issue link type!');
							}

							const issue = externalIssues[link[otherIssueKey]];

							if (!issue) {
								throw new Error('Unplanned issue!');
							}
							let leadTime;
							if (direction === ISSUE_LINK_DIRECTION.OUTWARD) {
								const sourceIssue = mergeOptimizedValueToIssue(
									issuesWithOptimizedById[originalIssueId],
									statePropsIsOptimizedMode,
								);
								const targetIssue = externalIssues[issue.id];
								leadTime = getIssueLinkLeadTime(sourceIssue, targetIssue);
								isOverlapping = getIssueLinkIfOverlapping(
									sourceIssue,
									targetIssue,
									issueStatusById,
									syncStartEnabled,
								);
							} else {
								const sourceIssue = externalIssues[issue.id];
								const targetIssue = mergeOptimizedValueToIssue(
									issuesWithOptimizedById[originalIssueId],
									statePropsIsOptimizedMode,
								);
								leadTime = getIssueLinkLeadTime(sourceIssue, targetIssue);
								isOverlapping = getIssueLinkIfOverlapping(
									sourceIssue,
									targetIssue,
									issueStatusById,
									syncStartEnabled,
								);
							}
							return {
								assignee: {
									personId: issue.userId,
									jiraUser: {
										avatarUrl: issue.userAvatarUrl,
										title: issue.userTitle,
										accountId: issue.userId,
										email: '',
									},
								},
								isOverlapping,
								isChanged: false,
								issue,
								issueType: {
									// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
									id: issue.typeId as unknown as number,
									iconUrl: issue.typeIconUrl,
									name: '',
									level: ISSUE_HIERARCHY_LEVEL_BASE,
								},
								itemKey,
								leadTime,
								projectKey: issue.projectKey,
								status: {
									id: issue.statusId,
									categoryId: issue.statusCategoryId,
									name: issue.statusName,
								},
								syncStartEnabled,
								type,
							};
						},
						externalIssueLinks,
					),
	);
	const internalRowsFactory = memoizeOne(
		(
			internalIssueLinks: OwnProps['internalIssueLinks'],
			issueLinkTypes: StatePropsForPreprocess['issueLinkTypes'],
			issuesWithOptimizedById: StatePropsForPreprocess['issuesWithOptimizedById'],
			direction: OwnProps['direction'],
			issueTypes: StatePropsForPreprocess['issueTypes'],
			projectsById: StatePropsForPreprocess['projectsById'],
			originalIssueId: string,
			statePropsIsOptimizedMode: boolean,
			issueStatusById: StatePropsForPreprocess['issueStatusById'],
			syncStartEnabled: StatePropsForPreprocess['syncStartEnabled'],
			assigneesById: StatePropsForPreprocess['assigneesById'],
			internalOriginalIssueLinks: OwnProps['internalOriginalIssueLinks'],
		) =>
			internalIssueLinks.map(({ itemKey, type: typeId, sourceItemKey, targetItemKey }) => {
				const type = issueLinkTypes.find(({ id }) => id === typeId);
				if (!type) {
					throw new Error('Unknown issue link type!');
				}

				const issue =
					issuesWithOptimizedById[direction === 'outward' ? targetItemKey : sourceItemKey];
				if (!issue) {
					throw new Error('Unplanned issue!');
				}
				const issueType = issueTypes.find(({ id }) => id === issue.type);
				if (!issueType) {
					throw new Error('Unknown issue type!');
				}
				const project = projectsById[issue.project];
				if (!project) {
					throw new Error('Unknown project!');
				}
				let leadTime;
				let isOverlapping = false;
				if (direction === ISSUE_LINK_DIRECTION.OUTWARD) {
					const sourceIssue = mergeOptimizedValueToIssue(
						issuesWithOptimizedById[originalIssueId],
						statePropsIsOptimizedMode,
					);
					const targetIssue = mergeOptimizedValueToIssue(
						issuesWithOptimizedById[issue.id],
						statePropsIsOptimizedMode,
					);
					leadTime = getIssueLinkLeadTime(sourceIssue, targetIssue);
					isOverlapping = getIssueLinkIfOverlapping(
						sourceIssue,
						targetIssue,
						issueStatusById,
						syncStartEnabled,
					);
				} else {
					const sourceIssue = mergeOptimizedValueToIssue(
						issuesWithOptimizedById[issue.id],
						statePropsIsOptimizedMode,
					);
					const targetIssue = mergeOptimizedValueToIssue(
						issuesWithOptimizedById[originalIssueId],
						statePropsIsOptimizedMode,
					);
					isOverlapping = getIssueLinkIfOverlapping(
						sourceIssue,
						targetIssue,
						issueStatusById,
						syncStartEnabled,
					);
					leadTime = getIssueLinkLeadTime(sourceIssue, targetIssue);
				}
				return {
					assignee: assigneesById[issue.assignee],
					isChanged: !internalOriginalIssueLinks.some((link) => link.itemKey === itemKey),
					issue,
					issueType,
					itemKey,
					leadTime,
					projectKey: project.key,
					status: issueStatusById[issue.status || ''],
					syncStartEnabled,
					type,
					isOverlapping,
				};
			}),
	);
	return (
		state: State,
		{
			direction,
			externalIssueLinks,
			internalIssueLinks,
			internalOriginalIssueLinks,
			issue,
		}: OwnProps,
	): StateProps => {
		const {
			assigneesById,
			externalIssues,
			issues,
			issueLinkTypes,
			issueStatusById,
			issueTypes,
			mode,
			projectsById,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			isOptimizedMode,
			issuesWithOptimizedById,
			syncStartEnabled,
			issueCountByLinkType,
			dependencyViewSettings,
		} = preprocessInstance(state);
		const { id: originalIssueId } = issue;
		const otherIssueKey = direction === 'outward' ? 'targetItemKey' : 'sourceItemKey';
		const missingExternalIssues: string[] = missingExternalIssuesFactory(
			externalIssueLinks,
			otherIssueKey,
			externalIssues,
		);
		const externalRows: Row[] = externalRowsFactory(
			missingExternalIssues,
			externalIssues,
			otherIssueKey,
			externalIssueLinks,
			issueLinkTypes,
			direction,
			issuesWithOptimizedById,
			originalIssueId,
			isOptimizedMode,
			issueStatusById,
			syncStartEnabled,
		);

		const internalRows: Row[] = internalRowsFactory(
			internalIssueLinks,
			issueLinkTypes,
			issuesWithOptimizedById,
			direction,
			issueTypes,
			projectsById,
			originalIssueId,
			isOptimizedMode,
			issueStatusById,
			syncStartEnabled,
			assigneesById,
			internalOriginalIssueLinks,
		);
		return {
			dependencyViewSettings,
			externalRows,
			internalRows,
			isReadOnly: mode !== EDIT,
			issues,
			issueLinkTypes,
			issueCountByLinkType,
			issueTypes,
			missingExternalIssues,
			projectsById,
		};
	};
};

const mapStateToProps = mapStateToPropsFactory;
export default mapStateToProps;
