import prop from 'lodash/fp/prop';
import { isOptimizedMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app';
import { getCustomFieldsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields';
import { getIssueTypes } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types';
import { getProjects } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects';
import { getScope } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope';
import { getVisualisationViewSettings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings';
import { getWarnings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/warnings';
import type { IssueType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-types/types.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import type {
	ScopeIssue,
	BasicScopeIssue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import { DEFAULT_SCRATCH_ISSUE_TYPE } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/util';
import { indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import {
	getCustomFieldIdFromCustomFieldGrouping,
	isMultiSelectCustomField,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/custom-fields';

type Issue = BasicScopeIssue & {
	type: IssueType;
	project: Project;
};

export const getIsGroupingByMultiSelectCustomField = createSelector(
	[getVisualisationViewSettings, getCustomFieldsById],
	(viewSetting, customFieldsById) => {
		const grouping = viewSetting.grouping;
		const customFieldId = getCustomFieldIdFromCustomFieldGrouping(grouping);
		if (customFieldId.length > 0) {
			const fieldType = customFieldsById[Number(customFieldId)]?.type?.key;
			return isMultiSelectCustomField(fieldType);
		}
		return false;
	},
);
export const getEnhancer = createSelector([getIssueTypes, getProjects], (issueTypes, projects) => {
	const id = prop('id');
	const issueTypesById = indexBy(id, issueTypes);
	const projectsById = indexBy(id, projects);
	// NOTE `enhance` is written in ill-typed and mutable style for the sake of performance
	// this code turned out to be a hot spot and we want to reduce GC and computational load
	const enhance = (issue: ScopeIssue): Issue => ({
		...issue,
		type: issueTypesById[issue.type] ?? DEFAULT_SCRATCH_ISSUE_TYPE,
		project: projectsById[issue.project],
	});

	return enhance;
});

const getRankDigits = createSelector(
	[getScope],
	(scope) => scope.startLevelIssues.rootIssuesCount.toString().length,
);
const getStartLevel = createSelector([getScope], (scope) => scope.startLevelIssues.level);

export default createSelector(
	[
		getEnhancer,
		getWarnings,
		getIsGroupingByMultiSelectCustomField,
		getRankDigits,
		getStartLevel,
		isOptimizedMode,
	],
	(
		enhancer,
		warnings,
		isGroupingByMultiSelectCustomField,
		rankDigits,
		startLevel,
		// eslint-disable-next-line @typescript-eslint/no-shadow
		isOptimizedMode,
	) => ({
		enhancer,
		warnings,
		isGroupingByMultiSelectCustomField,
		rankDigits,
		startLevel,
		isOptimizedMode,
	}),
);
