import type React from 'react';
import { useEffect } from 'react';
import { monitorForDependencyCreate } from '@atlassian/jira-portfolio-3-dependency-line-drag-create/src/ui/index.tsx';
import type { Props } from './types';
import { getIssue, isDragCreatePayload, useDropHandling } from './utils';

export const DependencyDragCreate: React.FC<Props> = ({
	rows,
	items,
	addIssueLink,
	outgoingLinks,
	dependencySettings,
}) => {
	const handleDrop = useDropHandling(addIssueLink, outgoingLinks, dependencySettings);
	useEffect(
		() =>
			monitorForDependencyCreate({
				onDrop: ({ source, location }) => {
					const { position, issueLinkType } = source.data;
					const target = location.current.dropTargets[0];

					if (!target) {
						return;
					}

					if (position !== 'start' && position !== 'end') {
						return;
					}

					if (typeof issueLinkType !== 'number') {
						return;
					}

					if (!isDragCreatePayload(target.data) || !isDragCreatePayload(source.data)) {
						return;
					}

					const sourceIssue = getIssue(rows, items, source.data.row);
					const targetIssue = getIssue(rows, items, target.data.row);

					if (!sourceIssue || !targetIssue) {
						return;
					}

					handleDrop({
						dragSourcePosition: position,
						dragSourceIssueId: sourceIssue.id,
						dropTargetIssueId: targetIssue.id,
						issueLinkType,
					});
				},
			}),
		[handleDrop, items, rows],
	);
	return null;
};
