import React, { type ReactNode } from 'react';
import { ROUTE_NAMES_SOFTWARE_PLAN_TIMELINE } from '@atlassian/jira-common-constants/src/spa-routes';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';

export type StateProps = {
	isAutoSchedulerEnabled: boolean;
};

export type Props = StateProps & {
	children: (enabled: boolean) => ReactNode;
};

const AutoSchedulerSwitch = (props: Props) => {
	const route = useCurrentRoute();
	const { name: routeName } = route;
	const { children, isAutoSchedulerEnabled } = props;

	if (getWillShowNav4()) {
		return (
			<>{children(isAutoSchedulerEnabled && routeName === ROUTE_NAMES_SOFTWARE_PLAN_TIMELINE)}</>
		);
	}

	return <>{children(isAutoSchedulerEnabled)}</>;
};

export default AutoSchedulerSwitch;
