import {
	getMode,
	isConfluenceMacro,
	isEmbed,
	isReportMode,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app';
import {
	getGridRows,
	getGridRowIds,
	getGridColumns,
	getRowConfigs,
	getColumnConfigs,
	getRowStickiness,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/grid';
import { getExportAppWidth } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/section-widths';
import { getViewMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings';
import { getActiveViewId } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/views';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export default createStructuredSelector<State, StateProps>({
	rows: getGridRows,
	rowIds: getGridRowIds,
	columns: getGridColumns,
	rowConfigs: getRowConfigs,
	columnConfigs: getColumnConfigs,
	mode: getMode,
	stickyRows: getRowStickiness,
	viewMode: getViewMode,
	viewId: getActiveViewId,
	exportAppWidth: getExportAppWidth,
	isConfluenceMacro,
	isEmbed,
	isReportMode,
});
