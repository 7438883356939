import React, { useState, useEffect } from 'react';
import { withSlots, slots } from '@atlassian/jira-portfolio-3-portfolio/src/common/component-slots';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

export const makeSaveValue =
	({
		isReadOnly,
		attribute,
		onChange,
		issue,
	}: {
		isReadOnly: Props['isReadOnly'];
		attribute: Props['attribute'];
		onChange: Props['onChange'];
		issue: Props['issue'];
	}) =>
	(value?: string | null) => {
		if (isReadOnly) return;
		const numberValue = Number(value);
		onChange(
			issue,
			attribute,
			Number.isNaN(numberValue) || !Number.isFinite(numberValue) ? null : numberValue,
		);
	};

export const NumberTextRenderer = ({
	attribute,
	issue,
	onChange,
	isReadOnly,
	TextField,
}: Props) => {
	const [textValue, setTextValue] = useState<unknown>(null);

	useEffect(
		() => {
			if (attribute && issue && textValue == null) {
				const customerFieldValue = issue.customFields && issue.customFields[attribute];
				setTextValue({
					textValue: isDefined(customerFieldValue) ? customerFieldValue : '',
				});
			} else {
				setTextValue(null);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[attribute, issue],
	);

	const saveValue = makeSaveValue({ isReadOnly, attribute, issue, onChange });

	const getValue = () => {
		const customerFieldValue = issue.customFields && issue.customFields[attribute];
		return isDefined(customerFieldValue) ? customerFieldValue : '';
	};

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={`${styles.textFieldWrapper} ${isReadOnly ? styles.readOnly : ''}`}>
			<TextField
				isAlignRight
				isReadOnly={isReadOnly}
				isTransparentBackground
				onDone={saveValue}
				type="number"
				value={getValue()}
			/>
		</div>
	);
};

export default withSlots({ TextField: slots.CellTextField })(NumberTextRenderer);
