import React from 'react';
import { xcss } from '@atlaskit/primitives';
import IssueLink from '@atlassian/jira-portfolio-3-common/src/issue-link';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

export default function IssueCell({ row: { issue, issueType, projectKey } }: Props) {
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.issueTitle}>
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.issueIcon}
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					backgroundSize: 'contain',
					backgroundImage: issueType && `url(${issueType.iconUrl})`,
				}}
			/>
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.issueSummary}
				title={issue.summary}
				data-testid={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.cells.issue-${issue.id}.summary`}
			>
				<IssueLink
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					issueKey={issue.issueKey as number | undefined}
					projectKey={projectKey}
					xcssStyles={issueLinkStyles}
				/>
				{issue.summary}
			</div>
		</div>
	);
}

const issueLinkStyles = xcss({
	whiteSpace: 'nowrap',
	marginRight: 'space.075',
});
