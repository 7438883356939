import { getGroupOptions } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope';
import {
	getEnrichedVersions,
	getEnrichedVersionsById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export default createStructuredSelector<State, StateProps>({
	groups: getGroupOptions,
	enrichedVersions: getEnrichedVersions,
	enrichedVersionsById: getEnrichedVersionsById,
});
