import React, {
	type FC,
	type ChangeEvent,
	useCallback,
	useEffect,
	useRef,
	type KeyboardEvent,
} from 'react';
import { Box, xcss, type XCSS } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { useIntl } from '@atlassian/jira-intl';
import usePressTracing from '@atlassian/react-ufo/use-press-tracing';
import messages from './messages';

export type Props = {
	onFocus?(): void;
	onChange(value: string): void;
	onSubmit(): void;
	onBlur?(): void;
	isDisabled?: boolean;
	value?: string;
	placeholder?: string;
	testId?: string;
	autoFocus?: boolean;
	customXcss?: XCSS;
};

export const InputPrompt: FC<Props> = ({
	onChange,
	onFocus,
	onSubmit,
	onBlur,
	value,
	placeholder,
	testId,
	isDisabled = false,
	autoFocus = false,
	customXcss,
}) => {
	const { formatMessage } = useIntl();
	const pressTracing = usePressTracing(
		'portfolio-3-ai-work-breakdown.ui.issue-breakdown.refine-prompt',
	);

	const handleOnChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			onChange?.(event.target.value);
		},
		[onChange],
	);

	const handleOnKeyDown = useCallback(
		(event: KeyboardEvent<HTMLInputElement>) => {
			if (event.key === 'Escape') return; // We expect Esc to propagate from input field
			event.stopPropagation();
			if (event.key === 'Enter') {
				onSubmit();
				pressTracing();
			}
		},
		[onSubmit, pressTracing],
	);

	const fieldRef = useRef<HTMLInputElement>();
	useEffect(() => {
		const { current } = fieldRef;
		if (autoFocus && current) {
			current.focus({ preventScroll: true });
		}
	}, [autoFocus]);

	return (
		// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
		<Box xcss={[inputContainerStyles, customXcss]} testId={testId}>
			<Textfield
				aria-label={formatMessage(messages.inputAriaLabel)}
				ref={fieldRef}
				onChange={handleOnChange}
				onKeyDown={handleOnKeyDown}
				onFocus={onFocus}
				onBlur={onBlur}
				width="100%"
				appearance="none"
				maxLength={300}
				placeholder={placeholder}
				testId="portfolio-3-ai-work-breakdown.common.header.input-prompt"
				/**
				 * We're letting "Textfield" handle it's own state and only update the
				 * IssueBreakdownAiContainer store on the changes. That way
				 * updates to the value don't cause re-renders and jumps of the caret.
				 */
				defaultValue={value}
				isDisabled={isDisabled}
				isCompact
			/>
		</Box>
	);
};

const inputContainerStyles = xcss({
	display: 'flex',
	width: '100%',
	color: 'color.text.subtlest',
});
