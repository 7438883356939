import React, { useState } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type { OptionType } from '@atlassian/jira-portfolio-3-common/src/select/types';
import { withSlots, slots } from '@atlassian/jira-portfolio-3-portfolio/src/common/component-slots';
import { getBody } from '@atlassian/jira-portfolio-3-portfolio/src/common/dom';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { MIN_SELECT_WIDTH } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/select/view.tsx';
import messages from './messages';
import OptionsProvider from './options-provider';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toOption = (data: { id: any; value: string }): OptionType => ({
	value: String(data.id),
	label: data.value,
});

export const SingleSelectRenderer = ({
	FieldSelect,
	isReadOnly,
	selectedOption,
	issue,
	attribute,
	onChange,
	fetchOptions,
}: Props) => {
	const [options, setOptions] = useState<unknown>([]);
	const [isLoading, setIsLoading] = useState(false);
	const { formatMessage } = useIntl();
	const value = selectedOption && toOption(selectedOption);

	const handleFetchOptions = () => {
		setIsLoading(true);
		fetchOptions(attribute, issue.project, issue.type.toString())
			.then((data) => data.map(toOption))
			// eslint-disable-next-line @typescript-eslint/no-shadow
			.then((options) => {
				setOptions(options);
				setIsLoading(false);
			});
	};

	const handleSelectionChange = (option: OptionType | null) => {
		onChange(issue, attribute, option);
	};

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={isReadOnly ? styles.readOnly : ''}>
			<FieldSelect
				isLoading={isLoading}
				isClearable={isDefined(value)}
				isDisabled={isReadOnly}
				isSearchable
				isTransparentBackground
				menuPlacement="auto"
				menuPortalTarget={getBody()}
				minSelectWidth={MIN_SELECT_WIDTH}
				onChange={handleSelectionChange}
				onMenuOpen={handleFetchOptions}
				options={options}
				placeholder={formatMessage(messages.placeholder)}
				scrollLock
				value={value}
				issueId={issue.id}
				attribute={attribute}
			/>
		</div>
	);
};

export const SingleSelectRendererView = OptionsProvider(SingleSelectRenderer);

export default withSlots({ FieldSelect: slots.FieldSelect })(SingleSelectRendererView);
