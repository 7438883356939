import { createContainer, createHook, createStore } from '@atlassian/react-sweet-state';
import { actions } from './actions';
import { selectCtpOnboardingTour } from './selectors';
import type { ContainerProps, State } from './types';
import { rehydrate } from './utils';

const initialState: State = {
	_rehydrated: false,
};

const store = createStore<State, typeof actions>({
	initialState,
	actions,
	name: 'portfolio-3/ctp-onboarding',
});

export const useCtpOnboardingTour = createHook(store, {
	selector: selectCtpOnboardingTour,
});

export const CtpOnboardingProvider = createContainer<State, typeof actions, ContainerProps>(store, {
	onInit:
		() =>
		async ({ setState, getState, dispatch }) => {
			if (getState()._rehydratePromise === undefined) {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				const promise = dispatch(rehydrate)!;
				setState({ _rehydratePromise: promise });
				await promise;
			}
		},
});
