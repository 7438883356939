import { getCustomFieldsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields';
import {
	getTypeToLevel,
	getEnrichedHierarchyLevels,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/hierarchy';
import { getInlineCreateState } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/inline-create';
import {
	getProjects,
	getDefaultProjectId,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects';
import { getScope } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope';
import { getVisualisationGrouping } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';

export default createSelector(
	[
		getTypeToLevel,
		getProjects,
		getInlineCreateState,
		getDefaultProjectId,
		getEnrichedHierarchyLevels,
		getScope,
		getVisualisationGrouping,
		getCustomFieldsById,
	],
	(
		typeToLevel,
		projects,
		{ issueTypeId, IssueTypeIdForHierarchy },
		defaultProjectId,
		enrichedHierarchyLevels,
		{ groups },
		visualisationGrouping,
		customFieldsById,
	) => ({
		typeToLevel,
		projects,
		issueTypeId,
		IssueTypeIdForHierarchy,
		defaultProjectId,
		enrichedHierarchyLevels,
		groups,
		visualisationGrouping,
		customFieldsById,
	}),
);
