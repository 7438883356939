import isDeepEqual from 'lodash/isEqual';
import memoizeOne from 'memoize-one';
import { asColor } from '@atlassian/jira-issue-epic-color/src/common/utils.tsx';
import { EPIC_LEVEL } from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import { getMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app';
import {
	getExternalOutgoingLinks,
	getExternalIncomingLinks,
	getInternalOutgoingLinks,
	getInternalIncomingLinks,
	getInternalOutgoingLinkOriginals,
	getInternalIncomingLinkOriginals,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-links';
import { getIssueStatusById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-statuses';
import type { IssueMap } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues/types';
import { getDefaultIterationLength } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan-defaults';
import { getIssueMapById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/index.tsx';
import { getChildrenByParent } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/issues-tree.tsx';
import { getTimelineRange } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/timeline';
import { getTimelineState } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/timeline-preview';
import { getWarningViewSettings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings';
import {
	getColorByValue,
	getColourByComponentColourMap,
	getColourByIssueTypeColourMap,
	getColourByLabelColourMap,
	getColourByPriorityColourMap,
	getColourBySelectColourMap,
	getColourMaps,
	isColorByCustomFieldOption,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations';
import {
	EDIT,
	OPTIMIZED,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/issues/issue/drop-menu/create-issue/types.tsx';
import type { IssueLink } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import {
	colourByOptions,
	epicColorToARJColor,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colours';
import type { BarColors, OwnProps, StateProps } from './types';

export const getColoursWithRatio = (colours: Set<string>): BarColors => {
	const ratio = 1 / colours.size;
	return [...colours].map((colour) => ({
		colour,
		ratio,
	}));
};

const externalIncomingLinksList: IssueLink[] = [];
const externalOutgoingLinksList: IssueLink[] = [];
const internalIncomingLinksList: IssueLink[] = [];
const internalIncomingLinksOriginalList: IssueLink[] = [];
const internalOutgoingLinksList: IssueLink[] = [];
const internalOutgoingLinksOriginalList: IssueLink[] = [];
const issueChildrenList: Issue[] = [];
const mapStateToPropsFactory: () => MapStateToProps<StateProps, OwnProps> = () => {
	const getColoursWithRatioFactory = memoizeOne(getColoursWithRatio, isDeepEqual);
	return (state, props) => {
		const timelineRange = getTimelineRange(state);
		const timelinePreview = getTimelineState(state);
		const mode = getMode(state);
		const colourByValue = getColorByValue(state);
		const issueStatuses = getIssueStatusById(state);
		const colourMap = getColourMaps(state);
		const issueId = props.issue.id;
		const externalIncomingLinks =
			getExternalIncomingLinks(state)[issueId] || externalIncomingLinksList;
		const externalOutgoingLinks =
			getExternalOutgoingLinks(state)[issueId] || externalOutgoingLinksList;
		const internalIncomingLinks =
			getInternalIncomingLinks(state)[issueId] || internalIncomingLinksList;
		const internalIncomingLinksOriginal =
			getInternalIncomingLinkOriginals(state)[issueId] || internalIncomingLinksOriginalList;
		const internalOutgoingLinks =
			getInternalOutgoingLinks(state)[issueId] || internalOutgoingLinksList;
		const internalOutgoingLinksOriginal =
			getInternalOutgoingLinkOriginals(state)[issueId] || internalOutgoingLinksOriginalList;
		const defaultIterationLength = getDefaultIterationLength(state);
		const showWarning = getWarningViewSettings(state).showWarning;

		let preview;
		let isBeingInteractedWith;
		let epicColor;

		if (timelinePreview) {
			preview = timelinePreview.previews[issueId];
			isBeingInteractedWith = timelinePreview.activeIssueId === issueId;
		}

		let barColors;
		if (colourByValue === colourByOptions.ISSUE_TYPE) {
			const {
				issue: { type: issueType },
			} = props;
			const colourByIssueTypeColourMap = getColourByIssueTypeColourMap(state);
			const colour = colourByIssueTypeColourMap[issueType.toString()];
			if (isDefined(colour)) {
				barColors = getColoursWithRatioFactory(new Set([colour]));
			}
		} else if (colourByValue === colourByOptions.LABEL) {
			const {
				issue: { labels },
			} = props;
			const colourByLabelColourMap = getColourByLabelColourMap(state);
			const colours = new Set(
				(labels || [])
					.map((label: string) => colourByLabelColourMap[label])
					.filter((x: string) => !!x),
			);
			if (colours.size > 0) {
				barColors = getColoursWithRatioFactory(colours);
			}
		} else if (colourByValue === colourByOptions.COMPONENT) {
			const components = props.issue?.components ?? [];
			const colourByComponentColourMap = getColourByComponentColourMap(state);
			const colours = new Set(
				(components || [])
					.map((component) => colourByComponentColourMap[component.toString()])
					.filter((x) => !!x),
			);
			if (colours.size > 0) {
				barColors = getColoursWithRatioFactory(colours);
			}
		} else if (colourByValue === colourByOptions.PRIORITY) {
			const {
				issue: { priority },
			} = props;
			if (isDefined(priority)) {
				const colourByPriorityColourMap = getColourByPriorityColourMap(state);
				const colour = colourByPriorityColourMap[priority];
				if (isDefined(colour)) {
					barColors = getColoursWithRatioFactory(new Set([colour]));
				}
			}
		} else if (colourByValue === colourByOptions.EPIC) {
			const issueMapById: IssueMap = getIssueMapById(state);
			const issue = issueMapById[issueId];

			if (issue?.level === EPIC_LEVEL) {
				epicColor = asColor(issue.color);
			} else if (issue?.level < EPIC_LEVEL) {
				let issueParentId = issue?.parent;

				// traversing the parent layers back up to the epic level
				while (!epicColor && issueParentId && issueMapById[issueParentId]) {
					const parent = issueMapById[issueParentId];
					const parentLevel = parent.level;

					if (parentLevel > EPIC_LEVEL) {
						break;
					}

					if (parentLevel === EPIC_LEVEL) {
						epicColor = asColor(parent?.color);
						break;
					}

					issueParentId = parent?.parent;
				}
			}

			if (isDefined(epicColor) && epicColorToARJColor[epicColor]) {
				barColors = getColoursWithRatioFactory(new Set([epicColorToARJColor[epicColor]]));
			}
		} else if (isColorByCustomFieldOption(colourByValue)) {
			const {
				issue: { customFields = {} },
			} = props;
			const colourBySelectColourMap = getColourBySelectColourMap(state);
			const value = customFields[colourByValue];

			// single-select custom field would have only one value so make it an array
			// multi-select custom field would have an array of values so it's good to go
			const values = Array.isArray(value) ? value : [value];
			const colours = new Set(
				// eslint-disable-next-line @typescript-eslint/no-shadow
				(values || []).map((value) => colourBySelectColourMap[value]).filter((x) => !!x),
			);
			if (colours.size > 0) {
				barColors = getColoursWithRatioFactory(colours);
			}
		}

		const issueChildren = getChildrenByParent(state)[props.issue.id] ?? issueChildrenList;

		return {
			barColors,
			issueChildren,
			colourByValue,
			colourMap,
			defaultIterationLength,
			epicColor,
			externalIncomingLinks,
			externalOutgoingLinks,
			internalIncomingLinks,
			internalIncomingLinksOriginal,
			internalOutgoingLinks,
			internalOutgoingLinksOriginal,
			isBeingInteractedWith,
			isReadOnly: mode !== EDIT || props.isReadOnly,
			issueStatuses,
			preview,
			showOptimizations: mode === OPTIMIZED,
			timelineRange,
			showWarning,
		};
	};
};

const mapStateToProps = mapStateToPropsFactory;
export default mapStateToProps;
