import React, { useState, type SyntheticEvent } from 'react';
import { ErrorMessage, Field, HelperMessage } from '@atlaskit/form';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalHeader,
	ModalTitle,
	ModalBody,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import AkTextField from '@atlaskit/textfield';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

const MAX_VIEW_NAME_LENGTH = 200;

export default function SaveAsDialog({
	hasDuplicateViewError,
	isSaving,
	onChange,
	onClose,
	onSubmit,
}: Props) {
	const { formatMessage } = useIntl();

	const [name, setName] = useState('');
	const [asDefault, markAsDefault] = useState(false);

	const handleTitleChange = (e: SyntheticEvent<HTMLInputElement>) => {
		onChange();
		if (e.currentTarget instanceof HTMLInputElement) {
			setName(e.currentTarget.value.substr(0, MAX_VIEW_NAME_LENGTH + 1));
		}
	};

	const handleMarkAsDefaultChange = (e: SyntheticEvent<HTMLInputElement>) => {
		if (e.currentTarget instanceof HTMLInputElement) {
			markAsDefault(e.currentTarget.checked);
		}
	};

	const isMaximumNameLengthExceeded = name.length > MAX_VIEW_NAME_LENGTH;
	const errorMessage = ((): string | null | undefined => {
		if (hasDuplicateViewError) {
			return formatMessage(messages.duplicateView);
		}

		if (isMaximumNameLengthExceeded) {
			return formatMessage(messages.maximumNameLengthExceeded, {
				number: MAX_VIEW_NAME_LENGTH,
			});
		}
	})();

	const hasError = hasDuplicateViewError || isMaximumNameLengthExceeded;

	const submit = () => onSubmit({ name: name.trim(), asDefault });
	const canSubmit = name.trim() !== '' && !hasError;

	return (
		<ShortcutScope>
			<ModalDialog
				autoFocus
				shouldScrollInViewport
				onClose={onClose}
				shouldCloseOnOverlayClick
				shouldCloseOnEscapePress
			>
				<ModalHeader>
					<ModalTitle>{formatMessage(messages.headingCreate)}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					{formatMessage(messages.messageCreate)}
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.formItemWrapper}>
						<Field isRequired label={formatMessage(messages.title)} name="viewName">
							{({ fieldProps }) => (
								<>
									<AkTextField
										{...fieldProps}
										autoFocus
										isInvalid={hasError}
										onChange={handleTitleChange}
										placeholder={formatMessage(messages.titlePlaceholder)}
										testId="portfolio-3-portfolio.app-simple-plans.top.view-bar.save-as-dialog.input"
										value={name}
									/>
									{!hasError && <HelperMessage>{formatMessage(messages.titleHint)}</HelperMessage>}
									{hasError && (
										<ErrorMessage testId="portfolio-3-portfolio.app-simple-plans.top.view-bar.save-as-dialog.error-message">
											{errorMessage || ''}
										</ErrorMessage>
									)}
								</>
							)}
						</Field>
					</div>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.formItemWrapper}>
						<Checkbox
							isChecked={asDefault}
							onChange={handleMarkAsDefaultChange}
							label={formatMessage(messages.makeDefaultCheckboxLabel)}
						/>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={`${styles.fieldHint} ${styles.checkboxHint}`}>
							{formatMessage(messages.makeDefaultCheckboxHint)}
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						appearance="subtle"
						onClick={onClose}
						testId="portfolio-3-portfolio.app-simple-plans.top.view-bar.save-as-dialog.cancel-button"
					>
						{formatMessage(commonMessages.cancel)}
					</Button>
					<Button
						appearance="primary"
						isDisabled={!canSubmit}
						isLoading={isSaving}
						onClick={submit}
						testId="portfolio-3-portfolio.app-simple-plans.top.view-bar.save-as-dialog.save-button"
					>
						{formatMessage(messages.save)}
					</Button>
				</ModalFooter>
			</ModalDialog>
		</ShortcutScope>
	);
}
