import * as R from 'ramda';
import type { OptionType } from '@atlassian/jira-portfolio-3-common/src/select/types';
import type { GroupedUserOptions, UserOptions } from './types';

export const isGroupedOptions = (options: UserOptions = []): options is GroupedUserOptions[] =>
	options.some((el) => 'options' in el);

/**
 * This is a custom search implementation for react select.
 * The reason of doing it is to have similar logic with endpoint '/rest/teams/1.0/persons/find'
 * and avoid cases when endpoint with query returns users but they are filtered in select
 */
export const filterOption = (candidate: OptionType, input: string) => {
	if (input) {
		const getFullText: (option: OptionType) => string = R.compose(
			R.toLower,
			R.join(' '),
			// @ts-expect-error - TS2769 - No overload matches this call.
			R.props(['label', 'value', 'email']),
			R.prop('data'),
		);
		const matchQuery = R.curry((query: string, option: OptionType): boolean =>
			R.includes(R.trim(R.toLower(query)))(getFullText(option)),
		);
		return matchQuery(input, candidate);
	}
	return true;
};
