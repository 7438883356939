import { getPlan } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan';
import { getRollupMap } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/issues-tree.tsx';
import { getJiraHoursPerDay } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/system';
import { getShowRolledUpOthers } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux';
import type { OwnProps, StateProps } from './types';

const querySelector: MapStateToProps<StateProps, OwnProps> = (state, props) => ({
	plan: getPlan(state),
	estimateRollUpForIssue: isDefined(getRollupMap(state)[props.issue.id])
		? getRollupMap(state)[props.issue.id].estimate
		: null,
	showEstimateRollup: getShowRolledUpOthers(state),
	workingHours: getJiraHoursPerDay(state),
});

export default querySelector;
