import { getSelectedIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues';
import {
	getTeams,
	getAllTeamsById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { StateProps } from './types';

export default createStructuredSelector<State, StateProps>({
	selectedIssues: getSelectedIssues,
	teams: getTeams,
	teamsById: getAllTeamsById,
});
