import React from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	useAssociatedIssues,
	useAssociatedIssuesInitialized,
} from '@atlassian/jira-portfolio-3-associated-issues/src/controllers/index.tsx';
import { CellSkeleton } from '@atlassian/jira-portfolio-3-common/src/skeleton/cell.tsx';
import { DEFAULT_FIELD_WIDTH } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import { IdeasCell } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ideas-cell';
import type { Props } from './types';
import {
	filterOnlyExistedAssociatedIssues,
	filterOnlyExistedOriginalAssociatedIssues,
} from './utils';
import ViewOld from './view-old';

const View = ({ issue, isReadOnly, isExportMode, onChange }: Props) => {
	const [associatedIssues] = useAssociatedIssues();
	const [isInitialized] = useAssociatedIssuesInitialized();

	if (ff('polaris.possibility-of-updating-ideas-in-plans')) {
		if (!isInitialized) {
			return <CellSkeleton width={DEFAULT_FIELD_WIDTH.MEDIUM} />;
		}

		const showEmpty = !associatedIssues || !issue.associatedIssueIds;
		if (showEmpty) {
			return null;
		}

		return (
			<IdeasCell
				isExportMode={isExportMode}
				isDisabled={isReadOnly}
				isReadOnly={isReadOnly}
				associatedIssues={filterOnlyExistedAssociatedIssues(issue, associatedIssues)}
				originalIssues={filterOnlyExistedOriginalAssociatedIssues(issue, associatedIssues)}
				issue={issue}
				onChange={onChange}
			/>
		);
	}

	return <ViewOld issue={issue} />;
};

export default View;
