import React, { Component } from 'react';
import AKInlineEdit from '@atlaskit/inline-edit';
import type { Props } from './types';

// eslint-disable-next-line jira/react/no-class-components
export default class InlineEdit extends Component<Props> {
	render() {
		return <AKInlineEdit {...this.props} />;
	}
}
