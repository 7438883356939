import {
	INCREMENT,
	type IncrementAction,
	RESET,
	type ResetAction,
	UPDATE,
	type UpdateAction,
} from './actions';
import type { Sequence } from './types';

const initialState: Sequence = {};

type Action = ResetAction | UpdateAction | IncrementAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: Sequence = initialState, action: Action) => {
	switch (action.type) {
		case INCREMENT: {
			const newState = { ...state };
			for (const key of action.payload) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const { current } = newState[key as keyof Sequence] || { current: 0 };
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				newState[key as keyof Sequence] = { before: current, current: current + 1 };
			}
			return newState;
		}
		case RESET:
			return { ...action.payload };
		case UPDATE:
			return { ...state, ...action.payload };
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
