import type { Dispatch } from 'redux';
import { updateIssueOrInlineCreate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { MapDispatchToProps } from './types';

const dispatchMethods: MapDispatchToProps = (dispatch: Dispatch) => ({
	onChange: (issue: ScopeIssue, customFieldId: string, customFieldValue?: number) => {
		dispatch(
			updateIssueOrInlineCreate({
				id: issue.id,
				customFields: {
					[customFieldId]: customFieldValue,
				},
			}),
		);
	},
});

export default dispatchMethods;
