import React, { useCallback, useEffect } from 'react';
import FocusLock from 'react-focus-lock';
import { useOpenIssueTransitionModal } from '@atlassian/jira-issue-transition-trigger/src/utils/use-trigger-issue-transition-modal/index.tsx';
import AuiModalStackIndex from '@atlassian/jira-issue-view-common-utils/src/aui-modal-stack-index';
import { createAri } from '@atlassian/jira-platform-ari';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { Props } from './types';

const getAriIssueId = (issueId = '', cloudId = '') =>
	createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'issue',
		resourceId: issueId ?? '',
	});

const FocusUnlock = () => (
	// a focus-lock that covers the whole body
	// effectively replaces another active lock (from modal)
	// and allows other solutions (focus-trap/ AUI) to work without interruptions
	// used to "support" AUI focus lock implementation
	<FocusLock
		// set FocusLock to ignore all elements
		whiteList={useCallback(() => false, [])}
		returnFocus
		// disable autofocus in order to minimize interference
		autoFocus={false}
	/>
);

const ChangeStatusDialog = ({
	isWaiting,
	issueKeyInJira,
	transitionId,
	issueId,
	success,
	fail,
	cancel,
}: Props) => {
	const openIssueTransitionModal = useOpenIssueTransitionModal();
	const cloudId = useCloudId();
	useEffect(() => {
		if (!isWaiting) return;
		const handleDialogSuccess = () => success();
		const handleDialogCancel = () => cancel();
		const handleDialogError = () => fail();
		const issueKey = toIssueKey(issueKeyInJira || '');
		const transitionIdString = transitionId || '';

		const ariIssueId = getAriIssueId(issueId, cloudId);
		openIssueTransitionModal?.({
			payload: {
				issueId: ariIssueId,
				issueKey,
				transitionId: transitionIdString,
			},
			triggerPointKey: 'issue-transition-advanced-roadmaps-jira-transition',
			onDialogSuccess: handleDialogSuccess,
			onDialogCancel: handleDialogCancel,
			onDialogError: handleDialogError,
		});
	}, [
		isWaiting,
		issueKeyInJira,
		transitionId,
		success,
		cancel,
		fail,
		openIssueTransitionModal,
		issueId,
		cloudId,
	]);

	return (
		<AuiModalStackIndex>{(stackIndex) => (stackIndex ? <FocusUnlock /> : null)}</AuiModalStackIndex>
	);
};
export default ChangeStatusDialog;
