import React, { forwardRef, useCallback, useState } from 'react';
import { UseShortcuts } from '@atlassian/jira-portfolio-3-keyboard-shortcuts/src/controllers';
import ReviewChangesButton from './review-changes-button';
import SpotlightChangesCountMonitor from './spotlight-changes-count-monitor';
import type { Props } from './types';

const ReviewChangesButtonWrapper = forwardRef<HTMLButtonElement, Props>(
	(
		{ currentScenarioColor, changesCount, isOptimizing, isOptimized, isReadOnly, openDialog },
		ref,
	) => {
		const [hasCountChanged, setHasCountChanged] = useState(false);
		const [isSpotlightActive, setIsSpotlightActive] = useState(false);

		const toggleSetHasCountChanged = useCallback(
			(hasCountUpdated: boolean) => setHasCountChanged(hasCountUpdated),
			[],
		);
		const activateSpotlight = useCallback(() => setIsSpotlightActive(true), []);

		const closeSpotlight = useCallback(() => {
			setIsSpotlightActive(false);
		}, []);

		const handleOpenDialog = useCallback(
			(e: KeyboardEvent | React.MouseEvent<Element, MouseEvent>) => {
				if (isSpotlightActive && hasCountChanged) {
					setIsSpotlightActive(false);
				}

				openDialog(e);
			},
			[isSpotlightActive, openDialog, setIsSpotlightActive, hasCountChanged],
		);

		return (
			<>
				<SpotlightChangesCountMonitor
					changesCount={changesCount}
					onCountChanged={toggleSetHasCountChanged}
				/>

				{!(isOptimizing || isOptimized || isReadOnly) && (
					<UseShortcuts shortcutKey="KeyR" onKeyDown={handleOpenDialog} />
				)}

				<ReviewChangesButton
					ref={ref}
					hasCountChanged={hasCountChanged}
					toggleSetHasCountChanged={toggleSetHasCountChanged}
					isSpotlightActive={isSpotlightActive}
					openDialog={handleOpenDialog}
					closeSpotlight={closeSpotlight}
					activateSpotlight={activateSpotlight}
					currentScenarioColor={currentScenarioColor}
					changesCount={changesCount}
					isOptimizing={isOptimizing}
				/>
			</>
		);
	},
);

export default ReviewChangesButtonWrapper;
