import type { Filters } from './types';

export const SET_ROLLUP_FILTER =
	'state.ui.main.tabs.dependencies.filters.SET_ROLLUP_FILTER' as const;
export const SET_ISSUE_IN_FOCUS =
	'state.ui.main.tabs.dependencies.filters.SET_ISSUE_IN_FOCUS' as const;
export const SET_GROUP_BY = 'state.ui.main.tabs.dependencies.filters.SET_GROUP_BY' as const;
export const SET_ISSUE_FILTER = 'state.ui.main.tabs.dependencies.filters.SET_ISSUE_FILTER' as const;
export const SET_PROJECT_FILTER =
	'state.ui.main.tabs.dependencies.filters.SET_PROJECT_FILTER' as const;
export const SET_ISSUE_LINK_TYPE_FILTER =
	'state.ui.main.tabs.dependencies.filters.SET_ISSUE_LINK_TYPE_FILTER' as const;
export const SET_SPRINT_FILTER =
	'state.ui.main.tabs.dependencies.filters.SET_SPRINT_FILTER' as const;
export const RESET_FILTERS = 'state.ui.main.tabs.dependencies.filters.RESET_FILTERS' as const;
export const INITIATE_FILTERS = 'state.ui.main.tabs.dependencies.filters.INITIATE_FILTERS' as const;

export type SetRollUpFilterAction = {
	type: typeof SET_ROLLUP_FILTER;
	payload: {
		rollupBy: string | null;
		rollupHierarchyLevel: number;
	};
};

export type SetIssueInFocusAction = {
	type: typeof SET_ISSUE_IN_FOCUS;
	payload: string | null | undefined;
};

export type SetGroupBy = {
	type: typeof SET_GROUP_BY;
	payload: string;
};

export type SetIssueFilter = {
	type: typeof SET_ISSUE_FILTER;
	payload: string | null;
};

export type SetProjectFilter = {
	type: typeof SET_PROJECT_FILTER;
	payload: string[] | null;
};

export type SetIssueLinkTypeFilter = {
	type: typeof SET_ISSUE_LINK_TYPE_FILTER;
	payload: string[] | null;
};

export type SetSprintFilter = {
	type: typeof SET_SPRINT_FILTER;
	payload: string[] | null;
};

export type ResetFiltersAction = {
	type: typeof RESET_FILTERS;
};

export type InitiateFiltersAction = {
	type: typeof INITIATE_FILTERS;
	payload: Filters;
};

export const setRollUp = (
	rollupBy: null | string,
	rollupHierarchyLevel: number,
): SetRollUpFilterAction => ({
	type: SET_ROLLUP_FILTER,
	payload: {
		rollupBy,
		rollupHierarchyLevel,
	},
});

export const setIssueInFocus = (issueId?: string | null): SetIssueInFocusAction => ({
	type: SET_ISSUE_IN_FOCUS,
	payload: issueId,
});

export const setGroupBy = (groupBy: string): SetGroupBy => ({
	type: SET_GROUP_BY,
	payload: groupBy,
});

export const setIssueFilter = (issueFilter: string | null): SetIssueFilter => ({
	type: SET_ISSUE_FILTER,
	payload: issueFilter,
});

export const setProjectFilter = (projectFilter: string[] | null): SetProjectFilter => ({
	type: SET_PROJECT_FILTER,
	payload: projectFilter,
});

export const setIssueLinkTypeFilter = (
	issueLinkTypeFilter: string[] | null,
): SetIssueLinkTypeFilter => ({
	type: SET_ISSUE_LINK_TYPE_FILTER,
	payload: issueLinkTypeFilter,
});

export const setSprintFilter = (sprintFilter: string[] | null): SetSprintFilter => ({
	type: SET_SPRINT_FILTER,
	payload: sprintFilter,
});

export const resetFilters = () => ({
	type: RESET_FILTERS,
});

export const initiateFilters = (payload: Filters): InitiateFiltersAction => ({
	type: INITIATE_FILTERS,
	payload,
});
