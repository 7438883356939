import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, ContextType } from './types';

export const context = createContext<ContextType>({
	sticky: false,
});

export default function StickyHeader({
	sectionId = '',
	containerId,
	stickyHeader,
	children,
}: Props) {
	if (!stickyHeader) return null;

	const classes = classNames(styles.columnHeaderWrapper, {
		[styles[`${sectionId}ColumnHeaderWrapper`]]: sectionId.length > 0,
	});

	return ReactDOM.createPortal(
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles.stickHeaderLayout}
			style={{
				// @ts-expect-error - TS2322 - Type '{ heigth: number; width: string; }' is not assignable to type 'Properties<string | number, string & {}>'.
				heigth: stickyHeader.height,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				width: '100%',
			}}
		>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={classes}>
				<context.Provider value={{ sticky: true }}>{children}</context.Provider>
			</div>
		</div>,

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.getElementById(containerId) || document.createElement('div'),
	);
}
