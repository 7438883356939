import {
	bulkIssuesUpdate,
	updateMultipleIssues,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	bulkUpdateAttribute: bulkIssuesUpdate,
	updateMultipleIssues,
};
