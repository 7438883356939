import React, { type FC, useMemo, useCallback, useRef, useEffect, useState } from 'react';
import type { EntryPointProps } from 'react-relay';
import { LoadingButton } from '@atlaskit/button';
import { Box, xcss, Inline } from '@atlaskit/primitives';
import { N40, N30 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import AIContainer from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-container';
import { AiFooter } from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-footer';
import { useIntl } from '@atlassian/jira-intl';
import type { SuggestedChildIssue } from '@atlassian/jira-issue-fetch-services/src/services/issue-breakdown-data-fetcher/types';
import { smoothScrollIntoCenterIfNeeded } from '@atlassian/jira-issue-view-common-utils/src/scroll/index.tsx';
import UFOLoadHold from '@atlassian/react-ufo/load-hold';
import { AiIcon } from '../../common/ai-icon';
import { useAiWorkBreakDownAnalytics } from '../../common/analytics';
import { useDescriptionUrlNumber } from '../../common/analytics/utils';
import { Header } from '../../common/header';
import { InputPrompt } from '../../common/header/input-prompt';
import { reasons, useIssueBreakdown } from '../../controllers/context';
import { FeedbackForm } from './feedback-form';
import messages from './messages';
import { SectionErrorMessage } from './section-error-message';
import { StreamingStatusBar } from './streaming-status-bar';
import { SubscribeToIssueBreakdown } from './subscribe-to-issue-breakdown';
import { SuggestionListItem } from './suggestion-list-item';
import { getAnalyticsIssueTypesCount, getSelectedIssueType } from './suggestion-list-item/utils';
import type { Props } from './types';
import { getLoadingStatusMessage } from './utils';

export const IssueBreakdownDraftList: FC<Props> = ({
	isDefaultCreatingIssues = false,
	onSubmit,
	onDiscard,
	onCreate,
	onChangePrompt,
	onRetry,
	childIssueTypes,
	parentIssueDetails,
}) => {
	const { formatMessage } = useIntl();
	const [
		{
			channelId,
			clearListWhenAddSuggestion,
			headerPromptValue,
			isStreaming,
			status,
			streamingError,
			suggestedChildIssues,
		},
		{ setSuggestedChildIssues, removeSuggestedIssue },
	] = useIssueBreakdown();
	const [isCreatingIssues, setIsCreatingIssues] = useState(isDefaultCreatingIssues);

	const { fireTrack } = useAiWorkBreakDownAnalytics({ channelId });

	const numOfUrl = useDescriptionUrlNumber();

	const defaultChildIssueTypes = useMemo(() => {
		const [defaultType] = childIssueTypes;
		return [defaultType.id];
	}, [childIssueTypes]);

	const acceptIssueSuggestions = useCallback(
		// We need to pass in new instances of createIssues for bulk and inline accept actions
		(issues: Array<SuggestedChildIssue>) => {
			onCreate(issues);

			removeSuggestedIssue(
				issues.map((suggestedIssue) => suggestedIssue.id),
				reasons.accept,
			);
		},
		[onCreate, removeSuggestedIssue],
	);

	const handleOnAcceptAll = useCallback(() => {
		setIsCreatingIssues(true);
		const issueTypes = getAnalyticsIssueTypesCount(childIssueTypes, suggestedChildIssues);

		fireTrack('aiResult actioned', 'suggestedIssuesAllAccepted', {
			aiResultAction: 'recommendationAccepted',
			acceptedNumber: suggestedChildIssues.length,
			issueTypes,
			numOfUrl,
		});

		acceptIssueSuggestions(suggestedChildIssues);

		setIsCreatingIssues(false);
	}, [acceptIssueSuggestions, childIssueTypes, fireTrack, numOfUrl, suggestedChildIssues]);

	const fieldRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const { current } = fieldRef;
		if (isStreaming && current) {
			smoothScrollIntoCenterIfNeeded(current);
			current.focus({ preventScroll: true });
		}
	}, [isStreaming]);

	const setSuggestedChildIssueType = useCallback(
		(updatedItem: SuggestedChildIssue) => {
			const updatedItems = suggestedChildIssues.map((item) => {
				if (updatedItem.id === item.id) {
					return { ...item, issueTypeId: updatedItem.issueTypeId };
				}
				return item;
			});
			setSuggestedChildIssues(updatedItems);
		},
		[setSuggestedChildIssues, suggestedChildIssues],
	);

	const renderItems = useMemo(
		() =>
			suggestedChildIssues.map((item) => {
				const { id, issueTypeId } = item;
				const selectedIssueType = getSelectedIssueType(childIssueTypes, issueTypeId.toString());
				const onClickRemove = () => {
					fireTrack('aiResult actioned', 'suggestedIssueRemoved', {
						aiResultAction: 'recommendationRejected',
						numOfUrl,
					});
					removeSuggestedIssue(id, reasons.reject);
				};

				return (
					<SuggestionListItem
						item={item}
						selectedIssueType={selectedIssueType}
						childIssueTypes={childIssueTypes}
						channelId={channelId}
						setSuggestedChildIssueType={setSuggestedChildIssueType}
						key={item.id}
						onClickAccept={acceptIssueSuggestions}
						onClickRemove={onClickRemove}
						isLoadingBulkAccept={isCreatingIssues}
					/>
				);
			}),
		[
			suggestedChildIssues,
			childIssueTypes,
			channelId,
			setSuggestedChildIssueType,
			acceptIssueSuggestions,
			isCreatingIssues,
			fireTrack,
			numOfUrl,
			removeSuggestedIssue,
		],
	);

	const streamingComponent = isStreaming && (
		<>
			<StreamingStatusBar message={formatMessage(getLoadingStatusMessage(status))} />
			<SubscribeToIssueBreakdown
				issueTypeIds={defaultChildIssueTypes}
				numOfUrl={numOfUrl}
				parentIssueDetails={parentIssueDetails}
			/>
		</>
	);

	const onFeedbackAction = useCallback(
		(typeOfAction: 'submitted' | 'clicked', aiFeedbackResult: 'up' | 'down') => {
			fireTrack(
				`aiFeedback ${typeOfAction === 'submitted' ? 'submitted' : 'clicked'}`,
				`${typeOfAction === 'submitted' ? 'feedbackSubmitted' : 'feedbackClicked'}`,
				{ aiFeedbackResult },
			);
		},
		[fireTrack],
	);

	const onFeedbackClicked = useCallback(
		(aiFeedbackResult: 'up' | 'down') => {
			onFeedbackAction('clicked', aiFeedbackResult);
		},
		[onFeedbackAction],
	);

	const onFeedbackSubmitted = useCallback(
		(aiFeedbackResult: 'up' | 'down') => {
			onFeedbackAction('submitted', aiFeedbackResult);
		},
		[onFeedbackAction],
	);

	const sectionMessages = !isStreaming && (
		<>
			{streamingError && (
				<Box xcss={messageSectionStyles}>
					<SectionErrorMessage
						error={streamingError}
						onRetry={onRetry}
						isRefined={!!headerPromptValue}
					/>
				</Box>
			)}
			<Box xcss={actionsSectionStyles}>
				<FeedbackForm onFeedbackClicked={onFeedbackClicked} onFeedbackSent={onFeedbackSubmitted} />
				{suggestedChildIssues.length !== 0 && (
					<LoadingButton
						type="button"
						testId="portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.accept-all-loading-button"
						onClick={handleOnAcceptAll}
						isLoading={isCreatingIssues}
						isDisabled={isCreatingIssues}
					>
						{formatMessage(messages.acceptAll)}
					</LoadingButton>
				)}
			</Box>
		</>
	);

	const handleOnDiscard = () => {
		onDiscard();
	};

	return (
		<Box ref={fieldRef} xcss={promptContainerStyles} tabIndex={-1}>
			<AIContainer
				hasNewBorderExperience
				shouldFitContainer
				isShadow={isStreaming}
				isLoading={isStreaming}
				spacing="none"
				testId="portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.ai-container"
			>
				<Header
					onChange={onChangePrompt}
					onSubmit={onSubmit}
					value={headerPromptValue}
					issueHierarchyLevel={parentIssueDetails.level}
					onCrossIconClick={handleOnDiscard}
					isClickable={false}
				/>
				<UFOLoadHold
					name="ai-work-breakdown.ui.issue-breakdown.first-suggestion"
					// We need to check `clearListWhenAddSuggestion` when refining the prompt
					hold={suggestedChildIssues.length < 1 || clearListWhenAddSuggestion}
				/>
				{renderItems}
				{streamingComponent}
				{sectionMessages}
				{!isStreaming && (
					<Inline
						testId="portfolio-3-ai-work-breakdown.ui.issue-breakdown-draft-list.bottom-input-prompt"
						xcss={footerStyle}
						spread="space-between"
						alignBlock="center"
						alignInline="start"
						space="space.050"
					>
						<AiIcon label="" />

						<InputPrompt
							placeholder={formatMessage(messages.bottomInputPrompt)}
							onChange={onChangePrompt}
							onSubmit={onSubmit}
							autoFocus
						/>
					</Inline>
				)}
				<AiFooter isBeta={false} />
			</AIContainer>
		</Box>
	);
};

export const AsyncIssueBreakdownDraftList = ({ props }: EntryPointProps<{}, {}, Props, {}>) => (
	<IssueBreakdownDraftList {...props} />
);

const promptContainerStyles = xcss({
	border: 0,
	outline: 'none', // remove focus highlight
});

const footerStyle = xcss({
	borderTop: `1px solid ${token('color.border', N40)}`,
	paddingInline: 'space.150',
	paddingBlock: 'space.150',
});

const messageSectionStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	rowGap: 'space.150',
	padding: 'space.150',
	borderBottom: `1px solid ${token('color.background.accent.gray.subtler', N30)}`,
});

const actionsSectionStyles = xcss({
	display: 'flex',
	justifyContent: 'space-between',
	padding: 'space.150',
});
