import type { Dispatch } from 'redux';
import { bulkRankIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue';
import type { BulkRankIssueActionPayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue/types.tsx';
import type { DispatchProps } from './types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	dispatch: Dispatch<{
		type: string;
	}>,
): DispatchProps => ({
	bulkUpdateRank: (payload: BulkRankIssueActionPayload) => {
		dispatch(bulkRankIssue(payload));
	},
});
