import React from 'react';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

export function TriggerButton(props: Props) {
	const { isOpen, testId, triggerButtonText, ariaLabel, onOpenChange } = props;

	const onOpenChangeHandle = () => {
		onOpenChange({ isOpen: !isOpen });
	};

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.filterButtonWrapper}>
			<Button
				iconAfter={<ChevronDownIcon label="" />}
				isSelected={isOpen}
				onClick={onOpenChangeHandle}
				testId={testId}
				ariaLabel={ariaLabel}
			>
				{triggerButtonText}
			</Button>
		</div>
	);
}

export default TriggerButton;
