import { ENABLE_ONLY_ISSUE_ID, type EnableOnlyIssueIdAction } from './actions';
import type { Schedule } from './types';

export type Action = EnableOnlyIssueIdAction;

const initialState: Schedule = {
	onlyEnabledIssueId: null,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: Schedule = initialState, action: Action): Schedule => {
	switch (action.type) {
		case ENABLE_ONLY_ISSUE_ID:
			return {
				...state,
				onlyEnabledIssueId: action.payload,
			};
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
