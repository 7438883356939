import { useCallback } from 'react';
import { advancedRoadmapsReduxStoreStateResource } from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { useResource } from '@atlassian/react-resource-router';
import type { State } from '../../state/types';

export const useReduxStateCache = () => {
	const { data: initialState, update } = useResource<State | undefined>(
		advancedRoadmapsReduxStoreStateResource,
	);

	const cacheState = useCallback(
		(state: State) => {
			update(() => state);
		},
		[update],
	);

	return [initialState ?? undefined, cacheState] as const;
};
