import { createHook, createStateHook } from '@atlassian/react-sweet-state';
import {
	type ParentIndicator,
	type DropIndicator,
	type DragIndicator,
	DRAG_INDICATOR,
	DROP_INDICATOR,
	PARENT_INDICATOR,
	type UseParentIndicatorOptions,
} from '../types';
import { store } from './store';

export const useDragAndDropController = createHook(store);

export const useDragAndDropInstance = createStateHook(store, {
	selector: (state) => state.instance,
});

export const useIsDragging = createStateHook(store, {
	selector: (state) => !!state.dragTarget,
});

export const useDragIndicator = createStateHook(store, {
	selector: (state, index: number): DragIndicator | undefined => {
		const indicator = state.indicators?.find(
			// eslint-disable-next-line @typescript-eslint/no-shadow
			(indicator) => indicator.type === DRAG_INDICATOR && indicator.index === index,
		);

		if (indicator?.type === DRAG_INDICATOR) return indicator;
	},
});

export const useDropIndicator = createStateHook(store, {
	selector: (state, index: number): DropIndicator | undefined => {
		const indicator = state.indicators?.find(
			// eslint-disable-next-line @typescript-eslint/no-shadow
			(indicator) => indicator.type === DROP_INDICATOR && indicator.index === index,
		);

		if (indicator?.type === DROP_INDICATOR) return indicator;
	},
});

export const useParentIndicator = createStateHook(store, {
	selector: (state, { id, index }: UseParentIndicatorOptions): ParentIndicator | undefined => {
		const indicator = state.indicators?.find(
			// eslint-disable-next-line @typescript-eslint/no-shadow
			(indicator) =>
				indicator.type === PARENT_INDICATOR &&
				((index && indicator.index === index) || (id && indicator.id === id)),
		);

		if (indicator?.type === PARENT_INDICATOR) return indicator;
	},
});
