import { getMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app';
import { getTypeToLevel } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/hierarchy';
import { getIsIssuesLoading } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues';
import {
	getCurrentScenarioColor,
	getDateConfiguration,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan';
import { isNonPublishingEditor } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/system';
import { getTeams } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams';
import {
	getFilteredReviewChanges,
	getReviewChangesCount,
	getIsLoading,
	getIsSaving,
	getIsSavingInterrupted,
	getIsReverting,
	getIsRevertingInterrupted,
	getIsOutOfSync,
	getIsCommitWarningFlagClosed,
	getCommittedChangesCount,
	getSelectedChangesCount,
	getIsLoaded,
	getSortDirection,
	getExpandedChanges,
	getCommitWarnings,
	getChangesWarnings,
	getNotificationPreference,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira';
import type { Mode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types';
import {
	EDIT,
	OPTIMIZING,
	OPTIMIZED,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import type { Team } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/teams/types';
import type { State as WarningsState } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/update-jira/warnings/types';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { DialogProps, StateProps } from '../types';
import {
	getCommitWarningMeta,
	getDialogVisibilityState,
	sortChanges,
	expandChanges,
} from './common';

const getHiddenIssuesDialogVisibilityState = (state: State) =>
	state.ui.Top.TitleBar.HiddenIssues.isDialogOpen;

export const dialog = createSelector(
	[
		// NOTE this changes count is different from just getReviewChanges.length,
		// refer selector comment for details
		// @ts-expect-error - TS2769 - No overload matches this call.
		getReviewChangesCount,
		// @ts-expect-error - TS2769 - No overload matches this call.
		getDialogVisibilityState,
		// @ts-expect-error - TS2769 - No overload matches this call.
		getIsLoading,
		// @ts-expect-error - TS2769 - No overload matches this call.
		getIsSaving,
		// @ts-expect-error - TS2769 - No overload matches this call.
		getIsSavingInterrupted,
		// @ts-expect-error - TS2769 - No overload matches this call.
		getIsReverting,
		// @ts-expect-error - TS2769 - No overload matches this call.
		getIsRevertingInterrupted,
		// @ts-expect-error - TS2769 - No overload matches this call.
		getIsOutOfSync,
		// @ts-expect-error - TS2769 - No overload matches this call.
		getHiddenIssuesDialogVisibilityState,
		// @ts-expect-error - TS2769 - No overload matches this call.
		getMode,
		// @ts-expect-error - TS2769 - No overload matches this call.
		getCommitWarnings,
		// @ts-expect-error - TS2769 - No overload matches this call.
		getChangesWarnings,
		getCommittedChangesCount,
		getIsCommitWarningFlagClosed,
		getSelectedChangesCount,
		getIsLoaded,
		isNonPublishingEditor,
		getNotificationPreference,
		getIsIssuesLoading,
	],
	(
		changesCount: number,
		isDialogOpen: boolean,
		isLoading: boolean,
		isSaving: boolean,
		isSavingInterrupted: boolean,
		isReverting: boolean,
		isRevertingInterrupted: boolean,
		isOutOfSync: boolean,
		hiddenIssuesDialogIsOpen: boolean,
		mode: Mode,
		commitWarnings: WarningsState,
		changesWarnings: WarningsState,
		committedChangesCount: number,
		isCommitWarningFlagClosed: boolean,
		selectedChangesCount: number,
		isLoaded: boolean,
		// eslint-disable-next-line @typescript-eslint/no-shadow
		isNonPublishingEditor: boolean,
		shouldNotifyWatchers: boolean,
		isIssuesLoading: boolean,
	): DialogProps => ({
		changesCount,
		isDialogOpen,
		isLoading,
		isSaving,
		isSavingInterrupted,
		isReadOnly: mode !== EDIT,
		isOptimizing: mode === OPTIMIZING,
		isOptimized: mode === OPTIMIZED,
		isReverting,
		isRevertingInterrupted,
		isOutOfSync,
		hiddenIssuesDialogIsOpen,
		commitWarningMeta: getCommitWarningMeta(
			commitWarnings,
			changesWarnings,
			isSaving,
			committedChangesCount,
			isCommitWarningFlagClosed,
			isLoaded,
		),
		selectedChangesCount,
		isNonPublishingEditor,
		shouldNotifyWatchers,
		isIssuesLoading,
	}),
);

const changesSelector = createSelector(
	[getFilteredReviewChanges, getSortDirection, getExpandedChanges],
	(filteredReviewChanges, sortDirection, expandedChanges) =>
		expandChanges(sortChanges(filteredReviewChanges, sortDirection), expandedChanges),
);

const getHasPlanOnlyTeamsPure = (teams: Team[]): boolean =>
	teams.some(({ isPlanTeam }) => isPlanTeam);

const getHasPlanOnlyTeams = createSelector([getTeams], getHasPlanOnlyTeamsPure);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State): StateProps => ({
	...dialog(state),
	changes: changesSelector(state),
	currentScenarioColor: getCurrentScenarioColor(state),
	dateConfiguration: getDateConfiguration(state),
	typeToLevel: getTypeToLevel(state),
	hasPlanOnlyTeams: getHasPlanOnlyTeams(state),
});
