import { isAfter } from 'date-fns';
import * as R from 'ramda';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import { isDefined, indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import type { Timestamp } from '@atlassian/jira-portfolio-3-portfolio/src/common/types';
import type { RollUpDates } from './types';

export const getRollUpDatesForSelectedIssues = (
	selectedIssues: Issue[],
	childrenByParent: {
		[parentId: string]: Issue[];
	},
): RollUpDates => {
	const selectedIssuesById = indexBy(R.prop('id'), selectedIssues);
	const childrenOfSelectedIssues = selectedIssues.flatMap(({ id }) => childrenByParent[id] || []);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result: Record<string, any> = {};
	childrenOfSelectedIssues.forEach(
		({ id, parent, baselineStart: issueBaselineStart, baselineEnd: issueBaselineEnd }) => {
			/**
			 * If the one of the children of the parent is selected for the bulk update and we
			 * have configured one of the dates to be updated then we need to consider the updated
			 * dates of the children while calculating rolled up dates for the parent.
			 * Example: If parent and child both are selected and child doesn't have a start date.
			 * Now we configure "Set by child dates" for the start date and "Clear date" for
			 * end date. So if the end date of the child is cleared, it will not participate in
			 * rolling up date as it will not have both the dates after we bulk update. Hence to
			 * align with the end result, we need to consider updated dates for the children.
			 */
			const child = selectedIssuesById[id];
			const dates = {
				baselineStart: isDefined(child) ? child.baselineStart : issueBaselineStart,
				baselineEnd: isDefined(child) ? child.baselineEnd : issueBaselineEnd,
			};

			if (!isDefined(dates.baselineStart) && !isDefined(dates.baselineEnd)) {
				return result;
			}

			if (parent) {
				if (!isDefined(result[parent])) {
					result[parent] = {
						baselineStart: R.defaultTo(null, dates.baselineStart),
						baselineEnd: R.defaultTo(null, dates.baselineEnd),
					};

					return result;
				}

				(['baselineStart', 'baselineEnd'] as const).forEach((dateType) => {
					/**
					 * Once we get a child without a date, we don't need to calculate roll-up date
					 * as the rolled up value for the parent will be null then after. Hence we are
					 * calculating roll-up date only when previous roll-up value is not null.
					 */
					if (isDefined(R.path([parent, dateType], result))) {
						if (!isDefined(dates[dateType])) {
							result[parent][dateType] = null;
						} else {
							const fn = dateType === 'baselineStart' ? Math.min : Math.max;

							result[parent][dateType] = fn(
								result[parent][dateType],
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								dates[dateType]!,
							);
						}
					}
				});
			}

			return result;
		},
	);
	return result;
};

export const getDateForValidation = (
	selectedIssues: Issue[],
	isTargetStart: boolean,
): Timestamp | null | undefined => {
	let dateForValidation: Issue['baselineEnd'] | null = null;
	if (isTargetStart) {
		selectedIssues.forEach(({ baselineEnd }) => {
			if (!isDefined(dateForValidation) && isDefined(baselineEnd)) {
				dateForValidation = baselineEnd;
			} else if (
				isDefined(dateForValidation) &&
				isDefined(baselineEnd) &&
				isAfter(dateForValidation, baselineEnd)
			) {
				dateForValidation = baselineEnd;
			}
		});
	} else {
		selectedIssues.forEach(({ baselineStart }) => {
			if (!isDefined(dateForValidation) && isDefined(baselineStart)) {
				dateForValidation = baselineStart;
			} else if (
				isDefined(dateForValidation) &&
				isDefined(baselineStart) &&
				isAfter(baselineStart, dateForValidation)
			) {
				dateForValidation = baselineStart;
			}
		});
	}
	return dateForValidation;
};
