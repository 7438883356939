import React, { type KeyboardEvent, type MouseEvent } from 'react';
import * as R from 'ramda';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { DropdownItemGroup as DropMenuItemGroup } from '@atlaskit/dropdown-menu';
import { FormattedMessage } from '@atlassian/jira-intl';
import { DropMenuItem } from '@atlassian/jira-portfolio-3-common/src/drop-menu/index.tsx';
import { getGroupKey } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/util.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import { getInlineCreateIssueTypeId } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/util';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

function CreateIssue(props: Props) {
	const {
		groupCombination,
		hierarchy,
		hierarchyLevelsForType,
		isDisabled = false,
		issue: { group, id, level, parent, project, type },
		projects,
		setGlobalCreateIssue,
		setIssueTypeIdForHierarchy,
		startInlineCreate,
		triggerInlineCreateIssueAnalytics,
		typeToLevel,
	} = props;

	const currentLevel = hierarchy.find((hierarchyLevel) => level === hierarchyLevel.level);
	if (!isDefined(currentLevel)) {
		return null;
	}

	const hierarchyLevelTitle = (hierarchyLevel: { readonly title: string }): string =>
		hierarchyLevel.title.toLowerCase();

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const getInlineCreateProject = (level: number) => {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const projectsForLevel = projects.filter((project) =>
			// eslint-disable-next-line @typescript-eslint/no-shadow
			project.issueTypeIds.some((id) => typeToLevel(id) === level),
		);
		// eslint-disable-next-line @typescript-eslint/no-shadow
		return projectsForLevel.some(({ id }) => id === project.id) ? project : projectsForLevel[0];
	};

	const hierarchyLevels = hierarchyLevelsForType(type.id);
	const childLevel = R.head(hierarchyLevels);

	let childInlineCreateProject: Project | undefined;
	if (isDefined(childLevel)) {
		childInlineCreateProject = getInlineCreateProject(childLevel.level);
	}
	const groupKey =
		groupCombination && Object.keys(groupCombination).length
			? getGroupKey(groupCombination)
			: group;

	return (
		<DropMenuItemGroup>
			<DropMenuItem
				id={level}
				onClick={(_: KeyboardEvent | MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
					const inlineCreateProject = getInlineCreateProject(currentLevel.level);
					if (!isDefined(inlineCreateProject)) {
						return;
					}
					const issueTypeId = type.id;
					if (isDefined(issueTypeId)) {
						setIssueTypeIdForHierarchy(currentLevel.level, issueTypeId);
						setGlobalCreateIssue();
						// triggering analytics
						triggerInlineCreateIssueAnalytics(analyticsEvent, issueTypeId);
						return startInlineCreate({
							parentId: parent,
							hierarchyLevel: currentLevel.level,
							issueTypeId,
							projectId: inlineCreateProject.id,
							siblingId: id,
							group: groupKey,
							groupCombination,
							source: 'INLINE',
						});
					}
				}}
				key={level}
				isDisabled={isDisabled}
			>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<span className={styles.issueTypeLabel}>
					<FormattedMessage
						{...messages.createIssue}
						values={{ issueType: hierarchyLevelTitle(currentLevel) }}
					/>
				</span>
			</DropMenuItem>
			{isDefined(childInlineCreateProject) && isDefined(childLevel) && (
				<DropMenuItem
					id={childLevel.level}
					isDisabled={isDisabled}
					onClick={(_: KeyboardEvent | MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
						const issueTypeId = getInlineCreateIssueTypeId(
							childLevel.level,
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							childInlineCreateProject as Project,
							props,
						);
						setIssueTypeIdForHierarchy(childLevel.level, issueTypeId);
						setGlobalCreateIssue();
						// triggering analytics
						triggerInlineCreateIssueAnalytics(analyticsEvent, issueTypeId);
						return startInlineCreate({
							parentId: id,
							hierarchyLevel: childLevel.level,
							issueTypeId,
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							projectId: (childInlineCreateProject as Project).id,
							siblingId: null,
							group: groupKey,
							groupCombination,
							source: 'INLINE',
						});
					}}
					key={childLevel.level}
				>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<span className={styles.issueTypeLabel}>
						<FormattedMessage
							{...messages.createIssue}
							values={{ issueType: hierarchyLevelTitle(childLevel) }}
						/>
					</span>
				</DropMenuItem>
			)}
		</DropMenuItemGroup>
	);
}

export default CreateIssue;
