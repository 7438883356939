import React, { useState, useCallback, useRef, useEffect } from 'react';
import debounce from 'lodash/debounce';
import type { MultiValue } from '@atlaskit/react-select';
import type { ControlProps, MultiValueRemoveProps, StylesConfig } from '@atlaskit/select';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { AssociatedIssue } from '@atlassian/jira-portfolio-3-associated-issues/src/common/types.tsx';
import { selectComponents } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import type { InputActionMeta } from '@atlassian/jira-portfolio-3-common/src/select/types';
import { withSlots, slots } from '@atlassian/jira-portfolio-3-portfolio/src/common/component-slots';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import ControlComponent from './control-component';
import messages from './messages';
import Option from './option';
import type { Props } from './types';
import { createFetchAssociatedIssuesForSelectOptions } from './utils';

const DEBOUNCE_TIME_MS = 250;
const MIN_SELECT_WIDTH = 300;

const MultiValueRemove = (props: MultiValueRemoveProps<AssociatedIssue, true>) => {
	const innerProps = {
		...props.innerProps,
		'aria-label': `Remove ${props.data?.summary ?? 'idea'}`,
	};
	return <selectComponents.MultiValueRemove {...props} innerProps={innerProps} />;
};

export const IdeasCellSelect = ({
	FieldSelect,
	renderMultipleIdeas,
	onUpdateIdeas,
	currentAssociatedIssues,
}: Props) => {
	const [selectedOptions, setSelectedOptions] = useState(currentAssociatedIssues);
	const { formatMessage } = useIntl();
	const [isSearchInProgress, setIsSearchInProgress] = useState(false);
	const cloudId = useCloudId();
	const [allAssociatedIssuesToRender, setAllAssociatedIssuesToRender] = useState<AssociatedIssue[]>(
		[],
	);
	const fetchData = createFetchAssociatedIssuesForSelectOptions(
		cloudId,
		setIsSearchInProgress,
		setAllAssociatedIssuesToRender,
	);
	const debounceGetOptions = useRef(debounce(fetchData, DEBOUNCE_TIME_MS)).current;

	useEffect(() => {
		setSelectedOptions(currentAssociatedIssues);
	}, [currentAssociatedIssues]);

	const reloadOptions = useCallback(() => {
		setIsSearchInProgress(true);
		debounceGetOptions('');
	}, [debounceGetOptions]);

	const onMenuOpen = useCallback(() => {
		reloadOptions();
	}, [reloadOptions]);

	const onMenuClose = useCallback(() => {
		onUpdateIdeas(selectedOptions);
	}, [onUpdateIdeas, selectedOptions]);

	const handleInputChange = useCallback(
		(query: string, { action }: InputActionMeta) => {
			if (action === 'input-change') {
				debounceGetOptions(query);
			}
		},
		[debounceGetOptions],
	);

	const onItemChanged = useCallback(
		(options: MultiValue<AssociatedIssue>) => {
			const currentSelectedOptions = Array.isArray(options) ? options : [];
			setSelectedOptions(currentSelectedOptions);
			reloadOptions();
		},
		[reloadOptions],
	);

	const ControlComponentWrapper = useCallback(
		(props: ControlProps<AssociatedIssue, true>) => (
			<ControlComponent
				{...props}
				renderMultipleIdeas={() => renderMultipleIdeas(selectedOptions)}
			/>
		),
		[renderMultipleIdeas, selectedOptions],
	);

	return (
		<FieldSelect
			isMulti
			isLoading={isSearchInProgress}
			value={selectedOptions}
			options={allAssociatedIssuesToRender}
			formatOptionLabel={(option?: AssociatedIssue) => <Option option={option} key={option?.key} />}
			onMenuClose={onMenuClose}
			onMenuOpen={onMenuOpen}
			onChange={onItemChanged}
			closeMenuOnSelect={false}
			styles={selectStyles}
			onInputChange={handleInputChange}
			minSelectWidth={MIN_SELECT_WIDTH}
			placeholder={formatMessage(messages.chooseIdea)}
			noOptionsMessage={() => formatMessage(messages.noIdeas)}
			components={{
				Control: ControlComponentWrapper,
				MultiValueRemove,
				LoadingIndicator: () => <Spinner size="small" />,
			}}
			getOptionValue={(idea: AssociatedIssue) => idea.key}
			filterOption={() => true}
		/>
	);
};

const selectStyles: StylesConfig<AssociatedIssue, true> = {
	container: (styles, { selectProps: { menuIsOpen } }) => ({
		...styles,
		...(menuIsOpen ? { zIndex: 1 } : {}),
	}),
	control: (styles, { hasValue, selectProps: { menuIsOpen } }) =>
		menuIsOpen
			? styles
			: {
					...styles,
					borderColor: 'transparent',
					backgroundColor: 'transparent',
					':hover': {
						...styles[':hover'],
						background: 'none',
						...(hasValue ? { borderColor: 'transparent' } : {}),
					},
				},
	valueContainer: (styles) => ({
		...styles,
		maxHeight: 150,
		overflow: 'auto',
		flex: '1',
	}),
	multiValue: (styles) => ({
		...styles,
		borderWidth: token('border.width', '1px'),
		borderStyle: 'solid',
		borderColor: token('color.border', '#091e4221'),
		borderRadius: token('border.radius', '3px'),
		backgroundColor: token('elevation.surface', '#FFFFFF'),
	}),
} as const;

export default withSlots({ FieldSelect: slots.FieldSelect })(IdeasCellSelect);
