import { useCallback } from 'react';
import { useFlagsService } from '@atlassian/jira-flags';
import type { addIssueLink } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue-links';
import { TABLE_ITEM } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/grid/constants';
import type { GridRow } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/grid/types';
import type { IssueLinksByIssueId } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-links';
import {
	TABLE_ISSUE,
	type TableItem,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table';
import type { DependencySettingsInfo } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/system/types';
// Move the messages to this folder when cleaning com.atlassian.rm.jpo.transposition.m2
import messages from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/timeline/schedule/dependency-lines/drag-preview/provider/messages';

type AddIssueLink = typeof addIssueLink;

const DEPENDENCY_CREATION_ERROR_FLAG_ID = 'dragPreviewDependencyCreationErrorFlag';

export const isDragCreatePayload = (data: unknown): data is { row: number } => {
	if (typeof data !== 'object' || data === null) {
		return false;
	}

	if (!('row' in data) || typeof data.row !== 'number') {
		return false;
	}

	return true;
};

export const getIssueFromItem = (item?: TableItem) =>
	item?.tag === TABLE_ISSUE ? item.value : undefined;

export const getIssue = (rows: GridRow[], items: TableItem[], rowIndex: number) => {
	const row = rows[rowIndex];

	if (row?.tag !== TABLE_ITEM) {
		return undefined;
	}

	const item = items[row.payload.itemIndex];

	if (item?.tag !== TABLE_ISSUE) {
		return undefined;
	}

	return item.value;
};

export const useDropHandling = (
	addIssueLink: AddIssueLink,
	outgoingLinks: IssueLinksByIssueId,
	dependencySettings: DependencySettingsInfo,
) => {
	const { showFlag } = useFlagsService();

	return useCallback(
		({
			dragSourcePosition,
			dragSourceIssueId,
			dropTargetIssueId,
			issueLinkType,
		}: {
			dragSourcePosition: 'start' | 'end';
			dragSourceIssueId: string | null;
			dropTargetIssueId: string | null;
			issueLinkType: number;
		}) => {
			if (dropTargetIssueId == null || dragSourceIssueId == null) {
				return false;
			}

			const [source, target]: string[] =
				dragSourcePosition === 'end'
					? [dragSourceIssueId, dropTargetIssueId]
					: [dropTargetIssueId, dragSourceIssueId];

			// We don't create a dependency with itself
			if (source === target) {
				return false;
			}

			const outgoingLinksForItem = outgoingLinks[source] || [];

			const existingLinkArray = outgoingLinksForItem.filter(
				(link) => link.targetItemKey === target && link.type === issueLinkType,
			);

			if (existingLinkArray.length > 0) {
				return false;
			}

			const dependencyIssueLinkTypes = dependencySettings.dependencyIssueLinkTypes ?? [];
			const hasIssueLinkTypeConfigured = dependencyIssueLinkTypes.find(
				(linkType) => linkType.issueLinkTypeId === issueLinkType,
			);

			if (!hasIssueLinkTypeConfigured) {
				showFlag({
					id: DEPENDENCY_CREATION_ERROR_FLAG_ID,
					title: messages.noLinkTypeFlagTitle,
					type: 'error',
					description: messages.noLinkTypeFlagDescription,
					isAutoDismiss: true,
					actions: [
						{
							content: messages.noLinkTypeFlagLink,
							href: 'https://support.atlassian.com/jira-software-cloud/docs/configure-dependencies-in-jira-for-advanced-roadmaps/',
							target: '_blank',
						},
					],
				});

				return false;
			}

			addIssueLink({
				sourceItemKey: source,
				targetItemKey: target,
				type: issueLinkType,
			});

			return true;
		},
		[addIssueLink, outgoingLinks, showFlag, dependencySettings?.dependencyIssueLinkTypes],
	);
};
