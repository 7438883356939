import * as R from 'ramda';
import type {
	IssueLink,
	IssueLinkData as ApiIssueLinkData,
	IssueLinksData,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types';
import { mapObject, indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import type { IssueId } from '@atlassian/jira-portfolio-3-portfolio/src/common/types';
import { ISSUE_LINK_DIRECTION } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant';
import type { IssueLinks } from '../../state/domain/issue-links/types';
import type {
	DependencySettingsInfo,
	IssueLinkTypesInfo,
	IssueLinkType,
} from '../../state/domain/system/types.tsx';
import type { EntityMetadata } from '../../state/domain/update-jira/changes/types.tsx';
import type { State } from '../../state/types';
import { getIssueMapById, type IssueMap } from '../raw-issues';
import { getDependencySettingsInfo, getIssueLinkTypesInfo } from '../system';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { IssueLinks } from '../../state/domain/issue-links/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { IssueLinkType } from '../../state/domain/system/types.tsx';

export type IssueLinksByIssueId = Record<IssueId, IssueLink[]>;

export type CountByIssueLinkId = {
	[key: number]: number;
};

// # Raw links

export const getIssueLinks = (state: State): IssueLinks => state.domain.issueLinks.values;

export const getIssueLinkOriginals = (state: State): IssueLinks =>
	state.domain.issueLinks.originals;

// # Internal/external links helpers

export const getInternalLinks = (
	issueLinks: IssueLinksByIssueId,
	issuesById: IssueMap,
): IssueLinksByIssueId =>
	R.map(
		(links) => links.filter((x) => issuesById[x.sourceItemKey] && issuesById[x.targetItemKey]),
		issueLinks,
	);

export const getExternalLinks = (
	issueLinks: IssueLinksByIssueId,
	issuesById: IssueMap,
): IssueLinksByIssueId =>
	R.map(
		(links) => links.filter((x) => !issuesById[x.sourceItemKey] || !issuesById[x.targetItemKey]),
		issueLinks,
	);

// # Outgoing links

// ## All outgoing links

export const getOutgoingLinksPure = (issueLinks: IssueLinks): IssueLinksByIssueId =>
	mapObject(
		(issueId, links) => R.values(links).filter((x) => x.sourceItemKey === issueId),
		issueLinks,
	);

export const getOutgoingLinks = createSelector([getIssueLinks], getOutgoingLinksPure);

export const getOutgoingLinkOriginals = createSelector(
	[getIssueLinkOriginals],
	getOutgoingLinksPure,
);

// ## Outgoing links within a plan

export const getInternalOutgoingLinks = createSelector(
	[getOutgoingLinks, getIssueMapById],
	getInternalLinks,
);

export const getInternalOutgoingLinkOriginals = createSelector(
	[getOutgoingLinkOriginals, getIssueMapById],
	getInternalLinks,
);

// ## External outgoing links

export const getExternalOutgoingLinks = createSelector(
	[getOutgoingLinks, getIssueMapById],
	getExternalLinks,
);

export const getExternalOutgoingLinkOriginals = createSelector(
	[getOutgoingLinkOriginals, getIssueMapById],
	getExternalLinks,
);

// # Incoming links

// ## All incoming links

export const getIncomingLinksPure = (issueLinks: IssueLinks): IssueLinksByIssueId =>
	mapObject(
		(issueId, links) => R.values(links).filter((x) => x.targetItemKey === issueId),
		issueLinks,
	);

export const getIncomingLinks = createSelector([getIssueLinks], getIncomingLinksPure);

export const getIncomingLinkOriginals = createSelector(
	[getIssueLinkOriginals],
	getIncomingLinksPure,
);

// ## Incoming links within a plan

export const getInternalIncomingLinks = createSelector(
	[getIncomingLinks, getIssueMapById],
	getInternalLinks,
);

export const getInternalIncomingLinkOriginals = createSelector(
	[getIncomingLinkOriginals, getIssueMapById],
	getInternalLinks,
);

// ## External incoming links

export const getExternalIncomingLinks = createSelector(
	[getIncomingLinks, getIssueMapById],
	getExternalLinks,
);

export const getExternalIncomingLinkOriginals = createSelector(
	[getIncomingLinkOriginals, getIssueMapById],
	getExternalLinks,
);

// # Various helpers

export const getAllIssuesWithLinks = createSelector(
	[getIssueLinks],
	(issueLinks) => new Set(Object.keys(R.reject(R.isEmpty, issueLinks))),
);

export const getUniqueLinks = createSelector([getIssueLinks], (issueLinks) => {
	const cleanIssueLinks = R.flatten(R.map(R.values, R.values(R.reject(R.isEmpty, issueLinks))));
	const seen = new Set();

	/* Unique items by property, this could potentially be moved to a util */
	return cleanIssueLinks.filter((issueLink) => {
		if (seen.has(issueLink.itemKey)) {
			return false;
		}
		seen.add(issueLink.itemKey);
		return true;
	});
});

// # Issue link types

export const getDependencyIssueLinkTypesPure = (
	{ issueLinkTypes }: IssueLinkTypesInfo,
	{ dependencyIssueLinkTypes }: DependencySettingsInfo,
): IssueLinkType[] => {
	const linkTypes = new Set(dependencyIssueLinkTypes.map(({ issueLinkTypeId }) => issueLinkTypeId));
	return issueLinkTypes.filter(({ id }) => linkTypes.has(id));
};

export const getDependencyIssueLinkTypes = createSelector(
	[getIssueLinkTypesInfo, getDependencySettingsInfo],
	getDependencyIssueLinkTypesPure,
);

export const getDependencyIssueLinkTypesById = createSelector(
	[getDependencyIssueLinkTypes],
	(
		dependencyIssueLinkTypes,
	): {
		[id: number]: IssueLinkType;
	} => indexBy(R.prop('id'), dependencyIssueLinkTypes),
);

// # Issue count by type
export const getIssueCountByLinkTypePure = (issueLinks: IssueLink[]): CountByIssueLinkId =>
	R.countBy((issue: IssueLink) => issue.type)(issueLinks);

export const getIssueCountByLinkType = createSelector(
	[getUniqueLinks],
	getIssueCountByLinkTypePure,
);

// # Issue link changes

export type IssueLinksDataMap = Record<IssueId, IssueLinksData[]>;

export const getIssueLinkChangesDataPure = (
	issueLinks: ApiIssueLinkData[] = [],
): IssueLinksDataMap => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result: Record<string, any> = {};

	for (const link of issueLinks) {
		const { sourceItemKey, targetItemKey } = link;

		if (!result[sourceItemKey]) result[sourceItemKey] = [];
		if (!result[targetItemKey]) result[targetItemKey] = [];

		result[sourceItemKey].push({ ...link, direction: ISSUE_LINK_DIRECTION.INWARD });
		result[targetItemKey].push({ ...link, direction: ISSUE_LINK_DIRECTION.OUTWARD });
	}

	return result;
};

export const getIssueLinkChangesData = createSelector(
	[R.path(['domain', 'updateJira', 'changes', 'data', 'issueLinks'])],
	getIssueLinkChangesDataPure,
);

export const getIssueLinkChangesMetaData = (state: State): EntityMetadata =>
	R.path(['domain', 'updateJira', 'changes', 'data', 'metaData', 'issueLinks'], state) || {};
