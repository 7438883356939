import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import Select from '@atlassian/jira-portfolio-3-portfolio/src/common/view/select/view.tsx';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types';

const FieldActionSelect = ({ fieldAction, setFieldAction }: Props) => {
	const { formatMessage } = useIntl();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleChange = (selectedOption: any) => {
		setFieldAction(selectedOption.value);
	};

	const fieldActionOptions = [
		{ label: formatMessage(messages.add), value: 'ADD' },
		{ label: formatMessage(messages.remove), value: 'REMOVE' },
		{ label: formatMessage(messages.replace), value: 'SET' },
		{ label: formatMessage(messages.clear), value: 'CLEAR' },
	];

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles['field-action-container']}>
			<Select
				id="field-action-select"
				options={fieldActionOptions}
				onChange={handleChange}
				value={fieldActionOptions.filter((option) => option.value === fieldAction)[0]}
				isTransparentBackground={false}
			/>
		</div>
	);
};

export default FieldActionSelect;
