import type { UserPickerOption } from './types';

export const RESET = 'state.domain.user-picker-options.RESET' as const;

export type ResetAction = {
	type: typeof RESET;
	payload: UserPickerOption[];
};

export const reset = (payload: UserPickerOption[]): ResetAction => ({
	type: RESET,
	payload,
});
