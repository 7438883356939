import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect';
import { getFlags } from '../../query/flags';
import { getVisualisationSorting } from '../../query/visualisations';
import type { State } from '../../state/types';
import type { OwnProps, StateProps } from './types';

export default createStructuredSelector<State, OwnProps, StateProps>({
	flags: getFlags,
	sorting: getVisualisationSorting,
});
