import {
	SET_PERMISSIONS,
	type SetPermissionsAction,
	ADD_PERMISSION,
	type AddPermissionAction,
	REMOVE_PERMISSION,
	type RemovePermissionAction,
} from '../actions';
import type { Permission } from '../types';

type Action = AddPermissionAction | RemovePermissionAction | SetPermissionsAction;

const initialState: Array<Permission> = [];

const permissionReducer = (state: Permission, action: Action) => {
	switch (action.type) {
		case ADD_PERMISSION: {
			if (state.holder.key !== action.payload.holder.key) {
				return state;
			}
			return {
				...state,
				permissions: state.permissions.concat(action.payload.permission),
			};
		}
		case REMOVE_PERMISSION: {
			if (state.holder.key !== action.payload.holder.key) {
				return state;
			}
			return {
				...state,
				permissions: state.permissions.filter((role) => role !== action.payload.permission),
			};
		}
		default: {
			return state;
		}
	}
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: Permission[] = initialState, action: Action): Permission[] => {
	switch (action.type) {
		case SET_PERMISSIONS:
			return [...action.payload];
		case ADD_PERMISSION: {
			const { holder } = action.payload;
			const existingPermission = state.find((permission) => permission.holder.key === holder.key);
			if (!existingPermission) {
				const { permission, ...rest } = action.payload;
				return state.concat({ ...rest, permissions: [permission] });
			}
			return state.map((permission) => permissionReducer(permission, action));
		}
		case REMOVE_PERMISSION:
			return state
				.map((permission) => permissionReducer(permission, action))
				.filter((permission) => permission.permissions.length > 0);
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
