import React, { Component, type ChangeEvent } from 'react';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle';
import AkTextField from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { injectIntl } from '@atlassian/jira-intl';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import { IconButton } from '@atlassian/jira-portfolio-3-common/src/icon/index.tsx';
import { SUMMARY_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import filterMessages from '../messages';
import messages from './messages';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, State } from './types';

// eslint-disable-next-line jira/react/no-class-components
class SummaryFilter extends Component<Props, State> {
	state = {
		query: this.props.value || '',
		value: this.props.value,
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static getDerivedStateFromProps(props: any, state: any) {
		if (props.value !== state.value) {
			return {
				value: props.value,
				query: props.value || '',
			};
		}
		return null;
	}

	applyQuery = (query: string) => {
		const { changeSummaryFilter } = this.props;
		changeSummaryFilter(query);
	};

	applyQueryDebounced = debounce(this.applyQuery, 250);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onFieldChange = (e: ChangeEvent<any>) => {
		const query = e.target.value;
		this.setState({
			query,
		});
		this.applyQueryDebounced(query);
	};

	onClearQuery = () => {
		this.setState({
			query: '',
		});
		this.applyQueryDebounced('');
	};

	render() {
		const { intl, inputRef } = this.props;
		const { query } = this.state;

		const hasInput = query.length > 0;
		return (
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={classNames(styles.searchFieldContainer, {
					[styles.hasInput]: hasInput,
				})}
			>
				<AkTextField
					autoFocus
					ref={inputRef}
					elemAfterInput={
						hasInput && (
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							<div className={styles.clearSearchField}>
								<IconButton
									onClick={this.onClearQuery}
									tabIndex={-1}
									ariaLabel={intl.formatMessage(messages.clearButtonLabel)}
								>
									<CrossCircleIcon
										label=""
										primaryColor={token('color.icon.subtle', colors.N70)}
										size="small"
									/>
								</IconButton>
							</div>
						)
					}
					onChange={this.onFieldChange}
					placeholder={intl.formatMessage(messages.placeholder)}
					testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.summary-filter"
					value={query}
					aria-label={intl.formatMessage(filterMessages[SUMMARY_FILTER_ID])}
				/>
			</div>
		);
	}
}

export default injectIntl(SummaryFilter);
