import React, { memo, useRef } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { Z_INDEX_LEVEL } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/types.tsx';
import { useZIndex } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/view.tsx';
import { useRow } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/row/index.tsx';
import { Cell } from '@atlassian/jira-portfolio-3-treegrid/src/ui/cell/index.tsx';
import { useColumnCollapsed } from '../column-collapse/utils/hooks.tsx';
import { DragIndicator } from '../drag-and-drop/drag-indicator';
import { DropIndicator } from '../drag-and-drop/drop-indicator';
import { DraggableColumn } from '../draggable-column';
import { ColumnDragHandle } from '../draggable-column/drag-handle';
import { ColumnDropIndicator } from '../draggable-column/drop-indicator';
import type { InnerProps, Props } from './types';
import { useFocusWithin } from './utils';

export const EnhancedCell = ({
	hasBorderTop,
	hasBorderBottom,
	hasDragIndicator,
	hasDropIndicator,
	columnText,
	resizeHandle,
	clipped,
	hasBackground,
	columnId,
	isDraggableColumn,
	hasColumnDropIndicator,
	children,
	...restProps
}: Props) => {
	const { row } = useRow();
	const { column } = restProps;

	const ref = useRef<HTMLTableCellElement>(null);
	const isFocusWithin = useFocusWithin(ref);
	const zIndex = useZIndex({
		row,
		column,
		level: isFocusWithin ? Z_INDEX_LEVEL.FOCUSED : Z_INDEX_LEVEL.DEFAULT,
	});

	return (
		<Cell {...restProps} xcss={cellStyles} style={{ zIndex }} ref={ref}>
			<MemoedCellInner
				hasBorderTop={hasBorderTop}
				hasBorderBottom={hasBorderBottom}
				hasDragIndicator={hasDragIndicator}
				hasDropIndicator={hasDropIndicator}
				columnText={columnText}
				resizeHandle={resizeHandle}
				clipped={clipped}
				hasBackground={hasBackground}
				columnId={columnId}
				isDraggableColumn={isDraggableColumn}
				hasColumnDropIndicator={hasColumnDropIndicator}
			>
				{children}
			</MemoedCellInner>
		</Cell>
	);
};

const CellInner = ({
	hasBorderTop = false,
	hasBorderBottom = false,
	hasDragIndicator = false,
	hasDropIndicator = false,
	hasBackground = true,
	columnText,
	resizeHandle,
	clipped = true,
	columnId,
	isDraggableColumn = false,
	hasColumnDropIndicator = false,
	children,
}: InnerProps) => {
	const collapsed = useColumnCollapsed();

	const contents = (
		<Box
			xcss={[
				wrapperStyles,
				hasBorderTop && borderTopStyles,
				hasBorderBottom && borderBottomStyles,
				collapsed && collapsedStyles,
				clipped && clippedStyles,
				!hasBackground && stripBackgroundStyles,
				focusStyles,
			]}
		>
			{!collapsed && children}
		</Box>
	);

	return (
		<>
			{isDraggableColumn && columnId && fg('plan_timeline_drag_and_drop_field_columns') ? (
				<DraggableColumn
					columnId={columnId}
					renderTrigger={({ ref, ...props }) => (
						<ColumnDragHandle ref={ref} columnId={columnId} {...props} />
					)}
					isDisabled={collapsed}
				>
					{contents}
				</DraggableColumn>
			) : (
				contents
			)}
			{collapsed && columnText}
			{resizeHandle}
			{hasColumnDropIndicator && fg('plan_timeline_drag_and_drop_field_columns') && (
				<ColumnDropIndicator columnId={columnId} />
			)}
			{hasDragIndicator && <DragIndicator />}
			{hasDropIndicator && <DropIndicator />}
		</>
	);
};

const MemoedCellInner = memo(CellInner);

const cellStyles = xcss({
	backgroundColor: 'elevation.surface',
	verticalAlign:
		'top' /* Some content (e.g. group header) is hard-coded 39px height and meant to be aligned at top. */,
});

const wrapperStyles = xcss({
	position: 'absolute',
	inset: 'space.0',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderRight: `1px solid ${token('color.background.accent.gray.subtler', colors.N50)}`,
	background: 'var(--plan-grid-row-background)',
	overflow: 'initial',
	transition: 'background-color 0.1s ease',
});

const borderTopStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderTop: `1px solid ${token('color.background.accent.gray.subtler', colors.N50)}`,
});

const borderBottomStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderBottom: `1px solid ${token('color.background.accent.gray.subtler', colors.N50)}`,
});

const collapsedStyles = xcss({
	background: 'unset',
});

const clippedStyles = xcss({
	overflow: 'hidden',
});

const focusStyles = xcss({
	/*
	 * The `overflow: hidden` need to be reset when focusing on cells, such as Status Fields,
	 * Otherwise, the menu which opened when editing the cells would be clipped.
	 */
	':focus-within': {
		overflow: 'initial',
	},
});

const stripBackgroundStyles = xcss({
	'--plan-grid-row-background': 'elevation.surface',
	':hover': {
		// @ts-expect-error - TS2353: Object literal may only specify known properties
		'--plan-grid-row-background': 'elevation.surface',
	},
});
